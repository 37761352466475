import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import styles from '../../../Css/FashionRemoval/FashionListModal.module.css'
import Slider from "react-slick";
import axios from 'axios'
import alertify from 'alertifyjs';

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
    />
  );
}
function SamplePrevArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff", left: "-19px" }}
    />
  );
}
function SampleNextArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff", right: "-19px" }}
    />
  );
}

class BackgroundListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gifList: [],
      gifUrl: "",
      listcustomWall: "https://storage.googleapis.com/spyne-cliq/Fashion_Walls/New6_4008.png",
      accordianUuid: "bg",
      favourable: false,
      previewCustomWall: "https://storage.googleapis.com/spyne-cliq/Fashion_Walls/New1_lr.png",
    }
    this.wallFile = React.createRef()
  }

  componentDidMount = async () => {
    axios.get(`${process.env.REACT_APP_BASEURL}/fetch-gif`)
      .then(res => {
        this.setState({
          gifList: res.data
        })
      }).catch((e)=>{
        alertify.error("Something went wrong! Please reload")
      })
    this.setState({
      favourable: this.props.fav_list == 0 ? false : true

    })
  }
  
  handleBackgroundGif = (imageId, imageCredit, gifUrls, favourite) => {

    this.setState({
      gifUrl: gifUrls,
      favourable: favourite
    })
    this.props.handleBackground(imageId, imageCredit)
  }

  customWallselect = (wall_selected, preview) => {
    this.setState({
      listcustomWall: wall_selected,
      previewCustomWall: preview,
    })
  }

  render() {
    const settings = {
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    const settings_2 = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ],
      nextArrow: <SampleNextArrow_1 />,
      prevArrow: <SamplePrevArrow_1 />
    };
    return (
      <Modal
        show={this.props.showBackgroundList}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size='xl'
        centered>

        <Modal.Body>
          <section className={[styles['perfect-output-1'], 'p-0'].join(' ')}>
            <div className={[styles[''], 'container-fluid'].join(' ')}>
              {
                this.props.visualSelection == "custom_walls" ?
                  <div className={[styles['choose-output'], 'row'].join(' ')}>
                    <div className={[styles['bg-recomended-right'], 'col-md-7 col-12'].join(' ')}>
                      <h3>Choose  <span className={styles['border-bt']}>your <span className={styles['colored']}> Background</span></span></h3>
                      {this.state.gifUrl != '' ?
                        <img className="" src={this.state.gifUrl} />
                        :
                        <img className="" src={this.state.previewCustomWall} />
                      }

                      <div className={[styles['perfect-output-images'], 'row'].join(' ')}>
                        <p><span><a onClick={this.props.hideList}>Cancel</a></span> <a disabled={this.state.listcustomWall.length == 0 ? true : false} onClick={() => this.props.customWallSets(this.state.listcustomWall)}>Select</a></p>
                      </div>
                    </div>
                    <div className={[styles['bg-recomended-area'], styles['custom-big'], 'col-md-5'].join(' ')}>
                      <div className={styles['bg-accordion-tab-main']} >
                        <div className={styles['bg-accordion-tab']} >
                          <h3><span className={styles['colored']}>Spyne's</span><span> Walls</span> <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/down-arrow.png" /></h3>

                          <div className={[styles['bg-recommended'], 'row m-0'].join(' ')}>
                            <ul className={[styles['bg-recomended-images'], ''].join(' ')}
                              style={{ height: "600px" }}
                            >
                              {
                                this.props.wallData.map((e, i) => {
                                  return (
                                    <li className={this.state.listcustomWall == e["wall_png_url"] ? styles['active-background-sec'] : styles['inactive-background-sec']} onClick={() => this.customWallselect(e["wall_png_url"], e["low_res_wall_url"])} >
                                      <img src={e["low_res_wall_url"]} ></img>
                                      {
                                        e["wall_id"].split('-')[0] == "WL" ?
                                          <img class={styles['wall-cross']} onClick={() => this.props.delWallShowModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" />
                                          :
                                          null
                                      }
                                      <span>{e["wall_name"].substring(0, 20)}</span>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <div className={[styles['background-list'], styles['custom-background-list'], ''].join(' ')}>
                      <div className={styles['backgrounds-grid-custom']}>

                        <input type="file" className="d-none" ref={this.wallFile} onChange={(e) => this.handleUploadNew(e)}></input>
                        <div className={[styles['inactive-walls-sec']]}>
                          <img className={styles['custom-wall']} onClick={(e) => this.props.handleUploadClick(e)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/upload-rect-2.png" ></img>
                          <span >Custom</span>
                        </div>


                        {

                          this.props.wallData.map((e, i) => {

                            return (
                              <div className={this.state.listcustomWall == e["wall_png_url"] ? styles['active-walls-sec'] : styles['inactive-walls-sec']} onClick={() => this.customWallselect(e["wall_png_url"])} >
                                <img src={e["low_res_wall_url"]} ></img>
                                {
                                  e["wall_id"].split('-')[0] == "WL" ?
                                    <img class={styles['wall-cross']} onClick={() => this.props.delWallShowModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" />
                                    :
                                    null
                                }
                                <span>{e["wall_name"].substring(0, 20)}</span>
                              </div>
                            )

                          })
                        }

                      </div>
                    </div>
                    <div className={[styles['perfect-output-images'], 'col-md-12'].join(' ')}>
                      <p><span><a onClick={this.props.hideList}>Cancel</a></span> <a disabled={this.state.listcustomWall.length == 0 ? true : false} onClick={() => this.props.customWallSets(this.state.listcustomWall)}>Select</a></p>
                    </div>
                  </div>
              }
            </div>
          </section>

        </Modal.Body>

      </Modal>
    );
  }
}

export default BackgroundListModal;