import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Helmet} from "react-helmet";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
import styles from '../Affiliate/Affiliate.module.css'


export default class Affiliate extends Component {
    
    showForm = () => {
        window.location.replace(`https://www.spyne.ai/affiliate/form`)
    }


render() {
    return (
            <div>
                <Helmet>
                    <title>Spyne Affiliate Program | Become a Spyne's Affiliate Partner</title>
                    <meta property="og:title" content="Spyne Affiliate Program | Become a Spyne's Affiliate Partner"/>
                    <meta property="og:description" content="Become Spyne’s Affiliate Partner and Start Earning Referral Commission with Our Unique AI-Powered Image Editing Tool for Cars, Ecommerce, Fashion and Many More."/>
                </Helmet>
                <section className={[styles['Affiliate-hero'], ''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['hero-content'], 'col-md-6'].join(' ')}>
                                <h1>Help us Reach the Right Audience & <span>Get Rewarded!</span></h1>
                                <p>Join Spyne's affiliate program to refer the right to us and get rewarded each time they make a purchase</p>
                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                            </div>
                            <div className={[styles['hero-img'], 'col-md-6'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/affiliate-map.png"/>
                            </div>
                        </div>
                    </div>
                </section>

    {/* //Hero Section  */}

    {/* Products Section  */}
                <section className={[styles['products'], ''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-md-8 offset-md-2'].join(' ')}>
                                <h2>Products</h2>
                                <p>Our AI-powered image editing tools enable businesses create high-quality visuals at affordable costs. </p>
                               
                            </div>
                            <div className={[styles['products-tabs-area'], 'col-md-12 col-lg-12'].join(' ')}>
                                <Tabs className={[styles['products-tab'],'row'].join(' ')}>
                                     
                                    <TabList className={[styles['tab-names'],'tab-names col-lg-10 col-md-12 col-4 offset-lg-1'].join(' ')}>
                                        <Tab> <span>Automobile</span></Tab>
                                        <Tab> <span>Fashion</span></Tab>
                                        <Tab> <span>Footwear</span></Tab>
                                        <Tab> <span>Product</span></Tab>
                                        <Tab> <span>Fashion Rendering</span></Tab>
                                        <Tab> <span>Home Furnishing</span></Tab>
                                        <Tab> <span>General Merchandise</span></Tab> 
                                     </TabList>

                                     <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''],'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/car.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Creating Studio-Setting Images in 3 Seconds!</h3>
                                                <p>Car dealerships globally use this AI tool to turn poor car images into professional studio-setting images at 90% lower cost. </p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Transform car listing images in a click</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Use custom backgrounds to change the car walls</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Blur car number plates & clean windows instantly</span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    {/* Fashion Tab content  */}
                                    <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-12 col-md-5'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/fashion.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Create Marketplace-Ready Images in a Click</h3>
                                                <p>Spyne’s AI tool for e-commerce allows you to create high-quality fashion photos in minutes. </p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Instantly generate marketplace complaint images</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Choose from various basic and creative backgrounds</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Edit 10,000+ images/day to scale your requirements</span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    {/* Footwear Tab content  */}
                                    <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/footwear.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Single-Click to Awesome Footwear Images</h3>
                                                <p>Edit your footwear images in seconds and make your products live faster than ever!</p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Create market-place ready shoes images in seconds</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Auto margining, scaling & shadow creation at speed</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Replace your background to white or gray in a click</span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    {/* Product Tab content  */}
                                    <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/product.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Cutting-Edge Tech to Edit Images at Speed</h3>
                                                <p>Edit your product images in bulk with AI, and make your cataloging faster.</p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Create product images for any marketplace in a click</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Single-click to change your background</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Cut your post-production time by 70%</span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                     {/* Fashion Rendering Tab content  */}
                                     <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/fashion-rendering.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Create Photo Realistic Visuals with AI-Powered Draping</h3>
                                                <p>Convert unstitiched fabric photos into high-quality model images with creative lifestyle setting. </p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Use sample photos of your fabric to generate model images</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Choose from various custom backgrounds</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>No need to stitch samples for cataloguing </span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                     {/* Home Furnishing Tab content  */}
                                     <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/home-furnishing.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Make Your Home Decor Products Stand out with Instant AI Rendering</h3>
                                                <p>Create stunning visuals with instant draping of your home decor products </p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Instanly render your marketplace-ready product images </span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Various creative templates & backgrounds to choose from</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Improve your visuals with creative lifestyle drapes </span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    {/* General Merchandise Tab content  */}
                                    <TabPanel className={[styles['tab-content-area'],'col-lg-10 col-md-12 offset-lg-1 col-8 display-none'].join(' ')}>
                                        <div className={[styles[''], 'row m-0'].join(' ')}>
                                            <div className={[styles['product-image'], 'col-lg-5 col-md-5 col-lg-5 col-12'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/gernal-merchand.gif"/>
                                            </div>
                                            <div className={[styles['product-content'], 'offset-lg-1 col-md-7 col-lg-6 col-12'].join(' ')}>
                                                <h3>Create Awesome Images with AI</h3>
                                                <p>Let Spyne’s AI tool help you edit your images and modernize you post-production workflow</p>
                                                <ul>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Scale you visual requirements 3x faster</span></li>
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>Cut your post production time by 70%</span></li> 
                                                    <li><img src="https://storage.googleapis.com/spyne-website/Affiliate-page/check.png"/> <span>One-click to download your marketplace complaint images.</span></li>
                                                </ul>
                                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </section>

            {/* Benefits of Being a Partner */}
                <section className={[styles['benefits-partner'], ''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-md-12'].join(' ')}>
                                <h2>Benefits of Being an Affiliate Partner  with Spyne!</h2>
                            </div>
                            <div className={[styles['benefit-cols'], 'col-md-3 col-sm-6 col-12'].join(' ')}>
                                <div className={[styles['benefits-single'], 'row m-0'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/commision.png"/>
                                    <h4>Earn 20% Recurring Commission  </h4>
                                    <p>From each sale that you refer to us, you'll earn upto 20% recurring commission. This means by sharing our affiliate link with your right audience you can create easy side hustle and keep your income flowing (without much effort). </p>
                                </div>
                                
                            </div>
                            
                            <div className={[styles['benefit-cols'], 'col-md-3 col-sm-6 col-12'].join(' ')}>
                                <div className={[styles['benefits-single'], 'row m-0'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/marketing.png"/>
                                    <h4>Free Marketing Content</h4>
                                    <p>We’ll provide you with everything you need to make your audience understand about our products. We’ll give all the content marketing materials so that you don’t have to worry about anything. </p>
                                </div>
                                
                            </div>
                            <div className={[styles['benefit-cols'], 'col-md-3 col-sm-6 col-12'].join(' ')}>
                                <div className={[styles['benefits-single'], 'row m-0'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/money.png"/>
                                    <h4>Track Your Money</h4>
                                    <p>Our easy to use dashboard will provide you access to all your earnings and the referrals. You can effortlessly track your progress and payments at one palace.  </p>
                                </div>
                                
                            </div>
                            <div className={[styles['benefit-cols'], 'col-md-3 col-sm-6 col-12'].join(' ')}>
                                <div className={[styles['benefits-single'], 'row m-0'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/payout.png"/>
                                    <h4>Free Spyne Credits</h4>
                                    <p>Spyne will give you access to Spyne Credits so that you can try our tech and give demos, if required!</p>
                                </div>
                                
                            </div>
                            <div className={[styles['mob-esponsive'], 'col-md-12 text-center mt-5'].join(' ')}>
                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                            </div>
                        </div>
                    </div>
                </section>

        {/* Spyne Partner? */}
                <section className={[styles['who-can-be'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-md-12'].join(' ')}>
                                <h2>Who Can Be a Spyne Partner?</h2>
                                {/* <p>You can be a Spyne partner if you are: </p> */}
                            </div>
                            <div className={[styles['image'], 'col-md-12'].join(' ')}> 
                                <img className={[styles[''], 'd-md-block d-none'].join(' ')} src="https://storage.googleapis.com/spyne-website/Affiliate-page/who-can1.svg"/>
                                <img className={[styles[''], 'd-md-none d-block'].join(' ')} src="https://storage.googleapis.com/spyne-website/Affiliate-page/who-can2.svg"/>
                            </div>
                             
                            <div className={[styles[''], 'col-md-12 text-center mt-5'].join(' ')}>
                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* //Spyne Partner? */}

                <section className={[styles['who-can-be'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-lg-8 offset-lg-2 col-md-12'].join(' ')}>
                                <h2>How to Become an Affiliate Partner with Spyne?</h2>
                                <p>Kick-start your new revenue stream by helping us reach the right audience!</p>
                            </div>
                            <div className={[styles['image'], 'col-md-12'].join(' ')}> 
                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/how-to.svg"/>
                            </div>
                             
                            <div className={[styles[''], 'col-md-12 text-center mt-5'].join(' ')}>
                                <button onClick={this.showForm} className={[styles['become-a-partner']]}>Become a Partner <span>&#x2192;</span></button>
                            </div>
                        </div>
                    </div>
                </section>
            {/* //How to become an affiliate partner */}

            <section className={[styles['affiliate-accord'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-lg-8 offset-lg-2 col-md-12'].join(' ')}>
                                <h2>FAQs: Have Questions? Here are Some Answers That can Help!</h2> 
                            </div>
                            <div className={[styles['affiliate-accordion'], 'affiliate-accordion col-md-12'].join(' ')}> 
                            <Accordion allowZeroExpanded preExpanded={['a']}>
                                <AccordionItem uuid="a" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. What is Spyne and how does it work?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>Spyne is an AI-driven tech company that builds cutting-edge, industry-first automatic image editing tools. We use tech based tools to help businesses with 100% automatic image editing in seconds. </p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="b" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. How much money will I earn? </h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  >  
                                                <p>Each time you refer a potential client to us and that client makes a purchase, you get 20% commission.</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="c" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. Do I need to be a spyne user to be an affiliate?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>Not necessarily, but the more you know about our tech, the better!</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="d" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. How do I get paid?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>We follow a 30 days time period, which means you’ll be credited 30 days after your referral makes a purchase. </p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                
                                <AccordionItem uuid="f" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. Why Promote SpyneAI?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>SpyneAI is a revolutionary priopotairy AI tool that helps car dealerships, e-commerce businesses create studio-setting images in seconds, 100% automatically. This tool can save businesses about 70% of their post-production time and 50-90% their cost.</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="g" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. How can I promote Spyne?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>You can promote Spyne in your blogs, tech reviews, videos, reels, emails, newsletter and any other form of content that you write. However, make sure you share it with the right audience to drive conversions. </p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="h" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. How much can I earn?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>You’ll earn 20% recurring commission for every you bring to us via your affiliate code. There’s no cap on earnings. The more sales you refer to, the more you earn.</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="i" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. Is there any joining fee?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>Spyne affiliate program is completely free to join – and you don’t have to pay any fee at all. Simply sign up for a free Spyne account to become a Spyne partner.</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="j" className={[styles['affiliate-item']]}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={[styles['qa-question'],'qa-question'].join(' ')}>
                                            <h6>Q. Can I become an affiliate?</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className= {[styles[''],'col-md-12'].join(' ')}  > 
                                            <div className= {[styles['qa-description'],'row m-0'].join(' ')}  > 
                                                <p>Yes, if you have the right audience to pitch our products and the services.</p>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                                        
                            </div>
                           
                        </div>
                    </div>
                </section>
            {/* //How to become an affiliate partner */}
                {/* Enjoy a Lifetime  */}

                <section className={[styles['enjoy-lifetime'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles['enjoy-row'], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-md-7'].join(' ')}>
                                <h2>Enjoy a Lifetime of Earnings with the Spyne <span>Partner Program!</span></h2>
                                <button onClick={this.showForm} className={[styles['become-a-partner'],''].join(' ')}>Become a Partner <span>&#x2192;</span></button>
                            </div>
                            <div className={[styles['enjoy-image'], 'col-md-5'].join(' ')}>  
                                <img className={[styles['dollar-img']]} src="https://storage.googleapis.com/spyne-website/Affiliate-page/dollar.png"/>
                                <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/lifetime.png"/>  
                            </div>
                            
                        </div>
                    </div>
                </section>
            {/* //Enjoy a Lifetime  */}

            <section className={[styles['page-break'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['heading'], 'col-md-8 offset-md-2'].join(' ')}>
                                <h5>Have more questions? We'd love to help!<span> Please contact us at <a href="mailto:affiliates@spyne.ai">affiliates@spyne.ai</a></span></h5>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>
            {/* //Enjoy a Lifetime  */}
            </div>
            
        )
    }
};










