import React, { Component } from 'react'   

import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery"; 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; 
import Styles from '../../../Css/CarV2.module.css'
import styles from '../../../Css/TrustedCars.module.css'


export default class TrustedCars extends Component {
    constructor(props){
        super(props)
        this.state={
            activateButton:"Mobile1",
            mobileTab: "Mobile1", 
        }
        this.myDivToFocus = React.createRef() 
    } 
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    handleMobileTab = (name) => {
        this.setState({
            mobileTab: name
        })
    }

    applyChange = (e) => {
        // window.scrollTo(0,500) 
         this.myDivToFocus.current.scrollIntoView() 
        // window.scrollTo({
        //     bottom: this.myDivToFocus.current.offsetBottom,
        //     behavior: 'smooth'     
        // })
      }
  
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
      }
      componentWillUnmount() {
        // whatever you need to cleanup the widgets code
      }
 
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // vertical: true,
            // verticalSwiping: true,
            swipeToSlide: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 7000
          };
         
        return (
        
             <div className={[Styles['bg-white']]}>
               
               <section className={[Styles['banner-slider'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[styles['banner-text'],'col-md-5'].join(' ')}>  
                               <h1>Get Marketplace Ready Images</h1> 
                               <p> In a <span>Single Click</span> using AI</p> 
                                <p><a target="_blank" href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo'],'d-md-block d-none'].join(' ')}>Contact Us</a></p>
                             </div>
  
                            <div className={[Styles['banner-gif'],'col-md-7'].join(' ')}>  
                               <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/banner-mac.png" />
                               <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/car-rotate-gif.gif" className={Styles['mac-gif']} />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Banner Slider */}

                <section className={[Styles['car-v2-tabs'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title-area'],'col-md-12'].join(' ')}>
                               <h1>Cover all 360 view of  Automobile</h1>
                               <p><a target="_blank" href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo']]}>Contact Us</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Section-2 */}
                <section className={[Styles['why-section'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            
                                <nav className={[Styles['car-v2-left'],'col-md-6'].join(' ')} id="spy">
                                    <div className={[Styles['scroll-tab'],'row m-0'].join(' ')}>
                                        <div className={[styles['why-use'],'row d-md-block d-none '].join(' ')}>
                                            <h1><span>Why Use T</span>rusted Cars App</h1>
                                            <p>With Trusted Cars anyone can create Studio-quality 360-degree visuals of cars. </p>
                                        
                                        </div>
                                        <ul className={[styles['why-tabs'],'flex-column '].join(' ')}>
                                            <li className={[styles[' '],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile1" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile1")}> 
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892395.png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}><h6>Shoot</h6>    
                                                    <p>Standardise photo clicking according to E-commerce websites solved through overlays & Guidlines</p>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className={[Styles[''],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile2" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile2")}> 
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892397.png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}> <h6>Edit</h6>    
                                                    <p>Smart Editing with inbuilt tools for best output of your car images</p>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className={[Styles[''],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile3" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile3")}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892399.png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}><h6>Publish</h6>    
                                                    <p>Upload your Car's perfect images and 360-degree visuals on E-commerce Marketplaces</p>
                                                    </div>
                                                </button>
                                            </li>
                                            
                                        </ul>
                                        <div className={[Styles['why-use'],'row'].join(' ')}>
                                        <p><a href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo']]}>Contact Us</a>
                                           
                                            </p> 
                                        </div>
                                    </div>
                                </nav>

                                <div className={[Styles['right-sections'],'col-md-5 offset-md-1'].join(' ')}>
                                        <div className={[Styles['why-use'],'row d-block d-md-none '].join(' ')}>
                                            <h1><span>Why Use S</span>pyne.ai App</h1>
                                            <p>With SpyneApp anyone can create Studio-quality 360-degree visuals of cars. </p> 
                                        </div>
                                    <div  className={[Styles['first-tab-content'],'row m-0 section'].join(' ')} >  
                                        { 
                                        this.state.activateButton == "Mobile1" ?   
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Group%205892385.png" />
                                        :
                                        this.state.activateButton == "Mobile2" ? 
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/iPhone%2012%20Pro-3.png" />
                                        :
                                        this.state.activateButton == "Mobile3" ?
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/3rd-point-final.gif" />
                                        :
                                        null
                                        }
                                    </div>
                                    
                                
                            </div>
                         
                        </div>
                    </div>
                </section>
        {/* Tabs On scroll section */}
             
            <section className={[Styles['custom-backgrounds'],'custom-backgrounds'].join(' ')}>
                <div className={[Styles[''],'container'].join(' ')}>
                    <div className={[Styles['exterior-tabs'],'row'].join(' ')}>
                        <div className={[styles['title'],'col-md-12'].join(' ')}>
                            <h1><span>Custom </span>Background</h1>
                            <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                <TabList className={[Styles['virtual-images'],Styles['v-image'],'col-md-2 col-12'].join(' ')}>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/1%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/2%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/3%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/4%20wall.jpg" /></Tab>
                                </TabList>


                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-3.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-1.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-2.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-4.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],Styles['side-tabs'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-3.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-1.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-2.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-4.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-11.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-12.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-13.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-14.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-11.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-12.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-13.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-14.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-21.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-22.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-23.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-24.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-21.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-22.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-23.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-24.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-31.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-32.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-33.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-34.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-31.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-32.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-33.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-34.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                
                            </Tabs>
                            <div className={[Styles['title'],'col-md-12  d-block'].join(' ')}>
                            <p><a target="_blank" href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo']]}>Contact Us</a></p>
                            </div>
                        </div>
                     </div>
                </div>
            </section>
            
            {/* Custom Backgrounds Section  */}
                <section className={[Styles['gif-area'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[styles['title'],'col-md-12'].join(' ')}>
                               <h1><span>Get Bulk  </span> Marketplace <span className={[Styles['no-border']]}>ready images</span></h1>  
                            </div>

                            <div className={[Styles['mac-pro-image'],'col-md-8 offset-md-2'].join(' ')}>
                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group-5892393.gif" />
                                <p><a target="_blank" href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo']]}>Contact Us</a></p>
                            </div>

                        </div>
                    </div>
                </section>
                {/* Gif Mac PRo Section          */}
            <section className={[Styles['custom-backgrounds'],'custom-backgrounds'].join(' ')}>
                <div className={[Styles[''],'container'].join(' ')}>
                    <div className={[styles['exterior-tabs'],'row'].join(' ')}>
                        <div className={[styles['title'],'col-md-12'].join(' ')}>
                            <h1><span>Virtual </span>Showroom</h1>
                            <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                <TabList className={[Styles['slates-tabs'],'col-md-12'].join(' ')}>
                                    <Tab><span>Bustling Slate</span></Tab>
                                    <Tab><span>Brick Slate</span></Tab>
                                    <Tab><span>Artistic Slate</span></Tab>
                                </TabList>

                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                                <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                    <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" /></Tab>
                                                    </TabList>

                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" />
                                                    </TabPanel>
                                                </Tabs>
                                            </TabPanel>
                                    
                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                        <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                            <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" /></Tab>
                                            </TabList>

                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    
                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                            <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" /></Tab>
                                                </TabList>

                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" />
                                                </TabPanel>
                                            </Tabs>
                                        </TabPanel>
                                        
                                    </Tabs>
                            <div className={[Styles['title'],'col-md-12  d-block'].join(' ')}>
                                <p><a target="_blank" href="https://bn3jl50l830.typeform.com/to/abH5C2sc" className={[styles['request-demo']]}>Contact Us</a></p>
                            </div>
                        </div>
                     </div>
                </div>
            </section>
         
            {/* Virtual Backgrounds Section  */}

                <section className={[styles['graph-area'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[styles['title'],'col-md-6'].join(' ')}>
                               <h1>  86% <span className={[Styles['no-border']]}>Shoppers browse cars  online before buying </span></h1>  
                               <p><a href="https://bn3jl50l830.typeform.com/to/abH5C2sc" target="_blank" className={[styles['request-demo']]}>Contact Us</a></p>
                            </div>
                            <div className={[Styles['graph-image'],'col-md-6'].join(' ')}>
                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892367.png" />  
                            </div>
                        </div>
                    </div>
                </section>
            {/* Graph Section  */}
   
            </div>
           
        )
    }
}
