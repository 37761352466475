import React, { Component } from 'react';
import LeftMenu from './leftMenu';
import TableSection from './tableSection';
import {getCookie,cookie} from '../Common/Utils'
import Axios from 'axios'


class AppDashboard extends Component {
    constructor(props){
        super(props)
        this.state={
            auth_key: "",
            userId : "",
            enterpriseId: "",
            emailId : "",
        }
    }

    componentDidMount = () => {
        let auth_key = cookie('ssoToken')
        let enterprise_id = cookie('enterpriseId')
        if(auth_key){
            this.setState({
                auth_key : auth_key,
                enterpriseId:enterprise_id
            })
        }else{
            window.location.replace("https://www.spyne.ai/butler/login")
        }

        this.getUserData()
    }

    getUserData = () => {
        Axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/user/get",
            params : {
               auth_key: getCookie('ssoToken')
            }
            
        }).then(resp =>{
            if(resp.data?.status == 200){
                this.setState({
                    userId : resp.data.data.user_id,
                    // enterpriseId : resp.data.data.enterprise_id,
                    emailId : resp.data.data.email_id,
                })
            }
        })
    }

    logout = () =>{
        const fd = new FormData()
        fd.append('email_id', this.state.emailId)
        fd.append('auth_token', this.state.auth_key)
        Axios({
            url: `${process.env.REACT_APP_BASEURL}/v2/user/logout`,
            method: 'POST',
            data: fd
        })
        .then((resp) => {
            if (resp.data?.status == 200) {
                // this.context.loggedOut({
                //     loggedIn: false,
                //     user: {},
                //     token: ''
                // })
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'ssoToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'enterpriseId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.replace("https://www.spyne.ai/butler/login")
            }
        })
        
    }

    overview = () => {
        window.location.replace("https://www.spyne.ai/butler/dashboard?userId=" + this.state.userId + "&enterpriseId=" + this.state.enterpriseId)
    }

    users = () => {
        window.location.replace("https://www.spyne.ai/butler/dashboard/enterprise-users?userId=" + this.state.userId + "&enterpriseId=" + this.state.enterpriseId)
    }

    shoots = () => {
        window.location.replace("https://www.spyne.ai/butler/dashboard/enterprise-shoot?userId=" + this.state.userId + "&enterpriseId=" + this.state.enterpriseId)
    }

    render() { 
        return (
            <div >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            <LeftMenu 
                                logout={this.logout}
                                overview={this.overview}
                                shoots={this.shoots}
                                users={this.users}
                                enterpriseId={this.state.enterpriseId}
                            />
                        </div>
                        <div className="col-md-10">
                            <TableSection 
                                enterpriseId={this.state.enterpriseId}
                            />
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}
 
export default AppDashboard;