import React, { Component } from 'react'
import styles from '../../Css/Footer.module.css'

export default class Footer extends Component {
    render() {
        return (
            <>
            <section className={[styles['footer-main'],' '].join(' ')}>
                <div className={[styles[' '],'container-fluid'].join(' ')}>
                    <div className={[styles[' '],'row'].join(' ')}>
                        <div className={[styles['footer-logo'],'col-lg-4 col-sm-12 col-md-4 col-12'].join(' ')} >
                            <img className={[styles["footer-logo-image"],'img-fluid'].join(' ')} src="https://images.spyne.ai/landing-page/home/logo/Spyne-White-Full-Logo.png"></img>
                        </div>
                        
                        <div className={[styles[' '],'col-lg-8 col-sm-12 col-md-8 col-12'].join(' ')}>
                            <div className={[styles['footer-links'],'row'].join(' ')}>
                                <div className={[styles['footer-links'],'col-lg-3 col-sm-3 col-md-3 col-6'].join(' ')} >
                                    <h2>Product</h2>
                                    <p><a href="https://www.spyne.ai/tech/">Spyne.ai</a></p>
                                    <p><a href="https://www.spyne.ai/tech/cars/demo/">Spyne AI - Cars <span > new</span></a></p>
                                    <p><a href="https://www.spyne.ai/tech/footwear/demo/">Spyne AI - Footwear <span > new</span></a></p> 
                                    {/* <p><a href="https://www.webbr.ai/" target="_blank">Webbr.ai</a></p> */}
                                    <p><a href="https://www.clippr.ai/" target="_blank">Clippr.ai</a></p>
                                    <p><a href="https://www.albumm.ai/" target="_blank">Albumm.ai</a></p>
                                    {/* <p><a href="https://www.spyne.ai/photographer" target="_blank">Studioo</a></p> */} 
                                </div>
                                <div className={[styles['social'],'col-lg-3 col-sm-3 col-md-3 col-6'].join(' ')} >
                                    <h2>Company</h2>
                                    <p><a href="https://www.spyne.ai/about-us" target="_blank">About Us </a></p>
                                    <p><a href="https://www.spyne.ai/content/photos/our-work" target="_blank">Our Work</a></p>
                                    <p><a href="https://angel.co/company/spyne-3/jobs" target="_blank">Career</a></p>
                                    <p><a href="https://www.spyne.ai/photographer" target="_blank">Photographers</a></p>
                                    <p><a href="https://www.spyne.ai/marketplaces" target="_blank">Marketplaces</a></p>
                                </div>
                                <div className={[styles['social'],'col-lg-3 col-sm-3 col-md-3 col-6'].join(' ')} >
                                    <h2>Support</h2>
                                    <p><a href="https://www.spyne.ai/cookie-policy" target="_blank">Cookie Policy</a></p>
                                    <p><a href="https://www.spyne.ai/terms-service" target="_blank">Terms of service</a></p>
                                    <p><a href="https://www.spyne.ai/data" target="_blank">Data Processing Addendum</a></p> 
                                    <p><a href="https://www.spyne.ai/privacy" target="_blank">Privacy Policy</a></p>
                                </div>
                                <div className={[styles['social'],'col-lg-3 col-sm-3 col-md-3 col-6'].join(' ')} >
                                    <h2>Install App</h2>
                                    <h6><a href="https://spyneai.page.link/download" target="_blank">
                                        <img className={[styles['play-store-badge'],'img-fluid'].join(' ')} src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0"/>
                                    </a>
                                    </h6>
                                    <h6><a href="https://spyneai.page.link/iosapp" target="_blank" className={[styles['request-demo2'],styles['applestore'],''].join(' ')}>
                                    <img alt="" className={[styles['play-store-badge'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                               
                                    </h6>
                                </div>
                        {/* <div className={[styles['news-letter'],'col-lg-4 col-sm-12 col-md-12 col-12'].join(' ')} >
                                <p>Subscribe to our newsletter</p>
                                <input placeholder="Enter your Email"></input>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={[styles['footer-bottom'],' '].join(' ')}>
                <div className={[styles[' '],'container-fluid'].join(' ')}>
                    <div className={[styles[' '],'row'].join(' ')}>
                        <div className={[styles[' '],'col-md-8'].join(' ')}>
                            <p>@2021 Spyne.ai All rights reserved | <a href="tel:8644864461"></a> Tel: +91-8644 8644 61</p>
                        </div>

                        <div className={[styles['social-links'],'col-md-4'].join(' ')}>
						 
							<ul className={[styles['footer-copyright'],''].join(' ')}>
								<li>
									<a href="https://www.facebook.com/spyneai/">
									    <img class="social-logo-footer" src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/icons/Group%20157.png" />
									</a>
								</li>
								
								<li><a href="https://www.instagram.com/spyne.ai/">
									    <img class="social-logo-footer" src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/icons/Instagram.png" />
                                    </a>
                                </li>
								
								
								<li><a href="https://twitter.com/spyne_ai?lang=en">
									    <img class="social-logo-footer" src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/icons/Twitter.png" />
                                    </a>
                                </li> 
								
								<li>
                                    <a href="https://www.youtube.com/channel/UCXFGiawbLL2pBWI84VY5sJw">
									    <img class="social-logo-footer" src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/icons/Youtube.png" />
                                    </a>
                                </li>
								 
							</ul>
						 
					</div>

                    </div>
                </div>
            </section>
            </>
        )
    }
}
