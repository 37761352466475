import React, { Component } from 'react' 
import Toggle from 'react-toggle'
import '../../../App.css' 
import Slider from "react-slick"; 
import { InlineWidget } from "react-calendly";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import {Helmet} from "react-helmet"; 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import LightSliderBox from '../Cars/LightSliderBox'
import Styles from '../../../Css/CarV2.module.css'


export default class Car extends Component {
    constructor(props){
        super(props)
        this.state={
             
            activateButton:"Mobile1",
            mobileTab: "Mobile1",
            activButton: "Exterior",
            exteriorTab: "Exterior",
            
            
        }
        this.myDivToFocus = React.createRef()
        
    }
   
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    
    handleMobileTab = (name) => {
        this.setState({
            mobileTab: name
        })
    }
    handleActivButton = (name) => {
        this.setState({
            activButton:name
        })
    }
    handleExteriorTab = (name) => {
        this.setState({
            exteriorTab: name
        })
    }
   


    applyChange = (e) => {
      
         this.myDivToFocus.current.scrollIntoView() 
        
      }
 

     

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
      }
      componentWillUnmount() {
        // whatever you need to cleanup the widgets code
      }

    
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // vertical: true,
            // verticalSwiping: true,
            swipeToSlide: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 7000
          };
         
        return (
            // <SmoothProvider skew={true} >
            <div className={[Styles['bg-white']]}>
               {/* SEO tags Start*/}


                <Helmet>
                    <title>Get Your Car Background Change With Car Photo Editor | Spyne</title>
                    <meta property="og:title" content="Get Your Car Background Change With Car Photo Editor | Spyne"/>
                    <meta property="og:description" content="Select Spyne For Car Photo Editing and Replace Your Car Background in Easy Steps. Get Car Photo Editor Online and Stand Out In The Market With AI-Driven Images."/>
                </Helmet>

                {/* SEO tags end */}
                 <section className={[Styles['banner-slider'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['banner-text'],'col-md-6 col-lg-5 col-sm-5'].join(' ')}>  
                               <h1>Get Marketplace Ready Images</h1> 
                               <p> In a <span>Single Click</span> using AI</p> 
                                <p><button onClick={this.applyChange}  className={[Styles['request-demo'],'d-md-block d-none'].join(' ')}>Request Demo</button></p>
                                <p><a  href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                    alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                </a>
                                </p>
                            </div>
                            <div className={[Styles['banner-gif'],'col-md-6 col-lg-7 col-sm-7'].join(' ')}>  
                               <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/banner-mac.png" />
                               <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/car-rotate-gif.gif" className={Styles['mac-gif']} />
                            </div>
                        </div>
                    </div>
                

                </section>


                {/* Banner Slider */}

                <section className={[Styles['car-v2-tabs'],'d-lg-block d-none d-md-none d-sm-none'].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title-area'],'col-md-12'].join(' ')}>
                               <h1>Cover all 360 view of  Automobile</h1>
                               <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button> &nbsp;
                               <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                    alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Section-2 */}
                <section className={[Styles['why-section'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <nav className={[Styles['car-v2-left'],'col-md-6'].join(' ')} id="spy">
                                <div className={[Styles['scroll-tab'],'row m-0'].join(' ')}>
                                    <div className={[Styles['why-use'],'row d-md-block d-none '].join(' ')}>
                                        <h1><span>Why Use S</span>pyne.ai App</h1>
                                        <p>SpyneApp is an AI based technology application that helps you capture and create studio-style car images instantly using your smartphone </p>
                                    </div>
                                    <ul className={[Styles['why-tabs'],'flex-column '].join(' ')}>
                                        <li className={[Styles[' '],''].join(' ')}>
                                            <button className={this.state.activateButton == "Mobile1" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile1")}> 
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892395.png" />
                                                <div className={[Styles['why-text'],''].join(' ')}><h6>Shoot</h6>    
                                                <p>App’s smart overlays will help you shoot the best car angles. No photography skills required</p>
                                                </div>
                                            </button>
                                        </li>
                                        <li className={[Styles[''],''].join(' ')}>
                                            <button className={this.state.activateButton == "Mobile2" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile2")}> 
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892397.png" />
                                                <div className={[Styles['why-text'],''].join(' ')}> <h6>Edit</h6>    
                                                <p>The SpyneAI App helps you turn your captured car images into showroom-like images in a few minutes, 100% automatically</p>
                                                </div>
                                            </button>
                                        </li>
                                        <li className={[Styles[''],''].join(' ')}>
                                            <button className={this.state.activateButton == "Mobile3" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile3")}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892399.png" />
                                                <div className={[Styles['why-text'],''].join(' ')}><h6>Publish</h6>    
                                                <p>Instantly generate 360-degree car videos and studio-style images that are dealership and car marketplace ready</p>
                                                </div>
                                            </button>
                                        </li>
                                        
                                    </ul>
                                    <div className={[Styles['why-use'],'row'].join(' ')}>
                                    <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button>
                                        <a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}><img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a>
                                        
                                        <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                                        <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                            <img className={[Styles[''],'mt-3 m-0'].join(' ')} src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                            alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                        </a>
                                        </p> 
                                    </div>
                                </div>
                            </nav>

                            <div className={[Styles['right-sections'],'col-md-5 offset-md-1'].join(' ')}>
                                    <div className={[Styles['why-use'],'row d-block d-md-none '].join(' ')}>
                                        <h1><span>Why Use S</span>pyne.ai App</h1>
                                        <p>With SpyneApp anyone can create Studio-quality 360-degree visuals of cars. </p> 
                                    </div>
                                <div  className={[Styles['first-tab-content'],'row m-0 section'].join(' ')} >  
                                    { 
                                    this.state.activateButton == "Mobile1" ?   
                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Group%205892385.png" />
                                    :
                                    this.state.activateButton == "Mobile2" ? 
                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/iPhone%2012%20Pro-3.png" />
                                    :
                                    this.state.activateButton == "Mobile3" ?
                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/3rd-point-final.gif" />
                                    :
                                    null
                                    }
                                </div>
                           </div>
                         
                        </div>
                    </div>
                </section>

        {/* Custom BG section */} 
            <section className={[Styles['custom-backgrounds'],''].join(' ')}>
                <div className={[Styles[''],'container'].join(' ')}>
                    <div className={[Styles['exterior-tabs'],'row'].join(' ')}>
                        <div className={[Styles['title'],'col-md-12'].join(' ')}>
                            <h1><span>Custom </span>Background</h1>
                            <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                <TabList className={[Styles['virtual-images'],Styles['v-image'],'col-md-2 col-12'].join(' ')}>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/1%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/2%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/3%20wall.jpg" /></Tab>
                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/4%20wall.jpg" /></Tab>
                                </TabList>
 
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-3.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-1.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-2.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-4.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],Styles['side-tabs'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-3.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-1.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-2.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-4.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-11.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-12.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-13.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-14.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-11.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-12.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-13.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-14.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-21.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-22.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-23.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-24.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-21.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-22.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-23.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-24.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                <TabPanel className={[Styles['virtual-tabs'],'col-md-10 col-12 display-none'].join(' ')}>
                                    <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-31.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-32.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-33.png" />
                                        </TabPanel>
                                        <TabPanel className={[Styles['virtual-images'],'col-md-10 col-12 display-none'].join(' ')}>
                                            <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-34.png" />
                                        </TabPanel>

                                        <TabList className={[Styles['virtual-images'],'col-md-2 col-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-31.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-32.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-33.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/c-34.png" /></Tab>
                                        </TabList>
                                    </Tabs>
                                </TabPanel>
                                
                                
                            </Tabs>
                            <div className={[Styles['title'],'col-md-12  d-block'].join(' ')}>
                                <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button>
                                    &nbsp;
                                    <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                        <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                        alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                    </a>    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* End Custom Backgrounds Section  */}

                <section className={[Styles['gif-area'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                            <h1><span>Get Bulk  </span> Marketplace <span className={[Styles['no-border']]}>ready images</span></h1>  
                           </div>
                            <div className={[Styles['mac-pro-image'],'col-md-8 offset-md-2'].join(' ')}>
                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group-5892393.gif" />
                                <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button>
                                &nbsp;
                                <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                    alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                </a>
                                </p>
                            </div> 
                        </div>
                    </div>
                </section>
                {/* Gif Mac PRo Section  */}
                <section className={[Styles['custom-backgrounds'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles['exterior-tabs'],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                                <h1><span>Virtual </span>Showroom</h1>
                                <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                    <TabList className={[Styles['slates-tabs'],'col-md-12'].join(' ')}>
                                        <Tab><span>Bustling Slate</span></Tab>
                                        <Tab><span>Brick Slate</span></Tab>
                                        <Tab><span>Artistic Slate</span></Tab>
                                    </TabList>

                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                        <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                            <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" /></Tab>
                                            </TabList>

                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    
                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                        <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                            <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" /></Tab>
                                            </TabList>

                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    
                                    <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                        <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                            <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" /></Tab>
                                                <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" /></Tab>
                                            </TabList>

                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" />
                                            </TabPanel>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    
                                </Tabs>
                                <div className={[Styles['title'],'col-md-12  d-block'].join(' ')}>
                                    <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button>
                                    &nbsp;
                                    <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                        <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                        alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                    </a>    
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                    {/* Virtual Backgrounds Section  */}

                        <section className={[Styles['graph-area'],' '].join(' ')}>
                            <div className={[Styles[''],'container'].join(' ')}>
                                <div className={[Styles[' '],'row'].join(' ')}>
                                    <div className={[Styles['title'],'col-md-6'].join(' ')}>
                                    <h1>  86% <span className={[Styles['no-border']]}>Shoppers browse cars  online before buying </span></h1>  
                                    <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Request Demo</button>
                                    &nbsp;
                                    <a className={[Styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                        <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                        alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                    </a>
                                    </p>
                                    </div>

                                    <div className={[Styles['graph-image'],'col-md-6'].join(' ')}>
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892367.png" />
                                    </div>

                                </div>
                            </div>
                        </section>
                    {/* End Graph Section  */}

                        <section className={[Styles['calendly-area'],' '].join(' ')} ref={this.myDivToFocus}>
                            <div className={[Styles[''],'container-fluid'].join(' ')}>
                                <div className={[Styles[' '],'row'].join(' ')}>
                                    <div className={[Styles['title'],'col-md-12'].join(' ')}>
                                    <h1> <span className={[Styles['no-border']]}>Earn more by </span> selling your car <span className={[Styles['no-border']]}> online using AI</span></h1>  
                                        <h6><span>Book a Demo</span> today to learn more!</h6>
                                    </div>

                                    <div className={[Styles['cars-demo '],'col-lg-8 col-md-7 col-sm-7'].join(' ')}>  
                                        <div className={[Styles['cars-video'],'row m-0'].join(' ')}>
                                            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/LV9sgTxsIgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    <div className={[Styles['cars-demo'],'col-lg-4 col-md-5 col-sm-5'].join(' ')}>  
                                        <div className={[Styles['cars-calender'],'row m-0'].join(' ')}>
                                            <h4>Book a Demo </h4>
                                            <InlineWidget className={[Styles['calendly-inline-widget'],'p-0'].join(' ')} styles={{"height": "100%"}} url="https://calendly.com/spyneai/spyne-car-ai-linkedin?hide_event_type_details=1&hide_gdpr_banner=1"  style={{"minwidth": "320px"}} />
                                            
                                        </div>
                                    
                                    </div>

                                </div>
                            </div>
                        </section>     
                    
                    {/* End Calendly Section */}

            

            </div>
            // </SmoothProvider>
        )
    }
}
