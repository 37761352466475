import React, { Component } from "react";
import styles from '../../Css/SuperAdmin/Admin.module.css'
import axios from 'axios'
import alertify from 'alertifyjs'
export default class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enterpriseList: [],
            email: "",
            unRegisteredEnterpriseList: []

        }
    }
    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]
   
   
    componentDidMount(){
        if(!this.cookie('userId')){
            window.location.href = `https://www.spyne.ai/sign-in?/sign-in?pageSrc=${window.location.href}`
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            email: e.target.value
        })
    }
    fetchUserData = async (e) => {
        e.preventDefault()
        await axios({
            'method': 'GET',
            'url': `${process.env.REACT_APP_BASEURL}/v2/enterprise/fetch-email-enterprise?email=${this.state.email}`,

        })
            .then(async response => {
                await this.setState({
                    enterpriseList: response.data.data
                })
            })
            .catch(() => {
                alertify.error("Something Went Wrong in Data Fetching")
            })
        this.fetchAllEnterpriseData()
    }
    fetchAllEnterpriseData = async (e) => {
        await axios({
            'method': 'GET',
            'url': `${process.env.REACT_APP_BASEURL}/v2/enterprise/fetch-all-enterprise`,

        })
            .then(async response => {
                await this.setState({
                    unRegisteredEnterpriseList: response.data.data
                })
            })
            .catch(() => {
                alertify.error("Something Went Wrong in Data Fetching")
            })
    }

    isRegistered = (e) => {
        let i = 0;
        for (i; i < this.state.enterpriseList.length; i++) {
            if (e.enterprise_id === this.state.enterpriseList[i].enterprise_id) {
                return false
            }
        }
        if (i === this.state.enterpriseList.length)
            return true
    }

    changeRole = (x, enterprise_id) => {
        let role = x.target.value
        axios({
            'method': 'GET',
            'url': `${process.env.REACT_APP_BASEURL}/v2/enterprise/update-single-role?email=${this.state.email}&enterprise_id=${enterprise_id}&role=${role}`
        })
            .then(() => {
                alertify.success('Updated Role Successfully')
            })
            .catch(() => {
                alertify.error("Something Went Wrong")
            })
    }
    changeUnRegisteredRole = (x, enterprise_id) => {
        let role = x.target.value
        axios({
            'method': 'GET',
            'url': `${process.env.REACT_APP_BASEURL}/v2/enterprise/insert-data-for-enterprise?email=${this.state.email}&enterprise_id=${enterprise_id}&role=${role}`
        })
            .then(() => {
                alertify.success('Updated Role Successfully')
            })
            .catch(() => {
                alertify.error("Something Went Wrong")
            })
    }
    render() {
        return (
            <div>
                <section className={[styles['admin-area'], ''].join(' ')}>
                    <div className="container">
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['admin-form'], 'col-md-6 offset-md-3 col-12 col-sm-10 offset-sm-1'].join(' ')}>
                                <form onSubmit={this.fetchUserData}>
                                    <label><strong>Email Id :</strong> <input type="text" name='email' placeholder="Email here..." onChange={this.handleChange} /></label>
                                </form>
                            </div>
                            <div className={[styles['enterprise-list']]}>
                                {this.state.enterpriseList.length > 0 ?
                                    <div className={[styles['table-area'], ''].join(' ')}>

                                        <h3>Registered</h3>

                                        <table>
                                            <tbody>
                                                {
                                                    this.state.enterpriseList.map((e, i) => {
                                                        let role = []
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <strong>{e.name}</strong></td>
                                                                <select defaultValue={e.role} onChange={(x) => this.changeRole(x, e.enterprise_id)}>
                                                                    <option>{e.role}</option>
                                                                    <option value="ADMIN">ADMIN</option>
                                                                    <option value="EDITOR">EDITOR</option>
                                                                    <option value="STANDARD">STANDARD</option>
                                                                </select>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.unRegisteredEnterpriseList.length > 0 ?
                                    <div className={[styles['table-area'], ''].join(' ')}>
                                        <h3>Unregistered</h3>
                                        <table>
                                            <tbody>
                                                {
                                                    this.state.unRegisteredEnterpriseList.map((e, i) => {
                                                        return (
                                                            this.isRegistered(e) ?
                                                                <tr>
                                                                    <td>
                                                                        <strong>{e.name}</strong></td>
                                                                    <select defaultValue={null} onChange={(x) => this.changeUnRegisteredRole(x, e.enterprise_id)}>
                                                                        <option value={null}>Select</option>
                                                                        <option value="ADMIN">ADMIN</option>
                                                                        <option value="EDITOR">EDITOR</option>
                                                                        <option value="STANDARD">STANDARD</option>
                                                                    </select>
                                                                    <td>
                                                                    </td>

                                                                </tr>
                                                                :
                                                                null
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}
