import React, { Component } from 'react';
import styles from '../../Css/AppDashboard/LeftMenu.module.css'
import 'font-awesome/css/font-awesome.min.css';


class LeftMenu extends Component {
    state = {  }
    render() { 
        return ( 
            <div className={[styles['left-menu'],"pt-3"].join(" ")}>
                
                    
                    <div className={[styles['sidebar-logo'],"row"].join(" ")}>
                        <div className="col-md-5">
                        {this.props.enterpriseId == 'e'?
                            <img style={{width:"0%"}} class={[styles['logo'],""].join(" ")} src="https://images.spyne.ai/vendors/website/vendorType-photographer/vendorId-1611437456885/photos/photoId-pyH65QNGyi1611437456885/pyH65QNGyi1611437456885_orig.jpg" />
                            :
                            <img class={[styles['logo'],""].join(" ")} src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png" />
                        }
                            
                        </div>
                        <div className="col-md-7 pt-3 ">
                            {this.props.enterpriseId == 'DaD1JC1Fp'?
                                 <span>Karvi</span>
                                :
                                <span>Spyne</span>
                            }
                           
                        </div>
                    </div>
               
                <div className={[styles['side-tab'],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")} onClick={this.props.overview}>
                        <span><i className="fa fa-home" aria-hidden="true"></i> Overview</span>
                    </div>
                </div>
                <div className={[styles['side-tab'],styles[''],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")} onClick={this.props.users}>
                        <span><i className="fa fa-users" aria-hidden="true"></i> Users</span>
                    </div>
                </div>
                <div className={[styles['side-tab'],styles[''],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")} onClick={this.props.shoots}>
                        <span><i className="fa fa-camera" aria-hidden="true"></i> Shoots</span>
                    </div>
                </div>
                <div className={[styles['side-tab'],styles['tab-active'],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")}>
                        <span><i className="fa fa-area-chart" aria-hidden="true"></i>Home API</span>
                    </div>
                </div>
                {/* <div className={[styles['side-tab'],styles[''],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")}>
                        <span><i className="fa fa-arrow-left" aria-hidden="true"></i> All Accounts</span>
                    </div>
                </div> */}
                <div className={[styles['side-tab'],styles[''],"row mt-5 p-4"].join(" ")} >
                    <div className={[styles['menu-icon'],"col-md-12"].join(" ")} onClick={this.props.logout}>
                        <span><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</span>
                    </div>
                </div>
            </div>
         ); 
    }
}
 
export default LeftMenu;