import React, { Component } from 'react'
import styles from '../../Css/dealerhipLogo.module.css'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

// This is the UI for Dealership Logo feature
// It takes 4 paramas in it
// 1. dealerLogoFileHandler --> This function will handle the image input from user
// 2. handleLogoPosition --> This function will handle the postion of logo to be placed
// 3. logoUrl --> This is the url of logo converted from fakepath
// 4. logoPosition --> This is the param which tells up where we have to place out logo on image


export default class NumberPlateLogo extends Component {
    render() {
        return (
            <div>
                
                <div className={[styles['dealership-logo-section-wrapper'],''].join(' ')} onChange={this.props.handleRadioLogo}>
                    <BrowserView>
                    <div className={[styles['right-mid-setion-content'],'row'].join(' ')}>
                        <p className={styles['logo-text']}>Hide Number Plate In The Car Image</p>
                        <label className={styles["big"]}>
                        <div className={styles['feature-position']}>
                            <input className={styles['radio-logo']} type="radio" value="blur" name="logo" autocomplete="off"/>
                            <p>Blur Number Plate</p>
                        </div>
                        </label>
                    </div>
                    

                    <div className={[styles['right-mid-setion-content'],'row'].join(' ')}>
                            <p className={styles['logo-text']}>Number Plate Logo <span></span></p>
                            

                            <div className={[styles['upload-logo-button'],'col-12 col-lg-6 col-md-5  text-left'].join(' ')}>
                            <label className={styles["big"]}>
                                <input className={styles['radio-logo']} type="radio" value="logo" name="logo" checked={this.props.checkUploadLogo}/>
                                <input 
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={this.props.dealerLogoFileHandler}
                                    ref={(fileInput) => (this.fileInput = fileInput)}
                                    ></input>
                                    <button  onClick={() => this.fileInput.click()} className={[styles['upload-button'],'btn'].join(' ')} disabled={!this.props.checkUploadLogo}>Upload Logo</button>
                                    </label> </div>
                            
                            <div className={[styles[''],'pl-0 col-md-4 col-lg-4 col-6'].join(' ')}>
                                
                                <div className={[styles['logo-image']]}>
                                    {this.props.logoUrl != '' ? 
                                    <img onClick={this.props.handleLogo} className={[styles['cross-logo']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                    : null
                                }
                                    
                                { this.props.logoUrl == ''?
                                    <p>Your Logo</p>
                                    :
                                    <img className="" src={this.props.logoUrl}></img>
                                }
                                </div>
                            </div>
                            
                            
                    </div>
                    </BrowserView>
                    <MobileView>
                    <div className={[styles['right-mid-setion-content'],'row '].join(' ')}>
                            <div className={[styles['logo-image'],'col-6'].join(' ')}>
                            { this.props.logoUrl == ''?
                                <p>Car Dealer logo</p>
                                :
                                <img className="" src={this.props.logoUrl}></img>
                            }
                            </div>
                            <div className={[styles['upload-logo-button'],'col-6'].join(' ')}>
                            <div className='d-flex'>
                                <input className={[styles['checkboxField'],''].join(' ')} type="checkbox" checked={this.props.logoPosition == 'leftTop' ? true : false}  onChange={this.props.handleLogoPosition} name="leftTop"></input>
                                <div style={{
                                    width:"2px",
                                    height:"40px",
                                    borderLeft:"1px solid grey",
                                    margin: "0 0px 0 5px"
                                }}></div>
                                <input className={[styles['checkboxField'],''].join(' ')} type="checkbox" checked={this.props.logoPosition == 'rightTop' ? true : false} onChange={this.props.handleLogoPosition} name="rightTop"></input>
                            </div>
                            <div style={{
                                width:"2px",
                                height:"40px",
                                borderLeft:"1px solid grey",
                                margin: "0 0px 0 5px"
                            }}></div>
                            <div style={{
                                width:"2px",
                                height:"40px",
                                borderLeft:"1px solid grey",
                                marginLeft:"50px",
                                marginTop:"-50px"
                            }}></div>
                            <div className='d-flex'>
                            <input className={[styles['checkboxField'],''].join(' ')} type="checkbox" checked={this.props.logoPosition == 'leftBottom' ? true : false} onChange={this.props.handleLogoPosition} name="leftBottom"></input>
                                <div style={{
                                     width:"30px",
                                     height:"2px",
                                     borderBottom:"1px solid grey",
                                     marginTop:"5px"
                                }}></div>
                            <input className={[styles['checkboxField'],''].join(' ')} type="checkbox" checked={this.props.logoPosition == 'rightBottom' ? true : false} onChange={this.props.handleLogoPosition} name="rightBottom"></input>
                            </div>
                        </div>
                    </div>
                    <div className={[styles['upload-logo-button'],''].join(' col-12')}>
                        <input 
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.props.dealerLogoFileHandler}
                            ref={(fileInput) => (this.fileInput = fileInput)}
                            ></input>
                            <button  onClick={() => this.fileInput.click()} className={[styles['upload-button'],'btn'].join(' ')} >Upload Logo</button>
                    </div>
                    </MobileView>
                    </div>
            </div>
        )
    }
}
