import React, { Component } from 'react'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'
import BackgroundListModalBulk from '../../Common/Modal/BackgroundListModalBulk'
import UploadLimitModal from '../../Common/Modal/UploadLimitModal'
import DealershipLogo from '../../Common/DealerShipLogo'
import BulkCreditModal from '../../Common/Modal/BulkCreditModal';
import ProjectNameModal from '../../Common/Modal/ProjectNameModal';
import RefundModal from '../../Common/Modal/RefundModal';
import Dropzone from 'react-dropzone'
import LogoCropModal from '../../Common/Modal/LogoCropModalcopy';
import NumberPlateLogo from '../../Common/NumberPlateLogo';



// import Logo from './Logo';
export default class BulkBikesBackground extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            orignalTab: "Original",
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
            activeButtonNew:"Edited",
            manual_retouch_show:''
          
        }
        
        
    }
    handleUnsatisfiedSection = (e) => {
        this.setState({
            manual_retouch_show:e
        })
        this.props.handleUnchecked()
    }
    hanldleChecked = (e) => {
        for(let i=0;i<this.props.imgId_for_retouch.length;i++){
            if(e==this.props.imgId_for_retouch[i][0]){
                return(true)
            }
        }
        return false
    }
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
            activeButtonNew:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }

      fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }


    render() {
        const orgCars = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /></span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>

        
            
        })
        const orgCarsUrl = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            
                            {/* <img src={carFile.url} /> */}
                            <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.url}>
                                     <img src={carFile.url} />
                            </LightgalleryItem>
                            {   carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : carFile.status == 'Uploading' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : null
                            }
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-6"><p>{carFile.name}</p></div>
                                { carFile.status == 'Done' || carFile.status == 'Error' ?
                                    <div className="col-6 text-right">
                                    
                                        {
                                            carFile.status == 'Done' ?
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                        : carFile.status == 'Error' ?
                                            <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                        : null
                                        }
                                        
                                        
                                    </div>
                                    : null
                                }
                                
                                
                            </div>
                            
                        </div>
                    </div>
        })
        const editedUrl = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            {this.props.bulkDownloadDone || carFile.status == 'Uploading' ? null : 
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                {this.state.manual_retouch_show=='delete'?
                                    <span><img onClick={() => this.props.getRefundModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /></span>
                                    :
                                    (this.state.manual_retouch_show=='retouch'?
                                        <span><input onChange={()=>this.props.handleRetouchImages(i)} type='checkbox' checked={this.hanldleChecked(i)}/></span>
                                        :
                                        null
                                    )
                                }
                                </div>
                            }
                                
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Uploading' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Error' ?
                                <img onClick={this.props.bulkUploadAPI(i)} style={{maxHeight: "80px"},{objectFit: "contain"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                            : carFile.status == 'Done' ?
                               
                                <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.fullScreenOutput}>
                                     <img src={carFile.outputImgUrl} />
                                </LightgalleryItem>
                            : null
                            }
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : carFile.status == 'Uploading' ?
                            <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : null
                            }
                            
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                                {/* <div className="col-6 text-right">
                                    
                                    { carFile.status == 'Done' ?
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                    : carFile.status == 'Error' ?
                                        <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                    : null
                                    }
                                    
                                    
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
        })
        const backLeft = this.props.planData.length - 8
        return (
            <div>
                <section className={[styles['perfect-output'],''].join(' ')}>
                 
                    <div className={[styles[''],''].join(' ')}>
                        {this.props.uploadStarteds ?
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                    <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                <div {...getRootProps({})} className={styles['dropzone1']}>
                                                    <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload New Project</button>
                                                    </div>
                                                    <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    
                                    <ul className={[styles['change-tabs'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activeButtonNew == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                                <span>Original</span>
                                            </button>
                                        </li>
                                        <li className={[styles[''],''].join(' ')}>
                                            <button className={this.state.activeButtonNew == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                                <span>Edited</span>   
                                            </button>
                                        </li>
                                    </ul>
                                
                                    {

                                        this.state.activeButtonNew == "Original" ?
                                        
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        <div className={styles['container-fluid']}>
                                            <div className="row">
                                                
                                                <LightgalleryProvider
                                                    lightgallerySettings={{
                                                        "download": false,
                                                        "thumbnail": false,
                                                        "fullScreen": true,
                                                        "zoom": true
                                                    }}
                                                >
                                                    {orgCarsUrl}
                                                </LightgalleryProvider>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    : this.state.activeButtonNew == "Edited" ?
                                    
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        <div className={styles['container-fluid']}>
                                            <div className="row">
                                            <LightgalleryProvider
                                                    lightgallerySettings={{
                                                        "download": false,
                                                        "thumbnail": false,
                                                        "fullScreen": true,
                                                        "zoom": true
                                                    }}
                                                >
                                                {editedUrl}
                                            </LightgalleryProvider>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                    }
                                
                                    
                                </div>
                                

                                <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                    <div className={styles['bulk-right-box']}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        <div className={[styles['right-top-credits'],'col-md-4'].join(' ')} >
                                            <a href="https://www.spyne.ai/tech/pricing"  >
                                            <button >
                                            {/* <img style={{"marginRight" : "4px"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add%20credits%20gif.gif"/> */}
                                            <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                            </a>
                                            </div>
                                    </div>
                                    
                                        
                                        
                                        <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}> 
                                                        
                                            <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                                <div className={[styles['background-grid'],'col-md-12'].join(' ')}> 
                                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                                        
                                                        {this.props.bulkDownloadDone ?
                                                            <div><a href={this.props.downloadZipLink} target="_blank"></a></div>
                                                            :
                                                            <div>
                                                                <h6>Unsatisfied with the Output ?</h6>
                                                                <input type="radio" id="retouch" name="manual retouch" onChange={() => this.handleUnsatisfiedSection('retouch')} value="retouch"/>
                                                                <label for="retouch"><p><b>Request Manual Retouch(<span>+1 credit</span>)</b></p></label>
                                                                <br/>
                                                                <input type="radio" id="delete" name="manual retouch" onChange={() => this.handleUnsatisfiedSection('delete')} value="delete"/>
                                                                <label for="delete"><p>Delete</p></label>
                                                                <p>Click
                                                                    <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/></span>
                                                                    on Images to  request refund  </p>
                                                            </div>
                                                            
                                                        }
                                                        
                                                    </div>
                                                    <div className={[styles['download-card-gif'],'row m-0'].join(' ')}>
                                                        {this.props.isZipReady  ?
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif"/>
                                                            :
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images-gif.gif"/>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                                    
                                        </div>
                                    
                                        
                                        <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                            {this.state.manual_retouch_show=='retouch'?
                                            <button onClick={this.props.requestRetouch} disabled = {this.props.isZipReady ? false : true} >Request Retouch</button>
                                            :
                                            <button onClick={this.props.downloadImages} disabled = {this.props.isZipReady ? false : true} >Download All</button>
                                            }
                                           
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <RefundModal 
                                          handleRefundModal = {this.props.handleRefundModal}
                                          showRefundModal = {this.props.showRefundModal}
                                          userId = {this.props.userId}
                                          creditUsed={this.props.creditUsed}
                                          availableCredit={this.props.availableCredit}
                                          allotedCredit={this.props.allotedCredit}  
                                          refundImgUrl={this.props.refundImgUrl}   
                                          refundOrgUrl={this.props.refundOrgUrl} 
                                          refundImgId={this.props.refundImgId}  
                                          refundSkuId={this.props.refundSkuId}  
                                          updateEditedImageList={this.props.updateEditedImageList} 
                                          auth_key={this.props.auth_key}     
                                          emailId={this.props.emailId}                                         
                                          />

                                
                                
                            </div>
                        :
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                
                                <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                    
                                                    <button className="">
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                         Upload New Project</button>
                                                </div>
                                                <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                    <li className={[styles[''],''].join(' ')}>
                                        <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")} disabled> 
                                            <span>Edited</span>   
                                        </button>
                                    </li>
                                </ul>
                                {this.state.activeButton == "Original" ?
                                    <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                            {({getRootProps, getInputProps}) => (
                                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                    <div {...getRootProps({})} className={styles['dropzone1']}>
                                                        <div className={[styles['add-more'],''].join(' ')} >
                                                            
                                                            <button >+</button>
                                                            
                                                        </div>
                                                        <img className={[styles['more-text'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    
                                    : null
                                }
                               
                                {
                                    this.state.activeButton == "Original" ?
                                <div className={[styles['tab-content'],''].join(' ')}>
                                    <div className={styles['container-fluid']}>
                                        <div className="row">
                                            <LightgalleryProvider
                                                lightgallerySettings={{
                                                    "download": false,
                                                    "thumbnail": false,
                                                    "fullScreen": false,
                                                    "zoom": false
                                                }}
                                            >
                                            
                                            {orgCars}
                                            

                                            </LightgalleryProvider>
                                        </div>
                                    </div>
                                    
                                </div>
                                : 
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    
                                    
                                </div>
                                
                                
                                }
                               
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        <div className={[styles['right-top-credits'],'col-md-4'].join(' ')} >
                                            <a href="https://www.spyne.ai/tech/pricing"  >
                                            <button >
                                            {/* <img style={{"marginRight" : "4px"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add%20credits%20gif.gif"/> */}
                                            <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                            </a>
                                            </div>
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}>
                                        <h6 className={[styles['bg-title'],''].join(' ')} >Backgrounds </h6>
                                                     
                                        <div className={styles['backgrounds-grid']}>
                                                    {
                                                      this.props.planData.slice(0,8).map((e,i) => {
                                                        return (
                                                          <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                            <img alt="" src={e.imageUrl}/>
                                                            <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                              <p className={[styles['back-name'],'col-12 col-md-12'].join(' ')}>{e.bgName} - {e.imageId}</p>
                                                              {/* <p className={[styles['back-credit'],styles['backcredit'],'col-3 col-md-3'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p> */}
                                                            </div>
                                                          </div>

                                                        )
                                                      })
                                                    }
                                                    {this.props.planData.length > 8 ?
                                                    <div className={styles['inactive-background-sec']} >
                                                      <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                        <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                        <div className={styles['img-overlay']}></div>
                                                        <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                      </div>
                                                      
                                                    </div>
                                                    :
                                                    null}
                                                </div>
                                                 
                                    </div>
                                    {/* <div className={[styles['logo-sec'],'col-md-12'].join(' ')}>
                                        <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                                    <DealershipLogo   
                                                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                                        handleLogoPosition = {this.props.handleLogoPosition}
                                                        logoUrl = {this.props.logoUrl}
                                                        logoPosition = {this.props.logoPosition}
                                                        hideLogo={this.props.hideLogo}
                                                        handleLogo={this.props.handleLogo}
                                                    />
                                         </div>
                                        
                                    </div>  */}
                                    {/* <div className={[styles['checkbox-section'],'col-md-12'].join(' ')}>
                                        <div className="row">
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                 <label className={styles['container-2']}>Color Enhancement
                                                <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement}/>
                                                <span className={styles['checkmark']}></span>
                                                </label>
                                            </div>
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                
                                                <label className={styles['container-2']}>Window Transparency
                                                <input type="checkbox" onChange={this.props.handleWindowTransparency} checked={this.props.checkWindowTransparency}/>
                                                <span className={styles['checkmark']} style={{left: "5%"}}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                    

                                       {/* <div className={styles['logo-sec']}>
                                           <p><b>Number Plate Editing (<span>+1 Credit</span>)</b></p>
                                                  <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                                      <NumberPlateLogo   
                                                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                                        handleLogoPosition = {this.props.handleLogoPosition}
                                                        logoUrl = {this.props.logoUrl}
                                                        logoPosition = {this.props.logoPosition}
                                                        hideLogo={this.props.hideLogo}
                                                        handleLogo={this.props.handleLogo}
                                                        handleRadioLogo={this.props.handleRadioLogo}
                                                        checkUploadLogo={this.props.checkUploadLogo}
                                                      />
                                                  </div>
                                                    
                                                </div> */}
                                    {/* {!this.props.proPage?<div>    
                                                        <label className={styles['toggle1']}>Show Window Reflection
                                                        <div style={{color:"black"}}>
                                                          <snap style={{position:"absolute"}}>Window correction</snap>
                                                          <input type="checkbox" onChange={this.props.handleWindowCorrenction} checked={this.props.checkWindowCorrenction} />
                                                          <span style={{color:"white"}} className={styles['slider1']}>{this.props.checkWindowCorrenction?<p></p>:<p></p>}</span>
                                                          </div>

                                                        </label>
                                                           
                                            <label className={styles['toggle1']}>Enhance the color of image!
                                            <div style={{color:"black"}}>
                                                <snap style={{position:"absolute"}}>Color Enhancement</snap>
                                                <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement} />
                                                <span style={{color:"white"}} className={styles['slider1']}>{this.props.checkColorEnhancement?<p></p>:<p></p>}</span>
                                                </div>

                                            </label>
                                            
                                    
                                                        
                                    </div>:null} */}
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button onClick={this.props.creditVerify}>Apply SpyneAI <span style={{"fontSize" : "14px"}}>({(1+this.props.chargedFeature)*(this.props.filesList.length)} Credits)</span>  </button>
                                    </div>
                                    <LogoCropModal 
                                              crop={this.props.crop}
                                              onImageLoaded={this.props.onImageLoaded}
                                              onComplete={this.props.onComplete}
                                              onChange={this.props.onChange}
                                              showlogoCropModal={this.props.showlogoCropModal}
                                              handleCropModal={this.props.handleCropModal}
                                              logoUrl={this.props.logoUrl}
                                              handleCroppedLogoBlob={this.props.handleCroppedLogoBlob}
                                              saveCroppedImage={this.props.saveCroppedImage}
                                            />
                                            
                                    <BackgroundListModalBulk 
                                            handleBackground = {this.handleBackground}
                                            background_id={this.props.background_id} 
                                            planData={this.props.planData} hideList={this.hideList} 
                                            showBackgroundList={this.state.showBackgroundList} 
                                            orgUrl={this.props.orgUrl}
                                            applyChange={this.applyChange}
                                            creditVerify = {this.props.creditVerify}/>
                                    <UploadLimitModal 
                                          maxUpload = {this.props.maxUpload}  
                                          handleUploadLimitModal = {this.props.handleUploadLimitModal}
                                          emptyMultipleFileArray = {this.props.emptyMultipleFileArray}
                                          />
                                    <BulkCreditModal 
                                          showBulkCreditModal = {this.props.showBulkCreditModal}  
                                          handleBulkCreditModal = {this.props.handleBulkCreditModal}
                                          notEnoughCreditModal={this.props.notEnoughCreditModal}
                                          enoughCreditModal={this.props.enoughCreditModal}
                                          zeroCreditModal={this.props.zeroCreditModal}
                                          hitUploadAPI={this.props.hitUploadAPI}
                                          filesList={this.props.filesList}
                                          availableCredit={this.props.availableCredit}
                                          getUserCredit={this.props.getUserCredit}
                                          chargedFeature={this.props.chargedFeature}
                                          />
                                    <ProjectNameModal 
                                          showProjectNameModal = {this.props.showProjectNameModal}  
                                          handleProjectNameModal = {this.props.handleProjectNameModal}
                                          handleProjectName={this.props.handleProjectName}
                                          project_name={this.props.project_name}
                                          projectNameExists = {this.props.projectNameExists}
                                          />
                                </div>
                            </div>
                        </div>
                            
                        }
                        
                    </div>
                </section>
            </div>
        )
    }
}
