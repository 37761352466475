import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';

import '../../../Css/Model/InsufficientCreditModal.css'

class EditedSkuWarningModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
    

    render() { 
        return ( 
            <>
                <Modal
                show={this.props.showWarningModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.props.handleWarningModal}
                >
                    <Modal.Body className="text-right cursor-pointer">
                            <img style={{cursor:"pointer"}} onClick={this.props.handleWarningModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                    </Modal.Body>
                    <Modal.Body className="zero">
                        <p style={{color:"#000", fontWeight:"600"}} className="one">Are you sure?</p>
                        {/* <img className="two" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/InsufficientCredit.png" alt="Insufficient Credit" /> */}
                        <p className="three">Input Images are not equal to the number of uploaded images <br /> Kindly check for missing image</p>
                    </Modal.Body>
                    <div className="button">
                    <Button  style={{background: '#FF7700', borderRadius:"20px", border: "0px", width:"250px", height: "46px"}}variant="primary" size="lg" className="four" onClick={this.props.hitUploadAPI}>Submit Anyway</Button>
                    </div>
                   
        
                    

                </Modal> 
            </>    
        );
    }
}
 
export default EditedSkuWarningModal;  