import React, { Component } from 'react'
import Styles from '../../Css/AppLanding/AppLanding.module.css'
import {Helmet} from "react-helmet";

export default class AppLanding extends Component {
    render() {
        return (
            <div className={[Styles['bg-white']]}>
                {/* SEO tags Start*/}


                <Helmet>
                    <title>Best Online Product Photo Background Editor | Spyne App</title>
                    <meta property="og:title" content="Best Online Product Photo Background Editor | Spyne App"/>
                    <meta property="og:description" content="Now Easily Remove, Edit, and Replace Product Image Backgrounds Online With Spyne App. Best Online Photo Editing And Product Background Removal App For Ecommerce"/>
                </Helmet>

                {/* SEO tags end */}

                <section className={[Styles['app-banner']]}>
                   <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles['banner-image'],'col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-sm-8 offset-sm-2 text-center'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/app-banner.png" />
                                <h4>Shoot And Edit Marketplace Ready Images Instantly</h4>
                                <p>With Spyne AI you can take Professional Product photos using your smartphone and get the images edited instntly with the app's built-in fully automated AI image editor</p>
                                <p className={[Styles['banner-btns']]}> <a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}>
                                        <img className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> 
                                    </a>
                                    <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> 
                                    </a> 
                                </p>
                            </div>
                       </div>
                   </div>
               </section>

               {/* App Banner */}
               <section className={[Styles['business-online']]}>
                   <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12 text-center'].join(' ')}>
                                <h3>Take Your Business To The Next Level Using Spyne AI App</h3>
                            </div>
                        </div>
                        <div className={[Styles['online-business-first'],'row'].join(' ')} >
                            <div className={[Styles['business-content'],'col-md-6 col-sm-6'].join(' ')} data-name="01">
                                <h3>All Types of <span>Product Shoots</span></h3>
                                <p>You can capture and auto-edit images without having to spend on investment-heavy studios or equipment. Whether you know professional photography or not, the app's smart overlay feature guides 
                                    you all the way to get awesome shots</p>
                                     <h6><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}>
                                        <img className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> 
                                    </a>
                                    <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> 
                                    </a>
                                </h6>
                            </div>
                            <div className={[Styles['business-images'],'col-md-6 col-sm-6'].join(' ')}>
                                <img className="img-fluid" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/mobile-1.png" />
                            </div>
                       </div>

                       <div className={[Styles['online-business-first'],'row'].join(' ')} >
                            <div className={[Styles['business-images'],'col-md-6 col-sm-6'].join(' ')}>
                                <img className="img-fluid" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/iPhonePro-3.png" />
                            </div>
                            <div className={[Styles['business-content'],'col-md-6 col-sm-6'].join(' ')} data-name="02">
                                <h3>AI based auto <span>editing</span></h3>
                                <p>From creating clean and sleek backgrounds for your product images to generating 3D virtual showrooms for your car images, Spyne AI is competent to edit your images 100% automatically.
                                     Enrich your images with our AI image editing </p>
                                <h6><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}>
                                        <img className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> 
                                    </a>
                                    <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> 
                                    </a>
                                    
                                </h6>
                            </div>
                       </div>

                        {/* Sell on Desires marketplace  */}
                       <div className={[Styles['online-business-first'],'row'].join(' ')} >
                            <div className={[Styles['business-content'],'col-md-5 col-sm-6'].join(' ')} data-name="03">
                                <h3>Sell on Desires  <span>Marketplace</span></h3>
                                <p>Whether you sell on a marketplace, aggregator, or a dealership, use Spyne AI app to create high-quality imagery for your desired platform without worrying about their guidelines.
                                     Creating marketplace-ready images made easy</p>
                                <h6><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}>
                                        <img className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> 
                                    </a>
                                    <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> 
                                    </a>
                                    
                                </h6>
                            </div>
                            <div className={[Styles['business-images'],Styles['third-images'],'col-md-7 col-sm-6'].join(' ')}>
                                <img className="img-fluid" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/third-mobile.png" />
                            </div>
                       </div>

                   </div>
               </section>
               
               {/* How the spyneAi works  */}
               <section className={[Styles['spyne-ai-works']]}>
                   <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-8 offset-md-2 text-center'].join(' ')}>
                                <h3>How the Spyne AI works</h3>
                                <p>Download Spyne AI app from Playstore, create an account, and start shooting your products to get marketplace-ready images instantly</p>
                            </div>

                            <div className={[Styles['spyne-works'],'col-md-3 col-12'].join(' ')}>
                                <div className={[Styles['works-single'],'row'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group.png" />
                                    <h4>Shoot Multiple Categories</h4>
                                    <p>Follow smart overlays to shoot your products anywhere according to your marketplace</p>
                                </div>
                                <div className={[Styles['works-single'],'row'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group%205892425.png" />
                                    <h4>One-click editing</h4>
                                    <p>Click the images on the app and get them edited in seconds. Save 48 hours waiting time</p>
                                </div>
                                
                            </div>
                            
                            <div className={[Styles['spyne-works'],'col-md-6 col-12'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group%205892405.png" /> 
                            </div>

                            <div className={[Styles['spyne-works'],'col-md-3 col-12'].join(' ')}>
                                <div className={[Styles['works-single'],Styles['spyne-wrk2'],'row'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/mobilephone%20%C2%B7%20ecommerce%20%C2%B7%20shopping%20%C2%B7%20buy%20%C2%B7%20cart.png" />
                                    <h4>Download Images Easily</h4>
                                    <p>Download ready-to-publish images and publish them on your desired platform instantly</p>
                                </div>
                                <div className={[Styles['works-single'],Styles['spyne-wrk2'],'row'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group%205892426.png" />
                                    <h4>Grow your Business</h4>
                                    <p>Use Spyne AI to create images that sell 40% better and 50% faster. Boost your sales now</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            
                <section className={[Styles['categories']]}>
                   <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-8 offset-md-2 text-center'].join(' ')}>
                                <h3>Categories we serve</h3>
                            </div>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                                <div className={[Styles['community-grid'],''].join(' ')}>

                                    <div className={[Styles['child'],Styles['fashion'],''].join(' ')} > 
                                         <div className={[Styles['work-auto'],''].join(' ')}>
                                           <a href="javascript:void(0)">
                                                <span className="">Fashion</span>
                                            </a>
                                        </div> 
                                    </div>


                                    <div className={[Styles['child'],Styles['automobile'],''].join(' ')}>
                                        <div className={[Styles['work-auto'],''].join(' ')}>
                                            <a href="javascript:void(0)">
                                                <span className="">Automobile</span>
                                            </a>
                                        </div>

                                    </div>

                                    <div className={[Styles['child'],Styles['jewellery'],''].join(' ')}>
                                        <div className={[Styles['work-auto'],''].join(' ')}>
                                           <a href="javascript:void(0)">
                                                <span className="">Jewellery</span>
                                            </a>
                                        </div>

                                    </div>

                                    <div  className={[Styles['child'],Styles['footwear'],''].join(' ')}>
                                        <div className={[Styles['work-auto'],''].join(' ')}>
                                            <a href="javascript:void(0)">
                                                <span className="">Footwear</span>
                                            </a>
                                        </div>

                                    </div>


                                    <div className={[Styles['child'],Styles['food'],''].join(' ')}>
                                         <div className={[Styles['work-auto'],''].join(' ')}>
                                            <a href="javascript:void(0)">
                                                <span className="">Food</span>
                                            </a>
                                        </div>

                                    </div>

                                    <div className={[Styles['child'],Styles['watch'],''].join(' ')}>
                                        
                                         <div className={[Styles['work-auto'],''].join(' ')}>
                                            <a href="javascript:void(0)"> 
                                               <span className="">Watch</span> 
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className={[Styles['create-account']]}>
                   <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            
                            <div className={[Styles['create-content'],'col-md-12'].join(' ')}>
                                <div className={[Styles['account-details'],''].join(' ')}>
                                    <p><span>Create an account</span></p>
                                    <h3>Take your product images to the Wow level</h3>
                                    <p>Download Spyne AI app to Shoot and Edit your product images with ease. Get deliverables at faster turnarounds than ever</p>
                                    <p><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}>
                                        <img className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> 
                                    </a>
                                    <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                        <img alt="" className={[Styles['img-google'],'img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> 
                                    </a>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            
            
            </div>
        )
    }
}
