import React, { Component } from "react";
import useState from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import categoryData from "../../Component/FashionRemovalV2/static";
import axios from "axios";
import "../../App.css";
import ScreenTwo from "./Screen2";
import Dropzone from "react-dropzone";
import { Form, ProgressBar, Spinner } from "react-bootstrap";
import {Helmet} from "react-helmet";
import queryString from 'query-string'
import loadImage from "blueimp-load-image";

import {fetchCreditUserDetails, updateUserCredit,cookie,poll, chunkArray} from '../Common/Utils'

import styles from "../../Css/Product/bgRemoval.module.css";
import LandingModal from "../Common/Modal/LandingModalFashion";
import imgData from "../Common/Static/clipper";
import cls from '../../Css/RemoveBg/ScreenTwo.module.css'
import { ReactCompareSlider, ReactCompareSliderImage,ReactCompareSliderHandle } from 'react-compare-slider'
import fs from 'fs'
import { copyFileSync } from "fs";
import Drawer from '../Drawer/Drawer'
import * as Sentry from "@sentry/react"

class ScreenOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      selectedFile: null,
      url: "",
      orgUrl: "",
      switchScreen: false,
      imageCategory: "",
      loader: false,
      imageWidth: "",
      imageHeight: "",
      productHunt: 'clippr',
      showLoginModal:true,
      auth_key:'',
      emailId:'',
      availableCredit:0,
      dropBackground: false,
      creditAlloted:0,
      creditUsed:0,
      wm_image:"",
      hd_image:"",
      source:this.props.match.params,
      tabSelection:"backgrounds",
      customWallPosition:0,
      customWall:"https://storage.googleapis.com/spyne-cliq/Fashion_Walls/New6_4008.png",
      walldata:[],
      fashionWall:"",
      market:"",
      alottedCredit: 0,
      creditUsed:0,
      userId:'',
      activateButton:'Hd',
      bgCredit:1,
      sku_id:'',
      bg_color:'808080',
      checkWallEffect:false,
      checkShadowWall:false,
      checkColorEnhancement:false,
      checkShadow:false

    };
  }
  cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

  handleColorEnhancement = () => {
    this.setState({
      checkColorEnhancement:!this.state.checkColorEnhancement,
    })
  }
  handleShadow = () => {
    this.setState({
      checkShadow:!this.state.checkShadow,
    })
  }

  handleTabSelection=(selection)=>{
    this.setState({
        tabSelection:selection
    })
  }
  customWallSet= (wall_selected) => {
    this.setState({
        customWall: wall_selected
    })
  }
  handlePositionChange=(pos)=>{
    this.setState({
        customWallPosition:pos
    })
}
handleBgColor=(e)=>
{
  this.setState({
    bg_color:e
  })
}
handleWallEffect=()=>{
  this.setState({
    checkWallEffect:!this.state.checkWallEffect
  })
}
handelShadowWall=()=>{
  this.setState({
    checkShadowWall:!this.state.checkShadowWall
  })
}
handlemarket=(e)=>{
   this.setState({
     market:e
   })

 }
 handleDownloadComplete = () => {
  this.setState({downloadComplete: false})
}

  fileUploadHandler = (resolution) => {
    
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          uploadPercentage: percentCompleted,
        });
        if (percentCompleted === 100) {
          this.setState({
            uploading: false,
            processing: true,
          });
        }
      }.bind(this),
    };
    
    try {
      this.setState({
        loader:true
      })
      
      var data = new FormData();
      var config_ph
      data.append('image_file', this.state.selectedFile);
      data.append('auth_key',this.state.auth_key)
      data.append('marketPlace',"Myntra");
      data.append('is_color',this.state.checkColorEnhancement)
     if(this.state.tabSelection=="backgrounds") {
        data.append('bg_color',this.state.bg_color);
        data.append('shadow',this.state.checkShadow);
        config_ph = {
          method: 'post',
          url: process.env.REACT_APP_BASEURL+'/v2/backgrounds/shadow_general',
          headers: { 
          },
          data : data
        };
      }
       
      axios(config_ph)
        .then((res) => {
          this.setState({
            url: res.data.display_image,
            wm_image:res.data.watermark_image,
            hd_image:res.data.output_image,
            sku_id:res.data.sku_id
            
          });
          window.sessionStorage.setItem("image_name",res.data.imagename)


          try {
            localStorage.setItem("urlbg", res.data.display_image);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            editedImages.push(res.data.display_image);
            localStorage.setItem(
              "editedImagesBgr",
              editedImages.toString()
            );
          } catch (e) {
            if (e == "QUOTA_EXCEEDED_ERR") {
            }
          }
          this.setState({
            orgImgShow: true,
            processing: false,
            loader: false,
            editedState: true,
            uploadPercentage: 0,
            tabIndex: 1,
          });
        });
    } catch (e) {
      this.setState({
        isError: true,
        uploading: false,
        processing: false,
        loader: false,
        tabIndex: 0,
      });
    }
  };
  handleActivateButton=(e)=>{
    this.setState({
      activateButton:e
    })
  }
  updateCredit = (e) => {  
 let userId = this.cookie('userId')
 let price 
 if(this.state.activateButton === 'HD'){
     price = this.state.bgCredit
     axios({
         method: "GET",
         url : `${process.env.REACT_APP_BASEURL}/v4/download-history`,
         params : {
             user_id : userId,
             enterprise_id : 'TaD1VC1Ko',
             sku_id: this.state.sku_id
         }
         
     }).then( resp => {
         if(resp.data.status == 404){
             let auth_key = this.cookie('auth_token')
             const fd = new FormData()
             fd.append("auth_key", auth_key)
             fd.append("credit_reduce",price)
             axios.put(`${process.env.REACT_APP_BASEURL}/v2/credit/reduce-credit`,fd)
             .then( (res) => {
                 this.setState({
                     availableCredit: this.state.availableCredit - price,
                 })
                 setTimeout(() => {
                     this.setState({downloadComplete: true})
                 },5000)
 
                 const output_link = this.state.activateButton === "HD" ? this.state.hd_image : this.state.wm_image;
                 fetch(output_link, {
                 method: "GET",
                 headers: {}
                 })
                 .then(response => {
                     response.arrayBuffer().then(function(buffer) {
                     const url = window.URL.createObjectURL(new Blob([buffer]));
                     const link = document.createElement("a");
                     link.href = url;
                     let name = output_link
                     var index = name.lastIndexOf("/") + 1;
                     
                     var filename = name.substr(index)
                     filename=window.sessionStorage.getItem('image_name')+ '.png'
                     link.setAttribute("download", filename); 
                     document.body.appendChild(link);
                     link.click();
                     
                     });
                     
                 })
             
                 const fd = new FormData()
                 fd.append("user_id", userId)
                 fd.append("sku_id",this.state.sku_id)
                 fd.append("enterprise_id" , 'TaD1VC1Ko')
                 fd.append("download_hd","true")
                 axios.post(`${process.env.REACT_APP_BASEURL}/v4/update-download-status`,fd)
                 .then(resp => {
                     console.log(resp)
                 })
             })
         }else{
             const output_link = this.state.activateButton === "HD" ? this.state.hd_image : this.state.wm_image;
                 fetch(output_link, {
                 method: "GET",
                 headers: {}
                 })
                 .then(response => {
                     response.arrayBuffer().then(function(buffer) {
                     const url = window.URL.createObjectURL(new Blob([buffer]));
                     const link = document.createElement("a");
                     link.href = url;
                     let name = output_link
                     var index = name.lastIndexOf("/") + 1;
                     var filename = name.substr(index)
                     filename=window.sessionStorage.getItem('image_name')+ '.png' 

                     link.setAttribute("download", filename); 
                     document.body.appendChild(link);
                     link.click();
                     
                     });
                     
                 })
         }
     })
 }else{
     price = 0
     const output_link = this.state.activateButton === "HD" ? this.state.hd_image : this.state.wm_image;
     fetch(output_link, {
     method: "GET",
     headers: {}
     })
     .then(response => {
         response.arrayBuffer().then(function(buffer) {
         const url = window.URL.createObjectURL(new Blob([buffer]));
         const link = document.createElement("a");
         link.href = url;
         let name = output_link
         var index = name.lastIndexOf("/") + 1;
         var filename = name.substr(index)
         filename=window.sessionStorage.getItem('image_name')+ '.png'

         link.setAttribute("download", filename); 
         document.body.appendChild(link);
         link.click();
         
         });
         
     })
 }

}
  getUserCredit = async () => {
    let emailId = this.cookie("emailId")
    let auth_key = this.cookie("auth_token") 
            const resp = await poll({
                fn: () => {
                    
                    return axios({
                        method: "GET",
                        url : `${process.env.REACT_APP_BASEURL}/v2/credit/fetch`,
                        params : {
                            auth_key : auth_key
                        }
                        
                    })
                },
                validate: (resp) => {
                    if(resp.data.status == 200){
                        if(this.state.bulkDownloadDone == true){
                            return true
                        }else{
                            this.setState({
                                availableCredit: resp.data.data.credit_available,
                                allotedCredit : resp.data.data.credit_allotted,
                                creditUsed: resp.data.data.credit_used
                            })
                            return false
                        }
                    }else if(resp.data.status == 400){
                        document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        window.sessionStorage.clear()
                        window.location.reload()
                    }
                    
                    
                },
                interval: 5000,
            })
}
  fileSelectedHandler = (event) => {
    this.getUserCredit()
    this.setState({
      loader: true,
      switchScreen: true
    });
    if (event.target) {
      var file = event.target.files[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event.target.files[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
        },
        () => {
        }
      );
    } else {
      let file = event[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
          
        },
        () => {
        }
      );
    }
  };

  componentDidMount = async () => {
     
        let auth_key = this.cookie('auth_token')
        this.setState({
            auth_key: auth_key
        })
 
        var passing_location; 

        passing_location = 'NULL'
        localStorage.setItem("passing_location", passing_location);
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        this.setState({
            emailId : userEmail
        })
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post(`${process.env.REACT_APP_BASEURL}/get_starting_info/`, dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            this.setState({
                showInstruction:true
            })
        }
        if (this.state.walldata.length==0 && auth_key){
          axios({
              'method':"GET",
              'url':`${process.env.REACT_APP_BASEURL}/v2/backgrounds/custom-walls?auth_key=`+auth_key+'&category=cat_skJ7HIvnc',

          })
          .then((reswall) => {
            console.log(reswall)
              this.setState({
                  walldata:reswall.data,
                  wall:reswall.data[0]['wall_png_url']
              })
          })
      }


        let userId = this.cookie('userId')
        if(userId !== "NA"){

            let emailId = this.cookie("emailId")
            let auth_key = this.cookie("auth_token")
            axios({
                method: "GET",
                url : `${process.env.REACT_APP_BASEURL}/v2/user/get`,
                params : {
                    email_id : emailId,
                    auth_key : auth_key
                }
                
            }).then(resp => {
                if(resp.data.status == 200){
                    this.setState({
                        availableCredit: resp.data.data.credit_available,
                        creditAlloted : resp.data.data.credit_allotted,
                        creditUsed: resp.data.data.credit_used
                    })
                }else{
                }
            })
                this.setState({
                    userId: userId
                })
        }
        if(this.state.uploadStarteds === "true"){
            this.getUserCredit()
        }
        
    }

  cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

  hideLandingModal = () => {
    this.setState({showLoginModal:!this.state.showLoginModal})
  }
  handleUnshiftWalls = (data) =>{
    this.state.walldata.unshift(data)
    this.setState({
      wall:data['low_res_wall_url']
  })
  }
  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }
  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }
  
  render() {
    return !this.state.switchScreen ? (
      <>
      <Helmet>
			<title>Change Product Background Instantly | Product Photo Editor.Try Now</title>
			<meta id="meta-description" name="description" content="Replace Your Product Images with Marketplace Ready images in Just 5 Seconds With Spyne AI Product Photo Editor. Instantly Remove Any Product Image Background and Give Your Product Photo a New Look. 100% Automated Editing in Just a Click. " />
			<meta property="og:title" content="Change Product Background Instantly | Product Photo Editor.Try Now" />
			<meta property="og:description" content="Replace Your Product Images with Marketplace Ready images in Just 5 Seconds With Spyne AI Product Photo Editor. Instantly Remove Any Product Image Background and Give Your Product Photo a New Look. 100% Automated Editing in Just a Click. " />
			<script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
		</Helmet>
      <div>
         {/* Drawer section  */}
      <Drawer/>
      {
        this.state.showLoginModal?
        <LandingModal
            showInstruction={this.state.showLoginModal}
            hideModal = {this.hideLandingModal}
            productHunt={this.state.productHunt}

        />
        :null
      }
        
        <Dropzone
          onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
          onDragEnter={this.dragStart}
          onDragLeave={this.dragLeave}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onClick: (event) => event.stopPropagation(),
              })}
              className={styles["dropzone1"]}
            >
              {this.state.dropBackground ? (
                <section className={[styles["drop-box-text"]]}>
                  <div>Drop Image Anywhere</div>
                </section>
              ) : null}
              <div className={[styles['try-credit']]}><span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png"/> 
                  Available Credits: <b> {this.state.availableCredit}</b></span></div>
              <div className={styles["header-section"]}>
                <p className={styles["heading-text"]}>
                    Marketplace Ready Images of your Products
                </p>
                <p className={styles["heading-para"]}>
                Transform Images In a{" "}
                  <span>single click</span> using <span>AI</span>
                </p>
              </div>
              <div className={[styles["car-main-wrapper"], ""].join(" ")} id="cars-try-modal" >
                <div className={[styles[""], "container"].join(" ")}>
                  <div className={[styles["car-try-wrapper"], "row"].join(" ")}>
                    <div
                      className={[
                        styles["car-left-section"],
                        "col-md-10 offset-md-1",
                      ].join(" ")}
                    >
                      <div
                        className={[styles["car-mid-section"], ""].join(" ")}
                      >
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.fileSelectedHandler(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className={[
                                styles["try-drag-drop"],
                                "d-md-block d-none",
                              ].join(" ")}
                            >
                              <div
                                {...getRootProps({})}
                                className={styles["dropzone1"]}
                              >
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                <p className={styles["para-11"]}>
                                  DROP IMAGE HERE{" "}
                                </p>
                                <p class={styles["para-2"]}>
                                  or <span>SELECT IMAGE ON YOUR COMPUTER</span>{" "}
                                </p>
                                <p className={styles["supports1"]}>
                                  Supports jpeg, jpg, bmp & png
                                </p>
                                <input
                                  onChange={this.fileSelectedHandler}
                                  ref={(fileInput) =>
                                    (this.fileInput = fileInput)
                                  }
                                  type="file"
                                  {...getInputProps()}
                                />
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <input
                onChange={this.fileSelectedHandler}
                ref={(fileInput) => (this.fileInput = fileInput)}
                type="file"
                {...getInputProps()}
              />
            </div>
          )}
        </Dropzone>
      </div>
      <div className="container" style={{maxWidth:"90%", marginBottom:'3rem' }}>
      <div className={[cls['categories'],'row'].join(' ') } >
         <h2><span style={{fontSize:"1.3em"}}>Our Work</span></h2>
         <div className="folder" style={{display:'flex', justifyContent:"space-evenly",padding:"1em 2em" , borderRadius:"0.3em", margin:"auto"}}>
          
            {imgData.map((ImageInfo)=>{
               return(
                <div style={{width:"40%", height:"15em", backgroundColor:"white"}}>
                <ReactCompareSlider
                handle={<ReactCompareSliderHandle style={{height:"82%"}}/>}
                itemOne={<ReactCompareSliderImage src={ImageInfo.img1}  alt="one"/>}
                itemTwo={<ReactCompareSliderImage src={ImageInfo.img2} alt="two"/>}
                onPositionChange={this.handleSetPosition}
                onlyHandleDraggable
                position={this.state.tempPosition==0?30:this.state.tempPosition}
                style={{objectPosition : "center center",width: this.state.new_width?this.state.new_width:"100%",left:this.state.left_wall_padding}}
                portrait={false}
                /> 
                </div>
                )
            })}
          
         </div>
      </div>
      </div>
      </>
    ) : (
      <ScreenTwo
        originalImage={this.state.orgUrl}
        editedImage={this.state.url}
        hd_image={this.state.hd_image}
        wm_image={this.state.wm_image}
        fileSelectedHandler={this.fileSelectedHandler}
        loader={this.state.loader}
        fileUploadHandler={this.fileUploadHandler}
        handleTabSelection={this.handleTabSelection}
        customWallSet={this.customWallSet}
        handlePositionChange={this.handlePositionChange}
        customWallPosition={this.state.customWallPosition}
        customWall={this.state.customWall}
        handleUnshiftWalls={this.handleUnshiftWalls}
        wallData={this.state.walldata}
        handlemarket={this.handlemarket}
        availableCredit={this.state.availableCredit}
        handleDownload={this.updateCredit}
        handleActivateButton={this.handleActivateButton}
        handleBgColor={this.handleBgColor}
        handelShadowWall={this.handelShadowWall}
        handleWallEffect={this.handleWallEffect}
        checkShadowWall={this.state.checkShadowWall}
        checkWallEffect={this.state.checkWallEffect}
        bgCredit={this.state.bgCredit}
        downloadComplete={this.state.downloadComplete}
        handleDownloadComplete = {this.handleDownloadComplete}
        handleColorEnhancement={this.handleColorEnhancement} 
        checkColorEnhancement={this.state.checkColorEnhancement}
        handleShadow={this.handleShadow}
        checkShadow={this.state.checkShadow} />
    );
  }
}
export default Sentry.withProfiler(ScreenOne)