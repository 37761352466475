import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import Dropzone from 'react-dropzone'
import styles from '../../../Css/AppDashboard/User.module.css'
import FavouriteListModal from '../../Common/Modal/FavouriteListModal'
import BackgroundUser from './BackgroundUser'
import axios from 'axios'
import queryString from 'query-string'
import { cookie, getCookie } from '../../Common/Utils'
import { AuthContext } from '../../../context/GlobalState'
import Resizer from 'react-image-file-resizer'

const User = (props) => {

    const [openModal, setOpenModal] = useState(false)
    const [favoriteBgList, setFavoriteBgList] = useState([])
    const [otherBgList, setOtherBgList] = useState([])
    const [planData, setPlanData] = useState([])
    const [backgroundId, setBackgroundId] = useState('')
    const [bgPrice, setBgPrice] = useState('')
    const [primary, setPrimary] = useState('')
    const [countries, setCountries] = useState([])
    const [userCreated, setUserCreated] = useState(false)
    const [logoScreen, setLogoScreen] = useState(false)
    const [user, setUser] = useState({})
    const username = React.createRef()
    const emailId = React.createRef()
    const phoneNumber = React.createRef()
    const country = React.createRef()
    const dealerId =  React.createRef()
    const file = React.createRef()
    const location = useLocation()
    const history = useHistory()
    const context = useContext(AuthContext)
    const [selectedLogoFile, setSelectedLogoFile] = useState('')
    const [logoPosition, setLogoPosition] = useState('left-top')
    const [logo, setLogo] = useState(null)

    useEffect(() => {
        if (!context.auth.loggedIn && cookie('role') !== 'ADMIN') {
			history.push('/login')
		}
    })


    const handleId = (bgId, bgCrdit) => {
        setBackgroundId(bgId)
        setBgPrice(bgCrdit)
    }

    const hideModal = () => {
        setOpenModal(false)
    }

    const showModal = () => {
        setOpenModal(true)
    }

    const handleModal = (e) => {
        e.preventDefault()
        setOpenModal(true)
    }

    const handleInput = (e) => {
        let newUser = {...user}
        newUser[e.currentTarget.name] = e.currentTarget.value
        setUser({...user, ...newUser})
    }

    function fetchBackgroundList(auth_token) {
        return axios({
            url: 'https://www.clippr.ai/api/v2/backgrounds/fav-ent-bgs?auth_key=' + auth_token,
            method: 'GET',
            params: {
                auth_key: auth_token
            }
        })
    }

    function planDataList() {
        return axios({
            'method':"GET",
            'url':`https://www.clippr.ai/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`
        })
    }

    useEffect(() => {
        let queries = queryString.parse(location.search)
        if (queries && queries.edit) {
            // Show User Edit Component
        }

    }, [location])

    useEffect(() => {
        Promise.all([planDataList(), getCountry()])
        .then(([plans, countries]) => {
            setPlanData([...plans.data])
            setCountries([...countries.data.data])
        })
    }, [])

    const registerUser = async (e) => {
        e.preventDefault()
        // Validate Required Fields
        const fd = new FormData()
        fd.append('user_name', username.current.value)
        fd.append('email_id', emailId.current.value)
        fd.append('contact_no', phoneNumber.current.value)
        fd.append('country', country.current.value)
        // fd.append('logo', file.current.files[0])
        // fd.append('logo_position', user['logoPosition'])
        fd.append('dealer_id', dealerId.current.value)
        fd.append('api_key', process.env.REACT_APP_API_KEY)
        fd.append('enterprise_id', cookie('enterpriseId'))
        fd.append('strategy', 'API_USER')
        fd.append('signup_url', window.location.href)
        fd.append('source', 'Web')

        const resp = await axios({
            url: `https://www.clippr.ai/api/v2/user/signup`,
            method: 'POST',
            data: fd
        })

        if (resp.data?.status === 200) {
            setUser({...resp.data})
            const backgrounds = await fetchBackgroundList(resp.data?.auth_token)
            setFavoriteBgList([...backgrounds.data.data.fav_list])
            setOtherBgList([...backgrounds.data.data.spynelist])
            setUserCreated(true)
        }

    }

    function getCountry() {
        return axios({
            url: `${process.env.REACT_APP_BASEURL}/v2/user/countries`,
            method: 'GET'
        })
    }

    async function updateBackgroundList(auth_token) {
        const backgrounds = await fetchBackgroundList(auth_token)
        setFavoriteBgList([...backgrounds.data.data.fav_list])
        setOtherBgList([...backgrounds.data.data.spynelist])
    }

    async function uploadLogo(e) {
        e.preventDefault()
        const fd = new FormData()
        fd.append('logo', logo)
        fd.append('user_id', user['user_id'])
        fd.append('enterprise_id', cookie('enterpriseId'))
        fd.append('dealer_id', user['dealer_id'] ? user['dealer_id'] : null)
        fd.append('logo_position', logoPosition)

        const resp = await axios({
            url: `https://www.clippr.ai/api/v2/enterprise-dealer/upload-logo`,
            method: 'POST',
            data: fd
        })

        if (resp.data?.status === 200) {
            let newUser = {...user}
            newUser['logoUrl'] = resp.data?.data['logo_url']
            setUser({...newUser})
            localStorage.removeItem('user')
            window.location.href = `dashboard/enterprise-users?userId=${cookie('userId')}&enterpriseId=${cookie('enterpriseId')}`
        }
    }

    function nextScreen (next) {
        setLogoScreen(next)
    }

    function redirectToDashboard(){
        localStorage.removeItem('user')
        window.location.href = `dashboard/enterprise-users?userId=${cookie('userId')}&enterpriseId=${cookie('enterpriseId')}`
    }

    function LogoUpload () {
        return (
            // <form className={[styles[''],'row justify-content-center align-items-center'].join(' ')} onSubmit={uploadLogo}>
            //     <div className="col-6">
            //         <label>Logo* 
            //             <input type="file" 
            //                 placeholder="" 
            //                 name="logo"
            //                 ref={file}
            //                 />
            //         </label>
            //         <label>Logo Position*
            //             <select defaultValue="top" onInput={handleInput} name="logoPosition">
            //                 <option value="top">Top</option>
            //                 <option value="right">Right</option>
            //                 <option value="bottom">Bottom</option>
            //                 <option value="left">Left</option>
            //             </select>
            //         </label>

                   
                        
                   

            //         <div className={[styles[''],'text-center mt-3 col-md-12'].join(' ')}>
            //             <button>Upload Logo</button>
            //         </div>
            //     </div>
                

            // </form>
             <div className={[styles['upload-dealership'],'row justify-content-center align-items-center'].join(' ')}>
                <div className="col-5">
                    <Dropzone onDrop={acceptedFiles => dealerLogoFileHandler(acceptedFiles)} >
                        {({getRootProps, getInputProps}) => (
                            <section className={[styles['try-drag-drop'],''].join(' ')}>
                                <div  {...getRootProps({})} className={styles['dropzone1']}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                    <p className={styles["para-11"]}>Upload Logo </p>
                                    <p class={styles["para-2"]}>or <span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                    <p className={styles['supports1']}>Supports jpeg, jpg & png </p>
                                    <input  onChange={dealerLogoFileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    
                    
                </div>
                <h5 onClick={redirectToDashboard}>Skip <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 0H19.5V18H18V0Z" fill="#FF7700"/>
                        <path d="M0.75 18C0.551088 18 0.360322 17.921 0.21967 17.7804C0.0790178 17.6397 0 17.4489 0 17.25V0.750031C5.88985e-05 0.618333 0.0347952 0.488973 0.100715 0.374961C0.166635 0.260948 0.261414 0.166304 0.37552 0.100547C0.489626 0.0347894 0.619036 0.000237742 0.750733 0.00036657C0.882431 0.000495397 1.01177 0.0353005 1.12575 0.101281L15.3757 8.35128C15.4894 8.41721 15.5837 8.51184 15.6493 8.6257C15.7149 8.73955 15.7494 8.86864 15.7494 9.00003C15.7494 9.13142 15.7149 9.26051 15.6493 9.37437C15.5837 9.48822 15.4894 9.58285 15.3757 9.64878L1.12575 17.8988C1.01162 17.9651 0.881986 18 0.75 18Z" fill="#FF7700"/>
                    </svg>
                </h5>
             </div>
        )
    }

    function UserForm() {
        return (
            <form className={[styles[''],'row justify-content-center align-items-center'].join(' ')} onSubmit={registerUser}>
                <div className="col-6">
                    <label>Name* <span>(mandatory)</span>
                        <input type="text" 
                            placeholder="Business Name" 
                            required 
                            onInput={handleInput} 
                            name="username"
                            ref={username} />
                    </label>
                    <label>Email ID <span>(optional)</span>
                        <input type="email" 
                            placeholder="Email ID" 
                            onInput={handleInput} 
                            name="emailId"
                            ref={emailId} />
                    </label>
                    <label>Dealer ID <span>(optional)</span>
                        <input type="text" 
                            placeholder="" 
                            onInput={handleInput} 
                            name="phoneNumber"
                            ref={dealerId}/>
                    </label>
                    <label>Phone Number <span>(optional)</span>
                        <input type="tel" 
                            placeholder="1234xxxxxx" 
                            onInput={handleInput} 
                            name="phoneNumber"
                            ref={phoneNumber}/>
                    </label>
                    <label>Country  <span>(optional)</span>
                        <select defaultValue="India" onInput={handleInput} name="country" ref={country}>
                            {
                                countries.map((country) => {
                                    return (
                                        <option 
                                        key={country.id} 
                                        value={country.name}
                                        >
                                            {country.name}
                                        </option>
                                    )
                                }) 
                            }
                        </select>
                    </label>
                    {/* <div className={[styles['bg-select'],'col-md-12'].join(' ')}>
                        <label>Select Background </label>
                            <input type="text" placeholder="Click to Choose Background" readOnly/>
                            <button onClick={handleModal}>Select Background </button> 
                    </div> */}
                    <div className={[styles[''],'text-center mt-3 col-md-12'].join(' ')}>
                        <button>Create User</button>
                    </div>
                </div>
            </form>    
        )
    } 

    function dealerLogoFileHandler(e) {
        

        var file = e[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        setLogo(file)
        setSelectedLogoFile(URL.createObjectURL(e[0]))
            
        
    }

    function removeLogo(){
        setSelectedLogoFile('')
    }

    function handleLogoPosition(position){
        setLogoPosition(position)
    }

    function LogoPosition(){
        return (
            <section className={[styles['']]}>
                <div className={[styles[''],'container-fluid'].join(' ')}>
                    <div className={[styles[''],'row align-items-center justify-content-center'].join(' ')}>
                        <div className={[styles['user-heading'],'col-md-12 text-center'].join(' ')}>
                            
                            <h3><span>
                                    <img class="" src={selectedLogoFile}/>
                                    <svg onClick={removeLogo} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.47642 1.92859L1.54785 8.85716" stroke="#FF7700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.54785 1.92859L8.47642 8.85716" stroke="#FF7700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <Dropzone onDrop={acceptedFiles => dealerLogoFileHandler(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <button {...getRootProps({})}>   Change logo
                                            <input  onChange={dealerLogoFileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        </button>
                                    )}
                                </Dropzone>
                                
                            </h3>
                            <h4>Select logo position</h4>
                            
                            <div className={[styles['logo-positioning'],'row m-0'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/app-dashboard/dealcar.png"/>
                                <div className={[styles['logo-area'],'col-md-10 offset-md-1'].join(' ')}>
                                    <div className={[styles['left']]} onClick={() => handleLogoPosition('left-top')}>
                                        { logoPosition == 'left-top' ?
                                            <img src={selectedLogoFile}/> : null
                                        }
                                        
                                    </div>
                                    <div className={[styles['right']]} onClick={() => handleLogoPosition('right-top')}>
                                        { logoPosition == 'right-top' ?
                                            <img src={selectedLogoFile}/> : null
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                            <button className={[styles['upload-logo-btn']]} onClick={uploadLogo}>Upload Logo</button>
                        </div>



                    </div>
                </div>
            </section>
        )
    }

    function showLogoUpload(){
        setLogoScreen(true)
    }



    return (
        <>
            <div>
                <section className={[styles['user-section']]}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row align-items-center justify-content-center'].join(' ')}>
                            <div className={[styles['user-heading'],'col-md-12'].join(' ')}>
                                <h6><img src="https://storage.googleapis.com/spyne-website/static/app-dashboard/AddUser.png"/>
                                    <span>
                                        {
                                            !userCreated ? 'Add a new User' : !logoScreen ? 'Add a User background' : 'Upload Logo'
                                        }
                                    </span></h6>
                            </div>
                            <div className={[styles['user-form'],'col-md-12'].join(' ')}>
                                       
                                {
                                    !userCreated ? 
                                        UserForm() : 
                                        !logoScreen ?
                                            <BackgroundUser 
                                                user={user} 
                                                planData={otherBgList} 
                                                favList={favoriteBgList} 
                                                otherBgList={otherBgList} 
                                                updateBackground={updateBackgroundList}
                                                nextForm={nextScreen}
                                                showLogoUpload={showLogoUpload}
                                                />
                                                
                                        :
                                            selectedLogoFile == '' ?
                                            LogoUpload()
                                            :
                                            LogoPosition()
                                } 
                            </div>
                        </div>
                    </div>
                </section>
            
                
            
            </div>
        </>      
    )
}

export default User


