import React, { Component } from 'react'
// import CarsThreeSixtyOutput from '../CarsThreeSixtyOutput';

import styles from '../../Css/CarsThreeSixty.module.css'
import cls from '../../Css/CarsBackgroundReplacement.module.css'
import Dropzone from 'react-dropzone'
import CarsThreeSixtyAfterUpload from './Cars/CarsThreeSixtyAfterUpload'
import { event } from 'jquery'
import Picker from 'react-scrollable-picker';
import axios from 'axios'
import {fetchCreditUserDetails, updateUserCredit,cookie,poll, chunkArray} from '../Common/Utils'
// import '../../Css/Video.scss'
// import NumberPicker from "react-number-picker";
// import "react-number-picker/dist/style.css"

export default class CarsThreeSixtyVideo extends Component {
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    constructor(props) {
        super(props)
        this.state = {
            videoUrl: null,
            selectedVideoFile: null,
            // numberOfFrame: 24,
            proceedToBgSelection: false,
            value: 1.99,
            selectedNumberFrames:false,
            valueGroups: {
                frames: '12',
            }, 
              optionGroups: {
                
                frames: [
                  { value: '24', label: '24' },
                  { value: '12', label: '12' },
                  { value: '6', label: '6' },
                ],
                
              },
            uploadVideoStarted: false,
            showProjectNameModal: false,
            project_name: window.sessionStorage.getItem("project_name") ? window.sessionStorage.getItem("project_name") : '',
            sku_name: window.sessionStorage.getItem("sku_name") ? window.sessionStorage.getItem("sku_name") : '',
            skuId: null,
            videoUploaded: false,
            framesCompleted: 0,
            videoTrimmed: false,
            player: null,
            video:null,
            playbackRect: null,
            seekableRect: null,
            seekRatio: null,
            progressBar: null,
            startGrabber: null,
            endGrabber: null,
            frontTrimmedRatio: 0,
            endTrimmedRatio: 1,
            start: 0,
            end: null,
            projectNameExists: false,
            threeSixtyUrl: null
        }
       
    }

    componentDidMount() {
    }

    componentDidUpdate = () => {
    }
 
    
    handleChange = (name, value) => {
        this.setState(({valueGroups}) => ({
          valueGroups: {
            ...valueGroups,
            [name]: value
          }
        }));
      };


    fileHandlerNew = (acceptedFiles) => {
        if(this.state.project_name === ''){
            this.setState({
                showProjectNameModal:true
            })
        }
        let file = acceptedFiles[0];
        let blobURL = URL.createObjectURL(file);
        this.setState({
            videoUrl: blobURL,
            selectedVideoFile: file
        })
        console.log(file)
        console.log(blobURL)
        this.setState({
            player: {
                videoUrl: blobURL,
                start: 0,
                end: null,
                controls: {
                    play: document.getElementsByClassName('play-control')[0],
                    seekToStart: document.getElementsByClassName('seek-start')[0],
                    seekToEnd: document.getElementsByClassName('seek-end')[0],
                    reset: document.getElementsByClassName('reset-control')[0]
                }
            }
        })
    }
    pausePlay = () => {
        let a = document.getElementsByTagName("video")[0]
        let video = document.getElementsByClassName('video')[0]
        console.log(video)
        console.log(a)
        if (a.paused) {
            a.play()
        }
        else {
            a.pause()
        }
    }

    handleFrameSelected = () => {
        this.setState({
            videoTrimmed: !this.state.videoTrimmed
        })
    }

    handleVideoTrimming = () => {
        this.setState({
            selectedNumberFrames: !this.state.selectedNumberFrames
        })
    }

    uploadVideoApi = () => {
        this.setState({
            uploadVideoStarted: !this.state.uploadVideoStarted
        })

        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("video",this.state.selectedVideoFile)
        fd.append("type", "360_exterior")
        fd.append("category", "Automobile")
        fd.append("auth_key", auth_key)
        fd.append("sub_category", "Sedan")
        fd.append("sku_name", this.state.sku_name)
        fd.append("background_id", this.props.background_id)
        fd.append("frames",this.state.valueGroups.frames);
        fd.append('src', 'Web')
        fd.append('start_time', this.state.start)
        fd.append('end_time', this.state.end)
        fd.append("prod_cat_id", "cat_d8R14zUNE")
        fd.append("prod_sub_cat_id", "cat_d8R14zUNE")
        axios.post(`https://www.clippr.ai/api/v2/video/upload_two`,fd)
        .then((resp) => {
            if(resp.status == 400){

            }else if(resp.status ==200){
                this.setState({
                    skuId: resp?.data?.sku_id,
                    videoUploaded: true
                })
                this.getProcessedFrames(resp?.data?.sku_id)
                this.updateCredits(resp?.data?.sku_id)
            }
        })
    }

    updateCredits = (skuId) => {
        let auth_key = this.cookie('auth_token')
        const fd = new FormData()
        fd.append("auth_key", auth_key)
        fd.append("credit_reduce",this.state.valueGroups.frames)
        fd.append("sku_id", skuId)
        fd.append("image_id",'')
        fd.append("source",'Web')
        axios.put(`https://www.clippr.ai/api/v2/credit/reduce-user-credit`,fd)
        .then((resp) => {
            console.log(resp)
        })
    }

    getProcessedFrames = async (skuId) => {
        
        let auth_key = this.cookie("auth_token") 
                const resp = await poll({
                    fn: () => {
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v3/scheduler/fetch/360_web",
                            params : {
                                auth_key : auth_key,
                                sku_id: skuId
                            }
                        })
                    },
                    validate: (resp) => {
                        console.log(resp.data.data)
                        if(resp.data.data.status == 200){
                            if(this.state.valueGroups.frames == resp.data.data.processed){
                                this.setState({
                                    threeSixtyUrl: resp.data.data.url
                                })
                                return true
                            }else{
                                this.setState({
                                    framesCompleted: resp.data.data.processed
                                })
                                return false
                            }
                            
                        }else if(resp.data.status == 400){
                            window.sessionStorage.clear()
                            window.location.reload()
                        }
                    },
                    interval: 3000,
                })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        this.setState({
            project_name: e.target.value,
            sku_name:e.target.value,
            projectNameExists : false
        })
        sessionStorage.setItem("project_name", e.target.value);
        sessionStorage.setItem("sku_name", e.target.value);
    
    }
    handleProjectNameModal =()=>{
        let auth_key = this.cookie("auth_token")
        let user_id = this.cookie("userId")
        const fd = new FormData()
        fd.append("auth_key", auth_key);
        fd.append("user_id", user_id);
        fd.append("enterprise_id", "TaD1VC1Ko");
        fd.append("project_name",this.state.project_name)
        axios.post(`https://www.clippr.ai/api/v2/project/verify-project`,fd)
        .then((resp) => {
            if(resp.data.status == 404){
                this.setState({
                    showProjectNameModal: false,
                    projectNameExists: false
                })
            }else if(resp.data.status == 200){
                this.setState({
                    projectNameExists: true
                })
            }
        })
        
    }

    playVideo = () => {
        var video = document.getElementsByClassName('video')[0]
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
          }
        if (player.video.paused) {
          player.video.play();
          player.controls.play.classList.remove('fa-play');
          player.controls.play.classList.add('fa-pause');
          console.log('play video');
        } else {
          this.pauseVideo();
        }
        if (player.video.currentTime >= player.end) {
          player.video.currentTime = player.start;
          player.controls.play.classList.remove('fa-play');
          player.controls.play.classList.add('fa-pause');
          console.log('start from beginning');
        }
      };

      pauseVideo =  () => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
          }
        var video = document.getElementsByClassName('video')[0]
        player.video.pause();
        player.controls.play.classList.remove('fa-pause');
        player.controls.play.classList.add('fa-play');
        console.log('pause video');
        video.classList.remove('paused');
      };

      resetVideo = () => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
        }

        
        var seekableBar = document.getElementsByClassName('seekable')[0]
        var progressBar = document.getElementsByClassName('progress')[0]
        var startGrabber = document.getElementsByClassName('start')[0]
        var endGrabber = document.getElementsByClassName('end')[0]
        player.start = 0;
        player.end = player.video.duration;
        player.video.currentTime = 0;
        startGrabber.style.left = '0';
        endGrabber.style.left = '100%';
        seekableBar.style.left = '0';
        seekableBar.style.right = '0';
        endGrabber.style.left = '100%';
        startGrabber.style.left = '0';
        // frontTrimmedRatio = 0;
        // endTrimmedRatio = 0;
        progressBar.style.right = 0;
        progressBar.style.left = 0;
        // console.log('reset video');
        this.setState({
            start: player.start,
            end: player.end,
            frontTrimmedRatio: 0,
            endTrimmedRatio: 0
        })
      };

      seekVideo = (seekRatio) => {
        var video = document.getElementsByClassName('video')[0]
        video.currentTime = video.duration * seekRatio;
      };

      updateProgressBar = () => {
        var progressBar = document.getElementsByClassName('progress')[0]
        progressBar.style.width = (this.state.seekRatio - this.state.frontTrimmedRatio) * 100 + '%';
      }

      updateProgressBarWidth = () => {
        var progressBar = document.getElementsByClassName('progress')[0]
        progressBar.style.left = this.state.frontTrimmedRatio * 100 + '%';
        progressBar.style.right = (1 - this.state.endTrimmedRatio) * 100 + '%';
      }
      
      updateSeekableStart = () => {
        var seekableBar = document.getElementsByClassName('seekable')[0]
        var startGrabber = document.getElementsByClassName('start')[0]
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
        }
        player.start = player.video.duration * this.state.frontTrimmedRatio;
        this.setState({
            start: player.start
        })
        seekableBar.style.left = this.state.frontTrimmedRatio * 100 + '%';
        startGrabber.style.left = this.state.frontTrimmedRatio * 100 + '%';
        this.updateProgressBar()
        console.log('front trimmed: ' + this.state.frontTrimmedRatio);
      }

      updateSeekableEnd = (event) => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
        }
        var seekableBar = document.getElementsByClassName('seekable')[0]
        var endGrabber = document.getElementsByClassName('end')[0]
        player.end = player.video.duration * this.state.endTrimmedRatio;
        this.setState({
            end: player.end
        })
        seekableBar.style.right = (1 - this.state.endTrimmedRatio) * 100 + '%';
        endGrabber.style.left = this.state.endTrimmedRatio * 100 + '%';
        this.updateProgressBar()
        console.log('end seek ratio: ' + this.state.endTrimmedRatio);
      };
      
      playheadMove = (event) => {
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        this.setState({
            seekAmount : this.state.seekRatio
        })
        this.seekVideo(seekAmount);
        window.addEventListener('mouseup', this.playheadDrop);
        console.log(seekAmount);
      }
      
      
      playheadDrop = () => {
        window.removeEventListener('mousemove', this.playheadMove);
        window.removeEventListener('mouseup', this.playheadDrop);
      }

      startGrabberMove = (event) => {
                
        var ratio = (event.clientX - this.state.playbackRect.left) / this.state.playbackRect.width;
        if (ratio <= 0) {
            this.setState({
                frontTrimmedRatio:0
            })
        } else if (ratio >= this.state.endTrimmedRatio) {
            this.setState({
                frontTrimmedRatio:this.state.endTrimmedRatio
            })
        } else {
            this.setState({
                frontTrimmedRatio:ratio
            })
        }
        this.updateSeekableStart();
        this.updateProgressBarWidth();
        this.seekVideo(this.state.frontTrimmedRatio);
      }
      
      startGrabberDrop = () => {
        window.removeEventListener('mousemove', this.startGrabberMove);
        window.removeEventListener('mouseup', this.startGrabberDrop);
        console.log('start grabber dropped');
      };
      
      endGrabberMove = (event) => {
        var ratio = (event.clientX - this.state.playbackRect.left) / this.state.playbackRect.width;
        if (ratio >= 1) {
            this.setState({
                endTrimmedRatio: 1
            })
          
        } else if (ratio <= this.state.frontTrimmedRatio) {
            this.setState({
                endTrimmedRatio: this.state.frontTrimmedRatio
            })
        } else {
            this.setState({
                endTrimmedRatio: ratio
            })
        }
        this.updateSeekableEnd();
        this.updateProgressBarWidth();
        
        this.seekVideo(this.state.endTrimmedRatio);
        
      }

      endGrabberDrop = () => {
        window.removeEventListener('mousemove', this.endGrabberMove);
        window.removeEventListener('mouseup', this.endGrabberDrop);
      }

      seekableBarMouseDown = () => {
        this.pauseVideo();
        var playbackBar = document.getElementsByClassName('playback')[0]
        var playbackRect = this.state.playbackRect
        playbackRect = playbackBar.getBoundingClientRect();
        this.setState({
            playbackRect : playbackRect
        })
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        this.seekVideo(seekAmount);
        window.addEventListener('mousemove', this.playheadMove);
        console.log('seekable bar mousedown');
      }

      seekableBarClick = () => {
        this.pauseVideo();
        var playbackBar = document.getElementsByClassName('playback')[0]
        var playbackRect = this.state.playbackRect
        playbackRect = playbackBar.getBoundingClientRect();
        this.setState({
            playbackRect : playbackRect
        })
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        this.seekVideo(seekAmount);
        console.log('seek clicked');
        window.removeEventListener('mousemove', this.playheadMove); 
      }

      startGrabberMouseDown = () => {
        var playbackBar = document.getElementsByClassName('playback')[0]
        var playbackRect = this.state.playbackRect
        playbackRect = playbackBar.getBoundingClientRect();
        this.setState({
            playbackRect : playbackRect
        })
        console.log('start grabber clicked');
        window.addEventListener('mousemove', this.startGrabberMove);
        window.addEventListener('mouseup', this.startGrabberDrop);
      }

      endGrabberMouseDown = () => {
        var playbackBar = document.getElementsByClassName('playback')[0]
        var playbackRect = this.state.playbackRect
        playbackRect = playbackBar.getBoundingClientRect();
        this.setState({
            playbackRect:playbackRect 
        })
        console.log('end grabber dropped');
        window.addEventListener('mousemove', this.endGrabberMove);
        window.addEventListener('mouseup', this.endGrabberDrop);
      }

      videoClick = () => {
          this.playVideo()
      }

      videoLoadData = () => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
        };
        player.end = player.video.duration;
        this.setState({
          end: player.end  
        }) 
      }

      videoTimeUpdate = () => {

        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.state.start,
            end: this.state.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
        };
        var video = document.getElementsByClassName('video')[0],
        seekRatio = video.currentTime / video.duration;
        this.setState({
            seekRatio: seekRatio
        })
        this.updateProgressBar()
        if (player.video.currentTime >= player.end) {
          this.pauseVideo();
        } 
      }

      playControlClick = () => {
          this.playVideo()
      }

    //   seekStartClick = () => {
    //     var player = {
    //         video: document.getElementsByClassName('video')[0],
    //         start: 0,
    //         end: null,
    //         controls: {
    //           play: document.getElementsByClassName('play-control')[0],
    //           seekToStart: document.getElementsByClassName('seek-start')[0],
    //           seekToEnd: document.getElementsByClassName('seek-end')[0],
    //           reset: document.getElementsByClassName('reset-control')[0]
    //         }
    //     };
    //     this.seekVideo(player.start / player.video.duration)
    //   }

    //   seekEndClick = () => {
    //     var player = {
    //         video: document.getElementsByClassName('video')[0],
    //         start: 0,
    //         end: null,
    //         controls: {
    //           play: document.getElementsByClassName('play-control')[0],
    //           seekToStart: document.getElementsByClassName('seek-start')[0],
    //           seekToEnd: document.getElementsByClassName('seek-end')[0],
    //           reset: document.getElementsByClassName('reset-control')[0]
    //         }
    //     };
    //     this.seekVideo(player.end / player.video.duration) 
    //   }

      resetClick = () => {
          this.resetVideo()
      }

      reuploadVideo = () => {
          this.setState({
            videoUrl: null,
            start: 0,
            end: null,
            videoTrimmed: false,
            frontTrimmedRatio: 0,
            endTrimmedRatio: 0
          })
      }


    render() {
        
        const {optionGroups, valueGroups} = this.state;

        return (
             this.state.videoUrl == null ?
                <div>
                    {/* Drag and drop Section  */}
                    <section className={[styles['video-section'], ''].join(' ')}>
                        <div className={[styles[''], 'container'].join(' ')}>
                            <div className={[styles[''], 'row'].join(' ')}>
                                <div className={[styles['video-title'], 'col-md-12 text-center'].join(' ')}>
                                    <h2>Create 360 car videos using Spyne’s ai- based Technology Tool</h2>
                                    <p>Convert 360 degree Video of your car into an interactive iFrame</p>
                                </div>
                                <div className={[styles['video-area'], 'col-md-6 text-center'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/360-video/video.png" />
                                </div>
                                <div className={[styles['video-area-right'], 'col-md-6 text-center'].join(' ')}>
                                    <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                <div {...getRootProps({})} className={styles['dropzone1']}>
                                                    <div className={[styles['choose-button'], 'text-center '].join(' ')}>

                                                        <button className={[styles['upload-btn-rotate']]}>
                                                            <div className={[styles['video-area-simple'], 'row m-0'].join(' ')}>
                                                                <img src="https://storage.googleapis.com/spyne-website/360-video/rotation-3.png" />
                                                            </div>
                                                            Upload New Project</button>
                                                        <h4>Drag and Drop Video</h4>
                                                        <p className={[cls['para-2'], ''].join(' ')}>or <span>SELECT VIDEO ON YOUR COMPUTER</span> </p>
                                                        <p>Supports MP4, FLV, MPEG </p>
                                                    </div>
                                                    <input onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </section>

                    
                </div>
                :
                <div>
                    {this.state.selectedNumberFrames ? 
                        <CarsThreeSixtyAfterUpload 
                            videoUrl={this.state.videoUrl}
                            planData={this.props.planData}
                            onChange={this.props.onChange}
                            multipleFileUpload={this.props.multipleFileUpload}
                            multipleFileUploadProject={this.props.multipleFileUploadProject}
                            creditUsed={this.props.creditUsed}
                            availableCredit={this.props.availableCredit}
                            allotedCredit={this.props.allotedCredit}
                            planData={this.props.planData}
                            background_id={this.props.background_id}
                            backgroundUrl={this.props.backgroundEditedUrl}
                            bgCredit={this.props.bgCredit}
                            showProjectNameModal={this.state.showProjectNameModal}
                            handleProjectName={this.handleProjectName}
                            handleProjectNameModal={this.handleProjectNameModal}
                            userId = {this.props.userId}
                            getUserCredit={this.props.getUserCredit}
                            auth_key={this.props.auth_key}
                            emailId = {this.props.emailId}
                            project_name={this.state.project_name}
                            projectNameExists = {this.state.projectNameExists}
                            handleId={this.props.handleId}
                            uploadVideoApi={this.uploadVideoApi}
                            uploadVideoStarted={this.state.uploadVideoStarted}
                            videoUploaded={this.state.videoUploaded}
                            playVideo={this.playVideo}
                            videoClick={this.videoClick}
                            onLoadedData={this.videoLoadData}
                            videoTimeUpdate={this.videoTimeUpdate}
                            start={this.state.start}
                            end={this.state.end}
                            threeSixtyUrl={this.state.threeSixtyUrl}
                            framesCompleted={this.state.framesCompleted}
                            frames={this.state.valueGroups.frames}
                        />
                        :

                        <div>
                            {this.state.videoTrimmed ? 
                                <section className={[styles['create-three-sixty'],''].join(' ')}>
                                    <div className={[styles[''],'container'].join(' ')}>
                                        <div className={[styles[''],'row'].join(' ')}>
                                            {/* <div className={[styles['video-title'],'col-md-12 text-center'].join(' ')}>
                                                <h2>Video upload status </h2> 
                                            </div>  */}
                                            <div className={[styles['video-area-right'],'col-md-8 offset-md-2 text-center'].join(' ')}>
                                                <div className={[styles['video-area-simple'],'row m-0'].join(' ')}> 
                                                    <h4>Trim your video</h4>
                                                    <video className="video" width="auto" id="yoo" autoload="metadata" onClick={this.videoClick} onLoadedData={this.videoLoadData} onTimeUpdate={this.videoTimeUpdate}>
                                                        <source src={this.state.videoUrl} type="video/mp4" />
                                                    </video>
                                                    {/* <div className="controls">
                                                        <div className="player-controls">
                                                            <button onClick={() => this.pausePlay()} className="play-control fa fa-play" title="Play/Pause"></button>
                                                        </div>
                                                    </div> */}

                                                    <div className="container">
                                                        <div className="playback">
                                                            <div className="grabber start" onMouseDown={this.startGrabberMouseDown}>
                                                                <svg version="1.1" xlinkHref="http://www.w3.org/2000/svg" x="0" y="0" width="10" height="14" viewBox="0 0 10 14">
                                                                    <path className="st0" d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="grabber end" onMouseDown={this.endGrabberMouseDown}>
                                                                <svg version="1.1" xlinkHref="http://www.w3.org/2000/svg" x="0" y="0" width="10" height="14" viewBox="0 0 10 14">
                                                                    <path className="st0" d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="seekable" onMouseDown={this.seekableBarMouseDown} onClick={this.seekableBarClick}>
                                                            </div>
                                                            <div className="progress">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="controls">
                                                        <div className="reset">
                                                            <button class="reset-control fa fa-refresh" title="Reset Video" onClick={this.resetVideo}></button>
                                                        </div>
                                                        <div class="player-controls">
                                                            {/* <button class="seek-start fa fa-step-backward" title="Skip to Start"></button> */}
                                                            <button class="play-control fa fa-play" title="Play/Pause" onClick={this.playVideo} style={{marginRight: "119px"}}></button>
                                                            {/* <button class="seek-end fa fa-step-forward" title="Skip to End"></button> */}
                                                        </div>
                                                        <div>
                                                            {/* <button class="trim-control" onclick="trimfunction()">Trim</button> */}
                                                        </div>
                                                    </div>

                                                    
                                                    <p>Note:  Trim the video from start and end to get the desired frames</p>

                                                    <p><button className={[styles['no-reupload']]} onClick={this.reuploadVideo}>No, Reupload</button> <button onClick={this.handleVideoTrimming}>Continue</button></p>
                                                </div>

                                            </div>
                                            

                                        </div>
                                    </div>
                                </section>
                                :
                                <section className={[styles['create-three-sixty'], ''].join(' ')}>
                                    <div className={[styles[''], 'container'].join(' ')}>
                                        <div className={[styles[''], 'row'].join(' ')}>
                                            <div className={[styles['video-title'], 'col-md-12 text-center'].join(' ')}>
                                                <h2>Create 360 car videos using Spyne’s ai- based Technology Tool</h2>
                                            </div>
                                            <div className={[styles['video-area-right'], 'col-md-8 offset-md-2 text-center'].join(' ')}>
                                                <div className={[styles['video-area-simple'], 'row m-0'].join(' ')}>
                                                    {/* <h4>Video upload status </h4>
                                                    <h6 className={[styles['notification']]}>Please do not close the upload tab until the video is completely uploaded</h6> */}
                                                    <h5>Select the 360 Fidelity</h5>
                                                    <p>Higher number of frames produce smoother transitions in 360 view Select the numbers of frames to preview the frame rate on a present example</p>
                                                    <div className={[styles['highlighter-box'], 'col-md-6 offset-md-3'].join(' ')}>
                                                        <div className={[styles['highlighter-box-single'], 'row m-0'].join(' ')}>
                                                        <Picker
                                                            optionGroups={optionGroups}
                                                            valueGroups={valueGroups}
                                                            onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <p><span>1 Credit = 1 Frame</span></p>
                                                    <button onClick={this.handleFrameSelected}>Proceed</button>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </section>
                            }
                            

                            
                        </div>
                        
                        

                    }
                    

                    
                </div>
                
                


        )
    }
}