import React, { Component } from 'react'

import Toggle from 'react-toggle'
import Dropzone from 'react-dropzone'
import "react-toggle/style.css"
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
import { ProgressBar, Spinner, Modal } from "react-bootstrap";

import '../../../Css/cars.css'
import styles from '../../../Css/CarsScreenTwo.module.css'
import {fetchCreditUserDetails,cookie,dealerLogoFileHandler} from '../../Common/Utils'
import LoginModal from '../../BackgroundRemoval/LoginModal';
import footwearOnlyStyles from '../../../Css/FootwearScreenTwo.module.css'
import DealershipLogo from '../../Common/DealerShipLogo'

export default class CarsBackground extends Component {
    constructor(props){
        super(props)
        this.state={
          backgroundsData:[], 
          availableCredit:5,
          angle:this.props.selectedAngle,
          angleSelected:this.props.imageSelected,
          loginStatus:false,
          show:false,
          showModal:false,
          email:window.localStorage.getItem("clippr_user_email"),
          otpScreen:false,
          otp:'',
          verification:false,
          autoLogin:false,
          toggleStatus:true,
          toggleChange:true,
          loginStatus:false,
          otpVerification:true,
          userId:'',
          availableCredit:null,
          creditId:"",
          loader:false,
          creditAlloted:null,
          creditUsed:null,
          spinner:false,
          emailValidation:true,
          logoUrl:'',
          logoPosition:null,
          askemail:'',
          isAskEmail:true,
          showInstruction:false
        }
        this.myDivToFocus = React.createRef()
    }

    handleClick = (selectedAngle) => {
      this.setState({
        angle:selectedAngle,
        angleSelected:true
      })
      var date = new Date();
      var days = 1;
      date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000
      window.document.cookie = "selectedAngle" + "=" + selectedAngle + "; expires=" + date.toGMTString() + "; path=/";
      this.props.handleAngle(selectedAngle)
    }
    handleModal = () => {
      let userEmail = cookie("emailId")
      let userId = cookie("userId")
      Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedHighRes/')
      .then((res) => {
      })
      .catch((err) => {
      })

      
        if(userEmail && userId !== 'NA'){
          if(this.props.availableCredit <= 0 && userEmail){
            window.location.replace("https://www.spyne.ai/tech/pricing")
          }
          else{
            this.props.handleDownload()
          }
        }
        else{
          this.setState({
            show:true,
            showModal:true
        })
        }
    }
    sendOTP = (e) => {
      e.preventDefault()
      let email = this.state.email
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(email.match(mailformat))
      {
          Axios({
              'method':'GET',
              "url":'https://www.spyne.ai/credit-user/register-vendor',
              "params":{
                  'emailId':this.state.email
              }
          })
          
          .then((res) => {
             this.setState({
                 otpScreen:true
             })
          })
          .catch((err) => {})
      }
      else
      {
          this.setState({
              emailValidation:false
          })
      }
     
  }
  verifyOTP = (e) => {
    e.preventDefault()
    Axios({
        'method':'GET',
        "url":'https://www.spyne.ai/credit-user/validate-otp',
        "params":{
            "emailId":this.state.email,
            'otp':this.state.otp
        }
    })
    .then((res) => {
        if(res.data.id == 200){
            this.setState({
                verification:res.data
            })
            document.cookie = `emailId=${this.state.email}; path=/`;
            window.location.reload(false)
        }
        else{
            this.setState({
                otpVerification:false
            })
        }
    })
    .catch((err) => {})
  }
  handleChange = (e) => {
    this.setState({
        [e.target.name]:e.target.value
    })
  }
  handleCloseModal = () => {
    this.setState({
        show:!this.state.show,
        showModal:false
    })
  }
  applyChange = (e) => {
    window.scrollTo(this.myDivToFocus.current.offsetTop,0) 
    this.props.handleClick()
  }
  handleAskEmail = (e) => {
    e.preventDefault()
    document.cookie = `emailId=${this.state.askemail}; path=/`;
    document.cookie = `userId=NA; path=/`;
    document.cookie = `isValidated=false; path=/`;
    this.setState({
      isAskEmail:false
    })
  }
  fileHandler = (acceptedFiles) => {
    this.setState({
      isAskEmail:true
    })
    this.props.fileSelectedHandler(acceptedFiles)
  }
  handleBackground = (imageId, imageCredit) => {
    this.setState({
      isAskEmail:true
    })
    this.props.handleId(imageId,imageCredit)
  }
  handleInstructionModal = () => {
    this.setState({
        showInstruction:true
    })
}
    render() {
      console.log(this.props.spinner)
      console.log(this.props.editedUrl)
      console.log(this.props.orgUrl)
      console.log(this.props.toggleChange)
        return (
          <>
            <div className={[styles['screen-two-main-wrapper'],''].join(' ')} ref={this.myDivToFocus}>
              <div className={[styles['left-section-with-image'],''].join(' ')} >
                <div className={[styles['with-image-inner'],''].join(' ')}>
                  {
                    !this.props.spinner && this.props.editedUrl !== "" && this.props.orgUrl !== "" && this.props.toggleChange ?
                    <div>
                     {  this.props.askEmail && this.state.isAskEmail ?
                        <div className={styles['email']}>
                          <form onSubmit={this.handleAskEmail} >
                          <img src="https://storage.googleapis.com/clippr-image-processing/Static/Group%205910.png"></img>
                            <label className="text-center">In order to view your image <br></br>Enter your email</label>
                            <input className="form-control w-100" onChange={this.handleChange} name="askemail" placeholder="Email" type="email"></input>
                            <button className="btn" type="submit">Submit</button>
                          </form>
                        </div>
                        :
                        null
                      }
                      <img src={this.props.editedUrl} className={this.props.askEmail && this.state.isAskEmail ? [styles['blured'],''].join(' ') : null}></img>
                    </div>
                    :
                    this.props.spinner && this.props.orgUrl !== "" ?
                    <div  className={[styles['image-box-area'],'d-flex'].join(' ')}>
                      <video autoPlay>
                        <source src="https://storage.googleapis.com/clippr-image-processing/Static/web%20design.mp4"></source>
                      </video>
                    </div>
                    :
                    !this.props.orgUrl == "" && !this.props.toggleChange ?
                      <img src={this.props.orgUrl} className={styles["magnifier_zoom"]}/>
                    :
                    null
                  }
                  
                </div>
                <div className={[styles['toggle-section'], "sec-2-toggle"].join(' ')}>
                    <p className="mr-5 mt-2">Original</p>
                    <label className="mt-2">
                        <Toggle onChange={this.props.handleToggle} icons={false} checked={this.props.toggleStatus} />
                    </label>
                    <p className="ml-5 mt-2">Edited</p>
                </div>
              </div>
              <div className={[styles['right-section-with-image'],''].join(' ')}>
                <center>
                  <button onClick={this.handleInstructionModal} className={[styles['sample-button'],'text-center btn'].join(' ')}>See <span style={{color:"#FF7700"}}>Sample</span> input images</button>
                </center>
                  <div className={[styles['right-top-section'],''].join(' ')} >
                      <div className={[footwearOnlyStyles['upload-section-car'],"d-flex justify-content-center"].join(' ')}>
                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()} className={footwearOnlyStyles["screen-2-drop-car"]}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg"></img>
                                    <p className="para-1">Upload Files Here</p>
                                    <p class="para-2"><b>or</b> Browse For Image</p>
                                    <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                </div>
                            </section>
                        )}
                        </Dropzone>
                    </div>
                  </div>
                  { !this.state.angleSelected ? 
                  <div className={[styles['right-mid-section'],''].join(' ')}> 
                  <p className={[styles['pricing-plan'],'d-block d-lg-none d-sm-block'].join(' ')}>See our pricing plans</p>
                        <p className={[styles['available-cre'],'d-block d-lg-none d-sm-block'].join(' ')}>Available Credit : <span>{this.props.availableCredit}</span></p>
                    <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                      <p className={[styles['center-title'],''].join(' ')}><strong>Dealership Logo</strong></p>
                      <DealershipLogo   
                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                        handleLogoPosition = {this.props.handleLogoPosition}
                        logoUrl = {this.props.logoUrl}
                        logoPosition = {this.props.logoPosition}
                        hideLogo={this.props.hideLogo}
                      />
                    </div>
                        <p className={[styles['center-title2'],''].join(' ')}><strong>Backgrounds</strong></p>
                    <div className={[styles['right-section-background-section'],''].join(' ')}>
            
                      <div className={[styles['backgrounds'],''].join(' ')}>
                          {
                            this.props.planData.map((e,i) => {
                              return(
                                <button style={{background:"none"}} key={i} className={this.props.background_id == e.imageId ? styles['active-background-images']:styles['inactive-background-images']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit)}>
                                  <img src={e.imageUrl} className={i == 0 ? styles['white-bg']:null}></img>
                                  <p className={styles['credit-para']}>{e.imageCredit} Credits</p>
                                  <p className={[styles['bg_img-texts'],'p-0 m-0 text-left'].join(' ')}>{e.bgName}</p>
                                </button>
                              )
                            })
                          }
                      </div>
                    </div>
                    <div className={[styles['right-bottom-section'],'row'].join(' ')}>
                      <div className={[styles['pricing-plan-area'],'col-md-6'].join(' ')}>
                        <a href="https://www.spyne.ai/tech/pricing" target="_blank"><p className={[styles['pricing-plan'],'d-none d-lg-block d-sm-none'].join(' ')}>See our pricing plans</p></a>
                        <p className={[styles[' '],'d-none d-lg-block d-sm-none'].join(' ')}>Available Credit : <span>{this.props.availableCredit}</span></p>
                        {/* <div className={[styles['separation-border'],''].join(' ')}></div> */}
                      </div>
                      <div className={[styles['bottom-apply-area'],'col-md-5 offset-md-1'].join(' ')}>
                        {
                          this.props.isEditingFinish ?
                          <>
                            <button onClick={this.handleModal}  className="btn">Download HD</button>
                            <p>Resolution(1280 X 720)</p>
                          </>
                          :
                          <button onClick={this.applyChange}  className="btn">Apply</button>
                        }
                      </div>
                    </div>
                  </div>
                  : null
                  }
              </div>
            
            </div>
            <div className={[styles['bottom-contact-badge'],''].join(' ')}>
                <p>Request<span> Bulk Images</span></p>
                <ul>
                  <li><a href="https://api.whatsapp.com/send?phone=+918644864461" target="_blank"><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5810.png" /></a></li>
                  <li><a href="mailto:product@spyne.ai" ><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/2.png" /></a></li>
                  <li><a href="tel:+918644864461" ><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/phonrsvg.png" style={{height:"18px",width:"18px"}}/></a></li>
                </ul>
            </div>
            <Modal
                        show={this.state.showInstruction}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='lg'
                        centered
                        onHide={() => this.setState({showInstruction:!this.state.showInstruction})}
                    >
                        <Modal.Header closeButton/>
                        <Modal.Body>
                            <center>
                                <h1>Upload Images with these <span style={{color:"#FF7700"}}>angles</span></h1>
                                <p 
                                    style={{
                                        color:"#757575"
                                    }}
                                >Our AI work best on when uploading these angles</p>
                                {/* <video className="img-fluid" autoPlay loop muted controls>
                                    <source src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/ezgif.com-gif-to-webp.webp"></source>
                                    <source src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/car+clippr1.webm"></source>
                                </video> */}
                                <img className="img-fluid" style={{
                                    height:'60%',
                                    width:'70%',
                                    borderRadius:"10px"
                                    }}src="https://storage.googleapis.com/clippr-image-processing/Static/ezgif.com-optimize%20(1).gif"></img>
                                <div className="d-flex justify-content-center mt-5">
                                    {/* <input type="checkbox" checked={this.state.showInstructionModal ? true : false} onChange={this.handleInstruction}></input> */}
                                    <p style={{
                                        marginTop:"-4px",
                                        marginLeft:"10px",
                                        color:"#5e5e5e"
                                    }}></p>
                                </div>
                            </center>

                        </Modal.Body>
                    </Modal>
            {
              this.state.showModal ? 
              <LoginModal
                show={this.handleModal}  
                sendOTP={this.sendOTP}  
                verifyOTP={this.verifyOTP}
                otpScreen={this.state.otpScreen}
                verification={this.state.verification}
                handleChange={this.handleChange}
                otpVerification={this.state.otpVerification}
                emailValidation={this.state.emailValidation}
                onHide={this.handleCloseModal}
                defaultEmail = {this.state.email}
                cars={true}
              />
              :
              null
            }
          </>
        )
    }
}