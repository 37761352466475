const marketPlace = [
    {
        'img':'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png',
        'name':'Amazon'
    },
    // {
    //     'img':'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/flipkart_logo.png',
    //     'name':'Flipkart'
    // },
    {
        'img':'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/myntra_logo.png',
        'name':'Myntra'
    },
    // {
    //     'img':'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/tatacliq_logo.png',
    //     'name':'TataCliq'
    // },
    // {
    //     'img':'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/ajio_logo.png',
    //     'name':'Ajio'
    // }
    
]
export default marketPlace