import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';
import Slider from "react-slick";
import Styles from '../../../Css/Footwear/FootwearInsufficientCredit.module.css'

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }


class FootwearInsufficientCreditModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            decription: '',
            editedImgUrl: '',
            likes: true,
        }
    }
    handleCloseModal = () => {
        
        this.props.hideThisModal()
    
    }
    
    render() { 
        const settings = { 
            infinite: false,
            slidesToShow:3,
            slidesToScroll: 1, 
            swipeToSlide: true,
            autoplay: false,
            speed: 500, 
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          };
        return ( 
            <>
                <Modal
                show={this.props.showInsufficientCreditModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                >
                    <Modal.Header onClick={this.handleCloseModal} closeButton></Modal.Header>
                    <Modal.Body className="zero">
                        <section className={[Styles['footwear-slider'],'col-md-12'].join(' ')}>
                                <div className={[Styles[''],'container-fluid'].join(' ')}>
                                    <div className={[Styles['footwear-slide-content'],'row'].join(' ')}>
                                        <div className={[Styles['footwear-slide-title'],'col-md-12'].join(' ')}>
                                            <h4>Download Full Size Image</h4>
                                        </div>
                                        <Slider {...settings}>
                                        <div className={[Styles['footwear-slide'],'col-md-12'].join(' ')}>
                                            <div className={[Styles['footwear-single'],'row m-0'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/slide1.png"/>
                                                <p>Full Image (1500 × 1500) <span>No Watermark</span> </p>
                                                <h6>5 Image = 50 Credit</h6>
                                            </div>
                                        </div>
                                        <div className={[Styles['footwear-slide'],'col-md-12'].join(' ')}>
                                            <div className={[Styles['footwear-single'],'row m-0'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/slide1.png"/>
                                                <p>Full Image (1100 × 1100) <span>No Watermark</span> </p>
                                                <h6>5 Image = 50 Credit</h6>
                                            </div>
                                        </div>
                                        <div className={[Styles['footwear-slide'],'col-md-12'].join(' ')}>
                                            <div className={[Styles['footwear-single'],'row m-0'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/slide2.png"/>
                                                <p>Full Image (1440 × 1080) <span>No Watermark</span> </p>
                                                <h6>5 Image = 50 Credit</h6>
                                            </div>
                                        </div>
                                        <div className={[Styles['footwear-slide'],'col-md-12'].join(' ')}>
                                            <div className={[Styles['footwear-single'],'row m-0'].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/slide1.png"/>
                                                <p>Full Image (1500 × 1500) <span>No Watermark</span> </p>
                                                <h6>5 Image = 50 Credit</h6>
                                            </div>
                                        </div>
                                        </Slider>
                                        <div className={[Styles['footwear-slide-title'],'col-md-12'].join(' ')}>
                                            <button onClick={event =>  window.location.href='https://www.spyne.ai/tech/pricing'} >Buy Credits now</button>
                                            <p>Buy credits & download full size image</p>
                                        </div>
                                </div>
                                </div>
                        </section>
                    </Modal.Body>
                </Modal> 
            </>    
        );
    }
}    


export default FootwearInsufficientCreditModal;  

