import React, { Component } from "react";
import '../../../App.css'
import Axios from 'axios';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dropzone from 'react-dropzone'
import styles from '../../../Css/3DBuild/SelectModelPose.module.css'
import Styles from '../../../Css/3DBuild/UploadSaree.module.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import posthog from 'posthog-js';
import {creditInfo, reduceCredit,downloadImg} from '../../Common/Utils';
import CreditModal from '../../Common/Modal/CreditModal'
import DownloadCompleteModal from '../../Common/Modal/DownloadCompleteModal'
import {Helmet} from "react-helmet";



export default class UploadSaree extends Component {

    constructor(props){
        super(props)
        this.state={ 
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            toggleChange:true,
            loginStatus:false,
            otpVerification:true,
            userId:'',
            availableCredit:0,
            creditId:"",
            loader:false,
            creditAlloted:null,
            creditUsed:null,
            spinner:false,
            emailValidation:true,
            downloadType:"",
            // rating:false
            showInsuffCredModal:false,
            downloadImage:"",
            dropBackground: false,
            showImage:'',
            selectedIndex:0,
            bgCredit:0,
        }


    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
     
      handleCloseModal = () => {
          this.setState({
              show:!this.state.show,
              showModal:false
          })
      }
        
    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat))
        {
            Axios({
                'method':'GET',
                "url":'https://www.spyne.ai/credit-user/register-vendor',
                "params":{
                    'emailId':this.state.email
                }
            })
            
            .then((res) => {
               this.setState({
                   otpScreen:true
               })
            })
            .catch((err) => {})
        }
        else
        {
            this.setState({
                emailValidation:false
            })
        }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":this.state.email,
                'otp':this.state.otp
            }
        })
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                verification:res.data
                })
                
            if(this.state.verification){
                posthog.people.set({email: this.state.email,website: "clippr.ai"});
            }
            document.cookie = `emailId=${this.state.email}`;
            window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
        .catch((err) => {})
    }
    handleToggle = (e) => {
        this.setState({
            spinner:true
        })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    spinner:false
                });
            }
            .bind(this),
            500
        );
    }

    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus:true,
            dropBackground: false,
            downloadType:"",
        })
        this.props.fileSelectedHandler(acceptedFiles)
    } 
    handleDownloadType=(DT)=>{
        // console.log(DT)
        // console.log(this.props.wm_image)

        if(DT=="HD"){
            this.setState({
                downloadType:"HD",
                downloadImage:this.props.hd_image
            })

        }
        else if(DT=="Basic") {
            this.setState({
                downloadType:"Basic",
                downloadImage:this.props.wm_image
            })
        }
    }
    handleDownload = () => {
        this.props.handleDownloadModal(this.state.downloadType);
    }

    handleRatingModal=()=>{

        this.setState({
            rating:true
        })
    }

    hideRatingModal=()=>{
        this.setState({
            rating:!this.state.rating
        })
    }
    hideInsufficientCreditModal=()=>{
        this.setState({
            showInsuffCredModal:!this.state.showInsuffCredModal
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
    }
    dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    changeImage = (imageid) => {
        this.setState({showImage:this.props.editedImage[imageid],selectedIndex:imageid});
    }






    render() {
        return  (

        <><Helmet>
                <title>Best 3D Saree Rendering | Saree Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content="Change Your Saree Background to Market Places Ready Images in Just 5 Seconds with Spyne Saree Photo Editing Tech. Instantly Change Image Background for Saree and give your Saree Photography a new look." />
                <meta property="og:title" content="Best 3D Saree Rendering | Saree Photo Editor.Try Now" />
                <meta property="og:description" content="Change Your Saree Background to Market Places Ready Images in Just 5 Seconds with Spyne Saree Photo Editing Tech. Instantly Change Image Background for Saree and give your Saree Photography a new look." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
            </Helmet><Dropzone onDrop={(acceptedFiles) => this.fileHandler(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                    {({ getRootProps, getInputProps }) => (
                        <section className={[styles['main-box'], ''].join(' ')}>
                            <div  {...getRootProps({
                                onClick: event => event.stopPropagation()
                            })} className={styles["dropzone1"]}>
                                {this.state.dropBackground ?
                                    <section className={[styles['drop-box-text']]}>
                                        <div>Drop Image Anywhere</div>

                                    </section>
                                    : null}
                                <section className={[styles['select-model'], ''].join(' ')}>
                                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                                        <div className={[styles[''], 'row'].join(' ')}>
                                            <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className={[styles['upload'], 'col-md-12'].join(' ')}>
                                                        <h6><button {...getRootProps()} className={[styles['upload-btn']]}>
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload Image
                                                            <input onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                        </button></h6>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>

                                        <div className={[styles['model-area'], 'row'].join(' ')}>

                                            <div className={[styles[''], 'offset-md-1 col-md-10 col-lg-6 offset-lg-6'].join(' ')}>
                                                <div className={[styles['credit-area'], 'row'].join(' ')}>
                                                    <div className={[styles['available-credit'], 'col-md-6 col-sm-8 col-8'].join(' ')}>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />Available Credits: &nbsp;
                                                        <span> {this.props.availableCredit}</span>
                                                    </div>
                                                    <div className={[styles['right-top-credits'], 'col-md-6 col-4 col-sm-4'].join(' ')}>
                                                        <a href="https://www.spyne.ai/tech/pricing"><button>
                                                            <span className={[styles['add-credit'], ''].join(' ')}> Add Credits</span> </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={[Styles[''], 'col-md-12'].join(' ')}>
                                                <Tabs className={[Styles['custom-saree'], 'row'].join(' ')} selectedIndex={this.state.selectedIndex}>
                                                    <TabList className={[Styles['custom-tabs'], 'col-md-1 custom-tabs'].join(' ')}>

                                                        {this.props.loader ?
                                                            this.props.posearr.map((e, i) => {
                                                                return (
                                                                    <SkeletonTheme key={i} color="#ffd5b0">
                                                                        <div>
                                                                            <Skeleton className={[Styles['download-skelton4']]} />
                                                                        </div>
                                                                    </SkeletonTheme>
                                                                );
                                                            })
                                                            :
                                                            this.props.editedImage.map((image, i) => {
                                                                return (
                                                                    <Tab onClick={() => this.changeImage(i)}><img key={i} src={image} /></Tab>
                                                                );

                                                            })}
                                                       
                                                    </TabList>
                                                    {this.props.posearr.map((image, i) => {
                                                        return (
                                                            <TabPanel key={i} className={[styles[''], 'col-md-6 display-none'].join(' ')}>

                                                                {!this.state.toggleStatus && !this.state.toggleChange ?
                                                                    <div className={[Styles['custom-big-image'], Styles['toggle-section'], 'row m-0 toggle-section'].join(' ')}>
                                                                        {this.state.spinner ?

                                                                            <SkeletonTheme color="#ffd5b0">
                                                                                <div>
                                                                                    <Skeleton className={[Styles['skelton']]} />
                                                                                </div>
                                                                            </SkeletonTheme>

                                                                            :
                                                                            <img src={this.props.originalImage} />}

                                                                    </div>
                                                                    :
                                                                    <div className={[Styles['custom-big-image'], Styles['toggle-section'], 'row m-0 toggle-section'].join(' ')}>
                                                                        {!this.props.loader && !this.state.spinner ?
                                                                            this.state.showImage.length > 0 ?
                                                                                <img src={this.state.showImage} />
                                                                                :
                                                                                <img src={this.props.editedImage[0]} />
                                                                            :
                                                                            this.state.spinner ?
                                                                                <SkeletonTheme color="#ffd5b0">
                                                                                    <div>
                                                                                        <Skeleton className={[Styles['skelton']]} />
                                                                                    </div>
                                                                                </SkeletonTheme>
                                                                                :
                                                                                <SkeletonTheme color="#ffd5b0">
                                                                                    <div>
                                                                                        <Skeleton className={[Styles['skelton']]} />
                                                                                    </div>
                                                                                </SkeletonTheme>}

                                                                    </div>}
                                                                {!this.props.loader ?
                                                                    <div className="sec-2-toggle">
                                                                        <p className="mr-5 mt-2">Original</p>
                                                                        <label className="mt-2">
                                                                            <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                                                                        </label>
                                                                        <p className="ml-5 mt-2">Edited</p>
                                                                    </div>
                                                                    : null}
                                                            </TabPanel>
                                                        );
                                                    })}
                                                   
                                                    <div className={[Styles['download-popup-area'], 'col-md-5'].join(' ')}>
                                                        <div className={[Styles['download-popup'], 'row'].join(' ')}>
                                                            <div className={[Styles['cross-btn'], ''].join(' ')}>

                                                                {/* <img  className={Styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/> */}
                                                            </div>
                                                            {this.props.loader ?
                                                                <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0">
                                                                    <div>
                                                                        <Skeleton className={[Styles['download-skelton']]} />
                                                                    </div>
                                                                </SkeletonTheme>
                                                                :
                                                                <div className={[Styles['total-credits'], ''].join(' ')}>
                                                                    <h6>Credit summary</h6>
                                                                    <h5>Poses: {this.props.editedImage.length} </h5>
                                                                    <h5>Total credits: <span>{this.props.editedImage.length} credits</span></h5>
                                                                </div>}
                                                            {this.props.loader ?
                                                                <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0">
                                                                    <div>
                                                                        <Skeleton className={[Styles['download-skelton']]}  />
                                                                    </div>
                                                                </SkeletonTheme>
                                                                :

                                                                <button onClick={() => this.handleDownloadType("HD")} className={[Styles['activate'], 'col-md-12 '].join(' ')}>
                                                                    <h6><input type="radio" checked={this.state.downloadType == "HD"} />HD Download</h6>
                                                                    <ul>
                                                                        <li>Full Image 1920 × 1080 </li>
                                                                        <li>Without Watermark</li>
                                                                    </ul>
                                                                    {/* <p>Price : <span>12 Credit/Image</span></p> */}
                                                                </button>}
                                                            <div className={[Styles['border-1'], ''].join(' ')}></div>
                                                            {this.props.loader ?
                                                                <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0">
                                                                    <div>
                                                                        <Skeleton className={[Styles['download-skelton3']]}  />
                                                                    </div>
                                                                </SkeletonTheme>
                                                                :
                                                                <button onClick={() => this.handleDownloadType("Basic")} className={[Styles['activate'], 'col-md-12 '].join(' ')}>
                                                                    <h6><input type="radio" checked={this.state.downloadType == "Basic"} />Basic Download</h6>
                                                                    <ul>
                                                                        <li>Preview Image 640 × 360 </li>
                                                                        <li>With Watermark</li>
                                                                    </ul>
                                                                    <p>Price: <span>Free</span></p>
                                                                </button>}

                                                            <div className="text-center">
                                                                {this.props.loader ?
                                                                    <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0">
                                                                        <div>
                                                                            <Skeleton className={[Styles['download-skelton2']]}  />
                                                                        </div>
                                                                    </SkeletonTheme>
                                                                    :
                                                                    <button onClick={this.handleDownload} disabled={this.state.downloadType.length > 0 ? false : true} className={[Styles['download-btn-hd']]}>Download</button>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Tabs>

                                            </div>
                                        </div>
                                        <CreditModal
                                            showCreditModal={this.state.creditModal}
                                            handleCreditModal={this.handleCreditModal}
                                            redirectPricePage={this.redirectPricePage}
                                            bgCredit={this.props.bgCredit}
                                            editedUrl={this.props.editedImage} />

                                        <DownloadCompleteModal
                                            showDownloadModal={this.props.downloadComplete}
                                            editedUrl={this.props.editedImage[0]}
                                            handleDownloadComplete={this.props.handleDownloadComplete}
                                            orgUrl={this.props.originalImage} />
                                    </div>
                                </section>
                                <input onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                            </div>
                        </section>
                    )}
                </Dropzone></>  
          
                                    
          
         

                );
        }
    }
