


export const data = [
    { 
      src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal12.png`, 
    },
    { 
      src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal13.png`, 
    },
    { 
      src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal3.png`, 
    },
    { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal15.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal41.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal7.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal20.png`,  
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal21.png`,  //End First Col data
      },

      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal9.png`,  
      }, 
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal16.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal17.png`,  
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal18.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal22.png`,  
      },{ 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal10.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal23.png`,  //End 2nd Col data
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal8.png`,  
      },

      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal27.png`,  
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal5.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal19.png`,  
      },{ 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal11.png`, 
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal24.png`,  
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal25.png`,  
      },
      { 
        src: `${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/spyne-website/Facebook-template/gal26.png`,  //End 3rd Col data
      },

  ];
 