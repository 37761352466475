import React, { Component, useRef, useState } from 'react'

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Toggle from 'react-toggle'
import Dropzone from 'react-dropzone'
import "react-toggle/style.css"
import ReactTooltip from 'react-tooltip';
// import Overlay from 'react-image-overlay'
import Axios from 'axios';
import { ProgressBar, Spinner, Modal } from "react-bootstrap";
import Pricing from '../../../../src/Component/BackgroundRemoval/pricing'
import Styles from '../../../Css/FootwearV2.module.css'
import LandingModal from '../../Common/Modal/LandingModal'


import '../../../Css/cars.css'
import styles from '../../../Css/CarsScreenTwo.module.css'
import {fetchCreditUserDetails,cookie,dealerLogoFileHandler} from '../../Common/Utils'
import LoginModal from '../../BackgroundRemoval/LoginModal';
import CarsOutputModal from '../../Common/Modal/BackgroundListModal'
import footwearOnlyStyles from '../../../Css/FootwearScreenTwo.module.css'
import DealershipLogo from '../../Common/DealerShipLogo'
import {data} from '../../Common/Static/overlays'

import { InlineWidget } from "react-calendly";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import BackgroundListModal from '../../Common/Modal/BackgroundListModal'
import CreditModal from '../../Common/Modal/CreditModal'
import DownloadCompleteModal from '../../Common/Modal/DownloadCompleteModal'

// import interact from 'interactjs'

import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { event, type } from 'jquery';
// import ReactCompareImage from 'react-compare-image';

import { ReactCompareSlider, ReactCompareSliderImage,ReactCompareSliderHandle } from 'react-compare-slider'
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'
import { link } from 'fs';
import { color } from 'jimp';
import LogoCropModal from '../../Common/Modal/LogoCropModalcopy';
import NumberPlateLogo from '../../Common/NumberPlateLogo';


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" ,    left: "-15px"}}
      onClick={onClick}
    />
  );
}
function SampleNextArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" ,right : "-20px"}}
      onClick={onClick}
    />
  );
}



export default class CarsBackground extends Component {
    constructor(props){
        super(props)
        this.state={
          backgroundsData:[], 
          availableCredit:5,
          angle:this.props.selectedAngle,
          angleSelected:this.props.imageSelected,
          loginStatus:false,
          show:false,
          showModal:false,
          email:window.localStorage.getItem("clippr_user_email"),
          otpScreen:false,
          otp:'',
          verification:false,
          autoLogin:false,
          toggleStatus:true,
          toggleChange:true,
          loginStatus:false,
          otpVerification:true,
          userId:'',
          availableCredit:null,
          creditId:"",
          loader:false,
          creditAlloted:null,
          creditUsed:null,
          spinner:false,
          emailValidation:true,
          logoUrl:'',
          below_bg:false,
          logoPosition:null,
          askemail:'',
          isAskEmail:true,
          showInstruction:false,
          overlays_shown:false,
          overlays_cat_clicked:"hb",
          overlays_icon_click:true,
          overlays_icon_click_src:"",
          // overlayiconclicked:false,
          overlays_toggle_show:false,
          carsModal: true,
          // overlays_toggle_show:true,
          active_category:"hb",
          active_overlay:"overlay_1",
          floorSelected: false,
          floor_img_url: '',
          autoplay:false,
          activButton: "Exterior",
          actiButton:"Background1",
          isDownload: false,
          showBackgroundList: false,
          creditModal: false,
          dropBackground: false,
          uploadWallUrl:"",
          visualSelection:"virtual_studio",
          applyWay:"",
          tempCustomWall:"",
          tempPosition:0,
          showDelPopup:false,
          delWall:false,
          wallIndex:"",
          new_width:0,
          left_wall_padding:0,
          new_height:0,
          src: null,
          cropDown:false,
          refBlob:null,
          crop: {
            unit: "%",
            width:0,
            height:0,
            aspect: undefined
          },
          dimensions:[0,0,0,0],
          startCrop:false
        }
        this.myDivToFocus = React.createRef()
        this.wallFile=React.createRef()
        // this.props.handledefwalls()
        this.orgImg = React.createRef()
        this.wallImg = React.createRef()

    }
    handleBackgroundList = () => {
      this.setState({showBackgroundList: true})
    }

    hideList = () => {
      this.setState({showBackgroundList: false})
    }

    handleClick = (selectedAngle) => {
      this.setState({
        angle:selectedAngle,
        angleSelected:true
      })
      var date = new Date();
      var days = 1;
      date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000
      window.document.cookie = "selectedAngle" + "=" + selectedAngle + "; expires=" + date.toGMTString() + "; path=/";
      this.props.handleAngle(selectedAngle)
    }

    handleUploadClick = (event)=>{
      event.preventDefault()
      this.wallFile.current.click()

    }

    handleUpload = () =>{
      // console.log(this.wallFile.current.files)
      this.wallfileHandler(this.wallFile.current.files)
    }


    handleModal = () => {
      this.setState({
        cropDown:true
      })
      let userEmail = cookie("emailId")
      let userId = cookie("userId")
      // Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedHighRes/')
      // .then((res) => {
      // })
      // .catch((err) => {
      // })

      // this.props.handleDownload()
        if(userEmail && userId !== 'NA'){
          if(this.props.availableCredit < ( 1+this.props.chargedFeature ) && userEmail && this.props.activateButton === "Hd"){
            this.setState({creditModal: true})
          }
          else{
            
            this.props.handleDownload()
            
          }
        }
        else{
          this.setState({
            show:false,
            showModal:false
        })
        }
    }
    handleCreditModal = () => {
      this.setState({creditModal:false})
   }
   redirectPricePage = () => {
    window.location.replace('https://www.spyne.ai/tech/pricing')
    this.setState({creditModal:false})
 }

    handleActivateButton = (name) => {
      this.setState({
          activateButton:name
      })
  }
    sendOTP = (e) => {
      e.preventDefault()
      let email = this.state.email
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(email.match(mailformat))
      {
          Axios({
              'method':'GET',
              "url":'https://www.spyne.ai/credit-user/register-vendor',
              "params":{
                  'emailId':this.state.email
              }
          })
          
          .then((res) => {
             this.setState({
                 otpScreen:true
             })
          })
          .catch((err) => {})
      }
      else
      {
          this.setState({
              emailValidation:false
          })
      }
     
  }
  verifyOTP = (e) => {
    e.preventDefault()
    Axios({
        'method':'GET',
        "url":'https://www.spyne.ai/credit-user/validate-otp',
        "params":{
            "emailId":this.state.email,
            'otp':this.state.otp
        }
    })
    .then((res) => {
        if(res.data.id == 200){
            this.setState({
                verification:res.data
            })
            document.cookie = `emailId=${this.state.email}; path=/`;
            window.location.reload(false)
        }
        else{
            this.setState({
                otpVerification:false
            })
        }
    })
    .catch((err) => {})
  }
  handleChange = (e) => {
    this.setState({
        [e.target.name]:e.target.value
    })
  }
  handleCloseModal = () => {
    this.setState({
        show:!this.state.show,
        showModal:false
    })
  }
  applyChange = (e) => {

    // window.scrollTo(this.myDivToFocus.current.offsetTop,0) 
    this.setState({
      isDownload: true,
      showBackgroundList: false,
      cropDown:false,
      crop:{
        aspect:undefined
      },
      startCrop:false
    })
    this.props.handleClick()
    // this.props.handleCroppedImage(this.props.editedUrl)
    console.log(this.props.croppedUrl)
    // console.log(this.state.visualSelection)
    
  }
  handleAskEmail = (e) => {
    e.preventDefault()
    document.cookie = `emailId=${this.state.askemail}; path=/`;
    document.cookie = `userId=NA; path=/`;
    document.cookie = `isValidated=false; path=/`;
    this.setState({
      isAskEmail:false
    })
  }
  fileHandler = (acceptedFiles) => {
    this.setState({
      isAskEmail:true,
      dropBackground: false,
      isDownload: false
    })
    this.props.fileSelectedHandler(acceptedFiles)
  }
  // fileHandlerNew = (acceptedFiles) => {
  //   this.setState({
  //     isAskEmail:true
  //   })
  //   this.props.fileSelectedHandlerNew(acceptedFiles)
  // }
  

  handleUnshiftWalls = (data) =>{
    this.props.handleUnshiftWalls(data)
    if(this.state.visualSelection=="custom_walls"){
      this.props.customWallSet(data["wall_png_url"])
    }
  }

  wallfileHandler = (acceptedFiles) => {
      // console.log(acceptedFiles[0])
    
      //If user is uploading image with choose Image Button
      let auth_key = this.cookie('auth_token')
      const fd = new FormData();
      fd.append("image", acceptedFiles[0]);
      fd.append("optimization",false)
      let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
      Axios({
        'method':'POST',
        "url":'https://www.clippr.ai/api/upload',
        "params":{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"},
        "data":fd
      }).then((res)=>{
      
      const wallfd = new FormData();
      wallfd.append("wall_name",acceptedFiles[0]["name"].split('.')[0])
      wallfd.append("wall_png_url", res.data.image)
      wallfd.append("auth_key",auth_key)
      wallfd.append("category","cat_d8R14zUNE")
      Axios({
        'method':'POST',
        "url":'https://www.clippr.ai/api/v2/backgrounds/customWall-upload',
        "data":wallfd
      }).then((wall_output)=>{
      this.handleUnshiftWalls(wall_output.data)
    })
    })
      
      
  }

  handleBackground = (imageId, imageCredit,below_bgs) => {
    this.setState({
      isAskEmail:true,
      below_bg:below_bgs
    })
    this.props.handleId(imageId,imageCredit,below_bgs)
  }






  overlayclicked = (data_overlay)  => {
    this.setState({
      active_category:data_overlay,
      overlays_shown:true,
      overlays_cat_clicked:data_overlay,
      overlays_icon_click:true,
      overlays_icon_click_src:(`https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/${data_overlay}_w/36_${data_overlay}_w.png`),
      active_overlay:"overlay_0",
      overlays_toggle_show:true,
    })
    // console.log(this.state.overlays_icon_click_src)
    // console.log(this.state.overlays_icon_click)


    // this.overlays_button()
    
  }
  





  overlayiconclicked = (overlay_src, overlay_index)  => {
    // alert(overlay_src)
    this.setState({
      overlays_icon_click:true,
      overlays_toggle_show:true,
      overlays_icon_click_src:overlay_src,
      active_overlay:overlay_index,

    })
    // alert(this.state.overlays_icon_click_src)

  }

  floorClicked = (floor_src) => {
    this.setState({
      floorSelected: true,
      floor_img_url: floor_src,
    })
    // console.log(this.state.floor_img_url)
  }
  cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
  componentDidMount = () => {
    let userEmail = cookie("emailId")
    let userId = cookie("userId")
    fetchCreditUserDetails(userId)
    .then((creditResponse) => {
      console.log(creditResponse.data)
    })
    this.overlayiconclicked(`https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/36_hb_w.png`, "overlay_0")
    interact('.resizable')
      .resizable({
        edges: { top: false, left: false, bottom: true, right: false },
        listeners: {
          move :(event) =>{
            let { x, y } = event.target.dataset

            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top

            if (event.rect.height>this.state.container_height){
              // console.log(387)
              event.rect.height=this.state.container_height
            }
            if (event.rect.height<20){
              // console.log(387)
              event.rect.height=20
            }
            let pos= (event.rect.height/this.state.container_height)*100
            this.handleSetPosition(pos,event.rect.height)
            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${x}px, ${y}px)`
            })

            Object.assign(event.target.dataset, { x, y })
          }
        }
      })
  }

  handleOverlayToggle = () =>{
    this.setState({
      overlays_icon_click:!this.state.overlays_icon_click
    })
  }
  

  hideCarsModal = () => {
    this.setState({carsModal:!this.state.carsModal})
  }

  handleFloorClick = (e,newUrl) => {
    e.preventDefault()
    this.floorClicked(newUrl)
  }
  handlewalls = (wall_selected) => {
    // console.log(wall_selected)
    this.props.handlewalls(wall_selected)
  }
  customWallSets= (wall_selected) => {
    // console.log(wall_selected)
    this.setState({
      tempCustomWall:wall_selected,
      showBackgroundList: false

    })
  // console.log(this.props.wall)
  this.props.customWallSet(wall_selected)
  }


  downloadPopup = () => {
    this.setState({
      isDownload: false
    })
    this.props.handleToggle()
    
  }

  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }

  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }

  handleVisualSelection =(selection)=>{
    this.setState({
      visualSelection:selection,
      applyWay:""
    })
    this.props.handleTabSelection(selection)
    if(selection=="custom_walls"){
      this.handleSliderwidth()
    }
}
handleToggleImage=()=>{
  this.props.handleToggle()
  if (this.props.toggleStatus==false && this.props.editedUrl !=""){
    this.setState({
      isDownload:true
    })
  }
  else if (this.props.toggleStatus==true){
    this.setState({
      isDownload:false
    })
  }
  
}
handleSetPosition = (position, wall_height) => {
  this.props.handlePositionChange(position)
  
  this.setState({
    tempPosition:position,
    wallHeight:wall_height
  })
  // console.log("position"+ position)
}

handleSliderwidth = () => {
  let contW=document.getElementById("slider_div").clientWidth;
  let contH=document.getElementById("slider_div").clientHeight;
  // let cont_aspRat=contW/contH;
  // var img_aspRat=0;
  var myImg = document.querySelector("#orgImg_2");
  var imgW = myImg.naturalWidth;
  var imgH = myImg.naturalHeight;
  // var imgW=document.getElementById("orgImg").width
  // var imgH=document.getElementById("wallImg").height
  // console.log("new")
  // console.log(imgW)
  // console.log(imgH)

  // img_aspRat=imgW/imgH;
  var ratio_diff= contH/imgH;
  var new_imgW=imgW*ratio_diff;
  var new_imgH=imgH*ratio_diff;
  var new_wall_width=(new_imgW/contW)*100
  new_wall_width=String(new_wall_width)+"%"
  var left_padding=String((contW-new_imgW)/2)+"px"
  // console.log(left_padding)

  this.setState({
    new_width:new_wall_width,
    left_wall_padding:left_padding,
    container_height:contH,
    container_width:contW
  })

  // var crop_height= (contH*position)/100;
  // var height_diff=(new_imgH-contH)/2;

  // var new_crop_height=crop_height+height_diff;

  // var new_position=(new_crop_height/new_imgH)*100  + 0.15;

  // this.props.handlePositionChange(new_position)
  // this.setState({
  //   tempPosition:position
  // })
}

partialapply =(partialApplyWay)=>{
  this.setState({
    applyWay:partialApplyWay,
    // below_bg:false
  })
  if(partialApplyWay=="manually"){
    if (this.state.tempCustomWall.length>0){
      this.props.customWallSet(this.state.tempCustomWall)
    }
    else{
      this.props.customWallSet(this.props.wallData[0]["wall_png_url"])
    } 
  }
  if(partialApplyWay=="magically"){
    this.props.handlePositionChange(0)
  }
  // console.log(this.props.wallData[0]["wall_png_url"])
}
handleCloseModal = () => {
  if(this.state.flagOTP) {
      this.props.hideModal()
  }
}

showInstModal=()=>{
    this.setState({
      showInstruction:true
  })
  
}
handleCarsFavList =(i)=>{
  
  this.props.handlefavList(i)
}
handleCarsBgList =(i)=>{
  
  this.props.handleBgList(i)
}
delWallapi=()=>{
  // const fd = new FormData();
  let auth_key = this.cookie('auth_token')
  let wall=this.props.wallData[this.state.wallIndex]
  let wall_id=wall["wall_id"]
  const wallfd = new FormData();
  
  wallfd.append("wall_Id", wall_id);
  wallfd.append("auth_key",auth_key)
  // wallfd.append("api_key","fde46c58-5735-4fcf-8b38-980c95001dc3")
  // let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
  Axios({
    'method':'POST',
    //"url":'https://www.clippr.ai/api/wall-delete',
    "url":'https://www.clippr.ai/api/v2/backgrounds/customWall-delete',
    "data":wallfd
  }).then((res)=>{
    if (res.data.status_code==200)
    {
      this.props.wallData.splice(this.state.wallIndex,1)
    }
    this.setState({
      wallIndex:0
    })
  })
}
delWallShowModal=(i)=>{
  this.setState({
    delWall:true,
    wallIndex:i
  })
  // this.props.wallData.splice(i,1)
}
delWallModal=()=>{
  this.setState({
    delWall:false
  })
  this.delWallapi()
}
delWallModalHide=()=>{
  this.setState({
    delWall:false
  })
}
modalOnHide=()=>{
  this.setState({
    showInstruction:false
  })
}
fileHandlerNew = (acceptedFiles) => {
  // console.log(acceptedFiles.target)
  this.props.multipleFileUploadProject(acceptedFiles)
}

crop_Ratio=(ratio)=>{
  this.setState({
    startCrop:true
  })
  if(ratio==16/9){
  this.setState({
    crop:{
      x:0,
      y:0,
      width:160,
      height:90,
      aspect:ratio
    }
  })}
  else if(ratio==4/3){
    this.setState({
      crop:{
        x:0,
        y:0,
        width:160,
        height:120,
        aspect:ratio
      }
    })}
  else if(ratio==1/1){
    this.setState({
      crop:{
        x:0,
        y:0,
        width:160,
        height:160,
        aspect:ratio
      }
    })}
  else if(ratio==null){
    this.setState({
      crop:{
        x:0,
        y:0,
        width:150,
        height:150,
        aspect:ratio
      }
    })}
}
onImageLoaded = (image) => {
  this.imageRef = image;
};

onCropComplete = (crop) => {
  this.makeClientCrop(crop);
};

onCropChange = (crop) => {
  // You could also use percentCrop:
  // this.setState({ crop: percentCrop });
  this.setState({ crop});
};

async makeClientCrop(crop) {
  if (this.imageRef && crop.width && crop.height) {
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRef,
      crop,
      "newFile.jpeg"
    );
    this.setState({ croppedImageUrl });
  }
}
getCroppedImg(image, crop, fileName) {
  
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  let contW=document.getElementById("slider_div").clientWidth;
  let contH=document.getElementById("slider_div").clientHeight;
  console.log(contW+ ' '+ contH)
  console.log(image.naturalWidth+' ' + image.naturalHeight)
  console.log(image.width+' ' + image.height)
  
  
console.log(crop)
// console.log(crop.x+' '+crop.y+' '+crop.width+' '+crop.height)
  var cropDim=[crop.x,crop.y,crop.width,crop.height]

  this.setState({
    dimensions:cropDim
  })
// console.log(cropDim)
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      blob.name = fileName;
      window.URL.revokeObjectURL(this.fileUrl);
      this.setState({
        refBlob:blob
      })
      this.fileUrl = window.URL.createObjectURL(blob);
      resolve(this.fileUrl);
    }, "image/jpeg");
  });
}
crop = (bool) => {
  if(bool){

     this.setState({
       crop:{
         x:0,
         y:0,
         width:this.state.crop.width,
         height:this.state.crop.height,
         aspect:this.state.crop.aspect
       }
     })
    //  this.state.dimensions[0]: +x
     console.log(this.state.refBlob)
     this.props.handleCroppedImage(this.state.refBlob,this.state.dimensions)
   
     
}
else if (!bool && this.props.cropperList.length!=1){
  this.setState({
    crop:{
      x:(this.props.cropperList[0][1])[0]-(this.props.cropperList[1][1])[0],
      y:(this.props.cropperList[0][1])[1]-(this.props.cropperList[1][1])[1],
      width:640-(this.props.cropperList[0][1])[0]-(this.props.cropperList[0][1])[2],
      height:360-(this.props.cropperList[0][1])[3]-(this.props.cropperList[0][1])[1],
      aspect:this.state.crop.aspect
    }
  })
  console.log(this.state.crop)
  this.props.handleCroppedImage(null)
}
};



    render() {
      // console.log(data)
      
      // const [activatedNumber, setActivateNumber] = useState<number>(0);
      // const NextButton = <button onClick={() => setActivateNumber(activatedNumber + 1)}>Next</button>;
  
      // const coachList: Array<ICoachProps> = [
      //     {
      //         activate: activatedNumber === 0,
      //         component: <div>I can do this {NextButton} </div>,
      //         reference: this.props.ref1,
      //         tooltip: { position: 'bottom' }
      //     }
      // ]
        const editedImage = this.props.backgroundUrl.map((newUrl) => {
            return <div className={[styles['perfect-output-images'],'col-md-4'].join(' ')}>
                      <div onClick={()=> this.floorClicked(newUrl)}>
                        <img src={newUrl} alt=""/>
                      </div>
                      {/* <p>Slate</p> */}
                  </div>
          })
      const settings = {
        // className: "center",
        // dots: true,
        infinite: false,
        slidesToShow:4,
        slidesToScroll: 1,
        // centePadding:"1px",
        // vertical: true,
        // verticalSwiping: true,
        swipeToSlide: true,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 7000
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
      const settings_2 = {
        // className: "center",
        // dots: true,
        infinite: false,
        slidesToShow:5,
        slidesToScroll: 1,
        // centePadding:"1px",
        // vertical: true,
        // verticalSwiping: true,
        swipeToSlide: true,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 7000
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ],
        nextArrow: <SampleNextArrow_1 />,
        prevArrow: <SamplePrevArrow_1 />
      }; 
      const backLeft = this.props.planData.length - 5
      const spyne_backLeft = this.props.bg_list.length - 9
      const fav_backLeft = this.props.fav_list.length - 4
      const customWallLeft=this.props.wallData.length - 6
      const { crop, croppedImageUrl } = this.state;

        return (
          <>
            <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
              {({getRootProps, getInputProps}) => (
                  <section>
                      <div {...getRootProps({
                        onClick: event => event.stopPropagation()
                      })} className={footwearOnlyStyles["screen-2-drop-car"]} >
                          {this.state.dropBackground ?
                            <section className={[styles['drop-box-text']]}>
                              <div>Drop Image Anywhere</div>  
                            
                            </section>
                            : null
                          }
                          
                          <section className={[styles['perfect-output'],''].join(' ')}>
                              <div className={[styles['upload-sec'],"container"].join(" ")}>
                                  {/* <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                      <input 
                                      style={{ display: "none" }}
                                      type="file"
                                      onChange={this.fileHandlerNew}
                                      ref={(fileInput) => (this.fileInput = fileInput)}
                                      ></input>
                                      <button  onClick={() => this.fileInput.click()} className=""><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" /> Upload Image</button>
                                  </div> */}
                                  <div className={[styles['dropzone-area'],'row'].join(' ')}>
                                        <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                              {({getRootProps, getInputProps}) => (
                                                  <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                      <div {...getRootProps({})} className={styles['dropzone1']}>
                                                          <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                              
                                                              <button className="">
                                                                  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                                  Upload New Project</button>
                                                          </div>
                                                          <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                      
                                                      </div>
                                                  </section>
                                              )}
                                        </Dropzone>
                                        <h6 className={[styles['product-hunt-tag'],''].join(' ')}>
                                            <a href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                                <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                                alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                              <div className={[styles['car-try-inner'],""].join(" ")}>
                                  <div className={[styles['angle-sec']]}>
                                    <div className="container-fluid">
                                      <div className={[styles['top-bar'],"row"].join(" ")}>
                                        <div className={[styles['car-mobile-angle'],"col-md-7 col-12"].join(" ")}>
                                            <div className="row">
                                                <div className="col-8 col-md-6" >
                                                How to Shoot your car?&nbsp;
                                                <span style={{color:"#FF7700", textDecoration :"underline", cursor:"pointer"}} onClick={()=>this.showInstModal()}>
                                                 Click Here</span> 
                                                </div>
                                            </div>
                                            
                                        
                                        </div>
                                      {/* MobileView */}
                                        {/* <div  className={[styles['overlay-box-mob'],'row m-0'].join(' ')} >
                                            <div className={[styles['car-overlay-cat-mob'],'col-md-12'].join(' ')}> 

                                              <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')}  onClick={()=>this.overlayclicked("hb")} style={this.state.active_category=="hb"?{borderBottom :"3px  solid #FF7700" , color:"#FF7700"  }:null } >
                                                {
                                                  this.state.active_category=="hb"?
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/HB_orange.png" />
                                                  :
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/HB_black.png" />
                                                }
                                                <span>Hatchback</span></button>
                                                

                                              <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')}   onClick={()=>this.overlayclicked("sedan")} style={this.state.active_category=="sedan"?{borderBottom :"3px  solid #FF7700" , color:"#FF7700"  }:null } >
                                              {
                                                  this.state.active_category=="sedan"?
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/sedan_orange.png" />
                                                  :
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/sedan_black.png" />
                                                }
                                                <span>Sedan</span></button>

                                              <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')} onClick={()=>this.overlayclicked("suv")} style={this.state.active_category=="suv"?{borderBottom :"3px  solid #FF7700", color:"#FF7700"  }:null } >
                                              {
                                                  this.state.active_category=="suv"?
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/suv_orange.png" />
                                                  :
                                                  <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/suv_Black.png"  />
                                                }
                                                <span>Suv</span></button>
                
                                            </div>
                                          
                                              <div className= {[styles['all-overlays'],'col-md-10'].join(' ')}  > 
                                                <Slider {...settings} className= {[styles['all-overlays-slider'],'col-md-12'].join(' ')} > 
                                                  {
                                                    data[this.state.active_category].map((e,i) => {
                                                      // console.log(e)
                                                      // console.log(i) 
                                                    return(
                                                    
                                                    <button className={[styles['overlays-click-btn'],''].join(' ')} onClick={()=>this.overlayiconclicked(e[i]["w"], `overlay_${i}`)} style={this.state.active_overlay==`overlay_${i}` ?{border:"2px  solid #FF7700"  }:null } >
                                                    <img className="btn" src={e[i]["b"]} /></button>
                                                    )
                                                  })
                                                  }

                                                </Slider>
                                            </div>  
                                            
                                        </div>
                                       */}
                                      {/* //-MobileVIew */}
                                      <div className={[styles['credit-text'],"col-md-5 text-right"].join(" ")}>
                                       <div className={[styles[''],"row"].join(" ")} >
                                        <div className={[styles[''],"col-md-6"].join(" ")} >
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png"/>
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        <div className={[styles['right-top-credits'],"col-md-6"].join(" ")}>
                                          <a href="https://www.spyne.ai/tech/pricing"  >
                                          <button >
                                          
                                          <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={[styles['lower-section-with-overlay-2'],''].join(' ')} >
                                      {/* <div className={[styles['angle-sec'],'d-md-block d-none'].join(' ')}>
                                        <div  className={[styles['overlay-box'],'row m-0'].join(' ')} >
                                          {/* <div className={[styles['car-overlay-cat'],'col-md-12'].join(' ')}> 
                                          
                                          <Accordion allowZeroExpanded preExpanded={['a']}>
                                            <AccordionItem uuid="a">
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                    <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')}   onClick={()=>this.overlayclicked("hb")} style={this.state.active_category=="hb"?{ color:"#FF7700" , borderRadius:"5px"}:null } >
                                                    {
                                                        this.state.active_category=="hb"?
                                                        <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/HB_orange.png" />
                                                        :
                                                        <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/HB_black.png" />
                                                      }
                                                      <span>Hatchback</span></button>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                <div className= {[styles['all-overlays'],'col-md-12'].join(' ')}  > 
                                                <div className= {[styles['overlay-vertical']]}>
                                                    {
                                                    data["hb"].map((e,i) => {
                                                      // console.log(e)
                                                      // console.log(i)
                                                    
                                                    return(
                                                      
                                                      <button className={[styles['overlays-click-btn'],''].join(' ')} onClick={()=>this.overlayiconclicked(e[i]["w"], `overlay_${i}`)} style={this.state.active_overlay==`overlay_${i}` ?{border:"2px  solid #FF7700"  }:null } >
                                                      <img className="btn" src={e[i]["b"]} /></button>
                                                      )
                                                    })
                                                    }
                                                    </div>
                                                </div>
                                                </AccordionItemPanel>
                                          </AccordionItem>
                                          <AccordionItem>
                                              <AccordionItemHeading>
                                                  <AccordionItemButton>
                                                    <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')}   onClick={()=>this.overlayclicked("sedan")} style={this.state.active_category=="sedan"?{ color:"#FF7700" , borderRadius:"5px"}:null } >
                                                    {
                                                      this.state.active_category=="sedan"?
                                                      <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/sedan_orange.png" />
                                                      :
                                                      <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/sedan_black.png" />
                                                    }
                                            
                                                    <span>Sedan</span></button>
                                                  </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <div className= {[styles['all-overlays'],'col-md-12'].join(' ')}  > 
                                                <div className= {[styles['overlay-vertical']]}>
                                                    {
                                                    data["sedan"].map((e,i) => {
                                                      // console.log(e)
                                                      // console.log(i)
                                                    
                                                    
                                                    return(
                                                      
                                                      <button className={[styles['overlays-click-btn'],''].join(' ')} onClick={()=>this.overlayiconclicked(e[i]["w"], `overlay_${i}`)} style={this.state.active_overlay==`overlay_${i}` ?{border:"2px  solid #FF7700"  }:null } >
                                                      <img className="btn" src={e[i]["b"]} />
                                                      </button>
                                                      )
                                                    })
                                                    }
                                                    </div>
                                                </div>
                                              </AccordionItemPanel>
                                          </AccordionItem>
                                          <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                    <button className={[styles['overlay-car-btn'],styles['car-try-btns'],''].join(' ')} onClick={()=>this.overlayclicked("suv")} style={this.state.active_category=="suv"?{color:"#FF7700"  }:null } >
                                                    {
                                                        this.state.active_category=="suv"?
                                                        <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/suv_orange.png" />
                                                        :
                                                        <img className="btn" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/category_icon/suv_Black.png"  />
                                                      }
                                                    <span>Suv</span></button>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                <div className= {[styles['all-overlays'],'col-md-12'].join(' ')}  > 
                                                <div className= {[styles['overlay-vertical']]}>

                                                    {
                                                    data["suv"].map((e,i) => {
                                                      // console.log(e)
                                                      // console.log(i)
                                                    
                                                    
                                                    return(
                                                      
                                                      <button className={[styles['overlays-click-btn'],''].join(' ')} onClick={()=>this.overlayiconclicked(e[i]["w"], `overlay_${i}`)} style={this.state.active_overlay==`overlay_${i}` ?{border:"2px  solid #FF7700"  }:null } >
                                                      <img className="btn" src={e[i]["b"]} />
                                                      </button>
                                                      )
                                                    })
                                                    }
                                                    </div>
                                                </div>
                                                </AccordionItemPanel>
                                          </AccordionItem>
                                          </Accordion>
                                          
                      
                                          </div>
                                            
                                          
                                          
                                      </div>
                                            
                                      </div>
                                       */}
                                      <div className={[styles['left-section-with-image'],''].join(' ')} >
                                      
                                        <div id='slider_div' className={[styles['with-image-inner'],'d-flex justify-content-center align-items-center'].join(' ')}>
                                        
                                          { 
                                            !this.props.spinner && this.state.editedUrl !== "" && this.props.orgUrl !== "" && this.props.toggleChange ?(

                                              this.state.visualSelection=="virtual_studio"?(
                                              !this.state.cropDown?(
                                                this.state.startCrop?
                                              <ReactCrop id='slider_div' className={[styles['cropreact'],''].join(' ')}
                                                      src={this.props.croppedUrl}
                                                      crossorigin='anonymous'
                                                      crop={crop}
                                                      ruleOfThirds
                                                      onImageLoaded={this.onImageLoaded}
                                                      onComplete={this.onCropComplete}
                                                      onChange={this.onCropChange}
                                                    />
                                                    :
                                                    <img className={[styles['cropper-image']]} src={this.props.editedUrl} ></img>
                                                  )
                                                    :
                                                    <img src={this.props.croppedUrl} ></img>

                                              )
                                                    :
                                                    <img src={this.props.editedUrl} ></img>
                                            )
                                            :
                                            this.props.spinner && this.props.orgUrl !== "" ?
                                            <div  className={[styles['image-box-area'],'d-flex'].join(' ')}>
                                              <video autoPlay>
                                                <source src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/ezgif.com-gif-maker.mp4"></source>
                                              </video>
                                              
                                            </div>
                                            :
                                            !this.props.orgUrl == "" && !this.props.toggleChange ?(
                                              <div  id="slider_div" className={styles['original_image']} >
                                                {
                                                this.state.visualSelection=="custom_walls"?
                                                // object-fit: contain;
                                                // style={{"object-fit": "contain" }}
                                                (
                                                  this.state.applyWay=="manually" ?(
                                                //   <ReactCompareSlider id="sliderId"
                                                //   handle={<ReactCompareSliderHandle id="handler" buttonStyle={{backdropFilter: undefined, border: "2px solid white", boxShadow: undefined, color: 'white',transform: "rotateZ(90deg)",cursor: "n-resize"}} linesStyle={{opacity: 0.5}}/>}
                                                //   itemOne={<ReactCompareSliderImage id="wallImg" ref={this.wallImg} alt="one" src={this.props.customWall} style={{opacity: 0.6 ,"object-fit": "inherit"}}/>}
                                                //   itemTwo={<ReactCompareSliderImage id="orgImg_2" ref={this.orgImg}  alt="two" src={this.props.orgUrl}
                                                //   style={{"object-fit": "contain" }} />}
                                                //   onPositionChange={this.handleSetPosition}
                                                //   onlyHandleDraggable
                                                //   position={this.state.tempPosition==0?30:this.state.tempPosition}
                                                //   style={{
                                                //     // "object-position": "center center",
                                                //     height: '100%',
                                                //     width: this.state.new_width?this.state.new_width:"100%",
                                                //     left:this.state.left_wall_padding
                                                //   }}
                                                //   portrait={true}
                                                // /> 
                                                <div className={[styles['original-image-div']]}>
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}   />
                                                  <img alt="" className={[styles['resizable'],"resizable"].join(" ")} id="wallImg" src={this.props.customWall} 
                                                  style={{position:"absolute", width:this.state.new_width, left:this.state.left_wall_padding,
                                                  height:this.state.new_height?this.state.new_height:80  }}
                                                  />
                                                  {/* <img alt="drag button" className={[styles['drag-button'],""].join(" ")} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/image%202.png"
                                                  style={{position:"relative", left:0 ,top:0}}/> */}


                                                </div>
                                               
                                                ):
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}  />
                                                  )
                                                :
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}  />
                                                
                                              
                                              } 
                                              </div>
                                            )
                                            :
                                            null
                                          } 
                                          
                                        </div>
                                        
                                        <div className={[styles['toggle-section'],'text-center'].join(' ')}>
                                            <div className={[styles[''], "sec-2-toggle mb-3"].join(' ')}>
                                            <p className="mr-5 mt-2">Original</p>
                                            <label className="mt-2">
                                                <Toggle onChange={this.handleToggleImage} icons={false} checked={this.props.toggleStatus} />
                                            </label>
                                            <p className="ml-5 mt-2">Edited</p>
                                            </div>
                                        </div>
                                        {!this.state.cropDown && this.state.visualSelection=="virtual_studio" && this.props.toggleChange?
                                        <div className={[styles['crop-features'],'row'].join(' ')}>
                                          
                                          <div className={[styles['crop-options'],'col-md-12'].join(' ')}>

                                          
                                          <div>
                                            {this.state.crop.aspect===16/9?
                                            <div>
                                            <button className={[styles['crop-active'],''].join(' ')} onClick={()=>this.crop_Ratio(16/9)}>16/9</button>
                                            <button onClick={()=>this.crop_Ratio(4/3)}>4/3</button>
                                            <button onClick={()=>this.crop_Ratio(1/1)}>1/1</button>  
                                            <button onClick={()=>this.crop_Ratio(null)}>Custom</button>
                                            </div>
                                            :
                                            <div>
                                              {this.state.crop.aspect===4/3?
                                              <div>
                                                <button  onClick={()=>this.crop_Ratio(16/9)}>16/9</button>
                                            <button className={[styles['crop-active'],''].join(' ')} onClick={()=>this.crop_Ratio(4/3)}>4/3</button>
                                            <button onClick={()=>this.crop_Ratio(1/1)}>1/1</button>  
                                            <button onClick={()=>this.crop_Ratio(null)}>Custom</button>
                                                </div>
                                                :
                                                <div>
                                                {this.state.crop.aspect===1/1?
                                                  <div>
                                                    <button  onClick={()=>this.crop_Ratio(16/9)}>16/9</button>
                                                <button  onClick={()=>this.crop_Ratio(4/3)}>4/3</button>
                                                <button className={[styles['crop-active'],''].join(' ')} onClick={()=>this.crop_Ratio(1/1)}>1/1</button>  
                                                <button onClick={()=>this.crop_Ratio(null)}>Custom</button>
                                                    </div>
                                                    :
                                                    <div>
                                                    {this.state.crop.aspect===null?
                                                      <div>
                                                        <button  onClick={()=>this.crop_Ratio(16/9)}>16/9</button>
                                                    <button  onClick={()=>this.crop_Ratio(4/3)}>4/3</button>
                                                    <button onClick={()=>this.crop_Ratio(1/1)}>1/1</button>  
                                                    <button className={[styles['crop-active'],''].join(' ')} onClick={()=>this.crop_Ratio(null)}>Custom</button>
                                                        </div>
                                                        :
                                                        <div>
                                                        <button  onClick={()=>this.crop_Ratio(16/9)}>16/9</button>
                                                    <button  onClick={()=>this.crop_Ratio(4/3)}>4/3</button>
                                                    <button onClick={()=>this.crop_Ratio(1/1)}>1/1</button>  
                                                    <button onClick={()=>this.crop_Ratio(null)}>Custom</button>
                                                    </div>
                                                        
                                                      }
    
    
                                                      </div>
                                                  }


                                                  </div>
                                              
                                              }
                                              </div>
                                            }
                                          </div>
                                          </div>
                                          <div className={[styles['crop-button'],'col-md-12'].join(' ')}>
                                              <div>
                                              <button disabled={this.state.startCrop?false:true} onClick={() => this.crop(true)}>crop</button>
                                              <button disabled={this.props.cropperList.length>1?false:true} onClick={() => this.crop(false)}>undo</button>
                                              </div>
                                                
                                        </div>
                                       </div>
                                       :
                                       null
                                      }
                                        {/* {
                                          this.props.editedUrl !== "" && this.state.visualSelection=="virtual_studio"?(
                                        <div className={[styles["bg-complete-sec"],''].join(' ')} >
                                          <div className={[styles['output-content'], "col-md-12"].join(' ')}>
                                            <h4>Hey! if output is not coming out well, <span style={{color:"#FF7700",   fontFamily: "Poppins"}} >Try other backgrounds</span></h4>
                                          </div>
                                          <div className={[styles['bg-below-sec'], "row"].join(' ')}>
                                            <div className={[styles['select-sec'],'col-md-4'].join(' ')}>
                                              <span>Select</span>
                                              <img className={styles["select-img"]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/bg_under.png"/>
                                            </div>
                                              {
                                                this.props.planData.map((e,i) => {
                                                  // console.log(e.imageId)
                                                  {
                                                  if (e.imageId==501 || e.imageId==806) {
                                                  return (
                                                    <div className={[styles['active-background-sec-below'],'col-md-2 col-6'].join(' ')}  onClick={()=>this.handleBackground(e.imageId,e.imageCredit,true)}>
                                                      <img alt="" style={this.props.background_id == e.imageId && this.state.below_bg==true ?{border:"2px solid"}:null } src={e.imageUrl}/>
                                                    </div>
                                                  )}
                                                  }
                                                })
                                              }
                                              
                                           
                                            <div className={[styles['output-apply-btn'], "col-md-4 col-12"].join(' ')}>
                                              <button  onClick={this.applyChange} className={styles['below-bg-apply']}>Apply</button>
                                            </div>
                                            </div>
                                        </div>)
                                        :
                                        null
                                        } */}
                                        <div className={[styles['mobile-uploads'],'col-md-12 d-md-none d-block'].join(' ')}>
                                            <p><span>Car Image</span><button  onClick={() => this.fileInput.click()} className={[styles['upload-sm-img']]}> Upload Image</button></p>
                                        </div>


                                        <DownloadCompleteModal 
                                            showDownloadModal={this.props.downloadComplete}
                                            editedUrl={this.props.croppedUrl}
                                            handleDownloadComplete={this.props.handleDownloadComplete}
                                            orgUrl={this.props.orgUrl}
                                          />
                                      </div>

                                      {/* MobileView (UploadLogo & UploadImage)  */}
                                          <div className={[styles['mobile-uploads'],'col-md-12 d-md-none d-block'].join(' ')}>
                                              <p><span>Logo</span> <button className={[styles['logo-upload'],styles['upload-sm-img'],''].join(' ')} disabled>Upload Logo</button>
                                              </p>
                                          </div>

                                      {
                                        this.state.isDownload ?
                                        <div className={[styles['download-popup-area'],'container-fluid'].join(' ')}>
                                            
                                            <div className={[styles['download-popup'],'row'].join(' ')}>
                                                <div className={[styles['cross-btn'],''].join(' ')}>
                                                  <img onClick={this.downloadPopup} className={styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                                </div>
                                                <button className={this.props.activateButton == "Hd" ? [styles['download-hd'],styles['activate'],'col-md-12 '].join(' ') : null} onClick={() => this.props.handleActivateButton("Hd")}>
                                                    <h6><input type="radio" checked={this.props.activateButton == "Hd"}/>HD Download</h6>
                                                    <ul>
                                                        <li>Full Image 1920 × 1080 </li>
                                                        <li>Without Watermark</li>
                                                    </ul>
                                                    <p>Price : <span>{this.props.bgCredit} Credit/Image</span></p>
                                                </button>
                                               <div className={[styles['border-1'],''].join(' ')}></div>
                                                <button className={this.props.activateButton == "Basic" ? [styles['download-hd'],styles['activate'],'col-md-12'].join(' ') : null} onClick={() => this.props.handleActivateButton("Basic")}>
                                                    <h6><input type="radio" checked={this.props.activateButton == "Basic"}/>Basic Download</h6>
                                                    <ul>
                                                        <li>Preview Image 640 × 360 </li>
                                                        <li>With Watermark</li>
                                                    </ul>
                                                    <p>Price : <span>Free</span></p>
                                                </button>
                                                
                                                <div className="text-center">
                                                  <button onClick={this.handleModal}  className={[styles['download-btn-hd']]}>Download</button>
                                                </div>
                                                {/* <div className={styles['background-list-2']}>
                                                  <h6>Backgrounds ({this.props.planData.length})</h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.planData.slice(0,2).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit)}>
                                                                <img alt="" src={e.imageUrl}/>
                                                                <div className="row pl-0 pr-0">
                                                                  <p className={[styles['back-name'],'col-md-8 col-12'].join(' ')}>{e.bgName}</p>
                                                                  <p className={[styles['back-credit'],'col-4'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        <div className={ styles['inactive-background-sec']}>
                                                          <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                          </div>
                                                          
                                                          
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className={[styles['btn-sec-2']]}>
                                                  <button onClick={this.applyChange}>Apply</button>
                                                </div> */}
                                                <BackgroundListModal 
                                                    delWallShowModal={this.delWallShowModal}
                                                    handleBackground = {this.handleBackground}
                                                    background_id={this.props.background_id} 
                                                    planData={this.props.planData} hideList={this.hideList} 
                                                    showBackgroundList={this.state.showBackgroundList} 
                                                    orgUrl={this.props.orgUrl}
                                                    visualSelection={this.state.visualSelection}
                                                    floor={this.props.floor}
                                                    customWallSets={this.customWallSets}
                                                    customWallSet={this.props.customWallSet}
                                                    wallFile={this.wallFile}
                                                    handleUploadClick={this.handleUploadClick}
                                                    handleUpload={this.handleUpload}
                                                    handleUnshiftWalls={this.props.handleUnshiftWalls}
                                                    wallData={this.props.wallData}
                                                    bg_list={this.props.bg_list}
                                                    fav_list={this.props.fav_list}
                                                    handleCarsFavList={this.handleCarsFavList}
                                                    handleCarsBgList={this.handleCarsBgList}
                                                    applyChange={this.applyChange}/>

                                                    <CreditModal 
                                                    showCreditModal={this.state.creditModal}
                                                    handleCreditModal={this.handleCreditModal}
                                                    redirectPricePage={this.redirectPricePage}
                                                    editedUrl={this.props.editedUrl}
                                                    bgCredit={this.props.bgCredit}
                                                    />
                                            </div>
                                            
                                              
                                          </div>
                                        :
                                        <div className={[styles['right-section-with-image'],''].join(' ')}>
                                          <div className={[styles['right-top-selection']]}>
                                            
                                            <div className={this.state.visualSelection=="virtual_studio"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}}  onClick={(e)=>this.handleVisualSelection('virtual_studio')} >Virtual Studio</div>
                                            <div className={this.state.visualSelection=="custom_walls"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}} onClick={(e)=>this.handleVisualSelection('custom_walls')} >Custom Walls <sup className={styles['superscript']}>Beta</sup></div>

                                            
                                          </div>
                                            {/* <div className={[styles['right-top-section'],''].join(' ')} >
                                                <div className={[footwearOnlyStyles['upload-section-car'],"d-flex justify-content-center"].join(' ')}>
                                                  <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                  {({getRootProps, getInputProps}) => (
                                                      <section>
                                                          <div {...getRootProps({
                                                            onClick: event => event.stopPropagation()
                                                          })} className={footwearOnlyStyles["screen-2-drop-car"]}>
                                                              <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                              <p className="para-1">DROP IMAGE HERE </p>
                                                              <p class="para-2"><b>or</b> Browse For Image</p>
                                                              <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                          </div>
                                                      </section>
                                                  )}
                                                  </Dropzone>
                                                </div>
                                                
                                            </div> */}
                                            {
                                              this.state.visualSelection=="virtual_studio"?(
                                              <div>
                                                <div className={styles['background-list']}>
                                                    <h6><span className={styles['colored']}>Your Favourite</span> <span>Backgrounds</span></h6> 
                                                    {
                                                        this.props.fav_list.length==0?
                                                          <p className={[styles['favroute-add'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/wishlist.gif" /> 
                                                            <span>Click on heart tab to add as your favourite</span>
                                                          </p>
                                                      :
                                                      <div></div>

                                                    }
                                                    {/* Favourite Backgrounds */}

                                                    <div className={[styles['backgrounds-grid'],''].join(' ')}>
                                                        {
                                                          this.props.fav_list.slice(0,4).map((e,i) => {
                                                            // console.log(e)
                                                            return (
                                                              <div className={this.props.background_id == e.image_id && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.image_id,e.image_credit,false)}>
                                                                <img alt="" src={e.image_url}/>
                                                                <p onClick={()=>this.handleCarsFavList(i)} className={[styles['fav-icon'],''].join(' ')}>
                                                                    <img src="https://storage.googleapis.com/spyne-website/try-categories/pink-heart.svg"/>

                                                                </p>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-12'].join(' ')}>{e.background_name} - {e.image_id}</p>
                                                                  {/* <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p> */}
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        {
                                                          this.props.fav_list.length>4?
                                                            <div className={[styles['inactive-background-sec'],styles['more-box'],''].join(' ')}>
                                                              <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                                <img alt="" src="https://storage.googleapis.com/spyne-website/try-categories/morre.png"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay']}> {fav_backLeft} other favourites<span></span></div>
                                                              </div>
                                                            </div>
                                                        :null}
                                                    </div>  
                                                </div>
                                                
                                                 {/* Spyne Backgrounds */}

                                                
                                                <div className={styles['background-list']}>
                                                    <h6><span className={styles['colored']}>Spyne’s</span> <span>Backgrounds</span> </h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.bg_list.slice(0,9).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.image_id && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.image_id,e.image_credit,false)}>
                                                                <img alt="" src={e.image_url}/>
                                                                <p  className={[styles['fav-icon'],''].join(' ')}>
                                                                    <img className={styles['white-icon']} src="https://storage.googleapis.com/spyne-website/try-categories/white-heart.svg"/>
                                                                    <img className={styles['hoverable-icon']} src="https://storage.googleapis.com/spyne-website/try-categories/border-fav.svg"/>
                                                                </p>
                                                                <p onClick={()=>this.handleCarsBgList(i)} className={[styles['fav-icon'],''].join(' ')}>
                                                                    <img className={styles['white-icon']} src="https://storage.googleapis.com/spyne-website/try-categories/white-heart.svg"/> 
                                                                    <img className={styles['hoverable-icon']} src="https://storage.googleapis.com/spyne-website/try-categories/border-fav.svg"/> 
                                                                </p>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-12'].join(' ')}>{e.background_name} - {e.image_id}</p>
                                                                  {/* <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p> */}
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        
                                                        {
                                                          this.props.bg_list.length>9?
                                                            <div className={[styles['inactive-background-sec'],styles['more-box'],''].join(' ')}>
                                                              <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                                <img alt="" src="https://storage.googleapis.com/spyne-website/try-categories/morre.png"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay']}> {spyne_backLeft} <br/>More</div>
                                                              </div>
                                                            </div>
                                                          :null}
                                                    </div>  
                                                </div>

                                                {/* <div className={styles['background-list']}>
                                                    <h6>Backgrounds </h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.planData.slice(0,2).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                                <img alt="" src={e.imageUrl}/>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.bgName}</p>
                                                                  <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                              

                                                            //   <div className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit)}>
                                                            //   <img alt="" src={e.imageUrl}/>
                                                            //   <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                            //     <p className={[styles['back-name'],'col-8'].join(' ')}>{e.bgName}</p>
                                                            //     <p className={[styles['back-credit'],'col-4 d-md-block d-none'].join(' ')}>{e.imageCredit}
                                                            //       <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                            //       <span></span></p>
                                                            //   </div>
                                                            // </div>





                                                            )
                                                          })
                                                        }
                                                        <div className={styles['inactive-background-sec']} >
                                                          <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                          </div> */}
                                                          
                                                          {/* <div className="row pl-0 pr-0">
                                                            <p className={[styles['back-name'],'col-6'].join(' ')}>Radiant Slate</p>
                                                            <p className={[styles['back-credit'],'col-6'].join(' ')}>10
                                                              <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                              <span></span></p>
                                                          </div> */}
                                                        {/* </div>
                                                    </div>  
                                                </div> */}
                                             {/* //Backgrounds */}


                                                <div className={styles['logo-sec']}>
                                                <p><b>Number Plate Editing (<span>+1 Credit</span>)</b></p>
                                                  <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                                      <NumberPlateLogo   
                                                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                                        handleLogoPosition = {this.props.handleLogoPosition}
                                                        logoUrl = {this.props.logoUrl}
                                                        logoPosition = {this.props.logoPosition}
                                                        hideLogo={this.props.hideLogo}
                                                        handleLogo={this.props.handleLogo}
                                                        handleRadioLogo={this.props.handleRadioLogo}
                                                        checkUploadLogo={this.props.checkUploadLogo}
                                                      />
                                                  </div>
                                                    
                                                </div>
                                                <div >
                                                {!this.props.proPage?
                                                <div className={[styles[''],'col-md-12 p-0'].join(' ')}>   
                                                <div className={[styles['enhancement-container']].join(' ')}>
                                                <div className={[styles['enhancement-title'],'col-md-12 text-left p-0'].join(' ')}>Enhancement
                                                </div>
                                                <div className={[styles[''],'col-md-12 p-0'].join(' ')}>
                                                  <label className={[styles['enhancements']]}>
                                                      <input type="checkbox" onChange={this.props.handleBodyReflection} checked={this.props.checkBodyReflection} />
                                                      <span >Body Reflection</span>
                                                  </label>
                                                  <label  className={[styles['enhancements']]}>
                                                      <input type="checkbox" onChange={this.props.handleSeeThrough} checked={this.props.checkSeeThrough} />
                                                      <span >Window SeeThrough</span>
                                                  </label>  
                                                  <label  className={[styles['enhancements']]}>
                                                      <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement} />
                                                      <span >Color Enhancement</span>
                                                  </label> 
                                                  </div>
                                                  </div>
                                                  <form className={[styles['brightness-container'],"row m-0"].join(' ')}>
                                                    <span  className={[styles['enhancement-title'],'text-left'].join(' ')}>Brightness Level</span>
                                                    <div className={[styles['tint-opt'],'col-md-4'].join(' ')}>
                                                        <input type="radio" name="brightness" value="0.9" defaultChecked={this.props.brightness==="0.9"?true:false} onClick={()=>this.props.handleBrightness("0.9")}/>
                                                        <label>Dark</label>
                                                    </div>
                                                    <div className={[styles['tint-opt'],'col-md-4'].join(' ')}> 
                                                        <input type="radio" name="brightness" value="1" defaultChecked={this.props.brightness==="1"?true:false} onClick={()=>this.props.handleBrightness("1")}/>
                                                        <label>Normal</label>
                                                    </div>
                                                    <div className={[styles['tint-opt'],'col-md-4'].join(' ')}>
                                                        <input type="radio" name="brightness" value="1.2" defaultChecked={this.props.brightness==="1.2"?true:false} onClick={()=>this.props.handleBrightness("1.2")}/>
                                                        <label>Bright</label>
                                                    </div>
                                                  </form>
                                                  <form className={[styles['brightness-container'],"row m-0"].join(' ')}>
                                                    <span className={[styles['enhancement-title'],'text-left'].join(' ')}>Tint Level</span>
                                                    {!this.props.checkSeeThrough?
                                                    <div className={[styles['tint-opt'],'col-md-3'].join(' ')}>
                                                        <input type="radio" name="tint" value="0" defaultChecked={this.props.tint==="0"?true:false} onClick={()=>this.props.handleTint("0")}/>
                                                        <label>No Tint</label>
                                                    </div>:null}
                                                    <div className={[styles['tint-opt'],'col-md-3'].join(' ')}>
                                                        <input type="radio" name="tint" value="0.28" defaultChecked={this.props.tint==="0.28"?true:false} onClick={()=>this.props.handleTint("0.28")}/>
                                                        <label>Light</label>
                                                    </div>
                                                    <div className={[styles['tint-opt'],'col-md-3'].join(' ')}> 
                                                        <input type="radio" name="tint" value="0.32" defaultChecked={this.props.tint==="0.32"?true:false} onClick={()=>this.props.handleTint("0.32")}/>
                                                        <label>Normal</label>
                                                    </div>
                                                    <div className={[styles['tint-opt'],'col-md-3'].join(' ')}> 
                                                        <input type="radio" name="tint" value="0.37" defaultChecked={this.props.tint==="0.37"?true:false} onClick={()=>this.props.handleTint("0.37")}/>
                                                        <label>Dark</label>
                                                    </div> 
                                                  </form> 
                                                  </div>
                                                :
                                                null
                                                }
                                                
                                                </div>
                                                  <div className={[styles['btn-sec']]}>
                                                    <p className={[styles['rmaining'],'d-md-none d-block'].join(' ')}>Available Credits:&nbsp; <span>{this.props.availableCredit}</span>
                                                    </p>
                                                    
                                                    <button onClick={this.applyChange}>Apply</button>
                                                  </div>

                                                  
                                                
                                              </div>
                                              ):
                                              <div>
                                                  <div className={[styles['custom-walls-area'],''].join(' ')}>
                                                    <div className={this.props.customWall.length>0?[styles['custom-wall'],''].join(' '):[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>1</span> Choose a Wall </p>
                                                    </div>
                                                    <div className={[styles['background-list'],styles['custom-background-list'],''].join(' ')}>
                                                      <div className={styles['backgrounds-grid-custom']}>
                                                          <div className={styles['inactive-background-sec']}  >
                                                          <input type="file"  className="d-none" ref={this.wallFile} onChange={(e)=>this.handleUpload(e)}></input>

                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/upload-rect.png"  onClick={(e)=>this.handleUploadClick(e)}  ></img>
                                                            <span>Custom</span>
                                                            </div>
                                                          
                                                          {
                                                            
                                                            this.props.wallData.slice(0,6).map((e,i)=>{
                                                              
                                                              return(
                                                                // <span>test</span>
                                                                <div className={this.props.customWall==e["wall_png_url"]?styles['active-walls-sec']: styles['inactive-walls-sec']} onClick={()=>this.customWallSets(e["wall_png_url"])} >
                                                                  <img src={e["low_res_wall_url"]} ></img>
                                                                  {
                                                                    e["wall_id"].split('-')[0]=="WL"?
                                                                    <img class={styles['wall-cross']} onClick={()=>this.delWallShowModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                                                                    :
                                                                    null
                                                                  }
                                                                  
                                                                  <span>{e["wall_name"].substring(0, 10)}</span>
                                                                
                                                                </div>
                                                                
                                                                // onClick={()=>this.delCustomWall(i)}
                                                            )

                                                            })
                                                          }
                                                          {
                                                          customWallLeft>0?(
                                                            <div className={styles['inactive-background-sec']} onClick={this.handleBackgroundList}>
                                                              <div className={styles['overlay-pos']} >
                                                                <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay-2']}>+ {customWallLeft} More</div>
                                                              </div>
                                                            </div>):null
                                                          }
                                                          
                                                      </div>  
                                                  </div>
                                                    <div className={this.state.applyWay.length>0?[styles['custom-wall'],''].join(' '):[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>2</span> Adjust Height</p>
                                                      <div className={[styles['adjust-height-area'],''].join(' ')}>
                                                        <ul>
                                                          <li>
                                                            <button  className={this.state.applyWay=="manually"?[styles['active'],''].join(' '):[styles['inactive'],''].join(' ')} onClick={()=> this.partialapply("manually")} >
                                                              <h6>Manually</h6>
                                                              <img src={this.state.applyWay=="manually"?"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/ezgif.com-gif-maker%20(3).gif":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/auto-slidr.png"}/>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button className={this.state.applyWay=="magically"?[styles['active'],''].join(' '):[styles['inactive'],''].join(' ')} onClick={()=> this.partialapply("magically")} >
                                                              <h6>Magically</h6>
                                                              <img src={this.state.applyWay=="magically"?"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/auto-setting.gif":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/animation_500_kov5rgmo%201.png"}/>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                    <div className={[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>3</span> Apply and View Changes</p>
                                                      
                                                    </div>
                                                    <div>
                                                    <label className={styles['toggle1']}>
                                                        <div style={{color:"black"}}>
                                                          <snap className={styles['greyscale']}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Greyscale</snap>
                                                          <input type="checkbox" onChange={this.props.handelGreyShade} checked={this.props.checkGreyShade} />
                                                          <span style={{color:"white"}} className={styles['slider1']}>{this.props.checkGreyShade?<p></p>:<p></p>}</span>
                                                          </div>
                                                        </label>
                                                    </div>
                                                    <div>    
                                                        <label className={styles['toggle1']}>
                                                        <div style={{color:"black"}}>
                                                          <snap style={{position:"absolute"}}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Color Enhancement</snap>
                                                          <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement}/>
                                                          <span style={{color:"white"}} className={styles['slider1']}>{this.props.checkColorEnhancement?<p></p>:<p></p>}</span>
                                                          </div>
                                                        </label>
                                                        
                                                </div>
                                                </div>

                                                <div className={[styles['btn-sec']]}>
                                                  <p className={[styles['rmaining'],'d-md-none d-block'].join(' ')}>Available Credits:&nbsp; <span>{this.props.availableCredit}</span></p>
                                                  <button onClick={this.applyChange}>Apply</button>
                                                </div>
                                              </div>
                                            }

                                            <LogoCropModal 
                                              crop={this.props.crop}
                                              onImageLoaded={this.props.onImageLoaded}
                                              onComplete={this.props.onComplete}
                                              onChange={this.props.onChange}
                                              showlogoCropModal={this.props.showlogoCropModal}
                                              handleCropModal={this.props.handleCropModal}
                                              logoUrl={this.props.logoUrl}
                                              handleCroppedLogoBlob={this.props.handleCroppedLogoBlob}
                                              saveCroppedImage={this.props.saveCroppedImage}
                                            />
                                            
                                            <BackgroundListModal 
                                              handleBackground = {this.handleBackground}
                                              delWallShowModal={this.delWallShowModal}
                                              background_id={this.props.background_id} 
                                              planData={this.props.planData} hideList={this.hideList} 
                                              showBackgroundList={this.state.showBackgroundList} 
                                              orgUrl={this.props.orgUrl}
                                              visualSelection={this.state.visualSelection}
                                              floor={this.props.floor}
                                              customWallSets={this.customWallSets}
                                              customWallSet={this.props.customWallSet}
                                              wallFile={this.wallFile}
                                              handleUploadClick={this.handleUploadClick}
                                              handleUpload={this.handleUpload}
                                              handleUnshiftWalls={this.props.handleUnshiftWalls}
                                              wallData={this.props.wallData}
                                              bg_list={this.props.bg_list}
                                              fav_list={this.props.fav_list}
                                              handleCarsFavList={this.handleCarsFavList}
                                              handleCarsBgList={this.handleCarsBgList}
                                              applyChange={this.applyChange}/>
                                        </div>
                                      }
                                      
                                </div>
                              </div>
                          </section>
                          <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                      </div>
                  </section>
              )}
              </Dropzone>
            
                   
            
            

             <section className={[Styles['calendly-area'],' '].join(' ')} ref={this.myDivToFocus}>
                <div className={[Styles[''],'container'].join(' ')}>
                    <div className={[Styles[' '],'row'].join(' ')}>
                        <div className={[Styles['title'],'col-md-12'].join(' ')}>
                          <h1> <span className={[Styles['no-border']]}>Earn more by </span> selling your car <span className={[Styles['no-border']]}> online using AI</span></h1>  
                            {/* <h6><span>Book a Demo</span> today to learn more!</h6> */}
                        </div>

                        {/* <div className={[Styles['cars-demo '],'col-lg-8 col-md-7'].join(' ')}>  
                            <div className={[Styles['cars-video'],'row m-0'].join(' ')}>
                                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/LV9sgTxsIgI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div> */}

                        <div className={[styles['cars-demo'],'col-lg-12 col-md-12'].join(' ')}>  
                            <div className={[styles['cars-calender'],'row m-0'].join(' ')}>
                                <h4>Book a Demo </h4>
                              
                                <InlineWidget className={[Styles['calendly-inline-widget'],'p-0'].join(' ')} url="https://calendly.com/spyneai/business?primary_color=ff7700"  style={{"left": "50% !important", "height": "100%", "marginLeft": "-100px!important"}} />
                              
                            </div>
                        </div>
                    </div>

                    <div className={[Styles['mob-area'],'row'].join(' ')}>
                        {/* <div className={[Styles['mob-image'],'col-md-7'].join(' ')}>
                            <img className="img-fluid" style={{
                                    height:'auto',
                                    width:'100%',
                                    }} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/11.png" />
                        </div> */}
                        <div className={[styles['mob-image'],'col-md-7'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/banner-mac.png" />
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/car-rotate-gif.gif" className={[styles['mac-gif'],''].join(' ')}/>
                        </div>

                        <div className={[Styles['why-use'],'col-md-5'].join(' ')}>
                          <h1>  <span className={[Styles['no-border']]}>Get SpyneAI </span><span className={[Styles['colored']]}>App</span></h1>  
                          <p>With Spyne App anyone can capture professional car images  </p>
                          <p><a href="https://www.spyne.ai/tech/cars/demo/" target="_blank" className={[Styles['request-demo']]} >Learn more</a></p>
                          
                          <p><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}><img className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a>
                          <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                            <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                               
                          </p>
                        
                        </div>
                    </div>
                </div>
            </section>     
            <Modal
              show={this.state.showInstruction}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size='lg'
              centered
              onHide={() => this.setState({showInstruction:!this.state.showInstruction})}
           >
              <Modal.Header />
              <Modal.Body>
                <center className={[styles['car-text']]}>
                <h1>Output works best with these <span style={{color:"#FF7700"}}>Overlays</span></h1>
                </center>
                <div className={[styles[' '],'row'].join(' ')}>
                    <img className="mt-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/angles-gif.gif" />
                    
                </div>
                <center>
                    <button onClick={this.modalOnHide} className={styles['got-btn']}>Got It</button>
                </center>

              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.delWall}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size='lg'
              centered
              onHide={() => this.setState({delWall:!this.state.delWall})}
              >
              <Modal.Header />
              <Modal.Body>
                <center className={[styles['car-text']]}>
                <h1>Are you sure you want to Delete this <span style={{color:"#FF7700"}}>CustomWall</span></h1>
                </center>
                
                <center>
                    <button onClick={this.delWallModal} className={styles['got-btn']}>Yes</button>
                    <button onClick={this.delWallModalHide} className={styles['got-btn']}>No</button>
                </center>

              </Modal.Body>
            </Modal>
            {
              this.state.showModal ? 
              <LoginModal
                show={this.handleModal}  
                sendOTP={this.sendOTP}  
                verifyOTP={this.verifyOTP}
                otpScreen={this.state.otpScreen}
                verification={this.state.verification}
                handleChange={this.handleChange}
                otpVerification={this.state.otpVerification}
                emailValidation={this.state.emailValidation}
                onHide={this.handleCloseModal}
                defaultEmail = {this.state.email}
                cars={true}
              />
              :
              null
            }
          </>
        )
    }
}