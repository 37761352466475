import React, { useContext, useState, useEffect } from 'react'
import cls from './LoginPage.module.css'
import axios from 'axios'
import { AuthContext } from '../../context/GlobalState'
import { Link, useHistory } from 'react-router-dom'
import styles from './ForgetPassword.module.css'
const ForgetPassword = () => {

	
	const [mailSent, setMailSent] = useState(false)
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const emailInputRef = React.createRef()
	const context = useContext(AuthContext)
	const history = useHistory()

	useEffect(() => {
		if (context.auth.loggedIn) {
			history.push('/try-categories')
		}
	})

	const handleForgotPassword = (e) => {
		e.preventDefault()
		const data = {}
		data.emailId = emailInputRef.current.value
		axios({
			url: 'https://www.clippr.ai/api/v2/user/forgot-password',
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			params: {
				email_id: data.emailId,
				api_key: 'ee84dc3e-340e-4d39-a24c-9365272405f9'
			}
		})
		.then((resp) => {
			if (resp.status === 200) {
				// Show 
				setMailSent(true)
				// history.push('/success')
			}
		})
		.catch((err) => {
			setError(true)
			setErrorMsg('Not able to send the link please try again.')
		})
	
	}
	return (
		<div className={[cls["font-family"],styles['forget-ps'],''].join(' ')}>
			<div className="container-fluid">
				<div className="row">
					<div className={["col", cls["top-container"]].join(' ')}>
						<p className={cls["back"]} onClick={() => history.goBack()}>
							<span> &larr; </span>
							Back
						</p>
						<p className={cls["create-account"]}>
							<span className={cls["hide-in-mobile"]}>New to Spyne ? </span>
							<Link className={cls["is-link"]} to="/signup">Create an Account</Link>
						</p>
					</div>
				</div>
			</div>

			<div className={[styles[''],'container'].join(' ')}>
				<div className={[styles[''],'row justify-content-center align-items-center'].join(' ')}>
					<div className={[styles['forget-pass'],'col-lg-5'].join(' ')}>
						<div className={[styles['forget-pass-box'],'row', mailSent ? 'd-none' : ''].join(' ')}>
							<div className="col-12 text-center">
								<img className={["img-fluid", cls["logo"],styles['logo'],''].join(' ')} src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png" />
								<h4 className={cls["brand"]}>Account   <span className={cls[""]}> Recovery?</span> </h4>
								<p>Enter the email address to reset your password. You may need to check your spam folder.</p>
							</div>
							<form onSubmit={handleForgotPassword}>
								<div className={[cls["input-box"], cls["form-floating"]].join(' ')}>
									<input className={[cls["inp"], cls["input-container"]].join(' ')} placeholder="Enter Email " type="text" ref={emailInputRef} />
								</div>
								<button className={[cls['submit-btn']]} type="submit">Send recovery password</button>
							</form>
						</div>
						<div className={[styles['forget-pass-box'],'row', mailSent ? '' : 'd-none'].join(' ')}>
							<div className="col-12 text-center p-0">
								<img className={["img-fluid", cls["logo"],styles['logo'],''].join(' ')} src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png" />
								<h4 className={cls["success"]}>New Password sent successfully!</h4>
								<p>Please check your email.</p>
							</div>
							<form>
								<p>Click here to go to the Login screen</p>
								<Link to="/login">
									<button className={[cls['submit-btn']]}>Go to LogIn</button>
								</Link>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="container mt-5">
				<div className="row justify-content-center align-items-center">
					<div className="col-12 text-center">
						<a href="https://www.spyne.ai/v2/login">
							<p className={cls["photographer-signIn"]}>Sign in as
								<span className="mx-2"> Photographer</span>
								<span>
									<img src="https://storage.googleapis.com/spyne-website/try-categories/next.svg" />
								</span>
							</p>
						</a>
						<div className="d-flex justify-content-center align-items-center">
							<a href="https://www.spyne.ai/v2/login?source_url=https://www.albumm.ai&redirect_url=albumm"><img className={[cls["logo2"], "mx-3"].join(' ')} src="https://images.spyne.ai/landing-page/share-landing/new-share/albumm-new-black.png" /></a>
							<a href="https://www.spyne.ai/v2/login?source_url=https://www.webbr.ai/&redirect_url=webbr"><img className={[cls["logo2"], "mx-3"].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/Webbr/logo%202.png" /></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ForgetPassword