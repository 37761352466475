import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Spinner } from "react-bootstrap";

import styles from '../../Css/Model/ModelLanding.module.css'
import FootwearUseCases from '../Footwears/FootwearUseCases'
import Footer from '../Common/Footer'
import {Helmet} from "react-helmet";

export default class FootwearLanding extends Component {
    constructor(props){
        super(props)
        this.state={
            show:true,
            selectedFile: null,
            url: "",
            orgUrl: "",
            switchScreen:false,
            imageCategory:"",
            loader:false,
            imageWidth:'',
            imageHeight:''
        }
    }
    fileSelectedHandler = (event) => {
        this.setState({
          loader:true
        })
        if(event.target){
        var file = event.target.files[0];
        if (Math.round(file.size / 1024) > 30720) {
          return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState(
            {
              orgUrl: [reader.result],
            },
            () => {
              try {
                localStorage.setItem("orgUrlBg", this.state.orgUrl);
              } catch (e) {
                if (e == "QUOTA_EXCEEDED_ERR") {
                }
              }
            }
          );
        }.bind(this);
        let editedImages;
        editedImages = localStorage.getItem("editedImagesBgr");
        editedImages = editedImages ? editedImages.split(",") : [];
        this.setState(
          {
            selectedFile: event.target.files[0],
            editing: true,
            editedImagesArr: [...editedImages],
            uploading: true,
            loader: true,
            uploadBox: false,
          },
        //   () => {
        //     this.fileUploadHandler(1);
        //   }
        );
        }
        else{
          let file = event[0]
          if (Math.round(file.size / 1024) > 30720) {
            return;
          }
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            this.setState(
              {
                orgUrl: [reader.result],
              },
              () => {
                try {
                  localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                  if (e == "QUOTA_EXCEEDED_ERR") {
                  }
                }
              }
            );
          }.bind(this);
          let editedImages;
          editedImages = localStorage.getItem("editedImagesBgr");
          editedImages = editedImages ? editedImages.split(",") : [];
          this.setState(
            {
              selectedFile: event[0],
              editing: true,
              editedImagesArr: [...editedImages],
              uploading: true,
              loader: true,
              uploadBox: false,
            },
            // () => {
            //   this.fileUploadHandler();
            // }
          );
        }
      };
    render() {
        return (
            <div>
              <Helmet>
                <title>Get Footwear Background Replace With Shoe Photo Editor | Spyne</title>
                <meta property="og:title" content="Get Footwear Background Replace With Shoe Photo Editor | Spyne"/>
                <meta property="og:description" content="Select Spyne For Shoes Photo Editing and Replace Your Footwear Background In Just a Few Clicks. Get Footwear Background Edit With Our Shoes Photo Editing App Now."/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
                
                
            </Helmet>
                <div className={[styles['common-header'],''].join(' ')}>
                    <center>
                        <h1>Get Marketplace Ready Images</h1>
                        <p>in a <span>Single Click</span> using AI</p>
                    </center>
                </div>
                { this.state.selectedFile == null ?
                <div className={[styles['image-upload'],' container'].join(' ')}>
                    <div className={[styles['left-box'],'col-lg-6 col-md-6 col-sm-12 col-12'].join(' ')}>
                        <div className="outer-box d-dlex flex-row">
                        {/* {!this.state.loader ?
                        <> */}
                            <div className="d-flex outer-box-section-1">
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                        <p className={[styles['drag-text'],''].join(' ')}>Drag Image Here</p>
                                        <p className={[styles['browse-image-text'],' '].join(' ')}>or <span>browse</span> for Image</p>
                                        <p className={[styles['support-image-type'],''].join(' ')}>Support JPG/JPEG file</p>
                                        <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                    </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <input 
                                style={{ display: "none" }}
                                type="file"
                                onChange={this.fileSelectedHandler}
                                ref={(fileInput) => (this.fileInput = fileInput)}
                            ></input>
                            <button  onClick={() => this.fileInput.click()} className="btn btn-primary choose-image-button">Choose Image</button>
                        {/* </>
                        :
                            <Spinner
                            className="loader-edited"
                            style={{
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                            animation="border"
                            variant="primary"
                            />
                        } */}
                        </div>
                    </div>
                    <div className={[styles['right-box'],'col-lg-6 col-md-6 col-sm-12 col-12'].join(' ')}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/jootaa9.png" className={[styles[''],'img-fluid'].join
                    (' ')}></img>
                    </div>
                    {/* <Footer /> */}
                </div>
                :
                <FootwearUseCases
                    originalImage={this.state.orgUrl}
                    selectedFile={this.state.selectedFile}
                />
                }
            </div>
        )
    }
}
