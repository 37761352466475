import React, { Component } from 'react';
import  {Modal} from 'react-bootstrap'
import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import Slider from "react-slick";
import axios from 'axios'
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  


class BackgroundListModalBulk extends Component {
    constructor(props){
        super(props)
        this.state={
            gifList:[],
            gifUrl: 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/comp_gifV2/929.gif'
        }
    }

    componentDidMount = async () => {
        axios.get('https://www.clippr.ai/api/fetch-gif')
            .then(res => {
                this.setState({
                    gifList: res.data
                })
            })
    }

    handleCloseModal = () => {
       
            this.props.hideModal()
    }

    handleBackgroundGif = (imageId,imageCredit, gifUrls) => {

        // for(let i = 0; i<this.state.gifList.length ; i++){
        //     if(imageId === this.state.gifList[i].backgroundId){
                this.setState({
                    gifUrl: gifUrls
                })
        //     }
        //     // console.log(this.state.gifList[i])
        // }

        this.props.handleBackground(imageId,imageCredit)
    }

    handleBulkBackgroundSelection = () =>{
        this.props.creditVerify()
        this.props.hideList()
    }

    render() {
        const settings = {
            // className: "center",
            // dots: true,
            infinite: false,
            slidesToShow:3,
            slidesToScroll: 1,
            // centePadding:"1px",
            // vertical: true,
            // verticalSwiping: true,
            swipeToSlide: true,
            autoplay: false,
            speed: 500,
            // autoplaySpeed: 7000
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          }; 
        return ( 
                <Modal 
                    show={this.props.showBackgroundList}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='lg'
                    centered>
                    
                    <Modal.Body>
                        <section className={[styles['perfect-output-1'],'p-0'].join(' ')}>
                            <div className={[styles[''],'container-fluid'].join(' ')}>
                                <div className={[styles['choose-output'],'row justify-content-center align-items-center'].join(' ')}>
                                    <div className={[styles['heading'],'col-md-12'].join(' ')}>
                                        <h3>Choose  <span>your Back</span>ground </h3>
                                    </div>

                                    <div className={[styles['bg-recomended-area'],styles['custom-big'], styles['bg-recomended-area-height'],'col-md-5'].join(' ')}>
                                        <div className={[styles['bg-recommended'],'row m-0'].join(' ')}>
                                            <h6>Backgrounds <span>(Recommended)</span></h6>
                                            <ul className={[styles['bg-recomended-images'],''].join(' ')}>
                                                {
                                                    this.props.planData.map((e,i) => {
                                                        return(
                                                            <li className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackgroundGif(e.imageId,e.imageCredit, e.gifUrl)}>
                                                                <img className="" src={e.imageUrl} style={{ height: '56px' }}/>
                                                                <span>{e.bgName}</span>
                                                            </li>
                                                        )
                                                        
                                                    })
                                                }
                                            
                                            </ul>
                                            {/* <p>Other Backgrounds</p>
                                            <ul className={[styles['bg-recomended-images'],'border-0 m-0'].join(' ')}>
                                                <li><img className="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/1%20wall.jpg"/>
                                                <span>Sterling Silver</span></li>
                                                <li><img className="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/1%20wall.jpg"/>
                                                <span>Sterling Silver</span></li>
                                                
                                            </ul> */}
                                        </div>

                                    </div>
                                    <div className={[styles['bg-recomended-right'],'col-md-7 col-12'].join(' ')}>
                                        {this.state.gifUrl != '' ?
                                        <img className="" src={this.state.gifUrl} />
                                        :
                                        <img className="" src={this.props.orgUrl} />
                                        }
                                    </div>

                                    <div className={[styles['bg-recomended-area'],'col-12 d-md-none d-block'].join(' ')}>
                                        <div className={[styles['bg-recommended'],'row m-0'].join(' ')} id="custom-bg-slider">
                                            <Slider {...settings} className= {[styles['custom-slider'],'col-md-12'].join(' ')} > 
                                                {
                                                    this.props.planData.map((e,i) => {
                                                        return(
                                                            <div className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackgroundGif(e.imageId,e.imageCredit)}>
                                                                <img className="" src={e.imageUrl}/>
                                                                {/* <span>{e.bgName}</span> */}
                                                            </div>
                                                        )
                                                        
                                                    })
                                                }
                                            </Slider>
                                        </div>       
                                    </div>

                                    <div className={[styles['perfect-output-images'],'col-md-12'].join(' ')}>
                                        <p><span><a onClick={this.props.hideList}>Cancel</a></span> <a onClick={this.handleBulkBackgroundSelection}>Apply</a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                    </Modal.Body>

                </Modal>
        );
    }
}
 
export default BackgroundListModalBulk;