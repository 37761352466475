import React, { Component } from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import LandingModal from "../../Common/Modal/LandingModalFashion";
import styles from '../../../Css/3DBuild/curtains/UploadCurtains.module.css'
import SelectTemplateCushion from "../Cushion/selectCushionTemplate"
import OutputCushion from "../Cushion/OutputCushion"
import { Message } from "@material-ui/icons";
import alertify from 'alertifyjs';
import { Helmet } from "react-helmet";
import Drawer from "../../Drawer/Drawer";
// import '../alertifyjs/build/css/alertify.css';
import{ delay }from 'q'


export default class uploadCushion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      selectedFile: null,
      url: [],
      orgUrl: "",
      switchScreen: false,
      imageCategory: "",
      loader: false,
      imageWidth: "",
      imageHeight: "",
      productHunt: 'clippr',
      showLoginModal: true,
      auth_key: '',
      emailId: '',
      availableCredit: 0,
      dropBackground: false,
      creditAlloted: 0,
      creditUsed: 0,
      wm_image: [],
      hd_image: [],
      isProductCategory: false,
      ProductCategory: "",
      category: "",
      isUploadSaree: false,
      isUploadBlouse: false,
      showYoutubeGifModal: false,
      showSareeModal: false,
      showBlouseModal: false,
      isProjectReady: false,
      isSaree: false,
      isBlouse: false,
      selected: false,
      uploadScreen: false,
      sareeSpinner: false,
      posearr: [],
      skuId: '',
      projectId: '',
      spinnerDownload: false,
      downloadZipLink: '',
      forDublicateProjectId: 1,
      templateSelection: false,
      templateProcess: false,
      bgCredit: 0,
      poselength: 0,
      downloadType: '',
      checkColorEnhancement: false,

    };
  }

  cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]

  handelColorEnhancement = () => {
    this.setState({
      checkColorEnhancement: !this.state.checkColorEnhancement
    })
  }
  handleDownloadModal = async (DT) => {

    let userId = this.cookie('userId')
    let price
    await this.setState({ bgCredit: this.state.poselength, downloadType: DT });
    if (this.state.downloadType == 'HD' && this.state.availableCredit >= this.state.bgCredit) {

      price = this.state.bgCredit;
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/v4/download-history`,
        params: {
          user_id: userId,
          enterprise_id: 'TaD1VC1Ko',
          sku_id: this.state.skuId[0]
        }

      }).then(resp => {
        if (resp.data.status == 404) {
          let auth_key = this.cookie('auth_token')
          const fd = new FormData()
          fd.append("auth_key", auth_key)
          fd.append("credit_reduce", price)
          axios.put(`${process.env.REACT_APP_BASEURL}/v2/credit/reduce-credit`, fd)
            //  updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
            .then((res) => {
              this.setState({
                availableCredit: this.state.availableCredit - price,
                // downloadComplete: true
              })
              for (var i = 0; i < this.state.hd_image.length; i++) {
                var output_link = this.state.hd_image[i];
                fetch(output_link, {
                  method: "GET",
                  headers: {}
                })
                  .then(response => {
                    response.arrayBuffer().then(function (buffer) {
                      const url = window.URL.createObjectURL(new Blob([buffer]));
                      const link = document.createElement("a");
                      link.href = url;
                      let name = output_link
                      var index = name.lastIndexOf("/") + 1;
                      var filename = name.substr(index)
                      link.setAttribute("download", filename);
                      document.body.appendChild(link);
                      link.click();
                    });
                    this.setState({
                      downloadComplete: true
                    })
                  })
                const fd = new FormData()
                fd.append("user_id", userId)
                fd.append("sku_id", this.state.skuId[0])
                fd.append("enterprise_id", 'TaD1VC1Ko')
                fd.append("download_hd", "true")
                axios.post(`${process.env.REACT_APP_BASEURL}/v4/update-download-status`, fd)
                .catch((e)=>{
                  alertify.error("Re-upload the image!")
                })
              }

            }).catch((e)=>{
              alertify.error("Re-upload the image!")
            })
        } else {
          for (var i = 0; i < this.state.hd_image.length; i++) {
            var output_link = this.state.hd_image[i];
            fetch(output_link, {
              method: "GET",
              headers: {}
            })
              .then(response => {
                response.arrayBuffer().then(function (buffer) {
                  const url = window.URL.createObjectURL(new Blob([buffer]));
                  const link = document.createElement("a");
                  link.href = url;
                  let name = output_link
                  var index = name.lastIndexOf("/") + 1;
                  var filename = name.substr(index)
                  link.setAttribute("download", filename);
                  document.body.appendChild(link);
                  link.click();
                });
                this.setState({
                  downloadComplete: true
                })
              })
          }

        }
      }).catch((e)=>{
        alertify.error("Re-upload the image!")
      })
    }
    else {
      for (var i = 0; i < this.state.wm_image.length; i++) {
        var output_link = this.state.wm_image[i];
        fetch(output_link, {
          method: "GET",
          headers: {}
        })
          .then(response => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              let name = output_link
              var index = name.lastIndexOf("/") + 1;
              var filename = name.substr(index)
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            });
            this.setState({
              downloadComplete: true
            })
          })
      }
      const fd = new FormData()
                fd.append("user_id", userId)
                fd.append("sku_id", this.state.skuId[0])
                fd.append("enterprise_id", 'TaD1VC1Ko')
                fd.append("download_low_res", "true")
                axios.post(`${process.env.REACT_APP_BASEURL}/v4/update-download-status`, fd)
                .catch((e)=>{
                  alertify.error("Re-upload the image!")
                })
    }
  }

  handleDownloadComplete = () => {
    this.setState({ downloadComplete: false })
  }

  handelModelprocess = () => {
    this.setState({
      templateProcess: true
    })
  }
  handleUpload = (e) => {
    this.setState({
      selectedFile: null
    })
  }
  handleUploadScreen = (pose, modelId) => {
    this.setState(
      {
        uploadScreen: true,
      },
      () => {
        this.fileUploadHandler(pose, modelId);
      })
  }

  getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  handleUploadBtn = async () => {
    await this.setState({
      templateProcess: false,
      templateSelection: false,
      orgUrl: '',
      selectedFile: null
    })
  }
  fileUploadHandler = async (pose, modelId) => {
    this.setState({
      poselength: pose.length,
      url: [],
      wm_image: [],
      hd_image: [],
      orgUrl: [],
      skuId: [],
      projectId: []
    })
    this.setState({ posearr: pose });
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          uploadPercentage: percentCompleted,
        });
        if (percentCompleted === 100) {
          this.setState({
            uploading: false,
            processing: true,
          });
        }
      }.bind(this),
    };


    try {
      let uuid = this.getRandomString(8);
      let auth_key = this.cookie('auth_token')
      let _userId_ = this.cookie("userId")
      let sku_name = "API_SKU_" + _userId_ + uuid;
      let project_name = "API_PROJECT_" + _userId_ + uuid;

      this.setState({
        sareeSpinner: true
      })
      for (var i = 0; i < pose.length; i++) {
        var data = new FormData();
        data.append('auth_key', auth_key);

        data.append('pose_id', pose[i]);
        data.append('model_id', "301");
        data.append('frame_seq_no', i + 1);
        data.append('total_project_count', pose.length);
        data.append('prod_cat_id', 'cat_skJ7HIvnc');
        data.append('prod_sub_cat_id', 'prod_WiE3dLKOs');
        data.append('image_file', this.state.selectedFile);
        data.append('category', "Cushion");
        data.append('source', 'BULKPROCESS')
        data.append('is_color', this.state.checkColorEnhancement)

        var config_ph = {
          method: 'post',
          url: `${process.env.REACT_APP_BASEURL}/v2/rendering/imageProcessSaree`,
          
          data: data
        };
        axios(config_ph)
          .then((res) => {
            this.setState({
              url: [...this.state.url, res?.data?.data?.display_image],
              wm_image: [...this.state.wm_image, res?.data?.data?.watermark_image],
              hd_image: [...this.state.hd_image, res?.data?.data?.output_image],
              orgUrl: [res?.data?.data?.original_image],
              skuId: [res?.data?.data?.sku_id],
              projectId: [res?.data?.data?.project_id]
            })
            try {
              localStorage.setItem("urlbg", res.data.data.display_image);
              let editedImages;
              editedImages = localStorage.getItem("editedImagesBgr");
              editedImages = editedImages ? editedImages.split(",") : [];
              editedImages.push(res.data.display_image);
              localStorage.setItem(
                "editedImagesBgr",
                editedImages.toString()
              );
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
            this.setState({
              orgImgShow: true,
              processing: false,
              loader: false,
              editedState: true,
              uploadPercentage: 0,
              tabIndex: 1,

            });
          }).catch((e)=>{
            alertify.error("Re-upload the image!")
          })
          await delay(7000)
      }
      this.setState({ sareeSpinner: false })
    } catch (e) {
      this.setState({
        isError: true,
        uploading: false,
        processing: false,
        loader: false,
        tabIndex: 0,
      });
      alertify.error("Re-upload the image!")
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      loader: true,
      dropBackground: false,
      isProjectReady: false,
      showSareeModal: true,
    });
    if (event.target) {
      var file = event.target.files[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event.target.files[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
        },
      );
    } else {
      let file = event[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,

        },
      );
    }
  };

  componentDidMount = async () => {

    let auth_key = this.cookie('auth_token')
    this.setState({
      auth_key: auth_key
    })


    var passing_location;


    passing_location = 'NULL'
    localStorage.setItem("passing_location", passing_location);


    if (window.localStorage.getItem("clippr_user_email") != null) {
      let dict2 = {};
      var userEmail = this.cookie("emailId")
      this.setState({
        emailId: userEmail
      })
      let _userId_ = this.cookie("userId")
      let isValidated = this.cookie("isValidated")

      dict2['authenticated_email_id'] = userEmail;
      dict2['user_id'] = _userId_;
      dict2['product_category'] = 'Cars Replacement';
      dict2['passing_location'] = window.localStorage.getItem("passing_location");
      dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");

      if (isValidated == 'true') {
        dict2['otp_verified'] = 'YES';
      }
      else {
        dict2['otp_verified'] = 'NO';
      }

      axios.post(`${process.env.REACT_APP_BASEURL}/get_starting_info/`, dict2)
        .then((res) => {
        })
        .catch((err) => {
        })
    }
    var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
    if (instructionModalCheck) {
      this.setState({
        showInstruction: false
      })
    }
    else {
      this.setState({
        showInstruction: true
      })
    }



    let userId = this.cookie('userId')
    if (userId !== "NA") {
      let emailId = this.cookie("emailId")
      let auth_key = this.cookie("auth_token")
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/v2/user/get`,
        params: {
          email_id: emailId,
          auth_key: auth_key
        }

      }).then(resp => {
        if (resp.data.status == 200) {
          this.setState({
            availableCredit: resp.data.data.credit_available,
            creditAlloted: resp.data.data.credit_allotted,
            creditUsed: resp.data.data.credit_used
          })
        } else {
        }
      })
      this.setState({
        userId: userId
      })
    }
    if (this.state.uploadStarteds === "true") {
      this.getUserCredit()
    }

  }

  cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]

  hideLandingModal = () => {
    this.setState({ showLoginModal: !this.state.showLoginModal })
  }

  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }
  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }
  isProdCategory = (screenNo) => {
    this.setState({ ProductCategory: "", isProjectReady: false, showSareeModal: !this.state.showSareeModal, orgUrl: "" })
  }
  handleProductCategory = (e) => {
    e.preventDefault()
    this.setState(
      {
        isProductCategory: true
      }
    )
  }


  handleYoutubeGifModal = () => {
    this.setState(
      {
        showYoutubeGifModal: !this.state.showYoutubeGifModal,
      }
    )
  }

  handleSareeModal = () => {    // After Clicking cross Button on saree Modal
    this.setState(
      {
        isUploadSaree: true,
        showSareeModal: !this.state.showSareeModal,
      }
    )
  }

  handleBlouseModal = () => {     //For opening Blouse Modal
    this.setState(
      {
        isUploadBlouse: true,
        showBlouseModal: !this.state.showBlouseModal,
      }
    )
  }

  handleSareeUpload = () => {   //For opening saree Modal
    this.setState(
      {
        showSareeModal: true,
      }
    )
  }

  handleFinishSareeUpload = () => {    // // After Clicking Upload Button on saree Modal
    this.setState(
      {
        isProjectReady: true,
        uploadScreen: false
      },
    )

  }

  handleBlouseUpload = () => {   // For Opening Blouse modal
    this.setState(
      {
        isUploadBlouse: true,
        showBlouseModal: true,
        isProjectReady: true,
        showProjectNameModal: true
      }
    )
  }

  handleBlouseSkip = () => {   //if user clicks skip on Blouse Modal
    this.setState(
      {
        isUploadBlouse: true,
        isProjectReady: true,
        showProjectNameModal: true
      }
    )
  }
  handeltemplateSelection = (e) => {
    this.setState({
      templateSelection: true
    })
  }

  render() {
    return (

      <><Helmet>
        <title>Best 3D Cushion Rendering | Cushion Photo Editor.Try Now</title>
        <meta id="meta-description" name="description" content="Change Your Curtain Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Curtain Photo Editing Tech. Instantly Change Image Background for Curtains and give your Curtain Photography a new look" />
        <meta property="og:title" content="Best 3D Curtain Rendering | Curtain Photo Editor.Try Now" />
        <meta property="og:description" content="Change Your Curtain Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Curtain Photo Editing Tech. Instantly Change Image Background for Curtains and give your Curtain Photography a new look" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
      </Helmet><div>
          {this.state.showLoginModal ?
            <LandingModal
              showInstruction={this.state.showLoginModal}
              hideModal={this.hideLandingModal}
              productHunt={this.state.productHunt} />
            : null}
          {!this.state.templateSelection ?

            <>
              {this.state.selectedFile == null ?

                <Dropzone
                  onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
                  onDragEnter={this.dragStart}
                  onDragLeave={this.dragLeave}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        onClick: (event) => event.stopPropagation(),
                      })}
                      className={styles["dropzone1"]}
                    >
                      {this.state.dropBackground ? (
                        <section className={[styles["drop-box-text"]]}>
                          <div>Drop Image Anywhere</div>
                        </section>
                      ) : null}

                      {/* Drawer section  */}
                      <Drawer />

                      <section className={[styles['upload-screen'], ''].join(' ')}>

                        <div className={[styles[''], 'container-fluid'].join(' ')}>

                          <div className={[styles[''], 'row'].join(' ')}>

                            <div className={[styles['upload'], 'col-md-12'].join(' ')}>

                              <h1>Upload Cushion cloth</h1>
                              <p><span></span></p>
                            </div>
                          </div>
                          {/* {  !this.state.isUploadSaree  ? */}
                          <>

                            <div className={[styles['crop-saree'], 'row'].join(' ')}>

                              <div className={[styles[''], 'offset-md-3 col-md-6'].join(' ')}>
                                <Dropzone
                                  onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section
                                      className={[styles["try-drag-drop"],''].join(" ")}>
                                      <div {...getRootProps({})} className={[styles['upload-cols'], 'row'].join(' ')}>
                                        <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                          <p className={styles['active']}><span>1</span> Upload Cushion Cloth </p>
                                        </div>
                                        <div className={[styles['upload-text'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                                          <div className={[styles['upload-box'],''].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/upld.png" />
                                            <h4>DROP IMAGE</h4>
                                            <p>Supports jpeg, jpg, bmp & png (maximun 36 images)</p>
                                          </div>
                                          <h3>Upload Cushion</h3>
                                        </div>

                                        <input
                                          onChange={this.fileSelectedHandler}
                                          ref={(fileInput) => (this.fileInput = fileInput)}
                                          type="file"
                                          {...getInputProps()} />
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                {/* <h6 style={{textAlign:"center"}}> <button disabled={this.state.orgUrl===""} className={this.state.orgUrl==="" ? styles['disabled-next']: styles['next-btn']} onClick={this.handleSareeUpload} >Next</button></h6> */}
                              </div>
                            </div>
                          </>


                        </div>
                      </section>
                      <input
                        onChange={this.fileSelectedHandler}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                        type="file"
                        {...getInputProps()} />

                    </div>
                  )}
                </Dropzone>
                :
                <section className={[styles['uploaded-img'], ''].join(' ')}>
                  <div className={[styles['fabric-upload'], 'col-md-12'].join(' ')}>
                    <h3>Uploaded Cloth</h3>
                    <img src={this.state.orgUrl} />
                    <span onClick={() => this.handleUpload()}>X</span>
                  </div>
                  <div className={styles['select-template']}>
                    <button onClick={() => this.handeltemplateSelection()}>Select Template</button>
                  </div>
                </section>}
            </>

            :
            <>
              {!this.state.templateProcess ?
                //selectmodel
                <SelectTemplateCushion
                  fileSelectedHandler={this.fileSelectedHandler}
                  availableCredit={this.state.availableCredit}
                  fileUploadHandler={this.fileUploadHandler}
                  handleModelProcess={this.handelModelprocess}
                  handleUploadScreen={this.handleUploadScreen}
                  handleUploadBtn={this.handleUploadBtn}
                  checkColorEnhancement={this.state.checkColorEnhancement}
                  handelColorEnhancement={this.handelColorEnhancement} />
                :
                //output of suit on models
                <OutputCushion
                  originalImage={this.state.orgUrl}
                  availableCredit={this.state.availableCredit}
                  editedImage={this.state.url}
                  hd_image={this.state.hd_image}
                  wm_image={this.state.wm_image}
                  fileSelectedHandler={this.fileSelectedHandler}
                  loader={this.state.loader}
                  posearr={this.state.posearr}
                  handleUploadBtn={this.handleUploadBtn}
                  handleDownloadModal={this.handleDownloadModal}
                  downloadComplete={this.state.downloadComplete}
                  handleDownloadComplete={this.handleDownloadComplete} />}
            </>}   </div></>


    );
  }
}
