import React, {useEffect, useContext, useState} from 'react'
import cls from '../Login/LoginPage.module.css'
import { AuthContext } from '../../context/GlobalState'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'

const SignUp = () => {

	const [emailId, setEmailId] = useState('')
	const [password, setPassword] = useState('')
	const [userName, setUserName] = useState('')
	const [country, setCountry] = useState('')
	const [countries, setCountries] = useState([])
	const [signUpType, setSignUpType] = useState('PASSWORD')
	const [otpSent, setOtpSent] = useState(false)
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const passwordInputRef = React.createRef()
	const history = useHistory()
	const context = useContext(AuthContext)

	useEffect(() => {
		axios({
			url: `${process.env.REACT_APP_BASEURL}/v2/user/countries`,
			method: 'GET'
		})
		.then((res) => {
			if (res.data?.status === 200) {
				setCountries([...res.data.data])
			}
		})
	}, [])

	const handleSignUp = (e) => {
		e.preventDefault()
		const fd = new FormData()
		fd.append('api_key', process.env.REACT_APP_API_KEY)
		fd.append('email_id', emailId)
		fd.append('first_name', userName)
		fd.append('country', country)
		fd.append('signup_url', window.location.href)
		fd.append('source', 'Web')
		if (signUpType === 'PASSWORD') {
			fd.append('password', password)
			fd.append('strategy', 'PASSWORD')
			axios({
				url: `${process.env.REACT_APP_BASEURL}/v2/user/signup`,
				method: 'POST',
				data: fd
			})
			.then(handleSignUpResponse)
			.catch((err) => {
				setError(false)
				console.log(err)
				setErrorMsg("Something went wrong")
			})
		} else {
			fd.append('otp', password)
			fd.append('signup_url', window.location.href)
			fd.append('source', 'Web')
			axios({
				url: `${process.env.REACT_APP_BASEURL}/v2/user/validate-otp`,
				method: 'POST',
				data: fd
			})
			.then((handleSignUpResponse))
			.catch((err) => {
				setError(false)
				console.log(err)
				setErrorMsg("Something went wrong")
			})
		}
	}

	const requestOtp = (e) => {
		e.preventDefault()
		const data = new FormData()
		data.append('api_key', process.env.REACT_APP_API_KEY)
		data.append('email_id', emailId)
		axios({
			url: `${process.env.REACT_APP_BASEURL}/v2/user/request-otp`,
			method: 'POST',
			data: data
		})
		.then((success) => {
			if (success.data?.status === 200)
				setOtpSent(true)
		})
		.catch((err) => setError(true))
	}

	const toggleSignUpType = () => {
		setPassword('')
		passwordInputRef.current.value = ''
		let toggle = signUpType === 'PASSWORD' ? 'OTP' : 'PASSWORD'
		setSignUpType(toggle)
		setOtpSent(false)
		setError(false)
		setErrorMsg('')
	}

	const handleSignUpResponse = (resp) => {
		if(resp?.data?.status == 200){
				document.cookie = `emailId=${emailId}; path=/`;
				document.cookie = `userId=${resp.data.user_id}; path=/`;
				document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
				document.cookie = `ssoToken=${resp.data?.auth_token}; path=/`;
                document.cookie = `enterpriseId=${resp.data?.enterprise_id}; path=/`;
				context.loggedIn({
						loggedIn: true,
						user: {
								emailId,
								userId: resp.data.user_id
						},
						token: resp.data.auth_token,
						apiKey: process.env.REACT_APP_API_KEY
				})
				history.push('/try-categories')
		}else if(resp?.data?.status != 200){
				setError(true)
				setErrorMsg(resp.data.message)
		}
	}

    return (
    	<div className={cls["font-family"]}>
				<div className="container-fluid">
					<div className="row">
						<div className={["col", cls["top-container"]].join(' ')}>
							<p className={cls["back"]} onClick={() => history.goBack()}>
								<span> &larr; </span>
								Back
							</p>
							<p className={cls["create-account"]}>
								<span className={cls["hide-in-mobile"]}>Already a Spyne User ? </span>
								<Link className={[cls["is-link"], cls['is-primary']].join(' ')} to="/login">Login Here</Link>
							</p>
						</div>
					</div>
				</div>
				<div className="mt-5 mt-lg-0 mt-xl-0" id={cls["login-vmi"]}>
					<div className="row justify-content-center align-items-center">
						<div className="col-12 text-center">
							<img className={["img-fluid", cls["logo"]].join(' ')} src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png" />
							<h4 className={cls["brand"]}>SpyneAI <span className={cls[""]}> Sign Up</span> </h4>
						</div>
					</div>
					<div className="row justify-content-center align-items-center mt-lg-5 mt-xl-5">
						<div className="col-lg-4 col-12 col-sm-12 col-xl-4 col-md-4 text-center">
							<form onSubmit={handleSignUp}>
								<div className={[cls["input-box"], cls["form-floating"]].join(' ')}>
									<input 
										className={[cls["inp"], cls["input-container"]].join(' ')} 
										placeholder="Email" 
										type="email" 
										id="email-mobile" 
										required 
										defaultValue={emailId}
										onChange={(e) => setEmailId(e.target.value)} />
								</div>
								<div className={[cls["input-box"], cls["form-floating"]].join(' ')}>
									<input 
										className={[cls["inp"], cls["input-container"]].join(' ')} 
										placeholder="Business/Your Name" 
										type="text" 
										required 
										defaultValue={userName}
										onChange={(e) => setUserName(e.target.value)} />
								</div>
								<div className={[cls["input-box"], cls["form-floating"]].join(' ')}>
									<select 
										className={[cls["inp"], cls["input-container"]].join(' ')}
										placeholder="Country"
										onChange={(e) => setCountry(e.target.value)}>
											<option value="" >Country</option>
										{
											countries.map((country, index) => {
												return (
													<option key={`${country}_${index}`}>{ country.name }</option>
												)
											})
										}
									</select>
								</div>
								<div className={[cls["input-box"],cls["form-floating"]].join(' ')}>
									{
										signUpType === 'PASSWORD' ? 
										(
											<input 
												className={[cls["inp"], cls["input-container"]].join(' ')} 
												placeholder="Password" 
												type="password" 
												id="password" 
												required 
												ref={passwordInputRef}
												defaultValue={password}
												onInput={(e) => setPassword(e.target.value)} />
										)
										:
										(
											<input 
												className={[cls["inp"], cls["input-container"]].join(' ')} 
												placeholder="Enter your OTP" 
												type="text" 
												id="password" 
												disabled={!otpSent} 
												required 
												ref={passwordInputRef}
												onChange={(e) => setPassword(e.target.value)} />
										)
									}
								</div>
								<div className="my-3">
									<p className="text-red">{errorMsg}</p>
								</div>
								<div className="mt-3">
									{
										signUpType === 'PASSWORD' || otpSent ? 
										(
											<button className={cls["submit-btn"]} type="submit" id={cls["login"]}>
												{	otpSent ?
													'Verify OTP'
													:
													'Sign Up'
												}
											</button>
										)
										:
										(
											<button className={cls["submit-btn"]} type="button" onClick={requestOtp}>
												Request OTP (One Time Password)
											</button>
										)
									}
								</div>
							</form>
						</div>
						<div className={["col-lg-2 col-12 col-sm-12 col-xl-2 col-md-2", cls["line-container"]].join(' ')}>
							<div className={cls["line-divider"]}></div>
							<div className={cls["line-divider_or"]}>OR</div>
							<div className={cls["line-divider"]}></div>
						</div>
						<div className="col-lg-4 col-12 col-sm-12 col-xl-4 col-md-4 text-center">
							<button className={cls["request-btn"]} id="login-otp-toggle" onClick={toggleSignUpType}>
							Sign Up Using   { signUpType === 'PASSWORD' ? 'OTP' : 'Password' } (One Time Password)
							</button>
							<button className={[cls["google-btn"], "mt-3 d-none"].join(' ')}>
								<span>
									<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M21.9873 11.5621C21.9873 10.6321 21.9125 9.95347 21.7508 9.24972H11.2314V13.4471H17.406C17.2816 14.4902 16.6094 16.0612 15.1155 17.1167L15.0945 17.2573L18.4205 19.8584L18.651 19.8816C20.7673 17.9085 21.9873 15.0055 21.9873 11.5621Z" fill="#4285F4" />
										<path d="M11.2306 22.6211C14.2557 22.6211 16.7952 21.6157 18.6501 19.8814L15.1146 17.1166C14.1685 17.7827 12.8987 18.2476 11.2306 18.2476C8.2678 18.2476 5.75314 16.2746 4.85674 13.5475L4.72534 13.5588L1.26691 16.2608L1.22168 16.3877C3.0641 20.0824 6.84857 22.6211 11.2306 22.6211Z" fill="#34A853" />
										<path d="M4.85645 13.5475C4.61993 12.8437 4.48304 12.0896 4.48304 11.3105C4.48304 10.5313 4.61993 9.7773 4.844 9.07354L4.83774 8.92366L1.33599 6.17829L1.22142 6.23331C0.462079 7.76653 0.0263672 9.48827 0.0263672 11.3105C0.0263672 13.1328 0.462079 14.8544 1.22142 16.3876L4.85645 13.5475Z" fill="#FBBC05" />
										<path d="M11.2307 4.37338C13.3345 4.37338 14.7537 5.29079 15.5629 6.05744L18.7249 2.94075C16.7829 1.11851 14.2557 2.28882e-05 11.2307 2.28882e-05C6.84867 2.28882e-05 3.06422 2.53858 1.22182 6.23331L4.84441 9.07354C5.75326 6.34646 8.2679 4.37338 11.2307 4.37338Z" fill="#EB4335" />
									</svg>
								</span>
								<span>
									Continue with Google
								</span>
							</button>
						</div>
					</div>
					<div className="row">
						<div className={[cls['forget-pass'],'col-lg-4 offset-lg-1 text-center'].join(' ')}>
							<a className={cls["is-link"]}>Forgot Password ?</a>
						</div>
					</div>
				</div>
				<div className="container mt-5">
					<div className="row justify-content-center align-items-center">
						<div className="col-12 text-center">
							<a href="https://www.spyne.ai/v2/login">
								<p className={cls["photographer-signIn"]}>Sign in as
									<span className="mx-2"> Photographer</span>
									<span>
										<img src="https://storage.googleapis.com/spyne-website/try-categories/next.svg" />
									</span>
								</p>
							</a>
							<div className="d-flex justify-content-center align-items-center">
								<a href="https://www.spyne.ai/v2/login?source_url=https://www.albumm.ai&redirect_url=albumm"><img className={[cls["logo2"], "mx-3"].join(' ')} src="https://images.spyne.ai/landing-page/share-landing/new-share/albumm-new-black.png" /></a>
								<a href="https://www.spyne.ai/v2/login?source_url=https://www.webbr.ai/&redirect_url=webbr"><img className={[cls["logo2"], "mx-3"].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/Webbr/logo%202.png" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
}

export default SignUp