import React, { Component } from 'react'
import Footer from '../Common/Footer'
import Styles from '../../Css/Upscaling/Upscaling.module.css'

export default class Upsacling extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    render() {
        return (
            <div>
                
                <section className={[Styles['main-banner'],''].join(' ')}>
                    <div className={[Styles[' '],'container'].join(' ')}>
                        <div className={[Styles['banner-row'],'row'].join(' ')}>
                            <div className={[Styles['left-banner-text'],'col-md-3 col-lg-2 d-md-block d-none'].join(' ')}>
                                <p>Increase the conversion rates of your e-commerce - crisp and clear images to let the customer see all product details.</p>
                            </div>
                            <div className={[Styles[' '],'col-md-6 col-lg-8 '].join(' ')}>
                                <div className={[Styles['banner-box'],'row '].join(' ')}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Upscaling/Group+5916.png" class="up_img"/>
                                            
                                    <h1 className={[Styles[''],' '].join(' ')}>Upscale Images</h1>
                                    <p className={[Styles[''],' '].join(' ')}>Obtain high resolution with fully automated processing. Photo improvement has never been so easy.</p>
                                    <form className={[Styles['file-upload-form'],'d-md-block d-none'].join(' ')}>
                                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Upscaling/Group.png"/>
                                        
                                        <label for="file-upload" className={[Styles['file-drag'],' '].join(' ')} > 
                                            Drag files here  <strong>or</strong> <span>Click to upload</span> </label>
                                
                                    </form>
                                    <button type="" className={[Styles['mybutton'],'d-block d-md-none'].join(' ')}>Upload Image</button>
                                </div>
                            </div>

                            <div className={[Styles['right-banner-text'],'col-md-3 col-lg-2 d-md-block d-none'].join(' ')}>
                                <p>Get vibrant & saturated images to increase interest.</p>
                            </div>

                        </div>
                    </div>
                    
                </section>
                {/* Main-Banner Section */}
                <section className={[Styles['after-image-section'],''].join(' ')}>
                    <div className={[Styles[' '],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles[' '],'col-md-6 col-lg-8 offset-md-3 offset-lg-2'].join(' ')}>
                                <div className={[Styles['image-area'],'row'].join(' ')}>  
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Upscaling/Rectangle+2902.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                {/* Footer */}
            </div>
        )
    }
}
