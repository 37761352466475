import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Spinner, Modal } from "react-bootstrap";
import Toggle from 'react-toggle'
import axios from 'axios'
import TwentyTwenty from 'react-twentytwenty'

import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import '../../../App.css'
import CarsBackground from '../Background/CarsBackground'
import CarsFront from '../FrontPages/CarsFront'
import Footer from '../../Common/Footer'
import {Helmet} from "react-helmet";
import {fetchCreditUserDetails, updateUserCredit,cookie} from '../../Common/Utils'


export default class CarsLandingPage extends Component {
    constructor(props){
        super(props)
        this.state={
          selectedFile: null,
          selectedLogoFile: null,
          url: window.sessionStorage.getItem("lastEditedImage") ? window.sessionStorage.getItem("lastEditedImage") : '' , // url of the image returned by the api 
          orgUrl:  window.sessionStorage.getItem("lastRawImage") ? window.sessionStorage.getItem("lastRawImage") :  '',
          object_id:0,
          background_id:0,
          uploadPercentage: 0,
          uploading: false,
          orgImgShow: true,
          loader: false,
          uploadBox: true,
          editedState: false,
          tabIndex: 0,
          isError: false,
          processing: false,
          editedImagesArr: [],
          editing: false,
          carShadow:false,
          buffer_array:[1],
          cnt:1,
          spinner:window.sessionStorage.getItem("lastEditedImage") ? false : true,
          car_bg_replacement_angle:'front',
          selectedAngle:'',
          imageSelected:false,
          firstUpload:true,
          planData:[],
          availableCredit:5,
          alottedCredit: 0,
          creditUsed:0,
          isEditingFinish:false,
          toggleChange:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          toggleStatus:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          bgCredit:0,
          loginStatus:false,
          logoUrl:'',
          logoPosition:null,
          hideLogo:false,
          showInstruction:true,
          showInstructionModal:false,
          askEmail:false
        }
    }

    componentDidMount = async () => {
        var passing_location;
        if ("geolocation" in navigator) {
          // check if geolocation is supported/enabled on current browser
          navigator.geolocation.getCurrentPosition(
           function success(position) {
    
            //  console.log('latitude', position.coords.latitude, 
            //              'longitude', position.coords.longitude);
            passing_location = [position.coords.latitude, position.coords.longitude];
            localStorage.setItem("passing_location", passing_location);
         },
          function error(error_message) {
            passing_location = 'NULL'
            localStorage.setItem("passing_location", passing_location);
          }  
      )}
         else {
          passing_location = 'NULL'
          localStorage.setItem("passing_location", passing_location);
        }
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            this.setState({
                showInstruction:true
            })
        }
        axios({
            'method':"GET",
            'url':`https://www.clippr.ai/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`
        })
        .then((res) => {
            this.setState({
                planData:res.data
            })
        })
        let userId = this.cookie('userId')
        if(userId !== "NA"){
            fetchCreditUserDetails(userId).then((resp) => {
                this.setState({
                    availableCredit:resp.data.data.creditAvailable,
                    allotedCredit:resp.data.data.creditAlloted,
                    creditUsed:resp.data.data.creditUsed
                })
            } )
        }
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
      };
    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }

    handleAngle=(id)=>{
        let selectedAngle = this.cookie("selectedAngle")
        this.setState({
          car_bg_replacement_angle:id,
          selectedAngle:selectedAngle,
          imageSelected:true,
          isEditingFinish:false
        })
    }

    handleId=(id,price)=>{
        this.setState({
            background_id:id,
            bgCredit:price,
            isEditingFinish:false,
            askEmail:false,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    updateCredit = (e) => {
        // e.preventDefault()
        let userId = this.cookie('userId')
        let price = this.state.bgCredit
        updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
        .then((res) => {
            this.setState({
                availableCredit: this.state.availableCredit - price
            })
            const output_link = this.state.url;
            fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();
                });
            })
        })
    }
    askEmail =() => {
        this.setState({
            askEmail:true
        })
    }

    hitapi = () => {
        this.setState({
          spinner: true,
          hideLogo:true,
        });
        if(this.state.selectedFile !== null){
            const fd = new FormData();
            fd.append("image", this.state.selectedFile);
            fd.append("optimization",false)
            let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
            axios.post(`https://www.clippr.ai/api/upload?api_key=${api_key}`,fd,{params:{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"}})
            .then((uploadedResponse) => {
                const replacementFd = new FormData()
                replacementFd.append("user", "spyne");
                replacementFd.append("key", 123);
                replacementFd.append("bg_replacement_backgound_id", this.state.background_id);
                replacementFd.append("car_bg_replacement_angle",this.state.car_bg_replacement_angle)
                replacementFd.append("image_url",uploadedResponse.data.image)
                axios
                .post("https://www.clippr.ai/api/replace-car-bg-v2?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
                .then((res) => {
                    let userEmail = this.cookie("emailId")
                    let _userId_ = this.cookie("userId")
                    let isValidated = this.cookie("isValidated")
                    if(!userEmail){
                        clearInterval(x)
                        var x = setTimeout(this.askEmail, 3000);
                    }
                    let temp = res;
                    if (temp.data['url']) {

                        if (userEmail){       

                            temp.data['authenticated_email_id'] = userEmail;
                            temp.data['user_id'] = _userId_;
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            if (isValidated=='true'){
                                temp.data['otp_verified'] = 'YES';
                            }
                            else {
                                temp.data['otp_verified'] = 'NO';
                            }
                            if (window.localStorage.getItem("clippr_user_email") != null){
                                temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            }
                            else{
                                temp.data['free_trail_email'] = 'NULL';
                            }
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                        }
            
                        else if (window.localStorage.getItem("clippr_user_email") != null) {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }
                        else {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['authenticated_email_id'] = 'NULL';
                            temp.data['free_trail_email'] = 'NULL';
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }

                    }
                    if(this.state.selectedLogoFile !== null){
                        const logoFormData = new FormData()
                        logoFormData.append("logo",this.state.selectedLogoFile);
                        logoFormData.append("logo-position",this.state.logoPosition)
                        logoFormData.append("image_url",res.data.url)
                        axios.post("https://www.clippr.ai/api/dealership-logo",logoFormData)
                        .then((logores) => {
                            // const logoFormData2 = new FormData()
                            // logoFormData2.append("image",this.state.selectedLogoFile);
                            // logoFormData2.append("logo-position",this.state.logoPosition)
                            // logoFormData2.append("image_url",logores.data.org_url)
                            // axios.post("http://www.clippr.ai/models/car-logo",logoFormData2)
                            // .then((carLogo) => {
                                this.setState({ 
                                        url:logores.data.org_url,
                                        orgImgShow: true,
                                        processing: false,
                                        loader: false,
                                        editedState: true,
                                        uploadPercentage: 0,
                                        tabIndex: 1,
                                        spinner:false,
                                        toggleStatus:true,
                                        toggleChange:true,
                                        isEditingFinish:true,
                                        // selectedLogoFile:null,
                                        // logoUrl:''
                                    });
                            // })
                            // .catch((err)=>{
                            //     this.setState({
                            //         spinner:false
                            //     })
                            // })
                        })
                        .catch((err)=>{
                            this.setState({
                                spinner:false
                            })
                        })
                    }
                    else{
                        this.setState({ 
                            url:res.data.url,
                            orgImgShow: true,
                            processing: false,
                            loader: false,
                            editedState: true,
                            uploadPercentage: 0,
                            tabIndex: 1,
                            spinner:false,
                            toggleStatus:true,
                            toggleChange:true,
                            isEditingFinish:true
                            });
                    }
                    window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                    window.sessionStorage.setItem("lastEditedImage", res.data.url)
                    window.sessionStorage.setItem("lastRawImage",uploadedResponse.data.image)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch((uploadFail) => {
                this.setState({
                    spinner:false
                })
            })
        }
        else{
            const replacementFd = new FormData()
            replacementFd.append("user", "spyne");
            replacementFd.append("key", 123);
            replacementFd.append("bg_replacement_backgound_id", this.state.background_id);
            replacementFd.append("car_bg_replacement_angle",this.state.car_bg_replacement_angle)
            replacementFd.append("image_url",this.state.orgUrl)
            axios
            .post("https://www.clippr.ai/api/replace-car-bg?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
            .then((res) => {
                if(this.state.selectedLogoFile !== null){
                    const logoFormData = new FormData()
                    logoFormData.append("logo",this.state.selectedLogoFile);
                    logoFormData.append("logo-position",this.state.logoPosition)
                    logoFormData.append("image_url",res.data.url)
                    axios.post("https://www.clippr.ai/api/dealership-logo",logoFormData)
                    .then((logores) => {
                        let userEmail = this.cookie("emailId")
                        if(!userEmail){
                            clearInterval(x)
                            var x = setTimeout(this.askEmail, 3000);
                        }
                        this.setState({ 
                            url:logores.data.org_url,
                            orgImgShow: true,
                            processing: false,
                            loader: false,
                            editedState: true,
                            uploadPercentage: 0,
                            tabIndex: 1,
                            spinner:false,
                            toggleStatus:true,
                            toggleChange:true,
                            isEditingFinish:true,
                            // selectedLogoFile:null,
                            // logoUrl:''
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            spinner:false
                        })
                    })
                }
                else{
                    this.setState({ 
                        url:res.data.url,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true
                        });
                }
                // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
                // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                window.sessionStorage.setItem("lastEditedImage", res.data.url)
                window.sessionStorage.setItem("lastRawImage", this.state.orgUrl)
            })
            .catch((err) => {
                this.setState({
                    spinner:false
                })
            })
        }
    };
    handelCarShadow = () => {
        this.setState({
          carShadow:!this.state.carShadow
        })
    }
    dealerLogoFileHandler = (event) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
        this.setState(
            {
                logoUrl: [reader.result],
                logoPosition:"leftTop",
                hideLogo:false
            },
        );
        }.bind(this);
        this.setState(
        { 
            selectedLogoFile:event.target.files[0],
            logoUrl:  URL.createObjectURL(event.target.files[0])
        },
        );
    };

    handleLogoPosition = (e) => {
        console.log(e.target.name)
        this.setState({
          logoPosition:e.target.name,
          hideLogo:false
        })
    }
    handleInstruction = (e) => {
        // e.preventDefault()
        this.setState({
            showInstructionModal:e.target.checked
        })
        window.localStorage.setItem("showInstructionModal", e.target.checked)
    }
    handleInstructionModal = () => {
        this.setState({
            showInstruction:true
        })
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Change Car Background Instantly | AI Car Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content="Replace Your Car Images With Studio-Setting Backgrounds in Just 3 Seconds With Spyne AI Car Photo Editor. Instantly Remove Any Car Image Background and Give Your Car Image a New Look. 100% Automated Editing in Just a Click. Simply Drag And Drop Your Photo(s) And See How The AI Transforms Your Photo(s) In Seconds" />
                <meta property="og:title" content="Change Car Background Instantly | AI Car Photo Editor.Try Now"/>
                <meta property="og:description" content="Replace Your Car Images With Studio-Setting Backgrounds in Just 3 Seconds With Spyne AI Car Photo Editor. Instantly Remove Any Car Image Background and Give Your Car Image a New Look. 100% Automated Editing in Just a Click. Simply Drag And Drop Your Photo(s) And See How The AI Transforms Your Photo(s) In Seconds"/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
                
                
            </Helmet>
            <div className={styles['header-section']}>
                    <p className={styles['heading-text']}>Car Background Replacement</p>
                    <p className={styles['heading-para']}>Create Professional Automobile Pictures in a <span>Single Click</span> using AI</p>
            </div>
            {
            this.state.orgUrl == '' ?
            <>
                <div className={[styles['car-main-wrapper'],"container"].join(" ")}>
                    <Modal
                        show={this.state.showInstruction}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='lg'
                        centered
                        onHide={() => this.setState({showInstruction:!this.state.showInstruction})}
                    >
                        <Modal.Header closeButton/>
                        <Modal.Body>
                            <center>
                                <h1>Upload Images with these <span style={{color:"#FF7700"}}>angles</span></h1>
                                <p 
                                    style={{
                                        color:"#757575"
                                    }}
                                >Our AI work best on when uploading these angles</p>
                                {/* <video className="img-fluid" autoPlay loop muted controls>
                                    <source src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/ezgif.com-gif-to-webp.webp"></source>
                                    <source src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/car+clippr1.webm"></source>
                                </video> */}
                                <img className="img-fluid" style={{
                                    height:'60%',
                                    width:'70%',
                                    borderRadius:"10px"
                                    }}src="https://storage.googleapis.com/clippr-image-processing/Static/ezgif.com-optimize%20(1).gif"></img>
                                <div className="d-flex justify-content-center mt-5">
                                    {/* <input type="checkbox" checked={this.state.showInstructionModal ? true : false} onChange={this.handleInstruction}></input> */}
                                    <p style={{
                                        marginTop:"-4px",
                                        marginLeft:"10px",
                                        color:"#5e5e5e"
                                    }}></p>
                                </div>
                            </center>

                        </Modal.Body>
                    </Modal>
                    <div className={[styles['wrapper'],''].join(' ')}> 
                            <div className={[styles['left-section'],''].join(' ')}>
                                <center>
                                    <button onClick={this.handleInstructionModal} className={[styles['sample-button'], 'mt-5'].join(' ')}>See <span style={{color:"#FF7700"}}>Sample</span> input images</button>
                                </center>
                                <div className={[styles['mid-section'],''].join(' ')}>
                                {/* <button id="upload_widget" className={[styles['dropzone'],"cloudinary-button"].join(' ')} onClick={() => myWidget.open()} >Upload files</button> */}
                                    <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles[''],''].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone']}>
                                                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                <p className={styles["para-1"]}>Drag Image Here</p>
                                                <p class={styles["para-2"]}>or <span>browse</span> for Image</p>
                                                <p className={styles['supports']}>Support JPG/JPEG file</p>
                                                <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <div className={styles['choose-button']}>
                                        <input 
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.fileSelectedHandler}
                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                        ></input>
                                        <button  onClick={() => this.fileInput.click()} className="btn">Choose Image</button>
                                    </div>
                                </div>
                            </div>
                        <div className={[styles['right-section'],''].join(' ')}>
                            <div>
                                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[styles['section-two'],''].join(' ')}>
                    <div className={[styles['section-two-one'],' '].join(' ')}>
                        <img className={[styles['number-image'],''].join(' ')} src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/01.png"></img>
                        <div className={[styles[''],'row'].join(' ')}>
                             <div className={[styles['section-two-one-left'],'col-lg-6 col-12 col-sm-12 col-md-12'].join(' ')}>
                                 <h1>Save <span>48 hours</span> of manual editing. </h1>
                                 <p>Our AI driven car background replacement tools helps you to sell faster in a single click download.</p>
                             </div>
                             <div className={[styles['section-two-one-right'],'col-lg-6 col-12 col-sm-12 col-md-12'].join(' ')}>
                                 <div >
                                     <video className="img-fluid" autoPlay loop muted>
                                         <source src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/car+clippr1.webm"></source>
                                     </video>
                                 </div>
                             </div>
                        </div>
                    </div>
                    <div className={[styles['section-two-one'],' '].join(' ')}>
                        <div className={[styles['mob-change'],'row'].join(' ')}>
                             <div className={[styles['section-two-two-right'],'col-lg-6 col-12 col-sm-12 col-md-12'].join(' ')}>
                                <img className="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5879.png"></img>
                             </div>
                             <div className={[styles['section-two-two-left'],'col-lg-6 col-12 col-sm-12 col-md-12 bg-dan'].join(' ')}>
                                <img className={[styles['number-image'],''].join(' ')} src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/02.png"></img>
                                 <h1 >Professional quality with our <br></br><span className="text-center">Premium backgrounds</span></h1>
                                 <p >Add your company logo on our high selling  premium backgrounds.<br></br>To publish <br></br> Better photos – Reach more buyers – Sell faster</p>
                             </div>
                        </div>
                    </div>
                    <div className={[styles['section-two-one'],' '].join(' ')}>
                        <img className={[styles['number-image'],''].join(' ')} src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/03.png"></img>
                        <div className={[styles[''],'row'].join(' ')}>
                             <div className={[styles['section-two-one-left'],'col-lg-6 col-12 col-sm-12 col-md-12 col-12'].join(' ')}>
                                 <h1>Automated <span>Shadows </span></h1>
                                 <p>Clippr.ai not just replace background,it also adds up shadow to make your automobile pictures more realistic</p>
                             </div>
                             <div className={[styles['section-two-three-right'],'col-lg-6 col-12 col-sm-12 col-md-12 col-12'].join(' ')}>
                                 <div>
                                    <img className="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Webp.net-gifmaker.gif"></img>
                                 </div>
                             </div>
                        </div>
                    </div>
                    <div className={[styles['section-two-one'],''].join(' ')}>
                        <div className={[styles['mob-change'],'row'].join(' ')}>
                             <div className={[styles['section-two-two-right'],'col-lg-6 col-12 col-sm-12 col-md-12'].join(' ')}>
                                <img className="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5883.png"></img>
                             </div>
                             <div className={[styles['section-two-two-left'],'col-lg-6 col-12 col-sm-12 col-md-12 bg-dan'].join(' ')}>
                                <img className={[styles['number-image'],''].join(' ')} src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/04.png"></img>
                                 <h1 >Upload directly to car dealer <span>Marketplaces</span></h1>
                                 <p >Download professional automobile pictures & upload directly to your desired marketplace.</p>
                             </div>
                        </div>
                    </div>
                </div>
                <div className={[styles['section-3'],'row'].join(' ')}>
                    <div className={[styles[''],'col-lg-6 col-12 col-sm-12 col-md-12'].join(' ')}>
                        <TwentyTwenty
                            left={<img  className="img-fluid"  src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/removeBgBefore.png"></img>}
                            right={<img  className="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/removeBgAfter.png"></img>}
                            slider={<div className={styles['slider']}>
                                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png"></img>
                            </div>}
                        />
                    </div>
                    <div className={[styles['section-3-right'],'col-lg-6 col-12 col-sm-12 col-md-12 '].join(' ')}>
                        <div>
                            <p>Use our background removal tool for free.</p>
                            <button><a href="https://www.clippr.ai/try">Try Now</a></button>
                        </div>
                    </div>
                </div>
            </>
            :
            <CarsBackground
                fileSelectedHandler={this.fileSelectedHandler}
                orgUrl={this.state.orgUrl}
                handleId={this.handleId}
                editedUrl={this.state.url}
                handleToggle={this.handleToggle}
                toggleStatus={this.state.toggleStatus}
                handleAngle={this.handleAngle}
                imageSelected={this.state.imageSelected}
                handleClick={this.hitapi}
                isEditingFinish={this.state.isEditingFinish}
                handleDownload={this.updateCredit}
                creditUsed={this.state.creditUsed}
                availableCredit={this.state.availableCredit}
                creditAlloted={this.state.creditAlloted}
                defaultChecked={this.state.carShadow}
                planData={this.state.planData}
                spinner={this.state.spinner}
                toggleChange={this.state.toggleChange}
                background_id={this.state.background_id}
                dealerLogoFileHandler = {this.dealerLogoFileHandler}
                handleLogoPosition = {this.handleLogoPosition}
                logoUrl = {this.state.logoUrl}
                logoPosition = {this.state.logoPosition}
                hideLogo={this.state.hideLogo}
                askEmail={this.state.askEmail}
            />
            } 
            </>
        )
    }
}
