import React, { Component } from 'react'
import "react-toggle/style.css";
import Toggle from 'react-toggle'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios';
import LoginModal from '../FashionRemovalV2/LoginModal'

import Dropzone from 'react-dropzone'
// import { ProgressBar, Spinner } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { creditInfo, reduceCredit, downloadImg } from '../Common/Utils';
import { fetchCreditUserDetails, cookie, dealerLogoFileHandler } from '../Common/Utils'

import styles from '../../Css/Food/ScreenTwo.module.css'
import DownloadclipprModal from '../Common/Modal/DownloadclipprModal';
import InsufficientCreditModal from '../Common/Modal/InsufficientCreditModal';

import FeedbackModal from '../Common/Modal/feedbackModal'
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'
import marketPlace from '../FashionRemovalV2/marketPlace'
import FoodListModal from './FoodListModal'
import CreditModal from '../Common/Modal/CreditModal'
import DownloadCompleteModal from '../Common/Modal/DownloadCompleteModal'
import SelectFoodBgModal from '../../Component/Common/Modal/SelectFoodBgModal'

import posthog from 'posthog-js';
import { ThreeSixty } from '@material-ui/icons';
posthog.init('FoIzpWdbY_I9T_4jr5k4zzNuVJPcpzs_mIpO6y7581M', { api_host: 'https://app.posthog.com' });


export default class ScreenSecond extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            showModal: false,
            email: window.localStorage.getItem("clippr_user_email"),
            otpScreen: false,
            otp: '',
            verification: false,
            autoLogin: false,
            toggleStatus: false,
            toggleChange: false,
            loginStatus: false,
            otpVerification: true,
            userId: '',
            availableCredit: 0,
            creditId: "",
            loader: false,
            creditAlloted: null,
            creditUsed: null,
            spinner: false,
            emailValidation: true,
            downloadType: "",
            // rating:false
            showInsuffCredModal: false,
            downloadImage: "",
            apply: false,
            applyWay: "",
            visualSelection: "backgrounds",
            delWall: false,
            tempCustomWall: "",
            new_width: 0,
            left_wall_padding: 0,
            new_height: 0,
            tempref: true,
            selectedMarketPlaceId: "",
            showBackgroundList: false,
            step1: true,
            step2: true,
            step3: true,
            step4: true,
            bg_custom_url: 'white',
            marketPlaceList: false,
            dropBackground: false,
            creditModal: false,
            bgCredit: 1,
            clickDownload: false,
            selectBg: true,
        }
        this.wallFile = React.createRef()
        this.wallImg = React.createRef()

    }

    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]


    componentDidMount = () => {

        let userEmail = this.cookie("emailId")
        let _userId_ = this.cookie("userId")
        let auth_key = this.cookie('auth_token')
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        if (userEmail) {
            this.setState({
                loginStatus: true,
                userId: _userId_
            })
            creditInfo(auth_key).then((res) => {
                // console.log(res.data.data.credit_available)
                // console.log(this.availableCredit)
                this.setState({
                    availableCredit: res?.data?.data.credit_available,
                    creditAlloted: res?.data?.data.credit_alloted,
                    creditUsed: res?.data?.data.credit_used
                })
                // console.log(res.data.data.credit_available)


            })

        }
        else {
            this.setState({
                show: true,
                showModal: true,

            })
        }
        interact('.resizable')
            .resizable({
                edges: { top: false, left: false, bottom: true, right: false },
                listeners: {
                    move: (event) => {
                        let { x, y } = event.target.dataset

                        x = (parseFloat(x) || 0) + event.deltaRect.left
                        y = (parseFloat(y) || 0) + event.deltaRect.top

                        if (event.rect.height > this.state.container_height) {
                            // console.log(387)
                            event.rect.height = this.state.container_height
                        }
                        if (event.rect.height < 20) {
                            // console.log(387)
                            event.rect.height = 20
                        }
                        let pos = (event.rect.height / this.state.container_height) * 100
                        this.handleSetPosition(pos, event.rect.height)
                        Object.assign(event.target.style, {
                            width: `${event.rect.width}px`,
                            height: `${event.rect.height}px`,
                            transform: `translate(${x}px, ${y}px)`
                        })

                        Object.assign(event.target.dataset, { x, y })
                    }
                }
            })
    }
    handleSetPosition = (position, wall_height) => {
        this.props.handlePositionChange(position)

        this.setState({
            tempPosition: position,
            wallHeight: wall_height
        })
        // console.log("position"+ position)
    }
    handleCloseModal = () => {
        this.setState({
            show: !this.state.show,
            showModal: false
        })
    }
    // updateCredit = () => {
    //     // console.log(this.state.creditAlloted, this.state.creditUsed, this.state.availableCredit)
    //     Axios({
    //         "method":"GET",
    //         "url":"https://www.spyne.ai/credit/update-total-credit",
    //         'params' : {
    //             "userId":this.state.userId,
    //             "creditAlloted":this.state.creditAlloted,
    //             "creditUsed":this.state.creditUsed + 1,
    //             "creditAvailable":Number(this.state.availableCredit) - 1
    //         }
    //     })
    //     .then((updatedCredit) => {
    //         Axios({
    //             'method':'GET',
    //             'url':"https://www.spyne.ai/credit/user-total-credit",
    //             "params":{
    //                 'userId':this.state.userId
    //             }
    //         })
    //         .then((res) => {
    //             this.setState({
    //                 availableCredit:res.data.data.creditAvailable,
    //                 creditAlloted:res.data.data.creditAlloted,
    //                 creditUsed:res.data.data.creditUsed
    //             })
    //         })
    //     })
    // }


    handleCustomBackgroundColor = async (bg_url) => {
        this.props.handleBgColor(bg_url)
        await this.setState({
            tempCustomWall: null,
            bg_custom_url: bg_url,
        })
        this.props.customWallSet(this.state.tempCustomWall)
    }
    applyChange = (e) => {
        this.setState({
            apply: true,
            toggleStatus: true,
            toggleChange: true,
            applyWay: "",
        })
        // this.setState({
        //     apply:true,
        //     toggleStatus:true,
        //     toggleChange:true,
        //     applyWay:""
        // })
        this.props.fileUploadHandler()

    }
    handleBackgroundList = () => {
        this.setState({ showBackgroundList: true })
    }
    handleChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    downloadPopup = () => {
        this.setState({
            isDownload: false,
            apply: false
        })
        this.handleToggle()

    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.match(mailformat)) {
            Axios({
                'method': 'GET',
                "url": 'https://www.spyne.ai/credit-user/register-vendor',
                "params": {
                    'emailId': this.state.email
                }
            })

                .then((res) => {
                    this.setState({
                        otpScreen: true
                    })
                })
                .catch((err) => { })
        }
        else {
            this.setState({
                emailValidation: false
            })
        }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method': 'GET',
            "url": 'https://www.spyne.ai/credit-user/validate-otp',
            "params": {
                "emailId": this.state.email,
                'otp': this.state.otp
            }
        })
            .then((res) => {
                if (res.data.id == 200) {
                    this.setState({
                        verification: res.data
                    })

                    if (this.state.verification) {
                        posthog.people.set({ email: this.state.email, website: "clippr.ai" });
                    }
                    document.cookie = `emailId=${this.state.email}`;
                    window.location.reload(false)
                }
                else {
                    this.setState({
                        otpVerification: false
                    })
                }
            })
            .catch((err) => { })
    }
    delWallapi = () => {
        // const fd = new FormData();
        let auth_key = this.cookie('auth_token')
        let wall = this.props.wallData[this.state.wallIndex]
        let wall_id = wall["wall_id"]
        const wallfd = new FormData();
        wallfd.append("auth_key", auth_key)
        wallfd.append("wall_Id", wall_id);
        // wallfd.append("user_id",cookie("userId"))
        // wallfd.append("enterprise_id","TaD1VC1Ko")
        // wallfd.append("api_key","fde46c58-5735-4fcf-8b38-980c95001dc3")
        // let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
        Axios({
            'method': 'POST',
            "url": 'https://www.clippr.ai/api/v2/backgrounds/customWall-delete',
            "data": wallfd
        }).then((res) => {
            if (res.data.status_code == 200) {
                this.props.wallData.splice(this.state.wallIndex, 1)
            }
            this.setState({
                wallIndex: 0
            })
        })
    }
    delWallShowModal = (i) => {
        this.setState({
            delWall: true,
            wallIndex: i
        })
        // this.props.wallData.splice(i,1)
    }
    delWallModal = () => {
        this.setState({
            delWall: false
        })
        this.delWallapi()
    }
    delWallModalHide = () => {
        this.setState({
            delWall: false
        })
    }
    handleToggle = (e) => {
        if (this.state.apply == true) {
            this.setState({
                spinner: true
            })
            setTimeout(
                function () {
                    this.setState({
                        toggleChange: !this.state.toggleChange,
                        toggleStatus: !this.state.toggleStatus,
                        spinner: false
                    });
                }
                    .bind(this),
                500
            );
        }
        else {
            this.setState({
                toggleChange: false,
                toggleStatus: false,
            })
        }
    }
    // downloadImage = () => {


    //     // this.props.fileSelectedHandler(0)
    //     const link = this.props.editedImage;
    //     window.open(link, "_blank");


    //     Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedLowRes/')
    //     .then((res) => {
    //     })
    //     .catch((err) => {
    //     })


    // }
    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus: false,
            toggleChange: false,
            apply: false,
            applyWay: "",
            dropBackground: false,
            downloadType: "",
        })
        this.props.fileSelectedHandler(acceptedFiles)
        this.handleBgModal()
    }
    handleDownloadType = (DT) => {
        // console.log(DT)
        // console.log(this.props.wm_image)

        if (DT == "HD") {
            this.props.handleActivateButton(DT)
            this.setState({
                downloadType: "HD",
                downloadImage: this.props.hd_image
            })

        }
        else if (DT == "Basic") {
            this.props.handleActivateButton(DT)
            this.setState({
                downloadType: "Basic",
                downloadImage: this.props.wm_image
            })
        }

    }

    handleDownloadModal = () => {
        let userEmail = cookie("emailId")
        let userId = cookie("userId")
        // Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedHighRes/')
        // .then((res) => {
        // })
        // .catch((err) => {
        // })

        // this.props.handleDownload()
        if (userEmail && userId !== 'NA') {
            if (this.props.availableCredit <= 0 && userEmail && this.state.downloadType == "HD") {
                this.setState({ creditModal: true })
            }
            else {

                this.props.handleDownload()

            }
        }
        else {
            this.setState({
                show: false,
                showModal: false
            })
        }

        // let auth_key = this.cookie('auth_token')
        // if(this.state.availableCredit == 0 && this.state.loginStatus && this.state.downloadType==="HD"){
        //     this.setState({
        //         showInsuffCredModal:true
        //     })
        // }
        // else if(this.state.availableCredit !== 0 && this.state.loginStatus && this.state.downloadType==="HD"){
        //     reduceCredit(auth_key,1).then((updatedCredit)=>{
        //         creditInfo(auth_key).then((res)=>{
        //         this.setState({
        //             availableCredit:res.data.data.credit_available,
        //             creditAlloted:res.data.data.credit_alloted,
        //             creditUsed:res.data.data.credit_used
        //         })
        //     })
        //     this.setState({
        //         clickDownload:true
        //     })
        //     // let imagePath = this.state.downloadImage;
        //     // downloadImg(imagePath)
        //     const output_link = this.state.downloadImage;
        //     fetch(output_link, {
        //     method: "GET",
        //     headers: {}
        //     })
        //     .then(response => {
        //         response.arrayBuffer().then(function(buffer) {
        //         const url = window.URL.createObjectURL(new Blob([buffer]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         let name = output_link
        //         var index = name.lastIndexOf("/") + 1;
        //         var filename = name.substr(index)
        //         link.setAttribute("download", filename); 
        //         document.body.appendChild(link);
        //         link.click();
        //         });
        //     })
        //     // let fileName = imagePath.substring(imagePath.lastIndexOf('/') + 1)
        //     // saveAs(imagePath, fileName);
        //     // console.log(updatedCredit)
        //  })
        // }
        // else
        // {
        //     this.setState({
        //         clickDownload:true
        //     })
        //     const output_link = this.state.downloadImage;
        //     fetch(output_link, {
        //     method: "GET",
        //     headers: {}
        //     })
        //     .then(response => {
        //         response.arrayBuffer().then(function(buffer) {
        //         const url = window.URL.createObjectURL(new Blob([buffer]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         let name = output_link
        //         var index = name.lastIndexOf("/") + 1;
        //         var filename = name.substr(index)
        //         link.setAttribute("download", filename); 
        //         document.body.appendChild(link);
        //         link.click();
        //         });
        //     })
        // }
        // const output_link = this.state.downloadImage;

        // downloadImg(this.state.downloadImage)
        // const fd = new FormData()
        //                 fd.append("user_id", userId)
        //                 fd.append("sku_id",this.state.sku_id)
        //                 fd.append("enterprise_id" , 'TaD1VC1Ko')
        //                 fd.append("download_hd","true")
        //                 axios.post(`http://35.240.148.77/api/v4/update-download-status`,fd)
        //                 .then(resp => {
        //                     console.log(resp)
        //                 })
    }
    hideList = () => {
        this.setState({ showBackgroundList: false })
    }

    // handleupload=()=>{
    //     this.setState({
    //         apply:false
    //     })
    // }
    // hideDownloadModal=()=>{
    //     this.setState({
    //         clickDownload:!this.state.clickDownload
    //     })
    // }

    //redirect fucntion for when user selects to buy more credit
    redirectPricePage = () => {
        window.location.replace('https://www.spyne.ai/tech/pricing')
    }
    //open and close of credit modal when credit is 0 in users account
    handleCreditModal = () => {
        this.setState({ creditModal: false })
    }


    handleUpload = () => {
        // console.log(this.wallFile.current.files)
        this.wallfileHandler(this.wallFile.current.files)
    }
    wallfileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles[0])

        //If user is uploading image with choose Image Button
        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("image", acceptedFiles[0]);
        fd.append("optimization", false)
        Axios({
            'method': 'POST',
            "url": 'https://www.clippr.ai/api/upload',
            "params": { 'api_key': auth_key },
            "data": fd
        }).then((res) => {
            //let auth_key = this.cookie('auth_token')
            const wallfd = new FormData();
            wallfd.append("wall_name", acceptedFiles[0]["name"].split('.')[0])
            wallfd.append("wall_png_url", res.data.image)
            wallfd.append("auth_key", auth_key)
            wallfd.append("category", "cat_skJ7HIvnc")
            Axios({
                'method': 'POST',
                "url": 'https://www.clippr.ai/api/v2/backgrounds/customWall-upload',
                "data": wallfd
            }).then((wall_output) => {
                this.handleUnshiftWalls(wall_output.data)
            })
        })


    }
    handleVisualSelection = (selection) => {
        this.setState({
            visualSelection: selection,
            applyWay: "",
            step1: true
        })
        // this.props.handleVisual(selection)
        this.props.handleTabSelection(selection)
        if (selection == "custom_walls") {
            this.handleSliderwidth()
        }
    }
    handleSliderwidth = () => {
        let contW = document.getElementById("slider_div").clientWidth;
        let contH = document.getElementById("slider_div").clientHeight;
        var myImg = document.querySelector("#orgImg_2");
        var imgW = myImg.naturalWidth;
        var imgH = myImg.naturalHeight;
        var ratio_diff = contH / imgH;
        var new_imgW = imgW * ratio_diff;
        var new_imgH = imgH * ratio_diff;
        var new_wall_width = (new_imgW / contW) * 100
        new_wall_width = String(new_wall_width) + "%"
        var left_padding = String((contW - new_imgW) / 2) + "px"
        this.setState({
            new_width: new_wall_width,
            left_wall_padding: left_padding,
            container_height: contH,
            container_width: contW
        })
    }
    partialapply = (partialApplyWay) => {
        this.handleSliderwidth()


        this.setState({
            applyWay: partialApplyWay,
            // below_bg:false
        })
        if (partialApplyWay == "manually") {
            if (this.state.tempCustomWall.length > 0) {
                this.props.customWallSet(this.state.tempCustomWall)
            }
            else {
                this.props.customWallSet(this.props.wallData[0]["wall_png_url"])
            }
        }
        if (partialApplyWay == "magically") {
            this.props.handlePositionChange(0)
        }
        // console.log(this.props.wallData[0]["wall_png_url"])
    }
    handleUnshiftWalls = (data) => {
        this.props.handleUnshiftWalls(data)
        if (this.state.visualSelection == "custom_walls") {
            this.props.customWallSet(data["wall_png_url"])
        }
    }



    customWallSets = async (wall_selected,bg_id) => {
        // console.log(wall_selected)
       await this.setState({
            bg_custom_url:null,
            tempCustomWall: wall_selected,
            showBackgroundList: false,
            step1: true
        })
        // console.log(this.props.wall)
        this.props.handleBgColor(this.state.bg_custom_url)
        this.props.customWallSet(wall_selected,bg_id)
    }
    handleUploadClick = (event) => {
        event.preventDefault()
        this.wallFile.current.click()

    }
    handleMarketPlaceId = (id) => {
        this.setState({
            selectedMarketPlaceId: id,
            step2: true,
        })
        this.props.handlemarket(id)
    }
    handleMarketPlace = () => {
        this.setState({
            marketPlaceList: !this.state.marketPlaceList
        })
    }
    handleRatingModal = () => {
        // console.log("hdsfahslfbajfbl")
        this.setState({
            rating: true
        })
    }

    hideRatingModal = () => {
        this.setState({
            rating: !this.state.rating
        })
    }
    // hideInsufficientCreditModal=()=>{
    //     this.setState({
    //         showInsuffCredModal:!this.state.showInsuffCredModal
    //     })
    // }
    dragStart = (e) => {
        e.preventDefault()
        this.setState({ dropBackground: true });
    }
    dragLeave = (e) => {
        e.preventDefault()
        this.setState({ dropBackground: false });
    }

    // SelectBgModal 
    handleBgModal = (e) => {    // After Clicking cross Button on saree Modal
        this.setState(
            {
                
                selectBg: !this.state.selectBg,
            }
        )
        this.props.handelAngle(e)
    }

    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]



    render() {
        const customWallLeft = this.props.wallData.length - 11
        return (
            <>
                {
                    this.state.selectBg ?
                        <SelectFoodBgModal
                            show={this.state.selectBg}
                            handleBgModal={this.handleBgModal}
                        />
                        :
                        null
                    }
                        <Dropzone onDrop={(acceptedFiles) => this.fileHandler(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps({
                                        onClick: event => event.stopPropagation()
                                    })} className={styles["dropzone1"]} >
                                        {this.state.dropBackground ?
                                            <section className={[styles['drop-box-text']]}>
                                                <div>Drop Image Anywhere</div>

                                            </section>
                                            : null
                                        }
                                        <div className={[styles['bg-main'], ''].join(' ')}>
                                            <div className="d-flex justify-content-center">

                                            </div>
                                            <div className={[styles['upload-sec'], 'row'].join(' ')}>
                                                <div className={[styles['upload-area'], 'col-md-12 text-center'].join(' ')}>
                                                    <>
                                                        <div className={[styles['upload-top'], 'row'].join(' ')}>
                                                            <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} className={[styles[''], ''].join(' ')}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className={[styles[''], 'col-md-12'].join(' ')}>
                                                                        <button className={[styles['upload-btn'], ''].join(' ')}>
                                                                            <div {...getRootProps()} className={[styles['screen-2-drop'], ''].join(' ')}>
                                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                                                <span>Upload Image</span>

                                                                                <input onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                            </div>
                                                                        </button>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <h6 className={[styles['product-hunt-tag'], ''].join(' ')}>
                                                                <a href="https://www.producthunt.com/posts/spyneai-s-car-background-tool?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spyneai-s-car-background-tool" target="_blank">
                                                                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299488&theme=light"
                                                                        alt="SpyneAI’s Car Background tool - Boost Online Car Sales with AI-Generated Backgrounds | Product Hunt" />
                                                                </a>
                                                            </h6>
                                                        </div>
                                                        <input
                                                            style={{ display: "none" }}
                                                            type="file"
                                                            onChange={this.fileSelectedHandler}
                                                            ref={(fileInput) => (this.fileInput = fileInput)}
                                                        ></input>
                                                    </>
                                                </div>
                                                <input
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={this.fileSelectedHandler}
                                                    ref={(fileInput) => (this.fileInput = fileInput)}
                                                ></input>

                                            </div>
                                        </div>
                                        <div className={[styles['main-box'], 'row'].join(' ')}>
                                            <div className={[styles['remove-bg-area'], 'col-md-12'].join(' ')}>
                                                <div className={[styles['angle-sec']]}>
                                                    <div className="container-fluid">
                                                        <div className={[styles['top-bar'], "row"].join(" ")}>
                                                            <div className={[styles['credit-text'], "col-md-7"].join(" ")}>
                                                                <p>How to Shoot your food? <span>Click Here</span></p>
                                                            </div>
                                                            <div className={[styles['credit-text'], "col-md-5"].join(" ")}>
                                                                <div className={[styles['credit-pos'], "row"].join(" ")} >
                                                                    <div className={[styles['avail-credit'], "col-md-6"].join(" ")} >
                                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                                                        Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                                                    </div>
                                                                    <div className={[styles['right-top-credits'], "col-md-6"].join(" ")}>
                                                                        <a href="https://www.spyne.ai/tech/pricing"  >
                                                                            <button style={{ width: "max-content" }} >

                                                                                <span className={[styles['add-credit'], ""].join(" ")} > Add Credits</span> </button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={[styles['remove-bg-box'], 'row'].join(' ')}>

                                                    <div className="col-lg-6 col-12 sec-2-left-card" className={[styles['sec-2-left']]}>

                                                        <center>
                                                            <div className={[styles['containers'], ''].join(' ')}>
                                                                {
                                                                    !this.state.toggleStatus && !this.state.toggleChange ?
                                                                        <div className="row">
                                                                            <div className="col-12-12">
                                                                                <div className="left-sec-upload">
                                                                                    <div id="slider_div" style={{ position: "relative" }} className="left-sec-upload-image"  >
                                                                                        {
                                                                                            this.state.spinner ?

                                                                                                <SkeletonTheme color="#ffd5b0" >
                                                                                                    <div>
                                                                                                        <Skeleton width={420} height={400} />
                                                                                                    </div>
                                                                                                </SkeletonTheme>
                                                                                                :
                                                                                                this.state.applyWay == "manually" ? (
                                                                                                    <div className={[styles['original-image-div']]}>
                                                                                                        <img alt="" id="orgImg_2" src={this.props.originalImage} />
                                                                                                        <img alt="" className={[styles['resizable'], "resizable"].join(" ")} id="wallImg" src={this.props.customWall}
                                                                                                            style={{
                                                                                                                position: "absolute", width: this.state.new_width, left: this.state.left_wall_padding,
                                                                                                                height: this.state.new_height ? this.state.new_height : 80
                                                                                                            }}

                                                                                                        />
                                                                                                    </div>
                                                                                                ) :
                                                                                                    <img alt="" id="orgImg_2" src={this.props.originalImage} />


                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className="col-12-12">
                                                                                <div className="left-sec-upload">

                                                                                    {

                                                                                        this.state.apply ? (
                                                                                            <div className={[styles['left-sec-upload-image'], 'left-sec-upload-image bg-removed'].join(' ')}>
                                                                                                {
                                                                                                    !this.props.loader && !this.state.spinner ?
                                                                                                        <img src={this.props.editedImage} className="magnifier_zoom_bg" />
                                                                                                        :
                                                                                                        this.state.spinner ?




                                                                                                            <div className={[styles['left-sec-upload-image'], 'left-sec-upload-image bg-removed'].join(' ')}>
                                                                                                                {
                                                                                                                    !this.props.loader && !this.state.spinner ?
                                                                                                                        <img src={this.props.editedImage} className="magnifier_zoom_bg" />
                                                                                                                        :
                                                                                                                        this.state.spinner ?

                                                                                                                            <SkeletonTheme color="#ffd5b0" >
                                                                                                                                <div>
                                                                                                                                    <Skeleton width={420} height={400} />
                                                                                                                                </div>
                                                                                                                            </SkeletonTheme>

                                                                                                                            :

                                                                                                                            <SkeletonTheme color="#ffd5b0" >
                                                                                                                                <div>
                                                                                                                                    <Skeleton width={420} height={400} />
                                                                                                                                </div>
                                                                                                                            </SkeletonTheme>

                                                                                                                }
                                                                                                            </div>

                                                                                                            :
                                                                                                            <SkeletonTheme color="#ffd5b0" >
                                                                                                                <div>
                                                                                                                    <Skeleton width={420} height={400} />
                                                                                                                </div>
                                                                                                            </SkeletonTheme>


                                                                                                }
                                                                                            </div>

                                                                                        )

                                                                                            :
                                                                                            (
                                                                                                null


                                                                                            )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>

                                                            <div className="sec-2-toggle">
                                                                <p className="mr-5 mt-2">Original</p>
                                                                <label className="mt-2">
                                                                    <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                                                                </label>
                                                                <p className="ml-5 mt-2">Edited</p>
                                                            </div>

                                                        </center>
                                                    </div>


                                                    <div className={[styles['sec-2-right-card'], ""].join(" ")}>{
                                                        this.state.apply && this.state.toggleStatus ?
                                                            <div>

                                                                <div className={[styles['download-section'], ""].join(" ")}>



                                                                    <div className={[styles[''], "download-section-inner2"].join(" ")}>

                                                                        <div className={[styles['hd-download-area'], "row m-0"].join(" ")}>

                                                                            <div className={[styles['download-popup'], 'col-md-12'].join(' ')}>
                                                                                <div className={[styles['cross-btn'], ''].join(' ')}>
                                                                                    <img onClick={this.downloadPopup} className={styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png" />
                                                                                </div>
                                                                                {
                                                                                    !this.props.loader ?
                                                                                        <div className={[styles['credit-text'], ""].join(" ")} >

                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }


                                                                                {
                                                                                    this.props.loader ?
                                                                                        <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0" >
                                                                                            <div>
                                                                                                <Skeleton width="85%" height={114} style={{ marginBottom: "25px", marginTop: "50px", borderRadius: "10px", marginLeft: "7.5%" }} />
                                                                                            </div>
                                                                                        </SkeletonTheme>
                                                                                        :

                                                                                        <button onClick={() => this.handleDownloadType("HD")} style={{ border: "1px solid #0579df", boxSizing: "border-box", borderRadius: "10px", marginLeft: "7.5%" }} className={this.state.downloadType == "HD" ? [styles['download-hd'], styles['activate'], 'col-md-12 '].join(' ') : [styles['download-hd'], styles[''], 'col-md-12 '].join(' ')}>
                                                                                            <h6><input type="radio" checked={this.state.downloadType == "HD"} />HD Download</h6>
                                                                                            <ul>
                                                                                                <li>Full Image High Resolution </li>
                                                                                                <li>Without Watermark</li>
                                                                                            </ul>
                                                                                            <p>Price : <span>1 Credit/Image</span></p>
                                                                                        </button>
                                                                                }
                                                                                {
                                                                                    this.props.loader ?
                                                                                        <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0" >
                                                                                            <div>
                                                                                                <Skeleton width="85%" height={114} style={{ marginBottom: "25px", borderRadius: "10px", marginLeft: "7.5%" }} />
                                                                                            </div>
                                                                                        </SkeletonTheme>
                                                                                        :
                                                                                        <div>
                                                                                            <div className={[styles['border-1'], ''].join(' ')}></div>
                                                                                            <button onClick={() => this.handleDownloadType("Basic")} style={{ border: "1px solid #0579df", boxSizing: "border-box", borderRadius: "10px", marginLeft: "7.5%" }} className={this.state.downloadType == "Basic" ? [styles['download-hd'], styles['activate'], 'col-md-12 '].join(' ') : [styles['download-hd'], styles[''], 'col-md-12 '].join(' ')}>
                                                                                                <h6><input type="radio" checked={this.state.downloadType == "Basic"} />Basic Download</h6>
                                                                                                <ul>
                                                                                                    <li>Preview Image Basic Resolution </li>
                                                                                                    <li>With Watermark</li>
                                                                                                </ul>
                                                                                                <p>Price : <span>Free</span></p>
                                                                                            </button>
                                                                                        </div>
                                                                                }


                                                                                <div style={{ marginLeft: "7.5%" }} className={[styles['rating-area'], 'col-md-10 text-center'].join(' ')}>
                                                                                    {
                                                                                        this.props.loader ?
                                                                                            <SkeletonTheme className={styles["download-section-inner"]} color="#ffd5b0" >
                                                                                                <div>
                                                                                                    <Skeleton width="83%" height={50} style={{ marginBottom: "25px", borderRadius: "10px" }} />
                                                                                                </div>
                                                                                            </SkeletonTheme>
                                                                                            :

                                                                                            <button onClick={this.handleDownloadModal} disabled={this.state.downloadType.length > 0 ? false : true} className={[styles['download-btn-hd']]}>Download</button>

                                                                                    }



                                                                                    <CreditModal
                                                                                        showCreditModal={this.state.creditModal}
                                                                                        handleCreditModal={this.handleCreditModal}
                                                                                        redirectPricePage={this.redirectPricePage}
                                                                                        bgCredit={this.props.bgCredit}
                                                                                        editedUrl={this.props.editedImage} />

                                                                                    <DownloadCompleteModal
                                                                                        showDownloadModal={this.props.downloadComplete}
                                                                                        editedUrl={this.props.editedImage}
                                                                                        handleDownloadComplete={this.props.handleDownloadComplete}
                                                                                        orgUrl={this.props.originalImage}
                                                                                    />


                                                                                    {
                                                                                        this.props.loader ? null : (
                                                                                            <div>
                                                                                                <p>Rate this Output : <span onClick={this.handleRatingModal}>
                                                                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M19.2053 21.9028C19.0281 21.9028 18.8481 21.8521 18.6887 21.7471C16.4687 20.279 13.5306 20.279 11.3096 21.7471C10.8765 22.034 10.2962 21.915 10.0103 21.4828C9.72432 21.0506 9.84338 20.4693 10.2746 20.1834C13.1115 18.3056 16.8878 18.3075 19.7228 20.1834C20.1549 20.4693 20.2731 21.0506 19.9871 21.4828C19.8081 21.7556 19.5099 21.9028 19.2053 21.9028Z" fill="#565656" />
                                                                                                        <path d="M15 30C6.72938 30 0 23.2706 0 15C0 6.72938 6.72938 0 15 0C23.2706 0 30 6.72938 30 15C30 23.2706 23.2706 30 15 30ZM15 1.875C7.7625 1.875 1.875 7.7625 1.875 15C1.875 22.2375 7.7625 28.125 15 28.125C22.2375 28.125 28.125 22.2375 28.125 15C28.125 7.7625 22.2375 1.875 15 1.875Z" fill="#565656" />
                                                                                                        <path d="M10.7894 12.1706C11.5614 12.1706 12.1872 11.5448 12.1872 10.7728C12.1872 10.0008 11.5614 9.375 10.7894 9.375C10.0174 9.375 9.3916 10.0008 9.3916 10.7728C9.3916 11.5448 10.0174 12.1706 10.7894 12.1706Z" fill="#565656" />
                                                                                                        <path d="M19.2191 12.1791C19.9911 12.1791 20.6169 11.5533 20.6169 10.7813C20.6169 10.0093 19.9911 9.38348 19.2191 9.38348C18.4471 9.38348 17.8213 10.0093 17.8213 10.7813C17.8213 11.5533 18.4471 12.1791 19.2191 12.1791Z" fill="#565656" />
                                                                                                    </svg>

                                                                                                </span>
                                                                                                    <FeedbackModal
                                                                                                        rating={this.state.rating}
                                                                                                        hideRatingModal={this.hideRatingModal}
                                                                                                    />
                                                                                                    <span>
                                                                                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="M19.2053 18.7765C19.0281 18.7765 18.8481 18.8271 18.6887 18.9321C16.4687 20.4002 13.5306 20.4002 11.3096 18.9321C10.8765 18.6452 10.2962 18.7643 10.0103 19.1965C9.72432 19.6287 9.84338 20.2099 10.2746 20.4958C13.1115 22.3737 16.8878 22.3718 19.7228 20.4958C20.1549 20.2099 20.2731 19.6287 19.9871 19.1965C19.8081 18.9237 19.5099 18.7765 19.2053 18.7765Z" fill="#565656" />
                                                                                                            <path d="M15 30C6.72938 30 0 23.2706 0 15C0 6.72938 6.72938 0 15 0C23.2706 0 30 6.72938 30 15C30 23.2706 23.2706 30 15 30ZM15 1.875C7.7625 1.875 1.875 7.7625 1.875 15C1.875 22.2375 7.7625 28.125 15 28.125C22.2375 28.125 28.125 22.2375 28.125 15C28.125 7.7625 22.2375 1.875 15 1.875Z" fill="#565656" />
                                                                                                            <path d="M10.7894 12.1706C11.5614 12.1706 12.1872 11.5448 12.1872 10.7728C12.1872 10.0008 11.5614 9.375 10.7894 9.375C10.0174 9.375 9.3916 10.0008 9.3916 10.7728C9.3916 11.5448 10.0174 12.1706 10.7894 12.1706Z" fill="#565656" />
                                                                                                            <path d="M19.2191 12.1791C19.9911 12.1791 20.6169 11.5533 20.6169 10.7813C20.6169 10.0093 19.9911 9.38348 19.2191 9.38348C18.4471 9.38348 17.8213 10.0093 17.8213 10.7813C17.8213 11.5533 18.4471 12.1791 19.2191 12.1791Z" fill="#565656" />
                                                                                                        </svg>

                                                                                                    </span>
                                                                                                </p>

                                                                                                <p className={[styles['get-mrktplace'], ''].join(' ')}> Get Marketplace ready images
                                                                                                    <a style={{ color: '#ff7700', textDecoration: 'none' }} href="https://www.clippr.ai/contact-form/" target="_blank">
                                                                                                        Click Here </a>
                                                                                                </p>
                                                                                            </div>)
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    {/* </SkeletonTheme> */}

                                                                    {/* </div> */}

                                                                    {/* </div> */}


                                                                    {/* </SkeletonTheme> */}

                                                                </div>
                                                            </div>
                                                            :
                                                            <div>{!this.state.marketPlaceList ?

                                                                <div className={[styles['right-top-selection']]}>
                                                                    {/* <div className={this.state.visualSelection=="custom_walls"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}} onClick={(e)=>this.handleVisualSelection('custom_walls')} >Custom Walls </div> */}
                                                                    {/* <div className={this.state.visualSelection=="backgrounds"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}} onClick={(e)=>this.handleVisualSelection('backgrounds')} >
                        <h6><span className={[styles['colored']]}>Solid Color</span> Backgrounds</h6>
                         </div> */}



                                                                    <div>
                                                                        <div className={[styles['custom-walls-area'], ''].join(' ')}>
                                                                            <div >
                                                                                {/* <div className={this.props.customWall.length>0||this.state.bg_custom_url!=''?[styles['custom-wall'],''].join(' '):[styles['adjust-height'],''].join(' ')}>
                                <p><span>1</span>{this.state.visualSelection=="custom_walls"? "Choose a Wall  ":"Select a Background"}</p>
                              </div> */}
                                                                                <div className={[styles['side-margin'], styles[''], ''].join(' ')}>

                                                                                    <div >{this.state.visualSelection == "custom_walls" ?
                                                                                        <div className={[styles['background-list'], styles['custom-background-list'], ''].join(' ')}>

                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <div className={styles['active-visual-selection']} onClick={(e) => this.handleVisualSelection('backgrounds')} >
                                                                                                <h6><span className={[styles['colored']]}>Solid Color</span> Backgrounds</h6>
                                                                                            </div>
                                                                                            <div className={styles['solid-background']}>
                                                                                                <div className={styles['bg-fashion']} onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/whitefood.png")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/whitefood.png' ? { border: "1px solid #FF7700", cursor: "pointer", borderRadius: "5px" } : { cursor: 'pointer' }}>
                                                                                                    <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/white-bg.png" />
                                                                                                    <p>White <span>1  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png" /></span></p>
                                                                                                </div>
                                                                                                <div className={styles['bg-fashion']} onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/yellowfood.png")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/yellowfood.png' ? { border: "1px solid #FF7700", cursor: 'pointer', borderRadius: "5px" } : { cursor: 'pointer' }}>
                                                                                                    <img src="https://storage.googleapis.com/spyne-website/Food-Try/yellow.png" />
                                                                                                    <p>Yellow <span>1  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png" /></span></p>
                                                                                                </div>
                                                                                                <div className={styles['bg-fashion']} onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/skyfood.png")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/skyfood.png' ? { border: "1px solid #FF7700", cursor: 'pointer', borderRadius: "5px" } : { cursor: 'pointer' }}>
                                                                                                    <img src="https://storage.googleapis.com/spyne-website/Food-Try/sky.png" />
                                                                                                    <p>Sky <span>1  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png" /></span></p>
                                                                                                </div>
                                                                                                <div className={styles['bg-fashion']} onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/brownfood.png")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/brownfood.png' ? { border: "1px solid #FF7700", cursor: 'pointer', borderRadius: "5px" } : { cursor: 'pointer' }}>
                                                                                                    <img src="https://storage.googleapis.com/spyne-website/Food-Try/pink.png" />
                                                                                                    <p>Brown <span>1  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png" /></span></p>
                                                                                                </div>
                                                                                                {/* <div className={styles['bg-fashion']} onClick={() => this.handleCustomBackgroundColor("DCDCDC")} style={this.state.bg_custom_url == 'DCDCDC' ? {border:"1px solid #FF7700", cursor:'pointer', borderRadius: "5px"}:{cursor:'pointer'}}>
                                            <img className="mt-2" src="https://storage.googleapis.com/spyne-website/Food-Try/more.png"  />
                                           
                                        </div> */}

                                                                                            </div>

                                                                                            <div className={styles['active-visual-selection']} onClick={(e) => this.handleVisualSelection('backgrounds')} >
                                                                                                <h6><span className={[styles['colored']]}>Custom </span> Backgrounds</h6>
                                                                                            </div>
                                                                                            <div className={styles['solid-background']}>
                                                                                                
                                                                                                {

                                                                                                    this.props.wallData.slice(0,11).map((e, i) => {

                                                                                                        return (
                                                                                                            
                                                                                                            <div className={this.props.customWall == e["background_image_url"] ? styles['active-walls-sec'] : styles['inactive-walls-sec']} onClick={() => this.customWallSets(e["background_image_url"],e["old_bg_id"])} >
                                                                                                                <img src={e["low_res_bg_image_url"]} ></img>
                                                                                                                <span>{e["background_name"].substring(0, 10)}</span>
                                                                                                            </div>
                                                                                                            
                                                                                                        )

                                                                                                    })
                                                                                    }{customWallLeft > 0 ? (
                                                                                        <div className={[styles['inactive-background-sec'], styles['more-box'], ''].join(' ')} onClick={this.handleBackgroundList}>
                                                                                            <div className={styles['overlay-pos']}>
                                                                                                <img alt="" src="https://storage.googleapis.com/spyne-website/try-categories/morre.png" />
                                                                                                <div className={styles['img-overlay']}></div>
                                                                                                <div className={styles['more-btn-overlay-2']}>+ {customWallLeft} <span>More</span></div>
                                                                                            </div>
                                                                                        </div>) : null}
                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    }</div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="slider_div" className={styles['original_image']} >
                                                                        {/* {
                
                        this.state.applyWay=="manually" ?(
                        <div className={[styles['original-image-div']]}>
                        <img alt="" id="orgImg_2" src={this.props.originalImage}   />
                        <img alt="" className={[styles['resizable'],"resizable"].join(" ")} id="wallImg" src={this.props.customWall} 
                        style={{position:"absolute", width:this.state.new_width, left:this.state.left_wall_padding,
                        height:this.state.new_height?this.state.new_height:80  }}
                        />
                    </div>
                    ):
                        <img alt="" id="orgImg_2" src={this.props.originalImage}  />
                        }    */}
                                                                    </div>

                                                                    <div style={{ textAlign: "center" }} className={styles['btn-sec']}>
                                                                        <button onClick={this.state.step2 == true ? this.applyChange : null}>Apply</button>
                                                                    </div>
                                                                    <FoodListModal
                                                                        handleBackground={this.handleBackground}
                                                                        delWallShowModal={this.delWallShowModal}
                                                                        background_id={this.props.background_id}
                                                                        planData={this.props.planData} hideList={this.hideList}
                                                                        showBackgroundList={this.state.showBackgroundList}
                                                                        orgUrl={this.props.orgUrl}
                                                                        visualSelection={this.state.visualSelection}
                                                                        floor={this.props.floor}
                                                                        customWallSets={this.customWallSets}
                                                                        customWallSet={this.props.customWallSet}
                                                                        wallFile={this.wallFile}
                                                                        handleUploadClick={this.handleUploadClick}
                                                                        handleUpload={this.handleUpload}
                                                                        handleUnshiftWalls={this.props.handleUnshiftWalls}
                                                                        wallData={this.props.wallData}
                                                                        bg_list={this.props.bg_list}
                                                                        fav_list={this.props.customWallLeft}
                                                                        handleCarsFavList={this.handleCarsFavList}
                                                                        handleCarsBgList={this.handleCarsBgList}
                                                                        applyChange={this.applyChange} />
                                                                </div>
                                                                :
                                                                <div>
                                                                    <p>Select Marketplace</p>
                                                                    <div className={[styles['marketplaces'], ''].join(' ')} >
                                                                        {
                                                                            marketPlace.map((e, i) => {
                                                                                return (
                                                                                    <div key={i} style={this.state.selectedMarketPlaceId == e.name && this.state.step1 == true ? { border: "1px solid #FF7700" } : null} >
                                                                                        <img src={e.img} onClick={this.state.step1 == true ? () => this.handleMarketPlaceId(e.name) : null} style={{ height: "50px", width: '50px' }}></img>
                                                                                    </div>
                                                                                )
                                                                            })


                                                                        }
                                                                    </div>
                                                                    <button onClick={this.handleMarketPlace}> select</button>
                                                                </div>
                                                            }</div>
                                                    }</div>

                                                </div>

                                            </div>
                                        </div>

                                        {
                                            this.state.showModal ?
                                                <LoginModal
                                                    show={this.handleModal}
                                                    sendOTP={this.sendOTP}
                                                    verifyOTP={this.verifyOTP}
                                                    otpScreen={this.state.otpScreen}
                                                    verification={this.state.verification}
                                                    handleChange={this.handleChange}
                                                    otpVerification={this.state.otpVerification}
                                                    emailValidation={this.state.emailValidation}
                                                    onHide={this.handleCloseModal}
                                                    defaultEmail={this.state.email}
                                                />
                                                :
                                                null
                                        }

                                        <input onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        <Modal
                                            show={this.state.delWall}
                                            dialogClassName="modal-90w"
                                            aria-labelledby="example-custom-modal-styling-title"
                                            size='lg'
                                            centered
                                            onHide={() => this.setState({ delWall: !this.state.delWall })}
                                        >
                                            <Modal.Header />
                                            <Modal.Body>
                                                <center className={[styles['car-text']]}>
                                                    <h1>Are you sure you want to Delete this <span style={{ color: "#FF7700" }}>CustomWall</span></h1>
                                                </center>

                                                <center>
                                                    <button onClick={this.delWallModal} className={styles['got-btn']}>Yes</button>
                                                    <button onClick={this.delWallModalHide} className={styles['got-btn']}>No</button>
                                                </center>

                                            </Modal.Body>
                                        </Modal>
                                        {
                                            this.state.showModal ?
                                                <LoginModal
                                                    show={this.handleModal}
                                                    sendOTP={this.sendOTP}
                                                    verifyOTP={this.verifyOTP}
                                                    otpScreen={this.state.otpScreen}
                                                    verification={this.state.verification}
                                                    handleChange={this.handleChange}
                                                    otpVerification={this.state.otpVerification}
                                                    emailValidation={this.state.emailValidation}
                                                    onHide={this.handleCloseModal}
                                                    defaultEmail={this.state.email}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                
            </>
        )
    }
}

