import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

import styles from '../../Css/CarsBackgroundReplacement.module.css'
import '../../App.css'
import Styles from '../../Css/RealEstate/SkyReplacement.module.css'
import {fetchCreditUserDetails, updateUserCredit,cookie, uploadImageTOS3 ,skyReplacement} from '../Common/Utils'
import SkyUseCases from './SkyUseCases'
export default class SkyLanding extends Component {
    constructor(props){
        super(props)
        this.state={
            selectedFile: null,
            url:  '' , // url of the image returned by the api 
            orgUrl: '',
            object_id:0,
            background_id:0,
            uploadPercentage: 0,
            uploading: false,
            orgImgShow: true,
            loader: false,
            uploadBox: true,
            editedState: false,
            tabIndex: 0,
            isError: false,
            processing: false,
            editedImagesArr: [],
            editing: false,
            spinner:false,
            selectedAngle:'',
            imageSelected:cookie('selectedAngle')? true :false,
            firstUpload:true,
            marketplaceData:[],
            availableCredit:5,
            alottedCredit: 0,
            creditUsed:0,
            isEditingFinish:false,
            toggleChange:false,
            bgCredit:0,
            loginStatus:false,
        }
    }
    componentDidMount = () => {
        axios({
            'method':'GET',
            'url':'https://www.clippr.ai/api/fetch-marketplace',
            'headers':{
                'category':'sky'
            }
        })
        .then((res) => {
            this.setState({
                marketplaceData:res.data
            })
        })
        let userId = cookie('userId')
        fetchCreditUserDetails(userId).then((resp) => {
            this.setState({
                availableCredit:resp.data.data.creditAvailable,
                allotedCredit:resp.data.data.creditAlloted,
                creditUsed:resp.data.data.creditUsed
            })
        } )
    }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
            );
            // console.log("this.state.orgUrl",this.state.orgUrl)
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
    };
    handleId=(id,price)=>{
        this.setState({
          background_id:id,
          bgCredit:price,
          isEditingFinish:false
        })
    }
    handleToggle = (e) => {
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }
    updateCredit = () => {
        let userId = cookie('userId')
        let price = this.state.bgCredit
        updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
        .then((res) => {
            this.setState({
                availableCredit: this.state.availableCredit - price
            })
            const link = this.state.url;
            window.open(link, "_blank");
        })
    }
    hitapi = () => {
        this.setState({
            spinner:true
        })
        // if(this.state.selectedFile !== null){
            const fd = new FormData();
            fd.append("image",this.state.selectedFile);
            fd.append("optimization", false)
            uploadImageTOS3(fd)
            .then((uploadResponse) => {
                const shoeData = new FormData()
                shoeData.append("image_url", uploadResponse.data.image)
                shoeData.append("image_id", this.state.background_id)
                skyReplacement(shoeData)
                .then((res) => {
                    this.setState({
                        url:res.data.url,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true
                    })
                    // window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch(err => {
                this.setState({
                    spinner:false
                })
            })
        // }
        // else{
        //     const shoeData = new FormData()
        //     shoeData.append("iamge_url",this.state.orgUrl)
        //     if(this.state.background_id == "mark_dje41p" || this.state.background_id == "mark_SB367w" || this.state.background_id == "mark_15X1HN"){
        //         shadowOverFootwear(shoeData)
        //         .then((res) => {
        //             this.setState({
        //                 url:res.data.url,
        //                 orgImgShow: true,
        //                 processing: false,
        //                 loader: false,
        //                 editedState: true,
        //                 uploadPercentage: 0,
        //                 tabIndex: 1,
        //                 spinner:false,
        //                 toggleStatus:true,
        //                 toggleChange:true,
        //                 isEditingFinish:true
        //             })
        //             // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
        //             // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
        //         })
        //         .catch((err) => {
        //             this.setState({
        //                 spinner:false
        //             })
        //         })
        //     }
        //     else{
        //         backgroundRemoval(shoeData)
        //         .then((res) => {
        //             this.setState({
        //                 url:res.data.url,
        //                 orgImgShow: true,
        //                 processing: false,
        //                 loader: false,
        //                 editedState: true,
        //                 uploadPercentage: 0,
        //                 tabIndex: 1,
        //                 spinner:false,
        //                 toggleStatus:true,
        //                 toggleChange:true,
        //                 isEditingFinish:true
        //             })
        //             // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
        //             // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
        //         })
        //         .catch((err) => {
        //             this.setState({
        //                 spinner:false
        //             })
        //         })
        //     }
        // }

    }
    render() {
        return (
            <div>
                <section >
                    <div className={Styles['sky-header-section']}>
                        <h1>Get Marketplace Ready Images</h1>
                        <p> in a <span>Single Click</span> using AI</p>
                    </div>
                    {
                    this.state.orgUrl == '' ?
                    <div className={[styles['car-main-wrapper'],"container"].join(" ")}>
                        <div className={[styles['wrapper'],''].join(' ')}>
                                <div className={[styles['left-section'],''].join(' ')}>
                                    <div className={[styles['mid-section'],''].join(' ')}>
                                        <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)}>
                                            {({getRootProps, getInputProps}) => (
                                                <section className={[styles[''],''].join(' ')}>
                                                <div {...getRootProps()} className={styles['dropzone']}>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                    <p className={styles["para-1"]}>Drag Image Here</p>
                                                    <p class={styles["para-2"]}>or <span>browse</span> for Image</p>
                                                    <p className={styles['supports']}>Support JPG/JPEG file</p>
                                                    <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <div className={styles['choose-button']}>
                                            <input 
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={this.fileSelectedHandler}
                                            ref={(fileInput) => (this.fileInput = fileInput)}
                                            ></input>
                                            <button  onClick={() => this.fileInput.click()} className="btn">Choose Image</button>
                                        </div>
                                    </div>
                                </div>
                            <div className={[styles['right-section'],''].join(' ')}>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group%205911.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    // <SkyBackground
                    //     editedUrl={this.state.url}
                    //     spinner={this.state.spinner}
                    //     orgUrl={this.state.orgUrl}
                    //     toggleStatus={this.state.toggleStatus}
                    //     handleClick={this.hitapi}
                    //     handleToggle={this.handleToggle}
                    //     marketplaceData={this.state.marketplaceData}
                    //     fileSelectedHandler={this.fileSelectedHandler}
                    //     handleId={this.handleId}
                    //     isEditingFinish={this.state.isEditingFinish}
                    //     background_id={this.state.background_id}
                    //     toggleChange={this.state.toggleChange}
                    //     handleDownload={this.updateCredit}
                    //     creditUsed={this.state.creditUsed}
                    //     availableCredit={this.state.availableCredit}
                    //     creditAlloted={this.state.creditAlloted}
                    //     handleDownload={this.updateCredit}
                    // />

                    <SkyUseCases
                        originalImage={this.state.orgUrl}
                        selectedFile={this.state.selectedFile}
                    />

                    }
            </section>        
        </div>
        )
    }
}
