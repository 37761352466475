import React, { useContext, useState, useEffect } from 'react'
import cls from './LoginPage.module.css'
import axios from 'axios'
import { AuthContext } from '../../context/GlobalState'
import { Link, useHistory } from 'react-router-dom'
import styles from './ForgetPassword.module.css'
const SuccessfullyChanged = () => {

	const [emailId, setEmailId] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [loginType, setLoginType] = useState('PASSWORD')
	const [otpSent, setOtpSent] = useState(false)
	const emailInputRef = React.createRef()
	const passwordInputRef = React.createRef()
	const context = useContext(AuthContext)
	const history = useHistory()

	useEffect(() => {
		if (context.auth.loggedIn) {
			history.push('/tech/cars/try')
		}
	})
    
	const toggleLoginType = () => {
		setPassword('')
		passwordInputRef.current.value = ''
		let toggle = loginType === 'PASSWORD' ? 'OTP' : 'PASSWORD'
		setLoginType(toggle)
		setOtpSent(false)
	}

	const requestOtp = (e) => {
		e.preventDefault()
		const data = new FormData()
		data.append('api_key', process.env.REACT_APP_API_KEY)
		data.append('email_id', emailId)
		axios({
			url: "https://www.clippr.ai/api/v2/user/request-otp",
			method: 'POST',
			data: data
		})
		.then((success) => {
			if (success.data?.status === 200)
				setOtpSent(true)
		})
		.catch((err) => setError(true))

}

	const handleLogin = (e) => {
		e.preventDefault()
		const fd = new FormData()
		fd.append('api_key', process.env.REACT_APP_API_KEY)
		
		if(loginType === "PASSWORD"){
				fd.append('strategy', 'PASSWORD')
				fd.append('email_id', emailId)
				fd.append('password', password)
				axios({
						method: "POST",
						url: "https://www.clippr.ai/api/v2/user/login",
						data : fd
				})
				.then(handleLoginResponse)
				.catch((err) => {
					setError(true)
				})
		}else{
				fd.append('email_id', emailId)
				fd.append('otp', password)
				fd.append('signup_url', window.location.href)
				fd.append('source', 'Web')
				axios({
						url: 'https://www.clippr.ai/api/v2/user/validate-otp',
						method: 'POST',
						data: fd
				})
				.then(handleLoginResponse)
				.catch((err) => {
					setError(true)
				})
		}
	}

	const handleLoginResponse = (resp) => {
		if(resp?.data?.status == 200){
				document.cookie = `emailId=${emailId}; path=/`;
				document.cookie = `userId=${resp.data.user_id}; path=/`;
				document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
				context.loggedIn({
						loggedIn: true,
						user: {
								emailId,
								userId: resp.data.user_id
						},
						token: resp.data.auth_token,
						apiKey: process.env.REACT_APP_API_KEY
				})
				history.replace('/try-categories')
		}else if(resp?.data?.status != 200){
				setError(true)
				setErrorMsg(resp.data.message)
		}
	}


	return (
		<div className={[cls["font-family"],styles['forget-ps'],''].join(' ')}>
			<div className="container-fluid">
				<div className="row">
					<div className={["col", cls["top-container"]].join(' ')}>
						<p className={cls["back"]} onClick={() => history.goBack()}>
							<span> &larr; </span>
							Back
						</p>
						
					</div>
				</div>
			</div>

			<div className={[styles[''],'container'].join(' ')}>
				<div className={[styles[''],'row'].join(' ')}>
					<div className={[styles['forget-pass'],'col-lg-4 offset-lg-4 mt-3 mb-5'].join(' ')}>
						<div className={[styles['forget-pass-box'],'row'].join(' ')}>
							<div className="col-12 text-center p-0">
								<img className={["img-fluid", cls["logo"],styles['logo'],''].join(' ')} src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png" />
								<h4 className={cls["success"]}>New Password sent successfully!</h4>
								<p>Please check your email.</p>
							</div>
							<form>
								<p>Click here to go to the Login screen</p>
								<Link to="/login"><button className={[cls['submit-btn']]}>Go to LogIn</button></Link>
							</form>
						</div>
					</div>
				</div>
			</div>
			
		
		
		
		
		
		
		</div>
	)
}

export default SuccessfullyChanged