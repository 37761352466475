import React, { Component } from "react";
import axios from 'axios'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Spinner from "../Spinner";
import styles from '../Affiliate/AffiliateForm.module.css'



export default class AffiliateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignupCard: false,
            firstName: '',
            lastName: '',
            email: '',
            businessName: '',
            designation: '',
            country: '',
            isdCode: '+91',
            phoneNumber: '',
            category: '',
            expectedCeditVolumne: '',
            isLoading: false,
        }
    }

    handleBack = () => {
        window.location.replace(`https://www.spyne.ai/affiliate`)
    }

    setFirstName = (e) => {
        this.setState({
            firstName: e.target.value
        })
    }

    setLastName = (e) => {
        this.setState({
            lastName: e.target.value
        })
    }

    setEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    setBusinessName = (e) => {
        this.setState({
            businessName: e.target.value
        })
    }

    setDesignation = (e) => {
        this.setState({
            designation: e.target.value
        })
    }

    setCountry = (e) => {
        this.setState({
            country: e.target.value
        })
    }

    setISDCode = (e) => {
        this.setState({
            isdCode: e.target.value
        })
    }

    setPhoneNumber = (e) => {
        this.setState({
            phoneNumber: e.target.value
        })
    }

    setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
    }

    setVolume = (e) => {
        this.setState({
            expectedCeditVolumne: e.target.value
        })
    }

    isValidPhoneNumber = () => {
        const phoneNumber = this.state.phoneNumber
        const isdCode = this.state.isdCode
        const regex = /^\d{10}$/;
        const isdCodeRegex = /^(\+?\d{1,3})?$/; // +91, +1, 91 
        return isdCodeRegex.test(isdCode) && regex.test(phoneNumber)
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: !this.state.isLoading
        })
        // validate phone number
        if (this.isValidPhoneNumber()) {
            // get form data and make a post request to the server
            const fd = new FormData();
            fd.append('first_name', this.state.firstName);
            fd.append('last_name', this.state.lastName);
            fd.append('email_id', this.state.email);
            fd.append('business_name', this.state.businessName);
            fd.append('designation', this.state.designation);
            fd.append('country', this.state.country);
            fd.append('isd_code', this.state.isdCode);
            fd.append('phone_number', this.state.phoneNumber);
            fd.append('category', this.state.category);
            fd.append('expected_credit_volume', this.state.expectedCeditVolumne);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/v2/affiliate/register`,
                data: fd,
            }).then(res => {
                if(res.data.status === 200) {
                    this.setState({
                        showSignupCard: true,
                        isLoading: !this.state.isLoading
                    })
                } else {
                    this.setState({
                        isLoading: !this.state.isLoading
                    }, () => {
                        if(res.data.message.includes('email')) {
                            alertify.alert('Email already exists', 'Please enter a different email address.')
                        } else {
                            alertify.error('Something went wrong. Please try again.')
                        }
                    })
                }
            }).catch(err => {
                this.setState({
                    isLoading: !this.state.isLoading
                }, () => alertify.error(err))
            })
        } else {
            this.setState({
                isLoading: !this.state.isLoading
            }, () => alertify.alert('Invalid phone number', 'phone number must be 10 digits.'))
        }
    }

render() {
    return (
            <div>
              
            <section className={[styles['affiliate-form'],''].join(' ')}>
                    <div className={[styles[''], 'container'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            {!this.state.showSignupCard ?
                            <div className={[styles['heading'], 'col-md-8 offset-md-2'].join(' ')}>
                                <form onSubmit={this.onFormSubmit}>
                                    <div className={[styles['heading'], 'col-md-12'].join(' ')}>
                                        <p onClick={this.handleBack} className={[styles['back']]}>&#8249;</p>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/images/spyne-black-logo.png" width="140px" height="auto"/>
                                        <h2>Welcome to Spyne Affiliate program!</h2>
                                    </div>
                                        <div className={[styles['form-area'],'col-md-12'].join(' ')}>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>First name<sup>*</sup></span>
                                                <input 
                                                    type="text" 
                                                    placeholder="First name" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.firstName}
                                                    onChange={this.setFirstName}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Last name<sup>*</sup></span>
                                                <input 
                                                    type="text"
                                                    placeholder="Last name" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.lastName}
                                                    onChange={this.setLastName}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Email ID<sup>*</sup></span>
                                                <input 
                                                    type="email" 
                                                    placeholder="Email ID" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.email}
                                                    onChange={this.setEmail}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Busines Name<sup>*</sup></span>
                                                <input 
                                                    type="text" 
                                                    placeholder="Busines Name"
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.businessName}
                                                    onChange={this.setBusinessName}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Designation<sup>*</sup></span>
                                                <input 
                                                    type="text" 
                                                    placeholder="Designation" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.designation}
                                                    onChange={this.setDesignation}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Country<sup>*</sup></span>
                                                <input 
                                                    type="text" 
                                                    placeholder="Country" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.country}
                                                    onChange={this.setCountry}
                                                />
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Phone Number<sup>*</sup></span>
                                            <div className="input-group-prepend">
                                            
                                                <input 
                                                    type="text"
                                                    class={[styles[''],'form-control col-md-3'].join(' ')}
                                                    defaultValue={this.state.isdCode}
                                                    onChange={this.setIsdCode} 
                                                />
                                                <input 
                                                    type="number" 
                                                    placeholder="987654321" 
                                                    className={[styles[''],'form-control'].join(' ')} 
                                                    required
                                                    defaultValue={this.state.phoneNumber}
                                                    onChange={this.setPhoneNumber}
                                                />
                                            </div>
                                            </label>
                                           <label className={[styles[''],'form-group'].join(' ')}><span>Category</span>
                                                <select onChange={this.setCategory}  className={[styles[''],'form-control'].join(' ')}>
                                                    <option selected={this.state.category === ''}>Your Category</option>
                                                    <option value='Automobile' selected={this.state.category === 'Automobile'}>Automobile</option>
                                                    <option value='Fashion' selected={this.state.category === 'Fashion'}>Fashion</option>
                                                    <option value='Footwear' selected={this.state.category === 'Footwear'}>Footwear</option>
                                                    <option value='Product' selected={this.state.category === 'Product'}>Product</option> 
                                                </select>
                                            </label>
                                            <label className={[styles[''],'form-group'].join(' ')}><span>Expected Credit Volume</span>
                                                <input 
                                                type="text" 
                                                placeholder="Volume" 
                                                className={[styles[''],'form-control'].join(' ')}
                                                defaultValue={this.state.expectedCreditVolume}
                                                onChange={this.setVolume}
                                                />
                                            </label>

                                            <div className={[styles['form-area'],'row m-0'].join(' ')}>
                                            <button className={[styles['submit']]}>Submit</button>
                                            </div>
                                        </div>
                                    
                                </form>
                            </div>
                            :
                            <div className={[styles['heading'], 'col-md-8 offset-md-2'].join(' ')}>
                                <div className={[styles['form'],'row m-0'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Affiliate-page/thanks.png"/>
                                    <h2><span>Thanks! You’re all signed up...</span></h2>
                                    <button onClick={this.handleBack} className={[styles['submit']]}><span>&#8592;</span> &nbsp; Back Home</button>
                                    <div className={[styles['bottom-pitch'],'col-md-12'].join(' ')}>
                                        <h6>Explore more</h6>
                                        <ul>
                                            <li><a href="spyne.ai/tech/cars/try">spyne.ai/tech/cars/try</a></li>
                                            <li><a href="">spyne.ai/tech/cars/try</a></li>
                                            <li><a href="">spyne.ai/tech/cars/try</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </section>
            {/* //Affiliate Form */}


            </div>
            
        )
    }
};










