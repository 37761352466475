import React, { useEffect } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
  { id: 'name', label: 'Project Name', minWidth: 170 },
  { id: 'skuName', label: 'SKU Name', minWidth: 170 },
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 170,
  },
  {
    id: 'inputUrl',
    label: 'Input Image URL',
    minWidth: 170,
  },
  {
    id: 'OutputUrl',
    label: 'Output Image URL',
    minWidth: 170,
  },
  {
    id: 'imageStatus',
    label: 'Image Status',
    minWidth: 170,
  },
  {
    id: 'bgName',
    label: 'BG Name',
    minWidth: 170,
  },
  {
    id: 'bgId',
    label: 'BG ID',
    minWidth: 170,
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 170,
    align: 'left'
  },
  
  { id: 'download', label: 'Download', minWidth: 100,align: 'center' },
];

function createData(name,skuName,email,inputUrl,OutputUrl,imageStatus,bgName,bgId,source,download) {
  
  return {name,skuName,email,inputUrl,OutputUrl,imageStatus,bgName,bgId,source,download};
}



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F9FAFC',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: 'linear-gradient(180deg, #FF8314 0%, #CDA989 100%);',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows] = React.useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() =>{
    const newRows= props.imageList.map((image) =>{
      return createData(image.project_name,image.sku_name,image.email_id,image.input_image_hres_url,image.output_image_hres_url,image.status,image.background_name,image.background_id,image.source,image.output_image_hres_url)
    })
    setRows(newRows)
  },[props.imageList])


  function downloadImage(download_url){
    const output_link = download_url;
      fetch(output_link, {
      method: "GET",
      headers: {}
      })
      .then(response => {
          response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          let name = output_link
          var index = name.lastIndexOf("/") + 1;
          var filename = name.substr(index)
          link.setAttribute("download", filename); 
          document.body.appendChild(link);
          link.click();
          
          });
          
      })
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <StyledTableRow  role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if(column.id == 'download'){
                      return(
                        <TableCell key={column.id} align={column.align}>
                          <img alt="" style={{cursor:"pointer"}} onClick={() => downloadImage(value)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/arrow-down-circle.png"/>
                        </TableCell>
                      )
                    }else{
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100,1000]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
