import React, { Component } from 'react'

import styles from '../../Css/Model/ModelUseCases.module.css'

import BackgroundRemobval from './BackgroundRemoval'
import BackgroundRetouch from './BackgroundRetouch'
import Beautification from './Beautification'
import FaceRetouch from './FaceRetouch'
import ImageTagging from './ImagTagging'
import ScalingMargins from './ScalindMargins'

export default class ModelUseCases extends Component {
    constructor(props){
        super(props)
        this.state={
            useCaseId:'5',
            originalImage:this.props.originalImage
        }
    }
    handleUseCaseId = (e) => {
        e.preventDefault()
        this.setState({
            useCaseId:e.target.id
        })
    }
    render() {
        return (
            <div>
                <div className={[styles['tab-section'],''].join(' ')}>
                    <button id="5" onClick={this.handleUseCaseId} className={this.state.useCaseId == "5" ? styles['active-usecase-button']: null}>Scaling & Margins</button>
                    <button id="1" onClick={this.handleUseCaseId} className={this.state.useCaseId == "1" ? styles['active-usecase-button']: null}>Remove Background</button>
                    <button id="6" onClick={this.handleUseCaseId} className={this.state.useCaseId == "6" ? styles['active-usecase-button']: null}>Beautification</button>
                    {/* <button id={styles['disabled-button']}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/face%20retouch.png"></img></button> */}
                    <button id={styles['disabled-button']}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/image%20tagging.png"></img></button>
                    <button id={styles['disabled-button']}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/background%20retouch.png"></img></button>
                    {/* <button id="2" onClick={this.handleUseCaseId} className={this.state.useCaseId == "2" ? styles['active-usecase-button']: null}>Background Retouch</button>
                    <button id="3" onClick={this.handleUseCaseId} className={this.state.useCaseId == "3" ? styles['active-usecase-button']: null}>Face Retouch</button>
                    <button id="4" onClick={this.handleUseCaseId} className={this.state.useCaseId == "4" ? styles['active-usecase-button']: null}>Image Tagging</button> */}
                </div>
                {
                    this.state.useCaseId == '1' ?
                    <BackgroundRemobval
                        originalImage={this.props.originalImage}
                        selectedFile={this.props.selectedFile}
                    />
                    :
                    this.state.useCaseId == '2' ?
                    <BackgroundRetouch
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '3' ?
                    <FaceRetouch
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '4' ?
                    <ImageTagging
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '5' ?
                    <ScalingMargins
                        originalImage={this.props.originalImage}
                        selectedFile={this.props.selectedFile}
                    />
                    :
                    this.state.useCaseId == '6' ?
                    <Beautification
                        originalImage={this.props.originalImage} 
                    />
                    :
                    null
                }
            </div>
        )
    }
}
