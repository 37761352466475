
import React, { Component } from 'react'
import styles from '../../Css/CarsBackgroundReplacement.module.css'
// import '../../App.css'
import Styles from '../../Css/RemoveForeground/RemoveForeground.module.css'
// import RemoveForeground from './RemoveForeground';
 

export default class RemoveForeground extends Component {
    render() {
        return (
            <div>
                <section className={[Styles['remove-foreground'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>  
                        <div className={[Styles[''],'row'].join(' ')}>
                            <h1 className={styles['header-section']}>
                                <p className={styles['heading-text']}>Get Marketplace Ready Images</p>
                                <p className={styles['heading-para']}> in a <span>Single Click</span> using AI</p>
                            </h1>
                            <div className={[styles['car-main-wrapper'],"col-md-12"].join(" ")}>
                                <div className={[styles['wrapper'],'row'].join(' ')}> 
                                    <div className={[styles['left-section'],'col-md-5'].join(' ')}>
                                        <div className={[styles['mid-section'],'mt-5'].join(' ')}>
                                            <div className={styles['dropzone']}>
                                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                    <p className={Styles["p-1"]}>Drag Image Here</p>
                                                    <p class={Styles["p-2"]}>or <span>browse</span> for Image</p>
                                                    <p className={styles['supports']}>Support JPG/JPEG file</p>
                                                    <input  type="file" />
                                            </div>
                                            <div className={[Styles['choose-button'],'text-center'].join(' ')}>
                                                <input style={{ display: "none" }} type="file" />
                                                <button  className="btn">Try Now</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={[styles['right-section'],'col-md-7'].join(' ')}>
                                        <div className={[styles[' '],'row'].join(' ')}>
                                            <img src="https://storage.googleapis.com/clippr-image-processing/Static/Group%205925.png"></img>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


 