import React, { Component } from "react";
import useState from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import categoryData from "./static";
import axios from "axios";
import "../../App.css";
import ScreenTwo from "./Screen2";
import Dropzone from "react-dropzone";
import { Form, ProgressBar, Spinner } from "react-bootstrap";
// import styles from '../../Css/CarsBackgroundReplacement.module.css'
// import LandingModal from '../Common/Modal/LandingModal'
import queryString from 'query-string'
import loadImage from "blueimp-load-image";

import {fetchCreditUserDetails, updateUserCredit,cookie,poll, chunkArray} from '../Common/Utils'

import styles from "../../Css/BackgroundRemoval/bgRemoval.module.css";
import LandingModal from "../Common/Modal/LandingModal";
import imgData from "../Common/Static/clipper";
import cls from '../../Css/RemoveBg/ScreenTwo.module.css'
import { ReactCompareSlider, ReactCompareSliderImage,ReactCompareSliderHandle } from 'react-compare-slider'
import fs from 'fs'
import { copyFileSync } from "fs";

class ScreenOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      selectedFile: null,
      url: "",
      orgUrl: "",
      switchScreen: false,
      imageCategory: "",
      loader: false,
      imageWidth: "",
      imageHeight: "",
      productHunt: 'clippr',
      showLoginModal:true,
      auth_key:'',
      emailId:'',
      availableCredit:0,
      dropBackground: false,
      creditAlloted:0,
      creditUsed:0,
      wm_image:"",
      hd_image:"",
      source:this.props.match.params

    };
  }
  cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

  fileUploadHandler = (resolution) => {
    
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          uploadPercentage: percentCompleted,
        });
        if (percentCompleted === 100) {
          this.setState({
            uploading: false,
            processing: true,
          });
        }
      }.bind(this),
    };
    
    try {
      this.setState({
        switchScreen: true
      })
      var data = new FormData();
      data.append('image_file', this.state.selectedFile);
      var config_ph = {
        method: 'post',
        url: 'https://www.clippr.ai/api/ph',
        headers: { 
        },
        data : data
      };
      axios(config_ph)
        .then((res) => {
          this.setState({
            url: res.data.display_image,
            wm_image:res.data.watermark_image,
            hd_image:res.data.output_image
            
          });

          try {
            localStorage.setItem("urlbg", res.data.display_image);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            editedImages.push(res.data.display_image);
            localStorage.setItem(
              "editedImagesBgr",
              editedImages.toString()
            );
          } catch (e) {
            if (e == "QUOTA_EXCEEDED_ERR") {
            }
          }
          this.setState({
            orgImgShow: true,
            processing: false,
            loader: false,
            editedState: true,
            uploadPercentage: 0,
            tabIndex: 1,
          });
        });
    } catch (e) {
      this.setState({
        isError: true,
        uploading: false,
        processing: false,
        loader: false,
        tabIndex: 0,
      });
    }
  };

  fileSelectedHandler = (event) => {
    // console.log(event)
    this.setState({
      loader: true,
    });
    if (event.target) {
      var file = event.target.files[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event.target.files[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
        },
        () => {
          this.fileUploadHandler(1);
        }
      );
    } else {
      let file = event[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
          
        },
        () => {
          this.fileUploadHandler();
        }
      );
    }
  };

  componentDidMount = async () => {
    let queries = queryString.parse(this.props.location.search)
    
    // if (!queries.source || queries.source!="in"){
    //   this.props.history.push('?source=demo')
    // }
    // else if(queries.source="in"){
    //     this.props.history.push('?source=in')
    // }
   

      // this.props.history.push('?source=pr')
      
      // console.log(this.props)
        let auth_key = this.cookie('auth_token')
        this.setState({
            auth_key: auth_key
        })

        // let queries = queryString.parse(this.props.location.search)
        // console.log(queries)
        // this.setState({productHunt : this.state.productHunt})
        // console.log(this.state.productHunt)
          

        var passing_location;
        

        passing_location = 'NULL'
        localStorage.setItem("passing_location", passing_location);
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        this.setState({
            emailId : userEmail
        })
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            this.setState({
                showInstruction:true
            })
        }
        


        let userId = this.cookie('userId')
        if(userId !== "NA"){
            // fetchCreditUserDetails(userId).then((resp) => {
            //     this.setState({
            //         availableCredit:resp.data.data.creditAvailable,
            //         allotedCredit:resp.data.data.creditAlloted,
            //         creditUsed:resp.data.data.creditUsed
            //     })
            // } )

            let emailId = this.cookie("emailId")
            let auth_key = this.cookie("auth_token")
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v2/user/get",
                params : {
                    email_id : emailId,
                    auth_key : auth_key
                }
                
            }).then(resp => {
                if(resp.data.status == 200){
                    this.setState({
                        availableCredit: resp.data.data.credit_available,
                        creditAlloted : resp.data.data.credit_allotted,
                        creditUsed: resp.data.data.credit_used
                    })
                }else{
                    // console.log(resp)
                }
            })
                this.setState({
                    userId: userId
                })
        }
        if(this.state.uploadStarteds === "true"){
            // this.getProcessedImages()
            this.getUserCredit()
        }
        
    }

  cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

  hideLandingModal = () => {
    this.setState({showLoginModal:!this.state.showLoginModal})
  }
  
  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }
  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }
  
  render() {
    return !this.state.switchScreen ? (
      <>
      <div>
      {/* {
        this.state.showLoginModal?
        <LandingModal
            showInstruction={this.state.showLoginModal}
            hideModal = {this.hideLandingModal}
            productHunt={this.state.productHunt}
        />
        :null
      } */}
        
        <Dropzone
          onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
          onDragEnter={this.dragStart}
          onDragLeave={this.dragLeave}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onClick: (event) => event.stopPropagation(),
              })}
              className={styles["dropzone1"]}
            >
              {this.state.dropBackground ? (
                <section className={[styles["drop-box-text"]]}>
                  <div>Drop Image Anywhere</div>
                </section>
              ) : null}
              <div className={styles["header-section"]}>
                <p className={styles["heading-text"]}>
                  Remove Image Background Instantly
                </p>
                <p className={styles["heading-para"]}>
                  Boost Online E-Commerce sales in a single click with AI{" "}
                  {/* <span>AI-Generated</span> Backgrounds */}
                </p>
              </div>
              <div
                className={[styles["car-main-wrapper"], ""].join(" ")}
                id="cars-try-modal"
              >
                <div className={[styles[""], "container"].join(" ")}>
                  <div className={[styles["car-try-wrapper"], "row"].join(" ")}>
                    <div
                      className={[
                        styles["car-left-section"],
                        "col-md-10 offset-md-1",
                      ].join(" ")}
                    >
                      <div
                        className={[styles["car-mid-section"], ""].join(" ")}
                      >
                        {/* <button id="upload_widget" className={[styles['dropzone'],"cloudinary-button"].join(' ')} onClick={() => myWidget.open()} >Upload files</button> */}
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.fileSelectedHandler(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className={[
                                styles["try-drag-drop"],
                                "d-md-block d-none",
                              ].join(" ")}
                            >
                              <div
                                {...getRootProps({})}
                                className={styles["dropzone1"]}
                              >
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                <p className={styles["para-11"]}>
                                  DROP IMAGE HERE{" "}
                                </p>
                                <p class={styles["para-2"]}>
                                  or <span>SELECT IMAGE ON YOUR COMPUTER</span>{" "}
                                </p>
                                <p className={styles["supports1"]}>
                                  Supports jpeg, jpg, bmp & png
                                </p>
                                <input
                                  onChange={this.fileSelectedHandler}
                                  ref={(fileInput) =>
                                    (this.fileInput = fileInput)
                                  }
                                  type="file"
                                  {...getInputProps()}
                                />
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <input
                onChange={this.fileSelectedHandler}
                ref={(fileInput) => (this.fileInput = fileInput)}
                type="file"
                {...getInputProps()}
              />
            </div>
          )}
        </Dropzone>
      </div>
      <div className="container" style={{maxWidth:"90%", marginBottom:'3rem' }}>
      <div className={[cls['categories'],'row'].join(' ') } >
         <h2><span style={{fontSize:"1.3em"}}>Our Work</span></h2>
         <div className="folder" style={{display:'flex', justifyContent:"space-evenly",padding:"1em 2em" , borderRadius:"0.3em", margin:"auto"}}>
          
            {imgData.map((ImageInfo)=>{
               return(
                <div style={{width:"40%", height:"15em", backgroundColor:"white"}}>
                <ReactCompareSlider
                handle={<ReactCompareSliderHandle style={{height:"82%"}}/>}
                itemOne={<ReactCompareSliderImage src={ImageInfo.img1}  alt="one"/>}
                itemTwo={<ReactCompareSliderImage src={ImageInfo.img2} alt="two"/>}
                onPositionChange={this.handleSetPosition}
                onlyHandleDraggable
                position={this.state.tempPosition==0?30:this.state.tempPosition}
                style={{objectPosition : "center center",width: this.state.new_width?this.state.new_width:"100%",left:this.state.left_wall_padding}}
                portrait={false}
                /> 
                </div>
                )
            })}
          
         </div>
      </div>
      </div>
      </>
    ) : (
      <ScreenTwo
        originalImage={this.state.orgUrl}
        editedImage={this.state.url}
        hd_image={this.state.hd_image}
        wm_image={this.state.wm_image}
        fileSelectedHandler={this.fileSelectedHandler}
        loader={this.state.loader}
        
        // photoresp={this.cookie('image_src')}
      />
    );
  }
}
export default withRouter(ScreenOne)