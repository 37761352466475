import React, { Component } from 'react'
import "react-toggle/style.css";
import Toggle from 'react-toggle'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios';
import Jimp from 'jimp'
import { MagnifierContainer, MagnifierPreview, MagnifierZoom, MOUSE_ACTIVATION, TOUCH_ACTIVATION,} from "react-image-magnifiers";
import { ProgressBar, Spinner } from "react-bootstrap";
import LoginModal from './LoginModal'
import {Link} from 'react-router-dom'
import Dropzone from 'react-dropzone'
// import { ProgressBar, Spinner } from "react-bootstrap";

import posthog from 'posthog-js';
posthog.init('FoIzpWdbY_I9T_4jr5k4zzNuVJPcpzs_mIpO6y7581M',{api_host:'https://app.posthog.com'});


export default class ScreenTwo extends Component {
    
    constructor(props){
        super(props)
        this.state={
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            toggleChange:true,
            loginStatus:false,
            otpVerification:true,
            userId:'',
            availableCredit:null,
            creditId:"",
            loader:false,
            creditAlloted:null,
            creditUsed:null,
            spinner:false,
            emailValidation:true,
        }
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    componentDidMount = () => {

      let userEmail = this.cookie("emailId")
      let _userId_ = this.cookie("userId")
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
      if(userEmail){
          this.setState({
              loginStatus:true,
              userId:_userId_
          })
          
            Axios({
                'method':'GET',
                'url':"https://www.spyne.ai/credit/user-total-credit",
                "params":{
                    'userId':_userId_
                }
            })

            .then((res) => {
                if(res.data.data.creditAvailable == 0 && res.data.data.creditAlloted == 0 && res.data.data.creditUsed == 0) {
                    Axios({
                        "method":"GET",
                        "url":"https://www.spyne.ai/credit/insert-user",
                        'params':{
                            "userId":_userId_,
                            "creditId":'cred_123jk33',
                            "creditAlloted":5,
                            "creditUsed":0,
                            "creditAvailable":5
                        }
                    })
                    .then(() => {
                        Axios({
                            "method":"GET",
                            "url":"https://www.spyne.ai/credit/insert-total-credit",
                            'params':{
                                "userId":_userId_,
                                "creditAlloted":5,
                                "creditUsed":0,
                                "creditAvailable":5
                            }
                        })
                        .then(() =>{
                            this.setState({
                                availableCredit:5,
                                creditAlloted:5,
                                creditUsed:0
                            })
                        })
                    })
                   
                }
                this.setState({
                    availableCredit:res.data.data.creditAvailable,
                    creditId:res.data.data.creditId,
                    creditAlloted:res.data.data.creditAlloted,
                    creditUsed:res.data.data.creditUsed
                })
            })
            .catch((err) => {
                
            })
      }
      else{
      }
    }
    handleModal = () => {
        if(this.state.availableCredit == 0 && this.state.loginStatus){
            window.location.replace("https://www.clippr.ai/try/pricing")
        }
        else if(this.state.loginStatus){
            const link = this.props.editedImage;
            window.open(link, "_blank");

        Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedHighRes/')
        .then((res) => {
        })
        .catch((err) => {
        })


            this.updateCredit()
        }
        else{
            this.setState({
                show:true,
                showModal:true
            })
        }
    }
    handleCloseModal = () => {
        this.setState({
            show:!this.state.show,
            showModal:false
        })
    }
    updateCredit = () => {
        // console.log(this.state.creditAlloted, this.state.creditUsed, this.state.availableCredit)
        Axios({
            "method":"GET",
            "url":"https://www.spyne.ai/credit/update-total-credit",
            'params' : {
                "userId":this.state.userId,
                "creditAlloted":this.state.creditAlloted,
                "creditUsed":this.state.creditUsed + 1,
                "creditAvailable":Number(this.state.availableCredit) - 1
            }
        })
        .then((updatedCredit) => {
            Axios({
                'method':'GET',
                'url':"https://www.spyne.ai/credit/user-total-credit",
                "params":{
                    'userId':this.state.userId
                }
            })
            .then((res) => {
                this.setState({
                    availableCredit:res.data.data.creditAvailable,
                    creditAlloted:res.data.data.creditAlloted,
                    creditUsed:res.data.data.creditUsed
                })
            })
        })
    }
    handleChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat))
        {
            Axios({
                'method':'GET',
                "url":'https://www.spyne.ai/credit-user/register-vendor',
                "params":{
                    'emailId':this.state.email
                }
            })
            
            .then((res) => {
               this.setState({
                   otpScreen:true
               })
            })
            .catch((err) => {})
        }
        else
        {
            this.setState({
                emailValidation:false
            })
        }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":this.state.email,
                'otp':this.state.otp
            }
        })
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                verification:res.data
                })
                
            if(this.state.verification){
                posthog.people.set({email: this.state.email,website: "clippr.ai"});
            }
            document.cookie = `emailId=${this.state.email}`;
            window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
        .catch((err) => {})
    }
    handleToggle = (e) => {
        this.setState({
            spinner:true
        })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    spinner:false
                });
            }
            .bind(this),
            500
        );
    }
    downloadImage = () => {


        // this.props.fileSelectedHandler(0)
        const link = this.props.editedImage;
        window.open(link, "_blank");

        
        Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedLowRes/')
        .then((res) => {
        })
        .catch((err) => {
        })


    }
    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus:true
        })
        this.props.fileSelectedHandler(acceptedFiles)
    } 

    render() {
        return (
            <>
            <div className="d-flex justify-content-center" style={{paddingTop:"100px"}}>
                {/* <div className="text-center screen-2-top">
                    <>
                        <div className="d-flex justify-content-center">
                            {
                                !this.props.loader ? 
                            <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg"></img>
                                    <p className="para-1">Upload Files Here</p>
                                    <p class="para-2"><b>or</b> Browse For Image</p>
                                    <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                </div>
                                </section>
                            )}
                            </Dropzone>
                            :
                            <Spinner
                            style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                width: '80px',
                                height: '80px',
                            }}
                            animation="border"
                            variant="primary"
                          />
                            }
                        </div>
                        <input 
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.fileSelectedHandler}
                            ref={(fileInput) => (this.fileInput = fileInput)}
                        ></input>
                    </>
                </div> */}
            </div>
            <div className="row p-5 pt-0">
                <div className="col-lg-6 col-12 sec-2-left-card">
                    <center>
                        <div className="containers">
                            {
                                !this.state.toggleStatus && !this.state.toggleChange?
                                <div className="row">
                                    <div className="col-12-12">
                                        <div className="left-sec-upload">
                                            <div className="left-sec-upload-image">
                                                {/* <img imageSrc={this.props.originalImage}/> */}
                                                {
                                                    this.state.spinner ? 
                                                    <Spinner
                                                        className="loader-edited-screen-2"
                                                        animation="grow"
                                                        variant="primary"
                                                    />
                                                    :
                                                    <img src={this.props.originalImage} className="magnifier_zoom"/>
                                                }
                                                {/* <img imageSrc={this.props.originalImage} className="magnifier_zoom"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                   <div className="col-12-12">
                                        <div className="left-sec-upload">
                                            <div className="left-sec-upload-image bg-removed">
                                                {/* <img imageSrc={this.props.editedImage}/> */}
                                                {
                                                    !this.props.loader && !this.state.spinner ? 
                                                    <img src={this.props.editedImage} className="magnifier_zoom_bg"/>
                                                    :
                                                    this.state.spinner ?
                                                    <Spinner
                                                        className="loader-edited-screen-2"
                                                        animation="grow"
                                                        variant="primary"
                                                    />
                                                    :
                                                    <Spinner
                                                        className="loader-edited-screen-2"
                                                        animation="border"
                                                        variant="primary"
                                                    />
                                                 }
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            }
                        </div>
                        <div className="sec-2-toggle">
                            <p className="mr-5 mt-2">Original</p>
                            <label className="mt-2">
                                <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                            </label>
                            <p className="ml-5 mt-2">Edited</p>
                        </div>
                    </center>
                </div>
                <div className="col-lg-5 col-12 ml-5 sec-2-right-card">
                <div className="text-center screen-2-top">
                    <>
                        <div className="d-flex justify-content-center screen-2-upload">
                            <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()} className="screen-2-drop">
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                    <p className="para-1">Upload Files Here</p>
                                    <p class="para-2"><b>or</b> Browse For Image</p>
                                    <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                </div>
                                </section>
                            )}
                            </Dropzone>
                        </div>
                        <input 
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.fileSelectedHandler}
                            ref={(fileInput) => (this.fileInput = fileInput)}
                        ></input>
                    </>
                </div>
                    {/* <div className="category-sec">
                        <div className="category-container">
                        <div className="category">{this.props.imageCategory}</div>
                            <div className="category-header">Category Identified</div>
                        </div>
                    </div> */}
                    <div className="download-section">
                        <div className="download-section-inner">
                            {/* <p>Download HD</p> */}
                            <button className="btn btn-primary mb-3" onClick={this.handleModal}>Download HD</button>
                            <p>(Resolution {this.props.imageWidth} * {this.props.imageHeight})</p>
                            <p><a style={{fontSize:"15px"}} href="/try/pricing">{this.state.availableCredit > 0 ? "See our pricing plans" : "Buy Credits"}</a></p>
                            <p className="my-1">Or</p>
                            <p><span onClick={this.downloadImage}>Download</span> low resolution for free</p>
                            <p>(Resolution 100 x 100)</p>
                            <p>Credits remaining {this.state.availableCredit !== null ? this.state.availableCredit: 5}</p>
                        </div>
                    </div>
                    <p className="text-center mt-3">For Ecommerce Optimise Images <a style={{color:'#0579DF',textDecoration:'none'}} href="https://www.clippr.ai/contact-form/" target="_blank">Click Here</a></p>
                </div>
            </div>
            {
                this.state.showModal ? 
                <LoginModal 
                    show={this.handleModal}  
                    sendOTP={this.sendOTP}  
                    verifyOTP={this.verifyOTP}
                    otpScreen={this.state.otpScreen}
                    verification={this.state.verification}
                    handleChange={this.handleChange}
                    otpVerification={this.state.otpVerification}
                    emailValidation={this.state.emailValidation}
                    onHide={this.handleCloseModal}
                    defaultEmail = {this.state.email}
                />
                :
                null
            }
            </>
        )
    }
}

