import React, { Component } from 'react'
import Routes from './Routes/Route'
import { ContextProvider } from './context/GlobalState'
import "lightgallery.js/dist/css/lightgallery.css";
require('dotenv').config()



export default class App extends Component {
  componentDidMount = () => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
  render() {
    return (
      <>
        <ContextProvider>
          <Routes/>
        </ContextProvider>
     </>
    )
  }
}
