import React, { Component } from 'react'


import Styles from '../../Css/Landing/LandingScreenTwo.module.css'
// import LandingScreenTwo from './LandingScreenTwo';


export default class LandingScreenTwo extends Component {
    render() {
        return (
            <div>
                <section className={[Styles['landing-screen-two'],' '].join(' ')}>
                    <div className={[Styles[' '],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles[' '],'col-md-12'].join(' ')}>
                                <div className={[Styles['screen-two-area'],'row'].join(' ')}>
                                    <h1>Select an image to remove background</h1>
                                    <p>Drag and drop a file here, or select a file from your device.</p>
                                    <div>
                                        <button className={[Styles['mybtn'],'btn text-center'].join(' ')}>Choose file</button>
                                        <p><span>Fully automated through AI</span></p>
                                    </div>
                                    <ul>
                                        <li><img src="https://spyne.s3.ap-south-1.amazonaws.com/clippr/static/Group+5743.png"/></li>
                                        <li><img src="https://spyne.s3.ap-south-1.amazonaws.com/clippr/static/Group+5930.png"/></li>
                                        <li><img src="https://spyne.s3.ap-south-1.amazonaws.com/clippr/static/Group+5931.png"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}
