import React, { Component } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import Axios from 'axios'
import Dropzone from 'react-dropzone'

import '../../Css/cars.css'
import styles from '../../Css/CarsScreenTwo.module.css'
import footwearOnlyStyles from '../../Css/FootwearScreenTwo.module.css'
import marginStyles from '../../Css/Model/ScalingMargins.module.css'
import Styles from '../../Css/RealEstate/SkyReplacement.module.css'

import { ProgressBar, Spinner } from "react-bootstrap";
import LoginModal from '../BackgroundRemoval/LoginModal'
import {cookie,sendOTP,verifyOTP} from '../Common/Utils'
export default class SkyReplacement extends Component {
    constructor(props){
        super(props)
        this.state={
            backgroundsData:[],
            availableCredit:5,
            angle:this.props.selectedAngle,
            angleSelected:this.props.imageSelected,
            loginStatus:false,
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            toggleChange:true,
            loginStatus:false,
            otpVerification:true,
            userId:'',
            availableCredit:null,
            creditId:"",
            loader:false,
            creditAlloted:null,
            creditUsed:null,
            spinner:false,
            emailValidation:true,
            url:this.props.originalImage ? this.props.originalImage : '', 
            selectedFile:this.props.selectedFile, 
            orgUrl:'',
            marketplaceData:[],
            selectedCategory:'topwear',
            selectedMarketPlaceId:'71',
            top:'',
            bottom:'',
            right:'',
            left:'',
            fetchingMarketplaces:true,
            processing:false
        }
    }
    handleModal = () => {
        let userEmail = cookie("emailId")
          if(userEmail){
            if(this.props.availableCredit <= 0 && userEmail){
              window.location.replace("https://www.clippr.ai/try/pricing")
            }
            else{
              this.props.handleDownload()
            }
          }
          else{
            this.setState({
              show:true,
              showModal:true
          })
          }
    }
    handleCloseModal = () => {
        this.setState({
            show:!this.state.show,
            showModal:false
        })
    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat))
        {
           sendOTP(email)
            .then((res) => {
               this.setState({
                   otpScreen:true
               })
            })
        }
        else
        {
            this.setState({
                emailValidation:false
            })
        }
       
    }
    verifyOTP = (e) => {
        e.preventDefault()
        verifyOTP(this.state.email,this.state.otp)
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                    verification:res.data
                })
                document.cookie = `emailId=${this.state.email}; path=/`;
                window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
    }
    handleChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            processing:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
            );
            // console.log("this.state.orgUrl",this.state.orgUrl)
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
    }

      componentDidMount = () => {
        console.log(process.env.BaseURL)
        Axios({
            'methods':"GET",
            'url':`${process.env.REACT_APP_BASEURL}/fetch-marketplace`,
            'headers':{
                'category':'scaling&margins'
            }
        })
        .then((res) => {
            this.setState({
                marketplaceData:res.data,
                fetchingMarketplaces:false
            })
        })
    }
    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    handleCategory = (e) => {
        e.preventDefault()
        this.setState({
            selectedCategory:e.target.id,
            processing:false
        })
    }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            processing:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
            );
            // console.log("this.state.orgUrl",this.state.orgUrl)
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
    };
  
    download = (e) => {
        e.preventDefault()
        const link = this.state.url;
        window.open(link, "_blank");
    }
    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    // spinner:false
                });
            }
            .bind(this),
            500
        );
    }
    render() {
        
        const {processing} = this.state
        return (
            <div>
                <section className={[Styles['real-estate'],''].join(' ')}>
                    <div className={[Styles[' '],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>

                            <div className={[styles[''],'col-md-7'].join(' ')}>
                                <div className={[Styles['real-estate-image-area'],'row'].join(' ')}>
                                    <div className={[styles['sky-image-inner'],''].join(' ')}>
                                        {
                                                this.state.loader ? 
                                                <Spinner
                                                    className="loader-edited"
                                                    style={{
                                                        marginTop: "0px",
                                                        marginBottom: "0px",
                                                    }}
                                                    animation="border"
                                                    variant="primary"
                                                />
                                                :
                                                <div className={[styles['display-image'],' mt-0'].join(' ')} style={{width:"100%"}}>
                                                    {
                                                        this.state.toggleStatus ?
                                                        <img src={this.state.url} className="img-fluid"></img>
                                                        :
                                                        <img src={this.state.orgUrl !== '' ? this.state.orgUrl : this.props.originalImage} className="img-fluid"></img>
                                                    }
                                                </div>
                                            }
                                    </div>
                                    <div className={[styles['toggle-section'], "sec-2-toggle"].join(' ')}>
                                        <p className="mr-5 mt-2">Original</p>
                                        <label className="mt-2">
                                            <Toggle onChange={this.props.handleToggle} icons={false} checked={this.props.toggleStatus} />
                                        </label>
                                        <p className="ml-5 mt-2">Edited</p>
                                    </div>
                                </div>
                            </div>
                            <div className={[styles[' '],'col-md-4 offset-md-1'].join(' ')}>
                                <div className={[Styles['real-estate-process'],'row'].join(' ')}>
                                    <div className={[Styles[' '],'col-md-12 p-0'].join(' ')}>
                                        <p>Upload Image  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Vector%20(1).png" /></p>
                                        <div className={[footwearOnlyStyles['upload-section'],"d-flex justify-content-center"].join(' ')}>
                                            <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                    <div {...getRootProps()} className={footwearOnlyStyles["screen-2-drop"]}>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                        <p className="para-1">Upload Files Here</p>
                                                        <p class="para-2"><b>or</b> Browse For Image</p>
                                                        <input  onChange={this.props.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                </section>
                                            )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                     
                                    <div className={[styles['right-bottom-section'],'row'].join(' ')}> 
                                     
                                        {/* <div className={[styles['bottom-apply-area'],'col-md-12 text-center'].join(' ')}>
                                            {
                                            this.props.isEditingFinish ?
                                            <>
                                                <button onClick={this.handleModal}  className="btn">Download HD</button>
                                                <p>Resolution(1200 X 900)</p>
                                            </>
                                            :
                                            <button onClick={this.props.handleClick}  className="btn">Apply Now</button>
                                            }
                                        </div> */}
                                        <div className={[marginStyles['processing'],''].join(' ')}>
                                                {
                                                    !processing ? 
                                                    <button onClick={this.handelProcess}>Process</button>
                                                    :
                                                    <button onClick={this.download}>Download</button>
                                                }
                                            </div>
                                    </div>
                                </div>
                            </div>
                            {/* <a href="https://www.clippr.ai/contact-form" target="_blank" className={[styles['bulk-pricing'],' '].join(' ')}>For Bulk Processing <span>Click Here</span></a> */}
                            {
                            this.state.showModal ? 
                            <LoginModal
                                show={this.handleModal}  
                                sendOTP={this.sendOTP}  
                                verifyOTP={this.verifyOTP}
                                otpScreen={this.state.otpScreen}
                                verification={this.state.verification}
                                handleChange={this.handleChange}
                                otpVerification={this.state.otpVerification}
                                emailValidation={this.state.emailValidation}
                                onHide={this.handleCloseModal}
                                defaultEmail = {this.state.email}
                                cars={true}
                                footwear={true}
                                sky={true}
                            />
                            :
                            null
                            }
                    </div>
                </div>
            </section>
        </div>
        )
    }
}
