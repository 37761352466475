import React, { Component } from 'react'

import styles from '../../Css/Model/BackgroundRemoval.module.css'
import marginStyles from '../../Css/Model/ScalingMargins.module.css'
import {backgroundRemoval,uploadImageTOS3, shadowOverFootwear,imageCropping} from '../Common/Utils'
import {backgroundReplacementData,bottomWear} from '../Common/ScalingMarginsData'

import Dropzone from 'react-dropzone'
import {Spinner} from "react-bootstrap";
import Toggle from 'react-toggle'
import Axios from 'axios'

export default class BackgroundReplacement extends Component {
    constructor(props){
        super(props)
        this.state={
            url:'',
            loader:false,
            selectedFile:this.props.selectedFile,
            toggleStatus:false,
            toggleChange:false,
            orgUrl:'',
            marketplaceData:[],
            selectedMarketPlaceId:'71',
            top:'',
            bottom:'',
            right:'',
            left:'',
            fetchingMarketplaces:true,
            processing:false,
            bg_custom_url:''
        }
    }
    componentDidMount = () => {
        console.log(process.env.BaseURL)
        Axios({
            'methods':"GET",
            'url':`${process.env.REACT_APP_BASEURL}/fetch-marketplace`,
            'headers':{
                'category':'footwear'
            }
        })
        .then((res) => {
            this.setState({
                marketplaceData:res.data,
                fetchingMarketplaces:false
            })
        })
    }
    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    handleMarketPlaceId = (id) => {
        this.setState({
            selectedMarketPlaceId:id,
            processing:false
        })
        
    }
    handleProcess = () => {
        this.setState({
            loader:!this.state.loader
        })
        let file = this.state.selectedFile
        const fd = new FormData()
        fd.append("image",file);
        fd.append("optimization", false)
        uploadImageTOS3(fd)
        .then((uploadResponse) => {
            const shoeFd = new FormData()
            shoeFd.append("image_url",uploadResponse.data.image)
            shoeFd.append("bg_color", this.state.bg_custom_url)
            shoeFd.append('marketplace_id',this.state.selectedMarketPlaceId)
            shadowOverFootwear(shoeFd)
            .then((shoeres) => {
                console.log(shoeres)
                this.setState({
                    url:shoeres.data.after,
                    orgImgShow: true,
                    loader: false,
                    editedState: true,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    processing:true,
                })
                
            })
        })
        .catch((err) => {
            this.setState({
                loader:false,
                processing:false
            })
        })
    }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            processing:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
            );
            // console.log("this.state.orgUrl",this.state.orgUrl)
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
    };
    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    // spinner:false
                });
            }
            .bind(this),
            500
        );
    }
    download = (e) => {
        e.preventDefault()
        const link = this.state.url;
        window.open(link, "_blank");
    }
    handleCustomBackgroundColor = (bg_url) => {
        this.setState({
            bg_custom_url:bg_url,
            processing:false
        })
    }
    render() {
        const {marketplaceData,selectedMarketPlaceId,fetchingMarketplaces,processing} = this.state
        const data = backgroundReplacementData[0]
        return (
            <div>
                <div className={[styles['wrapper'],'row mt-5'].join(' ')}>
                    <div className={[styles['left-box'],'col-lg-7 col-md-7 col-sm-12 col-12'].join(' ')} >
                        {
                            this.state.loader ? 
                            <Spinner
                                className="loader-edited"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                                animation="border"
                                variant="primary"
                            />
                            :
                            <div className={styles['display-image']}>
                                {
                                    this.state.toggleStatus ?
                                    <img src={this.state.url} className="img-fluid" style={{border:'1px solid grey'}}></img>
                                    :
                                    <img src={this.state.orgUrl !== '' ? this.state.orgUrl : this.props.originalImage} className="img-fluid" style={{border:'1px solid grey'}}></img>
                                }
                            </div>
                        }
                        <div className="sec-2-toggle">
                            <p className="mr-5 mt-2">Original</p>
                            <label className="mt-2">
                                <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                            </label>
                            <p className="ml-5 mt-2">Edited</p>
                        </div>
                    </div>
                    <div className={[styles['right-box'],'col-12 col-sm-12 offset-1 col-lg-4 col-md-4'].join(' ')} >
                        <p>Upload Image</p>
                        <div>
                            <div className={[marginStyles['upload-section'],"d-flex justify-content-center"].join(' ')} style={{margin:"0px", maxWidth:"100%"}}>
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} className={[styles["screen-2-drop"],'container-fluid'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                            <p className={[marginStyles["para-1"],''].join(' ')}>Drag Image Here</p>
                                            <p className={[marginStyles["para-2"],''].join(' ')}>or browse for image</p>
                                            <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <hr></hr>
                            <div>
                                <p>Marketplace</p>
                                <div className={[marginStyles['marketplaces'],''].join(' ')} id={marginStyles['grow-spinner']}>
                                    {   
                                        !fetchingMarketplaces ?
                                        marketplaceData.map((e,i) => {
                                            return(
                                                <div key={i} onClick={() => this.handleMarketPlaceId(e.market_id)} style={selectedMarketPlaceId == e.market_id ? {border:"1px solid #FF7700"}:null }>
                                                    <img src={e.image_url} style={{height:"50px", width:'50px'}}></img>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={[marginStyles[""],'spinner-grow align-center'].join(' ')}  role="status" style={{color:"#FF7700"}}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr></hr>
                            <div className='d-flex'>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/white%20bg.png" height='50px' width='50px' onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne/AI/raw/e904fad2-727e-467a-aef5-89b3e1f06c99.jpg")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne/AI/raw/e904fad2-727e-467a-aef5-89b3e1f06c99.jpg' ? {border:"1px solid #FF7700", cursor:"pointer"}:{cursor:'pointer'} }></img>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/grey%20bg.png" height='50px' width='50px' onClick={() => this.handleCustomBackgroundColor("https://storage.googleapis.com/spyne/AI/raw/4efd2c9d-7723-428e-8b97-ab6ee1cb32b1.jpg")} style={this.state.bg_custom_url == 'https://storage.googleapis.com/spyne/AI/raw/4efd2c9d-7723-428e-8b97-ab6ee1cb32b1.jpg' ? {border:"1px solid #FF7700", cursor:'pointer'}:{cursor:'pointer'} }></img>
                            </div>
                            <div>
                                <p>Margins</p>
                                <div className="d-flex">
                                    <div className="col-auto px-md-3 px-0">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Top</div>
                                            </div>
                                            <input type="text"  
                                                value={
                                                    this.state.selectedMarketPlaceId == '71' ? 
                                                    data['Amazon']['top']
                                                    :
                                                    this.state.selectedMarketPlaceId == '72' ? 
                                                    data['Flipkart']['top']
                                                    :
                                                    this.state.selectedMarketPlaceId == '73' ? 
                                                    data['Myntra']['top']
                                                    :
                                                    this.state.selectedMarketPlaceId == '74' ? 
                                                    data['Tata Cliq']['top']
                                                    :
                                                    this.state.selectedMarketPlaceId == '75' ? 
                                                    data['Ajio']['top']
                                                    :
                                                    null
                                                } 
                                                disabled
                                                className={[marginStyles['input-right'],"form-control"].join(' ')}  
                                                id="inlineFormInputGroup1"
                                                name="top"
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Bottom</div>
                                            </div>
                                            <input type="text" 
                                                value={
                                                    this.state.selectedMarketPlaceId == '71' ? 
                                                    data['Amazon']['bottom']
                                                    :
                                                    this.state.selectedMarketPlaceId == '72' ? 
                                                    data['Flipkart']['bottom']
                                                    :
                                                    this.state.selectedMarketPlaceId == '73' ? 
                                                    data['Myntra']['bottom']
                                                    :
                                                    this.state.selectedMarketPlaceId == '74' ? 
                                                    data['Tata Cliq']['bottom']
                                                    :
                                                    this.state.selectedMarketPlaceId == '75' ? 
                                                    data['Ajio']['bottom']
                                                    :
                                                    null
                                                } 
                                                disabled
                                                className={[marginStyles['input-right'],"form-control"].join(' ')}  
                                                id="inlineFormInputGroup2"
                                                name="bottom"
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-auto px-md-3 px-0">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Right</div>
                                            </div>
                                            <input type="text" 
                                                value={
                                                    this.state.selectedMarketPlaceId == '71' ? 
                                                    data['Amazon']['right']
                                                    :
                                                    this.state.selectedMarketPlaceId == '72' ? 
                                                    data['Flipkart']['right']
                                                    :
                                                    this.state.selectedMarketPlaceId == '73' ? 
                                                    data['Myntra']['right']
                                                    :
                                                    this.state.selectedMarketPlaceId == '74' ? 
                                                    data['Tata Cliq']['right']
                                                    :
                                                    this.state.selectedMarketPlaceId == '75' ? 
                                                    data['Ajio']['right']
                                                    :
                                                    null
                                                } 
                                                disabled
                                                className={[marginStyles['input-right'],"form-control"].join(' ')}  
                                                id="inlineFormInputGroup3"
                                                name="right"
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Left</div>
                                            </div>
                                            <input type="text"
                                                value={
                                                    this.state.selectedMarketPlaceId == '71' ? 
                                                    data['Amazon']['left']
                                                    :
                                                    this.state.selectedMarketPlaceId == '72' ? 
                                                    data['Flipkart']['left']
                                                    :
                                                    this.state.selectedMarketPlaceId == '73' ? 
                                                    data['Myntra']['left']
                                                    :
                                                    this.state.selectedMarketPlaceId == '74' ? 
                                                    data['Tata Cliq']['left']
                                                    :
                                                    this.state.selectedMarketPlaceId == '75' ? 
                                                    data['Ajio']['left']
                                                    :
                                                    null
                                                } 
                                                disabled
                                                className={[marginStyles['input-right'],"form-control"].join(' ')}  
                                                id="inlineFormInputGroup4"
                                                name="left"
                                                onChange={this.handleChange}    
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={[marginStyles['processing'],'mt-2'].join(' ')}>
                                {
                                    !processing ?
                                    <button onClick={this.handleProcess}>Process</button>
                                    :
                                    <button onClick={this.download}>Download</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
