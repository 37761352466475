import React, { Component } from 'react'

import Slider from "react-slick";

import { InlineWidget } from "react-calendly";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Styles from '../../Css/FootwearV2.module.css'


export default class Footwears extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Background1",
            backgroundTab:"Background1",
            activateButton:"Mobile1",
            mobileTab: "Mobile1",
        }
        this.myDivToFocus = React.createRef()
    }
     

    handleActiveButton = (name) => {
        this.setState({
            activeButton:name
        })
    }
    
    handleSliderTab = (name) => {
        this.setState({
            backgroundTab: name
        })
    }

    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    
    handleMobileTab = (name) => {
        this.setState({
            mobileTab: name
        })
    }
 
    applyChange = (e) => {
        // window.scrollTo(0,500) 
         this.myDivToFocus.current.scrollIntoView() 
        // window.scrollTo({
        //     bottom: this.myDivToFocus.current.offsetBottom,
        //     behavior: 'smooth'     
        // })
      }
 

     

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
      }
      componentWillUnmount() {
        // whatever you need to cleanup the widgets code
      }

    
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // vertical: true,
            // verticalSwiping: true,
            swipeToSlide: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 7000
          };
        return (

             <div className={[Styles['bg-white']]}>
                 <section className={[Styles['banner-slider'],''].join(' ')}>
 
                         <Slider {...settings}>
                            <div className={[Styles['slide-images'],''].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/footwear-gif.gif" style={{"width": "100%"}}/>
                            </div >
                            
                        </Slider> 
                </section> 
                {/* Banner Slider */}
 
                <section className={[Styles['why-section'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            
                                <nav className={[Styles['car-v2-left'],'col-md-6 col-sm-6'].join(' ')} id="spy">
                                    <div className={[Styles['scroll-tab'],'row m-0'].join(' ')}>
                                        <div className={[Styles['why-use'],'row d-md-block d-sm-block d-none d-sm-block'].join(' ')}>
                                            <h1><span>All this </span>with SpyneAI <span className={[Styles['colored']]}>App</span></h1>
                                            <p>With Spyne App anyone can capture professional footwear images</p>
                                        
                                        </div>
                                        <ul className={[Styles['why-tabs'],'flex-column '].join(' ')}>
                                            <li className={[Styles[' '],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile1" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile1")}> 
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%20(3).png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}><h6>Assisted Shoot</h6>    
                                                    <p>Standardise photo clicking according to E-commerce websites solved through overlays & Guidlines</p>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className={[Styles[''],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile2" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile2")}> 
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/245-edit-document-outline%201.png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}> <h6>AI Edit</h6>    
                                                    <p>Smart Editing with inbuilt tools for best outputs of your professionally shot footwear images</p>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className={[Styles[''],''].join(' ')}>
                                                <button className={this.state.activateButton == "Mobile3" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Mobile3")}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/37-approve-checked-simple-outline%201.png" />
                                                    <div className={[Styles['why-text'],''].join(' ')}><h6>Instant Download </h6>    
                                                    <p>Get the images into your mobile phone instantly. Publish the images on marketplaces and boost your sales</p>
                                                    </div>
                                                </button>
                                            </li>
                                            
                                        </ul>
                                        <div className={[Styles['why-use'],'row'].join(' ')}>
                                            <p className={[Styles['buttons-div']]}><button onClick={this.applyChange}  className={[Styles['request-demo']]} target="_blank">Request Demo</button>
                                            <a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}><img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a>
                                            <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                            <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                                            </p> 
                                        </div>
                                    </div>
                                </nav>

                                <div className={[Styles['right-sections'],'col-md-5 offset-md-1 col-sm-6'].join(' ')}>
                                        <div className={[Styles['why-use'],'row d-block d-md-none d-sm-none'].join(' ')}>
                                            <h1><span>Why Use S</span>pyne.ai App</h1>
                                            <p>With SpyneApp anyone can create Studio-quality 360-degree visuals of cars. </p>
                                        
                                        </div>
                                    <div  className={[Styles['first-tab-content'],'row m-0 section'].join(' ')} >  
                                        { 
                                        this.state.activateButton == "Mobile1" ?   
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892356.png" />
                                        :
                                        this.state.activateButton == "Mobile2" ? 
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892327.png" />
                                        :
                                        this.state.activateButton == "Mobile3" ?
                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892352.png" />
                                        :
                                        null
                                        }
                                    </div>
                            </div>
                         
                        </div>
                    </div>
                </section>
                {/* Why use section */}

                <section className={[Styles['custom-backgrounds'],''].join(' ')}>
                        <div className={[Styles[''],'container'].join(' ')}>
                            <div className={[Styles['exterior-tabs'],'row'].join(' ')}>
                                <div className={[Styles['title'],'col-md-12'].join(' ')}>
                                <h1><span>Market</span><span className={[Styles['no-border']]}>place Ready</span> Background Presets</h1> 
                               <p>According to marketplaces like AJIO, Myntra, Amazon, Flipkart, Tatacliq</p>
                                    <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                        <TabList className={[Styles['virtual-images'],Styles['v-image'],'col-md-2 col-sm-12'].join(' ')}>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m1.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m2.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m3.png" /></Tab>
                                            <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m4.png" /></Tab>
                                        </TabList>


                                        <TabPanel className={[Styles['virtual-tabs'],Styles['tab-middle'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                            <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12  display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_1.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12  display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_2.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12  display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_4.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12  display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_3.jpg" />
                                                </TabPanel>

                                                <TabList className={[Styles['virtual-images'],'col-md-2 col-sm-12'].join(' ')}>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[Styles['abs-img']]}/></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                </TabList>
                                            </Tabs>
                                        </TabPanel>
                                        
                                        <TabPanel className={[Styles['virtual-tabs'],Styles['tab-middle'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                            <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_1.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_2.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_4.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_3.jpg" />
                                                </TabPanel>

                                                <TabList className={[Styles['virtual-images'],'col-md-2 col-sm-12'].join(' ')}>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[Styles['abs-img']]}/>
                                                    </Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                </TabList>
                                            </Tabs>
                                        </TabPanel>
                                        
                                        <TabPanel className={[Styles['virtual-tabs'],Styles['tab-middle'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                            <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12  display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_1.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_2.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_4.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_3.jpg" />
                                                </TabPanel>

                                                <TabList className={[Styles['virtual-images'],'col-md-2 col-sm-12'].join(' ')}>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[Styles['abs-img']]}/></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                </TabList>
                                            </Tabs>
                                        </TabPanel>
                                        
                                        <TabPanel className={[Styles['virtual-tabs'],Styles['tab-middle'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                            <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                            <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_1.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_2.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_4.jpg" />
                                                </TabPanel>
                                                <TabPanel className={[Styles['virtual-images'],'col-md-10 col-sm-12 display-none'].join(' ')}>
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_3.jpg" />
                                                </TabPanel>

                                                <TabList className={[Styles['virtual-images'],'col-md-2 col-sm-12'].join(' ')}>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                    <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[Styles['abs-img']]}/></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                    <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                </TabList>
                                            </Tabs>
                                        </TabPanel>
                                        
                                        
                                    </Tabs>
                                     
                                </div>
                            </div>
                        </div>
                    </section>

            {/* Custom Backgrounds Section  */}
                <section className={[Styles['gif-area'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                               <h1>  <span className={[Styles['no-border']]}>Get Instant Marketplace ready  </span>images</h1>  
                            </div>

                            <div className={[Styles['mac-pro-image'],'col-md-8 offset-md-2'].join(' ')}>
                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/footwear-mac.gif" />
                                <p><button onClick={this.applyChange}  className={[Styles['request-demo'],''].join('')} >Request Demo</button></p>
                            </div>

                        </div>
                    </div>
                </section>
                {/* Gif Mac PRo Section          */}

                <section className={[Styles['graph-area'],' '].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                            <h1><span>Online is   </span>   <span className={[Styles['no-border']]}>the fastest growing channel in </span>footwear.</h1>  
                               <p>Online retail sales are growing 74% per year</p>
                            </div>

                            <div className={[Styles['graph-image'],'col-md-12'].join(' ')}>
                                <img className={[Styles[''],'d-md-block img-fluid d-none'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892409.png" />
                                <img className={[Styles['retail-graph'],'d-md-none d-block img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892427%20(1).png" />
                                <img className={[Styles['retail-graph'],'d-md-none d-block img-fluid'].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/Group%205892428.png" />
                                
                                <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}  >Request Demo</button></p>
                            </div>

                        </div>
                    </div>
                </section>
            {/* Graph Section  */}

                <section className={[Styles['calendly-area'],' '].join(' ')} ref={this.myDivToFocus}>
                    <div className={[Styles[''],'container-fluid'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                               <h1> <span className={[Styles['no-border']]}>Earn more by </span> selling your footwear <span className={[Styles['no-border']]}> online using AI</span></h1>  
                                <h6><span>Book a Demo</span> today to learn more!</h6>
                            </div>

                            <div className={[Styles['cars-demo '],'col-lg-8 col-md-7'].join(' ')}>  
                                <div className={[Styles['cars-video'],'row m-0'].join(' ')}>
                                    <iframe width="100%" height="auto" src="https://www.youtube.com/embed/heKIUGYCVec" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				                 </div>
                            </div>

                            <div className={[Styles['cars-demo'],'col-lg-4 col-md-5'].join(' ')}>  
                                <div className={[Styles['cars-calender'],'row m-0'].join(' ')}>
                                    <h4>Book a Demo </h4>
                                    <InlineWidget className={[Styles['calendly-inline-widget'],'p-0'].join(' ')} styles={{"height": "100%"}} url="https://calendly.com/spyneai/footweardemo?hide_event_type_details=1&hide_gdpr_banner=1"  style={{"minwidth": "320px"}} />
                                 </div>
                            </div>
                        </div>

                        <div className={[Styles['mob-area'],'row'].join(' ')}>
                            <div className={[Styles['mob-image'],'col-md-7 col-sm-7'].join(' ')}>
                                <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/11%201.png" />
                            </div>

                            <div className={[Styles['why-use'],Styles['app-pitch'],'col-md-5 col-sm-5'].join(' ')}>
                               <h1>  <span className={[Styles['no-border']]}>Get SpyneAI </span><span className={[Styles['colored']]}>App</span></h1>  
                               <p>With Spyne App anyone can capture professional footwear images  </p>
                               <p><button href="https://www.spyne.ai/tech/footwear/demo/" className={[Styles['request-demo']]}>Learn more</button></p>
                               <p className={[Styles['get-apple-app'],''].join(' ')} ><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[Styles['request-demo2']]}><img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a>
                               <a href="https://spyneai.page.link/iosapp" target="_blank" className={[Styles['request-demo2'],Styles['applestore'],''].join(' ')}>
                                <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                               </p>
                            </div>
                        </div>
                    </div>
                </section>     
            
            {/* Calendly Section */}

            </div>
        )
    }
}