import React, { Component } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import TwentyTwenty from 'react-twentytwenty'
import Footer from '../Common/Footer'
import Styles from '../../Css/Landing/landing.module.css'

export default class home extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Model",
            sliderTab:"Model",
            activateButton:"Dealership",
            marketplaceTab: "Dealership",
            flag:false,
        }
    }

    handleActiveButton = (name) => {
        this.setState({
            activeButton:name
        })
    }
    
    handleSliderTab = (name) => {
        this.setState({
            sliderTab: name
        })
    }
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    
    handleMarketplaceTab = (name) => {
        this.setState({
            marketplaceTab: name
        })
    }

    onMouseOver = () => {
      this.setState({
          flag:true
      })
    }
    onMouseOut = () => {
        this.setState({
            flag:false
        })
      }
  




    render() {
        AOS.init();
        return (

//  Banner Area 
 <div>
<section className={this.state.flag ? Styles['banner-area']: Styles['banner-area2']}> 
	<div className={[Styles[' '],'container-fluid'].join(' ')}>   
	    <div className={[Styles[' '],'row'].join(' ')}>
		    <div className={[Styles['drag-image-area'],'col-md-5'].join(' ')}>
			    <h1>Remove Background <span>in a <a href="#">Single Click</a> using AI</span> </h1>
			   
                    <div className={[Styles['file-upload-area'],' '].join(' ')}>
                        <form className={[Styles['file-upload-form'],'d-md-block d-none '].join(' ')}  >
                            <input  className={[Styles['file-upload'],' '].join(' ')} type="file" name="fileUpload" />
                            
                            <label for="file-upload" className={[Styles['file-drag'],' '].join(' ')} onMouseOver={() => this.onMouseOver()} onMouseOut={() => this.onMouseOut()}>
                                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg" className={[Styles[' up-img'],''].join(' ')} />
                                
                                Drag Image Here <span>or browse for image</span>
                            </label>
                      
                        </form>
                        <button type=""  className={[Styles['mybutton'],'d-block d-md-none'].join(' ')} onMouseOver={() => this.onMouseOver()} onMouseOut={() => this.onMouseOut()}>Upload Image</button>
                    </div>
                    <ul className={[Styles[''],'d-md-block d-none'].join(' ')} >
                        <li><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Vector+(1).png" /> Fully Automated and Free</li>
                        <li><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Vector+(1).png" /> 2M+ Images Processed, 2000+ Customers</li>
                        <li><img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Vector+(1).png" /> Saved 5,200 Hours Of Manual Editing</li> 
                    </ul>
			    </div>
			
    			 
			
		</div>
	</div>
</section>

 {/* <!-- //Banner Area --> */}

 {/* <!-- Product Sell Section --> */}
<section  className={[Styles['product-sell'],''].join(' ')}> 
	<div  className={[Styles[' '],'container-fluid'].join(' ')}>   
	    <div className={[Styles[' '],'row'].join(' ')}>
		    <div className={[Styles['title'],'col-md-12'].join(' ')} >
			    <h1 data-aos="fade-left" data-aos-offset="100"  data-aos-delay="10" data-aos-duration="3000" data-aos-easing="ease-in" data-aos-mirror="true" data-aos-once="false" >We create product images that  <span>SELL</span> </h1>
            </div>
            <div  className={[Styles['product-images'],'col-md-6'].join(' ')}>
                <div  className={[Styles['progress-bar'],' row'].join(' ')} data-aos="fade-right" data-aos-offset="100"  data-aos-delay="10" data-aos-duration="3000" data-aos-easing="ease-in" data-aos-mirror="true" data-aos-once="false">
                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Watch1(2).png" className={[Styles['in-left'],' '].join(' ')}/>
                    
                    <div   className={[Styles['progress-bar1'],'animate'].join(' ')}>  <span></span></div>
                </div>
                <p>Conversion Rate % <br/>with unnecessary background</p>
            </div>
            
            <div className={[Styles['pro-img2'],'col-md-6'].join(' ')} >
                <div  className={[Styles['progress-bar2'],' row'].join(' ')} data-aos="fade-right" data-aos-offset="100"  data-aos-delay="10" data-aos-duration="3000" data-aos-easing="ease-in" data-aos-mirror="true" data-aos-once="false">
                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Watch2(2).png" className={[Styles['in-left'],' '].join(' ')}/>
                    <div   className={[Styles['progress-barrr'],'animate'].join(' ')}>  <span></span></div>
                </div>
                <p>Conversion Rate % <br/>with solid background</p>
            </div>
            
        </div>
	</div>
</section>

{/* <!-- //Product Sell Section --> */}
 
{/* <!-- Make your subject stand out --> */}

 <section className={[Styles['subject-stand-out'],' '].join(' ')}> 
	<div className={[Styles[' '],'container'].join(' ')}>   
	    <div className={[Styles[' '],'row'].join(' ')}>
		    <div className={[Styles['title'],'col-md-12'].join(' ')}>
			    <h1>Make Your Subject Stand Out!</h1>
            </div>
            <div className={[Styles['subject-tabs'],'col-md-12'].join(' ')}>  
                <ul className={[Styles[' '],' '].join(' ')} >
                    <li className={[Styles[' '],' '].join(' ')}>
                        <button className={this.state.activeButton == "Model" ? [Styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Model")} >Models</button>
                    </li>
                    <li className={[Styles[' '],' '].join(' ')}>
                        <button className={this.state.activeButton == "Watches" ? [Styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Watches")}>Watches</button> 
                    </li>
                    <li className={[Styles[''],' '].join(' ')}>
                        <button className={this.state.activeButton == "Grocery" ? [Styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Grocery")}>Grocery</button> 
                    </li>
                    <li className={[Styles[''],' '].join(' ')}>
                        <button className={this.state.activeButton == "Footwear" ? [Styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Footwear")}>Footwear</button> 
                    </li>
                    <li className={[Styles[' '],' '].join(' ')}>
                        <button className={this.state.activeButton == "Car" ? [Styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Car")}>Car</button> 
                    </li>
                </ul>
    
                <div className={[Styles['tab-contents'],'row'].join(' ')}>
                    <div  className={[Styles['model-tab-content'],'col-md-7 col-lg-8 '].join(' ')} > 
                      { 
                        this.state.activeButton == "Model" ?
                            <TwentyTwenty
                                left={<img  className="img-fluid"  src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/removeBgBefore.png" />}
                                right={<img  className="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/removeBgAfter.png" />}
                                slider={<div className={Styles['slider']}>
                                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png" /> 
                                    </div>
                                        }
                            /> 
                        :
                        this.state.activeButton == "Watches" ?
                            <TwentyTwenty
                                left={<img  className="img-fluid"  src="https://storage.googleapis.com/clippr-image-processing/Static/watchbg.png" />}
                                right={<img  className="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/watch.png" />}
                                slider={<div className={Styles['slider']}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png" /> 
                                </div>
                                }
                            />
                        :
                        this.state.activeButton == "Grocery" ?
                            <TwentyTwenty
                                left={<img  className="img-fluid"  src="https://storage.googleapis.com/clippr-image-processing/Static/fruit.png" />}
                                right={<img  className="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/fruitbg.png" />}
                                slider={<div className={Styles['slider']}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png" /> 
                                </div>
                                }
                            />
                        : 
                        this.state.activeButton == "Footwear" ?
                            <TwentyTwenty
                                left={<img  className="img-fluid"  src="https://storage.googleapis.com/clippr-image-processing/Static/shoe.png" />}
                                right={<img  className="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/shoebg.png" />}
                                slider={<div className={Styles['slider']}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png" /> 
                                </div>
                                }
                            />
                        : 
                        this.state.activeButton == "Car" ?
                            <TwentyTwenty
                                left={<img  className="img-fluid"  src="https://storage.googleapis.com/clippr-image-processing/Static/car.png" />}
                                right={<img  className="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/carbg.png" />}
                                slider={<div className={Styles['slider']}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5887.png" /> 
                                </div>
                                }
                            />
                        : 
                        null
                    }
                    </div> 
                </div> 
            </div>
            


        </div>
	</div>
</section> 

 {/* <!-- Make your subject stand out --> */}
 
 {/* <!-- Extract The Subject --> */}
<section className={[Styles['extract-the-subject'],' '].join(' ')}> 
	<div className={[Styles[' '],'container-fluid '].join(' ')}>   
	    <div className={[Styles[' '],'row'].join(' ')}>
	         <div className={[Styles['extract-image'],'col-md-6'].join(' ')}>
                <video autoplay="" loop="" muted="" className={[Styles['img-fluid'],' '].join(' ')} style={{width: "100%"}}>
                    <source type="video/webm" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/ezgif.com-gif-to-webm.webm" />
                </video>
            </div>
		    <div className={[Styles['extract-content'],'col-md-6  '].join(' ')}>
                <div className={[Styles['title'],'row '].join(' ')}>
                    <h1>Extract The Subject</h1>
                    <p>from removing unnecessary Image Background  & put the Subject on certain occasion you like.  </p>
                    <h6>Free <span>AI driven</span> tool</h6>
                    <p><a href="#" className={[Styles['try-button'],'d-none d-md-block'].join(' ')}>Try Now</a></p>
                </div> 
            </div>


        </div>
	</div>
</section>
{/* <!-- //Extract The Subject --> */}

{/* <!-- Get Marketplace Ready Images --> */}

<section className={[Styles['subject-stand-out'],' '].join(' ')}> 
	<div className={[Styles[' '],'container-fluid'].join(' ')}>   
	    <div className={[Styles[' '],'row'].join(' ')}>
		    <div className={[Styles['title'],'col-md-12'].join(' ')}>
			    <h1>Get Marketplace Ready Images</h1>
            </div>
          <div className={[Styles['marketplace-tabs'],'col-md-12'].join(' ')}>  
                <ul className={[Styles[' '],' '].join(' ')} >
                    <li className={[Styles[' '],' '].join(' ')}>
                        <button className={this.state.activateButton == "Dealership" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Dealership")} >Car Dealership</button>
                    </li>
                    <li className={[Styles[' '],' '].join(' ')}>
                        <button className={this.state.activateButton == "Footwear" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Footwear")}>Footwear</button> 
                    </li>
                    <li className={[Styles[''],' '].join(' ')}>
                        <button className={this.state.activateButton == "Fashion" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Fashion")}>Fashion</button> 
                    </li>
                    <li className={[Styles[''],' '].join(' ')}>
                        <button className={this.state.activateButton == "Furniture" ? [Styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Furniture")}>Furniture</button> 
                    </li>
                </ul>
    
                <div className={[Styles['tab-contents'],'row '].join(' ')}>
                { 
                    this.state.activateButton == "Dealership" ?
                    <>
                        <div  className={[Styles['model-tab-content'],'col-md-7'].join(' ')} > 
                            <img class="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/crassss.png" />
                        </div> 


                        <div className={[Styles['marketable-content'],'col-md-5'].join(' ')}>
                            <h6>Create <span>Professional Automotive Pictures</span> & upload It directly to your favourite marketplace</h6>
                            <p><a href="#" className={[Styles['try-button']]}>Try Now</a></p>
                        </div>
                    </>
                    :
                    this.state.activateButton == "Footwear" ?
                    <>
                        <div  className={[Styles['model-tab-content'],'col-md-7'].join(' ')} > 
                            <img class="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/Footwear.png" />
                        </div> 


                        <div className={[Styles['marketable-content'],'col-md-5'].join(' ')}>
                            <h6>Create <span>Professional Automotive Pictures</span> & upload It directly to your favourite marketplace</h6>
                            <p><a href="#" className={[Styles['try-button']]}>Try Now</a></p>
                        </div>
                    </>
                    :
                    this.state.activateButton == "Fashion" ?
                    <>
                        <div  className={[Styles['model-tab-content'],'col-md-7'].join(' ')} > 
                            <img class="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/fashion.png" />
                        </div> 


                        <div className={[Styles['marketable-content'],'col-md-5'].join(' ')}>
                            <h6>Create <span>Professional Automotive Pictures</span> & upload It directly to your favourite marketplace</h6>
                            <p><a href="#" className={[Styles['try-button']]}>Try Now</a></p>
                        </div>
                    </>
                    :
                    this.state.activateButton == "Furniture" ?
                    <>
                        <div  className={[Styles['model-tab-content'],'col-md-7'].join(' ')} > 
                            <img class="img-fluid" src="https://storage.googleapis.com/clippr-image-processing/Static/furniture.png" />
                        </div> 


                        <div className={[Styles['marketable-content'],'col-md-5'].join(' ')}>
                            <h6>Create <span>Professional Automotive Pictures</span> & upload It directly to your favourite marketplace</h6>
                            <p><a href="#" className={[Styles['try-button']]}>Try Now</a></p>
                        </div>
                    </>
                    :
                    null
                }
                </div> 
            </div> 






        </div>
	</div>
</section> 

 {/* <!-- //Get Marketplace Ready Images --> */}
 
 {/* <!-- Best Useful For --> */}
<section className={[Styles['best-useful'],' '].join(' ')}>  
	<div className={[Styles[' '],'container'].join(' ')}>   
	    <div className={[Styles[''],'row '].join(' ')}>
		    <div className={[Styles['title'],'col-md-12 '].join(' ')}>
			    <h1>Best Useful For</h1>
            </div>
            <div className={[Styles['best-useful-col'],'col-md-6 '].join(' ')}>
                <div className={[Styles['best-useful-single'],'row '].join(' ')}>
                    <div className={[Styles[''],'col-md-5 col-4'].join(' ')}>
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/undraw_add_to_cart_vkjp+1.png" />
                    </div>
                   
                    <div className={[Styles['best-content'],'col-md-7 col-8'].join(' ')}>
                        <h4>E-Commerce</h4>
                        <p>Increase conversion rate with stunning photos. Just upload images and get the background removed in seconds.</p>
                    </div>
                </div>
            </div>
            
            <div className={[Styles['best-useful-col'],'col-md-6'].join(' ')}>
                <div className={[Styles['best-useful-single2'],'row'].join(' ')}>
                    <div className={[Styles[''],'col-md-5 col-4'].join(' ')}>
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/undraw_Business_plan_re_0v81+1.png" />
                    </div>
                   
                    <div className={[Styles['best-content'],'col-md-7 col-8'].join(' ')}>
                        <h4>Business</h4>
                        <p>Make your image background transparent & create great marketing pitch.</p>
                    </div>
                </div>
            </div>
            
            <div className={[Styles['best-useful-col'],'col-md-6'].join(' ')}>
                <div className={[Styles['best-useful-single'],'row'].join(' ')}>
                    <div className={[Styles[''],'col-md-5 col-4'].join(' ')}>
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/undraw_progressive_app_m9ms 1.png" />
                    </div>
                   
                    <div className={[Styles['best-content'],'col-md-7 col-8'].join(' ')}>
                        <h4>Graphic Design</h4>
                        <p>Save your time from manual editing. Remove background in a single click with Clippr.ai</p>
                    </div>
                </div>
            </div>
            
            <div className={[Styles['best-useful-col'],'col-md-6'].join(' ')}>
                <div className={[Styles['best-useful-single2'],'row'].join(' ')}>
                    <div className={[Styles[''],'col-md-5 col-4'].join(' ')}>
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/undraw_new_entries_nh3h+1.png" />
                    </div>
                   
                    <div className={[Styles['best-content'],'col-md-7 col-8'].join(' ')}>
                        <h4>Social Post</h4>
                        <p>Thousands of creators use Clippr.ai to create awesome posts & thumbnails. Try it now!</p>
                    </div>
                </div>
            </div>
            

        </div>
	</div>
</section>
{/* <!-- //Best Useful For --> */}

{/* <!-- Text Section --> */}
<section className={[Styles['text-section'],' '].join(' ')}> 
	<div className={[Styles['container-fluid'],' '].join(' ')}>   
	    <div   className={[Styles[' '],'row '].join(' ')}>
	         
		    <div className={[Styles['text-content'],'col-md-12 '].join(' ')}>
			    <p><strong>Now you can instantly remove the background from an image online for free - and it has never been so easy!</strong> </p>
                <p>Have you ever searched the internet for a tool that could remove the background from an image online? Well, every one of us 
                    has! And no matter if the online background burner was for personal or professional purposes, at some point, we all have
                     felt a need to erase background from our images. 
                </p>
                <p>In fact, one of the biggest pet peeves that designers find while creating designs is struggling to erase background from an 
                    image. <strong>But not anymore!</strong> With the latest developments in AI, technology companies are working to leverage artificial 
                    intelligence to create tools that make complex workflows seamless - like Clippr, an AI-based automatic background remover, 
                    developed by Spyne to help designers, individuals users, and businesses quickly make backgrounds transparent. Now, you don’t
                     have to use traditional methods to crop the background but you can use this 100% automatic background eraser tool to remove bg.
                </p>		    
                <p><strong> Removing background from an Image is now ‘Child’s Play’</strong></p>
                <p>Using advanced technology and powerful algorithms, our auto background remover tool intelligently detects and then smartly 
                    separates the main subject, object, or pet automatically from the background, allowing you to download a PNG file of your 
                    cutout with a transparent background. You don’t even have to make any effort, because all the complex work of removing the
                     background is done by our smart tool - the background eraser tool. 
                </p>
                <p><strong>It just gets better every day</strong></p>
                <p>Our AI-based online bg remover, Clippr, is designed using smart algorithms that gets better by each day. Clippr is not 
                    created using artificial lab data but algorithms that are trained on millions of real-world pictures, helping you to remove
                     the background of the most complex pictures, not just from the cherry-picked categories. The more you use Clippr, the 
                     better it gets, the better are the results in terms of accuracy and quality in removing bg. You can now remove background
                      from images in bulk using Clippr.
                </p>
                <p><strong>Create shots that stand out</strong></p>
                <p>Use our online bg remover to create stunning images that POP. it allows you to create cohesive, high-quality transparent
                     images that you can in any setting or on any backdrop of your choice. Whether it is a model image, product photo, or 
                     vacation pictures, you can seamlessly use online bg remover Clippr to get your job done in a matter of a few seconds.
                      Automatic background remover will remove image background in less than 7 seconds. 
                </p>
                <p><strong>Smarter than traditional tools, better than outsourcing</strong></p>
                <p>If you are too bored to use traditional tools and methods to crop the background of an image, then auto background remover 
                    is an online tool that can help you remove bg 100% automatically and free of cost. Not need to outsource and spend thousands 
                    of buck when you can it by yourself in a matter of seconds. Why wait?
                </p>
                <p><strong>Awesome tool for</strong></p>
                
                <p><strong>E-commerce:</strong> Getting ready to list your products on a website or multiple marketplaces? Our online bg remover 
                helps you in creating a transparent background in your product images that you can repurpose and upload on sites like Amazon, eBay,
                 Etsy, Flipkart, Myntra, etc. It helps you create marketplace-ready pictures that ensure a boost in engagements and conversion rates.
                 </p>
                 <p><strong>Businesses:</strong> Instantly remove the background from image online free and create immaculate marketing materials 
                 and presentations with ease. Remove picture background and get the deliverables at a faster turnaround time. 
                 </p>
                 <p><strong>Designing:</strong> Don’t worry if you find removing background in Photoshop tedious and time-consuming. Use quick online 
                 bg remover to save your time and get creative on creating your stunning designs instantly. 
                 </p>
                 <p><strong>And more...:</strong>  Hundreds of thousands of people use Clippr - our AI-based online bg remover - to delete the background and get
                      their jobs done in seconds. Yes FREE!
                </p>
                <p><strong> Free, Fast, and Fabulous </strong></p>
                <p>Why waste so much time and money when you can remove the background from an image online free, fast, and at the same time 
                    get fabulous results? You can delete background online from unlimited images using Clippr - a smart and hassle-free way of 
                    removing the background. Delete background online at lightning speed using Clippr.
                </p>

                <p><strong>Repurpose your product images</strong></p>
                <p>If you are looking for an online background burner that can help you to repurpose your product images, then Clippr is the 
                    perfect tool. You can remove white background online free from images and create transparent images, which you can use on 
                    any solid background of your choice. With Clippr, you can remove white background online free of cost and 100% automatically. 
                    All you need to do is upload the image and wait for Clippr to crop out the background.
                </p>
                <p>You can easily create PNG files with transparency using this online background burner - Clippr. It is easy to use tool to crop out 
                    the background and make it transparent. After removing bg and having transparent PNG, you can use that on any backdrop of your 
                    choice and create a new set of pictures without having to do any additional shoots. 
                </p>
                <p><strong>How to remove image background automatically using Clippr?</strong></p>
                <ol style={{fontSize: "12px"}}>
                    <li>Go to the Clippr website and click on ‘TRY IT NOW.’</li>
                    <li>Click on ‘CHOOSE IMAGE’ to upload an image. (Give it 7 seconds)</li>
                    <li>And click on the ‘DOWNLOAD IMAGE’ to save the image with a transparent background. </li>
                   
                </ol>
                <p><strong>Simple! Go try it now!</strong></p>
            </div>


        </div>
	</div>
</section>
{/* <!-- //Text Section --> */}



                {/* Footer */}


</div>
        )
    }
}
