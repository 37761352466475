import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import FootwearLandingModal from '../Common/Modal/FootwearLandingModal'
import axios from 'axios'
import {fetchCreditUserDetails, updateUserCredit,cookie,creditInfo, reduceCredit, poll, chunkArray} from '../Common/Utils'
import '../../App.css'
import '../Common/Modal/FootwearLandingModal'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FootwearBackgroundV2 from './FootwearBackgroundV2'
import FootwearBulkBackgroundV2 from './FootwearBulkBackgroundV2'
import { InlineWidget } from "react-calendly";
import  styles from '../../Css/Footwear/FootwearV2.module.css'
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";
import Drawer from '../Drawer/Drawer'
import * as Sentry from "@sentry/react"



class FootwearV2 extends Component {
    constructor(props){
        super(props)
        this.state={
          selectedFile: null,
          selectedLogoFile: null,
          url: window.sessionStorage.getItem("lastEditedImage") ? window.sessionStorage.getItem("lastEditedImage") : '' , // url of the image returned by the api 
          orgUrl:  window.sessionStorage.getItem("lastRawImage") ? window.sessionStorage.getItem("lastRawImage") :  '',
          object_id:0,
          background_id:929,
          uploadPercentage: 0,
          uploading: false,
          orgImgShow: true,
          loader: false,
          uploadBox: true,
          editedState: false,
          below_bg:false,
          tabIndex: 0,
          isError: false,
          processing: false,
          editedImagesArr: [],
          editing: false,
          carShadow:false,
          buffer_array:[1],
          cnt:1,
          spinner:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          car_bg_replacement_angle:'front',
          selectedAngle:'',
          imageSelected:false,
          firstUpload:true,
          planData:[],
          walldata:[],
          availableCredit:5,
          alottedCredit: 0,
          creditUsed:0,
          isEditingFinish:false,
          toggleChange:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          toggleStatus:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          bgCredit:1,
          loginStatus:false,
          logoUrl:'',
          logoPosition:null,
          hideLogo:false,
          showInstruction:false,
          showInstructionModal:false,
          askEmail:false,
          activButton: "Exterior",
          backgroundId:["80","81","82"],
          backgroundEditedUrl:[],
          imageEdited : false,
          actiButton:"Background1",
          waterMarkImg:"",
          activateButton:"Hd",
          downloadComplete: false,
          isDownload: false,
          urlDownload: '',
          dropBackground: false,
          checkColorEnhancement:false,
          checkWindowTransparency:false,
          filesList:JSON.parse(sessionStorage.getItem("filesList")) ? JSON.parse(sessionStorage.getItem("filesList")) : [],
          setFiles:JSON.parse(sessionStorage.getItem("setFiles")) ? JSON.parse(sessionStorage.getItem("setFiles")) : [],
          maxUpload: false,
          showBulkCreditModal: false,
          showProjectNameModal: false,
          project_name: window.sessionStorage.getItem("project_name") ? window.sessionStorage.getItem("project_name") : '',
          sku_name: window.sessionStorage.getItem("sku_name") ? window.sessionStorage.getItem("sku_name") : '',
          project_id:'',
          sku_id:'',
          notEnoughCreditModal:false,
          enoughCreditModal:false,
          zeroCreditModal:false,
          uploadStarteds: window.sessionStorage.getItem("uploadStarteds") ? window.sessionStorage.getItem("uploadStarteds") : false,
          orgUrlList:[],
          imgStatusUpload: true,
          imgStatusProcess: false,
          imgStatusDone: false,
          showRefundModal: false,
          refundImgUrl:'',
          refundOrgUrl:'',
          refundImgId:'',
          refundNum:null,
          userId : '',
          downloadZipLink: window.sessionStorage.getItem("downloadZipLink") ? window.sessionStorage.getItem("downloadZipLink") :'',
          isZipReady : window.sessionStorage.getItem("isZipReady") ? window.sessionStorage.getItem("isZipReady") : false,
          bulkDownloadDone:window.sessionStorage.getItem("bulkDownloadDone") ? window.sessionStorage.getItem("bulkDownloadDone") : false,
          errorBulkFiles: [],
          auth_key:'',
          spinnerDownload: false,
          emailId:'',
          wall:"",
          customWall:"",
          customWallPosition:0,
          tabSelection:"virtual_studio",
          productHunt: 'clippr',
          projectNameExists:false,
          isBulk : false,
          market:'',
          bg_color:'',
          checkColorEnhancement:false

        }
        this.myDivToFocus = React.createRef()
    }
    
    handleColorEnhancement=()=>{
        this.setState({
          checkColorEnhancement:!this.state.checkColorEnhancement
        })
      }

    componentDidMount = async () => {
        var passing_location;
        if ("geolocation" in navigator) {
          // check if geolocation is supported/enabled on current browser
          navigator.geolocation.getCurrentPosition(
           function success(position) {
    
            //  console.log('latitude', position.coords.latitude, 
            //              'longitude', position.coords.longitude);
            passing_location = [position.coords.latitude, position.coords.longitude];
            localStorage.setItem("passing_location", passing_location);
         },
          function error(error_message) {
            passing_location = 'NULL'
            localStorage.setItem("passing_location", passing_location);
          }  
      )}
         else {
          passing_location = 'NULL'
          localStorage.setItem("passing_location", passing_location);
        }
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            
                this.setState({
                    showInstruction:true
                })
            
        }
        axios({
            'method':"GET",
            'url':`https://www.clippr.ai/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`   //MarketPlace API will come here
        })
        .then((res) => {
            this.setState({
                planData:res.data    // planData Will contain MarketPlaces
            })
        })
        let userId = this.cookie('userId')
        if(userId !== "NA"){
            let emailId = this.cookie("emailId")
            let auth_key = this.cookie("auth_token")
            creditInfo(auth_key).then((resp)=>{
                if(resp.data.status == 200){
                        this.setState({
                            availableCredit: resp.data.data.credit_available,
                            creditAlloted : resp.data.data.credit_allotted,
                            creditUsed: resp.data.data.credit_used
                        })
                    }else{
                        console.log(resp)
                    }
                })
                this.setState({
                    userId: userId
                })
        }
    }
    
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    

    handleBgColor=(e)=>
{
  this.setState({
    bg_color:e
  })
}
handlemarket=(e)=>{
    this.setState({
      market:e
    })
 
  }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false,
            dropBackground: false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            
            );
        }
        // this.hitapiModal()
      };

      handelColorEnhancement = () =>{
        this.setState({
            checkColorEnhancement:!this.state.checkColorEnhancement,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleWindowTransparency = () =>{
        this.setState({
            checkWindowTransparency:!this.state.checkWindowTransparency,
            toggleChange:false,
            toggleStatus:false,
        })
    }

    fileSelectedHandlerNew = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0]),
                backgroundEditedUrl:[],
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file),
                backgroundEditedUrl:[],
            },
            
            );
        }
        this.hitapiModal()
      };

      handleDownloadComplete = () => {
          this.setState({downloadComplete: false})
      }

      handleLogo = () => {
        this.setState({
            logoPosition : null,
            selectedLogoFile: null,
            logoUrl: ''
        })
      }

    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }

    handleAngle=(id)=>{
        let selectedAngle = this.cookie("selectedAngle")
        this.setState({
          car_bg_replacement_angle:id,
          selectedAngle:selectedAngle,
          imageSelected:true,
          isEditingFinish:false
        })
    }

    handleId=(id,price)=>{
        this.setState({
            background_id:id,
            bgCredit:price,
            // isEditingFinish:false,
            askEmail:false,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    updateCredit = (e) => {
        // e.preventDefault()
        let userId = this.cookie('userId')
        let price 
        if(this.state.activateButton === 'Hd'){
            price = this.state.bgCredit
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v4/download-history",
                params : {
                    user_id : userId,
                    enterprise_id : 'TaD1VC1Ko',
                    sku_id: this.state.sku_id
                }
                
            }).then(resp => {
                if(resp.data.status == 404){
                    let auth_key = this.cookie('auth_token')
                    const fd = new FormData()
                    fd.append("auth_key", auth_key)
                    fd.append("credit_reduce",price)
                    axios.put(`https://www.clippr.ai/api/v2/credit/reduce-credit`,fd)
                    // updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
                    .then((res) => {
                        this.setState({
                            availableCredit: this.state.availableCredit - price,
                            // downloadComplete: true
                        })
                        setTimeout(() => {
                            this.setState({downloadComplete: true})
                        },5000)
                        const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                        const fd = new FormData()
                        fd.append("user_id", userId)
                        fd.append("sku_id",this.state.sku_id)
                        fd.append("enterprise_id" , 'TaD1VC1Ko')
                        fd.append("download_hd","true")
                        axios.post(`https://www.clippr.ai/api/v4/update-download-status`,fd)
                        .then(resp => {
                            console.log(resp)
                        })
                    })
                }else{
                    const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                }
            })
        }else{
            price = 0
            const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
            fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();
                
                });
                
            })
        }     
    }
    askEmail =() => {
        this.setState({
            askEmail:true
        })
    }

    // downloadPopup = () => {
    //     this.setState({isEditingFinish : false})
    // }

    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }

    

    downloadPopup = () => {
        this.setState({isDownload: false})
    }

    hitapi = () => {
        this.setState({
          spinner: true,
          hideLogo:true,
        });
        if(this.state.filesList[0].file !== null){
            const fd = new FormData();
            fd.append("image", this.state.filesList[0].file);
            fd.append("optimization",false)
            let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
            axios.post(`https://www.clippr.ai/api/upload?api_key=${api_key}`,fd,{params:{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"}})
            .then((uploadedResponse) => {
                const replacementFd = new FormData()
                let timestamp= new Date().getTime();
                timestamp.toString();
                let sku_id="sku"+timestamp
                replacementFd.append("user_id", this.cookie("userId"));
                replacementFd.append("sku_id", sku_id);
                replacementFd.append("background", this.state.background_id);
                replacementFd.append("source","Web");
                replacementFd.append("image_url",uploadedResponse.data.image);
                replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
                replacementFd.append("contrast",this.state.checkColorEnhancement);
                if(this.state.tabSelection=="custom_walls"){
                    replacementFd.append("appyType","CW")
                    replacementFd.append("custom_wall",this.state.customWall)
                    if(this.state.customWallPosition>0){
                        replacementFd.append("height",this.state.customWallPosition)
                    }
                    else{
                        replacementFd.append("height","mid")
                    }
                }
                else {
                    
                    replacementFd.append("wall_url","0");
                    replacementFd.append("appyType","VS")
                    replacementFd.append("custom_wall","null")
                    replacementFd.append("height","null")
                }

                if(this.state.selectedLogoFile !== null){
                    replacementFd.append("logo",this.state.selectedLogoFile);
                    replacementFd.append("logo-position",this.state.logoPosition)
                }
                axios
                .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
                .then((res) => {
                    let userEmail = this.cookie("emailId")
                    let _userId_ = this.cookie("userId")
                    let isValidated = this.cookie("isValidated")
                    if(!userEmail){
                        clearInterval(x)
                        var x = setTimeout(this.askEmail, 3000);
                    }
                    let temp = res;
                    if (temp.data['url']) {

                        if (userEmail){       

                            temp.data['authenticated_email_id'] = userEmail;
                            temp.data['user_id'] = _userId_;
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            if (isValidated=='true'){
                                temp.data['otp_verified'] = 'YES';
                            }
                            else {
                                temp.data['otp_verified'] = 'NO';
                            }
                            if (window.localStorage.getItem("clippr_user_email") != null){
                                temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            }
                            else{
                                temp.data['free_trail_email'] = 'NULL';
                            }
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                        }
            
                        else if (window.localStorage.getItem("clippr_user_email") != null) {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }
                        else {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['authenticated_email_id'] = 'NULL';
                            temp.data['free_trail_email'] = 'NULL';
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }

                    }
                    this.setState({ 
                        url:res.data.display_image,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true,
                        waterMarkImg:res.data.watermark_image,
                        isDownload: true,
                        urlDownload: res.data.output_image
                        });
                    
                    window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                    window.sessionStorage.setItem("lastEditedImage", res.data.output_image)
                    window.sessionStorage.setItem("lastRawImage",uploadedResponse.data.image)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch((uploadFail) => {
                this.setState({
                    spinner:false
                })
            })
        }
        else{
            const replacementFd = new FormData()
            // const replacementFd = new FormData()
            let timestamp= new Date().getTime();
            timestamp.toString();
            let sku_id="sku"+timestamp
            replacementFd.append("user_id", this.cookie("userId"));
            replacementFd.append("sku_id", sku_id);
            replacementFd.append("background", this.state.background_id);
            replacementFd.append("source","Web");
            replacementFd.append("image_url",this.state.filesList[0].url);
            replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
            replacementFd.append("contrast",this.state.checkColorEnhancement);
            if(this.state.tabSelection=="custom_walls"){
                replacementFd.append("appyType","CW")
                replacementFd.append("custom_wall",this.state.customWall)
                if(this.state.customWallPosition>0){
                    replacementFd.append("height",this.state.customWallPosition)
                }
                else{
                    replacementFd.append("height","mid")
                }
            }
            else {
                
                replacementFd.append("wall_url","0");
                replacementFd.append("appyType","VS")
                replacementFd.append("custom_wall","null")
                replacementFd.append("height","null")
            }

            if(this.state.selectedLogoFile !== null){
                replacementFd.append("logo",this.state.selectedLogoFile);
                replacementFd.append("logo-position",this.state.logoPosition)
            }
            axios
            .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
            .then((res) => {
                // console.log(res.data.output_image)
                this.setState({ 
                    url:res.data.display_image,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:res.data.watermark_image,
                    isDownload: true,
                    urlDownload: res.data.output_image
                    });
                
                // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
                // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                window.sessionStorage.setItem("lastEditedImage", res.data.display_image)
                window.sessionStorage.setItem("lastRawImagsuve", this.state.orgUrl)
            })
            .catch((err) => {
                this.setState({
                    spinner:false
                })
            })
        }
    };

    handelCarShadow = () => {
        this.setState({
          carShadow:!this.state.carShadow
        })
    }
     

    handleLogoPosition = (e) => {
        console.log(e.target.name)
        this.setState({
          logoPosition:e.target.name,
          hideLogo:false
        })
    }
    handleInstruction = (e) => {
        // e.preventDefault()
        this.setState({
            showInstructionModal:e.target.checked
        })
        window.localStorage.setItem("showInstructionModal", e.target.checked)
    }
    handleInstructionModal = () => {
        this.setState({
            showInstruction:true
        })
    }
    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }
    handleActivButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
      }
      dragLeave = (e) => {
        e.preventDefault()
        this.state.dropBackground = false
      }

      fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false,
            dropBackground: false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            
            );
        }
        // this.hitapiModal()
      };

    multipleFileUpload = (event) =>{
        // console.log(event.target.files)
        // if(this.state.filesList.length < 36){

            this.state.orgUrl = ''
            this.state.editedUrl = ''
            this.state.spinner = false
            this.state.toggleChange = false
            this.state.toggleStatus = false
            this.state.isEditingFinish = false
            window.sessionStorage.removeItem('lastEditedImage')
            window.sessionStorage.removeItem('lastRawImage')
            if(this.state.project_name === ''){
                this.setState({
                    showProjectNameModal:true
                })
            }
            
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            } 
    }

    multipleFileUploadProject = (event) =>{
        // console.log(event.target.files)
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.state.uploadStarteds = false
        this.state.project_name = ''
        this.state.sku_name = ''
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.orgUrl = ''
        this.state.editedUrl = ''
        this.state.spinner = false
        this.state.toggleChange = false
        this.state.toggleStatus = false
        this.state.isEditingFinish = false
        this.setState({
            tabSelection: 'virtual_studio'
        }) 
        sessionStorage.clear()

        if(this.state.project_name === ''){


                    this.setState({
                    showProjectNameModal:true
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
        // console.log(this.state.filesList)
        // console.log(this.state.setFiles)
    }

    creditVerify = () => {
        
        let userId = this.cookie('userId')
        let price = this.state.filesList.length
        if(this.state.availableCredit >= price){
           
            
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:false,
                zeroCreditModal:false,
                enoughCreditModal:true,
                
                // uploadStarteds:true,
            })
            
        }else if(this.state.availableCredit == 0){
            this.setState({
                showBulkCreditModal: true,
                zeroCreditModal:true,
                // uploadStarteds:true,
            })
        }else if(this.state.availableCredit < price){
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:true,
                filesList : this.state.filesList.slice(0,this.state.availableCredit)

            })
        }
        // console.log(this.state.filesList)
    }

    hitUploadAPI = async () => {

        let price = this.state.filesList.length

        if(this.state.availableCredit >= price){
            this.getUserCredit()
            this.setState({
                uploadStarteds:true,
                showBulkCreditModal:false,
                enoughCreditModal: false,
                
            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for(let batchIndex=0; batchIndex < batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4*batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            this.setState({
                isZipReady: true
            })
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i< this.state.filesList.length;i++){
            //     this.bulkUploadAPI(i)
            // }
            sessionStorage.setItem("uploadStarteds", true);
            
        }else if(this.state.availableCredit < price){
            // const filesListNew = this.state.filesList.slice(0,this.state.availableCredit)
            // this.forceUpdate()

            this.setState((prevState) => {
                return {
                    uploadStarteds:true,
                    showBulkCreditModal:false,
                    notEnoughCreditModal: false,
                    // filesList: prevState.filesList.slice(0,this.state.availableCredit)
                }
            }, () => {
                console.log(this.state.filesList.length)
                sessionStorage.setItem("uploadStarteds", true);
            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for(let batchIndex=0; batchIndex < batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4*batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            this.setState({
                isZipReady: true
            })
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i<this.state.availableCredit;i++){
            //     this.bulkUploadAPI(i)
            // }

            let arr = this.state.filesList.slice(0, this.state.availableCredit)
            this.setState({
                filesList : [...arr]
            }, () => {
                console.log(this.state.filesList.length)
            })
             
        }
        

    }

    singleUploadAPI = (i) => {
        this.setState({
            spinner: true,
            hideLogo:true,
        });
        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("image", this.state.filesList[0].file);
        fd.append("is_color",this.state.checkColorEnhancement)
        // fd.append("optimization",false)
        // fd.append("background_id", this.state.background_id);
        // fd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
        // fd.append("contrast",this.state.checkColorEnhancement);
        // fd.append("prod_cat_id", "Footwear")
         fd.append("auth_key", auth_key)
         fd.append("marketplace_id","mark_dje41p")
         fd.append("bg_color",this.state.bg_color=="white"?"https://storage.googleapis.com/spyne/AI/raw/e904fad2-727e-467a-aef5-89b3e1f06c99.jpg":"https://storage.googleapis.com/spyne/AI/raw/4efd2c9d-7723-428e-8b97-ab6ee1cb32b1.jpg");
        // fd.append("prod_sub_cat_id", "Sedan")
        // fd.append("sku_name", this.state.sku_name)
        // fd.append("project_name", this.state.project_name)
        // fd.append("enterprise_id", 'TaD1VC1Ko')
        // fd.append('processingType', 'REALTIME')
        fd.append('source', 'SINGLEPROCESS')
        

        if(this.state.selectedLogoFile !== null){
            fd.append("logo",this.state.selectedLogoFile);
            fd.append("logo-position",this.state.logoPosition)
        }

        axios.post(`https://www.clippr.ai/api/footwear-background-replacement`,fd)
        .then((resp) => {
            if(resp.status == 200){
                this.setState({ 
                    url:resp.data.output_image_lres_url,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:resp.data.watermark_image,
                    isDownload: true,
                    urlDownload: resp.data.output_image_hres_url,
                    project_id: resp.data.project_id,
                    sku_id: resp.data.sku_id,
                    });
                
                window.localStorage.setItem("car-replacement-original-url", resp.data.input_image_hres_url)
                window.localStorage.setItem("car-replacement-edited-url",resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastEditedImage", resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastRawImage",resp.data.input_image_hres_url)
            }
        })

    }

    bulkUploadAPI = async (i) => {
        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("image", this.state.filesList[i].file)
        fd.append("is_color",this.state.checkColorEnhancement)
        //fd.append("prod_cat_id", "Automobile")
        //fd.append("auth_key", auth_key)
        fd.append("auth_key", auth_key)
        fd.append("marketplace_id","mark_dje41p")

        //fd.append("prod_sub_cat_id", "Sedan")
        fd.append("sku_name", this.state.sku_name)
        fd.append("project_name", this.state.project_name)
        fd.append("bg_color",this.state.bg_color=="white"?"https://storage.googleapis.com/spyne/AI/raw/e904fad2-727e-467a-aef5-89b3e1f06c99.jpg":"https://storage.googleapis.com/spyne/AI/raw/4efd2c9d-7723-428e-8b97-ab6ee1cb32b1.jpg"); 
        fd.append('source', 'BULKPROCESS')
        
        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesList: [
                ...this.state.filesList.slice(0, i),
                Object.assign({}, this.state.filesList[i], { status: 'Uploading' }),
                ...this.state.filesList.slice(i+1)
            ]
        })
        return axios.post(`https://www.clippr.ai/api/footwear-background-replacement`,fd)
        .then((uploadResponse) => {
            if(uploadResponse.status == 200){
                // this.setState({
                //     orgUrlList: [...this.state.orgUrlList,uploadResponse.data.input_image_hres_url]
                // })
                this.setState({
                    filesList: [
                        ...this.state.filesList.slice(0, i),
                        Object.assign({}, this.state.filesList[i], { 
                            status: 'Done', 
                            url: uploadResponse.data.input_image_hres_url,
                            imageId: uploadResponse.data.image_id, 
                            projectId: uploadResponse.data.project_id,
                            skuId: uploadResponse.data.sku_id,
                            outputImgUrl: uploadResponse.data.output_image_lres_url,
                            fullScreenOutput:uploadResponse.data.watermark_image,
                        }),
                        ...this.state.filesList.slice(i+1)
                    ]
                })

            }else if(uploadResponse.data.status == 400){
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.reload()
            }
            sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
            return Promise.resolve(uploadResponse)
        })
        .catch((err) => {
            this.setState({
                errorBulkFiles: [...this.state.errorBulkFiles,Object.assign({}, this.state.filesList[i], { 
                    status: 'Error', 
                })],
                filesList: [
                    ...this.state.filesList.slice(0, i),
                    Object.assign({}, this.state.filesList[i], { 
                        status: 'Error', 
                        
                    }),
                    ...this.state.filesList.slice(i+1)
                ]
            })
            return Promise.reject(err)

        })
        
    }

    handleUploadLimitModal = () => {
        this.setState({
            maxUpload: false
        })
    }

    emptyMultipleFileArray = () => {
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.setState({
            maxUpload: false
        })
    }
    updateMultipleImageList = (i) => {
        this.state.filesList.splice(i,1) 
        this.state.setFiles.splice(i,1)
        // console.log(this.state.filesList)
       this.forceUpdate()
    }

    handleBulkCreditModal = () => {
        this.setState({
            showBulkCreditModal: false
        })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        this.setState({
            project_name: e.target.value,
            sku_name:e.target.value,
            projectNameExists : false
        })
        sessionStorage.setItem("project_name", e.target.value);
        sessionStorage.setItem("sku_name", e.target.value);
    
    }
    handleProjectNameModal =()=>{
        let auth_key = this.cookie("auth_token")
        let user_id = this.cookie("userId")
        const fd = new FormData()
        fd.append("auth_key", auth_key);
        fd.append("user_id", user_id);
        fd.append("enterprise_id", "TaD1VC1Ko");
        fd.append("project_name",this.state.project_name)
        axios.post(`https://www.clippr.ai/api/v2/project/verify-project`,fd)
        .then((resp) => {
            if(resp.data.status == 404){
                this.setState({
                    showProjectNameModal: false,
                    projectNameExists: false
                })
            }else if(resp.data.status == 200){
                this.setState({
                    projectNameExists: true
                })
            }
            // console.log(resp)
        })
        
    }

    getProcessedImages = async () => {
        let auth_key = this.cookie('auth_token')
        if(this.state.availableCredit >= this.state.filesList.length){
            for(let i=0; i< this.state.filesList.length; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
            
        }else if(this.state.availableCredit < this.state.filesList.length){
            for(let i=0; i< this.state.availableCredit; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
        }
        this.setState({
            isZipReady: true
        })
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
        sessionStorage.setItem("isZipReady", true);
        this.props.history.push('/tech/cars/try')

    }

    handleRefundModal = () => {
        this.setState({
            showRefundModal: false
        })
        this.props.history.push('/tech/cars/try')
    }

    getRefundModal = (i) => {
        this.setState({
            refundImgUrl: this.state.filesList[i].outputImgUrl,
            refundOrgUrl: this.state.filesList[i].url,
            showRefundModal: true,
            refundImgId:this.state.filesList[i].imageId,
            refundNum: i
        })
    }
    updateEditedImageList = () => {
        this.state.filesList.splice(this.state.refundNum,1)
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
    }

    downloadImages = () => {
        this.setState({
            spinnerDownload: true
        })
        axios({
            method: "GET",
            url : "https://www.spyne.ai/download/shoot/ai-images",
            params : {
                skuId : this.state.filesList[0].skuId,
                projectId : this.state.filesList[0].projectId,
            }
        })
        .then(resp => {
            this.setState({
                downloadZipLink: resp.data
            })
            const a = document.createElement("a")
            a.href = resp.data
            a.download = true
            a.click()
            sessionStorage.setItem("downloadZipLink" , resp.data)
            this.setState({
                bulkDownloadDone: true,
                spinnerDownload: false
            })
            sessionStorage.setItem("bulkDownloadDone" , true)
        })
    }

    getUserCredit = async () => {
        let emailId = this.cookie("emailId")
        // if(){
        let auth_key = this.cookie("auth_token") 
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/credit/fetch",
                            // data : fd
                            params : {
                                // email_id : emailId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status == 200){
                            if(this.state.bulkDownloadDone == true){
                                return true
                            }else{
                                this.setState({
                                    availableCredit: resp.data.data.credit_available,
                                    allotedCredit : resp.data.data.credit_allotted,
                                    creditUsed: resp.data.data.credit_used
                                })
                                return false
                            }
                        }else if(resp.data.status == 400){
                            document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            window.sessionStorage.clear()
                            window.location.reload()
                        }
                        
                        
                    },
                    interval: 5000,
                })
    }

    applyChange = (e) => {
        // window.scrollTo(0,500) 
         this.myDivToFocus.current.scrollIntoView() 
        // window.scrollTo({
        //     bottom: this.myDivToFocus.current.offsetBottom,
        //     behavior: 'smooth'     
        // })
      }  
    render() {
        return (

            <><Helmet>
                <title>Replace Shoes Background Instantly | Shoe Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                <meta property="og:title" content="Replace Shoes Background Instantly | Shoe Photo Editor.Try Now" />
                <meta property="og:description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
            </Helmet>
            <div className={styles['bg-try']}>
                     {this.state.showInstruction?
                         <FootwearLandingModal
                         showInstruction={this.state.showInstruction}
                         hideModal={this.hideLandingModal}
                         productHunt={this.state.productHunt} />
                         :
                         null
                     }
                     
                    {this.state.filesList.length == 0 ?
                        <>
                            
                            <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({
                                        onClick: event => event.stopPropagation()
                                    })} className={styles['dropzone1']}>
                                        {this.state.dropBackground ?
                                            <section className={[styles['drop-box-text']]}>
                                                <div>Drop Image Anywhere</div>

                                            </section>
                                            : null}

                                            {/* Drawer section  */}
                                            <Drawer/> 

                                        <div className={[styles['car-main-wrapper'], ""].join(" ")} id="cars-try-modal">
                                            <div className={[styles[''], "container"].join(" ")}>

                                                <div style={{ marginTop: "5%" }} className={[styles['car-try-wrapper'], 'row'].join(' ')}>
                                                    <div className={[styles['car-left-section'], 'col-md-10 offset-md-1'].join(' ')}>

                                                        <div className={[styles['car-mid-section'], ''].join(' ')}>
                                                            <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/Group.png" />
                                                                            <p className={styles["para-11"]}>Drag and drop images here </p>
                                                                            <p className={styles["para-f1"]}>or <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/upload-image.png" /> SELECT IMAGE ON YOUR COMPUTER</span></p>
                                                                            <p className={styles['supports1']}>Supports jpeg, jpg, bmp & png </p>
                                                                            <input onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <section className={[styles['try-drag-drop'], 'd-block d-md-none'].join(' ')}>
                                                                <div className={styles['dropzone1']}>
                                                                    <div className={styles['choose-button']}>
                                                                        <input
                                                                            style={{ display: "none" }}
                                                                            type="file"
                                                                            onChange={this.multipleFileUpload}
                                                                            ref={(fileInput) => (this.fileInput = fileInput)}
                                                                        ></input>
                                                                        <button onClick={() => this.fileInput.click()} className="btn">Upload Image</button>
                                                                    </div>
                                                                </div>
                                                            </section>


                                                        </div>
                                                    </div>
                                                    <div className={[styles['try-images-area'], 'col-md-4 d-md-block d-none'].join(' ')}>
                                                        <div className={[styles['try-images-area'], 'row m-0'].join(' ')}>
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/foot1.png" />

                                                        </div>
                                                    </div>
                                                    <div className={[styles['try-images-area'], 'col-md-4 d-md-block d-none'].join(' ')}>
                                                        <div className={[styles['try-images-area'], 'row m-0'].join(' ')}>
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/foot2.png" />

                                                        </div>
                                                    </div>
                                                    <div className={[styles['try-images-area'], 'col-md-4 d-md-block d-none'].join(' ')}>
                                                        <div className={[styles['try-images-area'], 'row m-0'].join(' ')}>
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/foot3.png" />

                                                        </div>
                                                    </div>
                                                    <div className={[styles['try-images-area'], 'col-md-12 col-12 d-block d-md-none'].join(' ')}>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/car-sm.png" />
                                                    </div>

                                                </div>





                                                {/* <div className={[styles['car-try-wrapper'],'row mt-5'].join(' ')}>  */}
                                                {/* BulkUpload Section */}
                                                {/* <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['bulk-images-area'],'col-md-2'].join(' ')}>
                        <div className={[styles['bulk-image'],'row m-0'].join(' ')}>
                            <p>Kids.png</p>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </div>
                    </div>
                    <div className={[styles['proceed-btn'],'col-md-12 text-center mt-5'].join(' ')}>
                        <button>Proceed</button>
                    </div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <input onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                            <section className={[styles['custom-backgrounds'], ''].join(' ')}>
                                <div className={[styles[''], 'container'].join(' ')}>
                                    <div className={[styles['exterior-tabs'], 'row'].join(' ')}>
                                        <div className={[styles['title'], 'col-md-12'].join(' ')}>
                                            <h1><span>Market</span><span className={[styles['no-border']]}>place Ready</span> Background Presets</h1>
                                            <p>According to marketplaces like AJIO, Myntra, Amazon, Flipkart, Tatacliq</p>
                                            <Tabs className={[styles['virtual-tabs'], 'row'].join(' ')}>
                                                <TabList className={[styles['virtual-images'], styles['v-image'], 'col-md-2'].join(' ')}>
                                                    <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m1.png" /></Tab>
                                                    <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m2.png" /></Tab>
                                                    <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m3.png" /></Tab>
                                                    <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/m4.png" /></Tab>
                                                </TabList>


                                                <TabPanel className={[styles['virtual-tabs'], styles['tab-middle'], 'col-md-10 display-none'].join(' ')}>
                                                    <Tabs className={[styles['virtual-images-tabs'], 'row'].join(' ')}>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_1.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_2.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_4.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Amazon_3.jpg" />
                                                        </TabPanel>

                                                        <TabList className={[styles['virtual-images'], 'col-md-2'].join(' ')}>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                                <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[styles['abs-img']]} /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                        </TabList>
                                                    </Tabs>
                                                </TabPanel>

                                                <TabPanel className={[styles['virtual-tabs'], styles['tab-middle'], 'col-md-10 display-none'].join(' ')}>
                                                    <Tabs className={[styles['virtual-images-tabs'], 'row'].join(' ')}>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_1.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_2.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_4.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Myntra_3.jpg" />
                                                        </TabPanel>

                                                        <TabList className={[styles['virtual-images'], 'col-md-2'].join(' ')}>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                                <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[styles['abs-img']]} />
                                                            </Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                        </TabList>
                                                    </Tabs>
                                                </TabPanel>

                                                <TabPanel className={[styles['virtual-tabs'], styles['tab-middle'], 'col-md-10 display-none'].join(' ')}>
                                                    <Tabs className={[styles['virtual-images-tabs'], 'row'].join(' ')}>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_1.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_2.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_4.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Tata%20cliq_3.jpg" />
                                                        </TabPanel>

                                                        <TabList className={[styles['virtual-images'], 'col-md-2'].join(' ')}>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                                <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[styles['abs-img']]} /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                        </TabList>
                                                    </Tabs>
                                                </TabPanel>

                                                <TabPanel className={[styles['virtual-tabs'], styles['tab-middle'], 'col-md-10 display-none'].join(' ')}>
                                                    <Tabs className={[styles['virtual-images-tabs'], 'row'].join(' ')}>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_1.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_2.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_4.jpg" />
                                                        </TabPanel>
                                                        <TabPanel className={[styles['virtual-images'], 'col-md-10 display-none'].join(' ')}>
                                                            <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/background-presets/Flipkart_3.jpg" />
                                                        </TabPanel>

                                                        <TabList className={[styles['virtual-images'], 'col-md-2'].join(' ')}>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg1.png" />
                                                                <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/360-neetu/360-cars/Vector%20(3).png" className={[styles['abs-img']]} /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg3.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg2.png" /></Tab>
                                                            <Tab> <img className={[styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/bg5.png" /></Tab>
                                                        </TabList>
                                                    </Tabs>
                                                </TabPanel>


                                            </Tabs>

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className={styles['app-section']}>
                                <div className="container">
                                    <div className={[styles['mob-area'], 'row'].join(' ')}>
                                        <div className={[styles['mob-image'], 'col-md-7'].join(' ')}>
                                            <img alt="" className="img-fluid" style={{
                                                height: 'auto',
                                                width: '100%',
                                            }} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/11.png" />
                                        </div>

                                        <div className={[styles['why-use'], 'col-md-5'].join(' ')}>
                                            <h1>  <span className={[styles['no-border']]}>Get SpyneAI </span><span className={[styles['colored']]}>App</span></h1>
                                            <p>With Spyne App anyone can capture professional car images  </p>
                                            <p><a href="https://www.spyne.ai/tech/cars/demo/" target="_blank" className={[styles['request-demo']]}>Learn more</a>
                                                <a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[styles['request-demo2']]}>
                                                    <img alt="" className="img-fluid" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a></p>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </>
                        :
                        <div>
                            {this.state.filesList.length === 1 && this.state.isBulk === false ?
                                <FootwearBackgroundV2
                                    showInstruction={this.state.showInstruction}
                                    hideModal={this.hideLandingModal}
                                    selectedFile={this.state.selectedFile}
                                    fileSelectedHandler={this.fileSelectedHandler}
                                    fileSelectedHandlerNew={this.fileSelectedHandlerNew}
                                    handlePositionChange={this.handlePositionChange}
                                    handleTabSelection={this.handleTabSelection} //redundant
                                    handleUnshiftWalls={this.handleUnshiftWalls} //redundant
                                    orgUrl={this.state.filesList[0].url}
                                    handleId={this.handleId}
                                    customWallPosition={this.state.customWallPosition} //redundant
                                    handlewalls={this.handlewalls} //redundant
                                    customWallSet={this.customWallSet} //redundant
                                    editedUrl={this.state.url}
                                    customWall={this.state.customWall} //redundant
                                    handleToggle={this.handleToggle}
                                    toggleStatus={this.state.toggleStatus}
                                    handleAngle={this.handleAngle}
                                    imageSelected={this.state.imageSelected}
                                    handleClick={this.singleUploadAPI}
                                    handleModalApi={this.hitapiModal}
                                    isEditingFinish={this.state.isEditingFinish}
                                    handleDownload={this.updateCredit}
                                    creditUsed={this.state.creditUsed}
                                    availableCredit={this.state.availableCredit}
                                    creditAlloted={this.state.creditAlloted}
                                    defaultChecked={this.state.carShadow}
                                    planData={this.state.planData} //redundant
                                    floorData={this.state.floordata} //redundant
                                    wallData={this.state.walldata} //redundant
                                    spinner={this.state.spinner}
                                    toggleChange={this.state.toggleChange}
                                    background_id={this.state.background_id}
                                    dealerLogoFileHandler={this.dealerLogoFileHandler}
                                    handleLogoPosition={this.handleLogoPosition}
                                    logoUrl={this.state.logoUrl}
                                    logoPosition={this.state.logoPosition}
                                    hideLogo={this.state.hideLogo}
                                    askEmail={this.state.askEmail}
                                    backgroundUrl={this.state.backgroundEditedUrl}
                                    imageEdited={this.state.imageEdited}
                                    downloadPopup={this.downloadPopup}
                                    handleActivateButton={this.handleActivButton}
                                    activateButton={this.state.activateButton}
                                    downloadComplete={this.state.downloadComplete}
                                    handleDownloadComplete={this.handleDownloadComplete}
                                    isDownload={this.state.isDownload}
                                    // downloadPopup={this.downloadPopup}
                                    handleWindowTransparency={this.handleWindowTransparency}
                                    handelColorEnhancement={this.handelColorEnhancement}
                                    checkColorEnhancement={this.state.checkColorEnhancement}
                                    checkWindowTransparency={this.state.checkWindowTransparency}
                                    bgCredit={this.state.bgCredit}
                                    handleLogo={this.handleLogo}
                                    multipleFileUploadProject={this.multipleFileUploadProject}
                                    handleBgColor={this.handleBgColor}
                                    handlemarket={this.handlemarket} 
                                    handleColorEnhancement={this.handleColorEnhancement}
                                    checkColorEnhancement={this.state.checkColorEnhancement}/>
                                :
                                <FootwearBulkBackgroundV2
                                    setFiles={this.state.setFiles}
                                    filesList={this.state.filesList}
                                    multipleFileUpload={this.multipleFileUpload}
                                    multipleFileUploadProject={this.multipleFileUploadProject}
                                    handleId={this.handleId}
                                    editedUrl={this.state.url}
                                    handleToggle={this.handleToggle}
                                    toggleStatus={this.state.toggleStatus}
                                    handleAngle={this.handleAngle}
                                    imageSelected={this.state.imageSelected}
                                    handleClick={this.hitapi}
                                    handleModalApi={this.hitapiModal}
                                    isEditingFinish={this.state.isEditingFinish}
                                    handleDownload={this.updateCredit}
                                    creditUsed={this.state.creditUsed}
                                    availableCredit={this.state.availableCredit}
                                    allotedCredit={this.state.allotedCredit}
                                    defaultChecked={this.state.carShadow}
                                    planData={this.state.planData}
                                    spinner={this.state.spinner}
                                    toggleChange={this.state.toggleChange}
                                    background_id={this.state.background_id}
                                    dealerLogoFileHandler={this.dealerLogoFileHandler}
                                    handleLogoPosition={this.handleLogoPosition}
                                    logoUrl={this.state.logoUrl}
                                    logoPosition={this.state.logoPosition}
                                    hideLogo={this.state.hideLogo}
                                    askEmail={this.state.askEmail}
                                    backgroundUrl={this.state.backgroundEditedUrl}
                                    imageEdited={this.state.imageEdited}
                                    downloadPopup={this.downloadPopup}
                                    handleActivateButton={this.handleActivButton}
                                    activateButton={this.state.activateButton}
                                    downloadComplete={this.state.downloadComplete}
                                    handleDownloadComplete={this.handleDownloadComplete}
                                    isDownload={this.state.isDownload}
                                    handleWindowTransparency={this.handleWindowTransparency}
                                    handelColorEnhancement={this.handelColorEnhancement}
                                    checkColorEnhancement={this.state.checkColorEnhancement}
                                    checkWindowTransparency={this.state.checkWindowTransparency}
                                    bgCredit={this.state.bgCredit}
                                    handleLogo={this.handleLogo}
                                    maxUpload={this.state.maxUpload}
                                    handleUploadLimitModal={this.handleUploadLimitModal}
                                    emptyMultipleFileArray={this.emptyMultipleFileArray}
                                    updateMultipleImageList={this.updateMultipleImageList}
                                    creditVerify={this.creditVerify}
                                    showBulkCreditModal={this.state.showBulkCreditModal}
                                    handleBulkCreditModal={this.handleBulkCreditModal}
                                    showProjectNameModal={this.state.showProjectNameModal}
                                    handleProjectName={this.handleProjectName}
                                    handleBgColor={this.handleBgColor}
                                    handlemarket={this.handlemarket}
                                    handleProjectNameModal={this.handleProjectNameModal}
                                    notEnoughCreditModal={this.state.notEnoughCreditModal}
                                    enoughCreditModal={this.state.enoughCreditModal}
                                    zeroCreditModal={this.state.zeroCreditModal}
                                    hitUploadAPI={this.hitUploadAPI}
                                    uploadStarteds={this.state.uploadStarteds}
                                    imgStatusDone={this.state.imgStatusDone}
                                    imgStatusProcess={this.state.imgStatusProcess}
                                    imgStatusUpload={this.state.imgStatusUpload}
                                    showRefundModal={this.state.showRefundModal}
                                    handleRefundModal={this.handleRefundModal}
                                    userId={this.state.userId}
                                    refundImgUrl={this.state.refundImgUrl}
                                    getRefundModal={this.getRefundModal}
                                    refundOrgUrl={this.state.refundOrgUrl}
                                    refundImgId={this.state.refundImgId}
                                    refundNum={this.state.refundNum}
                                    updateEditedImageList={this.updateEditedImageList}
                                    downloadImages={this.downloadImages}
                                    downloadZipLink={this.state.downloadZipLink}
                                    isZipReady={this.state.isZipReady}
                                    bulkDownloadDone={this.state.bulkDownloadDone}
                                    getUserCredit={this.getUserCredit}
                                    auth_key={this.state.auth_key}
                                    bulkUploadAPI={this.bulkUploadAPI}
                                    emailId={this.state.emailId}
                                    project_name={this.state.project_name}
                                    projectNameExists={this.state.projectNameExists} 
                                    handleColorEnhancement={this.handleColorEnhancement}
                                    checkColorEnhancement={this.state.checkColorEnhancement}/>
                                    }
                        </div>}

                </div></>
        )
    }
}
export default Sentry.withProfiler(FootwearV2)