import React, { Component } from 'react';
import ProjectNameModal from '../Common/Modal/ProjectNameModal';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'
import BackgroundListModalBulk from '../Common/Modal/BackgroundListModalBulk'
import UploadLimitModal from '../Common/Modal/UploadLimitModal'
import RefundModal from '../Common/Modal/RefundModal';
import UploadCreditModal from './UploadCreditModal';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Spinner from '../Background/Spinner'
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';


class BulkUploadSku extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            orignalTab: "Original",
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
            activeButtonNew:"Original",
            themeColor:'#ff7700',
            isSpinner : false,

        }
        
        
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
            activeButtonNew:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }

      fileHandlerNew = () => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject()
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      redirectToDashboard = () => {
        let enterprise_id = this.cookie("enterpriseId")
        let user_id = this.cookie("userId")

        let URL = 'https://www.spyne.ai/dashboard/enterprises/projects?userId=' + user_id + '&enterpriseId='+ enterprise_id

        window.open(URL, '_blank');
      }

      getCSV = () => {
        let user_id = this.cookie("userId")
        this.setState({
            isSpinner: true
        })
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/feed/scheduler/connect-ftp",
            params : {
                user_id : user_id,
            }
        }).then(resp =>{
            this.setState({
                isSpinner: false
            })
            let csv_url = resp.data?.data?.output_url
            window.open(csv_url, '_blank');
        })
      }
      

    render()  { 
        
        const orgExteriorCars = this.props.filesList.map((carFile,i) => {
            if(this.props.filesList[i].imageCategory == 'Exterior' || this.props.filesList[i].imageCategory == 'Ecom' || this.props.filesList[i].imageCategory == 'Food'){
                return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                    <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>
            }
        })

        const orgInteriorCars = this.props.filesList.map((carFile,i) => {
            if(this.props.filesList[i].imageCategory == 'Interior'){
                return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                    <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>
            }
        })

        const orgInfoCars = this.props.filesList.map((carFile,i) => {
            if(this.props.filesList[i].imageCategory == 'Info'){
                return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                    <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>
            }
        })
        
        const backLeft = this.props.planData.length - 8

        const uploadPercent = (this.props.uploadCounter*100)/this.props.filesList.length
        const uploadPercentage = uploadPercent.toFixed(1)
        return ( 
            <div>
                {this.state.isSpinner ?
                    <Spinner />
                    :
                    null
                }
                
                <section className={[styles['perfect-output'],''].join(' ')}>
                 
                    <div className={[styles[''],''].join(' ')}>
                        {this.props.uploadStarteds ?
                            <div className={[styles[''],''].join(' ')}>

                                    {this.props.isZipReady ? 
                                        <div className={[styles['green-strip'],''].join(' ')}>
                                                <h6>Images Uploaded Successfully </h6>
                                            
                                        </div>
                                        :
                                        <div className={[styles['red-strip'],''].join(' ')}>
                                                <h6>Images are being uploaded, DO NOT CLOSE THIS TAB. </h6>
                                        </div>
                                    }
                                
                                {
                                    this.props.isZipReady ?
                                        <div className={[styles['download-card-gif-1'],'row m-0'].join(' ')}>
                                            <img alt="" src="https://storage.googleapis.com/spyne-website/whitelabel/success-gif.gif"/>
                                        </div>
                                        :
                                        <div className={[styles['progress-strip'],''].join(' ')}>
                                            <ProgressBar striped variant="success" now={this.props.uploadCounter} min={0} max={this.props.filesList.length}/>
                                            <h4>{uploadPercentage}% ({this.props.uploadCounter}/{this.props.filesList.length}) Uploaded</h4>
                                        </div>
                                }
                                
                                {/* <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['try-drag-drop-1'],''].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                    
                                                    <button className="">
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                        Upload New Project</button>
                                                </div>
                                                <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone> */}

                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                    <div className={styles['dropzone1']}>
                                        <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                            
                                            <button className="" onClick={this.fileHandlerNew}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                Upload New Project</button>
                                        </div>
                                        
                                    </div>
                                </section>

                                {
                                    this.props.isZipReady ?
                                    <div className="row justify-content-center">
                                        <div className={[styles['button-sec'],'col-md-8 d-flex'].join(' ')}>
                                            <div className="col-md-6">
                                                <h4 onClick={this.redirectToDashboard}>View in Dashboard</h4>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 onClick={this.getCSV}>Export Project as CSV</h4>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <h4>Go to V-Auto</h4>
                                            </div> */}
                                        </div>
                                    </div>
                                    : null
                                        
                                }
                                
                                {/* <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                    <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                <div {...getRootProps({})} className={styles['dropzone1']}>
                                                    <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload New Project</button>
                                                    </div>
                                                    <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    
                                    <ul className={[styles['change-tabs'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activeButtonNew == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                                <span>Original</span>
                                            </button>
                                        </li>
                                        
                                    </ul>
                                
                                    {

                                        this.state.activeButtonNew == "Original" ?
                                        
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        <div className={styles['container-fluid']}>
                                            <div className="row">
                                                
                                                <LightgalleryProvider
                                                    lightgallerySettings={{
                                                        "download": false,
                                                        "thumbnail": false,
                                                        "fullScreen": true,
                                                        "zoom": true
                                                    }}
                                                >
                                                    {orgCarsUrl}
                                                </LightgalleryProvider>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    : this.state.activeButtonNew == "Edited" ?
                                    
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        
                                    </div>
                                    :
                                    null
                                    }
                                
                                    
                                </div>
                                

                                <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                    <div className={styles['bulk-right-box']}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        
                                    </div>
                                    
                                        
                                        
                                        <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}> 
                                                        
                                            <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                                <div className={[styles['background-grid'],'col-md-12'].join(' ')}> 
                                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                                        
                                                    </div>
                                                    <div className={[styles['download-card-gif'],'row m-0'].join(' ')}>
                                                        {this.props.isZipReady  ?
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif"/>
                                                            :
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images-gif.gif"/>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                                    
                                        </div>
                                    </div>
                                </div> */}
                                <div>

                                </div>
                                

                                
                                
                            </div>
                        :
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                
                                {/* <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                    
                                                    <button className="">
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                        Upload New Project</button>
                                                </div>
                                                <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone> */}

                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                    <div className={styles['dropzone1']}>
                                        <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                            
                                            <button className="" onClick={this.fileHandlerNew}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                Upload New Project</button>
                                        </div>
                                        
                                    </div>
                                </section>
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                   
                                </ul>
                                {this.state.activeButton == "Original" ?
                                    <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                            {({getRootProps, getInputProps}) => (
                                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                    <div {...getRootProps({})} className={styles['dropzone1']}>
                                                        <div className={[styles['add-more'],''].join(' ')} >
                                                            
                                                            <button >+</button>
                                                            
                                                        </div>
                                                        <img className={[styles['more-text'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    
                                    : null
                                }
                                
                                {
                                    this.state.activeButton == "Original" ?
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        {this.props.productCategory=="automobiles" ?
                                        <div>
                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                        <h1>Exterior</h1>
                                                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                            {({getRootProps, getInputProps}) => (
                                                                <section className={[styles['add-section'],''].join(' ')}>
                                                                    <div {...getRootProps({})} className={styles['']}>
                                                                        <h6> (Images will be processed using AI)</h6>
                                                                            
                                                                        
                                                                        <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    

                                                        
                                                            {/* // <div className="row">
                                                            //     <div className={[styles['null-text'],'col'].join(' ')}>
                                                            //     No Exterior Images Uploaded yet
                                                            //     </div>
                                                            // </div> */}
                                                            <div className="row">
                                                                    <LightgalleryProvider
                                                                        lightgallerySettings={{
                                                                            "download": false,
                                                                            "thumbnail": false,
                                                                            "fullScreen": false,
                                                                            "zoom": false
                                                                        }}
                                                                    >
                                                                    
                                                                    {orgExteriorCars}
                                                                    

                                                                    </LightgalleryProvider>
                                                            </div> 
                                                    </div>
                                            
                                        </div>

                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['interior-null'],'row'].join(' ')}>
                                                <h1>Interior</h1>
                                                <Dropzone onDrop={acceptedFiles => this.fileHandlerInterior(acceptedFiles)} >
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className={[styles['add-section'],''].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['']}>
                                                                <h6> (Images will be <span>not</span> processed using AI)</h6>
                                                                
                                                                <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                <input  onChange={this.fileHandlerInterior} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                
                                                    {/* <div className="row">
                                                        <div className={[styles['null-text'],'col'].join(' ')}>
                                                            No Interior Images Uploaded yet
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <LightgalleryProvider
                                                            lightgallerySettings={{
                                                                "download": false,
                                                                "thumbnail": false,
                                                                "fullScreen": false,
                                                                "zoom": false
                                                            }}
                                                        >
                                                        
                                                        {orgInteriorCars}
                                                        

                                                        </LightgalleryProvider>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['info-null'],'row'].join(' ')}>
                                                <h1>Info Image</h1>
                                                <Dropzone onDrop={acceptedFiles => this.fileHandlerInterior(acceptedFiles)} >
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className={[styles['add-section'],''].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['']}>
                                                                <h6> (Images will be <span>not</span> processed using AI)</h6>
                                                                
                                                                <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                <input  onChange={this.fileHandlerInterior} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                
                                                    
                                                    <div className="row">
                                                        <LightgalleryProvider
                                                            lightgallerySettings={{
                                                                "download": false,
                                                                "thumbnail": false,
                                                                "fullScreen": false,
                                                                "zoom": false
                                                            }}
                                                        >
                                                        
                                                        {orgInfoCars}
                                                        

                                                        </LightgalleryProvider>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        :
                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                        <h1>All Images</h1>
                                                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                            {({getRootProps, getInputProps}) => (
                                                                <section className={[styles['add-section'],''].join(' ')}>
                                                                    <div {...getRootProps({})} className={styles['']}>
                                                                        <h6> (Images will be processed using AI)</h6>
                                                                            
                                                                        
                                                                        <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    

                                                        
                                                            {/* // <div className="row">
                                                            //     <div className={[styles['null-text'],'col'].join(' ')}>
                                                            //     No Exterior Images Uploaded yet
                                                            //     </div>
                                                            // </div> */}
                                                            <div className="row">
                                                                    <LightgalleryProvider
                                                                        lightgallerySettings={{
                                                                            "download": false,
                                                                            "thumbnail": false,
                                                                            "fullScreen": false,
                                                                            "zoom": false
                                                                        }}
                                                                    >
                                                                    
                                                                    {orgExteriorCars}
                                                                    

                                                                    </LightgalleryProvider>
                                                            </div> 
                                                    </div>
                                            
                                        </div>
                                        }
                                    
                                </div>
                                : 
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    
                                    
                                </div>
                                
                                
                                }
                                
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    {this.props.productCategory=="automobiles" ?
                                    <div>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}>
                                        <h6 className={[styles['bg-title'],''].join(' ')} >Backgrounds </h6>
                                                    
                                        <div className={styles['backgrounds-grid']}>
                                                    <div className={this.props.background_id == 9999 && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} >
                                                            <div className={styles['overlay-pos']} onClick={()=>this.handleBackground(9999,1,false)}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            
                                                            </div>
                                                            <p className={[styles['back-name'],''].join(' ')}>Manual Editing</p>
                                                    </div>
                                                    {
                                                    this.props.planData.slice(0,7).map((e,i) => {
                                                        return (
                                                        <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                            <img alt="" src={e.imageUrl}/>
                                                            <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                            <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.bgName}</p>
                                                            <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            </div>
                                                        </div>

                                                        )
                                                    })
                                                    }
                                                    {this.props.planData.length > 8 ?
                                                        <div className={styles['inactive-background-sec']} >
                                                            <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                            </div>
                                                            
                                                        </div>

                                                        :
                                                        null
                                                    }
                                                </div>
                                                
                                    </div>
                                    <div className={styles['slider-button']}>
                                        
                                    </div>
                                    </div>
                                    :
                                    <div className={[styles['credits-available2'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                            
                                        </div>
                                        <div className={styles["uploadImg"]}>
                                            <svg  width="88" height="83" viewBox="0 0 88 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C36.7413 0.0227618 29.7301 2.64074 24.233 7.381C20.02 11.011 16.9675 15.741 16.181 20.4875C6.963 22.5225 0 30.5525 0 40.249C0 51.513 9.394 60.5 20.7955 60.5H41.25V31.3885L29.447 43.197C28.9306 43.7134 28.2303 44.0035 27.5 44.0035C26.7697 44.0035 26.0694 43.7134 25.553 43.197C25.0366 42.6806 24.7465 41.9803 24.7465 41.25C24.7465 40.5197 25.0366 39.8194 25.553 39.303L42.053 22.803C42.3085 22.5469 42.6119 22.3437 42.946 22.2051C43.2801 22.0664 43.6383 21.9951 44 21.9951C44.3617 21.9951 44.7199 22.0664 45.054 22.2051C45.3881 22.3437 45.6915 22.5469 45.947 22.803L62.447 39.303C62.9634 39.8194 63.2535 40.5197 63.2535 41.25C63.2535 41.9803 62.9634 42.6806 62.447 43.197C61.9306 43.7134 61.2303 44.0035 60.5 44.0035C59.7697 44.0035 59.0694 43.7134 58.553 43.197L46.75 31.3885V60.5H69.784C79.761 60.5 88 52.635 88 42.7515C88 33.7535 81.169 26.422 72.413 25.1845C71.0765 10.9945 58.795 0 44 0ZM41.25 79.75V60.5H46.75V79.75C46.75 80.4793 46.4603 81.1788 45.9445 81.6945C45.4288 82.2103 44.7293 82.5 44 82.5C43.2707 82.5 42.5712 82.2103 42.0555 81.6945C41.5397 81.1788 41.25 80.4793 41.25 79.75Z" fill="#FF7700"/>
                                            </svg></div>
                                        
                                        
                                    </div>
                                    }





                                    
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button onClick={this.props.creditVerify}>Upload Images <span style={{"fontSize" : "14px"}}>({this.props.totalImageCost} Credits)</span>  </button>
                                    </div>
                                    <BackgroundListModalBulk 
                                            handleBackground = {this.handleBackground}
                                            background_id={this.props.background_id} 
                                            planData={this.props.planData} hideList={this.hideList} 
                                            showBackgroundList={this.state.showBackgroundList} 
                                            orgUrl={this.props.orgUrl}
                                            applyChange={this.applyChange}
                                            creditVerify = {this.props.creditVerify}/>
                                    <UploadLimitModal 
                                        maxUpload = {this.props.maxUpload}  
                                        handleUploadLimitModal = {this.props.handleUploadLimitModal}
                                        emptyMultipleFileArray = {this.props.emptyMultipleFileArray}
                                        />
                                    <UploadCreditModal 
                                        showBulkCreditModal = {this.props.showBulkCreditModal}  
                                        handleBulkCreditModal = {this.props.handleBulkCreditModal}
                                        notEnoughCreditModal={this.props.notEnoughCreditModal}
                                        enoughCreditModal={this.props.enoughCreditModal}
                                        zeroCreditModal={this.props.zeroCreditModal}
                                        hitUploadAPI={this.props.hitUploadAPI}
                                        filesList={this.props.filesList}
                                        availableCredit={this.props.availableCredit}
                                        getUserCredit={this.props.getUserCredit}
                                        totalImageCost={this.props.totalImageCost}
                                        />
                                    <ProjectNameModal 
                                        showProjectNameModal = {this.props.showProjectNameModal}  
                                        handleProjectNameModal = {this.props.handleProjectNameModal}
                                        handleProjectName={this.props.handleProjectName}
                                        project_name={this.props.project_name}
                                        projectNameExists = {this.props.projectNameExists}
                                        />
                                </div>
                            </div>
                        </div>
                            
                        }
                        
                    </div>
                </section>
            </div>
         );
    }
}
 
export default BulkUploadSku;