import React, { Component } from 'react'
import Axios from 'axios' 
import LoginModal from './LoginModal'
import Styles from '../../Css/Pricing/Pricing.module.css'
import  {Modal} from 'react-bootstrap'
import {newLogin,verifyOTP,insertFreeCredit, cookie} from '../Common/Utils'
import queryString from 'query-string'
import Spinner from '../Spinner'
import alertify from 'alertifyjs'
import LandingModal from '../Common/Modal/LandingModal'


export default class EcomPricing extends Component {
    constructor(props){
        super(props)
        this.state={
            plans:[],
            planSelectionId:38,
            subscrptionPlanSelectionId:'124',
            planCreditId:'cred_123jk26',
            planCredits:20000,
            planPrice:3600,
            planPricePerImage:'0.18',
            planSelectionIdPremium:3,
            rackPlanPricePerImage:'0.36',
            planCreditIdPremium:'',
            planCreditsPremium:'',
            planPricePremium:'',
            planPricePerImagePremium:'',
            loginStatus:true,
            email:window.localStorage.getItem("clippr_user_email"),
            otp:'',
            otpVerification:true,
            verification:false,
            userId:'',
            razorpayOrderId:'',
            baseCredit:'',
            creditAvailable:'',
            creditUsed:'',
            creditAlloted:'',
            loader:false,
            emailValidation:true,
            moreContent:false,
            showMailPopup: false,
            redirectUrl : '',
            subscriptionId:'',
            suscriptionPlans:[],
            SubplanSelectionId:37,
            SubplanCreditId:'cred_123jk25',
            SubplanCredits:'20000',
            SubplanPrice:2400,
            SubplanPricePerImage:'0.12',
            SubrackPlanPricePerImage:'0.24',
            isLoading:false,
            showInstruction: false,
            currency:''
        }
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    componentDidMount = () => {
        this.setState({loader:true})
        const script = document.createElement("script");
        script.src="https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        let clippruserId = this.cookie("userId")
        let auth_key = this.cookie("auth_token")
        this.setState({
            userId:clippruserId
        })
        Axios({
            'method':'GET',
            'url':'https://www.clippr.ai/api/v2/payment/fetch-pricing_plans?auth_key=7be1ae85-514d-4f8d-a51b-a72bee0ba3c3&category_id=cat_Ujt0kuFxY',
        })
        .then((res) => {
            this.setState({
                plans:res.data.data['non_recuring'],
                suscriptionPlans:res.data.data['recuring'],
                loader:false,
                currency:res.data.data.currency,
                SubplanPricePerImage: Number((this.state.SubplanPricePerImage * res.data.data.rate).toFixed(2)),
                SubrackPlanPricePerImage:Number((this.state.SubrackPlanPricePerImage * res.data.data.rate).toFixed(2)),
                rackPlanPricePerImage:Number((this.state.rackPlanPricePerImage * res.data.data.rate).toFixed(2)),
                planPricePerImage:Number((this.state.planPricePerImage *  res.data.data.rate).toFixed(2)),
            })
        })
        if(auth_key){
            Axios({
                'method':'GET',
                'url':"https://www.clippr.ai/api/v2/credit/fetch",
                "params":{
                    'auth_key':auth_key
                }
            })
            .then((res) => {
               this.setState({
                   baseCredit:res.data.data.credit_available,
                   creditUsed:res.data.data.credit_used,
                   creditAlloted:res.data.data.credit_allotted,
                   creditAvailable:res.data.data.credit_available
               })
            })
            .catch((err) => {
               
            })
        }
       
    }
    handlePayment= (e,id) => { 
     this.setState({
        planSelectionId:e.id,
        planCreditId:e.creditId,
        planCredits:e.credits,
        planPrice:e.price,
        planPricePerImage:e.pricePerImage,
        rackPlanPricePerImage:e.rackPricePerImage
     })
    //  console.log(e)
    }
    handlePaymentSubscription= (e,id) => { 
        this.setState({
           SubplanSelectionId:e.id,
           SubplanCreditId:e.creditId,
           SubplanCredits:e.credits,
           SubplanPrice:e.price,
           SubplanPricePerImage:e.pricePerImage,
           SubrackPlanPricePerImage:e.rackPricePerImage
        })
       //  console.log(e)
       }
    handlePaymentPremium= (e,id) => { 
        this.setState({
           planSelectionIdPremium:id,
           planCreditIdPremium:e.creditId,
           planCreditsPremium:e.credits,
           planPricePremium:e.price,
           planPricePerImagePremium:e.pricePerImage
        })
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    


    purchasePlan = () => {
        let userId = this.cookie("userId")
        let auth_key = this.cookie("auth_token")
        // let userId = 'KMv4iX4dM'
        this.handleQueryString()
        if(auth_key){
            this.setState({
                loginStatus:true
            })
            this.razorpayFunction()
            Axios({
                'method':'GET',
                'url':"https://www.clippr.ai/api/v2/credit/fetch",
                "params":{
                    'auth_key':auth_key
                }
            })
            .then((res) => {
               this.setState({
                   baseCredit:res.data.data.credit_available,
                   creditUsed:res.data.data.credit_used,
                   creditAlloted:res.data.data.credit_allotted,
                   creditAvailable:res.data.data.credit_available
               })
            })
        }
        else{
            this.setState({
                loginStatus:false,
                showInstruction: true,
            })
            // this.razorpayFunction()
        }
    }

    purchaseSubscriptionPlan = async() => {
        let auth_key = this.cookie("auth_token")
        let plan_id = this.state.SubplanSelectionId

        if(auth_key){
            this.setState({
                loginStatus:true,
                // isLoading:!this.state.isLoading
            })
            
            Axios({
                'method':'POST',
                'url':"https://www.clippr.ai/api/v2/transactions/subscription",
                "params":{
                    'auth_key':auth_key,
                    'plan_id': plan_id
                }
            })
            .then((res) => {
                if(res.data.data) {
                    this.setState({
                        subscriptionId : res.data.data.subscription_id,
                    })
                    this.razorpayFunctionSubs(res.data.data.subscription_id)
                } else {
                    this.setState({
                        isLoading:!this.state.isLoading
                    }, () => {
                        alertify.success('Something went wrong')   
                    })
                }
            }).catch(err => {
                this.setState({
                    isLoading:!this.state.isLoading
                }, () => {
                    alertify.success('Something went wrong')   
                })     
            })
           
        }
        else{
            this.setState({
                loginStatus:false,
                showInstruction:true
            })
            //this.razorpayFunction()
        }
        
    }

    purchasePremiumPlan = () => {
        let userId = this.cookie("userId")
        if(userId){
            this.setState({
                loginStatus:true
            })
            this.razorpayFunctionPremium()
        }
        else{
            this.setState({
                loginStatus:false,
                showInstruction:true
            })
        }
    }


    handleModal = () => {
        if(this.state.loginStatus){
            const link = this.props.editedImage;
            window.open(link, "_blank");
        }
        else{
            this.setState({
                show:!this.state.show,
                showModal:true
            })
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    sendOTP = (e) => {
        e.preventDefault();
        let data = {
            'emailId':this.state.email
        }
        newLogin(data)
        .then((success) => {
            this.setState({
                otpScreen:true
            })
         }).catch((err) => {})
        // let email = this.state.email
        // var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // if(email.match(mailformat)){
        // Axios({
        //     'method':'GET',
        //     "url":'https://www.spyne.ai/credit-user/register-vendor',
        //     "params":{
        //         'emailId':this.state.email
        //     }
        // })
        // .then((res) => {
        //    this.setState({
        //        otpScreen:true
        //    })
        // })
        // .catch((err) => {})
        // }
        // else
        // {
        //     this.setState({
        //         emailValidation:false
        //     })
        // }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":this.state.email,
                'otp':this.state.otp
            },
            "changeOrigin": true,
        })
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                    verification:res.data
                })
                document.cookie = `emailId=${this.state.email}`;
                window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
        .catch((err) => {})
    }

    razorpayFunction = () =>{
        let random = Math.round((Math.pow(36, 7 + 1) - Math.random() * Math.pow(36, 7))).toString(36).slice(1);
        let auth_key = this.cookie('auth_token')
        Axios({
            "method":"POST",
            "url":"https://payment.spyne.ai/order/credit/",
            data:{
                "planId":this.state.planSelectionId,
                "userId":this.state.userId,
                // "userId": 'KMv4iX4dM',
                "orderId":"ord_clippr_"+ random,
                // "planOrigCost":this.state.planPrice,
                "planOrigCost":this.state.planPrice,
                "planDiscount":0.0,
                // "planFinalCost":1,
                "planFinalCost":this.state.planPrice,
                "subscriptionType":"New",
                "active":false,
                "currency":this.state.currency,
                "status":"CREATED"
            }
        })
        .then((res) => {
            this.setState({
                razorpayOrderId:res.data.razorpayOrderId
            })
            let userId = this.state.userId
            let creditId = this.state.planCreditId
            let planCredits = this.state.planCredits
            let oldCredit = this.state.baseCredit
            let baseCredit = this.state.baseCredit
            let creditAlloted = this.state.creditAlloted
            let creditAvailable = this.state.creditAvailable
            let creditUsed = this.state.creditUsed
            let planPrice = this.state.planPrice
            let description = planCredits + " Credits"
            var options = {
                "key" : "rzp_live_IHhXp2aSS9Ys4p", // Enter the Key ID generated from
                // the Dashboard
                "amount" : planPrice, // Amount is in currency subunits.
                // Default currency is USD. Hence, 50000
                // refers to 50000 paise
                "currency" : this.state.currency,
                "name" : "SpyneAI",
                "description" : description ,
                "image" : "https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/logo-img.png",
                "order_id" : res.data.razorpayOrderId, // This is a sample Order ID. Pass
                "handler" : (response) => {
                    // Axios({
                    //     "method":"GET",
                    //     "url":"https://www.spyne.ai/credit/insert-user",
                    //     'params':{
                    //         "userId":userId,
                    //         // "userId": 'KMv4iX4dM',
                    //         "creditId":creditId,
                    //         "creditAlloted":planCredits,
                    //         "creditUsed":0,
                    //         "creditAvailable":planCredits
                    //     }
                    // })
                    // .then((res) => {
                    //     // window.location.reload(false)
                    // //     window.localStorage.setItem("latestCreditId", creditId)
                    //        return Axios({
                    //             "method":"GET",
                    //             "url":"https://www.spyne.ai/credit/update-total-credit",
                    //             'params':{
                    //                 "userId":userId,
                    //                 "creditAlloted":Number(planCredits + creditAlloted),
                    //                 "creditUsed":Number(creditUsed),
                    //                 "creditAvailable":Number(planCredits + creditAvailable)
                    //             }
                    //         })
                            
                    // })
                    const fd = new FormData()
                    fd.append("auth_key", auth_key)
                    fd.append("credit_id",creditId)
                    fd.append("credit_alotted",planCredits)
                    fd.append("razorpay_order_id",this.state.razorpayOrderId)
                    // fd.append("credit_available",this.state.availableCredit - price)
                    // fd.append("credit_used",this.state.creditUsed + price)
                    Axios.post(`https://www.clippr.ai/api/v2/credit/insert`,fd)
                    .then((cred) => {
                        if(this.state.redirectUrl == 'cars'){
                            window.opener=null;
                            window.open('','_self');
                            window.close();
                            // this.props.history.push('/tech/cars/try')
                        }else{
                             window.location.replace("https://www.spyne.ai/tech/cars/try")
                        }
                    })
                },
                "prefill" : {
                    "name" : 'Clippr',
                    "email" : this.state.email,
                    // "contact" : 'vendorNumber'
                },
                "notes" : {
                    "address" : "Razorpay Corporate Office"
                },
                "theme" : {
                    "color" : "#008AC7"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        })
       
    }

    razorpayFunctionSubs = (e) =>{
        let auth_key = this.cookie('auth_token')
        var options = {
            "key": "rzp_live_IHhXp2aSS9Ys4p",
            "subscription_id": e,
            "name": "My Billing Label",
            "currency": this.state.currency,
            "description": "Auth txn for sub_00000000000001",
            "handler": (response) => {
              fetch("https://www.clippr.ai/api/v2/transactions/payment/subscription", {
                'method': 'post',
                'headers': {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                },
                'body': JSON.stringify({...response,auth_key})
              }).then(res => {
                    console.log(res)
                    this.setState({
                        isLoading: !this.state.isLoading
                    },() => {
                    alert("Payment is successful!!! Redirecting to cars try page....")
                    window.location.replace("https://www.spyne.ai/tech/cars/try")
                    })
                });
              // console.log(response);
            }
          };
          var rzp1 = new window.Razorpay(options);
        //   document.getElementById('rzp-button').onclick = function (e) {
            rzp1.open();
        //   }
       
    }


    razorpayFunctionPremium = () =>{
        let random = Math.round((Math.pow(36, 7 + 1) - Math.random() * Math.pow(36, 7))).toString(36).slice(1);
        Axios({
            "method":"POST",
            "url":"https://payment.spyne.ai/order/credit/",
            data:{
                "planId":this.state.planSelectionIdPremium,
                "userId":this.state.userId,
                "orderId":"ord_clippr_"+ random,
                "planOrigCost":this.state.planPrice,
                // "planOrigCost":1,
                "planDiscount":0.0,
                // "planFinalCost":this.state.planPricePremium,
                "planFinalCost":1,
                "subscriptionType":"New",
                "active":false,
                "currency":"INR",
                "status":"CREATED"
            }
        })
        .then((res) => {
            this.setState({
                razorpayOrderId:res.data.razorpayOrderId
            })
            let userId = this.state.userId
            let creditId = this.state.planCreditIdPremium
            let planCredits = this.state.planCreditsPremium
            let oldCredit = this.state.baseCredit
            let baseCredit = this.state.baseCredit
            let creditAlloted = this.state.creditAlloted
            let creditAvailable = this.state.creditAvailable
            let creditUsed = this.state.creditUsed
            let planPrice = this.state.planPricePremium
            let description = planCredits + " Credits"
            var options = {
                "key" : "rzp_live_IHhXp2aSS9Ys4p", // Enter the Key ID generated from
                // the Dashboard
                // "amount" : planPrice, // Amount is in currency subunits.
                "amount":1,
                // Default currency is USD. Hence, 50000
                // refers to 50000 paise
                "currency" : 'INR',
                "name" : "Clippr",
                "description" : description ,
                "image" : 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/logo-img.png',
                "order_id" : res.data.razorpayOrderId, // This is a sample Order ID. Pass
                // the `id` obtained in the response
                // of Step 1
                "handler" : function(response) {
                    Axios({
                        "method":"GET",
                        "url":"https://www.spyne.ai/credit/insert-user",
                        'params':{
                            "userId":userId,
                            "creditId":creditId,
                            "creditAlloted":planCredits,
                            "creditUsed":0,
                            "creditAvailable":planCredits
                        }
                    })
                    .then((res) => {
                        // window.location.reload(false)
                    //     window.localStorage.setItem("latestCreditId", creditId)
                        Axios({
                            "method":"GET",
                            "url":"https://www.spyne.ai/credit/update-total-credit",
                            'params':{
                                "userId":userId,
                                "creditAlloted":Number(planCredits + creditAlloted),
                                "creditUsed":Number(creditUsed),
                                "creditAvailable":Number(planCredits + creditAvailable)
                            }
                        })
                        .then((cred) => window.location.replace("https://www.clippr.ai/try/dashboard"))
                    })
                },
                "prefill" : {
                    "name" : 'Clippr',
                    "email" : this.state.email,
                    // "contact" : 'vendorNumber'
                },
                "notes" : {
                    "address" : "Razorpay Corporate Office"
                },
                "theme" : {
                    "color" : "#008AC7"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        })
       
    }

    handleMoreContent = (e) => {
        e.preventDefault()
        this.setState({
            moreContent:!this.state.moreContent
        })
    }

    sendMailPopup = () => {
        this.setState({
            showMailPopup: true
        })
    }

    handlePriceModal = () => {
        this.setState({
            showMailPopup: false
        })
    }

    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }

    handleQueryString = () => {
        let queries = queryString.parse(this.props.location.search)
        // console.log(queries)
        this.setState({redirectUrl : queries.redirect_url})
        // console.log(this.state.redirectUrl)
      }

   
    
    render() {
        let plans = this.state.plans
        let subscriptionPlans = this.state.suscriptionPlans;
        return (
            !this.state.loader ?
            <div className={[Styles['pricing-section'],' '].join(' ')}>
                {this.state.isLoading ? <Spinner /> : '' }
                <div className={[Styles[''],'container'].join(' ')}>
                    {/* <div className="get-5-banner-text">{this.state.baseCredit == '' ? "Get 5 free Credits" : `Available Credits ${this.state.baseCredit}`}</div> */}
                    <div className={[Styles['pricing-title'],' '].join(' ')}>
                        <h2>Introductory Pricin<span>g for Full </span> Resolution Images</h2>
                        <p><span>Download Full Resolution Images </span></p>
                        <h3>1 image = 1 Credit</h3>
                    </div>
                    <div className={[Styles['pricing-area'],'row'].join(' ')}>
                        <div className={[Styles['pricing-cols'],'col-lg-7 col-12'].join(' ')}>
                            <div className={[Styles['pricing-single'],'row'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/logo-img.png"/>
                                <h6 className="pay-as-you-go">Subs<span>cription</span> Plan</h6>
                                {this.state.currency == 'AED' ?
                                    <h2><strike>&#x62f;&#x2e;&#x625; {this.state.SubrackPlanPricePerImage}</strike> &#x62f;&#x2e;&#x625; {this.state.SubplanPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'USD' ?
                                    <h2><strike>$ {this.state.SubrackPlanPricePerImage}</strike> $ {this.state.SubplanPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'GBP' ?
                                    <h2><strike>&#163; {this.state.SubrackPlanPricePerImage}</strike> &#163; {this.state.SubplanPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'CAD' ?
                                    <h2><strike>$ {this.state.SubrackPlanPricePerImage}</strike> $ {this.state.SubplanPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'INR' ?
                                    <h2><strike>₹ {this.state.SubrackPlanPricePerImage}</strike> ₹ {this.state.SubplanPricePerImage} <span>/ Image</span></h2>
                                    : null
                                }
                                
                                <div className="row price-container">
                                    <div className="col-md-12 main-price-container">
                                        

                                        
                                    {
                                    subscriptionPlans.length !== 0 ?
                                    subscriptionPlans.map((e,id) => {
                                        let total_price = 13.25 * e.credits
                                        let saving = total_price - e.price
                                        if(e.credits >= 100000) {
                                            return  <div className={[Styles['input-box-div'],'row'].join(' ')} onClick={() => this.sendMailPopup()}>
                                                    <div className={[Styles['credit-radio'],'col-md-5 col-5'].join(' ')}>
                                                        <input type="radio" name="credits" checked={this.state.SubplanSelectionId === e.id ? true : false}></input>
                                                    {/* </div>
                                                    <div className="col-4">  */}
                                                        <p className="m-0 credit">{e.credits} Credits/month
                                                            {/* <span>Save 20%</span> */}
                                                        </p>
                                                    </div>
                                                    <div  className={[Styles['price-rate-cr'],'col-3 col-md-4'].join(' ')}> 
                                                    {this.state.currency == 'AED' ?
                                                        <p className="m-0 credit"><strike>&#x62f;&#x2e;&#x625; {e.rackPrice}</strike> <span>&#x62f;&#x2e;&#x625; {e.price}</span></p>
                                                        : this.state.currency == 'USD' ?
                                                        <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                        : this.state.currency == 'GBP' ?
                                                        <p className="m-0 credit"><strike>&#163; {e.rackPrice}</strike> <span>&#163; {e.price}</span></p>
                                                        : this.state.currency == 'CAD' ?
                                                        <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                        : this.state.currency == 'INR' ?
                                                        <p className="m-0 credit"><strike>₹ {e.rackPrice}</strike> <span>₹ {e.price}</span></p>
                                                        : null
                                                    }
                                                        {/* <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p> */}
                                                    </div>
                                                    <div className={[Styles['pricing-list-single'],'col-4 col-md-3'].join(' ')}>
                                                    {this.state.currency == 'AED' ?
                                                        <p className={Styles['save-price']}> &#x62f;&#x2e;&#x625; {e.pricePerImage}/ image
                                                        {/* <span>₹ {e.price}</span> */}
                                                        </p>
                                                        : this.state.currency == 'USD' ?
                                                        <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                            {/* <span>₹ {e.price}</span> */}
                                                        </p>
                                                        : this.state.currency == 'GBP' ?
                                                        <p className={Styles['save-price']}> &#163; {e.pricePerImage}/ image
                                                            {/* <span>₹ {e.price}</span> */}
                                                        </p>
                                                        : this.state.currency == 'CAD' ?
                                                        <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                            {/* <span>₹ {e.price}</span> */}
                                                        </p>
                                                        : this.state.currency == 'INR' ?
                                                        <p className={Styles['save-price']}> ₹ {e.pricePerImage}/ image
                                                            {/* <span>₹ {e.price}</span> */}
                                                        </p>
                                                        : null
                                                    }
                                                        {/* <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                            <span>₹ {e.price}</span>
                                                        </p> */}
                                                    </div>
                                                </div>

                                        }else{
                                            return  <div className={[Styles['input-box-div'],'row'].join(' ')} onClick={() => this.handlePaymentSubscription(e,id)}>
                                                    <div className={[Styles['credit-radio'],'col-md-5 col-5'].join(' ')}>
                                                        <input type="radio" name="credits" checked={this.state.SubplanSelectionId === e.id ? true : false}></input>
                                                    {/* </div>
                                                    <div className="col-4">  */}
                                                        <p className="m-0 credit">{e.credits} Credits/month
                                                            {/* <span>Save 20%</span> */}
                                                        </p>
                                                    </div>
                                                    <div  className={[Styles['price-rate-cr'],'col-3 col-md-4'].join(' ')}> 
                                                    {this.state.currency == 'AED' ?
                                                        <p className="m-0 credit"><strike>&#x62f;&#x2e;&#x625; {e.rackPrice}</strike> <span>&#x62f;&#x2e;&#x625; {e.price}</span></p>
                                                        : this.state.currency == 'USD' ?
                                                        <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                        : this.state.currency == 'GBP' ?
                                                        <p className="m-0 credit"><strike>&#163; {e.rackPrice}</strike> <span>&#163; {e.price}</span></p>
                                                        : this.state.currency == 'CAD' ?
                                                        <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                        : this.state.currency == 'INR' ?
                                                        <p className="m-0 credit"><strike>₹ {e.rackPrice}</strike> <span>₹ {e.price}</span></p>
                                                        : null
                                                    }
                                                    </div>
                                                    <div className={[Styles['pricing-list-single'],'col-4 col-md-3'].join(' ')}>
                                                        {this.state.currency == 'AED' ?
                                                            <p className={Styles['save-price']}> &#x62f;&#x2e;&#x625; {e.pricePerImage}/ image
                                                            {/* <span>₹ {e.price}</span> */}
                                                            </p>
                                                            : this.state.currency == 'USD' ?
                                                            <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                            </p>
                                                            : this.state.currency == 'GBP' ?
                                                            <p className={Styles['save-price']}> &#163; {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                            </p>
                                                            : this.state.currency == 'CAD' ?
                                                            <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                            </p>
                                                            : this.state.currency == 'INR' ?
                                                            <p className={Styles['save-price']}> ₹ {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                            </p>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        
                                    })
                                    : null
                                }
                                        
                                    </div>
                                </div>
                                
                                <Modal
                                        show={this.state.showMailPopup}
                                        dialogClassName="modal-90w"
                                        aria-labelledby="example-custom-modal-styling-title"
                                        size='md'
                                        centered
                                        
                                    >
                        
                                    <Modal.Body className={[Styles['pricing-modal']]}>
                                            <div className={[Styles['pricing-modal-cross']]}>
                                                <img onClick={this.handlePriceModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                            </div>
                                            <div className={[Styles['pricing-modal-text']]}>
                                                <h4>To purchase enterprise pricing plans <a style={{color:"#ff7700"}} href="mailto:product@spyne.ai"></a></h4>
                                                <a href="mailto:product@spyne.ai">
                                                    <button >Contact Us</button>
                                                </a>
                                                
                                            </div>
                                    </Modal.Body>
                                

                                </Modal>


                                {/* <div>
                                    <p className="rate"><span >{this.state.planPricePerImage}</span>  per credit</p>
                                </div> */}
                                {/* <div className="mx-0 price-container">
                                    {
                                        plans.length !== 0 ?
                                        plans.map((e,id) => {
                                            let total_price = 13.25 * e.credits
                                            let saving = total_price - e.price
                                            if(e.planType == "Basic"){
                                            return(
                                                <div className="row main-price-container" onClick={() => this.handlePayment(e,id)}>
                                                    {
                                                        this.state.planSelectionId == id ? 
                                                        <div className="col-4 pl-5 mt-2">
                                                            <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/noun_Check_2422421.svg"></img>
                                                        </div>
                                                        :
                                                        <div className="col-4 pl-5 mt-2">
                                                            <p>{id}</p>
                                                        </div>
                                                    } 
                                                    <div className="col-md-1 col-1">
                                                            <input type="radio" checked={this.state.planSelectionId == id ? true : false}></input>
                                                    </div>
                                                    
                                                    <div className={this.state.planSelectionId ==id ? "col-4" : "col-4"}>
                                                        <p className="m-0 total_credit">{e.credits}</p>
                                                        <p className="m-0 credit">Credit</p>
                                                    </div>
                                                    <div className={this.state.planSelectionId ==id ? [Styles['pricing-list-single'],'col-3'].join(' '): [Styles[''],'col-3'].join(' ')}>
                                                        <p className={saving > 0 ? Styles['save-price'] : null }>₹ {e.price}</p>
                                                        {
                                                            saving > 0 ?
                                                            <p className="m-0 saving"> Save ₹ {saving}</p>
                                                            :null
                                                        }
                                                    </div>
                                                    <div className={[Styles[' '],'col-4'].join(' ')}>
                                                    <p className={[Styles['per-price-credit'],' '].join(' ')}>{e.pricePerImage}/ credit</p>
                                                    </div>

                                                </div>
                                            )
                                            }
                                        })
                                        :
                                        null
                                    }
                                </div> */}
                                <center>
                                {/* <p className={[Styles['reminder'],''].join(' ')}>Pay Yearly:<span>Save 10%</span></p> */}
                                <button onClick={this.purchaseSubscriptionPlan} className={[Styles['mybtn'],'btn'].join(' ')}>Subscribe Now</button>
                                
                                {
                                    !this.state.loginStatus ?
                                        <LandingModal
                                            showInstruction={this.state.showInstruction}
                                            hideModal = {this.hideLandingModal}
                                        />

                                    :null
                                }
                            </center>
                            </div>
                        </div>
                        {/* <div className="col-4"></div> */}
                        <div className={[Styles['pricing-cols'],'col-lg-5 col-12 d-md-block d-none pl-0'].join(' ')}>
                            <div className={[Styles['pricing-right'],'row'].join(' ')}>
                            <h6 className="pay-as-you-go">Pay<span> As You </span>Go</h6>
                            {this.state.currency == 'AED' ?
                                    <h2><strike>&#x62f;&#x2e;&#x625; {this.state.rackPlanPricePerImage}</strike> &#x62f;&#x2e;&#x625; {this.state.planPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'USD' ?
                                    <h2><strike>$ {this.state.rackPlanPricePerImage}</strike> $ {this.state.planPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'GBP' ?
                                    <h2><strike>&#163; {this.state.rackPlanPricePerImage}</strike> &#163; {this.state.planPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'CAD' ?
                                    <h2><strike>$ {this.state.rackPlanPricePerImage}</strike> $ {this.state.planPricePerImage} <span>/ Image</span></h2>
                                    : this.state.currency == 'INR' ?
                                    <h2><strike>₹ {this.state.rackPlanPricePerImage}</strike> ₹ {this.state.planPricePerImage} <span>/ Image</span></h2>
                                    : null
                                }
                            {/* <h2><strike>$ {this.state.rackPlanPricePerImage}</strike> $ {this.state.planPricePerImage} <span>/ Image</span></h2> */}
                        <div className="row price-container">
                            <div className="col-md-12 main-price-container">
                                

                                
                                {
                                    plans.length !== 0 ?
                                    plans.map((e,id) => {
                                        let total_price = 13.25 * e.credits
                                        let saving = total_price - e.price
                                        if(e.credits >= 100000){
                                            return  <div className={[Styles['input-box-div'],'row'].join(' ')} onClick={() => this.sendMailPopup()}>
                                                    <div className={[Styles['credit-radio'],'col-md-5 col-5'].join(' ')}>
                                                        <input type="radio" name="credit" checked={this.state.planSelectionId === e.id ? true : false}></input>
                                                    {/* </div>
                                                    <div className="col-4">  */}
                                                        <p className="m-0 credit">{e.credits} Credits
                                                            {/* <span>Save 20%</span> */}
                                                        </p>
                                                    </div>
                                                    <div  className={[Styles['price-rate-cr'],'col-3 col-md-4'].join(' ')}> 
                                                        {this.state.currency == 'AED' ?
                                                            <p className="m-0 credit"><strike>&#x62f;&#x2e;&#x625; {e.rackPrice}</strike> <span>&#x62f;&#x2e;&#x625; {e.price}</span></p>
                                                            : this.state.currency == 'USD' ?
                                                            <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                            : this.state.currency == 'GBP' ?
                                                            <p className="m-0 credit"><strike>&#163; {e.rackPrice}</strike> <span>&#163; {e.price}</span></p>
                                                            : this.state.currency == 'CAD' ?
                                                            <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                            : this.state.currency == 'INR' ?
                                                            <p className="m-0 credit"><strike>₹ {e.rackPrice}</strike> <span>₹ {e.price}</span></p>
                                                            : null
                                                        }
                                                        {/* <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span>
                                                           
                                                        </p> */}
                                                    </div>
                                                    <div className={[Styles['pricing-list-single'],'col-4 col-md-3'].join(' ')}>
                                                        {this.state.currency == 'AED' ?
                                                                <p className={Styles['save-price']}> &#x62f;&#x2e;&#x625; {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'USD' ?
                                                                <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'GBP' ?
                                                                <p className={Styles['save-price']}> &#163; {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'CAD' ?
                                                                <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'INR' ?
                                                                <p className={Styles['save-price']}> ₹ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : null
                                                            }
                                                    </div>
                                                </div>
                                        }else{
                                            return  <div className={[Styles['input-box-div'],'row'].join(' ')} onClick={() => this.handlePayment(e,id)}>
                                                    <div className={[Styles['credit-radio'],'col-md-5 col-5'].join(' ')}>
                                                        <input type="radio" name="credit" checked={this.state.planSelectionId === e.id ? true : false}></input>
                                                    {/* </div>
                                                    <div className="col-4">  */}
                                                        <p className="m-0 credit">{e.credits} Credits
                                                            {/* <span>Save 20%</span> */}
                                                        </p>
                                                    </div>
                                                    <div  className={[Styles['price-rate-cr'],'col-3 col-md-4'].join(' ')}> 
                                                        {this.state.currency == 'AED' ?
                                                            <p className="m-0 credit"><strike>&#x62f;&#x2e;&#x625; {e.rackPrice}</strike> <span>&#x62f;&#x2e;&#x625; {e.price}</span></p>
                                                            : this.state.currency == 'USD' ?
                                                            <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                            : this.state.currency == 'GBP' ?
                                                            <p className="m-0 credit"><strike>&#163; {e.rackPrice}</strike> <span>&#163; {e.price}</span></p>
                                                            : this.state.currency == 'CAD' ?
                                                            <p className="m-0 credit"><strike>$ {e.rackPrice}</strike> <span>$ {e.price}</span></p>
                                                            : this.state.currency == 'INR' ?
                                                            <p className="m-0 credit"><strike>₹ {e.rackPrice}</strike> <span>₹ {e.price}</span></p>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={[Styles['pricing-list-single'],'col-4 col-md-3'].join(' ')}>
                                                        {this.state.currency == 'AED' ?
                                                                <p className={Styles['save-price']}> &#x62f;&#x2e;&#x625; {e.pricePerImage}/ image
                                                                {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'USD' ?
                                                                <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'GBP' ?
                                                                <p className={Styles['save-price']}> &#163; {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'CAD' ?
                                                                <p className={Styles['save-price']}> $ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : this.state.currency == 'INR' ?
                                                                <p className={Styles['save-price']}> ₹ {e.pricePerImage}/ image
                                                                    {/* <span>₹ {e.price}</span> */}
                                                                </p>
                                                                : null
                                                            }
                                                    </div>
                                                </div>
                                        }
                                        
                                    })
                                    : null
                                }
                                
                            </div>
                        </div>
                        
                        <Modal
                                show={this.state.showMailPopup}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                size='md'
                                centered
                                
                            >
                
                            <Modal.Body className={[Styles['pricing-modal']]}>
                                    <div className={[Styles['pricing-modal-cross']]}>
                                        <img onClick={this.handlePriceModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                    </div>
                                    <div className={[Styles['pricing-modal-text']]}>
                                        <h4>To purchase enterprise pricing plans <a style={{color:"#ff7700"}} href="mailto:product@spyne.ai"></a></h4>
                                        <a href="mailto:product@spyne.ai">
                                            <button >Contact Us</button>
                                        </a>
                                        
                                    </div>
                            </Modal.Body>
                        

                        </Modal>


                        {/* <div>
                            <p className="rate"><span >{this.state.planPricePerImage}</span>  per credit</p>
                        </div> */}
                        {/* <div className="mx-0 price-container">
                            {
                                plans.length !== 0 ?
                                plans.map((e,id) => {
                                    let total_price = 13.25 * e.credits
                                    let saving = total_price - e.price
                                    if(e.planType == "Basic"){
                                    return(
                                        <div className="row main-price-container" onClick={() => this.handlePayment(e,id)}>
                                            {
                                                this.state.planSelectionId == id ? 
                                                <div className="col-4 pl-5 mt-2">
                                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/noun_Check_2422421.svg"></img>
                                                </div>
                                                :
                                                <div className="col-4 pl-5 mt-2">
                                                    <p>{id}</p>
                                                </div>
                                            } 
                                            <div className="col-md-1 col-1">
                                                    <input type="radio" checked={this.state.planSelectionId == id ? true : false}></input>
                                            </div>
                                            
                                            <div className={this.state.planSelectionId ==id ? "col-4" : "col-4"}>
                                                <p className="m-0 total_credit">{e.credits}</p>
                                                <p className="m-0 credit">Credit</p>
                                            </div>
                                            <div className={this.state.planSelectionId ==id ? [Styles['pricing-list-single'],'col-3'].join(' '): [Styles[''],'col-3'].join(' ')}>
                                                <p className={saving > 0 ? Styles['save-price'] : null }>₹ {e.price}</p>
                                                {
                                                    saving > 0 ?
                                                    <p className="m-0 saving"> Save ₹ {saving}</p>
                                                    :null
                                                }
                                            </div>
                                            <div className={[Styles[' '],'col-4'].join(' ')}>
                                            <p className={[Styles['per-price-credit'],' '].join(' ')}>{e.pricePerImage}/ credit</p>
                                            </div>

                                        </div>
                                    )
                                    }
                                })
                                :
                                null
                            }
                        </div> */}
                        <center>
                        <button onClick={this.purchasePlan} className={[Styles['mybtn'],'btn'].join(' ')}>Buy Now</button>
                        
                        {
                            !this.state.loginStatus ?
                                <LandingModal
                                    showInstruction={this.state.showInstruction}
                                    hideModal = {this.hideLandingModal}
                                />

                            :null
                        }
                    </center>
                            
                                {/* <div className={[Styles['premium-img'],' '].join(' ')}>
                                    <img src="https://storage.googleapis.com/clippr-image-processing/Static/BEST-VALUE-BANNER%201.png" />
                                </div> */}
                                {/*<h3 className="">What you get</h3>
                                
                                <ul>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>Credits never expire</span></li>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>Unlimited Download</span></li>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>High-res images</span></li>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>No watermark</span></li>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>Credits works on both App & Web</span></li>
                                    <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/check.png"/> <span>No monthly subscription</span></li>
                               </ul> */}

                                {/* <div>
                                    {
                                        this.state.planPricePerImagePremium !== '' ?
                                        <p className="rate"><span >{this.state.planPricePerImagePremium} </span> per credit</p>
                                        :null
                                    }
                                </div> */}
                                {/* <div className="mx-0 price-container">
                                {
                                        plans.length !== 0 ?
                                        plans.map((e,id) => {
                                            let total_price = 13.25 * e.credits
                                            let saving = total_price - e.price
                                            if(e.planType !== "Basic"){
                                            return(
                                                <div className="row main-price-container" onClick={() => this.handlePaymentPremium(e,id)}> */}
                                                    {/* {
                                                        this.state.planSelectionId == id ? 
                                                        <div className="col-4 pl-5 mt-2">
                                                            <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/noun_Check_2422421.svg"></img>
                                                        </div>
                                                        :
                                                        <div className="col-4 pl-5 mt-2">
                                                            <p>{id}</p>
                                                        </div>
                                                    }  */}
                                                    {/* <div className="col-md-1 col-1">
                                                            <input type="radio" checked={this.state.planSelectionIdPremium == id ? true : false}></input>
                                                    </div>
                                                    
                                                    <div className={this.state.planSelectionIdPremium ==id ? "col-4" : "col-4"}>
                                                        <p className="m-0 total_credit">{e.credits}</p>
                                                        <p className="m-0 credit">Credit</p>
                                                    </div>
                                                    <div className={this.state.planSelectionIdPremium ==id ? [Styles['pricing-list-single'],'col-3'].join(' '): [Styles[''],'col-3'].join(' ')}>
                                                        <p className={saving > 0 ? Styles['save-price'] : null }>₹ {e.price}</p> */}
                                                        {/* {
                                                            saving > 0 ?
                                                            <p className="m-0 saving"> Save ₹ {saving}</p>
                                                            :null
                                                        } */}
                                                    {/* </div>
                                                    <div className={[Styles[' '],'col-4'].join(' ')}>
                                                    <p className={[Styles['per-price-credit'],' '].join(' ')}>{e.pricePerImage}/ credit</p>
                                                    </div>

                                                </div>
                                            )
                                            }
                                        })
                                        :
                                        null
                                    }
                                </div> */}
                                {/* <center>
                                <button onClick={this.purchasePremiumPlan} className={[Styles['mybtn'],'btn'].join(' ')}>Buy Now</button>
                                </center> */}
                            </div>
                        </div>
                    
                    </div>

            {/* Pricing List Row End */}


                    <div className={[Styles['advantage'],''].join(' ')}>
                        <h3>What you get</h3>
                        <div className={[Styles['advantage-content'],''].join(' ')}>
                            <div className={[Styles['advantage-content-row'],''].join(' ')}>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Vector.png"/>
                                    <p>Credits never expire</p>
                                </div>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Vector.png"/>
                                    <p>Unlimited Downloads</p>
                                </div>
                                <div>
                                    <img src='https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images-res.svg' />
                                    <p>High-res Images</p>
                                </div>
                            </div>
                            <div className={[Styles['advantage-content-row'],''].join(' ')}>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/watermark.svg"/>
                                    <p>No watermark</p>
                                </div>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/webmobile.svg"/>
                                    <p>Credits work on both App & Web</p>
                                </div>
                                <div>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/nomonthly.svg"/>
                                    <p>No Monthly subscription</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[Styles['price-breakup'],'row'].join(' ')}>
                        {/* <div  onClick={this.handleMoreContent}>
                            <h2><span>Show price breakup</span></h2>
                        </div> */}
                        {   this.state.moreContent ?
                            <>
                            <div className={[Styles['price-table'],'col-md-12'].join(' ')}>
                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                        <h4 className={[Styles[' '],'d-md-block d-none'].join(' ')}> Category </h4>
                                        <p>Automobile</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}>
                                        <h4> Features </h4>
                                        <p>Background Replacement</p>
                                        <p>Dealership Logo Addition</p>
                                        <p>Watermark at Low Resolution</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                        <h4> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div>
                                
                                </div>

                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                        <p>Real Estate</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}>
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Features </h4>
                                        <p>Sky Replacement</p>
                                        <p> Logo Addition</p>
                                        <p>Tags/Label</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                        <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div>
                                
                                </div>

                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                    
                                        <p>Apparel & Footwear</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}> 
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Features </h4>
                                        <p>Background Removal</p>
                                        <p>Marketplace + Background</p>
                                        <p>Margins & Scaling</p>
                                        <p>Tags/Label</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                        <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div> 
                                </div>
                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                    
                                        <p>Grocery & Kitchenware</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}> 
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Features </h4>
                                        <p>Background Removal</p>
                                        <p>Marketplace + Background</p>
                                        <p>Margins & Scaling</p>
                                        <p>Tags/Label</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div>
                                
                                </div>
                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                    
                                        <p>Fashion & Model</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}> 
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Features </h4>
                                        <p>Background Removal</p>
                                        <p>Mole Removal/Beautification</p>
                                        <p>Margins + Marketplace Background</p>
                                        <p>Tags</p>
                                        <p>Virtual Try on</p>
                                        <p>Background retouch</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div>
                                
                                </div>
                                <div className={[Styles['price-table-area'],'row'].join(' ')}>
                                    <div className={[Styles['price-table-left'],'col-md-4 col-12'].join(' ')}>
                                        <p>Jewellery</p>
                                    </div>
                                    <div className={[Styles['price-table-middle'],'col-md-4 col-9'].join(' ')}> 
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Features </h4>
                                        <p>Background Removal</p>
                                        <p>Virtual Try on</p>
                                        <p>Auto Color Enchancement</p>
                                    </div>
                                    <div className={[Styles['price-table-right'],'col-md-4 col-3'].join(' ')}>
                                    <h4 className={[Styles[' '],'d-block d-md-none'].join(' ')}> Credits </h4>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                        <p>2 <span>Credit</span></p>
                                    </div>
                                
                                </div>

                                </div>
                            </>
                            :null
                        }
                    </div>

                </div>
              
            </div>
            :
            <div className="spinner-div">
                <div class="sk-cube-grid">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                </div>
                <p>Fetching Pricing Plan</p>
            </div>
        )
    }
}
