import React, { useContext, useEffect } from 'react'
import styles from '../../../Css/3DBuild/Fabric/Fabric.module.css'
import { Link, useHistory } from 'react-router-dom'
import {Helmet} from "react-helmet";
 

const Fabric = (props) => {
    
	 
    return (
        <><Helmet>
            <title>Change Fabric Background Instantly | Fabric Photo Editor.Try Now</title>
            <meta id="meta-description" name="description" content="Change Your Fabric Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Fabric Photo Editing Tech. Instantly Change Image Background for Fabric and give your Fabric photography a new look." />
            <meta property="og:title" content="Change Fabric Background Instantly | Fabric Photo Editor.Try Now" />
            <meta property="og:description" content="Change Your Fabric Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Fabric Photo Editing Tech. Instantly Change Image Background for Fabric and give your Fabric photography a new look." />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
        </Helmet><div>
                <section className={[styles['fabric-section'], ''].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['fabric'], 'col-md-12'].join(' ')}>
                                <div className={[styles[''], 'row'].join(' ')}>
                                    <div className={[styles['fabric-back'], 'col-md-3 col-8 col-sm-4'].join(' ')}>
                                        <a href="/try-categories"><span>&#8249;</span></a>
                                        <img src="https://storage.googleapis.com/spyne-website/try-categories/fabric-breadcrumb.png" />
                                    </div>
                                    <div className={[styles['fabric-content'], 'col-md-9 col-12 col-sm-8'].join(' ')}>
                                        <h4>Edit<span> Fabric </span>Images</h4>
                                        <p>Convert Fabric images to 3D rendered Marketplace ready images instantly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* //Fabric Breadcrumb  */}

                {/* Fabric main  */}
                <section className={[styles['fabric-main'], ''].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                        <div className={[styles[''], 'row align-item-center justify-content-center'].join(' ')}>

                            <div className={[styles['cars-try'], 'col-md-4 col-sm-6'].join(' ')}>
                                <Link to="/tech/fabric/kurti" className={[styles["text-black"], styles['link']].join(' ')}>
                                    <div className={[styles['card'], styles['fabric1']].join(' ')}>
                                        <div className={styles['img-container']}>
                                            <img src="https://storage.googleapis.com/spyne-website/try-categories/fabric12.png" />
                                        </div>
                                        <div className="row">
                                            <h4 className="text-decoration-none">Kurti</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className={[styles['cars-try'], 'col-md-4 col-sm-6'].join(' ')}>
                                <Link to="/tech/fabric/saree" className={[styles["text-black"], styles['link']].join(' ')}>
                                    <div className={[styles['card'], styles['fabric2']].join(' ')}>
                                        <div className={styles['img-container']}>
                                            <img src="https://storage.googleapis.com/spyne-website/try-categories/fabric13.png" />
                                        </div>
                                        <div className="row">
                                            <h4 className="text-decoration-none">Saree</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </section>

            </div></>
    )
}

export default Fabric