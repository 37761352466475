import React, { Component } from "react";
import styles from '../../../Css/3DBuild/Suit/UploadSuit.module.css'
import Dropzone from "react-dropzone";
import SelectModelSuit from "../Suit/SelectModelSuit"
import OutputSuit from "../Suit/outputSuit"
import axios from "axios";
import { delay } from "q";
import LandingModal from '../../Common/Modal/LandingModalFashion'
import {Helmet} from "react-helmet";
import Drawer from '../../Drawer/Drawer' 
import * as Sentry from "@sentry/react"

class UploadSuit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            selectedFileKurti: null,
            selectedFileSalwar: null,
            selectedFileDupatta: null,
            url: [],
            orgUrlKurti: "",
            orgUrlSalwar: "",
            orgUrlDupatta: "",
            loader: false,
            imageWidth: "",
            imageHeight: "",
            productHunt: 'clippr',
            showLoginModal: true,
            auth_key: '',
            emailId: '',
            availableCredit: 0,
            dropBackground: false,
            creditAlloted: 0,
            creditUsed: 0,
            wm_image: [],
            hd_image: [],
            isProductCategory: false,
            ProductCategory: "",
            category: "",
            isUploadSaree: false,
            isUploadBlouse: false,
            showYoutubeGifModal: false,
            showSareeModal: false,
            showBlouseModal: false,
            isProjectReady: false,
            isSaree: false,
            isBlouse: false,
            selected: false,
            sareeSpinner: false,
            posearr: [],
            skuId: '',
            projectId: '',
            spinnerDownload: false,
            downloadZipLink: '',
            modelSelection: false,
            modelProcess: false,
            rotationKurti: 0,
            rotationSalwar: 0,
            rotationDupatta: 0,
            poselength:0,
            downloadType:'',
            bgCredit:0,
            checkColorEnhancement:false,

        };
        this.rotate1 = this.rotate1.bind(this);
        this.rotate2 = this.rotate2.bind(this);
        this.rotate3 = this.rotate3.bind(this);
        this.rotateleft1 = this.rotateleft1.bind(this);
        this.rotateleft2 = this.rotateleft2.bind(this);
        this.rotateleft3 = this.rotateleft3.bind(this);
    }

    handelColorEnhancement = () => {
        this.setState({
          checkColorEnhancement:!this.state.checkColorEnhancement
        })
      }
componentDidMount = async() => {
    let userId = this.cookie('userId')
    if (userId !== "NA") {

      let emailId = this.cookie("emailId")
      let auth_key = this.cookie("auth_token")
      axios({
        method: "GET",
        url: process.env.REACT_APP_BASEURL + "/v2/user/get",
        params: {
          email_id: emailId,
          auth_key: auth_key
        }

      }).then(resp => {
        if (resp.data.status == 200) {
          this.setState({
            availableCredit: resp.data.data.credit_available,
            creditAlloted: resp.data.data.credit_allotted,
            creditUsed: resp.data.data.credit_used
          })
        } else {
          // console.log(resp)
        }
      })
      this.setState({
        userId: userId
      })
    }
    // if (this.state.uploadStarteds === "true") {
    //   this.getUserCredit()
    // }
}


    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]


    hideLandingModal = () => {
        this.setState({showLoginModal:!this.state.showLoginModal})
      }

      handleDownloadComplete = () => {
        this.setState({downloadComplete: false})
      }

    handleDownloadModal = async (DT) =>{

        let userId = this.cookie('userId')
        let price
        await this.setState({bgCredit:this.state.poselength,downloadType:DT});
        if(this.state.downloadType == 'HD' && this.state.availableCredit >= this.state.bgCredit){
        
        price = this.state.bgCredit;
        axios({
         method: "GET",
         url : process.env.REACT_APP_BASEURL + "/v4/download-history",
         params : {
             user_id : userId,
             enterprise_id : 'TaD1VC1Ko',
             sku_id: this.state.skuId[0]
         }
         
        }).then( resp => {
         if(resp.data.status == 404){
             let auth_key = this.cookie('auth_token')
             const fd = new FormData()
             fd.append("auth_key", auth_key)
             fd.append("credit_reduce",price)
             axios.put(`https://www.clippr.ai/api/v2/credit/reduce-credit`,fd)
            //  updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
             .then( (res) => {
                 this.setState({
                     availableCredit: this.state.availableCredit - price,
                     // downloadComplete: true
                 })
                for(var i=0;i<this.state.hd_image.length;i++){
                var output_link = this.state.hd_image[i];
                fetch(output_link, {
                    method: "GET",
                    headers: {}
                    })
                    .then(response => {
                        response.arrayBuffer().then(function(buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        let name = output_link
                        var index = name.lastIndexOf("/") + 1;
                        var filename = name.substr(index)
                        link.setAttribute("download", filename); 
                        document.body.appendChild(link);
                        link.click();       
                    });
                    this.setState({
                        downloadComplete:true
                      })
                    }).catch((err) => console.log(err))
                    const fd = new FormData()
                     fd.append("user_id", userId)
                     fd.append("sku_id",this.state.skuId[0])
                     fd.append("enterprise_id" , 'TaD1VC1Ko')
                     fd.append("download_hd","true")
                     axios.post(`https://www.clippr.ai/api/v4/update-download-status`,fd)
                     .then(resp => {
                         console.log(resp)
                     })
                }
    
    }).catch((err) => console.log(err))
    }else{
      for(var i=0;i<this.state.hd_image.length;i++){
        var output_link = this.state.hd_image[i];
        fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();       
            });
            this.setState({
                downloadComplete:true
              })
            }).catch((err) => console.log(err))
        }
    
    }
        }).catch((err) => console.log(err))
    console.log(this.state.availableCredit)
    }
    else {
    for(var i=0;i<this.state.wm_image.length;i++){
        var output_link = this.state.wm_image[i];
        fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();       
            });
            this.setState({
                downloadComplete:true
              })
            }).catch((err) => console.log(err))
        }
    }
    }

    //random no. is generated for uuid
    getRandomString(length1) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length1; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }
    //receiving details from selectmodelSuit
    handleUploadScreen = (pose, modelId) => {
        this.setState(
            () => {
                this.fileUploadHandler(pose, modelId)
            })
    }

    //API call is made for output with all data
    fileUploadHandler = async (pose, modelId) => {
        this.setState({
            poselength:pose.length,
            url: [],
            wm_image: [],
            hd_image: [],
            orgUrl: [],
            skuId: [],
            projectId: []

        })

        this.setState({ posearr: pose });
        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercentage: percentCompleted,
                });
                if (percentCompleted === 100) {
                    this.setState({
                        uploading: false,
                        processing: true,
                    });
                }
            }.bind(this),
        };


        try {
            let uuid = this.getRandomString(8);
            let auth_key = this.cookie('auth_token')
            let _userId_ = this.cookie("userId")
            let sku_name = "API_SKU_" + _userId_ + uuid;
            let project_name = "API_PROJECT_" + _userId_ + uuid;

            this.setState({
                sareeSpinner: true
            })
            console.log(this.state.selectedFileKurti)
            for (var i = 0; i < pose.length; i++) {
                var data = new FormData();
                data.append('auth_key', auth_key);
                data.append('pose_id', pose[i]);
                data.append('model_id', modelId);
                data.append('frame_seq_no', i + 1);
                data.append('total_project_count', pose.length);
                data.append('prod_cat_id', 'cat_skJ7HIvnc');
                data.append('prod_sub_cat_id', 'prod_WiE3dLKOs');
                data.append('image_file', this.state.selectedFileKurti);
                data.append('image_file2', this.state.selectedFileSalwar);
                data.append('image_file3', this.state.selectedFileDupatta);
                data.append('category', "KurtiBody");
                data.append('source', 'BULKPROCESS')
                data.append('is_color',this.state.checkColorEnhancement)
                var config_ph = {
                    method: 'post',
                    url: `${process.env.REACT_APP_BASEURL}/v2/rendering/imageProcessSaree`,

                    headers: {
                    },
                    data: data
                };
                axios(config_ph)
                    .then((res) => {
                        this.setState({
                            url: [...this.state.url, res?.data?.data?.display_image],
                            wm_image: [...this.state.wm_image, res?.data?.data?.watermark_image],
                            hd_image: [...this.state.hd_image, res?.data?.data?.output_image],
                            orgUrl: [res?.data?.data?.original_image],
                            skuId: [res?.data?.data?.sku_id],
                            projectId: [res?.data?.data?.project_id]


                        });

                        try {
                            localStorage.setItem("urlbg", res.data.data.display_image);
                            let editedImages;
                            editedImages = localStorage.getItem("editedImagesBgr");
                            editedImages = editedImages ? editedImages.split(",") : [];
                            editedImages.push(res.data.display_image);
                            localStorage.setItem(
                                "editedImagesBgr",
                                editedImages.toString()
                            );
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                        this.setState({
                            orgImgShow: true,
                            processing: false,
                            loader: false,
                            editedState: true,
                            uploadPercentage: 0,
                            tabIndex: 1,

                        });
                    });
                await delay(6000)
            }
            this.setState({ sareeSpinner: false })
        } catch (e) {
            this.setState({
                isError: true,
                uploading: false,
                processing: false,
                loader: false,
                tabIndex: 0,
            });
        }
    };

    //skeleton rendering time management function    
    fileSelectedHandler = (event) => {
        this.setState({
            loader: true,
            dropBackground: false,
            isProjectReady: false,
            showSareeModal: true,
        });
        if (event.target) {
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrl: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrl);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFile: event.target.files[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,
                },
                () => {
                    this.fileUploadHandler(1);
                }
            );
        } else {
            let file = event[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrl: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrl);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFile: event[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,

                },
                // () => {
                //   this.fileUploadHandler();
                // }
            );
        }
    }; 

    //function to move from uploadsuit to SelectModelSuit
    handelModelSelection = (e) => {
        this.setState({
            modelSelection: true
        })
    }
    //function to move from SelectModel to outputSuit
    handelModelprocess = () => {
        this.setState({
            modelProcess: true
        })
    }
    handleUploadBtn = () => {
        this.setState({
            modelProcess: false,
            modelSelection: false,
            orgUrlDupatta:'',
            orgUrlKurti:'',
            orgUrlSalwar:'',
            orgUrlSalwar:'',
            selectedFileDupatta:null,
            selectedFileKurti:null,
            selectedFile:null,
            selectedFileSalwar:null,
        })
    }
    //function to set category
    handleUpload = (e) => {
        if (e == "Dupatta")
            this.setState({
                selectedFileDupatta: null
            })
        else if (e == "Kurti")
            this.setState({
                selectedFileKurti: null
            })
        else if (e == "Salwar")
            this.setState({
                selectedFileSalwar: null
            })
    }
    //function to handle the file selected and converts uploaded file to url
    fileSelectedHandler = (event) => {
        this.setState({
            loader: true,
            dropBackground: false,
            isProjectReady: false,
            showSareeModal: true,
        });
        if (event.target) {
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlKurti: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlKurti);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileKurti: event.target.files[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,
                },
            );
        } else {
            let file = event[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlKurti: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlKurti);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileKurti: event[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,

                },
            );
        }
    };
    //function to handle the file selected and converts uploaded file to url
    fileSelectedHandler2 = (event) => {
        this.setState({
            loader: true,
            dropBackground: false,
            isProjectReady: false,
            showSareeModal: true,
        });
        if (event.target) {
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlSalwar: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlSalwar);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileSalwar: event.target.files[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,
                },
            );
        } else {
            let file = event[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlSalwar: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlSalwar);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileSalwar: event[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,

                },

            );
        }
    };
    //function to handle the file selected and converts uploaded file to url
    fileSelectedHandler3 = (event) => {
        this.setState({
            loader: true,
            dropBackground: false,
            isProjectReady: false,
            showSareeModal: true,
        });
        if (event.target) {
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlDupatta: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlDupatta);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileDupatta: event.target.files[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,
                },
            );
        } else {
            let file = event[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState(
                    {
                        orgUrlDupatta: [reader.result],
                    },
                    () => {
                        try {
                            localStorage.setItem("orgUrlBg", this.state.orgUrlDupatta);
                        } catch (e) {
                            if (e == "QUOTA_EXCEEDED_ERR") {
                            }
                        }
                    }
                );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
                {
                    selectedFileDupatta: event[0],
                    editing: true,
                    editedImagesArr: [...editedImages],
                    uploading: true,
                    loader: true,
                    uploadBox: false,

                },
            );
        }
    };
    isProdCategory = () => {
        this.props.backToCategory();
      }
    //function used in dropzone to handle drag event by user
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
    }
    //function used in dropzone to handle drag event by user
    dragLeave = (e) => {
        e.preventDefault()
        this.state.dropBackground = false
    }
    //function to rotate uploaded image to right
    rotate1 = () => {

        let newRotation = this.state.rotationKurti + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationKurti: newRotation,
        })
    }
    //function to rotate uploaded image to right
    rotate2 = () => {

        let newRotation = this.state.rotationSalwar + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationSalwar: newRotation,
        })
    }
    //function to rotate uploaded image to right
    rotate3 = (state) => {

        let newRotation = this.state.rotationDupatta + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationDupatta: newRotation,
        })
    }
    //function to rotate uploaded image to left
    rotateleft1 = () => {
        let newRotation = this.state.rotationKurti - 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationKurti: newRotation,
        })
    }
    //function to rotate uploaded image to left
    rotateleft2 = () => {
        let newRotation = this.state.rotationSalwar - 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationSalwar: newRotation,
        })
    }
    //function to rotate uploaded image to left
    rotateleft3 = () => {
        let newRotation = this.state.rotationDupatta - 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotationDupatta: newRotation,
        })
    }

    render() {
        const { rotationKurti } = this.state;
        const { rotationSalwar } = this.state;
        const { rotationDupatta } = this.state;
        return (
            <div className={[styles['']]}>
            <Helmet>
                <title>Replace Kurti Background Instantly | Kurti Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content=" Change Your Kurti Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Kurti Photo Editing Tech. Instantly Change Image Background for Kurti and give your Kurti Photography a new look." />
                <meta property="og:title" content="Replace Kurti Background Instantly | Kurti Photo Editor.Try Now" />
                <meta property="og:description" content=" Change Your Kurti Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Kurti Photo Editing Tech. Instantly Change Image Background for Kurti and give your Kurti Photography a new look." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
            </Helmet>
           
           
            {
        this.state.showLoginModal?
        <LandingModal
            showInstruction={this.state.showLoginModal}
            hideModal = {this.hideLandingModal}
            productHunt={this.state.productHunt}

        />
        :null
      }
                {!this.state.modelSelection ?
                    <div className={[styles['upload-main'], ''].join(' ')}>
                       {/* Drawer section  */}
                       <Drawer/> 
                        {/* div to display uploading of images */}
                        <section className={[styles['upload-screen'], ''].join(' ')}>
                            <div className={[styles[''], 'container'].join(' ')}>
                                <div className={[styles[''], 'row'].join(' ')}>
                                    <div className={[styles['upload'], 'col-md-12 text-center'].join(' ')}>
                                        <h1>Upload Kurti for 3-D Rendering</h1>
                                        <p><span></span></p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* uploading for 1st image */}
                        <div className={[styles['upload-kurti'], ''].join(' ')}>
                            {this.state.selectedFileKurti == null ?
                                <Dropzone
                                    onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
                                    onDragEnter={this.dragStart}
                                    onDragLeave={this.dragLeave}

                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({
                                                onClick: (event) => event.stopPropagation(),
                                            })}
                                            className={styles["dropzone1"]}
                                        >
                                            <section className={[styles[''], ''].join(' ')}>
                                                <div className={[styles[''], ''].join(' ')}>
                                                    <>
                                                        <div className={[styles['crop-saree'], ''].join(' ')}>

                                                            <div className={[styles[''], ''].join(' ')}>
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) =>
                                                                        this.fileSelectedHandler(acceptedFiles)
                                                                    }
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section  className={[styles["try-drag-drop"],"",].join(" ")} >
                                                                            <div {...getRootProps({})} className={[styles['upload-cols'], 'row'].join(' ')}>
                                                                                <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                                                    <p className={styles['']}><span>1</span></p>
                                                                                </div>
                                                                                <div className={[styles['upload-text'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                                                                                    <div className={[styles['upload-box'], ''].join(' ')}>
                                                                                        <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/upld.png" />
                                                                                        <h4>DROP IMAGE</h4>
                                                                                        <p>Supports jpeg, jpg, bmp & png </p>
                                                                                    </div>
                                                                                    <h3>Upload Kurti</h3>
                                                                                </div>
                                                                                <input
                                                                                    onChange={this.fileSelectedHandler}
                                                                                    ref={(fileInput) =>
                                                                                        (this.fileInput = fileInput)
                                                                                    }
                                                                                    type="file"
                                                                                    {...getInputProps()}
                                                                                />
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </section>
                                            <input
                                                onChange={this.fileSelectedHandler}
                                                ref={(fileInput) => (this.fileInput = fileInput)}
                                                type="file"
                                                {...getInputProps()}
                                            />

                                        </div>
                                    )}
                                </Dropzone>
                                :
                                // after uploading image is displayed
                                <section className={[styles[''], ''].join(' ')}>
                                    
                                                <div className={[styles['upload-cols'], 'row m-0'].join(' ')}>
                                                    <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                        <p className={styles['active']}><span>1</span> </p>
                                                    </div>
                                                    <div className={[styles['fabric-upload'], 'col-md-12'].join(' ')}>
                                                        <h3>Uploaded Kurti</h3>
                                                        <img style={{ transform: `rotate(${rotationKurti}deg)` }} src={this.state.orgUrlKurti} />
                                                        <span onClick={() => this.handleUpload("Kurti")} >X</span>
                                                    </div>

                                                    <h6 className={[styles['rotate-btn']]}><button onClick={this.rotateleft1} className={[styles['upload-btn']]}>Rotate left</button><button onClick={this.rotate1} className={[styles['upload-btn']]}>Rotate Right</button></h6>
                                                </div>
                                            
                                </section>
                            }
                            {/* uploading for 2nd image */}
                            {this.state.selectedFileSalwar == null ?
                                <Dropzone

                                    onDrop={(acceptedFiles) => this.fileSelectedHandler2(acceptedFiles)}
                                    onDragEnter={this.dragStart}
                                    onDragLeave={this.dragLeave}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({
                                                onClick: (event) => event.stopPropagation(),
                                            })}
                                            className={styles["dropzone1"]}
                                        >
                                            <section className={[styles[''], ''].join(' ')}>
                                                <div className={[styles[''], ''].join(' ')}>
                                                    <>
                                                        <div className={[styles[''], ''].join(' ')}>

                                                            <div className={[styles[''], ''].join(' ')}>
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) =>
                                                                        this.fileSelectedHandler2(acceptedFiles)
                                                                    }
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section
                                                                            className={[styles["try-drag-drop"], "",].join(" ")}>
                                                                            <div {...getRootProps({})} className={[styles['upload-cols'], 'row'].join(' ')}>
                                                                                <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                                                    <p className={styles['']}><span>2</span></p>
                                                                                </div>
                                                                                <div className={[styles['upload-text'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                                                                                    <div className={[styles['upload-box'], ''].join(' ')}>
                                                                                        <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/upld.png" />
                                                                                        <h4>DROP IMAGE</h4>
                                                                                        <p>Supports jpeg, jpg, bmp & png </p>
                                                                                    </div>
                                                                                    <h3>Upload Dupatta</h3>
                                                                                </div>
                                                                                <input
                                                                                    onChange={this.fileSelectedHandler2}
                                                                                    ref={(fileInput) =>
                                                                                        (this.fileInput = fileInput)
                                                                                    }
                                                                                    type="file"
                                                                                    {...getInputProps()}
                                                                                />
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </section>
                                            <input
                                                onChange={this.fileSelectedHandler2}
                                                ref={(fileInput) => (this.fileInput = fileInput)}
                                                type="file"
                                                {...getInputProps()}
                                            />

                                        </div>
                                    )}
                                </Dropzone>
                                :
                                // display 2nd image uploaded 
                                <section className={[styles[''], ''].join(' ')}>
                                   
                                                <div className={[styles['upload-cols'], 'row m-0'].join(' ')}>
                                                    <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                        <p className={styles['active']}><span>2</span></p>
                                                    </div>

                                                    <div className={[styles['fabric-upload'], 'col-md-12'].join(' ')}>
                                                        <h3>Uploaded Dupatta</h3>
                                                        <img style={{ transform: `rotate(${rotationSalwar}deg)` }} src={this.state.orgUrlSalwar} />
                                                        <span onClick={() => this.handleUpload("Salwar")} >X</span>
                                                    </div>
                                                    <h6 className={[styles['rotate-btn']]}><button onClick={this.rotateleft2} className={[styles['upload-btn']]}>Rotate left</button><button onClick={this.rotate2} className={[styles['upload-btn']]}>Rotate Right</button></h6>
                                                </div>
                                            
                                </section>
                            }
                            {/* uploading 3rd image by user  */}
                            {this.state.selectedFileDupatta == null ?
                                <Dropzone
                                    onDrop={(acceptedFiles) => this.fileSelectedHandler3(acceptedFiles)}
                                    onDragEnter={this.dragStart}
                                    onDragLeave={this.dragLeave}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({
                                                onClick: (event) => event.stopPropagation(),
                                            })}
                                            className={styles["dropzone1"]}
                                        >
                                            <section className={[styles[''], ''].join(' ')}>
                                                <div className={[styles[''], ''].join(' ')}>
                                                    <>
                                                        <div className={[styles[''], ''].join(' ')}>

                                                            <div className={[styles[''], ''].join(' ')}>
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) =>
                                                                        this.fileSelectedHandler3(acceptedFiles)
                                                                    }
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section
                                                                            className={[ styles["try-drag-drop"],"",].join(" ")}>
                                                                            <div {...getRootProps({})} className={[styles['upload-cols'], 'row'].join(' ')}>
                                                                                <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                                                    <p className={styles['']}><span>3</span></p>
                                                                                </div>
                                                                                <div className={[styles['upload-text'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                                                                                    <div className={[styles['upload-box'], ''].join(' ')}>
                                                                                        <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/upld.png" />
                                                                                        <h4>DROP IMAGE</h4>
                                                                                        <p>Supports jpeg, jpg, bmp & png </p>
                                                                                    </div>
                                                                                    <h3>Upload Salwar</h3>
                                                                                </div>
                                                                                <input
                                                                                    onChange={this.fileSelectedHandler3}
                                                                                    ref={(fileInput) =>
                                                                                        (this.fileInput = fileInput)
                                                                                    }
                                                                                    type="file"
                                                                                    {...getInputProps()}
                                                                                />
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </section>
                                            <input
                                                onChange={this.fileSelectedHandler3}
                                                ref={(fileInput) => (this.fileInput = fileInput)}
                                                type="file"
                                                {...getInputProps()}
                                            />

                                        </div>
                                    )}
                                </Dropzone>
                                :
                                // display of 3rd image
                                <section className={[styles[''], ''].join(' ')}>
                                    <div className={[styles['upload-cols'], 'row m-0'].join(' ')}>
                                                    <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                                        <p className={styles['active']}><span>3</span></p>
                                                    </div>
                                                    <div className={[styles['fabric-upload'], 'col-md-12'].join(' ')}>
                                                        <h3>Uploaded Salwar</h3>
                                                        <img style={{ transform: `rotate(${rotationDupatta}deg)` }} src={this.state.orgUrlDupatta} />
                                                        <span onClick={() => this.handleUpload("Dupatta")} >X</span>

                                                    </div>
                                                    <h6 className={[styles['rotate-btn']]}><button onClick={this.rotateleft3} className={[styles['upload-btn']]}>Rotate left</button><button onClick={this.rotate3} className={[styles['upload-btn']]}>Rotate Right</button></h6>
                                                </div>        
                                </section>
                            }
                        </div>
                        <div className={[styles['select-model']]}>
                            <button onClick={() => this.handelModelSelection("suit")} disabled={this.state.selectedFileKurti == null || this.state.selectedFileDupatta == null || this.state.selectedFileSalwar == null ? true : false}>Select model</button>
                        </div>
                    </div>
                    :
                    <>
                        {/* change of state leads to change in screen */}
                        {!this.state.modelProcess ?
                            //selectmodel
                            <SelectModelSuit
                                fileSelectedHandler={this.fileSelectedHandler}
                                availableCredit={this.state.availableCredit}
                                fileUploadHandler={this.fileUploadHandler}
                                handleModelProcess={this.handelModelprocess}
                                handleUploadScreen={this.handleUploadScreen}
                                handleUploadBtn={this.handleUploadBtn}
                                isProdCategory={this.isProdCategory}
                                checkColorEnhancement={this.state.checkColorEnhancement}
                                handelColorEnhancement={this.handelColorEnhancement}
                            />
                            :
                            //output of suit on models
                            <OutputSuit
                                originalImage={this.state.orgUrl}
                                editedImage={this.state.url}
                                hd_image={this.state.hd_image}
                                wm_image={this.state.wm_image}
                                fileSelectedHandler={this.fileSelectedHandler}
                                loader={this.state.loader}
                                posearr={this.state.posearr}
                                handleUploadBtn={this.handleUploadBtn}
                                availableCredit={this.state.availableCredit}
                                handleDownloadModal={this.handleDownloadModal}
                                downloadComplete={this.state.downloadComplete}
                                handleDownloadComplete={this.handleDownloadComplete}
                            />
                        }
                    </>
                }
            </div>
        )
    }
}

export default Sentry.withProfiler(UploadSuit);










