// const data:[
            
//     {  "hatchb{ack":{
//           "fron":"kejw"
//         },
// }} 
// ]

// e
const data = {
    
    
    "hb":[
        {"0":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/36_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/36_hb_b.png"
        }} ,
       { "1":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/1_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/1_hb_b.png"
        } },
       { "2":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/2_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/2_hb_b.png"
        } },
       { "3":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/3_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/3_hb_b.png"
        } },
       { "4":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/5_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/5_hb_b.png"
        } },
       { "5":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/7_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/7_hb_b.png"
        } },
        {"6":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/11_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/11_hb_b.png"
        }} ,
        {"7":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/13_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/13_hb_b.png"
        }} ,
        {"8":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/15_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/15_hb_b.png"
        }} ,
        {"9":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/17_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/17_hb_b.png"
        }} ,
        {"10":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/19_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/19_hb_b.png"
        }} ,
        {"11":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/21_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/21_hb_b.png"
        }} ,
        {"12":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/23_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/23_hb_b.png"
        }} ,
        {"13":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/25_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/25_hb_b.png"
        }} ,
        {"14":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/28_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/28_hb_b.png"
        }} ,
        {"15":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/31_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/31_hb_b.png"
        }} ,
        {"16":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/33_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/33_hb_b.png"
        }} ,
        {"17":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/35_hb_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_b/35_hb_b.png"
        }} ,
        
    ],
    "sedan":[
        {"0":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/36_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/36_sedan_b.png"
        }} ,
       { "1":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/1_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/1_sedan_b.png"
        } },
       { "2":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/2_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/2_sedan_b.png"
        } },
       { "3":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/3_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/3_sedan_b.png"
        } },
       { "4":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/5_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/5_sedan_b.png"
        } },
       { "5":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/7_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/7_sedan_b.png"
        } },
        {"6":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/11_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/11_sedan_b.png"
        }} ,
        {"7":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/13_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/13_sedan_b.png"
        }} ,
        {"8":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/15_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/15_sedan_b.png"
        }} ,
        {"9":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/17_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/17_sedan_b.png"
        }} ,
        {"10":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/19_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/19_sedan_b.png"
        }} ,
        {"11":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/21_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/21_sedan_b.png"
        }} ,
        {"12":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/23_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/23_sedan_b.png"
        }} ,
        {"13":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/25_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/25_sedan_b.png"
        }} ,
        {"14":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/28_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/28_sedan_b.png"
        }} ,
        {"15":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/31_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/31_sedan_b.png"
        }} ,
        {"16":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/33_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/33_sedan_b.png"
        }} ,
        {"17":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_w/35_sedan_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/sedan_b/35_sedan_b.png"
        }} 
        
    ],
    "suv":[
        {"0":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/36_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/36_suv_b.png"
        }} ,
       { "1":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/1_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/1_suv_b.png"
        } },
       { "2":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/2_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/2_suv_b.png"
        } },
       { "3":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/3_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/3_suv_b.png"
        } },
       { "4":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/5_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/5_suv_b.png"
        } },
       { "5":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/7_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/7_suv_b.png"
        } },
        {"6":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/11_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/11_suv_b.png"
        }} ,
        {"7":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/13_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/13_suv_b.png"
        }} ,
        {"8":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/15_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/15_suv_b.png"
        }} ,
        {"9":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/17_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/17_suv_b.png"
        }} ,
        {"10":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/19_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/19_suv_b.png"
        }} ,
        {"11":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/21_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/21_suv_b.png"
        }} ,
        {"12":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/23_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/23_suv_b.png"
        }} ,
        {"13":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/25_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/25_suv_b.png"
        }} ,
        {"14":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/28_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/28_suv_b.png"
        }} ,
        {"15":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/31_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/31_suv_b.png"
        }} ,
        {"16":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/33_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/33_suv_b.png"
        }} ,
        {"17":{
            "w":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_w/35_suv_w.png",
            "b":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/suv_b/35_suv_b.png"
        }} 
        
    ]

    

}
export {data}