import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Modal } from "react-bootstrap";
import Toggle from 'react-toggle'
import axios from 'axios'
import TwentyTwenty from 'react-twentytwenty'
import '../../App.css'
import styles from '../Css/CarsBackgroundReplacement.module.css'
import CarsBackground from './Background/CarsBackground'
import BulkCarsBackground from './Background/BulkCarsBackground'
import {fetchCreditUserDetails, updateUserCredit,cookie,poll, chunkArray} from './Common/Utils'
import LandingModal from './Common/Modal/LandingModal'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Styles from '../Css/CarV2.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Resizer from 'react-image-file-resizer'
import {Helmet} from "react-helmet";
import Spinner from './Background/Spinner'
import queryString from 'query-string'
import {isMobile} from 'react-device-detect';
// import variables from '../Css/variables.scss'


export default class CarsLandingPage extends Component {
    constructor(props){
        super(props)
        this.state={
          selectedFile: null,
          selectedLogoFile: null,
          url: window.sessionStorage.getItem("lastEditedImage") ? window.sessionStorage.getItem("lastEditedImage") : '' , // url of the image returned by the api 
          orgUrl:  window.sessionStorage.getItem("lastRawImage") ? window.sessionStorage.getItem("lastRawImage") :  '',
          object_id:0,
          background_id:929,
          uploadPercentage: 0,
          uploading: false,
          orgImgShow: true,
          loader: false,
          uploadBox: true,
          editedState: false,
          below_bg:false,
          tabIndex: 0,
          isError: false,
          processing: false,
          editedImagesArr: [],
          editing: false,
          carShadow:false,
          buffer_array:[1],
          cnt:1,
          spinner:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          car_bg_replacement_angle:'front',
          selectedAngle:'',
          imageSelected:false,
          firstUpload:true,
          planData:[],
          walldata:[],
          availableCredit:5,
          alottedCredit: 0,
          creditUsed:0,
          isEditingFinish:false,
          toggleChange:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          toggleStatus:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          bgCredit:1,
          loginStatus:false,
          logoUrl:'',
          logoPosition:null,
          hideLogo:false,
          showInstruction:false,
          showInstructionModal:false,
          askEmail:false,
          activButton: "Exterior",
          backgroundId:["80","81","82"],
          backgroundEditedUrl:[],
          imageEdited : false,
          actiButton:"Background1",
          waterMarkImg:"",
          activateButton:"Hd",
          downloadComplete: false,
          isDownload: false,
          urlDownload: '',
          dropBackground: false,
          checkColorEnhancement:false,
          checkWindowTransparency:false,
          filesList:JSON.parse(sessionStorage.getItem("filesList")) ? JSON.parse(sessionStorage.getItem("filesList")) : [],
          filesListInterior:JSON.parse(sessionStorage.getItem("filesListInterior")) ? JSON.parse(sessionStorage.getItem("filesListInterior")) : [],
          setFiles:JSON.parse(sessionStorage.getItem("setFiles")) ? JSON.parse(sessionStorage.getItem("setFiles")) : [],
          maxUpload: false,
          showBulkCreditModal: false,
          showProjectNameModal: false,
          project_name: window.sessionStorage.getItem("project_name") ? window.sessionStorage.getItem("project_name") : '',
          sku_name: window.sessionStorage.getItem("sku_name") ? window.sessionStorage.getItem("sku_name") : '',
          project_id:'',
          sku_id:'',
          notEnoughCreditModal:false,
          enoughCreditModal:false,
          zeroCreditModal:false,
          uploadStarteds: window.sessionStorage.getItem("uploadStarteds") ? window.sessionStorage.getItem("uploadStarteds") : false,
          orgUrlList:[],
          imgStatusUpload: true,
          imgStatusProcess: false,
          imgStatusDone: false,
          showRefundModal: false,
          refundImgUrl:'',
          refundOrgUrl:'',
          refundImgId:'',
          refundNum:null,
          userId : '',
          downloadZipLink: window.sessionStorage.getItem("downloadZipLink") ? window.sessionStorage.getItem("downloadZipLink") :'',
          isZipReady : window.sessionStorage.getItem("isZipReady") ? window.sessionStorage.getItem("isZipReady") : false,
          bulkDownloadDone:window.sessionStorage.getItem("bulkDownloadDone") ? window.sessionStorage.getItem("bulkDownloadDone") : false,
          errorBulkFiles: [],
          auth_key:'',
          spinnerDownload: false,
          emailId:'',
          wall:"",
          customWall:"",
          customWallPosition:0,
          tabSelection:"virtual_studio",
          productHunt: '',
          projectNameExists:false,
          isBulk : false,
          fav_list:[],
          bg_list:[],
          themeColor: '',
          themeFont:'',
          themeFontColor: '',
          enterpriseName:''
        }
    }


    
    componentDidMount = async () => {
        // if(window.localStorage.getItem("filesList")){    
        //     this.setState({
        //         filesList: JSON.parse(localStorage.getItem("filesList"))
        //     })
        // }

        let queries = queryString.parse(this.props.location.search)
        var enterpriseId
        if(queries.enterpriseId != undefined){
            this.setState({enterpriseId : enterpriseId})
            enterpriseId = queries.enterpriseId
        }else{
            this.setState({enterpriseId : 'TaD1VC1Ko'})
            enterpriseId = 'TaD1VC1Ko'
        }
        


        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/enterprise/get",
            params : {
                enterprise_id : enterpriseId,
            }
        }).then(resp => {
        
            document.cookie = `enterpriseId=${resp.data?.data?.enterprise_id}; path=/`;
            document.cookie = `apiKey=${resp.data?.data?.api_key}; path=/`;
        })
        



        let auth_key = this.cookie('auth_token')
        this.setState({
            auth_key: auth_key
        })

        let enterprise_id = '2VOVT5ROF'
        axios({
            method:"GET",
            url:"https://www.clippr.ai/api/v2/backgrounds/fetchEnterpriseBgs/v2",
            params: {
                category : 'automobiles',
                enterprise_id: enterprise_id,
            }
        })
        .then((res) => {
            this.setState({
                planData:res.data?.data
            })
        })

        
        
          

        var passing_location;
        

        passing_location = 'NULL'
        localStorage.setItem("passing_location", passing_location);
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        this.setState({
            emailId : userEmail
        })
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            if(isMobile){
                this.setState({
                    showInstruction:false
                })
            }else{
                this.setState({
                    showInstruction:true
                })
            }
            
        }
        if (this.cookie('auth_token')!="" && this.cookie('auth_token')){
            // axios({
            //     'method':"GET",
            //     'url':`https://www.clippr.ai/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`
            // })
            // .then((res) => {
            //     this.setState({
            //         planData:res.data
            //     })
            // })
            axios({
                'method':"GET",
                'url':`https://www.clippr.ai/api/v2/backgrounds/fav-ent-bgs?auth_key=${cookie('auth_token')}`
                // 'url':'https://www.clippr.ai/api/v2/backgrounds/fav-ent-bgs?auth_key=9569c6d5-52ea-4dcd-ba79-7ca4fde329e7'

            })
            .then((res) => {
                // console.log(res.data.data.fav_list) 
                // console.log(res.data.data.spynelist)
                this.setState({
                    fav_list:res.data.data.fav_list,
                    bg_list:res.data.data.spynelist
                })
                // console.log(res.data)
            })
        }
        if (this.state.walldata.length==0){
            axios({
                'method':"GET",
                'url':`https://www.clippr.ai/api/car-replacement-walls?api_key=${process.env.REACT_APP_API_KEY}&user_id=${cookie('userId')}`,
                
            })
            .then((reswall) => {
                this.setState({
                    walldata:reswall.data,
                    wall:reswall.data[0]['wall_png_url']
                })
            })
        }

        let userId = this.cookie('userId')
        if(userId !== "NA"){
            // fetchCreditUserDetails(userId).then((resp) => {
            //     this.setState({
            //         availableCredit:resp.data.data.creditAvailable,
            //         allotedCredit:resp.data.data.creditAlloted,
            //         creditUsed:resp.data.data.creditUsed
            //     })
            // } )

            let emailId = this.cookie("emailId")
            let auth_key = this.cookie("auth_token")
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v2/credit/fetch",
                params : {
                    // email_id : emailId,
                    auth_key : auth_key
                }
                
            }).then(resp => {
                // console.log(resp)
                if(resp.data.status == 200){
                    this.setState({
                        availableCredit: resp.data.data.credit_available,
                        creditAlloted : resp.data.data.credit_allotted,
                        creditUsed: resp.data.data.credit_used
                    })
                }else{
                    console.log(resp)
                }
            })
                this.setState({
                    userId: userId
                })
        }
        if(this.state.uploadStarteds === "true"){
            // this.getProcessedImages()
            this.getUserCredit()
        }

    }

    // componentDidUpdate(prevProps,prevState) {
    //     console.log(prevState.filesList.length, this.state.filesList.length)
    //     if(prevState.filesList.length != this.state.filesList.length){
    //         sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
    //     }
    // }
    
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    

      handelColorEnhancement = () =>{
        this.setState({
            checkColorEnhancement:!this.state.checkColorEnhancement,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleWindowTransparency = () =>{
        this.setState({
            checkWindowTransparency:!this.state.checkWindowTransparency,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleUnshiftWalls = (data) =>{
        this.state.walldata.unshift(data)
        this.setState({
            wall:data['low_res_wall_url']
        })
      }

    fileSelectedHandlerNew = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0]),
                backgroundEditedUrl:[],
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file),
                backgroundEditedUrl:[],
            },
            
            );
        }
        this.hitapiModal()
      };

      handleDownloadComplete = () => {
          this.setState({downloadComplete: false})
      }

      handleLogo = () => {
        this.setState({
            logoPosition : null,
            selectedLogoFile: null,
            logoUrl: ''
        })
      }

    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }

    handleAngle=(id)=>{
        let selectedAngle = this.cookie("selectedAngle")
        this.setState({
          car_bg_replacement_angle:id,
          selectedAngle:selectedAngle,
          imageSelected:true,
          isEditingFinish:false
        })
    }

    handleId=(id,price)=>{
        this.setState({
            background_id:id,
            bgCredit:price,
            // isEditingFinish:false,
            askEmail:false,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    
    updateCredit = (e) => {
        // e.preventDefault()
        let userId = this.cookie('userId')
        let enterprise_id = 'TaD1VC1Ko'
        let price 
        if(this.state.activateButton === 'Hd'){
            price = this.state.bgCredit
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v4/download-history",
                params : {
                    user_id : userId,
                    enterprise_id : enterprise_id,
                    sku_id: this.state.sku_id
                }
                
            }).then(resp => {
                if(resp.data.status == 404){
                    let auth_key = this.cookie('auth_token')
                    const fd = new FormData()
                    fd.append("auth_key", auth_key)
                    fd.append("credit_reduce",price)
                    axios.put(`https://www.clippr.ai/api/v2/credit/reduce-credit`,fd)
                    // updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
                    .then((res) => {
                        this.setState({
                            availableCredit: this.state.availableCredit - price,
                            // downloadComplete: true
                        })
                        setTimeout(() => {
                            this.setState({downloadComplete: true})
                        },5000)
                        const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                        const fd = new FormData()
                        fd.append("user_id", userId)
                        fd.append("sku_id",this.state.sku_id)
                        fd.append("enterprise_id" , enterprise_id)
                        fd.append("download_hd","true")
                        axios.post(`https://www.clippr.ai/api/v4/update-download-status`,fd)
                        .then(resp => {
                            console.log(resp)
                        })
                    })
                }else{
                    const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                }
            })
        }else{
            price = 0
            const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
            fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();
                
                });
                
            })
        }
   
    }
    askEmail =() => {
        this.setState({
            askEmail:true
        })
    }

    // downloadPopup = () => {
    //     this.setState({isEditingFinish : false})
    // }

    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }

    

    downloadPopup = () => {
        this.setState({isDownload: false})
    }

    
    handleBgList=(i) =>{
        console.log(this.state.bg_list[i]['image_id'])
        const fd = new FormData()
        let auth_key = this.cookie('auth_token')
        fd.append("auth_key", auth_key)
        // fd.append("auth_key", "9569c6d5-52ea-4dcd-ba79-7ca4fde329e7")
        fd.append("background_id",this.state.bg_list[i]['image_id'])
        fd.append("favourable","true")
        fd.append("primary","false")
        axios({
            'method':"POST",
            'url':'https://www.clippr.ai/api/v2/backgrounds/updatefavbg',
            "data":fd
        })
        .then((res) => {
            console.log(res.data.status)
            if (res.data.status==200){
                let sliced=this.state.bg_list.splice(i,1)
                this.state.fav_list.unshift(sliced[0])
                this.setState({})
            }
            // console.log("bglist splice")
            
        })
        
    }
    handlefavList=(i) =>{

        console.log(this.state.fav_list[i]['image_id'])
        const fd = new FormData()
        let auth_key = this.cookie('auth_token')
        fd.append("auth_key", auth_key)
        // fd.append("auth_key", "9569c6d5-52ea-4dcd-ba79-7ca4fde329e7")
        fd.append("background_id",this.state.fav_list[i]['image_id'])
        fd.append("favourable","false")
        fd.append("primary","false")
        axios({
            'method':"POST",
            'url':'https://www.clippr.ai/api/v2/backgrounds/updatefavbg',
            "data":fd
        })
        .then((res) => {
            console.log(res.data.status)
            if (res.data.status==200){
                let sliced=this.state.fav_list.splice(i,1)
                this.state.bg_list.unshift(sliced[0])
                this.setState({})
            }
        })
    }

    hitapi = () => {
        this.setState({
          spinner: true,
          hideLogo:true,
        });
        if(this.state.filesList[0].file !== null){
            const fd = new FormData();
            fd.append("image", this.state.filesList[0].file);
            fd.append("optimization",false)
            let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
            axios.post(`https://www.clippr.ai/api/upload?api_key=${api_key}`,fd,{params:{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"}})
            .then((uploadedResponse) => {
                const replacementFd = new FormData()
                let timestamp= new Date().getTime();
                timestamp.toString();
                let sku_id="sku"+timestamp
                replacementFd.append("user_id", this.cookie("userId"));
                replacementFd.append("sku_id", sku_id);
                replacementFd.append("background", this.state.background_id);
                replacementFd.append("source","Web");
                replacementFd.append("image_url",uploadedResponse.data.image);
                replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
                replacementFd.append("contrast",this.state.checkColorEnhancement);
                if(this.state.tabSelection=="custom_walls"){
                    replacementFd.append("appyType","CW")
                    replacementFd.append("custom_wall",this.state.customWall)
                    if(this.state.customWallPosition>0){
                        replacementFd.append("height",this.state.customWallPosition)
                    }
                    else{
                        replacementFd.append("height","mid")
                    }
                }
                else {
                    
                    replacementFd.append("wall_url","0");
                    replacementFd.append("appyType","VS")
                    replacementFd.append("custom_wall","null")
                    replacementFd.append("height","null")
                }

                if(this.state.selectedLogoFile !== null){
                    replacementFd.append("logo",this.state.selectedLogoFile);
                    replacementFd.append("logo-position",this.state.logoPosition)
                }
                axios
                .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
                .then((res) => {
                    let userEmail = this.cookie("emailId")
                    let _userId_ = this.cookie("userId")
                    let isValidated = this.cookie("isValidated")
                    if(!userEmail){
                        clearInterval(x)
                        var x = setTimeout(this.askEmail, 3000);
                    }
                    let temp = res;
                    if (temp.data['url']) {

                        if (userEmail){       

                            temp.data['authenticated_email_id'] = userEmail;
                            temp.data['user_id'] = _userId_;
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            if (isValidated=='true'){
                                temp.data['otp_verified'] = 'YES';
                            }
                            else {
                                temp.data['otp_verified'] = 'NO';
                            }
                            if (window.localStorage.getItem("clippr_user_email") != null){
                                temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            }
                            else{
                                temp.data['free_trail_email'] = 'NULL';
                            }
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                        }
            
                        else if (window.localStorage.getItem("clippr_user_email") != null) {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }
                        else {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['authenticated_email_id'] = 'NULL';
                            temp.data['free_trail_email'] = 'NULL';
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }

                    }
                    this.setState({ 
                        url:res.data.display_image,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true,
                        waterMarkImg:res.data.watermark_image,
                        isDownload: true,
                        urlDownload: res.data.output_image
                        });
                    
                    window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                    window.sessionStorage.setItem("lastEditedImage", res.data.output_image)
                    window.sessionStorage.setItem("lastRawImage",uploadedResponse.data.image)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch((uploadFail) => {
                this.setState({
                    spinner:false
                })
            })
        }
        else{
            const replacementFd = new FormData()
            // const replacementFd = new FormData()
            let timestamp= new Date().getTime();
            timestamp.toString();
            let sku_id="sku"+timestamp
            replacementFd.append("user_id", this.cookie("userId"));
            replacementFd.append("sku_id", sku_id);
            replacementFd.append("background", this.state.background_id);
            replacementFd.append("source","Web");
            replacementFd.append("image_url",this.state.filesList[0].url);
            replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
            replacementFd.append("contrast",this.state.checkColorEnhancement);
            if(this.state.tabSelection=="custom_walls"){
                replacementFd.append("appyType","CW")
                replacementFd.append("custom_wall",this.state.customWall)
                if(this.state.customWallPosition>0){
                    replacementFd.append("height",this.state.customWallPosition)
                }
                else{
                    replacementFd.append("height","mid")
                }
            }
            else {
                
                replacementFd.append("wall_url","0");
                replacementFd.append("appyType","VS")
                replacementFd.append("custom_wall","null")
                replacementFd.append("height","null")
            }

            if(this.state.selectedLogoFile !== null){
                replacementFd.append("logo",this.state.selectedLogoFile);
                replacementFd.append("logo-position",this.state.logoPosition)
            }
            axios
            .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
            .then((res) => {
                // console.log(res.data.output_image)
                this.setState({ 
                    url:res.data.display_image,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:res.data.watermark_image,
                    isDownload: true,
                    urlDownload: res.data.output_image
                    });
                
                // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
                // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                window.sessionStorage.setItem("lastEditedImage", res.data.display_image)
                window.sessionStorage.setItem("lastRawImagsuve", this.state.orgUrl)
            })
            .catch((err) => {
                this.setState({
                    spinner:false
                })
            })
        }
    };

    handelCarShadow = () => {
        this.setState({
          carShadow:!this.state.carShadow
        })
    }
    dealerLogoFileHandler = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
        fileInput = true;
        }
        if (fileInput) {
        try {
            Resizer.imageFileResizer(
            event.target.files[0],
            200,
            120,
            "JPG",
            100,
            0,
            (uri) => {
                // console.log(uri)
                this.setState(
                    { 
                        selectedLogoFile:uri,
                        // logoUrl:  uri,
                        // logoPosition:"leftTop",
                        // hideLogo:false
                    },
                    );
                
            },
            "blob",
            
            );
        } catch (err) {
            console.log(err);
            }
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
        this.setState(
            {
                logoUrl: [reader.result],
                logoPosition:"leftTop",
                hideLogo:false
            },
        );
        }.bind(this);
        this.setState(
        { 
            // selectedLogoFile:event.target.files[0],
            logoUrl:  URL.createObjectURL(event.target.files[0])
        },
        );
    };

    handleLogoPosition = (e) => {
        console.log(e.target.name)
        this.setState({
          logoPosition:e.target.name,
          hideLogo:false
        })
    }
    handleInstruction = (e) => {
        // e.preventDefault()
        this.setState({
            showInstructionModal:e.target.checked
        })
        window.localStorage.setItem("showInstructionModal", e.target.checked)
    }
    handleInstructionModal = () => {
        this.setState({
            showInstruction:true
        })
    }
    handlePositionChange=(pos)=>{
        this.setState({
            customWallPosition:pos
        })
    }
    handleTabSelection=(selection)=>{
        this.setState({
            tabSelection:selection
        })
    }
    customWallSet= (wall_selected) => {
        this.setState({
            customWall: wall_selected
        })
      }
    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }
    handleActivButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
    }
    dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
    }

    //   onDrop(files) {
    //     console.log('Received files: ', files);
    //     files.map(file => {
    //        return this.setState({
    //              filesList: file
    //         })
    //     })
    //     console.log(this.state.filesList)
    // }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false,
            dropBackground: false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            
            );
        }
        // this.hitapiModal()
      };

    multipleFileUpload = (event) =>{
        // console.log(event.target.files)
        // if(this.state.filesList.length < 36){

            this.state.orgUrl = ''
            this.state.editedUrl = ''
            this.state.spinner = false
            this.state.toggleChange = false
            this.state.toggleStatus = false
            this.state.isEditingFinish = false
            window.sessionStorage.removeItem('lastEditedImage')
            window.sessionStorage.removeItem('lastRawImage')
            if(this.state.project_name === ''){
                this.setState({
                    showProjectNameModal:true
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
        // }else{
        //     this.setState({
        //         maxUpload: true
        //     })
        // }
        // console.log(this.state.filesList)
        // console.log(this.state.setFiles)
    } 
    
   


    multipleFileUploadInterior = (event) =>{

            this.state.orgUrl = ''
            this.state.editedUrl = ''
            this.state.spinner = false
            this.state.toggleChange = false
            this.state.toggleStatus = false
            this.state.isEditingFinish = false
            window.sessionStorage.removeItem('lastEditedImage')
            window.sessionStorage.removeItem('lastRawImage')
            if(this.state.project_name === ''){
                this.setState({
                    showProjectNameModal:true
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesListInterior.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesListInterior: [...this.state.filesListInterior,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesListInterior: [...this.state.filesListInterior,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesListInterior.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
    }

    handleRedirect = ()=>{
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.state.uploadStarteds = false
        this.state.project_name = ''
        this.state.sku_name = ''
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.orgUrl = ''
        this.state.editedUrl = ''
        this.state.spinner = false
        this.state.toggleChange = false
        this.state.toggleStatus = false
        this.state.isEditingFinish = false
        this.state.dropBackground = false
        this.setState({
            tabSelection: 'virtual_studio'
        })
        // window.sessionStorage.removeItem('filesList')
        // window.sessionStorage.removeItem('uploadStarteds')
        // window.sessionStorage.removeItem('downloadZipLink')
        sessionStorage.clear()
        this.state.filesListInterior.length=0

    }

    multipleFileUploadProject = (event) =>{
        // console.log(event.target.files)
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.state.uploadStarteds = false
        this.state.project_name = ''
        this.state.sku_name = ''
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.orgUrl = ''
        this.state.editedUrl = ''
        this.state.spinner = false
        this.state.toggleChange = false
        this.state.toggleStatus = false
        this.state.isEditingFinish = false
        this.setState({
            tabSelection: 'virtual_studio'
        })
        // window.sessionStorage.removeItem('filesList')
        // window.sessionStorage.removeItem('uploadStarteds')
        // window.sessionStorage.removeItem('downloadZipLink')
        sessionStorage.clear()

        if(this.state.project_name === ''){


                    this.setState({
                    showProjectNameModal:true
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
        // console.log(this.state.filesList)
        // console.log(this.state.setFiles)
    }


    

    creditVerify = () => {
        
        let userId = this.cookie('userId')
        let price = this.state.filesList.length
        if(this.state.availableCredit >= price){
           
            
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:false,
                zeroCreditModal:false,
                enoughCreditModal:true,
                
                // uploadStarteds:true,
            })
            
        }else if(this.state.availableCredit == 0){
            this.setState({
                showBulkCreditModal: true,
                zeroCreditModal:true,
                // uploadStarteds:true,
            })
        }else if(this.state.availableCredit < price){
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:true,
                filesList : this.state.filesList.slice(0,this.state.availableCredit)

            })
        }
        // console.log(this.state.filesList)
    }

    hitUploadAPI = async () => {

        let price = this.state.filesList.length

        if(this.state.availableCredit >= price){
            this.getUserCredit()
            this.setState({
                uploadStarteds:true,
                showBulkCreditModal:false,
                enoughCreditModal: false,
                
            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for(let batchIndex=0; batchIndex < batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4*batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            const batchFilesInterior = chunkArray(this.state.filesListInterior, 4)

            for(let batchIndex=0; batchIndex < batchFilesInterior.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFilesInterior[batchIndex].map((file, currIndex) => {
                        let j = 4*batchIndex + currIndex
                        return this.bulkUploadAPIInterior(j)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            this.setState({
                isZipReady: true
            })
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i< this.state.filesList.length;i++){
            //     this.bulkUploadAPI(i)
            // }
            sessionStorage.setItem("uploadStarteds", true);
            
        }else if(this.state.availableCredit < price){
            // const filesListNew = this.state.filesList.slice(0,this.state.availableCredit)
            // this.forceUpdate()

            this.setState((prevState) => {
                return {
                    uploadStarteds:true,
                    showBulkCreditModal:false,
                    notEnoughCreditModal: false,
                    // filesList: prevState.filesList.slice(0,this.state.availableCredit)
                }
            }, () => {
                console.log(this.state.filesList.length)
                sessionStorage.setItem("uploadStarteds", true);
            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for(let batchIndex=0; batchIndex < batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4*batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            const batchFilesInterior = chunkArray(this.state.filesListInterior, 4)

            for(let batchIndex=0; batchIndex < batchFilesInterior.length; batchIndex++){

                try {
                    const resp = await Promise.all(batchFilesInterior[batchIndex].map((file, currIndex) => {
                        let j = 4*batchIndex + currIndex
                        return this.bulkUploadAPIInterior(j)
                    }))
                    console.log(resp)
                    
                } catch (err) {
                    console.error(err)
                    
                }
            }
            this.setState({
                isZipReady: true
            })
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i<this.state.availableCredit;i++){
            //     this.bulkUploadAPI(i)
            // }

            let arr = this.state.filesList.slice(0, this.state.availableCredit)
            this.setState({
                filesList : [...arr]
            }, () => {
                console.log(this.state.filesList.length)
            })
            
            
            
            
            // sessionStorage.setItem("uploadStarteds", true);
            // sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
            
        }
        

    }

    singleUploadAPI = (i) => {
        this.setState({
            spinner: true,
            hideLogo:true,
        });
        let auth_key = this.cookie('auth_token')
        let enterprise_id = 'TaD1VC1Ko'
        const fd = new FormData();
        fd.append("image", this.state.filesList[0].file);
        fd.append("optimization",false)
        fd.append("background_id", this.state.background_id);
        fd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
        fd.append("contrast",this.state.checkColorEnhancement);
        fd.append("prod_cat_id", "cat_d8R14zUNE")
        fd.append("auth_key", auth_key)
        fd.append("prod_sub_cat_id", "Sedan")
        // fd.append("sku_name", this.state.sku_name)
        // fd.append("project_name", this.state.project_name)
        fd.append("enterprise_id", enterprise_id)
        fd.append('processingType', 'REALTIME')
        fd.append('source', 'SINGLEPROCESS')
        if(this.state.tabSelection=="custom_walls"){
            fd.append("appyType","CW")
            fd.append("custom_wall",this.state.customWall)
            if(this.state.customWallPosition>0){
                fd.append("height",this.state.customWallPosition)
            }
            else{
                fd.append("height","mid")
            }
        }
        else {
            
            fd.append("wall_url","0");
            fd.append("appyType","VS")
            fd.append("custom_wall","null")
            fd.append("height","null")
        }

        if(this.state.selectedLogoFile !== null){
            fd.append("logo",this.state.selectedLogoFile);
            fd.append("logo-position",this.state.logoPosition)
        }

        axios.post(`https://www.clippr.ai/api/algo/image/transform`,fd)
        .then((resp) => {
            if(resp.status == 200){
                this.setState({ 
                    url:resp.data.output_image_lres_url,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:resp.data.watermark_image,
                    isDownload: true,
                    urlDownload: resp.data.output_image_hres_url,
                    project_id: resp.data.project_id,
                    sku_id: resp.data.sku_id,
                    });
                
                window.localStorage.setItem("car-replacement-original-url", resp.data.input_image_hres_url)
                window.localStorage.setItem("car-replacement-edited-url",resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastEditedImage", resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastRawImage",resp.data.input_image_hres_url)
            }
        })

    }

    bulkUploadAPI = async (i) => {
        let auth_key = this.cookie('auth_token')
        let enterprise_id = 'TaD1VC1Ko'
        const fd = new FormData();
        fd.append("image", this.state.filesList[i].file)
        fd.append("prod_cat_id", "cat_d8R14zUNE")
        fd.append("auth_key", auth_key)
        fd.append("prod_sub_cat_id", "Sedan")
        fd.append("sku_name", this.state.sku_name)
        fd.append("project_name", this.state.project_name)
        fd.append("background_id", this.state.background_id)
        fd.append("enterprise_id", enterprise_id)
        fd.append('processingType', 'REALTIME')
        fd.append('source', 'BULKPROCESS')
        fd.append('image_category','Exterior')
        
        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesList: [
                ...this.state.filesList.slice(0, i),
                Object.assign({}, this.state.filesList[i], { status: 'Uploading' }),
                ...this.state.filesList.slice(i+1)
            ]
        })
        return axios.post(`https://www.clippr.ai/api/algo/image/transform/v2`,fd)
        .then((uploadResponse) => {
            if(uploadResponse.status == 200){
                // this.setState({
                //     orgUrlList: [...this.state.orgUrlList,uploadResponse.data.input_image_hres_url]
                // })
                this.setState({
                    filesList: [
                        ...this.state.filesList.slice(0, i),
                        Object.assign({}, this.state.filesList[i], { 
                            status: 'Done', 
                            url: uploadResponse.data.input_image_hres_url,
                            imageId: uploadResponse.data.image_id, 
                            projectId: uploadResponse.data.project_id,
                            skuId: uploadResponse.data.sku_id,
                            outputImgUrl: uploadResponse.data.output_image_lres_url,
                            fullScreenOutput:uploadResponse.data.output_image_hres_url,
                        }),
                        ...this.state.filesList.slice(i+1)
                    ]
                })

            }else if(uploadResponse.data.status == 400){
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.reload()
            }
            sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
            return Promise.resolve(uploadResponse)
        })
        .catch((err) => {
            this.setState({
                errorBulkFiles: [...this.state.errorBulkFiles,Object.assign({}, this.state.filesList[i], { 
                    status: 'Error', 
                })],
                filesList: [
                    ...this.state.filesList.slice(0, i),
                    Object.assign({}, this.state.filesList[i], { 
                        status: 'Error', 
                        
                    }),
                    ...this.state.filesList.slice(i+1)
                ]
            })
            return Promise.reject(err)

        })
        
    }
    bulkUploadAPIInterior = async (i) => {
        let auth_key = this.cookie('auth_token')
        let enterprise_id = 'TaD1VC1Ko'
        const fd = new FormData();
        fd.append("image", this.state.filesListInterior[i].file)
        fd.append("prod_cat_id", "cat_d8R14zUNE")
        fd.append("auth_key", auth_key)
        fd.append("prod_sub_cat_id", "Sedan")
        fd.append("sku_name", this.state.sku_name)
        fd.append("project_name", this.state.project_name)
        fd.append("background_id", this.state.background_id)
        fd.append("enterprise_id", enterprise_id)
        fd.append('processingType', 'REALTIME')
        fd.append('source', 'BULKPROCESS')
        fd.append('image_category','Interior')
        
        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesListInterior: [
                ...this.state.filesListInterior.slice(0, i),
                Object.assign({}, this.state.filesListInterior[i], { status: 'Uploading' }),
                ...this.state.filesListInterior.slice(i+1)
            ]
        })
        return axios.post(`https://www.clippr.ai/api/algo/image/transform/v2`,fd)
        .then((uploadResponse) => {
            if(uploadResponse.status == 200){
                // this.setState({
                //     orgUrlList: [...this.state.orgUrlList,uploadResponse.data.input_image_hres_url]
                // })
                this.setState({
                    filesListInterior: [
                        ...this.state.filesListInterior.slice(0, i),
                        Object.assign({}, this.state.filesListInterior[i], { 
                            status: 'Done', 
                            url: uploadResponse.data.input_image_hres_url,
                            imageId: uploadResponse.data.image_id, 
                            projectId: uploadResponse.data.project_id,
                            skuId: uploadResponse.data.sku_id,
                            outputImgUrl: uploadResponse.data.output_image_lres_url,
                            fullScreenOutput:uploadResponse.data.output_image_hres_url,
                        }),
                        ...this.state.filesListInterior.slice(i+1)
                    ]
                })

            }else if(uploadResponse.data.status == 400){
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.reload()
            }
            sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
            return Promise.resolve(uploadResponse)
        })
        .catch((err) => {
            this.setState({
                errorBulkFiles: [...this.state.errorBulkFiles,Object.assign({}, this.state.filesListInterior[i], { 
                    status: 'Error', 
                })],
                filesListInterior: [
                    ...this.state.filesListInterior.slice(0, i),
                    Object.assign({}, this.state.filesListInterior[i], { 
                        status: 'Error', 
                        
                    }),
                    ...this.state.filesListInterior.slice(i+1)
                ]
            })
            return Promise.reject(err)

        })
        
    }

    handleUploadLimitModal = () => {
        this.setState({
            maxUpload: false
        })
    }

    emptyMultipleFileArray = () => {
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.setState({
            maxUpload: false
        })
    }
    updateMultipleImageList = (i) => {
        this.state.filesList.splice(i,1) 
        this.state.setFiles.splice(i,1)
        // console.log(this.state.filesList)
       this.forceUpdate()
    }

    updateMultipleImageListInterior = (i) => {
        this.state.filesListInterior.splice(i,1) 
        this.state.setFiles.splice(i,1)
        // console.log(this.state.filesList)
       this.forceUpdate()
    }

    handleBulkCreditModal = () => {
        this.setState({
            showBulkCreditModal: false
        })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        this.setState({
            project_name: e.target.value,
            sku_name:e.target.value,
            projectNameExists : false
        })
        sessionStorage.setItem("project_name", e.target.value);
        sessionStorage.setItem("sku_name", e.target.value);
    
    }
    handleProjectNameModal =()=>{
        let auth_key = this.cookie("auth_token")
        let user_id = this.cookie("userId")
        let enterprise_id = 'TaD1VC1Ko'
        const fd = new FormData()
        fd.append("auth_key", auth_key);
        fd.append("user_id", user_id);
        fd.append("enterprise_id", enterprise_id);
        fd.append("project_name",this.state.project_name)
        axios.post(`https://www.clippr.ai/api/v2/project/verify-project`,fd)
        .then((resp) => {
            if(resp.data.status == 404){
                this.setState({
                    showProjectNameModal: false,
                    projectNameExists: false
                })
            }else if(resp.data.status == 200){
                this.setState({
                    projectNameExists: true
                })
            }
            // console.log(resp)
        })
        
    }

    getProcessedImages = async () => {
        let auth_key = this.cookie('auth_token')
        if(this.state.availableCredit >= this.state.filesList.length){
            for(let i=0; i< this.state.filesList.length; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
            
        }else if(this.state.availableCredit < this.state.filesList.length){
            for(let i=0; i< this.state.availableCredit; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
        }
        this.setState({
            isZipReady: true
        })
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
        sessionStorage.setItem("isZipReady", true);
        this.props.history.push('/tech/cars/try')

    }

    handleRefundModal = () => {
        this.setState({
            showRefundModal: false
        })
        this.props.history.push('/whitelabel/cars/try')
    }

    getRefundModal = (i) => {
        this.setState({
            refundImgUrl: this.state.filesList[i].outputImgUrl,
            refundOrgUrl: this.state.filesList[i].url,
            showRefundModal: true,
            refundImgId:this.state.filesList[i].imageId,
            refundNum: i
        })
    }
    updateEditedImageList = () => {
        this.state.filesList.splice(this.state.refundNum,1)
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
    }

    downloadImages = () => {
        this.setState({
            spinnerDownload: true
        })
        axios({
            method: "GET",
            url : "https://www.spyne.ai/download/shoot/ai-images",
            params : {
                skuId : this.state.filesList[0].skuId,
                projectId : this.state.filesList[0].projectId,
            }
        })
        .then(resp => {
            this.setState({
                downloadZipLink: resp.data
            })
            const a = document.createElement("a")
            a.href = resp.data
            a.download = true
            a.click()
            sessionStorage.setItem("downloadZipLink" , resp.data)
            this.setState({
                bulkDownloadDone: true,
                spinnerDownload: false
            })
            sessionStorage.setItem("bulkDownloadDone" , true)
        })
    }

    getUserCredit = async () => {
        let emailId = this.cookie("emailId")
        // if(){
        let auth_key = this.cookie("auth_token") 
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/credit/fetch",
                            // data : fd
                            params : {
                                // email_id : emailId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status == 200){
                            if(this.state.bulkDownloadDone == true){
                                return true
                            }else{
                                this.setState({
                                    availableCredit: resp.data.data.credit_available,
                                    allotedCredit : resp.data.data.credit_allotted,
                                    creditUsed: resp.data.data.credit_used
                                })
                                return false
                            }
                            // this.setState({
                            //     availableCredit: resp.data.data.credit_available,
                            //     creditAlloted : resp.data.data.credit_allotted,
                            //     creditUsed: resp.data.data.credit_used
                            // })
                            // return false
                        }else if(resp.data.status == 400){
                            document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            window.sessionStorage.clear()
                            window.location.reload()
                        }
                        
                        
                    },
                    interval: 5000,
                })
    }
      
    render() {
        
        return (
            <div id="app-2">
                <div className={styles['bg-try-mob']}>
                    <div className="container text-center">
                        <h4>Welcome to <span >{this.state.enterpriseName}</span> </h4>
                        <h6>We are currently only available on <br /><span style={{fontWeight:"600"}}>Web/Desktop</span></h6>
                        <img src="https://storage.googleapis.com/spyne-website/Screen%20rotation.gif"/>
                        <p>Please switch from Mobile to Web</p>
                    </div>
                </div>
            <div className={styles['bg-try']}>
            <Helmet>
                <title>Change Car Background Instantly | AI Car Photo Editor.Try Now</title>
                <meta property="og:title" content="Change Car Background Instantly | AI Car Photo Editor.Try Now"/>
                <meta property="og:description" content="Replace Your Car Images With Studio-Setting Backgrounds in Just 3 Seconds With Spyne AI Car Photo Editor. Instantly Remove Any Car Image Background and Give Your Car Image a New Look. 100% Automated Editing in Just a Click. Simply Drag And Drop Your Photo(s) And See How The AI Transforms Your Photo(s) In Seconds"/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
                
                
            </Helmet>
            {this.state.spinnerDownload ? 
                <Spinner />
                :
                null
            }
            
            {
            this.state.filesList.length == 0 && this.state.filesListInterior.length ==0 ?
            <>  
                {/* <LandingModal
                    showInstruction={this.state.showInstruction}
                    hideModal = {this.hideLandingModal}
                    productHunt={this.state.productHunt}
                        /> */}
                {/* {this.state.productHunt == 'productHunt' ? 

                    <div className={styles['product-hunt-strip']}>
                        <h6>Hey ProductHunters! Sign up today and get extra 5 free credits </h6>
                    </div>
                : null
                } */}
                
                                <div className={styles['header-section']}>
                                    <p className={styles['heading-text']}>Car Background Replacement</p>
                                    <p className={styles['heading-para']}><strong>Step 1 - Upload </strong> Generate Professional Automobile Images in a  <span>Single Click </span> using AI</p>
                                    <h4> </h4>
                                
                                </div>
                                <div className={[styles['car-main-wrapper'],""].join(" ")} id="cars-try-modal">
                                    <div className={[styles[''],"container-fluid"].join(" ")}>
                                        
                                        <div className={[styles['car-try-wrapper'],'row'].join(' ')}> 
                                            <div className={[styles['car-left-section'],'col-md-5'].join(' ')}>
                                            <Dropzone
                                                onDrop={(acceptedFiles) =>
                                                    this.multipleFileUpload(acceptedFiles)
                                                }
                                                onDragEnter={this.dragStart}
                                                onDragLeave={this.dragLeave}
                                                >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                    {...getRootProps({
                                                        onClick: (event) => event.stopPropagation(),
                                                    })}
                                                    className={styles["dropzone1"]}
                                                    >
                                                    {this.state.dropBackground ? (
                                                        <section className={[styles["drop-box-text"]]}>
                                                            <div className={styles['container']}>
                                                                <div className={styles['row']}>
                                                                    <div className={[styles['try-drag-drop1'],'col-md-5'].join(' ')}>
                                                                        <div className={styles['dropzone1']}>
                                                                            <h6><span>Exterior <br/>Images <br/>Here </span></h6>
                                                                            
                                                                        </div>
                                                                    </div>
                                                            
                                                                <div className={[styles['drag-image-drop'],'col-md-2'].join(' ')}>Drop Image Anywhere</div>
                                                                    <div className={[styles['try-drag-drop1'],'col-md-5'].join(' ')}>
                                                                        <div className={styles['dropzone1']}>
                                                                            <h6><span>Inetior <br/>Images <br/>Here </span></h6>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    ) : null}
                                                        
                                                        <div className={[styles['car-mid-section'],''].join(' ')}>
                                                            
                                                            <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                            <h6><span>Exterior <br/>Images <br/>Here </span><img className={styles['cars-img']} src="https://storage.googleapis.com/spyne-website/whitelabel/darcars/upexterior.svg"/></h6>
                                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                            {/* <p className={styles["para-11"]}>DROP IMAGE HERE </p>
                                                                            <p class={styles["para-2"]}>or <span>SELECT IMAGE ON YOUR COMPUTER</span> </p> */}
                                                                            {/* <p className={styles['supports1']}>Supports jpeg, jpg, bmp & png</p> */}
                                                                            <p>Supports jpeg, jpg, bmp & png<br/>
                                                                            (maximun 36 images)</p>
                                                                            <input  onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <section className={[styles['try-drag-drop'],'d-block d-md-none'].join(' ')}>
                                                                <div  className={styles['dropzone1']}>
                                                                        <div className={styles['choose-button']}>
                                                                        <input 
                                                                        style={{ display: "none" }}
                                                                        type="file"
                                                                        onChange={this.multipleFileUpload}
                                                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                                                        ></input>
                                                                        <button  onClick={() => this.fileInput.click()} className="btn">Upload Image</button>
                                                                    </div>
                                                                </div>
                                                            </section>

                                                           
                                                        </div>
                                                        <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                )}
                                            </Dropzone>
                                            
                                            </div>
                                            <div className={[styles['car-left-section'],'col-md-5 offset-md-2'].join(' ')}>
                                            <Dropzone
                                                onDrop={(acceptedFiles) =>
                                                    this.multipleFileUploadInterior(acceptedFiles)
                                                }
                                                onDragEnter={this.dragStart}
                                                onDragLeave={this.dragLeave}
                                                >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                    {...getRootProps({
                                                        onClick: (event) => event.stopPropagation(),
                                                    })}
                                                    className={styles["dropzone1"]}
                                                    >
                                                    {this.state.dropBackground ? (
                                                        <section className={[styles["drop-box-text"]]}>
                                                            <div className={styles['container']}>
                                                                <div className={styles['row']}>
                                                                    <div className={[styles['try-drag-drop1'],'col-md-5'].join(' ')}>
                                                                        <div className={styles['dropzone1']}>
                                                                            <h6><span>Interior <br/>Images <br/>Here </span></h6>
                                                                            
                                                                        </div>
                                                                    </div>
                                                            
                                                                <div className={[styles['drag-image-drop'],'col-md-2'].join(' ')}>Drop your Anywhere</div>
                                                                    <div className={[styles['try-drag-drop1'],'col-md-5'].join(' ')}>
                                                                        <div className={styles['dropzone1']}>
                                                                            <h6><span>Exterior <br/>Images <br/>Here </span></h6>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    ) : null}
                                                        
                                                        <div className={[styles['car-mid-section'],''].join(' ')}> 
                                                            {/* <button id="upload_widget" className={[styles['dropzone'],"cloudinary-button"].join(' ')} onClick={() => myWidget.open()} >Upload files</button> */}
                                                             <Dropzone onDrop={acceptedFiles => this.multipleFileUploadInterior(acceptedFiles)} >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                        <h6><span>Interior<br/> Images<br/> Here</span> <img className={styles['int-cars']} src="https://storage.googleapis.com/spyne-website/whitelabel/darcars/upinterior.svg"/></h6>
                                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                            <p>Supports jpeg, jpg, bmp & png<br/>
                                                                            (maximun 36 images)</p>
                                                                            <input  onChange={this.multipleFileUploadInterior} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <section className={[styles['try-drag-drop'],'d-block d-md-none'].join(' ')}>
                                                                <div  className={styles['dropzone1']}>
                                                                        <div className={styles['choose-button']}>
                                                                        <input 
                                                                        style={{ display: "none" }}
                                                                        type="file"
                                                                        onChange={this.multipleFileUploadInterior}
                                                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                                                        ></input>
                                                                        <button  onClick={() => this.fileInput.click()} className="btn">Upload Image</button>
                                                                    </div>
                                                                </div>
                                                            </section> 

                                                            {/* <div className={[styles['choose-button'],'text-center d-md-block d-none'].join(' ')}>
                                                                <input 
                                                                style={{ display: "none" }}
                                                                type="file"
                                                                onChange={this.fileSelectedHandler}
                                                                ref={(fileInput) => (this.fileInput = fileInput)}
                                                                ></input>
                                                                <button  onClick={() => this.fileInput.click()} className=" d-md-block d-none">Choose Image</button>
                                                            </div> */}
                                                        </div>
                                                        <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                )}
                                            </Dropzone>
                                            
                                           
                                            
                                          

                                            </div>
                                            
                                            {/* <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/whitelabel/img1.png"></img>
                                                    <p><span>1</span> Upload </p>
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],styles['try-png-image'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/whitelabel/img2.png"></img>
                                                    <p><span>2</span> Generate </p>
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/whitelabel/img3.png"></img>
                                                    <p><span>3</span> Custom </p>
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],'col-md-12 col-12 d-block d-md-none'].join(' ')}> 
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/car-sm.png"/>
                                            </div> */}
                                            
                                                
                                            {/* <div className={[styles['right-section'],''].join(' ')}>
                                                <div>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right%20side.png"></img>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                             
                
            </>
            :
            <div>
                <BulkCarsBackground 
                    setFiles={this.state.setFiles}
                    filesList={this.state.filesList}
                    filesListInterior={this.state.filesListInterior}
                    multipleFileUpload={this.multipleFileUpload}
                    multipleFileUploadInterior={this.multipleFileUploadInterior}
                    multipleFileUploadProject={this.multipleFileUploadProject}
                    handleId={this.handleId}
                    editedUrl={this.state.url}
                    handleToggle={this.handleToggle}
                    toggleStatus={this.state.toggleStatus}
                    handleAngle={this.handleAngle}
                    imageSelected={this.state.imageSelected}
                    handleClick={this.hitapi}
                    handleModalApi={this.hitapiModal}
                    isEditingFinish={this.state.isEditingFinish}
                    handleDownload={this.updateCredit}
                    creditUsed={this.state.creditUsed}
                    availableCredit={this.state.availableCredit}
                    allotedCredit={this.state.allotedCredit}
                    defaultChecked={this.state.carShadow}
                    planData={this.state.planData}
                    spinner={this.state.spinner}
                    toggleChange={this.state.toggleChange}
                    background_id={this.state.background_id}
                    dealerLogoFileHandler = {this.dealerLogoFileHandler}
                    handleLogoPosition = {this.handleLogoPosition}
                    logoUrl = {this.state.logoUrl}
                    logoPosition = {this.state.logoPosition}
                    hideLogo={this.state.hideLogo}
                    askEmail={this.state.askEmail}
                    backgroundUrl={this.state.backgroundEditedUrl}
                    imageEdited = {this.state.imageEdited}
                    downloadPopup = {this.downloadPopup}
                    handleActivateButton={this.handleActivButton}
                    activateButton={this.state.activateButton}
                    downloadComplete={this.state.downloadComplete}
                    handleDownloadComplete={this.handleDownloadComplete}
                    isDownload={this.state.isDownload}
                    handleWindowTransparency = {this.handleWindowTransparency}
                    handelColorEnhancement = {this.handelColorEnhancement}
                    checkColorEnhancement = {this.state.checkColorEnhancement}
                    checkWindowTransparency = {this.state.checkWindowTransparency}
                    bgCredit={this.state.bgCredit}
                    handleLogo = {this.handleLogo}
                    maxUpload = {this.state.maxUpload}
                    handleUploadLimitModal={this.handleUploadLimitModal}
                    emptyMultipleFileArray={this.emptyMultipleFileArray}
                    updateMultipleImageList = {this.updateMultipleImageList}
                    updateMultipleImageListInterior = {this.updateMultipleImageListInterior}
                    creditVerify={this.creditVerify}
                    showBulkCreditModal={this.state.showBulkCreditModal}
                    handleBulkCreditModal={this.handleBulkCreditModal}
                    showProjectNameModal={this.state.showProjectNameModal}
                    handleProjectName={this.handleProjectName}
                    handleProjectNameModal={this.handleProjectNameModal}
                    notEnoughCreditModal={this.state.notEnoughCreditModal}
                    enoughCreditModal={this.state.enoughCreditModal}
                    zeroCreditModal={this.state.zeroCreditModal}
                    hitUploadAPI={this.hitUploadAPI}
                    uploadStarteds={this.state.uploadStarteds}
                    imgStatusDone={this.state.imgStatusDone}
                    imgStatusProcess={this.state.imgStatusProcess}
                    imgStatusUpload={this.state.imgStatusUpload}
                    showRefundModal={this.state.showRefundModal}
                    handleRefundModal={this.handleRefundModal}
                    userId = {this.state.userId}
                    refundImgUrl={this.state.refundImgUrl}
                    getRefundModal={this.getRefundModal}
                    refundOrgUrl={this.state.refundOrgUrl}
                    refundImgId={this.state.refundImgId}
                    refundNum={this.state.refundNum}
                    updateEditedImageList={this.updateEditedImageList}
                    downloadImages = {this.downloadImages}
                    downloadZipLink = {this.state.downloadZipLink}
                    isZipReady = {this.state.isZipReady}
                    bulkDownloadDone={this.state.bulkDownloadDone}
                    getUserCredit={this.getUserCredit}
                    auth_key={this.state.auth_key}
                    bulkUploadAPI={this.bulkUploadAPI}
                    emailId = {this.state.emailId}
                    project_name={this.state.project_name}
                    projectNameExists = {this.state.projectNameExists}
                    handleRedirect = {this.handleRedirect}
                    bulkUploadAPIInterior={this.bulkUploadAPIInterior}
                    />
            {/* {this.state.filesList.length === 1 && this.state.isBulk===false?
            
            <CarsBackground
            
                showInstruction={this.state.showInstruction}
                hideModal = {this.hideLandingModal}
                selectedFile={this.state.selectedFile}
                fileSelectedHandler={this.fileSelectedHandler}
                fileSelectedHandlerNew={this.fileSelectedHandlerNew}
                handlePositionChange={this.handlePositionChange}
                handleTabSelection={this.handleTabSelection}
                handleUnshiftWalls={this.handleUnshiftWalls}
                orgUrl={this.state.filesList[0].url}
                handleId={this.handleId}
                customWallPosition={this.state.customWallPosition}
                handlewalls={this.handlewalls}
                customWallSet={this.customWallSet}
                editedUrl={this.state.url}
                customWall={this.state.customWall}
                handleToggle={this.handleToggle}
                toggleStatus={this.state.toggleStatus}
                handleAngle={this.handleAngle}
                imageSelected={this.state.imageSelected}
                handleClick={this.singleUploadAPI}
                handleModalApi={this.hitapiModal}
                isEditingFinish={this.state.isEditingFinish}
                handleDownload={this.updateCredit}
                creditUsed={this.state.creditUsed}
                availableCredit={this.state.availableCredit}
                creditAlloted={this.state.creditAlloted}
                defaultChecked={this.state.carShadow}
                planData={this.state.planData}
                floorData={this.state.floordata}
                wallData={this.state.walldata}
                spinner={this.state.spinner}
                toggleChange={this.state.toggleChange}
                background_id={this.state.background_id}
                dealerLogoFileHandler = {this.dealerLogoFileHandler}
                handleLogoPosition = {this.handleLogoPosition}
                logoUrl = {this.state.logoUrl}
                logoPosition = {this.state.logoPosition}
                hideLogo={this.state.hideLogo}
                askEmail={this.state.askEmail}
                backgroundUrl={this.state.backgroundEditedUrl}
                imageEdited = {this.state.imageEdited}
                downloadPopup = {this.downloadPopup}
                handleActivateButton={this.handleActivButton}
                activateButton={this.state.activateButton}
                downloadComplete={this.state.downloadComplete}
                handleDownloadComplete={this.handleDownloadComplete}
                isDownload={this.state.isDownload}
                // downloadPopup={this.downloadPopup}
                handleWindowTransparency = {this.handleWindowTransparency}
                handelColorEnhancement = {this.handelColorEnhancement}
                checkColorEnhancement = {this.state.checkColorEnhancement}
                checkWindowTransparency = {this.state.checkWindowTransparency}
                bgCredit={this.state.bgCredit}
                handleLogo = {this.handleLogo}
                multipleFileUploadProject={this.multipleFileUploadProject}
                fav_list={this.state.fav_list}
                bg_list={this.state.bg_list}
                handleBgList={this.handleBgList}
                handlefavList={this.handlefavList}
                // filesList={this.state.filesList}
            /> 
            :
            <BulkCarsBackground 
            setFiles={this.state.setFiles}
            filesList={this.state.filesList}
            multipleFileUpload={this.multipleFileUpload}
            multipleFileUploadProject={this.multipleFileUploadProject}
            handleId={this.handleId}
            editedUrl={this.state.url}
            handleToggle={this.handleToggle}
            toggleStatus={this.state.toggleStatus}
            handleAngle={this.handleAngle}
            imageSelected={this.state.imageSelected}
            handleClick={this.hitapi}
            handleModalApi={this.hitapiModal}
            isEditingFinish={this.state.isEditingFinish}
            handleDownload={this.updateCredit}
            creditUsed={this.state.creditUsed}
            availableCredit={this.state.availableCredit}
            allotedCredit={this.state.allotedCredit}
            defaultChecked={this.state.carShadow}
            planData={this.state.planData}
            spinner={this.state.spinner}
            toggleChange={this.state.toggleChange}
            background_id={this.state.background_id}
            dealerLogoFileHandler = {this.dealerLogoFileHandler}
            handleLogoPosition = {this.handleLogoPosition}
            logoUrl = {this.state.logoUrl}
            logoPosition = {this.state.logoPosition}
            hideLogo={this.state.hideLogo}
            askEmail={this.state.askEmail}
            backgroundUrl={this.state.backgroundEditedUrl}
            imageEdited = {this.state.imageEdited}
            downloadPopup = {this.downloadPopup}
            handleActivateButton={this.handleActivButton}
            activateButton={this.state.activateButton}
            downloadComplete={this.state.downloadComplete}
            handleDownloadComplete={this.handleDownloadComplete}
            isDownload={this.state.isDownload}
            handleWindowTransparency = {this.handleWindowTransparency}
            handelColorEnhancement = {this.handelColorEnhancement}
            checkColorEnhancement = {this.state.checkColorEnhancement}
            checkWindowTransparency = {this.state.checkWindowTransparency}
            bgCredit={this.state.bgCredit}
            handleLogo = {this.handleLogo}
            maxUpload = {this.state.maxUpload}
            handleUploadLimitModal={this.handleUploadLimitModal}
            emptyMultipleFileArray={this.emptyMultipleFileArray}
            updateMultipleImageList = {this.updateMultipleImageList}
            creditVerify={this.creditVerify}
            showBulkCreditModal={this.state.showBulkCreditModal}
            handleBulkCreditModal={this.handleBulkCreditModal}
            showProjectNameModal={this.state.showProjectNameModal}
            handleProjectName={this.handleProjectName}
            handleProjectNameModal={this.handleProjectNameModal}
            notEnoughCreditModal={this.state.notEnoughCreditModal}
            enoughCreditModal={this.state.enoughCreditModal}
            zeroCreditModal={this.state.zeroCreditModal}
            hitUploadAPI={this.hitUploadAPI}
            uploadStarteds={this.state.uploadStarteds}
            imgStatusDone={this.state.imgStatusDone}
            imgStatusProcess={this.state.imgStatusProcess}
            imgStatusUpload={this.state.imgStatusUpload}
            showRefundModal={this.state.showRefundModal}
            handleRefundModal={this.handleRefundModal}
            userId = {this.state.userId}
            refundImgUrl={this.state.refundImgUrl}
            getRefundModal={this.getRefundModal}
            refundOrgUrl={this.state.refundOrgUrl}
            refundImgId={this.state.refundImgId}
            refundNum={this.state.refundNum}
            updateEditedImageList={this.updateEditedImageList}
            downloadImages = {this.downloadImages}
            downloadZipLink = {this.state.downloadZipLink}
            isZipReady = {this.state.isZipReady}
            bulkDownloadDone={this.state.bulkDownloadDone}
            getUserCredit={this.getUserCredit}
            auth_key={this.state.auth_key}
            bulkUploadAPI={this.bulkUploadAPI}
            emailId = {this.state.emailId}
            project_name={this.state.project_name}
            projectNameExists = {this.state.projectNameExists}
            />
            } */}
            
            
            
            </div>
            
            } 
            </div>
        </div>
        )
    }
}