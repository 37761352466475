import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/Model/DownloadCompleteModal.module.css'
import axios from 'axios'
import {cookie} from '../Utils'

class DownloadCompleteModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            reviewCompleted: false,
            isCommentDone: false,
            decription: '',
            editedImgUrl: '',
            likes: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }
    

    //  handleLikeBtn = () => {
    //     this.setState({
    //         likes: true
    //     })
    //     this.insertUserReview()
    //  }

    //  handleDislikeBtn = () => {
    //      this.setState({
    //          likes: false
    //         })
    //         this.insertUserReview()   
    //  }

     handleChange = (e) => {
         this.setState({
             description: e.target.value
         })
         console.log(this.state.description)
     }


     insertUserReview = (likes) => {
        //  e.preventDefault()
        let userId = cookie("userId")
         axios({
             method: "GET",
             url: "https://www.spyne.ai/credit-user/insert-review",
             params: {
                userId: userId,
                description: this.state.description,
                editedUrl: this.props.editedUrl,
                likes: likes,
                orgUrl: '',
                productCategory: 'Automobile',
             }
         }).then(resp => {
             console.log(resp)
             if(likes){
                this.setState({
                    reviewCompleted: true,
                 })
                 setTimeout(() => {
                     this.setState({
                        reviewCompleted: false,
                        isCommentDone: false
                     })
                     this.props.handleDownloadComplete()
                 },2000)
             }else{
                 if(this.state.decription == ''){
                    this.setState({
                        isCommentDone: true
                     })
                 }else{
                    this.setState({
                        reviewCompleted: true,
                     })
                 }
                
             }
            
         }).catch(err => {
            this.setState({
                reviewCompleted: false,
             })
         })
     }

     insertUserReviewComment = () => {
        //  e.preventDefault()
        let userId = cookie("userId")
         axios({
             method: "GET",
             url: "https://www.spyne.ai/credit-user/insert-review",
             params: {
                userId: userId,
                description: this.state.description,
                editedUrl: this.props.editedUrl,
                likes: this.state.likes,
                orgUrl: this.props.orgUrl,
                productCategory: 'Automobile',
             }
         }).then(resp => {
            //  console.log(resp)
            this.setState({
                reviewCompleted: true,
             })
             setTimeout(() => {
                this.setState({
                   reviewCompleted: false,
                   isCommentDone: false
                })
                this.props.handleDownloadComplete()
            },2000)
            
         }).catch(err => {
            this.setState({
                reviewCompleted: false,
             })
         })
     }

     handleDownloadPopup = () => {
         this.setState({
            reviewCompleted: false,
            isCommentDone: false
         })
         this.props.handleDownloadComplete()
     }

    render() { 
        return ( 
                <Modal
                show={this.props.showDownloadModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                >
                    {/* <Modal.Body className="text-right cursor-pointer">
                        <img className={styles['close-btn']} onClick={this.handleDownloadPopup} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                    </Modal.Body> */}
                    {this.state.reviewCompleted ?
                        <Modal.Body className={[styles['success-cmnt'],"text-center"]}>
                            {/* <div className="text-right cursor-pointer">
                                <img className={styles['close-btn']} onClick={this.handleDownloadPopup} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                            </div> */}
                            <p>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/tick.png"/>
                                Thanks for your Feedback!</p>
                        </Modal.Body>
                        :
                        
                        <Modal.Body className="">
                            <div className="text-right cursor-pointer">
                                <img className={styles['close-btn']} onClick={this.handleDownloadPopup} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                            </div>
                            {
                                this.state.isCommentDone
                                ?
                                <div className="container ">
                                    <div className={[styles['cnmt-sec']]}>
                                        <h6>How can we improve?</h6>
                                        <p>Do you have any suggestion or found some bug? please let us know in the field below.</p>
                                        <form>
                                            <textarea onChange={this.handleChange} placeholder="Describe your issue or idea...."></textarea>
                                        </form>
                                        <div className="text-center">
                                            <button onClick={this.insertUserReviewComment}>Submit</button>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                :
                                <div className="row text-center">
                                    <div className={[styles['download-img'],"col-12 col-md-6"].join(' ')}>
                                        <img src={this.props.editedUrl}/>
                                    </div>
                                    <div className={[styles['gif-sec'],"col-md-6 col-12"].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/36591-beertech-check%20(6).gif" alt=""/>
                                        <h4>Download Completed!</h4>
                                        <div className={[styles['download-right']]}>
                                            <p>Rate this Output : 
                                                <img onClick={() => this.insertUserReview(true)} className="pl-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/up.png"/>
                                                <img onClick={() => this.insertUserReview(false)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/down.png"/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </Modal.Body>
                    }
                    

                </Modal> 
        );
    }
}
 
export default DownloadCompleteModal;