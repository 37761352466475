import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import Dropzone from 'react-dropzone'
import styles from '../../../Css/AppDashboard/User.module.css'
import BackgroundUser from './BackgroundUser'
import axios from 'axios'
import queryString from 'query-string'
import { cookie, getCookie } from '../../Common/Utils'
import { AuthContext } from '../../../context/GlobalState'

const EditUser = (props) => {

    const [openModal, setOpenModal] = useState(false)
    const [favoriteBgList, setFavoriteBgList] = useState([])
    const [otherBgList, setOtherBgList] = useState([])
    const [planData, setPlanData] = useState([])
    const [backgroundId, setBackgroundId] = useState('')
    const [bgPrice, setBgPrice] = useState('')
    const [primary, setPrimary] = useState('')
    const [countries, setCountries] = useState([])
    const [userCreated, setUserCreated] = useState(true)
    const [logoScreen, setLogoScreen] = useState(true)
    const [user, setUser] = useState({})
    const username = React.createRef()
    const emailId = React.createRef()
    const phoneNumber = React.createRef()
    const country = React.createRef()
    const dealerId =  React.createRef()
    const file = React.createRef()
    const location = useLocation()
    const history = useHistory()
    const context = useContext(AuthContext)

    useEffect(() => {
        if (!context.auth.loggedIn && cookie('role') !== 'ADMIN') {
			history.push('/login')
		}
    })

    const handleInput = (e) => {
        let newUser = {...user}
        newUser[e.currentTarget.name] = e.currentTarget.value
        setUser({...user, ...newUser})
    }

    function fetchBackgroundList(auth_token) {
        return axios({
            url: 'http://localhost:5000/api/v2/backgrounds/fav-ent-bgs?auth_key=' + auth_token,
            method: 'GET',
            params: {
                auth_key: auth_token
            }
        })
    }

    function planDataList() {
        return axios({
            'method':"GET",
            'url':`http://localhost:5000/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`
        })
    }

    function nextScreen (next) {
        setLogoScreen(next)
    }

    useEffect(() => {
        let queries = queryString.parse(location.search)

        // Get User Details from localStorage
        let user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            axios({
                url: `http://localhost:5000/api/v2/user/get?email_id=${user.emailId}&auth_key=${user.auth_token}`,
                method: 'GET'
            })
            .then((resp) => {
                if (resp.data?.status === 200) {
                    setUser({...user, ...resp.data?.data})
                }
            })
        }
    }, [])

    useEffect(() => {
        Promise.all([planDataList(), getCountry()])
        .then(([plans, countries]) => {
            setPlanData([...plans.data])
            setCountries([...countries.data.data])
        })
    }, [])

    const editUser = async (e) => {
        e.preventDefault()
        // Validate Required Fields
        const fd = new FormData()
        fd.append('user_name', username.current.value)
        fd.append('email_id', emailId.current.value)
        fd.append('contact_no', phoneNumber.current.value)
        fd.append('country', country.current.value)
        fd.append('dealer_id', user['dealerId'])
        // fd.append('logo_position', user['logoPosition'])
        fd.append('user_id', user['userId'])
        fd.append('enterprise_id', cookie('enterpriseId'))

        const resp = await axios({
            url: `http://localhost:5000/api/v2/enterprise-dealer/edit`,
            method: 'PUT',
            data: fd
        })

        if (resp.data?.status === 200) {
            setUser({...resp.data})
            const backgrounds = await fetchBackgroundList(user.auth_token)
            setFavoriteBgList([...backgrounds.data.data.fav_list])
            setOtherBgList([...backgrounds.data.data.spynelist])
            setUserCreated(true)
        }

    }

    function getCountry() {
        return axios({
            url: `${process.env.REACT_APP_BASEURL}/v2/user/countries`,
            method: 'GET'
        })
    }

    async function updateBackgroundList(auth_token) {
        const backgrounds = await fetchBackgroundList(auth_token)
        setFavoriteBgList([...backgrounds.data.data.fav_list])
        setOtherBgList([...backgrounds.data.data.spynelist])
    }

    async function uploadFile(e) {
        e.preventDefault()
        const fd = new FormData()
        fd.append('logo', file.current.files[0])
        fd.append('user_id', user['userId'])
        fd.append('enterprise_id', cookie('enterpriseId'))
        fd.append('dealer_id', user['dealerId'])
        fd.append('logo_position', user['logoPosition'])

        const resp = await axios({
            url: `http://localhost:5000/api/v2/enterprise-dealer/upload-logo`,
            method: 'POST',
            data: fd
        })

        if (resp.data?.status === 200) {
            let newUser = {...user}
            newUser['logoUrl'] = resp.data?.data
            setUser({...newUser})
        }

    }



    function UserForm() {
        return (
            <form className={[styles[''],'row justify-content-center align-items-center'].join(' ')} onSubmit={editUser}>
                <div className="col-6">
                    <label>Name* <span>(mandatory)</span>
                        <input type="text" 
                            placeholder="Business Name" 
                            required 
                            onInput={handleInput} 
                            name="user_name"
                            defaultValue={user['user_name']}
                            ref={username}
                            disabled />
                    </label>
                    <label>Email ID <span>(optional)</span>
                        <input type="email" 
                            placeholder="Email ID" 
                            onInput={handleInput} 
                            name="email_id"
                            defaultValue={user['email_id']}
                            ref={emailId} />
                    </label>
                    <label>Dealer ID* <span>(mandatory)</span>
                        <input type="text" 
                            placeholder="" 
                            onInput={handleInput} 
                            name="dealerId"
                            defaultValue={user['dealerId']}
                            ref={dealerId}/>
                    </label>
                    <label>Phone Number* <span>(mandatory)</span>
                        <input type="tel" 
                            placeholder="1234xxxxxx" 
                            onInput={handleInput} 
                            name="contact_no"
                            value={user['contact_no']}
                            ref={phoneNumber}/>
                    </label>
                    {/* <label>Country
                        <select defaultValue={user['country']} onInput={handleInput} name="country" ref={country}>
                            {
                                countries.map((country) => {
                                    return (
                                        <option 
                                        key={country.id} 
                                        value={country.name}
                                        selected={country.name === user['country']}
                                        >
                                            {country.name}
                                        </option>
                                    )
                                }) 
                            }
                        </select>
                    </label> */}
                    {/* <div className={[styles['bg-select'],'col-md-12'].join(' ')}>
                        <label>Select Background </label>
                            <input type="text" placeholder="Click to Choose Background" readOnly/>
                            <button onClick={handleModal}>Select Background </button> 
                    </div> */}
                    <div className={[styles[''],'text-center mt-3 col-md-12'].join(' ')}>
                        <button>Save and Next</button>
                    </div>
                </div>
            </form>    
        )
    } 


    function UploadLogoScreen() {
        return (
            <form className={[styles[''],'row justify-content-center align-items-center'].join(' ')} onSubmit={uploadFile}>
                <div className="col-6">
                    <label>Logo*
                        <input type="file" 
                            placeholder="" 
                            name="logo"
                            ref={file}
                            />
                    </label>
                    <label>Logo Position*
                        <select defaultValue="top" onInput={handleInput} name="logoPosition">
                            <option value="top">Top</option>
                            <option value="right">Right</option>
                            <option value="bottom">Bottom</option>
                            <option value="left">Left</option>
                        </select>
                    </label>
                    <div className={[styles[''],'text-center mt-3 col-md-12'].join(' ')}>
                        <button>Upload Logo</button>
                    </div>
                </div>

                <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} >
                    {({getRootProps, getInputProps}) => (
                        <section className={[styles['try-drag-drop'],''].join(' ')}>
                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                <p className={styles["para-11"]}>DROP IMAGE HERE </p>
                                <p class={styles["para-2"]}>or <span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                <p className={styles['supports1']}>Supports jpeg, jpg, bmp & png</p>
                                <input  onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                            </div>
                        </section>
                    )}
                </Dropzone>


            </form>
        )
    }



    return (
        <>
            <div>
                <section className={[styles['user-section']]}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row align-items-center justify-content-center'].join(' ')}>
                            <div className={[styles['user-heading'],'col-md-12'].join(' ')}>
                                <h6><img src="https://storage.googleapis.com/spyne-website/static/app-dashboard/AddUser.png"/>
                                    <span>
                                        {
                                            !userCreated ? 'Edit a new User' : !logoScreen ? 'Add a User background' : 'Upload Logo'
                                        }
                                    </span></h6>
                            </div>
                            <div className={[styles['user-form'],'col-md-12'].join(' ')}>        
                                {
                                    !userCreated ? 
                                        UserForm() : 
                                        !logoScreen ?
                                            <BackgroundUser 
                                                user={user} 
                                                planData={otherBgList} 
                                                favList={favoriteBgList} 
                                                otherBgList={otherBgList} 
                                                updateBackground={updateBackgroundList}
                                                nextForm={nextScreen}/>
                                            :
                                            UploadLogoScreen()
                                        
                                }   
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>      
    )
}





export default EditUser