import React, { Component } from 'react'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'

// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
//   } from 'react-accessible-accordion';

import Logo from './Logo';
export default class BulkProcessingV2 extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            orignalTab: "Original",
          
        }
        
        
    }
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }



    





    render() {
        return (
            <div>
                <section className={[styles['bulk-try'],''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                    <li className={[styles[''],''].join(' ')}>
                                        <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                            <span>Edited</span>   
                                        </button>
                                    </li>
                                </ul>
                               <div className={[styles['add-more'],''].join(' ')}>
                                   <span>+</span>
                               </div>
                                {
                                    this.state.activeButton == "Original" ?
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    <LightgalleryProvider
                                        lightgallerySettings={{
                                            "download": true,
                                            "thumbnail": false,
                                            "fullScreen": true,
                                            "zoom": true
                                        }}
                                    >
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                            <div className={[styles['reload-cancel'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span>
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>
                                            <LightgalleryItem className={[styles['light-item'],'p-0']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png">
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                            </LightgalleryItem>
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    

                                </LightgalleryProvider>
                                </div>
                                : 
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                            <div className={[styles['reload-cancel'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span>
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                }
                               
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'col-md-12'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                        <sub>15</sub>
                                        Available Credits: &nbsp; <span> 15</span>
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}>
                                        <h6 className={[styles['bg-title'],''].join(' ')} >Backgrounds </h6>
                                                     
                                        <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                            
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                   <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                   <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                    <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                    <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                    <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                    <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-4'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/backgroundV2/Radiant_Slate.jpeg"/>
                                                    <p className={[styles['back-credit'],'col-12'].join(' ')}>
                                                    Sterling Silver
                                                    <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                    </span></p>
                                                </div>
                                            </div>
                                       
                                        </div>
                                                 
                                    </div>
                                    <div className={[styles['logo-sec'],'col-md-12'].join(' ')}>
                                        <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                            <Logo   
                                            dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                            handleLogoPosition = {this.props.handleLogoPosition}
                                            logoUrl = {this.props.logoUrl}
                                            logoPosition = {this.props.logoPosition}
                                            hideLogo={this.props.hideLogo}
                                            />
                                        </div>
                                        
                                    </div> 
                                    <div className={[styles['checkbox-section'],'col-md-12'].join(' ')}>
                                        <div className="row">
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                 <label className={styles['container-2']}>Color Enhancement
                                                <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement}/>
                                                <span className={styles['checkmark']}></span>
                                                </label>
                                            </div>
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                
                                                <label className={styles['container-2']}>Window Transparency
                                                <input type="checkbox" onChange={this.props.handleWindowTransparency} checked={this.props.checkWindowTransparency}/>
                                                <span className={styles['checkmark']} style={{left: "5%"}}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button>Apply (36 Credits) </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
