import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import "react-toggle/style.css";
import styles from '../../../Css/Model/DownloadclipprModal.module.css'

export default class FeedbackModal extends Component {
    
    constructor(props){
        super(props)
        this.state={
            
        }
    }

    componentDidMount = () => {}



    handleCloseModal = () => {
        
        this.props.hideRatingModal()
        
    }



    render(){

        return(

            <Modal
                show={this.props.rating}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                >
                <Modal.Header />
                
                    
                {/* this is for Dislike */}
                <Modal.Body>
                    <div className={[styles[''],'container-fluid'].join(' ')}> 
                        <div className={[styles['dislike-popup'],'row'].join(' ')}>
                            <div className={[styles['dislike-text'],'col-md-12'].join(' ')}>
                                <h5>How can we improve?</h5>
                                <p>Do you have any suggestion or found some bug? please let us know in the field below.</p>
                                <form>
                                    <textarea cols="" rows="4" placeholder="Describe your issue or idea...."></textarea>
                                
                                <button className={styles['submit-btn']}>Submit</button>
                                </form>
                            </div>
                                
                        </div>
                    </div>
                </Modal.Body>
                    
            </Modal>
        )
    }
}