import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';

import '../../../Css/Model/InsufficientCreditModal.css'

class InsufficientCreditModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            decription: '',
            editedImgUrl: '',
            likes: true,
        }
    }
    handleCloseModal = () => {
        
        this.props.hideThisModal()
        
    }

    render() { 
        return ( 
            <>
                <Modal
                show={this.props.showInsufficientCreditModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                >
                    <Modal.Header onClick={this.handleCloseModal} closeButton></Modal.Header>
                    <Modal.Body className="zero">
                        <p className="one">Uh-Oh!!</p>
                        <img className="two" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/InsufficientCredit.png" alt="Insufficient Credit" />
                        <p className="three">You don’t have credits to process the image </p>
                    </Modal.Body>
                    <div className="button">
                    <Button style={{background: '#FF7700', borderRadius:"20px", border: "0px"}}variant="primary" size="lg" className="four" onClick={event =>  window.location.href='https://www.spyne.ai/tech/pricing'}>Buy Credits</Button>
                    </div>
                   
        
                    

                </Modal> 
            </>    
        );
    }
}
 
export default InsufficientCreditModal;  