import React, { Component } from 'react';
import styles from '../../Css/Spinner.module.css'
import Axios from 'axios'

class Spinner extends Component {
    state = {  }
    componentDidMount = () => {
        Axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/enterprise/get",
            params : {
                enterprise_id : 'X9ZFGRKN5',
            }
        }).then(resp => {
        
            document.documentElement.style.setProperty("--primaryColor",resp.data.data.color)
            
        })
    }
    render() { 
        return ( 
            <div className={styles["spinner-background"]}>
                <div className={styles["spinner-box"]}>
                    <div className={styles["loading-icon"]}>
                    </div>
                    {/* <span>Preparing Zip...</span> */}
                </div>
            </div>
            
         );
    }
}
 
export default Spinner;