import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'
import {Link} from 'react-router-dom'

class BulkCreditModal extends Component {
    state = {  }

    storeImages = () => {
        sessionStorage.setItem("filesList", JSON.stringify(this.props.filesList));
        this.props.handleBulkCreditModal()
        this.props.getUserCredit()
    }
    
    render() { 
        return ( 
                <React.Fragment>
                {
                    this.props.notEnoughCreditModal ?
                        <Modal
                        show={this.props.showBulkCreditModal}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='md'
                        centered
                        >
                            <Modal.Body className="text-right cursor-pointer">
                                <img className={styles['cross-popup']} onClick={this.props.handleBulkCreditModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                            </Modal.Body>
                            <Modal.Body className="text-center">
                                <section className={[styles['more-uploads'],''].join(' ')}>
                                    <div className={[styles[''],'container-fluid'].join(' ')}>
                                        <div className={[styles[''],'row'].join(' ')}>
                                            <div className={[styles['popup-content'],' text-center'].join(' ')}>
                                                <h5>Uh-Oh !!</h5>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/oh-no.gif" />
                                                <p>You don’t have enough  credits to process all the images.</p>
                                                <p>First {this.props.availableCredit/(1+this.props.chargedFeature)}  Image can be processed</p>
                                                <button onClick={this.props.hitUploadAPI} className={styles['proceed-btn']}>Continue</button>
                                                <p><span>or</span></p>
                                                <h6>
                                                <Link target="_blank" to="/tech/pricing?redirect_url=cars" onClick={this.storeImages}>
                                                    Buy Credits </Link></h6>
                                            </div>
                            
                                        </div>
                                    </div>
                            </section>
                                
                            </Modal.Body>
                        
                        </Modal>
                    : this.props.enoughCreditModal ?
                    <Modal
                    show={this.props.showBulkCreditModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='md'
                    centered
                    >
                        <Modal.Body className="text-right cursor-pointer">
                                <img className={styles['cross-popup']} onClick={this.props.handleBulkCreditModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                            </Modal.Body>
                        <Modal.Body className="text-center">
                        <section className={[styles['more-uploads'],''].join(' ')}>
                            <div className={[styles[''],'container-fluid'].join(' ')}>
                                <div className={[styles[''],'row'].join(' ')}>
                                    <div className={[styles['popup-content'],' text-center'].join(' ')}> 
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/payment-success.gif" />
                                        <p><span style={{fontWeight:"120%",fontSize:"120%", color:"#000"}}>{(1+this.props.chargedFeature)*(this.props.filesList.length)} Credits</span> will be deducted from your wallet! </p>
                                        <button onClick={this.props.hitUploadAPI} className={styles['proceed-btn']} style={{"marginTop" : "7px"}}>Start Editing</button>
                                        <h3 style={{"marginTop" : "20px"}}>Remaining Credits : <span>{this.props.availableCredit - (1+this.props.chargedFeature)*(this.props.filesList.length)}</span></h3>
                                        <h6 style={{color:"#c73838",fontSize:"14px",marginTop:"16px",textDecoration:"none"}}>Note - Works only on entire car exterior images.</h6>
                                        
                                    
                                    </div>
                    
                                </div>
                            </div>
                        </section>
                            
                        </Modal.Body>
                    
                    </Modal>
                    : this.props.zeroCreditModal ?
                    <Modal
                        show={this.props.showBulkCreditModal}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='md'
                        centered
                        >
                            <Modal.Body className="text-right cursor-pointer">
                                <img className={styles['cross-popup']} onClick={this.props.handleBulkCreditModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                            </Modal.Body>
                            <Modal.Body className="text-center">
                                <section className={[styles['more-uploads'],''].join(' ')}>
                                    <div className={[styles[''],'container-fluid'].join(' ')}>
                                        <div className={[styles[''],'row'].join(' ')}>
                                            <div className={[styles['popup-content'],' text-center'].join(' ')}>
                                                <h5>Uh-Oh !!</h5>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/oh-no.gif" />
                                                <p>You don’t have credits to process the image .</p>
                                                <button className={styles['proceed-btn']}><Link target="_blank" to="/tech/pricing?redirect_url=cars" onClick={this.storeImages}>
                                                    Buy Credits </Link></button>
                                                {/* <p><span>or</span></p> */}
                                                {/* <h6>Upload a single image <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/arrow.png" /></h6> */}
                                            </div>
                            
                                        </div>
                                    </div>
                            </section>
                                
                            </Modal.Body>
                        
                        </Modal>
                    : null
                }
                </React.Fragment>
                
        );
    }
}
 
export default BulkCreditModal;