import React, { Component } from 'react';
import ProjectIdModal from '../Common/Modal/ProjectIdModal';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Spinner from '../Background/Spinner'
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import EditedSkuWarningModal from '../Common/Modal/EditedSkuWarningModal';


class BulkUploadEdited extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            orignalTab: "Original",
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
            activeButtonNew:"Original",
            themeColor:'#ff7700',
            isSpinner : false,

        }
        
        
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
            activeButtonNew:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
      }

      fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      redirectToDashboard = () => {
        let enterprise_id = this.cookie("enterpriseId")
        let user_id = this.cookie("userId")

        let URL = 'https://www.spyne.ai/butler/dashboard?userId=' + user_id + '&enterpriseId='+ enterprise_id

        window.open(URL, '_blank');
      }

      getCSV = () => {
        let user_id = this.cookie("userId")
        this.setState({
            isSpinner: true
        })
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/feed/scheduler/connect-ftp",
            params : {
                user_id : user_id,
            }
        }).then(resp =>{
            this.setState({
                isSpinner: false
            })
            let csv_url = resp.data?.data?.output_url
            window.open(csv_url, '_blank');
        })
      }
      

    render()  { 
        
        const originalImages = this.props.originalImageList ? this.props.originalImageList.map((carFile,i) => {
                return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            {/* <div className={[styles['reload-cancel'],''].join(' ')}>
                                
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                    <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div> */}
                            <img src={carFile.input_image_hres_url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.image_name}</p></div>
                            </div>
                            
                        </div>
                    </div>
        }): null

        const editedCars = this.props.filesList ? this.props.filesList.map((carFile,i) => {
                return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                    <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>
        }): null

        
        
    

        const uploadPercent = (this.props.uploadCounter*100)/this.props.filesList.length
        const uploadPercentage = uploadPercent.toFixed(1)
        return ( 
            <div>
                
                <section className={[styles['perfect-output'],''].join(' ')}>
                 
                    <div className={[styles[''],''].join(' ')}>
                        {this.props.uploadStarteds ?
                            <div className={[styles[''],''].join(' ')}>

                                    {this.props.isZipReady ? 
                                        <div className={[styles['green-strip'],''].join(' ')}>
                                                <h6>Images Uploaded Successfully </h6>
                                            
                                        </div>
                                        :
                                        <div className={[styles['red-strip'],''].join(' ')}>
                                                <h6>Images are being uploaded, DO NOT CLOSE THIS TAB. </h6>
                                        </div>
                                    }
                                
                                {
                                    this.props.isZipReady ?
                                        <div className={[styles['download-card-gif-1'],'row m-0'].join(' ')}>
                                            <div className="col-md-4">
                                                <div className={[styles['project-details-box'],''].join(' ')}>
                                                    <div className={styles['project-head']}>Image Count Details</div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h4>Rejected Images: {this.props.errorImageCounter}</h4>
                                                            
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h4>Uploaded Images: {this.props.uploadCounter}</h4>
                                                            </div>
                                                        </div>
                                                        
                                                </div>
                                            </div>
                                            
                                            
                                            <img alt="" src="https://storage.googleapis.com/spyne-website/whitelabel/success-gif.gif"/>
                                        </div>
                                        :
                                        <div className={[styles['progress-strip'],''].join(' ')}>
                                            <ProgressBar striped variant="success" now={this.props.uploadCounter} min={0} max={this.props.filesList.length}/>
                                            <h4>{uploadPercentage}% ({this.props.uploadCounter}/{this.props.filesList.length}) Uploaded</h4>
                                        </div>
                                }
                                
                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                    <div className={styles['dropzone1']}>
                                        <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                            
                                            <button className="" onClick={this.fileHandler}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                Upload New Project</button>
                                        </div>
                                        
                                    </div>
                                </section>

                                {/* {
                                    this.props.isZipReady ?
                                    <div className="row justify-content-center">
                                        <div className={[styles['button-sec'],'col-md-8 d-flex'].join(' ')}>
                                            <div className="col-md-6">
                                                <h4 onClick={this.redirectToDashboard}>View in Dashboard</h4>
                                            </div>
                                            <div className="col-md-6" >
                                                <h4 disabled onClick={this.getCSV}>Export Project as CSV</h4>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    : null
                                        
                                } */}
                                
                                
                                <div>

                                </div>
                                

                                
                                
                            </div>
                        :
                        <div>
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className="col-md-12">
                                    <div className={styles['bulk-header']}>Multi SKU Bulk Uploader</div>
                                    <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                <div {...getRootProps({})} className={styles['dropzone1']}>
                                                    <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload Images</button>
                                                    </div>
                                                    <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <div className={[styles['progress-strip-1'],''].join(' ')}>
                                            <ProgressBar striped variant="success" now={this.props.filesList.length} min={0} max={this.props.originalImageList.length}/>
                                            <h4>({this.props.filesList.length}/{this.props.originalImageList.length}) Uploaded</h4>
                                    </div>
                                </div>
                            </div>
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles[''],'col-md-7 col-lg-7'].join(' ')}>
                                    
                                    <ul className={[styles['change-tabs'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                                <span>Original</span>
                                            </button>
                                        </li>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                                <span>Edited</span>
                                            </button>
                                        </li>
                                    
                                    </ul>
                                    {/* {this.state.activeButton == "Original" ?
                                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                {({getRootProps, getInputProps}) => (
                                                    <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                            <div className={[styles['add-more'],''].join(' ')} >
                                                                
                                                                <button >+</button>
                                                                
                                                            </div>
                                                            <img className={[styles['more-text'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                            <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        
                                        : null
                                    } */}
                                    
                                    {
                                        this.state.activeButton == "Original" ?
                                        <div className={[styles['tab-content'],''].join(' ')}>
                                        
                                            <div>
                                            <div className={styles['container-fluid']}>
                                                <div className={[styles['exterior-notnull-1'],'mb-3 row'].join(' ')}>
                                                            {/* <h1>Exterior</h1> */}
                                                            
                                                                <div className="row">
                                                                        <LightgalleryProvider
                                                                            lightgallerySettings={{
                                                                                "download": false,
                                                                                "thumbnail": false,
                                                                                "fullScreen": false,
                                                                                "zoom": false
                                                                            }}
                                                                        >
                                                                        
                                                                        {originalImages}
                                                                        

                                                                        </LightgalleryProvider>
                                                                </div> 
                                                        </div>
                                                
                                            </div>

                                            
                                            
                                            </div>
                                            
                                        
                                    </div>
                                    : 
                                    <div className={[styles['tab-content'],'row'].join(' ')}>
                                        
                                        <div>
                                            <div className={styles['container-fluid']}>
                                                <div className={[styles['exterior-notnull-1'],'mb-3 row'].join(' ')}>
                                                            {/* <h1>Exterior</h1> */}
                                                            
                                                                <div className="row">
                                                                        <LightgalleryProvider
                                                                            lightgallerySettings={{
                                                                                "download": false,
                                                                                "thumbnail": false,
                                                                                "fullScreen": false,
                                                                                "zoom": false
                                                                            }}
                                                                        >
                                                                        
                                                                        {editedCars}
                                                                        

                                                                        </LightgalleryProvider>
                                                                </div> 
                                                        </div>
                                                </div>
                                            </div>
                                    </div>
                                    
                                    
                                    }
                                    
                                    
                                </div>

                                <div className={[styles['bulk-right'],'col-md-5 col-lg-5'].join(' ')}>
                                    <div className={[styles['bulk-right-box-1'],'row m-0 justify-content-center'].join(' ')}>
                                        

                                        <div className="col-md-11">
                                            
                                            <div className={[styles['project-details-box'],''].join(' ')}>
                                                <div className={styles['project-head']}>Project Details</div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h4>User Id</h4>
                                                        <h6>{this.props.projectUserId}</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h4>Project Name</h4>
                                                        <h6>{this.props.projectName}</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h4>Number of SKU</h4>
                                                        <h6>{this.props.skuCount}</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h4>Number of Images</h4>
                                                        <h6>{this.props.imageCount}</h6>
                                                    </div>
                                                </div>
                                                
                                            </div>      
                                        </div>
                                        


                                        
                                        <div className={[styles['apply-btn-section-1'],'col-md-12'].join(' ')}>
                                            <button onClick={this.props.filesList.length == this.props.originalImageList.length ? this.props.hitUploadAPI : this.props.handleWarningModal} disabled={this.props.filesList == ''}>Submit Project</button>
                                        </div>

                                        <EditedSkuWarningModal 
                                            hitUploadAPI={this.props.hitUploadAPI}
                                            showWarningModal={this.props.showWarningModal}
                                            handleWarningModal={this.props.handleWarningModal}
                                            filesList={this.props.filesList}
                                        />
                                        
                                        <ProjectIdModal 
                                            showProjectNameModal = {this.props.showProjectNameModal}  
                                            handleProjectNameModal = {this.props.handleProjectNameModal}
                                            handleProjectName={this.props.handleProjectName}
                                            project_name={this.props.project_name}
                                            projectNameExists = {this.props.projectNameExists}
                                            projectIdExists={this.props.projectIdExists}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                            
                        }
                        
                    </div>
                </section>
            </div>
         );
    }
}
 
export default BulkUploadEdited;