import React, { Component } from 'react'
import  {Modal, Dropdown} from 'react-bootstrap'
import {newLogin,verifyOTP,insertFreeCredit, cookie, validateOTP, setCookie} from '../Utils'
import Login from '../GoogleLogin/GoogleLogin'
import styles from '../../../Css/Model/LandingModal.module.css'
import {sedanImgList} from '../Static'
import {data} from '../Static/overlays'
import Axios from 'axios'
import GoogleLogin from 'react-google-login'
import { ContextConsumer, AuthContext } from '../../../context/GlobalState'

export default class LandingModal extends Component {
    constructor(props){
        super(props)
        this.state={
            loginCheck:true,
            userEmail:'',
            otpSent:false,
            otp:'',
            error:false,
            stepOne:false,
            suv: true,
            sedan:false,
            hatchback: false,
            category:'suv',
            userId:'',
            otpValid: true,
            flagOTP:false,
            islogin: false,
            isPassword: true,
            password: '',
            validEmail:false,
            emailExists: false,
            otpMessage: false,
            distributor_list:[],
            distributor_api:"",
            isDistributor:false
        }
        this.passwordRef = React.createRef()
    }

    static contextType = AuthContext


    componentDidMount = () => {
        let userEmail = cookie("emailId")
        let userId = cookie("userId")
        if(userId && userEmail && this.context.auth.loggedIn){
            this.setState({
                stepOne:true,
                loginCheck:false,
                otpSent:true,
                flagOTP:true,
            })
        }
        Axios({
            'method':"GET",
            'url':'https://www.clippr.ai/api/v2/user/distributor'
        })
        .then((res) => {
            this.setState({
                distributor_list:res?.data?.data
            })
            // console.log(res.data)
        })


        
    }
    handledistributor=(select)=>{
        // console.log(select)
        if(select=="null"){
            this.setState({
            distributor_api:"",
            distributor_logo:"",
            distributor_name:"",
            
            })
        }
        else{
            this.setState({
                distributor_api:select['api_key'],
                distributor_logo:select['logo_url'],
                distributor_name:select['name'],
            })
        }
        
    }
    handleCloseModal = () => {
        if(this.state.flagOTP) {
            this.props.hideModal()
        }
    }
    sendOTP = (e) => {
        e.preventDefault()
        // let data = {
        //     'emailId':this.state.userEmail
        // }
        const data = new FormData()
        data.append('api_key', process.env.REACT_APP_API_KEY)
        data.append('email_id', this.state.userEmail)
        newLogin(data)
        .then((success) => {
           this.setState({
               otpSent:true,
               userId:success.data?.user_id,
               loginCheck:true,
               otpMessage: true
           })
        })
        .catch((err) => this.setState({error:true}))

    }
    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            userEmail:e.target.value,
            
        })
    }
    handleChangeOTP = (e) => {
        e.preventDefault()
        this.setState({
            otp:e.target.value
        })
    }
    handleChangePassword = (e) => {
        e.preventDefault()
        this.setState({
            password:e.target.value
        })
    }
    verifyOtp = (e) => {
        e.preventDefault()
        const fd = new FormData()
        fd.append('api_key', process.env.REACT_APP_API_KEY)
        fd.append('email_id', this.state.userEmail)
        fd.append('otp', this.state.otp)
        fd.append('user_name', "AA")
        fd.append('country', "India")
        validateOTP(fd)
            .then((verified) => {

                if (verified.data?.status === 200) {
                    this.setState({
                        loginCheck:false,
                        flagOTP:true,
                        otpMessage: false,
                    })
                    // setCookie('emailId', this.state.userEmail)
                    // setCookie('userId', this.state.userId)
                    // setCookie('auth_token', verified.data?.auth_token)
                    document.cookie = `emailId=${this.state.userEmail}; path=/`;
                    document.cookie = `userId=${this.state.userId}; path=/`;
                    document.cookie = `auth_token=${verified.data?.auth_token}; path=/`;
                    this.context.loggedIn({
                        loggedIn: true,
                        user: {
                            emailId: this.state.userEmail,
                            userId: verified.data?.user_id
                        },
                        token: verified.data?.auth_token,
                        apiKey: process.env.REACT_APP_API_KEY
                    })
                } else {
                    this.setState({
                        otpValid:false
                    })
                }

                // if(verified.data.id === "200"){
                //     const creditInsertFd = new FormData()
                //     creditInsertFd.append("user_id",this.state.userId)
                //     creditInsertFd.append("email_id", this.state.userEmail)
                //     insertFreeCredit(creditInsertFd)
                //     .then((dataInsertion) => {
                //         this.setState({
                //             loginCheck:false,
                //             flagOTP:true,
                //             otpMessage: false,
                            
                            
                //         })
                //         document.cookie = `emailId=${this.state.userEmail}; path=/`;
                //         document.cookie = `userId=${this.state.userId}; path=/`;
                //         window.location.reload()
                //     })
                //     .catch (dataInsertionErr => {
                //         this.setState({
                //             loginCheck:false
                //         })
                //     })
                // }else{
                //     this.setState({
                //         otpValid:false
                //     })
                // }
                
            }
        )
        .catch((err) => this.setState({loginCheck:true}))
    }
    handleFistMessage = (e) => {
        e.preventDefault()
        
        if (cookie('userId') && cookie('emailId') && cookie('auth_token')) {
            window.location.reload()
        } else {
            this.setState({
                loginCheck:true,
                stepOne:true
            })
        }
    }

    changeCategory = (name) => {
        // e.preventDefault();
        this.setState({
            category:name
        })
    }

    handleSignUpPassword = (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append('email_id', this.state.userEmail)
        fd.append('password', this.state.password)
        fd.append('strategy', 'PASSWORD')
        fd.append('user_name', 'default')
        fd.append('signup_url', window.location.href)
        fd.append('source', 'Web')
        console.log(this.state.distributor_api)
        if(this.state.distributor_api==""){
            fd.append('api_key', process.env.REACT_APP_API_KEY)
        }
        else{
            // const flag = 'false'
            // {this.state.isDistributor ? flag = 'true' : flag = 'false'}

            fd.append('if_distributor', 'true')
            fd.append('api_key', this.state.distributor_api)
        }

        if(this.props.productHunt == 'producthunt'){
            fd.append('page_source', "productHunt")
        }
        Axios({
            method: "POST",
            url: "https://www.clippr.ai/api/v2/user/signup",
            data : fd,
        }).then(resp => {
            if (resp?.data?.status !== 200) {
                this.setState({
                    loginCheck:true,
                    flagOTP:true,
                    emailExists: true
                })
            } else if (resp?.data?.status === 200) {
                this.setState({
                    userId : resp.data.user_id,
                    // loginCheck:false,
                    flagOTP:true
                })
                document.cookie = `emailId=${this.state.userEmail}; path=/`;
                document.cookie = `userId=${this.state.userId}; path=/`;
                document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
                document.cookie = `ssoToken=${resp.data?.auth_token}; path=/`;
                document.cookie = `enterpriseId=TaD1VC1Ko; path=/`;
                // setCookie('emailId', this.state.userEmail)
                // setCookie('userId', this.state.userId)
                // setCookie('auth_token', resp.data?.auth_token)
                
                this.context.loggedIn({
                    loggedIn: true,
                    user: {
                        emailId: this.state.userEmail,
                        userId: resp.data?.user_id
                    },
                    token: resp.data?.auth_token,
                    apiKey: process.env.REACT_APP_API_KEY
                })
                window.location.reload()
            }
            // if(resp.data.exists == true){
            //     this.setState({
            //         loginCheck:true,
            //         flagOTP:true,
            //         emailExists: true
            //     })
            // }else if(resp.data.exists == false){
            //     this.setState({
            //         userId : resp.data.userId
            //     })
            //     const creditInsertFd = new FormData()
            //         creditInsertFd.append("user_id",this.state.userId)
            //         creditInsertFd.append("email_id", this.state.userEmail)
            //         insertFreeCredit(creditInsertFd)
            //         .then((dataInsertion) => {
            //             this.setState({
            //                 loginCheck:false,
            //                 flagOTP:true
            //             })
            //             document.cookie = `emailId=${this.state.userEmail}; path=/`;
            //             document.cookie = `userId=${this.state.userId}; path=/`;
            //             window.location.reload()
            //         })
            //         .catch (dataInsertionErr => {
            //             this.setState({
            //                 loginCheck:false
            //             })
            //         })
            // }
            // console.log(resp)
        })
    }

    handleLogin = (e) => {
        e.preventDefault()
        console.log(this.context)
        let strategy,password;

        const fd = new FormData()
        fd.append('api_key', process.env.REACT_APP_API_KEY)
        if(this.props.productHunt == 'producthunt'){
            fd.append('page_source', "productHunt")
        }
        if(this.state.isPassword){
            // strategy = "EMAILANDPASSWORD"
            // password = this.state.password
            fd.append('strategy', 'PASSWORD')
            fd.append('email_id', this.state.userEmail)
            fd.append('password', this.state.password)
            Axios({
                method: "POST",
                url: "https://www.clippr.ai/api/v2/user/login",
                data : fd
            })
            .then(this.handleLoginResponse)
        }else{
            // strategy = "EMAILANDOTP"
            // password = this.state.otp
            fd.append('email_id', this.state.userEmail)
            fd.append('otp', this.state.otp)
            fd.append('signup_url', window.location.href)
            fd.append('source', 'Web')
            Axios({
                url: 'https://www.clippr.ai/api/v2/user/validate-otp',
                method: 'POST',
                data: fd
            })
            .then(this.handleLoginResponse)
        }
        // Axios({
        //     method: "POST",
        //     url: "https://www.clippr.ai/api/v2/user/login",
        //     data : fd
        // }).then(resp => {
        //     // console.log(this.context)
        //     if(resp?.data?.status == 200){
        //         this.setState({
        //             loginCheck:false,
        //             flagOTP:true,
        //             userId: resp.data.user_id,
        //             validEmail: false
        //         })
        //         document.cookie = `emailId=${this.state.userEmail}; path=/`;
        //         document.cookie = `userId=${this.state.userId}; path=/`;
        //         // console.log(this.context)
        //         this.context.loggedIn({
        //             loggedIn: true,
        //             user: {
        //                 emailId: this.state.userEmail,
        //                 userId: resp.data.user_id
        //             },
        //             token: resp.data.auth_token,
        //             apiKey: process.env.REACT_APP_API_KEY
        //         })
        //         // window.location.reload()
        //     }else if(resp?.data?.status != 200){
        //         this.setState({
        //             loginCheck:true,
        //             flagOTP:false,
        //             validEmail: true
                   
        //         })
        //         console.log(this.context)
        //     }
        //     // console.log(resp)
            
            
        // })
    }

    handleOtpRequest = (e) => {
        e.preventDefault()
        const fd = new FormData()
        fd.append('email_id', this.state.userEmail)
        fd.append('api_key', process.env.REACT_APP_API_KEY)
        this.setState({
            otp: ''
        })
        Axios({
            method: "POST",
            url: "https://www.clippr.ai/api/v2/user/request-otp",
            data: fd
        }).then(resp => {

            if (resp.data?.status == 200) {
                this.setState({
                    otpSent:true,
                    validEmail: false,
                    loginCheck:true,
                    otpMessage: true
                })
            } else {
                this.setState({
                    validEmail: true
                })
            }

            // if(resp.data.msgInfo.msgCode == "403"){
            //     this.setState({
            //         validEmail: true
            //     })
            // }else{
            //     // console.log(resp)
            //     this.setState({
            //         otpSent:true,
            //         validEmail: false,
            //         loginCheck:true,
            //         otpMessage: true
            //     })
            // }
            
        })
    }

    handleLoginModal = (e) => {
        e.preventDefault()

        // Check if user details exist in cookies
        if (cookie('userId') && cookie('emailId') && cookie('auth_token')) {
            window.location.reload()
        } else {
            this.setState({
                islogin: true,
                loginCheck:true,
                stepOne:true,
                otpMessage: false
            })
        }
    }

    handlePasswordOrOTP = (e) => {
        e.preventDefault()
        this.setState({
            isPassword: !this.state.isPassword,
            otpMessage: false,
            otp: '',
            password: '',
            isDistributor:false,
            distributor_api:"",
        })
        if (this.passwordRef.current) 
            this.passwordRef.current.value = ''
    }

    handleIsDistributor = (e) => {
        e.preventDefault()
        if(this.state.isPassword===false)
        {
            this.setState({
                isPassword: !this.state.isPassword,
                isDistributor:true
        })
        }
        this.setState({
            isDistributor: !this.state.isDistributor,
        })
    }

    loginModal = (e) => {
        e.preventDefault()
        this.setState({
            islogin: false,
            stepOne: false,
            otpMessage: false
        })
    }

    handleLoginResponse = (resp) => {
        if(resp?.data?.status == 200){
            
            this.setState({
                // loginCheck:false,
                flagOTP:true,
                userId: resp.data.user_id,
                validEmail: false
            })
            // setTimeout(() => {
            //     this.setState({
            //         loginCheck:false,
            //         flagOTP:true,
            //         userId: resp.data.user_id,
            //         validEmail: false
            //     })
            // },2000)
            
            document.cookie = `emailId=${this.state.userEmail}; path=/`;
            document.cookie = `userId=${this.state.userId}; path=/`;
            document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
            document.cookie = `ssoToken=${resp.data?.auth_token}; path=/`;
            document.cookie = `enterpriseId=TaD1VC1Ko; path=/`;
            // setCookie('emailId', this.state.userEmail)
            // setCookie('userId', this.state.userId)
            // setCookie('auth_token', resp.data?.auth_token)
            // console.log(this.context)
            
            this.context.loggedIn({
                loggedIn: true,
                user: {
                    emailId: this.state.userEmail,
                    userId: resp.data.user_id
                },
                token: resp.data.auth_token,
                apiKey: process.env.REACT_APP_API_KEY
            })
            window.location.reload()
        }else if(resp?.data?.status != 200){
            this.setState({
                loginCheck:true,
                flagOTP:false,
                validEmail: true
               
            })
            console.log(this.context)
        }
    }

    render() {
        let {loginCheck,stepOne, category,otpSent} = this.state
        const sedanImgList = ['https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-0.png', 
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-1.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-2.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-3.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-4.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-5.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-6.png',
                                'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/sedan/Mask%20Group-7.png', 
                            ]
        const listSedan = data["sedan"].map((e,i) =>{
           return <img className={styles['cars-img']} src={e[i]["b"]} alt=""/>
        })
        const suvImgList = ['https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-0.png', 
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-1.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-2.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-3.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-4.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-5.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-6.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/suv/Mask%20Group-7.png',
                            ]
        const listSuv = data["suv"].map((e,i) =>{
           return <img className={styles['cars-img']} src={e[i]["b"]} alt=""/>
        })
        const hatchbackImgList = ['https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-0.png', 
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-1.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-2.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-3.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-4.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-5.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-6.png',
                           'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Black_overlays/hatchback/Mask%20Group-7.png',
                            
                            ]
        const listHatchback = data["hb"].map((e,i) =>{
           return <img className={styles['cars-img']} src={e[i]["b"]} alt=""/>
        })
        return (
                <Modal
                        show={this.props.showInstruction}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='lg'
                        centered
                        onHide={this.handleCloseModal}
                        backdrop={this.context.auth.loggedIn ? true : 'static'}
                        keyboard={this.context.auth.loggedIn ? true : false }
                        className={styles['hide-in-mobile']}
                    >
                        <Modal.Header />
                        {   
                            !stepOne ?
                                <Modal.Body>
                                        <div className={[styles['firstStep'],'row'].join(' ')}>
                                            <div className={[styles['circle-img'],'col-md-11'].join(' ')}>
                                                <img className="img-fluid" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/login-gif.gif" />
                                            </div>
                                         
                                            <div className={[styles['circle-area'],''].join(' ')}>
                                                {this.props.productHunt == 'producthunt'
                                                    ?
                                                    <div className={[styles['circle-area-inner']]}>
                                                        <h6 className={[styles['product-hunt-tag'],''].join(' ')}>
                                                            <a className={[styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/spyneai-s-car-background-tool?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spyneai-s-car-background-tool" target="_blank">
                                                                <img className={[styles[''],''].join(' ')} src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299488&theme=light" alt="SpyneAI’s Car Background tool - Boost Online Car Sales with AI-Generated Backgrounds | Product Hunt" />
                                                            </a>
                                                        </h6>
                                                        <h3 style={{"fontSize" : "31px"}}>Welcome ProductHunters</h3>
                                                        <p>Get<span> 15 Free </span>Credits</p>
                                                        <button className={styles['signup-button']} onClick = {this.handleFistMessage}>Sign-up</button>
                                                        <p onClick={this.handleLoginModal}>Existing User? <a >Login here</a></p>
                                                    </div>
                                                    :
                                                    <div className={[styles['circle-area-inner']]}>
                                                        <h6 className={[styles['product-hunt-tag'],''].join(' ')}>
                                                        <a className={[styles['product-hunt'],''].join(' ')} href="https://www.producthunt.com/posts/replace-car-background-magically-spyneai?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-replace-car-background-magically-spyneai" target="_blank">
                                                            <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=299488&theme=light&period=daily" 
                                                            alt="Replace Car Background magically-SpyneAI - Boost online car sales with AI generated backgrounds | Product Hunt" />
                                                        </a>
                                                        </h6>
                                                        <h3 style={{marginTop:'50px'}}>Welcome to Spyne </h3>
                                                        <p></p>
                                                        <button className={styles['signup-button']} style={{marginTop:'50px'}} onClick = {this.handleLoginModal}>Login</button>
                                                        {/* <p onClick={this.handleLoginModal}>Existing User? <a >Login here</a></p> */}
                                                    </div>
                                                }
                                                
                                               
                                            </div>
                                           
                                        </div>
                                </Modal.Body>
                            : 
                            loginCheck?
                                <Modal.Body>
                                    {this.state.islogin ?
                                        <center className={styles['login-check-modal']}>
                                            <div className="text-right cursor-pointer">
                                                <img className={styles['close-btn']} onClick={this.loginModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                                            </div>
                                            <img src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png"></img>
                                            
                                            <p>SpyneAI Login</p>
                                            {/* <Login /> */}
                                            <form onSubmit={this.state.isPassword ? this.handleLogin : !this.state.otpSent? this.handleOtpRequest : this.handleLogin}>
                                                <input className="form-control " type="email" required placeholder="Enter your Email" onChange={this.handleChange}></input>
                                                {this.state.validEmail ? 
                                                <h6>Invalid Credentials</h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ?
                                                <input className="form-control " type="password" placeholder="Enter your Password" onChange={this.handleChangePassword} ref={this.passwordRef}></input>
                                                :
                                                <input className="form-control " type="text" placeholder="Enter your OTP" onChange={this.handleChangeOTP} disabled={!otpSent ? true : false} ref={this.passwordRef}></input>
                                                }
                                                {this.state.otpMessage ? 
                                                    <h6>One Time Password has been sent to your Email</h6>
                                                    : null
                                                }
                                                
                                                {!this.state.otpValid ? 
                                                <h6>Please Enter Valid OTP</h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ? 
                                                    <button className="" type="submit">Login</button>
                                                    :
                                                    <button className="" type="submit">{!this.state.otpSent ? 'Request OTP' : 'Verify OTP'}</button>
                                                }
                                                <div className={styles['or']}><span>or</span></div>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    {/* <Login /> */}
                                                    <button onClick={this.handlePasswordOrOTP} className="" style={{backgroundColor: "#000000"}} type="submit">{this.state.isPassword ? 'Login Using OTP (One Time Password)' : 'Login Using Password '}</button>
                                                </div>
                                                
                                            </form>
                                            <h5 className={styles['forgot-password']}><a href="https://www.spyne.ai/forgot-password" target="_blank">Forgot Password?</a></h5>
                                        </center>
                                        :
                                        <center className={styles['login-check-modal']}>
                                            <div className="text-right cursor-pointer">
                                                <img className={styles['close-btn']} onClick={this.loginModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                                            </div>
                                            <img src="https://eventila.spyne.ai/logos/spyne-logo/bg-less-title.png"></img>
                                            
                                            <p>SpyneAI Sign-up</p>
                                            {/* <Login /> */}
                                            <form onSubmit={this.state.isPassword? this.handleSignUpPassword : !this.state.otpSent ? this.sendOTP : this.verifyOtp}>
                                                <input className="form-control " type="email" required placeholder="Enter your Email" onChange={this.handleChange}></input>
                                                {this.state.emailExists ? 
                                                <h6>Email Id Already Exists. <a className={styles['login-here']} onClick={this.handleLoginModal}>Login here</a></h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ?
                                                (   
                                                    <input className="form-control " required={true} type="password" placeholder="Enter your Password" onChange={this.handleChangePassword} ref={this.passwordRef}></input>
                                                )
                                                
                                                :
                                                <input className="form-control " required={true} type="text" placeholder="Enter your OTP" onChange={this.handleChangeOTP} disabled={!otpSent ? true : false} ref={this.passwordRef} ></input>
                                                }
                                                {this.state.otpMessage ? 
                                                    <h6>One Time Password has been sent to your Email</h6>
                                                    : null
                                                }
                                                {!this.state.otpValid ? 
                                                <h6>Please Enter Valid OTP</h6>
                                                :
                                                null 
                                                }
                                                {this.state.isPassword && this.state.distributor_list.length>0 && this.state.isDistributor? 
                                                    <div >
                                                        <Dropdown className={styles['drop-menu']} >
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className={styles['distributor']}>
                                                            {this.state.distributor_name?this.state.distributor_name:"Select Distributor"}
                                                            </Dropdown.Toggle>
                                                        
                                                            <Dropdown.Menu  className={styles['drop-list']}>
                                                            <Dropdown.Item onClick={()=>this.handledistributor("null")} >Select None</Dropdown.Item>

                                                            {
                                                                // console.log(this.state.distributor_list)
                                                                this.state.distributor_list.map((e,i)=>{
                                                                    

                                                                    return(
                                                                    <Dropdown.Item onClick={()=>this.handledistributor(e)} >
                                                                        <div>
                                                                            {/* <img style={{height:"8%",width:"8%",objectFit:"contain"}} src={e['logo_url']}/> */}
                                                                            <span>&nbsp;&nbsp;{e['name']}</span>
                                                                        </div>    
                                                                    </Dropdown.Item>
                                                                    )
                                                                })
                                                            }
                                                            
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {this.state.isPassword ? 
                                                    <button className="" type="submit">Sign up</button>
                                                    :
                                                    <button className="" type="submit">{!this.state.otpSent ? 'Request OTP' : 'Verify OTP'}</button>
                                                }
                                                <div className={styles['or']}><span>or</span></div>
                                                <button onClick={this.handlePasswordOrOTP} className="" style={{backgroundColor: "#000000", display:"block", marginBottom:"0px"}} type="submit">{this.state.isPassword ? 'Sign-up Using OTP (One Time Password)' : 'Sign-up Using Password '}</button>
                                                
                                                <button onClick={this.handleIsDistributor} className="" style={{backgroundColor: "#808080"}} type="submit">{this.state.isDistributor ? 'Sign-up Using Password' : this.state.isPassword ? 'Sign-up by a Distributor' : 'Sign-up by a Distributor '}</button>
                                                {/* <p>Already a member? <a href="#"> Login</a></p> */}
                                            </form>
                                            
                                        </center>
                                    }
                                    
                                </Modal.Body>
                            :
                            (   
                                this.props.productHunt=='clippr'?this.handleCloseModal():
                            
                            <Modal.Body>
                                <center className={[styles['car-text']]}>
                                    <h1>Output works best with these <span style={{color:"#FF7700"}}>Overlays</span></h1>
                                </center>
                                <div className={[styles[' '],'row'].join(' ')}>
                                    <img className="mt-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/angles-gif.gif" />
                                    {/* <div className={[styles['overlays-models-text'],'col-4 d-flex flex-column'].join(' ')}>
                                        <center>
                                            <div className={category === 'suv' ? styles['cars-circle-active'] : styles['cars-circle'] } onClick={() => this.changeCategory("suv")}>
                                                <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/noun_SUV_446092%201.png" height="50px" width="50px"></img>
                                                
                                            </div>
                                            <p><span>   Suv  </span></p>
                                        </center>
                                        <center>
                                            <div className={category === 'sedan' ? styles['cars-circle-active'] : styles['cars-circle']} onClick={() => this.changeCategory("sedan")}>
                                                <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/noun_Sedan_446090%201.png" height="50px" width="50px"></img>
                                                https://meet.google.com/nmh-kmxw-hcj
                                            </div>
                                            <p><span>Sedan</span></p>
                                        </center>
                                        <center>
                                            <div className={ category === 'hatchback' ? styles['cars-circle-active'] : styles['cars-circle']} onClick={() => this.changeCategory("hatchback")}>
                                                <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/noun_Hatchback_446997%201.png" height="50px" width="50px"></img>
                                                
                                            </div>
                                            <p><span>Hatchback</span></p>
                                        </center>
                                        
                                    </div> 
                                    {
                                        category === 'suv'? 
                                            <div className={['col-8'].join(' ')} >
                                                <div className={[styles['img-sec']]}>
                                                    {listSuv}
                                                </div>
                                                 
                                            </div>
                                        :
                                        category === 'sedan'?
                                            <div className={['col-8'].join(' ')}>
                                               <div className={[styles['img-sec']]} >
                                                    {listSedan}
                                                </div>
                                            </div>
                                        :
                                        category === 'hatchback'?
                                            <div className={['col-8'].join(' ')}>
                                                <div className={[styles['img-sec']]} >
                                                    {listHatchback}
                                                </div>
                                                
                                            </div>
                                        : null

                                    }   
                                     */}
                                </div>
                                <center>
                                    <button onClick={this.handleCloseModal} className={styles['got-btn']}>Got It</button>
                                </center>
                            </Modal.Body>)

                        }   
                </Modal>
        )
    }
}
