import React, { Component } from 'react'
import  {Modal} from 'react-bootstrap'
import {newLogin,verifyOTP,insertFreeCredit, cookie, validateOTP, setCookie} from '../Utils'
import styles from '../../../Css/Model/LandingModal.module.css'
import {data} from '../../Static/overlays'
import Axios from 'axios'
import { ContextConsumer, AuthContext } from '../../../context/GlobalState'

export default class LandingModal extends Component {
    constructor(props){
        super(props)
        this.state={
            loginCheck:true,
            userEmail:'',
            otpSent:false,
            otp:'',
            error:false,
            stepOne:false,
            suv: true,
            sedan:false,
            hatchback: false,
            category:'suv',
            userId:'',
            otpValid: true,
            flagOTP:false,
            islogin: false,
            isPassword: true,
            password: '',
            validEmail:false,
            emailExists: false,
            otpMessage: false,
            companyName:'',
            logoUrl: '',
            favIcon:''
        }
        this.passwordRef = React.createRef()
    }

    static contextType = AuthContext

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    componentDidMount = () => {
        let userEmail = cookie("emailId")
        let userId = cookie("userId")
        if(userId && userEmail && this.context.auth.loggedIn){
            this.setState({
                stepOne:true,
                loginCheck:false,
                otpSent:true,
                flagOTP:true,
            })
        }

        
    }
    handleCloseModal = () => {
        if(this.state.flagOTP) {
            this.props.hideModal()
        }
    }
    sendOTP = (e) => {
        e.preventDefault()
        // let data = {
        //     'emailId':this.state.userEmail
        // }
        let apiKey = this.cookie("apiKey")
        const data = new FormData()
        data.append('api_key', apiKey)
        data.append('email_id', this.state.userEmail)
        newLogin(data)
        .then((success) => {
           this.setState({
               otpSent:true,
               userId:success.data?.user_id,
               loginCheck:true,
               otpMessage: true
           })
        })
        .catch((err) => this.setState({error:true}))

    }
    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            userEmail:e.target.value,
            
        })
    }
    handleChangeOTP = (e) => {
        e.preventDefault()
        this.setState({
            otp:e.target.value
        })
    }
    handleChangePassword = (e) => {
        e.preventDefault()
        this.setState({
            password:e.target.value
        })
    }
    verifyOtp = (e) => {
        e.preventDefault()
        let apiKey = this.cookie("apiKey")
        const fd = new FormData()
        fd.append('api_key', apiKey)
        fd.append('email_id', this.state.userEmail)
        fd.append('otp', this.state.otp)
        fd.append('user_name', "AA")
        fd.append('country', "India")
        validateOTP(fd)
            .then((verified) => {

                if (verified.data?.status === 200) {
                    this.setState({
                        loginCheck:false,
                        flagOTP:true,
                        otpMessage: false,
                    })
                    document.cookie = `emailId=${this.state.userEmail}; path=/`;
                    document.cookie = `userId=${this.state.userId}; path=/`;
                    document.cookie = `auth_token=${verified.data?.auth_token}; path=/`;
                    this.context.loggedIn({
                        loggedIn: true,
                        user: {
                            emailId: this.state.userEmail,
                            userId: verified.data?.user_id
                        },
                        token: verified.data?.auth_token,
                        apiKey: process.env.REACT_APP_API_KEY
                    })
                } else {
                    this.setState({
                        otpValid:false
                    })
                }

                
                
            }
        )
        .catch((err) => this.setState({loginCheck:true}))
    }
    handleFistMessage = (e) => {
        e.preventDefault()
        
        if (cookie('userId') && cookie('emailId') && cookie('auth_token')) {
            window.location.reload()
        } else {
            this.setState({
                loginCheck:true,
                stepOne:true
            })
        }
    }

    changeCategory = (name) => {
        // e.preventDefault();
        this.setState({
            category:name
        })
    }

    handleSignUpPassword = (e) => {
        e.preventDefault();
        let apiKey = this.cookie("apiKey")
        const fd = new FormData();
        fd.append('email_id', this.state.userEmail)
        fd.append('password', this.state.password)
        fd.append('strategy', 'PASSWORD')
        fd.append('user_name', 'default')
        fd.append('api_key', apiKey)
        fd.append('signup_url', window.location.href)
        fd.append('source', 'Web')
        if(this.props.productHunt == 'producthunt'){
            fd.append('page_source', "productHunt")
        }
        Axios({
            method: "POST",
            url: "https://www.clippr.ai/api/v2/user/signup",
            data : fd,
        }).then(resp => {
            if (resp?.data?.status !== 200) {
                this.setState({
                    loginCheck:true,
                    flagOTP:true,
                    emailExists: true
                })
            } else if (resp?.data?.status === 200) {
                this.setState({
                    userId : resp.data.user_id,
                    // loginCheck:false,
                    flagOTP:true
                })
                document.cookie = `emailId=${this.state.userEmail}; path=/`;
                document.cookie = `userId=${this.state.userId}; path=/`;
                document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
                document.cookie = `ssoToken=${resp.data?.auth_token}; path=/`;
                // document.cookie = `enterpriseId=${resp.data?.enterprise_id}; path=/`;
                // setCookie('emailId', this.state.userEmail)
                // setCookie('userId', this.state.userId)
                // setCookie('auth_token', resp.data?.auth_token)
                
                this.context.loggedIn({
                    loggedIn: true,
                    user: {
                        emailId: this.state.userEmail,
                        userId: resp.data?.user_id
                    },
                    token: resp.data?.auth_token,
                    apiKey: process.env.REACT_APP_API_KEY
                })
                window.location.reload()
            }
            
        })
    }

    handleLogin = (e) => {
        e.preventDefault()
        // console.log(this.context)
        let apiKey = this.cookie("apiKey")
        let strategy,password;
        const fd = new FormData()
        fd.append('api_key', apiKey)
        if(this.props.productHunt == 'producthunt'){
            fd.append('page_source', "productHunt")
        }
        if(this.state.isPassword){
            // strategy = "EMAILANDPASSWORD"
            // password = this.state.password
            fd.append('strategy', 'PASSWORD')
            fd.append('email_id', this.state.userEmail)
            fd.append('password', this.state.password)
            Axios({
                method: "POST",
                url: "https://www.clippr.ai/api/v2/user/login",
                data : fd
            })
            .then(this.handleLoginResponse)
        }else{
            // strategy = "EMAILANDOTP"
            // password = this.state.otp
            fd.append('email_id', this.state.userEmail)
            fd.append('otp', this.state.otp)
            fd.append('signup_url', window.location.href)
            fd.append('source', 'Web')
            Axios({
                url: 'https://www.clippr.ai/api/v2/user/validate-otp',
                method: 'POST',
                data: fd
            })
            .then(this.handleLoginResponse)
        }
        
    }

    handleOtpRequest = (e) => {
        e.preventDefault()
        let apiKey = this.cookie("apiKey")
        const fd = new FormData()
        fd.append('email_id', this.state.userEmail)
        fd.append('api_key', apiKey)
        this.setState({
            otp: ''
        })
        Axios({
            method: "POST",
            url: "https://www.clippr.ai/api/v2/user/request-otp",
            data: fd
        }).then(resp => {

            if (resp.data?.status == 200) {
                this.setState({
                    otpSent:true,
                    validEmail: false,
                    loginCheck:true,
                    otpMessage: true
                })
            } else {
                this.setState({
                    validEmail: true
                })
            }

        })
    }

    handleLoginModal = (e) => {
        e.preventDefault()

        // Check if user details exist in cookies
        if (cookie('userId') && cookie('emailId') && cookie('auth_token')) {
            window.location.reload()
        } else {
            this.setState({
                islogin: true,
                loginCheck:true,
                stepOne:true,
                otpMessage: false
            })
        }
    }

    handlePasswordOrOTP = (e) => {
        e.preventDefault()
        this.setState({
            isPassword: !this.state.isPassword,
            otpMessage: false,
            otp: '',
            password: ''
        })
        if (this.passwordRef.current) 
            this.passwordRef.current.value = ''
    }

    loginModal = (e) => {
        e.preventDefault()
        this.setState({
            islogin: false,
            stepOne: false,
            otpMessage: false
        })
    }

    handleLoginResponse = (resp) => {
        if(resp?.data?.status == 200){
            
            this.setState({
                // loginCheck:false,
                flagOTP:true,
                userId: resp.data.user_id,
                validEmail: false
            })
            
            
            document.cookie = `emailId=${this.state.userEmail}; path=/`;
            document.cookie = `userId=${this.state.userId}; path=/`;
            document.cookie = `auth_token=${resp.data?.auth_token}; path=/`;
            document.cookie = `ssoToken=${resp.data?.auth_token}; path=/`;
            
            
            this.context.loggedIn({
                loggedIn: true,
                user: {
                    emailId: this.state.userEmail,
                    userId: resp.data.user_id
                },
                token: resp.data.auth_token,
                apiKey: process.env.REACT_APP_API_KEY
            })
            window.location.reload()
        }else if(resp?.data?.status != 200){
            this.setState({
                loginCheck:true,
                flagOTP:false,
                validEmail: true
               
            })
            console.log(this.context)
        }
    }

    render() {
        let {loginCheck,stepOne, category,otpSent} = this.state
        
        return (
                <Modal
                        show={this.props.showInstruction}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size='lg'
                        centered
                        onHide={this.handleCloseModal}
                        backdrop={this.context.auth.loggedIn ? true : 'static'}
                        keyboard={this.context.auth.loggedIn ? true : false }
                        className={styles['hide-in-mobile']}
                    >
                        <Modal.Header />
                        {   
                            !stepOne ?
                                <Modal.Body>
                                        <div className={[styles['firstStep'],'row'].join(' ')}>
                                            <div className={[styles['circle-img'],'col-md-11'].join(' ')}>
                                                <img className="img-fluid" src="https://storage.googleapis.com/spyne-website/whitelabel/with%20Studio-Style%20AI%20Backgrounds%20just%20From%20Your%20Smartphones%20(3).gif" />
                                            </div>
                                         
                                            <div className={[styles['circle-area'],''].join(' ')}>
                                                    <div className={[styles['circle-area-inner']]}>
                                                        <h3>Welcome to Spyne </h3>
                                                        <button style={{marginTop:"10px"}} className={styles['signup-button']} onClick = {this.handleFistMessage}>Sign-up</button>
                                                        <p onClick={this.handleLoginModal}>Existing User? <a >Login here</a></p>
                                                    </div>
                                            </div>
                                           
                                        </div>
                                </Modal.Body>
                            : 
                            loginCheck?
                                <Modal.Body>
                                    {this.state.islogin ?
                                        <center className={styles['login-check-modal']}>
                                            <div className="text-right cursor-pointer">
                                                <img className={styles['close-btn']} onClick={this.loginModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                                            </div>
                                            <img src={this.state.favIcon}></img>
                                            
                                            <p>{this.state.companyName} Login</p>
                                            {/* <Login /> */}
                                            <form onSubmit={this.state.isPassword ? this.handleLogin : !this.state.otpSent? this.handleOtpRequest : this.handleLogin}>
                                                <input className="form-control " type="email" required placeholder="Enter your Email" onChange={this.handleChange}></input>
                                                {this.state.validEmail ? 
                                                <h6>Invalid Credentials</h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ?
                                                <input className="form-control " type="password" placeholder="Enter your Password" onChange={this.handleChangePassword} ref={this.passwordRef}></input>
                                                :
                                                <input className="form-control " type="text" placeholder="Enter your OTP" onChange={this.handleChangeOTP} disabled={!otpSent ? true : false} ref={this.passwordRef}></input>
                                                }
                                                {this.state.otpMessage ? 
                                                    <h6>One Time Password has been sent to your Email</h6>
                                                    : null
                                                }
                                                
                                                {!this.state.otpValid ? 
                                                <h6>Please Enter Valid OTP</h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ? 
                                                    <button className="" type="submit">Login</button>
                                                    :
                                                    <button className="" type="submit">{!this.state.otpSent ? 'Request OTP' : 'Verify OTP'}</button>
                                                }
                                                {/* <div className={styles['or']}><span>or</span></div> */}
                                                {/* <div className="d-flex flex-column justify-content-center align-items-center">
                                                    
                                                    <button onClick={this.handlePasswordOrOTP} className="" style={{backgroundColor: "#000000"}} type="submit">{this.state.isPassword ? 'Login Using OTP (One Time Password)' : 'Login Using Password '}</button>
                                                </div> */}
                                                
                                            </form>
                                            {/* <h5 className={styles['forgot-password']}><a href="https://www.spyne.ai/forgot-password" target="_blank">Forgot Password?</a></h5> */}
                                        </center>
                                        :
                                        <center className={styles['login-check-modal']}>
                                            <div className="text-right cursor-pointer">
                                                <img className={styles['close-btn']} onClick={this.loginModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>  
                                            </div>
                                            <img src={this.state.favIcon}></img>
                                            
                                            <p>{this.state.companyName} Sign-up</p>
                                            {/* <Login /> */}
                                            <form onSubmit={this.state.isPassword? this.handleSignUpPassword : !this.state.otpSent ? this.sendOTP : this.verifyOtp}>
                                                <input className="form-control " type="email" required placeholder="Enter your Email" onChange={this.handleChange}></input>
                                                {this.state.emailExists ? 
                                                <h6>Email Id Already Exists. <a className={styles['login-here']} onClick={this.handleLoginModal}>Login here</a></h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ?
                                                (   
                                                    <input className="form-control " required={true} type="password" placeholder="Enter your Password" onChange={this.handleChangePassword} ref={this.passwordRef}></input>
                                                )
                                                
                                                :
                                                <input className="form-control " required={true} type="text" placeholder="Enter your OTP" onChange={this.handleChangeOTP} disabled={!otpSent ? true : false} ref={this.passwordRef} ></input>
                                                }
                                                {this.state.otpMessage ? 
                                                    <h6>One Time Password has been sent to your Email</h6>
                                                    : null
                                                }
                                                {!this.state.otpValid ? 
                                                <h6>Please Enter Valid OTP</h6>
                                                :
                                                null
                                                }
                                                {this.state.isPassword ? 
                                                    <button className="" type="submit">Sign up</button>
                                                    :
                                                    <button className="" type="submit">{!this.state.otpSent ? 'Request OTP' : 'Verify OTP'}</button>
                                                }
                                                {/* <div className={styles['or']}><span>or</span></div> */}
                                                {/* <button onClick={this.handlePasswordOrOTP} className="" style={{backgroundColor: "#000000"}} type="submit">{this.state.isPassword ? 'Sign-up Using OTP (One Time Password)' : 'Sign-up Using Password '}</button> */}
                                                {/* <p>Already a member? <a href="#"> Login</a></p> */}
                                            </form>
                                            
                                        </center>
                                    }
                                    
                                </Modal.Body>
                            :
                            (   
                                this.props.productHunt=='clippr'?this.handleCloseModal():
                            
                            <Modal.Body>
                                <center className={[styles['car-text']]}>
                                    <h1>Output works best with these <span>Overlays</span></h1>
                                </center>
                                <div className={[styles[' '],'row'].join(' ')}>
                                    <img className="mt-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/angles-gif.gif" />
                                    
                                </div>
                                <center>
                                    <button onClick={this.handleCloseModal} className={styles['got-btn']}>Got It</button>
                                </center>
                            </Modal.Body>)

                        }   
                </Modal>
        )
    }
}
