import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from '../../Css/FacebookTemplate/Template.module.css'
import { data } from '../../Component/FacebookTemplate/ImagesConfig'
import alertify from 'alertifyjs'



function Template(props) {
    const [showMoreTemplates, setShowMoreTemplates] = useState(false)
    const [userName, setUserName] = useState('')
    const [userLocation, setUserLocation] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userMobile, setUserMobile] = useState(0)
    const [category, setCategory] = useState('')
    const [totalSku, setTotalSku] = useState(0)
    const [process, setProcess] = useState('')
    const [isSumbited, setIsSumbited] = useState('')
    const [sumbitBtn, setSumbitBtn] = useState(false)
    const [isName, setIsName] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isInvalidEmail, setIsInvalidEmail] = useState(false)
    const categoryList = ["Food & Beverages ", "Health and Beauty", "Men Fashion", "Women Fashion", "Accessories", "Caps", "Footwear", "Automobile", "Bikes", "E-Commerce", "Fashion", "Fashion clothing", "Generic"]


    // const validation = () => {
       
    //     else false
    // }
    const handleData = async(e) => {
        e.preventDefault()

        let parameters = {
            isEmail:false,
            isName:false,
            isMobile:false,
            validEmail:false,
        }


        const data = new FormData();

        if (!userMobile || userMobile.length !== 10 ) {
            parameters.isMobile = true
        }
        else {
            parameters.isMobile = false

        }

        if (!userEmail) {
            parameters.isEmail=true
        }
        else {
            parameters.isEmail=false
            let temp = invalidEmail(userEmail)
            if(temp)
           parameters.validEmail=true
            else
           parameters.validEmail=false
            
        }

        if (!userName) {
            parameters.isName=true
        }
        else {
            parameters.isName=false
        }

        console.log({isMobile,isEmail,isName,isInvalidEmail})

        if (parameters.isMobile || parameters.isName || parameters.isEmail || parameters.validEmail) {
            setIsEmail(parameters.isEmail)
            setIsMobile(parameters.isMobile)
            setIsInvalidEmail(parameters.validEmail)
            setIsName(parameters.isName)
            return
        }
        // let exit = setTimeout(validation(), 500);
        // if(exit)
        // return

        
        setSumbitBtn(true)
        data.append("name", userName)
        data.append("location", userLocation)
        data.append("email", userEmail)
        data.append("mobile", userMobile)
        data.append("category", category)
        data.append("number_skus", totalSku)
        data.append("select_process", process)
        data.append("page_url", "/tech/facebook")
        data.append("page_source", "facebook")
        axios({
            'method': 'POST',
            "url": `https://www.clippr.ai/api/algo/facebook-template/insert-data`,
            "data": data
        }).then((response) => {
            if (response.status == 200) {
                if(process)
                setIsSumbited(process);
                else
                setIsSumbited('Assisted-Shoot');
            }
            else {
                alertify.warning("something went wrong")
            }
        }).catch((e) => {
            alertify.error("something went wrong")
        })
    }
    const handleMobileNo = (e) => {
        e.preventDefault()
        const value = e.target.value;
        const regex = /^[0-9]+$/; //this will admit numbers only
        if (value.match(regex) || value === "") {
            setUserMobile(value)
        }
    }

    const invalidEmail = (val) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return true;
        }
        else{
            return false;
        }
    }


    return (
        <div className="template-main">{/* Banner Section */}
            <section className={[styles['banner-area'], 'banner-area'].join(' ')}>
                <div className={[styles[''], 'container'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['banner-text'], 'col-md-8'].join(' ')}>
                            <h1>Use the Power of <span>Spyne.AI to boost your sales</span> on Facebook</h1>
                            <a href="https://www.spyne.ai/try-categories" target="_blank" className={['d-none d-md-block']}>Start selling</a>
                        </div>
                        <div className={[styles['banner-image'], 'col-md-4'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/Facebook-template/baner-gif.png" />
                        </div>
                        <div className={[styles['banner-text'], 'col-12 d-block d-md-none text-center mb-5'].join(' ')}>
                            <a href="https://www.spyne.ai/try-categories" target="_blank">Start selling</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Blank Section  */}
            <section className={[styles['black-box']]}> </section>

            {/* Form Section  */}
            <section className={[styles['form-section'], 'form-section'].join(' ')}>
                <div className={[styles[''], 'container'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['form-area'], 'col-md-8 offset-md-2 col-lg-8 offset-lg-2'].join(' ')}>
                            {!isSumbited ?
                                <>
                                    <h3>We’d would love to talk to you</h3>
                                    <p>If you would like to know more about Optifo and how it can help your dealer, please fill in your information below and a sales specialist will contact you.</p>
                                    <form className={[styles['form-content'], 'row'].join(' ')} >
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>
                                            <label>Name*</label>{!isName ? null : <span>Mandatory</span>}
                                            <input type="text" placeholder="Enter username" name="Username" onChange={(e) => { setUserName(e.target.value) }} className="form-control" />

                                        </div>
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>
                                            <label>Location</label>
                                            <input type="text" placeholder="Enter your location" name="Location" onChange={(e) => { setUserLocation(e.target.value) }} className="form-control" />
                                        </div>
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>
                                            <label>Email ID*</label>{!isEmail && !isInvalidEmail ? null : isEmail?<span>Mandatory</span>:<span>Invalid Email</span>}
                                            <input type="email" placeholder="Enter your emailID" name="EmailId" onChange={(e) => { setUserEmail(e.target.value) }} className="form-control" />


                                        </div>
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>

                                            <label>Phone Number*</label>{!isMobile ? null : <span>Mandatory</span>}
                                            <input type="telNo" placeholder="91+" minlength="10" maxlength="10" name="Mobile" className="form-control" value={userMobile === 0 ? null : userMobile} onChange={(e) => handleMobileNo(e)} />

                                        </div>
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>
                                            <label>Category</label>
                                            <input list="Category" onChange={(e) => setCategory(e.target.value)} name="Category" placeholder="Select your Category" className="form-control" />
                                            <datalist id="Category" >
                                                {categoryList.map((e, i) => {
                                                    return (
                                                        <option value={e} />
                                                    )

                                                })}
                                            </datalist >
                                        </div>
                                        <div className={[styles[''], 'col-md-6 form-group'].join(' ')}>
                                            <label>Number of SKUs </label>
                                            <input type="number" placeholder="Project 5-10" min="0" name="Skus" onChange={(e) => { setTotalSku(e.target.value) }} className="form-control" />
                                        </div>
                                        <h4>Self Serve or Assisted Shoot</h4>
                                        <div className={[styles['radio-select'], 'col-md-4 col-5 form-group'].join(' ')} onClick={() => {
                                            document.getElementById('Self-Serve-radio-btn').checked = true;
                                             setProcess('Self-Serve')}}>
                                            <input type="radio" name="shoot" id="Self-Serve-radio-btn"/>
                                            <label>Self Serve </label>
                                        </div>
                                        <div className={[styles['radio-select'], 'col-md-4 col-7 form-group'].join(' ')} onClick={() => {
                                            document.getElementById('Assisted-Shoot-radio-btn').checked = true;
                                            setProcess('Assisted-Shoot')}}>
                                            <input type="radio" name="shoot" id='Assisted-Shoot-radio-btn'/>
                                            <label>Assisted Shoot</label>
                                        </div>
                                    <div className={[styles['btn-area'], 'col-md-12 text-center'].join(' ')}>
                                        <button disable={sumbitBtn ? true : false} onClick={handleData}>Please Contact Me</button>
                                    </div>
                                    </form>
                                </>
                                :
                                <div className={[styles['sumbition-popup'], 'col-md-12 text-center'].join(' ')}>

                                    <img src="https://storage.googleapis.com/spyne-website/Facebook-template/check-tick.gif" />
                                    <h4 className={[styles[''], 'text-center'].join(' ')} >Thanks For Registering!</h4>
                                    {isSumbited === 'Assisted-Shoot' ?
                                        <p>
                                            You're all set to bring your products to life. Download our <a href="https://apps.apple.com/us/app/spyne-ai/id1570801766" target="_blank">SpyneAI app</a> to start shooting.
                                        </p>
                                        :
                                        <p>
                                            Our Sales team will get in touch with you. In the meantime, you can explore our AI <a href="https://www.spyne.ai/tech/cars/try" target="_blank"> web tool</a> here.
                                        </p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* application-loaded */}
            <section className={[styles['application-loaded'], styles['ai-app'], 'application-loaded'].join(' ')}>
                <div className={[styles[''], 'container'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['heading'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                            <h2>AI Application Loaded With amazing Features</h2>
                        </div>
                        <div className={[styles['left-app'], 'col-md-4'].join(' ')}>
                            <div className={[styles['left-single'], 'row'].join(' ')} data-name="01">
                                <h5>Shoot Anywhere</h5>
                                <p>Sensor-guided AI application to help you capture perfect images for any category.</p>
                            </div>
                            <div className={[styles['left-single'], 'row'].join(' ')} data-name="02">
                                <h5>Maketplace-ready Images</h5>
                                <p>One click to any-marketplace-ready images using computer vision & AI. </p>
                                <p>Instant AI filters for any-marketplace</p>
                            </div>
                        </div>
                        <div className={[styles['middle-image'], 'col-md-4'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/Facebook-template/fashion.gif" />
                        </div>
                        <div className={[styles['left-app'], 'col-md-4'].join(' ')}>
                            <div className={[styles['left-single'], 'row'].join(' ')} data-name="03">
                                <h5>One Tap AI Editing</h5>
                                <p>Spyne AI app takes just 3-5 secs to edit an image 100% automatically. It helps you cut time to market for online selling. </p>
                            </div>
                            <div className={[styles['left-single'], 'row'].join(' ')} data-name="04">
                                <h5>500X Faster tech</h5>
                                <p>Process 10000+ images per day for 3X fast cataloguing & scaling needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* amazing web Features */}
            <section className={[styles['amazing-web'], 'amazing-web'].join(' ')}>
                <div className={[styles[''], 'container'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['heading'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                            <h2>Features That Maketplaces & Dealerships Love</h2>
                        </div>
                        <div className={[styles[''], 'col-md-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/Facebook-template/feature1.png" />
                                <h5>Single & Bulk Processing</h5>
                                <p>Drag & drop images in bulk to hyperspeed your image processing using our web tool. </p>
                            </div>

                        </div>
                        <div className={[styles[''], 'col-md-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/Facebook-template/feature2.png" />
                                <h5>Single-click To Background Change</h5>
                                <p>Remove & Add any background in seconds. Choose your marketplace to create any markeplace-ready images.  </p>
                            </div>

                        </div>
                        <div className={[styles[''], 'col-md-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/Facebook-template/feature3.png" />
                                <h5>API Integration</h5>
                                <p>Do you want to transform massive catalogs instantly? Use our API to make your workflow efficient and seamless. </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* Templates for absolutely  */}
            <section className={[styles['templates']]}>
                <div className={[styles[''], 'container-fluid'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['heading'], 'col-md-10 offset-md-1 col-lg-10 offset-lg-1'].join(' ')}>
                            <h2>Templates for absolutely anything</h2>
                            <p>Customise your own background, or add creative custom backgronds.</p>
                        </div>
                        <div className={[styles['fade-in'], 'col-md-2 col-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[0].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[1].src} />
                            </div>

                            {showMoreTemplates ?
                                <>

                                    {
                                        data.map((imgData, index) => {
                                            if (index >= 2 && index <= 3) {
                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }

                                    {/* For mobile  */}
                                    <div className={[styles[''], 'd-md-none d-block'].join(' ')}>
                                        {
                                            data.map((imgData, index) => {
                                                if (index >= 4 && index <= 7) {
                                                    return (
                                                        <div className={[styles['left-web'], 'row'].join(' ')}>
                                                            <img src={imgData.src} />
                                                        </div>
                                                    )
                                                }
                                                else
                                                    return null
                                            })
                                        }
                                    </div>
                                </>

                                :
                                null
                            }

                        </div>
                        {/* //1st Col  */}
                        <div className={[styles['fade-in'], 'col-md-2 col-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[8].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[9].src} />
                            </div>

                            {showMoreTemplates ?
                                <>
                                    {
                                        data.map((imgData, index) => {
                                            if (index >= 10 && index <= 11) {

                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }

                                    {/* For mobile */}
                                    <div className={[styles[''], 'd-md-none d-block'].join(' ')}>
                                        {
                                            data.map((imgData, index) => {
                                                if (index >= 12 && index <= 14) {
                                                    return (
                                                        <div className={[styles['left-web'], 'row'].join(' ')}>
                                                            <img src={imgData.src} />
                                                        </div>
                                                    )
                                                }
                                                else
                                                    return null
                                            })
                                        }
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                        {/* //2nd Col  */}
                        <div className={[styles['fade-in'], 'col-md-2 col-4'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[15].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[16].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[17].src} />
                            </div>

                            {showMoreTemplates ?
                                <>
                                    {
                                        data.map((imgData, index) => {
                                            if (index === 18) {
                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }
                                    {/* For mobile */}
                                    <div className={[styles[''], 'd-md-none d-block'].join(' ')}>
                                        {
                                            data.map((imgData, index) => {
                                                if (index >= 19 && index <= 22) {

                                                    return (
                                                        <div className={[styles['left-web'], 'row'].join(' ')}>
                                                            <img src={imgData.src} />
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })
                                        }
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                        {/* //3rd Col  */}
                        <div className={[styles['fade-in'], 'col-md-2 col-4 d-md-block d-none'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[4].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[5].src} />
                            </div>

                            {showMoreTemplates ?
                                <>
                                    {
                                        data.map((imgData, index) => {
                                            if (index >= 6 && index <= 7) {

                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }
                                </>
                                :
                                null
                            }
                        </div>
                        {/* //4th Col  */}
                        <div className={[styles['fade-in'], 'col-md-2 col-4 d-md-block d-none'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[12].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[13].src} />
                            </div>
                            {showMoreTemplates ?
                                <>
                                    {
                                        data.map((imgData, index) => {
                                            if (index === 14) {
                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }
                                </>
                                :
                                null
                            }
                        </div>
                        {/* //5th Col  */}
                        <div className={[styles['fade-in'], 'col-md-2 col-4 d-md-block d-none'].join(' ')}>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[19].src} />
                            </div>
                            <div className={[styles['left-web'], 'row'].join(' ')}>
                                <img src={data[20].src} />
                            </div>

                            {showMoreTemplates ?
                                <>
                                    {
                                        data.map((imgData, index) => {
                                            if (index >= 21 && index <= 22) {

                                                return (
                                                    <div className={[styles['left-web'], 'row'].join(' ')}>
                                                        <img src={imgData.src} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    }
                                </>
                                :
                                null
                            }
                        </div>
                        {/* //6th Col  */}
                        <div className={[styles['expand-btn'], ''].join(' ')}>
                            <button onClick={() => setShowMoreTemplates(!showMoreTemplates)}>{showMoreTemplates ? "Browse Less Templates" : "Browse more templates"}</button>
                        </div>
                    </div>
                </div>
            </section>

            {/* Single dashboard to track */}
            <section className={[styles['application-loaded'], 'application-loaded'].join(' ')}>
                <div className={[styles[''], 'container'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['heading'], 'col-md-10 offset-md-1 col-lg-8 offset-lg-2'].join(' ')}>
                            <h2>Single dashboard to track all your on-going shoots</h2>
                        </div>

                        <div className={[styles['single-dash-image'], 'col-md-7'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/Facebook-template/dashboard.png" />
                        </div>
                        <div className={[styles['left-app'], 'col-md-5'].join(' ')}>
                            <div className={[styles['dash-details'], 'row'].join(' ')}>
                                <div className={[styles['dashboard-image'], 'col-md-2 col-4'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Facebook-template/realtime.png" />
                                </div>
                                <div className={[styles['dashboard-content'], 'col-md-10 col-8'].join(' ')}>
                                    <h6>Realtime Tracking</h6>
                                    <p>Single dashboard to oversee all your ongoing shoots from multiple locations </p>
                                </div>
                            </div>
                            <div className={[styles['dash-details'], 'row'].join(' ')}>
                                <div className={[styles['dashboard-image'], 'col-md-2 col-4'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Facebook-template/image.png" />
                                </div>
                                <div className={[styles['dashboard-content'], 'col-md-10 col-8'].join(' ')}>
                                    <h6>Image Rating</h6>
                                    <p>The AI automatically rates your image on three parameters: size, quality, blurriness. The higher the rating, the higher are the chances to sell online.</p>
                                </div>
                            </div>
                            <div className={[styles['dash-details'], 'row'].join(' ')}>
                                <div className={[styles['dashboard-image'], 'col-md-2 col-4'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/Facebook-template/validate.png" />
                                </div>
                                <div className={[styles['dashboard-content'], 'col-md-10 col-8'].join(' ')}>
                                    <h6>Validate The Best Images</h6>
                                    <p>You can validate and reject images from the dashboard itself. This way you can initiate re-shoot in real-time to ensure all images are uniform and standard. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Power Your Marketplace */}
            <section className={[styles['power-your-marketplace']]}>
                <div className={[styles[''], 'container-fluid'].join(' ')}>
                    <div className={[styles[''], 'row'].join(' ')}>
                        <div className={[styles['heading'], 'col-md-6'].join(' ')}>
                            <h2>Power Your Marketplace With AI-powered Visuals </h2>
                            <p>Download SpyneAI application to shoot, edit and scale your visual operations across locations. Track and manage all projects from the single dashboard.</p>
                            <h6>Get the App</h6>
                            <h5><a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank"><img className={[styles['try-image']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /></a>
                                <a href="https://apps.apple.com/us/app/spyne-ai/id1570801766" target="_blank"><img className={[styles['try-image']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /></a></h5>
                        </div>
                        <div className={[styles['marketplace-images'], 'col-md-6'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/Facebook-template/power-market.png" />
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );


}
export default Template
