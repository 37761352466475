const topWear = [
    { 
        "Myntra":{
            "marketPlaceName":"myntra",
            "left":0,
            "right":0,
            "top":5,
            "bottom":100,
            "output_height":1440,
            "output_width":1080,        
        },
        "Amazon":{
            "marketPlaceName":"amazon",
            "left":0,
            "right":0,
            "top":50,
            "bottom":100,
            "output_height":1500,
            "output_width":1500,
        },
        "Flipkart":{
            "marketPlaceName":"flipkart",
            "left":0,
            "right":0,
            "top":50,
            "bottom":50,
            "output_height":1500,
            "output_width":1500,
        },
        "Ajio":{
            "marketPlaceName":"ajio",
            "left":0,
            "right":0,
            "top":50,
            "bottom":50,
            "output_height":1117,
            "output_width":1400,
        },
        "Tata Cliq":{
            "marketPlaceName":"tata Cliq",
            "left":0,
            "right":0,
            "top":30,
            "bottom":30,
            "output_height":1117,
            "output_width":1400,
        }
    }

]

const bottomWear = [
    { 
        "Myntra":{
            "marketPlaceName":"myntra",
            "left":0,
            "right":0,
            "top":120,
            "bottom":5,
            "output_height":1440,
            "output_width":1080,    
        },
        "Amazon":{
            "marketPlaceName":"amazon",
            "left":0,
            "right":0,
            "top":120,
            "bottom":50,
            "output_height":1500,
            "output_width":1500,
        },
        "Flipkart":{
            "marketPlaceName":"flipkart",
            "left":0,
            "right":0,
            "top":120,
            "bottom":50,
            "output_height":1500,
            "output_width":1500,
        },
        "Ajio":{
            "marketPlaceName":"ajio",
            "left":0,
            "right":0,
            "top":120,
            "bottom":50,
            "output_height":1117,
            "output_width":1400,
        },
        "Tata Cliq":{
            "marketPlaceName":"tata Cliq",
            "left":0,
            "right":0,
            "top":50,
            "bottom":30,
            "output_height":1117,
            "output_width":1400,
        }
    }
]

const backgroundReplacementData = [
    {
        "Amazon":{
            "top":50,
            "bottom":50,
            "left":50,
            "right":50,
        },
        "Flipkart":{
            "top":50,
            "bottom":50,
            "left":50,
            "right":50,
        },
        "Myntra":{
            "top":100,
            "bottom":100,
            "left":100,
            "right":100,
        },
        "Tata Cliq":{
            "top":30,
            "bottom":30,
            "left":30,
            "right":30,
        },
        "Ajio":{
            "top":100,
            "bottom":100,
            "left":100,
            "right":100,
        }
    }
]
export {topWear, bottomWear, backgroundReplacementData}

//<------------Refrence for Backend Modal value and Frontend Value------------>
// topwear ----> topwear is bottom and head is top
// bottomwer --> bottomwear is top and feet is bottom