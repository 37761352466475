import React, { useContext } from 'react'
import { GoogleLogin } from 'react-google-login'
import { AuthContext } from '../../../context/GlobalState'

const CLIENT_ID = process.REACT_APP_GOOGLE_CLIENT_ID

const GoogleLoginIn = ({ props }) => {

    const { auth, loggedIn, loggedOut } = useContext(AuthContext)

    const onSuccess = (res) => {
        loggedIn({
            loggedIn: true,
            user: {
                username: res.profileObj.name,
                emailId: res.profileObj.email,
            },
            token: res.accessToken
        })
        // Login the user in spyne
    }

    const onFailure = (res) => {
        console.log("Login Failure ", res)
    }

    return (
        <>
            <GoogleLogin
                clientId={CLIENT_ID} 
                buttonText="Continue With Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                style={{ marginBottom: "100px" }}
                isSignedIn={true}
            />
        </>
    )

}

export default GoogleLoginIn