import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios';
import styles from '../../Css/LoginModal.module.css'
export default class LoginModal extends Component {
    constructor(props){
        super(props)
        this.state={
            show:true,
            showModal:false,
            useremail: '' ,
            domain:''
        }
    }
    handleModal = () => {
        this.setState({
            show:!this.state.show,
            showModal:true
        })
    }
    componentDidMount = () => {
        this.setState({
            useremail:window.localStorage.getItem("clippr_user_email"),
            domain:window.location.href
        })
    }
    render() {
        return (
            !this.props.cars ?
            <div>
                <Modal 
                    show={this.props.show}
                    onHide={this.props.onHide}
                    centered
                    style={{borderRadius:'20px'}}
                    
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    
                    
                    <form onSubmit={!this.props.otpScreen ? this.props.sendOTP : this.props.verification ? null : this.props.verifyOTP}>
                    <center>  
                        {
                            !this.state.otpScreen ?
                            <h6>Enjoy 5 free Credits</h6>
                            :null
                        }
                    </center>
                        <div style={{marginTop:'40px'}}>
                            {   
                                !this.props.otpScreen ?
                                <>
                                    <p className="otpScreen-email">Your Email Id</p>
                                    <input name="email" type="email" defaultValue={this.props.defaultEmail} className="email-verification form-control w-75" placeholder="Enter Your Email" onChange={this.props.handleChange}/>
                                </>
                                : this.props.verification ?
                                <div>
                                    <img  className="otpScreen-success-image" alt="verified" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/verified.svg"/>
                                    <p className="mt-3 otpScreen-success-image">Great your has been successfully Verified.</p>
                                    <p className="otpScreen-success-image mt-4"><b>Verified.</b></p>
                                </div>
                                :
                                <>
                                <img className="otpScreen-image"  src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/otpScreen.svg"></img>
                                <p  style={{marginTop:'10px'}} className="otpScreen-image">Enter OTP</p>
                                <div className="otp-field">
                                    <input onChange={this.props.handleChange} name="otp" className="form-control w-75 mt-3" placeholder="Enter OTP"></input>
                                </div>
                                {
                                    !this.props.otpVerification ? 
                                    <>
                                    <p className="otpScreen-image mt-3 text-danger">Please enter valid OTP</p>
                                    </>
                                    :null
                                }
                                </>
                            }
                        </div>
                        {
                            !this.props.otpScreen ?
                            <>
                                {!this.props.emailValidation ? <p className="text-danger text-center mt-4">Enter Valid Email Id</p>: null} 
                                <button className='btn btn-primary submit-button-otpScreen'  onClick={this.props.sendOTP}>Send OTP</button>
                                {/* <button className='btn btn-primary submit-button-otpScreen'  type="submit">Send OTP</button> */}

                            </>
                            : this.props.verification ? null :
                            <button className='btn btn-primary submit-button-otpScreen'  onClick={this.props.verifyOTP}>Verify</button>
                            // <button className='btn btn-primary submit-button-otpScreen'  type="submit">Verify</button>

                        }
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
            :
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                centered
                style={{borderRadius:'20px'}}
                size="md"
            >
               <Modal.Header closeButton></Modal.Header>
               <Modal.Body>
                   { !this.state.verification ?
                        <>
                        
                        <div className="row">
                        
                            <div className={[styles["left-sec"],'col-12'].join(' ')}>
                                <div className={[styles["dynamic-logo-popup"],' '].join(' ')}>
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/spyne/images/spyne-black-logo.png" ></img>
                                </div>

                                {   this.props.cars && this.props.footwear == undefined && this.props.sky == undefined?
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/image+1.png"></img>
                                    :
                                    this.props.cars == undefined && this.props.footwear && this.props.sky == undefined ? 
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Footwear/giphy+1.png" className={styles['footwear-image']}></img>
                                    :
                                    <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Sky/Drag+image+here.png"></img>
                                }
                            </div>
                            <div className={[styles['right-sec'],'col-12'].join(' ')}>
                                    {   !this.props.otpScreen ?
                                        <p className={[styles['email'],''].join(' ')}>Email address*</p>
                                        :
                                        <p className={[styles['email'],''].join(' ')}>Enter OTP</p>
                                    }
                                    <form onSubmit={!this.props.otpScreen ? this.props.sendOTP : this.props.verification ? null : this.props.verifyOTP}>
                                        {
                                            !this.props.otpScreen ?
                                            <>
                                                <input name="email" type="email" defaultValue={this.props.defaultEmail} className="form-control" placeholder="Enter Your Email" onChange={this.props.handleChange}/>
                                                <button className={[styles['send-otp'],'btn'].join(' ')}  type="submit" onClick={this.props.sendOTP}>Send OTP</button>
                                            </>
                                            : this.props.verification ?
                                            <div>
                                                <img  className="otpScreen-success-image" alt="verified" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/verified.svg"/>
                                                <p className="mt-3 otpScreen-success-image">Great your has been successfully Verified.</p>
                                                <p className="otpScreen-success-image mt-4"><b>Verified.</b></p>
                                            </div>
                                            :
                                            <>
                                                <input hidden></input>
                                                <input onChange={this.props.handleChange} name="otp" className={[styles['otp-input'],"form-control"].join(' ')}  placeholder="Enter OTP"></input>
                                                <p className={[styles['otp-send-info'],''].join(' ')}>An OTP has been sent to your Email Address</p>
                                                <button className={[styles['verify'],'btn'].join(' ')} onClick={this.props.verifyOTP} type="submit">Verify</button>
                                                {
                                                    !this.props.otpVerification ? 
                                                    <>
                                                        <p className="otpScreen-image mt-3 text-danger">Please enter valid OTP</p>
                                                    </>
                                                    :null
                                                }
                                            </>
                                        }
                                    </form>
                            </div>
                        </div>
                        </>
                        :
                        <center>
                            <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/check-circle+1.png" ></img>
                            <p className={[styles['verification'],''].join}>Great your Email has been successfully Verified.</p>
                        </center>
                    }
               </Modal.Body>
            </Modal>
        )
    }
}
