import Slider from "react-slick";
import styles from '../../Css/ImageScoring/UploadScreen.module.css'
import '../../Css/ImageScoring/Scanner.css'
import Dropzone from 'react-dropzone'
import React,{useEffect,useState,useContext,useRef} from 'react'
import axios from 'axios'
import { event } from "jquery";
import ProgressBar from 'react-bootstrap/ProgressBar'

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }

function ImageScoringUpload() {

    const [imageFile,setImageFile] = useState('')
    const [sampleImages, setSampleImages] = useState([])
    const [processingStarted, setProcessingStarted] = useState(true)
    const [imageScore, setImageScore] = useState('')
    const [imageQuality, setImageQuality] = useState('')
    const [blurScore, setBlurScore] = useState('')
    const [cropScore, setCropScore] = useState('')
    const [resScore, setResScore] = useState('')
    const [dropBackground,setDropBackground] = useState(false)


    useEffect(() => {
        getSampleImages()
    },[])

    const settings = {
        // className: "center",
        // dots: true,
        infinite: false,
        slidesToShow:4,
        slidesToScroll: 1, 
        swipeToSlide: true,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 7000
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }

    const fileUpload = (event) => {
        if(event.target){
            let file = {
                file: event.target.files,
                name: event.target.files.name,
                url: URL.createObjectURL(event.target.files),
                imageScore:null
            }
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            setImageFile(file)
            getImageScore(event.target.files)
        }else{
            let file = {
                file: event[0],
                name: event[0].name,
                url: URL.createObjectURL(event[0]),
                imageScore: null
            }
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            setImageFile(file)
            let uploadType = 'image'
            getImageScore(uploadType,event[0])
        }
    }

    const getSampleImages = () => {

        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/product/fetch-sample-images",
        })
        .then((resp) => {
            if(resp?.data?.status == 200){
                setSampleImages(resp?.data?.data)
            }
        })
    }

    const handleSampleImageClick = (url) =>{
        let file = {
            
            url: url,
            status: 'Uploading',
            imageScore: null,
            file: null
        }
        setImageFile(file)
        let uploadType = 'url'
        getImageScore(uploadType,url)
    }

    const getImageScore = (uploadType,file) => {
      setProcessingStarted(true)
      setDropBackground(false)
      const fd = new FormData()
      if(uploadType === 'image'){
        fd.append('image',file)
      }else{
        fd.append('image_url',file)
      }
      axios.post(`https://www.clippr.ai/api/v2/image/image-scoring`,fd)
      .then((resp)=>{
        console.log(resp)
        if(resp?.data.status === 200){
          setImageScore(resp.data.image_score)
          setBlurScore(resp.data.blur_score)
          setResScore(resp.data.res_score)
          setCropScore(resp.data.crop_score)

          blurColor(resp.data.blur_score)
          resColor(resp.data.res_score)
          cropColor(resp.data.crop_score)

          if(resp.data.image_score > 75){
            setImageQuality('Excellent')
            document.documentElement.style.setProperty("--textColor",'#198754')
          }else if(resp.data.image_score > 50){
            setImageQuality('Good')
            document.documentElement.style.setProperty("--textColor",'#ffc107')
          }
          else{
            setImageQuality('Bad')
            document.documentElement.style.setProperty("--textColor",'#dc3545')
          }



        }
        setProcessingStarted(false)
      })
      .catch((err) => {
        setProcessingStarted(false)
        setImageFile('')
      })
    }

    const blurColor = (blur) => {
      if(blur > 7.5) {
        document.documentElement.style.setProperty("--blurColor",'#198754')
      }else if (blur > 3.5){
        document.documentElement.style.setProperty("--blurColor",'#ffc107')
      }else{
        document.documentElement.style.setProperty("--blurColor",'#dc3545')
      }
    }

    const resColor = (res) => {
      if(res > 7.5) {
        
        document.documentElement.style.setProperty("--resColor",'#198754')
      }else if (res > 3.5){
        document.documentElement.style.setProperty("--resColor",'#ffc107')
      }else{
        document.documentElement.style.setProperty("--resColor",'#dc3545')
      }
    }

    const cropColor = (crop) => {
      if(crop > 7.5) {
        
        document.documentElement.style.setProperty("--cropColor",'#198754')
      }else if (crop > 3.5){
        document.documentElement.style.setProperty("--cropColor",'#ffc107')
      }else{
        document.documentElement.style.setProperty("--cropColor",'#dc3545')
      }
    }

    const handleRedirection = () => {
      window.location.href = 'https://www.spyne.ai/try-categories'
    }

    const handleRedirectionPlayStore = () => {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.spyneai'
    }

    const handleRedirectionAppStore = () => {
      window.location.href = 'https://apps.apple.com/us/app/spyne-ai/id1570801766'
    }

    const handleRedirectionAPI = () => {
      window.location.href = 'https://documenter.getpostman.com/view/10990096/TzRX7Qbq'
    }

    const dragStart = (e) => {
      e.preventDefault()
      setDropBackground(true)
    }
    const dragLeave = (e) => {
      e.preventDefault()
      setDropBackground(false) 
    }

    return (
      <Dropzone  onDrop={acceptedFiles => fileUpload(acceptedFiles)} onDragEnter={dragStart} onDragLeave={dragLeave}>
        {({getRootProps, getInputProps}) => (
                <div {...getRootProps({
                    onClick: event => event.stopPropagation()
                })} className={styles['dropzone1']}>
                    {dropBackground ?
                        <section className={[styles['drop-box-text']]}>
                        <div>Drop Image Anywhere</div>  
                        
                        </section>
                        : null
                    }
                    {imageFile === '' ?
            
            <section className={[styles['image-scoring'],'i'].join(' ')}>
                  <div className={[styles['image-container'],''].join(' ')}>
                      <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['scoring-col'],'col-md-6 col-lg-5'].join(' ')}>
                            <div className={[styles['scoring-upload'],'row'].join(' ')}>
                                <div className={[styles['scoring-upload-box'],'col-md-12'].join(' ')}>
                                    <Dropzone onDrop={acceptedFiles => fileUpload(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({})}>
                                                <img src="https://storage.googleapis.com/spyne-website/image-scoring/score-upload.gif"/>
                                                <h6 className="d-md-block d-none">Drag & D<span>rop or Click file here t</span>o upload</h6>
                                                <h6 className="d-block d-md-none">u<span>pload your fil</span>e</h6>
                                                <p>Browse files</p> 
                                                <input  onChange={fileUpload}  type="file" {...getInputProps()} />
                                            </div>
                                            )}
                                        </Dropzone> 
                                    <div className={[styles['slider-area'],'row m-0'].join(' ')}>
                                      <p>No image? Try one of these: <span onClick={getSampleImages} style={{cursor:"pointer"}}> <i class="fa fa-refresh" aria-hidden="true"></i></span></p>
                                      <Slider {...settings} className= {[styles['image-slider'],'col-md-12 image-slider'].join(' ')} > 
                                        {sampleImages.map((e,i)=>{
                                            return(
                                                <div className={[styles['slide']]} onClick={() => handleSampleImageClick(e.image_url)}>
                                                    <img src={e.image_url} />
                                                </div>
                                            )
                                        })}
                                      </Slider>
                                    </div>
                                </div>

                            </div>
                        </div>
                          <div className={[styles['scoring-title'],'col-md-6 col-lg-7'].join(' ')}> 
                               <h1>Try out <span>SpyneAI's </span>Catalog Image Scorer</h1>
                               <p>Use the Power of <span>Computer Vision </span>to know how well your images will work </p>
                               <img src="https://storage.googleapis.com/spyne-website/image-scoring/Rectangle.png" className={styles['rectangle']}/>
                          </div>
                      </div>
                  </div>
                </section>
                // Upload Screen Section 
                :
                <section className={[styles['image-scoring'],styles['image-scoring-2'],'i'].join(' ')}>
                  <div className={[styles['image-container'],''].join(' ')}>
                      <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['scoring-col'],styles['scoring-col2'],'col-lg-5 col-md-6'].join(' ')}>
                            <div className={[styles['scoring-upload2'],'row'].join(' ')}>
                                <div className={[styles['scoring-upload-box2'],'col-md-12'].join(' ')}>
                                {!processingStarted ? 
                                
                                    <img src={imageFile.url}/>
                                    :
                                    <div className={[styles['scan'],'scan'].join(' ')}>
                                      <img src={imageFile.url}/>  
                                    </div>
                                }
                                {/* <div id="monitor">

                                  <div className="scan"></div>
                                  <div className="screen"></div>

                                </div> */}
                                  
                                     
                                </div>
                                 <Dropzone  onDrop={acceptedFiles => fileUpload(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps({})}>
                                                <p className="d-md-block d-none">Drag & Drop or Click file here to <span>upload</span></p>
                                                <p className="d-block d-md-none"><span>upload</span> your Image</p>
                                                <input  onChange={fileUpload}  type="file" {...getInputProps()} />
                                            </div>
                                        )}
                                </Dropzone> 
                            </div>
                            <div className={[styles['slider-area'],styles['slider2'],'row m-0'].join(' ')}>
                                      <p>No image? Try one of these: <span onClick={getSampleImages} style={{cursor:"pointer"}}> <i class="fa fa-refresh" aria-hidden="true"></i></span></p>
                                      <Slider {...settings} className= {[styles['image-slider'],'col-md-12 image-slider'].join(' ')} > 
                                      {sampleImages.map((e,i)=>{
                                            return(
                                                <div className={[styles['slide'],styles['slide2'],''].join(' ')} onClick={() => handleSampleImageClick(e.image_url)}>
                                                    <img src={e.image_url} />
                                                </div>
                                            )
                                        })}
                                      </Slider>
                                    </div>
                        </div>
                        {processingStarted ? 
                          <div className={[styles['scoring-title'],styles['scoring-title2'],'col-lg-7 col-md-6'].join(' ')}>
                              <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" className={styles['loading-gif']}/>
                          </div>
                          :
                          <div className={[styles['scoring-title'],styles['scoring-title2'],'col-lg-7 col-md-6'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/image-scoring/Rectangle2.png" className={[styles['rectangle2']]}/>
                               <h1>{imageScore}% <span>{imageQuality}!</span></h1>
                                {imageQuality === 'Excellent' ? 
                                  <ProgressBar variant="success" now={imageScore} min={0} max={100} className={[styles['progress']]}/>
                                  :
                                  imageQuality === 'Good' ?
                                  <ProgressBar variant="warning" now={imageScore} min={0} max={100} className={[styles['progress']]}/>
                                  :
                                  imageQuality === 'Ok' ?
                                  <ProgressBar variant="info" now={imageScore} min={0} max={100} className={[styles['progress']]}/>
                                  :
                                  <ProgressBar variant="danger" now={imageScore} min={0} max={100} className={[styles['progress']]}/>

                                }
                                <ul>
                                    <li><p><button className={[styles['six']]}>{blurScore} / 10</button> Blur Score <b  className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i><span>High Blur Score is good, Low Blur Score indicates that the image has noise and is blurry!</span></b></p></li>
                                    <li><p><button className={[styles['nine']]}>{resScore} / 10</button> Resolution / Image Size <b className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i><span>For Most E-commerce HD Images are reqd, Low scores indicate small images that will be rejected on most marketplaces.</span></b></p></li>
                                    <li><p><button className={[styles['five']]}>{cropScore} / 10</button>Subject Crop Score <b className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i><span>This score calculates if the entire subject of the photo is captured. Low Score indicates that part of the main subject has been cropped.</span></b></p></li>
                                    <li><p> <button className={[styles['comming']]}>Coming Soon</button> <b>Lighting Score</b> </p></li>
                                    <li><p> <button className={[styles['comming']]}>Coming Soon</button> <b>Marketplace Dimension Verifier</b> </p></li>
                                </ul>

                                {/* <ul className={[styles['marketplace-dimension'],''].join(' ')}>
                                    <h5><button className={[styles['comming-2']]}>Coming Soon</button><span style={{marginTop:"5px"}}>Marketplace <span style={{color: "#ff7700"}}>Dimension</span> Verifier</span></h5>
                                    <li><button className={[styles['marketplace-img'],styles['active'],''].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/image-scoring/check.png" className={[styles['check']]}/>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li><button className={[styles['marketplace-img'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li><button className={[styles['marketplace-img'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li>Your Image is almost matching <br/>to myntra guidelines: 1440/1080</li>
                                </ul>

                                <p>Power your marketplace imagery with our AI</p> */}
                                <h5><button className={[styles['try-btn']]} onClick={handleRedirection}>Try Spyne AI</button>
                                 <button onClick={handleRedirectionAPI} className={[styles['try-api']]}>Try API</button>
                                 </h5>
                                 <h5>
                                   <img className={[styles['try-img']]} onClick={handleRedirectionPlayStore} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" />
                                   <img className={[styles['try-img']]} onClick={handleRedirectionAppStore} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" />
                                 </h5>
                          </div>

                        } 
                          
                      </div>
                  </div>
                </section>
            } 
                  
                    <input  onChange={fileUpload}  type="file" {...getInputProps()} />
                </div>
        )}
    </Dropzone>
        
    )
}

export default ImageScoringUpload
