import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/Model/CreditModal.module.css'

class CreditModal extends Component {
    state = {  }
    handleCreditModal = () => {

    }
    render() { 
        return ( 
                <Modal
                    show={this.props.showCreditModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='md'
                    centered
                    >
                        <Modal.Body className="text-right cursor-pointer">
                            <img onClick={this.props.handleCreditModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                        </Modal.Body>
                        <Modal.Body className="text-center">
                            
                            <div className={[styles['credit-modal-text']]}>
                                <h4>Download Full Size Image</h4>
                                <img src={this.props.editedUrl}/>
                                <p>Full Image (1920 × 1080)    | No Watermark</p>
                                <h6>1 Image = {this.props.bgCredit} Credit</h6>

                                <button onClick={this.props.redirectPricePage} >
                                    Buy Credit Now
                                </button>
                                <p>Buy credits & download full size image</p>
                            </div>
                        </Modal.Body>
                    
                </Modal> 
        );
    }
}
 
export default CreditModal;