import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Modal } from "react-bootstrap";
import Toggle from 'react-toggle'
import axios from 'axios'
import TwentyTwenty from 'react-twentytwenty'

import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import '../../../App.css'
import BikesBackground from './BikesBackground'
import BulkBikesBackground from './BulkBikesBackground'
import CarsFront from '../FrontPages/CarsFront'
import Footer from '../../Common/Footer'
import {fetchCreditUserDetails, updateUserCredit,cookie,poll, chunkArrayWhiteoutCorrection} from '../../Common/Utils'
import LandingModal from '../../Common/Modal/LandingModal'
import CarsOutputModal from '../../Common/Modal/BackgroundListModal'  
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Styles from '../../../Css/CarV2.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Resizer from 'react-image-file-resizer'
import {Helmet} from "react-helmet";
// import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Background/Spinner'
import queryString from 'query-string'
import {isMobile} from 'react-device-detect';
import { types } from 'image-size';
import cls from '../../../Css/CarsThreeSixty.module.css'
import CarsThreeSixtyVideo from '../CarsThreeSixtyVideo';
import alertify from 'alertifyjs';
import * as Sentry from "@sentry/react"
import Drawer from '../../Drawer/Drawer'




class CarsLandingPage extends Component {
    constructor(props){
        super(props)
        this.state={
          selectedFile: null,
          selectedLogoFile: null,
          url: window.sessionStorage.getItem("lastEditedImage") ? window.sessionStorage.getItem("lastEditedImage") : '' , // url of the image returned by the api 
          orgUrl:  window.sessionStorage.getItem("lastRawImage") ? window.sessionStorage.getItem("lastRawImage") :  '',
          object_id:0,
          background_id:929,
          uploadPercentage: 0,
          uploading: false,
          orgImgShow: true,
          loader: false,
          uploadBox: true,
          editedState: false,
          below_bg:false,
          tabIndex: 0,
          isError: false,
          processing: false,
          editedImagesArr: [],
          editing: false,
          carShadow:false,
          buffer_array:[1],
          cnt:1,
          spinner:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          car_bg_replacement_angle:'front',
          selectedAngle:'',
          imageSelected:false,
          firstUpload:true,
          planData:[],
          walldata:[],
          availableCredit:0,
          alottedCredit: 0,
          creditUsed:0,
          isEditingFinish:false,
          toggleChange:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          toggleStatus:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          bgCredit:1,
          loginStatus:false,
          logoUrl:'',
          logoPosition:null,
          hideLogo:false,
          showInstruction:false,
          showInstructionModal:false,
          askEmail:false, 
          activButton: "Exterior",
          backgroundId:["80","81","82"],
          backgroundEditedUrl:[],
          imageEdited : false,
          actiButton:"Background1",
          waterMarkImg:"",
          activateButton:"Hd",
          downloadComplete: false,
          isDownload: false,
          urlDownload: '',
          dropBackground: false,
          checkColorEnhancement:false,
          checkBlurNumberPlate:false,
          checkWindowTransparency:false,
          checkWindowCorrenction:0,
          filesList:JSON.parse(sessionStorage.getItem("filesList")) ? JSON.parse(sessionStorage.getItem("filesList")) : [],
          setFiles:JSON.parse(sessionStorage.getItem("setFiles")) ? JSON.parse(sessionStorage.getItem("setFiles")) : [],
          maxUpload: false,
          showBulkCreditModal: false,
          showProjectNameModal: false,
          project_name: window.sessionStorage.getItem("project_name") ? window.sessionStorage.getItem("project_name") : '',
          sku_name: window.sessionStorage.getItem("sku_name") ? window.sessionStorage.getItem("sku_name") : '',
          project_id:'',
          sku_id:'',
          singleImage_Id:'',
          notEnoughCreditModal:false,
          enoughCreditModal:false,
          zeroCreditModal:false,
          uploadStarteds: window.sessionStorage.getItem("uploadStarteds") ? window.sessionStorage.getItem("uploadStarteds") : false,
          orgUrlList:[],
          imgStatusUpload: true,
          imgStatusProcess: false,
          imgStatusDone: false,
          showRefundModal: false,
          refundImgUrl:'',
          refundOrgUrl:'',
          refundImgId:'',
          refundSkuId:'',
          refundNum:null,
          userId : '',
          downloadZipLink: window.sessionStorage.getItem("downloadZipLink") ? window.sessionStorage.getItem("downloadZipLink") :'',
          isZipReady : window.sessionStorage.getItem("isZipReady") ? window.sessionStorage.getItem("isZipReady") : false,
          bulkDownloadDone:window.sessionStorage.getItem("bulkDownloadDone") ? window.sessionStorage.getItem("bulkDownloadDone") : false,
          errorBulkFiles: [],
          auth_key:'',
          spinnerDownload: false,
          emailId:'',
          wall:"",
          customWall:"",
          customWallPosition:0,
          tabSelection:"virtual_studio",
          productHunt: '',
          projectNameExists:false,
          isBulk : false,
          cropperList : [],
          tempref:null ,
          cropConst:true,
          fav_list:[],
          bg_list:[],
          checkGreyShade:false,
           zoom: 1,
           aspect: 1,
           showlogoCropModal: false,
           croppedLogo: null,
           croppedImageUrl: null,
           checkUploadLogo: false,
           chargedFeature:0,
           numberPlateChargesApplied:false,
           
          imageId:'',
          beforeCredits:0,
          viewThreeSixty: false,
          history_data:[],
          history_data_error:false,
          imgId_for_retouch:[],
          img_id:[],
          batchFiles:[],
          prodCatId: 'cat_d8R14zUNx'
        }

        Sentry.init({
            dsn: "<https://e680ca88ac2645ec91329a2109c3cbcc@o1213546.ingest.sentry.io/6383824>"
        });
    }

    
    componentDidMount = async () => {
        // if(window.localStorage.getItem("filesList")){
        //     this.setState({
        //         filesList: JSON.parse(localStorage.getItem("filesList"))
        //     })
        // }

        window.sessionStorage.removeItem("project_name")
        window.sessionStorage.removeItem("sku_name")

        let enterprise = this.cookie('enterpriseId')

        if(enterprise != null){
            if(enterprise != 'TaD1VC1Ko'){
                document.cookie = 'emailId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'ssoToken' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'enterpriseId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.reload(false)
            }
        }

        let auth_key = this.cookie('auth_token')
        this.setState({
            auth_key: auth_key
        })


        let enterprise_id = 'TaD1VC1Ko'
        axios({
            method:"GET",
            url:"https://www.clippr.ai/api/v2/backgrounds/fetchEnterpriseBgs/v2",
            params: {
                category : 'bikes',
                enterprise_id: enterprise_id,
            }
        })
        .then((res) => {
            this.setState({
                planData:res.data?.data
            })
        })

        let queries = queryString.parse(this.props.location.search)
        this.setState({productHunt : queries.ref})
        console.log(this.state.productHunt)
          

        var passing_location;
        // if ("geolocation" in navigator) {
        //   // check if geolocation is supported/enabled on current browser
        //   navigator.geolocation.getCurrentPosition(
        //    function success(position) {
    
        //     //  console.log('latitude', position.coords.latitude, 
        //     //              'longitude', position.coords.longitude);
        //     passing_location = [position.coords.latitude, position.coords.longitude];
        //     localStorage.setItem("passing_location", passing_location);
        //  },
        //   function error(error_message) {
        //     passing_location = 'NULL'
        //     localStorage.setItem("passing_location", passing_location);
        //   }  
        // )}
        //  else {
        //   passing_location = 'NULL'
        //   localStorage.setItem("passing_location", passing_location);
        // }

        passing_location = 'NULL'
        localStorage.setItem("passing_location", passing_location);
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        this.setState({
            emailId : userEmail
        })
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            if(isMobile){
                this.setState({
                    showInstruction:false
                })
            }else{
                this.setState({
                    showInstruction:true
                })
            }
            
        }
        if (this.cookie('auth_token')!="" && this.cookie('auth_token')){
            
            axios({
                'method':"GET",
                'url':`https://www.clippr.ai/api/v2/backgrounds/fav-ent-bgs-bikes?auth_key=${cookie('auth_token')}`
               

            })
            .then((res) => {
                
                this.setState({
                    fav_list:res?.data?.data?.fav_list,
                    bg_list:res?.data?.data?.spynelist
                })
            })
        }
        if (this.state.walldata.length==0 && this.cookie('auth_token')!="" && this.cookie('auth_token')){
            axios({
                'method':"GET",
                //'url':`https://www.clippr.ai/api/car-replacement-walls?api_key=${process.env.REACT_APP_API_KEY}&user_id=${cookie('userId')}`,
                'url':'https://www.clippr.ai/api/v2/backgrounds/custom-walls?auth_key='+auth_key+'&category=cat_d8R14zUNE',
                
            })
            .then((reswall) => {
                this.setState({
                    walldata:reswall?.data,
                    wall:reswall?.data[0]['wall_png_url']
                })
            })
        }

        let userId = this.cookie('userId')
        if(userId ){
            // fetchCreditUserDetails(userId).then((resp) => {
            //     this.setState({
            //         availableCredit:resp.data.data.creditAvailable,
            //         allotedCredit:resp.data.data.creditAlloted,
            //         creditUsed:resp.data.data.creditUsed
            //     })
            // } )

            let emailId = this.cookie("emailId")
            let auth_key = this.cookie("auth_token")
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v2/credit/fetch",
                params : {
                    // email_id : emailId,
                    auth_key : auth_key
                }
                
            }).then(resp => {
                // console.log(resp)
                if(resp.data.status == 200){
                    this.setState({
                        availableCredit: resp.data.data.credit_available,
                        creditAlloted : resp.data.data.credit_allotted,
                        creditUsed: resp.data.data.credit_used,
                        beforeCredits:resp.data.data.credit_available
                    })
                }else{
                }
            })
                this.setState({
                    userId: userId
                })
        }
        if(this.state.uploadStarteds === "true"){
            // this.getProcessedImages()
            this.getUserCredit()
        }

        try{axios({
            method: "GET",
            url : process.env.REACT_APP_BASEURL + "/v2/fetch_history/cars?auth_key="+auth_key+"&category=automobiles"
        })
        .then(resp => {
            if(resp.data.status==200){
            this.setState({
                history_data: resp.data.data,
            })
        }
        else{
         this.setState({   history_data_error:true})
        }
        })
    }catch (e){
        this.setState({
            history_data_error:true
        })
    }
    }

    // componentDidUpdate(prevProps,prevState) {
    //     console.log(prevState.filesList.length, this.state.filesList.length)
    //     if(prevState.filesList.length != this.state.filesList.length){
    //         sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
    //     }
    // }
    
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    

    handleCroppedImage = (imageBlob, dimensions) =>{
        
        if(imageBlob!=null){
        dimensions[0]=Math.floor(dimensions[0]+(this.state.cropperList[0][1])[0]);
        dimensions[1]=Math.floor(dimensions[1]+(this.state.cropperList[0][1])[1]);
        dimensions[2]=Math.floor(640-dimensions[2]-dimensions[0]);
        dimensions[3]=Math.floor(360-dimensions[3]-dimensions[1]);
        this.state.cropperList.unshift([imageBlob,dimensions])}
        else{
        this.state.cropperList.shift()}
        console.log(this.state.cropperList)
        if(this.state.cropperList.length>=2){
            this.setState({tempref:URL.createObjectURL(this.state.cropperList[0][0])
            })
            }
        else if(this.state.cropperList.length==1){
            this.setState({
                tempref:this.state.cropperList[0][0]
            })
        }
        
    }
      handelColorEnhancement = () =>{
        this.setState({
            checkColorEnhancement:!this.state.checkColorEnhancement,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handelBlurNumberPlate = () =>{
        this.setState({
            checkBlurNumberPlate:!this.state.checkBlurNumberPlate,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleWindowCorrenction = () =>{
        this.setState({
            checkWindowCorrenction:1-this.state.checkWindowCorrenction,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleWindowTransparency = () =>{
        this.setState({
            checkWindowTransparency:!this.state.checkWindowTransparency,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handelGreyShade = () =>{
        this.setState({
            checkGreyShade:!this.state.checkGreyShade,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleUnshiftWalls = (data) =>{
        this.state.walldata.unshift(data)
        this.setState({
            wall:data['low_res_wall_url']
        })
      }

    fileSelectedHandlerNew = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0]),
                backgroundEditedUrl:[],
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file),
                backgroundEditedUrl:[],
            },
            
            );
        }
        this.hitapiModal()
      };

      handleDownloadComplete = () => {
          this.setState({downloadComplete: false})
      }

      handleLogo = () => {
        this.setState({
            logoPosition : null,
            selectedLogoFile: null,
            logoUrl: ''
        })
      }

    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }

    handleAngle=(id)=>{
        let selectedAngle = this.cookie("selectedAngle")
        this.setState({
          car_bg_replacement_angle:id,
          selectedAngle:selectedAngle,
          imageSelected:true,
          isEditingFinish:false
        })
    }

    handleId=(id,price)=>{
        this.setState({
            background_id:id,
            bgCredit:price,
            // isEditingFinish:false,
            askEmail:false,
            toggleChange:false,
            toggleStatus:false,
        })
    }
     async cropperLink(){
            let auth_key = this.cookie('auth_token')
            const cd = new FormData()
            cd.append("auth_key",auth_key)
            cd.append("left",(this.state.cropperList[0][1])[0])
            cd.append("top",(this.state.cropperList[0][1])[1])
            cd.append("right",(this.state.cropperList[0][1])[2])
            cd.append("bottom",(this.state.cropperList[0][1])[3])
            cd.append("sku_id",this.state.sku_id)
            cd.append("project_id",this.state.project_id)
            cd.append("singleImage_Id",this.state.singleImage_Id)
            cd.append("image_url", this.state.urlDownload)
            let respon= await axios.post(`https://www.clippr.ai/api/v2/image/cropper`,cd)
                if(respon.status == 200){
                this.setState({ 
                    urlDownload:respon.data.original,
                    editedUrl:respon.data.display_image,
                    waterMarkImg:respon.data.watermark_image,
                    });
                    console.log(respon)
                }
        }
    updateCredit = async (e) => {  
           if(this.state.cropperList.length>1 && this.state.cropConst==true){
        await this.cropperLink()
        this.setState({
            cropConst:false
        })
           }
        // e.preventDefault()
        let userId = this.cookie('userId')
        let price 
        if(this.state.activateButton === 'Hd'){
            price = this.state.bgCredit
            axios({
                method: "GET",
                url : "https://www.clippr.ai/api/v4/download-history",
                params : {
                    user_id : userId,
                    enterprise_id : 'TaD1VC1Ko',
                    sku_id: this.state.sku_id
                }
                
            }).then( resp => {
                if(resp.data.status == 404){
                    let auth_key = this.cookie('auth_token')
                    const fd = new FormData()
                    fd.append("auth_key", auth_key)
                    fd.append("credit_reduce",price)
                    fd.append("sku_id", this.state.sku_id)
                    fd.append("image_id",this.state.imageId)
                    fd.append("source",'SINGLEPROCESS')
                    axios.put(`https://www.clippr.ai/api/v2/credit/reduce-user-credit`,fd)
                    // updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
                    .then( (res) => {
                        this.setState({
                            availableCredit: this.state.availableCredit - (price+this.state.chargedFeature),
                            // downloadComplete: true
                        })
                        setTimeout(() => {
                            this.setState({downloadComplete: true})
                        },5000)

                        // if(this.state.cropperList.length>1){
                        //     const cd = new FormData()
                        //     cd.append("auth_key","fde46c58-5735-4fcf-8b38-980c95001dc3")
                        //     cd.append("left",(this.state.cropperList[0][1])[0])
                        //     cd.append("top",(this.state.cropperList[0][1])[1])
                        //     cd.append("right",(this.state.cropperList[0][1])[2])
                        //     cd.append("bottom",(this.state.cropperList[0][1])[3])
                        //     cd.append("sku_id",this.state.sku_id)
                        //     cd.append("project_id",this.state.project_id)
                        //     cd.append("singleImage_Id",this.state.singleImage_Id)
                        //     cd.append("image_url", this.state.urlDownload)
                            
                        //     let respon= await axios.post(`https://www.clippr.ai/api/v2/image/cropper`,cd)
                        //     // .then(respon =>{
                        //         if(respon.status == 200){
                        //         this.setState({ 
                        //             urlDownload:respon.data.original,
                        //             editedUrl:respon.data.display_image,
            
                        //             });
                        //             console.log(respon)
                        //         }
                        //     // }
                        //     // )
                        // }
                       
                        const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            // var extension =output_link.split('.').reverse()
                            // extension=extension[0]

                            // var filename = win+'.' +extension

                            // if (image_name=='null'){
                            //     var filename = name.substr(index)
                            // }
                            var filename=window.sessionStorage.getItem("image_name")
                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                    
                        const fd = new FormData()
                        fd.append("user_id", userId)
                        fd.append("sku_id",this.state.sku_id)
                        fd.append("enterprise_id" , 'TaD1VC1Ko')
                        fd.append("download_hd","true")
                        axios.post(`https://www.clippr.ai/api/v4/update-download-status`,fd)
                        .then(resp => {
                            console.log(resp)
                        })
                        
                    })
                }else{
                    const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                        fetch(output_link, {
                        method: "GET",
                        headers: {}
                        })
                        .then(response => {
                            response.arrayBuffer().then(function(buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            let name = output_link
                            var index = name.lastIndexOf("/") + 1;
                            var filename = name.substr(index)
                            var filename=window.sessionStorage.getItem("image_name")

                            link.setAttribute("download", filename); 
                            document.body.appendChild(link);
                            link.click();
                            
                            });
                            
                        })
                }
            })
        }else{
            price = 0
            const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
            fetch(output_link, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                let name = output_link
                var index = name.lastIndexOf("/") + 1;
                var filename = name.substr(index)
                var filename=window.sessionStorage.getItem("image_name")

                link.setAttribute("download", filename); 
                document.body.appendChild(link);
                link.click();
                
                });
                
            })
        }
   
    }
    askEmail =() => {
        this.setState({
            askEmail:true
        })
    }

    // downloadPopup = () => {
    //     this.setState({isEditingFinish : false})
    // }

    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }

    

    downloadPopup = () => {
        this.setState({isDownload: false})
    }

    
    handleBgList=(i) =>{
        console.log(this.state.bg_list[i]['image_id'])
        const fd = new FormData()
        let auth_key = this.cookie('auth_token')
        fd.append("auth_key", auth_key)
        // fd.append("auth_key", "9569c6d5-52ea-4dcd-ba79-7ca4fde329e7")
        fd.append("background_id",this.state.bg_list[i]['image_id'])
        fd.append("favourable","true")
        fd.append("primary","false")
        axios({
            'method':"POST",
            'url':'https://www.clippr.ai/api/v2/backgrounds/updatefavbg',
            "data":fd
        })
        .then((res) => {
            console.log(res.data.status)
            if (res.data.status==200){
                let sliced=this.state.bg_list.splice(i,1)
                this.state.fav_list.unshift(sliced[0])
                this.setState({})
            }
            // console.log("bglist splice")
            
        })
        
    }
    handlefavList=(i) =>{

        console.log(this.state.fav_list[i]['image_id'])
        const fd = new FormData()
        let auth_key = this.cookie('auth_token')
        fd.append("auth_key", auth_key)
        // fd.append("auth_key", "9569c6d5-52ea-4dcd-ba79-7ca4fde329e7")
        fd.append("background_id",this.state.fav_list[i]['image_id'])
        fd.append("favourable","false")
        fd.append("primary","false")
        axios({
            'method':"POST",
            'url':'https://www.clippr.ai/api/v2/backgrounds/updatefavbg',
            "data":fd
        })
        .then((res) => {
            console.log(res.data.status)
            if (res.data.status==200){
                let sliced=this.state.fav_list.splice(i,1)
                this.state.bg_list.unshift(sliced[0])
                this.setState({})
            }
        })
    }

    hitapi = () => {
        this.setState({
          spinner: true,
          hideLogo:true,
        });
        if(this.state.filesList[0].file !== null){
            const fd = new FormData();
            fd.append("image", this.state.filesList[0].file);
            fd.append("optimization",false)
            let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
            axios.post(`https://www.clippr.ai/api/upload?api_key=${api_key}`,fd,{params:{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"}})
            .then((uploadedResponse) => {
                const replacementFd = new FormData()
                let timestamp= new Date().getTime();
                timestamp.toString();
                let sku_id="sku"+timestamp
                replacementFd.append("user_id", this.cookie("userId"));
                replacementFd.append("sku_id", sku_id);
                replacementFd.append("background", this.state.background_id);
                replacementFd.append("source","Web");
                replacementFd.append("image_url",uploadedResponse.data.image);
                replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
                replacementFd.append("contrast",this.state.checkColorEnhancement);
                
                if(this.state.tabSelection=="custom_walls"){
                    replacementFd.append("appyType","CW")
                    replacementFd.append("custom_wall",this.state.customWall)
                    if(this.state.customWallPosition>0){
                        replacementFd.append("height",this.state.customWallPosition)
                    }
                    else{
                        replacementFd.append("height","mid")
                    }
                }
                else {
                    
                    replacementFd.append("wall_url","0");
                    replacementFd.append("appyType","VS")
                    replacementFd.append("custom_wall","null")
                    replacementFd.append("height","null")
                }

                if(this.state.selectedLogoFile !== null){
                    replacementFd.append("logo",this.state.selectedLogoFile);
                    replacementFd.append("logo-position",this.state.logoPosition)
                }
                axios
                .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
                .then((res) => {
                    let userEmail = this.cookie("emailId")
                    let _userId_ = this.cookie("userId")
                    let isValidated = this.cookie("isValidated")
                    if(!userEmail){
                        clearInterval(x)
                        var x = setTimeout(this.askEmail, 3000);
                    }
                    let temp = res;
                    if (temp.data['url']) {

                        if (userEmail){       

                            temp.data['authenticated_email_id'] = userEmail;
                            temp.data['user_id'] = _userId_;
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            if (isValidated=='true'){
                                temp.data['otp_verified'] = 'YES';
                            }
                            else {
                                temp.data['otp_verified'] = 'NO';
                            }
                            if (window.localStorage.getItem("clippr_user_email") != null){
                                temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            }
                            else{
                                temp.data['free_trail_email'] = 'NULL';
                            }
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                        }
            
                        else if (window.localStorage.getItem("clippr_user_email") != null) {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }
                        else {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['authenticated_email_id'] = 'NULL';
                            temp.data['free_trail_email'] = 'NULL';
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }

                    }
                   this.state.cropperList.unshift([res.data.display_image,[0,0,0,0]])
                    this.setState({ 
                        url:res.data.display_image,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true,
                        waterMarkImg:res.data.watermark_image,
                        isDownload: true,
                        urlDownload: res.data.output_image, 
                        })
                  
                    
                    window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                    window.sessionStorage.setItem("lastEditedImage", res.data.output_image)
                    window.sessionStorage.setItem("lastRawImage",uploadedResponse.data.image)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch((uploadFail) => {
                this.setState({
                    spinner:false
                })
            })
        }
        else{
            const replacementFd = new FormData()
            // const replacementFd = new FormData()
            let timestamp= new Date().getTime();
            timestamp.toString();
            let sku_id="sku"+timestamp
            replacementFd.append("user_id", this.cookie("userId"));
            replacementFd.append("sku_id", sku_id);
            replacementFd.append("background", this.state.background_id);
            replacementFd.append("source","Web");
            replacementFd.append("image_url",this.state.filesList[0].url);
            replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
            replacementFd.append("contrast",this.state.checkColorEnhancement);
            if(this.state.tabSelection=="custom_walls"){
                replacementFd.append("appyType","CW")
                replacementFd.append("custom_wall",this.state.customWall)
                if(this.state.customWallPosition>0){
                    replacementFd.append("height",this.state.customWallPosition)
                }
                else{
                    replacementFd.append("height","mid")
                }
            }
            else {
                
                replacementFd.append("wall_url","0");
                replacementFd.append("appyType","VS")
                replacementFd.append("custom_wall","null")
                replacementFd.append("height","null")
            }

            if(this.state.selectedLogoFile !== null){
                replacementFd.append("logo",this.state.selectedLogoFile);
                replacementFd.append("logo-position",this.state.logoPosition)
            }
            axios
            .post("https://www.clippr.ai/api/v6/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
            .then((res) => {
                // console.log(res.data.output_image)
                this.setState({ 
                    url:res.data.display_image,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:res.data.watermark_image,
                    isDownload: true,
                    urlDownload: res.data.output_image
                    });
                  
                // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
                // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                window.sessionStorage.setItem("lastEditedImage", res.data.display_image)
                window.sessionStorage.setItem("lastRawImagsuve", this.state.orgUrl)
            })
            .catch((err) => {
                this.setState({
                    spinner:false
                })
            })
        }
    };

    handleChargedFeature = async () => {
        await this.setState({
            chargedFeature : this.state.chargedFeature + 1,
            numberPlateChargesApplied : true
        })
    }
    handelCarShadow = () => {
        this.setState({
          carShadow:!this.state.carShadow
        })
    }
    dealerLogoFileHandler = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
        fileInput = true;
        }
        if (fileInput) {
        try {
            Resizer.imageFileResizer(
            event.target.files[0],
            200,
            120,
            "JPG",
            100,
            0,
            (uri) => {
                // console.log(uri)
                this.setState(
                    { 
                        selectedLogoFile:uri,
                        // logoUrl:  uri,
                        // logoPosition:"leftTop",
                        // hideLogo:false
                    },
                    );
                
            },
            "blob",
            
            );
        } catch (err) {
            console.log(err);
            }
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
        this.setState(
            {
                logoUrl: [reader.result],
                logoPosition:"leftTop",
                hideLogo:false,
                showlogoCropModal: !this.state.showlogoCropModal
            },
        );
        }.bind(this);
        this.setState(
        { 
            // selectedLogoFile:event.target.files[0],
            logoUrl:  URL.createObjectURL(event.target.files[0])
        },
        );
    };

    handleLogoPosition = (e) => {
        console.log(e.target.name)
        this.setState({
          logoPosition:e.target.name,
          hideLogo:false
        })
    }
    handleInstruction = (e) => {
        // e.preventDefault()
        this.setState({
            showInstructionModal:e.target.checked
        })
        window.localStorage.setItem("showInstructionModal", e.target.checked)
    }
    handleInstructionModal = () => {
        this.setState({
            showInstruction:true
        })
    }
    handlePositionChange=(pos)=>{
        this.setState({
            customWallPosition:pos
        })
    }
    handleTabSelection=(selection)=>{
        this.setState({
            tabSelection:selection
        })
    }
    customWallSet= (wall_selected) => {
        this.setState({
            customWall: wall_selected
        })
      }
    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }
    handleActivButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
      }
      dragLeave = (e) => {
        e.preventDefault()
        this.state.dropBackground = false
      }

    //   onDrop(files) {
    //     console.log('Received files: ', files);
    //     files.map(file => {
    //        return this.setState({
    //              filesList: file
    //         })
    //     })
    //     console.log(this.state.filesList)
    // }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false,
            dropBackground: false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            
            );
        }
        // this.hitapiModal()
      };

    multipleFileUpload = (event) =>{
        // console.log(event.target.files)
        // if(this.state.filesList.length < 36){

            this.state.orgUrl = ''
            this.state.editedUrl = ''
            this.state.spinner = false
            this.state.toggleChange = false
            this.state.toggleStatus = false
            this.state.isEditingFinish = false
            window.sessionStorage.removeItem('lastEditedImage')
            window.sessionStorage.removeItem('lastRawImage')
            if(this.state.project_name === ''){
                this.setState({
                    showProjectNameModal:true,
                    batchFiles:[]
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
        // }else{
        //     this.setState({
        //         maxUpload: true
        //     })
        // }
        // console.log(this.state.filesList)
        // console.log(this.state.setFiles)
    }

    multipleFileUploadProject = (event) =>{
        // console.log(event.target.files)
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.state.uploadStarteds = false
        this.state.project_name = ''
        this.state.sku_name = ''
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.orgUrl = ''
        this.state.editedUrl = ''
        this.state.spinner = false
        this.state.toggleChange = false
        this.state.toggleStatus = false
        this.state.isEditingFinish = false
        this.state.chargedFeature = 0
        this.state.selectedLogoFile = null
        this.state.checkBlurNumberPlate = false
        this.state.numberPlateChargesApplied = false
        this.state.logoUrl = ''
        this.setState({
            tabSelection: 'virtual_studio',
            imgId_for_retouch:[],
            batchFiles:[]
        })
        // window.sessionStorage.removeItem('filesList')
        // window.sessionStorage.removeItem('uploadStarteds')
        // window.sessionStorage.removeItem('downloadZipLink')
        sessionStorage.clear()

        if(this.state.project_name === ''){


                    this.setState({
                    showProjectNameModal:true
                })
            }
            
            for(let i=0; i<event.length ; i++){
                if(this.state.filesList.length < 36){
                    if(event.target){
                        //If user is uploading image with choose Image Button
                        let file = {
                            file: event.target.files[i],
                            name: event.target.files[i].name,
                            size: event.target.files[i].size,
                            type: event.target.files[i].type,
                            lastModified: event.target.files[i].lastModified,
                            lastModifiedDate: event.target.files[i].lastModifiedDate,
                            url: URL.createObjectURL(event.target.files[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        );
                    }else{
                        let file = {
                            file: event[i],
                            name: event[i].name,
                            size: event[i].size,
                            type: event[i].type,
                            lastModified: event[i].lastModified,
                            lastModifiedDate: event[i].lastModifiedDate,
                            url: URL.createObjectURL(event[i]),
                            status: 'Uploading',
                            imageId: null,
                            outputImgUrl: null,
                            projectId: null,
                            skuId:null,
                            fullScreenOutput:null,
                        }
                        // let file = event[i]
                        if (Math.round(file.size / 1024) > 30720) {
                            return;
                        }
                        this.setState(
                        {
                            filesList: [...this.state.filesList,file],
                            setFiles: [...this.state.setFiles,file.url]
                        },
                        
                        );
                    }
                    if(this.state.filesList.length===1)
                    {
                        this.setState({
                            isBulk:false
                        })
                    }
                    else{
                        this.setState({
                            isBulk:true
                        })
                    }
                }
                else{
                    this.setState({
                        maxUpload: true
                    })
                }
                
                
            }
        // console.log(this.state.filesList)
        // console.log(this.state.setFiles)
    }

    creditVerify = () => {
        
        let userId = this.cookie('userId')
        let price = (1+this.state.chargedFeature)*this.state.filesList.length
        if(this.state.availableCredit >= price){
           
            
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:false,
                zeroCreditModal:false,
                enoughCreditModal:true,
                
                // uploadStarteds:true,
            })
            
        }else if(this.state.availableCredit == 0){
            this.setState({
                showBulkCreditModal: true,
                zeroCreditModal:true,
                // uploadStarteds:true,
            })
        }else if(this.state.availableCredit < price){
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal:true,
                filesList : this.state.filesList.slice(0,this.state.availableCredit)

            })
        }
        // console.log(this.state.filesList)
    }

    hitUploadAPI = async () => {

        let price = this.state.filesList.length

        if(this.state.availableCredit >= price){
            this.getUserCredit()
            await this.setState({
                uploadStarteds:true,
                showBulkCreditModal:false,
                enoughCreditModal: false,
                batchFiles:chunkArrayWhiteoutCorrection(this.state.filesList, 4)

                
            })

            

            for(let batchIndex=0; batchIndex < this.state.batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(this.state.batchFiles[batchIndex].map(async (file, currIndex) => {
                        if(batchIndex==0)
                        {
                            let i = 1*batchIndex + currIndex
                            return await this.bulkUploadAPI(i)
                        }
                        else{
                            let i = 4*batchIndex -3 + currIndex
                            return await this.bulkUploadAPI(i)
                        }
                    }))
                } catch (err) {
                    alertify.error("Something Went Wrong! please try again.") 
                }
            }
            this.setState({
                isZipReady: true
            })

            this.updateUserTransactions()
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i< this.state.filesList.length;i++){
            //     this.bulkUploadAPI(i)
            // }
            sessionStorage.setItem("uploadStarteds", true);
            
        }else if(this.state.availableCredit < price){
            // const filesListNew = this.state.filesList.slice(0,this.state.availableCredit)
            // this.forceUpdate()

            this.setState((prevState) => {
                return {
                    uploadStarteds:true,
                    showBulkCreditModal:false,
                    notEnoughCreditModal: false,
                    // filesList: prevState.filesList.slice(0,this.state.availableCredit)
                }
            }, () => {
                console.log(this.state.filesList.length)
                sessionStorage.setItem("uploadStarteds", true);
            })

            this.setState({
                batchFiles:chunkArrayWhiteoutCorrection(this.state.filesList, 4)
            })
            for(let batchIndex=0; batchIndex < this.state.batchFiles.length; batchIndex++){

                try {
                    const resp = await Promise.all(this.state.batchFiles[batchIndex].map(async (file, currIndex) => {
                        if(batchIndex==0)
                        {
                            let i = 1*batchIndex + currIndex
                            return await this.bulkUploadAPI(i)
                        }
                        else{
                            let i = 4*batchIndex -3 + currIndex
                            return await this.bulkUploadAPI(i)
                        }
                    }))
                } catch (err) {
                    alertify.error("Something Went Wrong! please try again.") 
                }
            }
            this.setState({
                isZipReady: true
            })

            this.updateUserTransactions()
            
            sessionStorage.setItem("isZipReady", true);

            // for(let i=0; i<this.state.availableCredit;i++){
            //     this.bulkUploadAPI(i)
            // }

            let arr = this.state.filesList.slice(0, this.state.availableCredit)
            this.setState({
                filesList : [...arr]
            }, () => {
                console.log(this.state.filesList.length)
            })
            
             
        }
        

    }

    singleUploadAPI = (i) => {
        this.setState({
            spinner: true,
            hideLogo:true,
            cropperList:[],
            cropConst:true
        });
        
        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("image", this.state.filesList[0].file);
        fd.append("optimization",false)
        fd.append("background_id", this.state.background_id);
        fd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
        fd.append("is_color",this.state.checkColorEnhancement);
        fd.append("number_plate_blur",this.state.checkBlurNumberPlate);
        fd.append("window_correction",this.state.checkWindowCorrenction)
        fd.append("prod_cat_id", "cat_d8R14zUNx")
        fd.append("auth_key", auth_key)
        //fd.append("auth_key", '9569c6d5-52ea-4dcd-ba79-7ca4fde329e7')
        fd.append("prod_sub_cat_id", "Sedan")
        // fd.append("sku_name", this.state.sku_name)
        // fd.append("project_name", this.state.project_name)
        fd.append("enterprise_id", 'TaD1VC1Ko')
        fd.append('processingType', 'REALTIME')
        fd.append('source', 'SINGLEPROCESS')
        fd.append('frame_seq_no',1)
        if(this.state.tabSelection=="custom_walls"){

            fd.append("appyType","CW")
            fd.append('grey_scale',this.state.checkGreyShade)
            fd.append("custom_wall",this.state.customWall)
            if(this.state.customWallPosition>0){
                fd.append("height",this.state.customWallPosition)
            }
            else{
                fd.append("height","mid")
            }
        }
        else {
            
            fd.append("wall_url","0");
            fd.append("appyType","VS")
            fd.append("custom_wall","null")
            fd.append("height","null")
        }

        if(this.state.selectedLogoFile !== null){
            fd.append("logo_number_plate",this.state.selectedLogoFile);
            // fd.append("logo-position",this.state.logoPosition)
        }

        axios.post(`https://www.clippr.ai/api/v2/image/transform-bike`,fd)
        .then((resp) => {
            if(resp.status == 200){
                this.state.cropperList.unshift([resp.data.output_image_lres_url,[0,0,0,0]])
                this.setState({ 
                    url:resp.data.output_image_lres_url,
                    orgImgShow: true,
                    processing: false,
                    loader: false,
                    editedState: true,
                    uploadPercentage: 0,
                    tabIndex: 1,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    isEditingFinish:true,
                    waterMarkImg:resp.data.watermark_image,
                    isDownload: true,
                    urlDownload: resp.data.output_image_hres_url,
                    project_id: resp.data.project_id,
                    sku_id: resp.data.sku_id,
                    singleImage_Id:resp.data.image_id,
                    tempref:this.state.cropperList[0][0],
                    imageId:resp.data.image_id
                    });
                
                window.localStorage.setItem("car-replacement-original-url", resp.data.input_image_hres_url)
                window.localStorage.setItem("car-replacement-edited-url",resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastEditedImage", resp.data.output_image_lres_url)
                window.sessionStorage.setItem("lastRawImage",resp.data.input_image_hres_url)
                window.sessionStorage.setItem("image_name",resp.data.image_name)
            }
        })

    }

    bulkUploadAPI = async (i) => {
        let auth_key = this.cookie('auth_token')
        const fd = new FormData();
        fd.append("image", this.state.filesList[i].file)
        fd.append("prod_cat_id", "cat_d8R14zUNE")
        fd.append("auth_key", auth_key)
        //fd.append("auth_key", '9569c6d5-52ea-4dcd-ba79-7ca4fde329e7')
        fd.append("prod_sub_cat_id", "Sedan")
        fd.append("sku_name", this.state.sku_name)
        fd.append("project_name", this.state.project_name)
        fd.append("background_id", this.state.background_id)
        fd.append("number_plate_blur",this.state.checkBlurNumberPlate);
        fd.append("window_correction",this.state.checkWindowCorrenction)
        fd.append("is_color",this.state.checkColorEnhancement);
        fd.append("enterprise_id", 'TaD1VC1Ko')
        fd.append('processingType', 'REALTIME')
        fd.append('source', 'BULKPROCESS')
        fd.append('frame_seq_no',i)
        if(this.state.selectedLogoFile !== null){
            fd.append("logo_number_plate",this.state.selectedLogoFile);
        }
        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesList: [
                ...this.state.filesList.slice(0, i),
                Object.assign({}, this.state.filesList[i], { status: 'Uploading' }),
                ...this.state.filesList.slice(i+1)
            ]
        })
        // return axios.post(`${process.env.REACT_APP_BASEURL}/algo/image/transform`,fd)
        return axios.post(`https://www.clippr.ai/api/v2/image/transform-bike`,fd)
        .then((uploadResponse) => {
            if(uploadResponse.status == 200){
                if(uploadResponse.data.project_name==this.state.project_name){
                // this.setState({
                //     orgUrlList: [...this.state.orgUrlList,uploadResponse.data.input_image_hres_url]
                // })
                this.setState({
                    filesList: [
                        ...this.state.filesList.slice(0, i),
                        Object.assign({}, this.state.filesList[i], { 
                            status: 'Done', 
                            url: uploadResponse.data.input_image_hres_url,
                            imageId: uploadResponse.data.image_id, 
                            projectId: uploadResponse.data.project_id,
                            skuId: uploadResponse.data.sku_id,
                            outputImgUrl: uploadResponse.data.output_image_lres_url,
                            fullScreenOutput:uploadResponse.data.watermark_image,
                        }),
                        ...this.state.filesList.slice(i+1)
                    ]
                })

            }
            }else if(uploadResponse.data.status == 400){
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.clear()
                window.location.reload()
            }
            sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
            return Promise.resolve(uploadResponse)
        })
        .catch((err) => {
            this.setState({
                errorBulkFiles: [...this.state.errorBulkFiles,Object.assign({}, this.state.filesList[i], { 
                    status: 'Error', 
                })],
                filesList: [
                    ...this.state.filesList.slice(0, i),
                    Object.assign({}, this.state.filesList[i], { 
                        status: 'Error', 
                        
                    }),
                    ...this.state.filesList.slice(i+1)
                ]
            })
            return Promise.reject(err)

        })
        
    }

    handleUploadLimitModal = () => {
        this.setState({
            maxUpload: false
        })
    }

    emptyMultipleFileArray = () => {
        this.state.filesList.length = 0
        this.state.setFiles.length = 0
        this.setState({
            maxUpload: false
        })
    }
    updateMultipleImageList = (i) => {
        this.state.filesList.splice(i,1) 
        this.state.setFiles.splice(i,1)
        // console.log(this.state.filesList)
       this.forceUpdate()
    }

    handleBulkCreditModal = () => {
        this.setState({
            showBulkCreditModal: false
        })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        const value = e.target.value;
        const regex = /^[0-9a-zA-Z]+$/; //this will admit letters & numbers 
        if (value.match(regex) || value === "") {
            this.setState({
                project_name: value,
                sku_name:value,
                projectNameExists : false
            })
        }

        sessionStorage.setItem("project_name", this.state.project_name);
        sessionStorage.setItem("sku_name", this.state.sku_name);
    
    }
    handleProjectNameModal =()=>{
        let auth_key = this.cookie("auth_token")
        let user_id = this.cookie("userId")
        const fd = new FormData()
        fd.append("auth_key", auth_key);
        fd.append("user_id", user_id);
        fd.append("enterprise_id", "TaD1VC1Ko");
        fd.append("project_name",this.state.project_name)
        axios.post(`https://www.clippr.ai/api/v2/project/verify-project`,fd)
        .then((resp) => {
            if(resp.data.status == 404){
                this.setState({
                    showProjectNameModal: false,
                    projectNameExists: false
                })
            }else if(resp.data.status == 200){
                this.setState({
                    projectNameExists: true
                })
            }
            // console.log(resp)
        })
        
    }

    getProcessedImages = async () => {
        let auth_key = this.cookie('auth_token')
        if(this.state.availableCredit >= this.state.filesList.length){
            for(let i=0; i< this.state.filesList.length; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
            
        }else if(this.state.availableCredit < this.state.filesList.length){
            for(let i=0; i< this.state.availableCredit; i++){
            
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/image/get-image",
                            // data : fd
                            params : {
                                image_id : this.state.filesList[i].imageId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status === 200){
                            if(resp.data.data.status === 'Done'){
                                return true
                            }
                            
                        }else{
                            return false
                        }
                    },
                    interval: 5000,
                })
                
                if(resp.data != null){
                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], { status: 'Done', outputImgUrl: resp.data.data.output_image_hres_url}),
                            ...this.state.filesList.slice(i+1)
                        ]
                    })
                }
            }
        }
        this.setState({
            isZipReady: true
        })
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
        sessionStorage.setItem("isZipReady", true);
        this.props.history.push('/tech/cars/try')

    }

    handleRefundModal = () => {
        this.setState({
            showRefundModal: false
        })
        this.props.history.push('/tech/cars/try')
        if (this.state.filesList.length==0){
            this.state.filesList.length = 0
            this.state.setFiles.length = 0
            this.state.uploadStarteds = false
            this.state.project_name = ''
            this.state.sku_name = ''
            this.state.isZipReady = false
            this.state.bulkDownloadDone = false
            this.state.orgUrl = ''
            this.state.editedUrl = ''
            this.state.spinner = false
            this.state.toggleChange = false
            this.state.toggleStatus = false
            this.state.isEditingFinish = false
            this.setState({
                tabSelection: 'virtual_studio'
            })
            sessionStorage.clear()
        }
    }

    handleRetouchImages = async (e) => {
            let retouch_img=[e,this.state.filesList[e].imageId]
            var found=0
            for(let i=0;i<this.state.imgId_for_retouch.length;i++)
            {
                if(e==this.state.imgId_for_retouch[i][0]){
                    this.state.imgId_for_retouch.splice(i, 1);
                    this.setState({
                        imgId_for_retouch: this.state.imgId_for_retouch
                    })
                    found=1
                }
            }
        if(found==0){
            await this.setState({ imgId_for_retouch: [...this.state.imgId_for_retouch,retouch_img ]});
        }
        console.log(this.state.imgId_for_retouch)
    }

    requestRetouch = async() => {
        let e = this.state.imgId_for_retouch
        for(let i=0;i<e.length;i++){
            this.state.filesList.splice(e[i][0],1)
            await this.setState({ img_id: [...this.state.img_id,e[i][1]]});
            sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));

        }
     
    this.updateCredits(e.length)
       console.log(this.state.img_id)
        const fd = new FormData()
        fd.append('manual_retouch',this.state.img_id)

        axios({
            method: "PUT",
            url : `${process.env.REACT_APP_BASEURL}/v2/manual_retouch/upload`,
            data: fd
        }).then( (resp)=>{
            if(resp.status==200){
                this.setState({
                    imgId_for_retouch:[]
                })

            }
            alertify.success('Images sent for Manual Retouch Successfully')

        }).catch((e)=>{
            alertify.alert('Something Went Wrong! Plese log-out and log-in again')
        })

    }
    updateCredits = (e) => {
        let auth_key = this.cookie('auth_token')
        const fd = new FormData()
        fd.append("auth_key", auth_key)
        fd.append("credit_reduce",e)
        fd.append("sku_id", this.state.sku_id)
        fd.append("image_id",'')
        fd.append("source",'Web')
        axios.put(`https://www.clippr.ai/api/v2/credit/reduce-user-credit`,fd)
        .then((resp) => {
            console.log(resp)
        })
    }

    handleUnchecked = () =>{
        this.setState({
            imgId_for_retouch:[]
        })
        console.log(this.state.imgId_for_retouch)
    }

    getRefundModal = (i) => {
        this.setState({
            refundImgUrl: this.state.filesList[i].outputImgUrl,
            refundOrgUrl: this.state.filesList[i].url,
            showRefundModal: true,
            refundImgId:this.state.filesList[i].imageId,
            refundSkuId:this.state.filesList[i].skuId,
            refundNum: i
        })
    }
    updateEditedImageList = () => {
        this.state.filesList.splice(this.state.refundNum,1)
        sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
    }

    downloadImages = () => {
        this.setState({
            spinnerDownload: true
        })
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/download/sku",
            params : {
                sku_id : this.state.filesList[0].skuId,
                auth_key : this.cookie("auth_token"),
            }
        })
        .then(resp => {
            this.setState({
                downloadZipLink: resp?.data?.data?.zip_file_url
            })
            const a = document.createElement("a")
            a.href = resp?.data?.data?.zip_file_url
            a.download = true
            a.click()
            sessionStorage.setItem("downloadZipLink" , resp?.data?.data?.zip_file_url)
            this.setState({
                bulkDownloadDone: true,
                spinnerDownload: false
            })
            sessionStorage.setItem("bulkDownloadDone" , true)
        })
    }

    getUserCredit = async () => {
        let emailId = this.cookie("emailId")
        // if(){
        let auth_key = this.cookie("auth_token") 
                const resp = await poll({
                    fn: () => {
                        
                        return axios({
                            method: "GET",
                            url : "https://www.clippr.ai/api/v2/credit/fetch",
                            // data : fd
                            params : {
                                // email_id : emailId,
                                auth_key : auth_key
                            }
                            
                        })
                    },
                    validate: (resp) => {
                        if(resp.data.status == 200){
                            if(this.state.bulkDownloadDone == true){
                                return true
                            }else{
                                this.setState({
                                    availableCredit: resp.data.data.credit_available,
                                    allotedCredit : resp.data.data.credit_allotted,
                                    creditUsed: resp.data.data.credit_used
                                })
                                return false
                            }
                            
                        }else if(resp.data.status == 400){
                            document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            window.sessionStorage.clear()
                            window.location.reload()
                        }
                        
                        
                    },
                    interval: 5000,
                })
    }

    updateUserTransactions = () => {
        let auth_key = this.cookie("auth_token")
        const fd = new FormData()
        let available_credits_after = this.state.beforeCredits - this.state.filesList.length
        fd.append("auth_key", auth_key);
        fd.append("sku_id", this.state.filesList[0].skuId);
        fd.append("processing_source", "BULKPROCESS");
        fd.append("available_credits_before",this.state.beforeCredits)
        fd.append("image_count",this.state.filesList.length)
        fd.append("available_credits_after",available_credits_after)
        axios.post(`https://www.clippr.ai/api/v2/transactions/insert`,fd)
        .then(resp => {
            // if(resp.data.status == 200){
            //     console.log(resp)
            // }
        })
    }

    handleCroppedLogoBlob = (blob,croppedImageUrl) => {
        this.setState({
            croppedLogo:blob,
            croppedImageUrl: croppedImageUrl
        })
    }

    handleCropModal = () =>{
        this.setState({
            showlogoCropModal:false
        })
    }

    saveCroppedImage = () => {
        const objectURL = URL.createObjectURL(this.state.croppedLogo)
        this.setState({
            selectedLogoFile:this.state.croppedLogo,
            logoUrl:objectURL,
            showlogoCropModal:false
        })
    }

    handleRadioLogo = (event) => {
        console.log(event.target.value);
        if(this.state.numberPlateChargesApplied==false){
            this.handleChargedFeature();
        }
        if(event.target.value == 'blur'){
            this.setState({
                checkBlurNumberPlate: true,
                checkUploadLogo: false,
                selectedLogoFile: null,
                logoUrl: ''
            })
        }else{
            this.setState({
                checkUploadLogo: true,
                checkBlurNumberPlate: false
            })
            
        }
    }

    videoThreeSixty = () =>{
        this.setState({
             viewThreeSixty:!this.state.viewThreeSixty
         })
     }

    
      
     render() {
        return (
            <div >
                <div className={styles['bg-try-mob']}>
                    <div className="container text-center">
                        <h4>Welcome to <span style={{color:"#ff7700"}}>Spyne</span> </h4>
                        <h6>We are currently only available on <br /><span style={{fontWeight:"600"}}>Web/Desktop</span></h6>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/mob-gif.gif"/>
                        <p>Please switch from Mobile to Web</p>
                    </div>
                </div>
            <div className={styles['bg-try']}>
            <Helmet>
                <title>Change Bike Background Instantly | AI Bike Photo Editor.Try Now</title>
                <meta property="og:title" content="Change Bike Background Instantly | AI Bike Photo Editor.Try Now"/>
                <meta property="og:description" content="Replace Your Bike Images With Studio-Setting Backgrounds in Just 3 Seconds With Spyne AI Bike Photo Editor. Instantly Remove Any Bike Image Background and Give Your Bike Image a New Look. 100% Automated Editing in Just a Click. Simply Drag And Drop Your Photo(s) And See How The AI Transforms Your Photo(s) In Seconds"/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
                
                
            </Helmet>
            {this.state.spinnerDownload ? 
                <Spinner />
                :
                null
            }
            
            {
            this.state.filesList.length == 0 ?
            <>  
            <Drawer/>
                {
                this.state.showInstruction?
                 <LandingModal
                      showInstruction={this.state.showInstruction}
                      hideModal = {this.hideLandingModal}
                      productHunt={this.state.productHunt}
                      prodCatId={this.state.prodCatId}
                    />
                    :null
                }
                {!this.state.viewThreeSixty ?
                    <>
                    <Dropzone  onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                        {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })} className={styles['dropzone1']}>
                                    {this.state.dropBackground ?
                                        <section className={[styles['drop-box-text']]}>
                                        <div>Drop Image Anywhere</div>  
                                        
                                        </section>
                                        : null
                                    }
                                    
                                    <div className={styles['header-section']}>
                                        <p className={styles['heading-text']}>
                                            Bike Background Replacement <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png"/> Available Credits: <b>{this.state.availableCredit}</b></span></p>
                                        <p className={styles['heading-para']}>Boost Online Bike Sales in a single click with <span>AI-Generated</span> Backgrounds</p>
                                    </div>
                                    <div className={[styles['car-main-wrapper'],""].join(" ")} id="cars-try-modal">
                                        <div className={[styles[''],"container"].join(" ")}>
                                            
                                            <div className={[styles['car-try-wrapper'],'row'].join(' ')}> 
                                                <div className={[styles['car-left-section'],'col-md-10 offset-md-1'].join(' ')}>
                                                            
                                                            <div className={[styles['car-mid-section'],''].join(' ')}>
                                                                {/* <button id="upload_widget" className={[styles['dropzone'],"cloudinary-button"].join(' ')} onClick={() => myWidget.open()} >Upload files</button> */}
                                                                <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} >
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                                <p className={styles["para-11"]} style={{"fontSize" : "20px"}}>DROP IMAGE HERE </p>
                                                                                <p class={styles["para-2"]}>or <span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                                                                <p className={styles['supports1']}>Supports jpeg, jpg, bmp & png</p>
                                                                                <input  onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                                <section className={[styles['try-drag-drop'],'d-block d-md-none'].join(' ')}>
                                                                    <div  className={styles['dropzone1']}>
                                                                            <div className={styles['choose-button']}>
                                                                            <input 
                                                                            style={{ display: "none" }}
                                                                            type="file"
                                                                            onChange={this.multipleFileUpload}
                                                                            ref={(fileInput) => (this.fileInput = fileInput)}
                                                                            ></input>
                                                                            <button  onClick={() => this.fileInput.click()} className="btn">Upload Image</button>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                        {/* Video Section  */}
                                                        {/* <div className={[cls['video-upload'],'col-md-8 offset-md-2'].join(' ')}>
                                                            <h6><span><strong>NEW:</strong> Convert 360 degree Video of your car into an interactive iFrame</span> <button onClick={()=>this.videoThreeSixty()}>Get 360 Degree Video</button></h6>
                                                        </div> */}
                                                <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                    <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                        <img src="https://storage.googleapis.com/spyne-website/cars-try/1.png"></img>
                                                        
                                                    </div>
                                                </div>
                                                <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                    <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                        <img src="https://storage.googleapis.com/spyne-website/cars-try/2.png"></img>
                                                        
                                                    </div>
                                                </div>
                                                <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                    <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                        <img src="https://storage.googleapis.com/spyne-website/cars-try/3.png"></img>
                                                        
                                                    </div>
                                                </div>
                                                <div className={[styles['try-images-area'],'col-md-12 col-12 d-block d-md-none'].join(' ')}> 
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/car-sm.png"/>
                                                </div>
                                                
                                                    
                                                {/* <div className={[styles['right-section'],''].join(' ')}>
                                                    <div>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right%20side.png"></img>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                </div>
                        )}
                    </Dropzone>

                    {/* History Section  */}
                
                {/* <section className={[Styles['history'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles[''],'col-md-10 offset-md-1'].join(' ')}>
                                <div className={[Styles['upload-history'],'row m-0'].join(' ')}>
                                    <div className={[Styles['history-title'],'col-md-3'].join(' ')}>
                                        <h3>Uplo<span>ad H</span>istory</h3>
                                    </div>
                                    <div className={[Styles[''],'col-md-9'].join(' ')}>
                                        <div className={[Styles['history-img-row'],'row'].join(' ')}>
                                            {!this.state.history_data_error?
                                            <>
                                                {
                                                this.state.history_data.length==0?
                                                <div className={Styles['no-upload-message']}>
                                                <h2>No Images Uploaded</h2>
                                                </div>
                                                :    
                                                this.state.history_data.map((e) => {
                                                return (
                                                    <div className={[Styles['history-img'],'col'].join(' ')}>
                                                    <img src= {e.input_image_lres_url}/>
                                                    {
                                                        e.image_name.length>6?
                                                        <h5 title={e.image_name}>{e.image_name.slice(0,6)}...</h5>
                                                        :
                                                        <h5>{e.image_name}</h5>
                                                    }
                                                    <p>{e.created_on.split(' ')[this.state.history_data.length-2]}-{e.created_on.split(' ')[this.state.history_data.length-1]} at {e.created_on.split(' ')[this.state.history_data.length+1].slice(0,5)}</p>
                                                    </div>
                                                )
                                                })
                                                }   
                                                {
                                                this.state.history_data.length!=0?                                         
                                                <div  className={[Styles['arrow-history'],'col-1'].join(' ')}>
                                                <span><a href='/dashboard/enterprises'>&#x2192;</a></span>
                                                </div>
                                                :
                                                null
                                                }
                                            </>
                                            :
                                            <div className={Styles['no-upload-message']}>
                                                <h2>Error loding History!</h2>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                 {/* //History Section  */}

                    {/* <section className={[Styles['custom-backgrounds'],''].join(' ')}>
                            <div className={[Styles[''],'container'].join(' ')}>
                                <div className={[Styles['exterior-tabs'],'row'].join(' ')}>
                                    <div className={[Styles['title'],'col-md-12'].join(' ')}>
                                        <h1><span>Virtual </span>Showroom</h1>
                                        <Tabs className={[Styles['virtual-tabs'],'row'].join(' ')}>
                                            <TabList className={[Styles['slates-tabs'],'col-md-12'].join(' ')}>
                                                <Tab><span>Brick Slate</span></Tab>
                                                <Tab><span>Garage Slate</span></Tab>
                                                <Tab><span>Artistic Slate</span></Tab>
                                            </TabList>

                                            <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                                <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                    <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" /></Tab>
                                                    </TabList>

                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/4.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/7.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/10.png" />
                                                    </TabPanel>
                                                </Tabs>
                                            </TabPanel>
                                            
                                            <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                                <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                    <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" /></Tab>
                                                    </TabList>

                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/2.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/5.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/8.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/11.png" />
                                                    </TabPanel>
                                                </Tabs>
                                            </TabPanel>
                                            
                                            <TabPanel className={[Styles['virtual-tabs'],'col-md-12'].join(' ')}>
                                                <Tabs className={[Styles['virtual-images-tabs'],'row'].join(' ')}>
                                                    <TabList className={[Styles['virtual-images'],Styles['virtual-sm'],'col-md-2 col-2'].join(' ')}>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" /></Tab>
                                                        <Tab> <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" /></Tab>
                                                    </TabList>

                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/3.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/6.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/9.png" />
                                                    </TabPanel>
                                                    <TabPanel className={[Styles['virtual-images'],'col-md-10 col-10 display-none'].join(' ')}>
                                                        <img className={[Styles['img-fluid']]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/12.png" />
                                                    </TabPanel>
                                                </Tabs>
                                            </TabPanel>
                                            
                                        </Tabs>
                                        <div className={[Styles['title'],'col-md-12  d-block'].join(' ')}>
                                            <p><button onClick={this.applyChange}  className={[Styles['request-demo']]}>Try Now</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        <section className={styles['app-section']}>
                            <div className="container">
                                <div className={[styles['mob-area'],'row'].join(' ')}>
                                        <div className={[styles['mob-image'],'col-md-7'].join(' ')}>
                                            <img alt="" className="img-fluid" style={{
                                                    height:'auto',
                                                    width:'100%',
                                                    }} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/11.png" />
                                        </div>

                                        <div className={[styles['why-use'],'col-md-5'].join(' ')}>
                                            <h1>  <span className={[styles['no-border']]}>Get SpyneAI </span><span className={[styles['colored']]}>App</span></h1>  
                                            <p>With Spyne App anyone can capture professional car images  </p>
                                            <p><a href="https://www.spyne.ai/tech/cars/demo/" target="_blank" className={[styles['request-demo']]} >Learn more</a></p>
                                            <p>   <a href="https://play.google.com/store/apps/details?id=com.spyneai" target="_blank" className={[styles['request-demo2']]}>
                                                <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Store%20Name%3DGoogle%20Play%2C%20Badge%20Color%20Type%3DBlack%2C%20Language%3DEN.png" /> </a>
                                                <a href="https://spyneai.page.link/iosapp" target="_blank" className={[styles['request-demo2'],styles['applestore'],''].join(' ')}>
                                                <img alt="" className="img-fluid"  src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/applestore.png" /> </a>
                                            </p>
                                        </div>
                                </div>
                            </div>
                        </section>
                </>
                :
                <CarsThreeSixtyVideo 
                    onChange={this.onChange}
                    creditUsed={this.state.creditUsed}
                    availableCredit={this.state.availableCredit}
                    allotedCredit={this.state.allotedCredit}
                    planData={this.state.planData}
                    background_id={this.state.background_id}
                    backgroundUrl={this.state.backgroundEditedUrl}
                    bgCredit={this.state.bgCredit}
                    userId = {this.state.userId}
                    getUserCredit={this.getUserCredit}
                    auth_key={this.state.auth_key}
                    emailId = {this.state.emailId}
                    handleId={this.handleId}                               
                />
                }
                
                
                
            </>
            :
            <div>
            {this.state.filesList.length === 1 && this.state.isBulk===false?
            
            <BikesBackground
                handelGreyShade={this.handelGreyShade} 
                checkGreyShade={this.state.checkGreyShade}
                handleCroppedImage={this.handleCroppedImage}
                croppedUrl={this.state.tempref}
                cropperList={this.state.cropperList}
                handleCroppedDown={this.handleCroppedDown}
                showInstruction={this.state.showInstruction}
                hideModal = {this.hideLandingModal}
                selectedFile={this.state.selectedFile}
                fileSelectedHandler={this.fileSelectedHandler}
                fileSelectedHandlerNew={this.fileSelectedHandlerNew}
                handlePositionChange={this.handlePositionChange}
                handleTabSelection={this.handleTabSelection}
                handleUnshiftWalls={this.handleUnshiftWalls}
                orgUrl={this.state.filesList[0].url}
                handleId={this.handleId}
                customWallPosition={this.state.customWallPosition}
                handlewalls={this.handlewalls}
                customWallSet={this.customWallSet}
                editedUrl={this.state.url}
                customWall={this.state.customWall}
                handleToggle={this.handleToggle}
                toggleStatus={this.state.toggleStatus}
                handleAngle={this.handleAngle}
                imageSelected={this.state.imageSelected}
                handleClick={this.singleUploadAPI}
                handleModalApi={this.hitapiModal}
                isEditingFinish={this.state.isEditingFinish}
                handleDownload={this.updateCredit}
                creditUsed={this.state.creditUsed}
                availableCredit={this.state.availableCredit}
                creditAlloted={this.state.creditAlloted}
                defaultChecked={this.state.carShadow}
                planData={this.state.planData}
                floorData={this.state.floordata}
                wallData={this.state.walldata}
                spinner={this.state.spinner}
                toggleChange={this.state.toggleChange}
                background_id={this.state.background_id}
                dealerLogoFileHandler = {this.dealerLogoFileHandler}
                handleLogoPosition = {this.handleLogoPosition}
                logoUrl = {this.state.logoUrl}
                logoPosition = {this.state.logoPosition}
                hideLogo={this.state.hideLogo}
                askEmail={this.state.askEmail}
                backgroundUrl={this.state.backgroundEditedUrl}
                imageEdited = {this.state.imageEdited}
                downloadPopup = {this.downloadPopup}
                handleActivateButton={this.handleActivButton}
                activateButton={this.state.activateButton}
                downloadComplete={this.state.downloadComplete}
                handleDownloadComplete={this.handleDownloadComplete}
                isDownload={this.state.isDownload}
                // downloadPopup={this.downloadPopup}
                handleWindowTransparency = {this.handleWindowTransparency}
                handelColorEnhancement = {this.handelColorEnhancement}
                checkColorEnhancement = {this.state.checkColorEnhancement}
                handelBlurNumberPlate = {this.handelBlurNumberPlate}
                handleWindowCorrenction={this.handleWindowCorrenction}
                checkWindowCorrenction={this.state.checkWindowCorrenction}
                checkBlurNumberPlate = {this.state.checkBlurNumberPlate}
                checkWindowTransparency = {this.state.checkWindowTransparency}
                bgCredit={this.state.bgCredit}
                handleLogo = {this.handleLogo}
                multipleFileUploadProject={this.multipleFileUploadProject}
                fav_list={this.state.fav_list}
                bg_list={this.state.bg_list}
                handleBgList={this.handleBgList}
                handlefavList={this.handlefavList}
                showlogoCropModal={this.state.showlogoCropModal}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onComplete}
                onChange={this.onChange}
                handleCroppedLogoBlob={this.handleCroppedLogoBlob}
                handleCropModal={this.handleCropModal}
                saveCroppedImage={this.saveCroppedImage}
                handleRadioLogo={this.handleRadioLogo}
                checkUploadLogo={this.state.checkUploadLogo}
                chargedFeature={this.state.chargedFeature}
                // filesList={this.state.filesList}
            /> 
            :
            <BulkBikesBackground 
            showlogoCropModal={this.state.showlogoCropModal}
            crop={this.state.crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onComplete}
            onChange={this.onChange}
            handleCroppedLogoBlob={this.handleCroppedLogoBlob}
            handleCropModal={this.handleCropModal}
            saveCroppedImage={this.saveCroppedImage}
            handleRadioLogo={this.handleRadioLogo}
            checkUploadLogo={this.state.checkUploadLogo}
            chargedFeature={this.state.chargedFeature}
            setFiles={this.state.setFiles}
            filesList={this.state.filesList}
            multipleFileUpload={this.multipleFileUpload}
            multipleFileUploadProject={this.multipleFileUploadProject}
            handleId={this.handleId}
            editedUrl={this.state.url}
            handleToggle={this.handleToggle}
            toggleStatus={this.state.toggleStatus}
            handleAngle={this.handleAngle}
            imageSelected={this.state.imageSelected}
            handleClick={this.hitapi}
            handleModalApi={this.hitapiModal}
            isEditingFinish={this.state.isEditingFinish}
            handleDownload={this.updateCredit}
            creditUsed={this.state.creditUsed}
            availableCredit={this.state.availableCredit}
            allotedCredit={this.state.allotedCredit}
            defaultChecked={this.state.carShadow}
            planData={this.state.planData}
            spinner={this.state.spinner}
            toggleChange={this.state.toggleChange}
            background_id={this.state.background_id}
            dealerLogoFileHandler = {this.dealerLogoFileHandler}
            handleLogoPosition = {this.handleLogoPosition}
            logoUrl = {this.state.logoUrl}
            logoPosition = {this.state.logoPosition}
            hideLogo={this.state.hideLogo}
            askEmail={this.state.askEmail}
            backgroundUrl={this.state.backgroundEditedUrl}
            imageEdited = {this.state.imageEdited}
            downloadPopup = {this.downloadPopup}
            handleActivateButton={this.handleActivButton}
            activateButton={this.state.activateButton}
            downloadComplete={this.state.downloadComplete}
            handleDownloadComplete={this.handleDownloadComplete}
            isDownload={this.state.isDownload}
            handleWindowTransparency = {this.handleWindowTransparency}
            handelColorEnhancement = {this.handelColorEnhancement}
            handelBlurNumberPlate={this.handelBlurNumberPlate}
            handleWindowCorrenction={this.handleWindowCorrenction}
            checkWindowCorrenction={this.state.checkWindowCorrenction}
            checkBlurNumberPlate={this.state.checkBlurNumberPlate}
            checkColorEnhancement = {this.state.checkColorEnhancement}
            checkWindowTransparency = {this.state.checkWindowTransparency}
            bgCredit={this.state.bgCredit}
            handleLogo = {this.handleLogo}
            maxUpload = {this.state.maxUpload}
            handleUploadLimitModal={this.handleUploadLimitModal}
            emptyMultipleFileArray={this.emptyMultipleFileArray}
            updateMultipleImageList = {this.updateMultipleImageList}
            creditVerify={this.creditVerify}
            showBulkCreditModal={this.state.showBulkCreditModal}
            handleBulkCreditModal={this.handleBulkCreditModal}
            showProjectNameModal={this.state.showProjectNameModal}
            handleProjectName={this.handleProjectName}
            handleProjectNameModal={this.handleProjectNameModal}
            notEnoughCreditModal={this.state.notEnoughCreditModal}
            enoughCreditModal={this.state.enoughCreditModal}
            zeroCreditModal={this.state.zeroCreditModal}
            hitUploadAPI={this.hitUploadAPI}
            uploadStarteds={this.state.uploadStarteds}
            imgStatusDone={this.state.imgStatusDone}
            imgStatusProcess={this.state.imgStatusProcess}
            imgStatusUpload={this.state.imgStatusUpload}
            showRefundModal={this.state.showRefundModal}
            handleRefundModal={this.handleRefundModal}
            userId = {this.state.userId}
            refundImgUrl={this.state.refundImgUrl}
            getRefundModal={this.getRefundModal}
            refundOrgUrl={this.state.refundOrgUrl}
            refundImgId={this.state.refundImgId}
            refundSkuId={this.state.refundSkuId}
            refundNum={this.state.refundNum}
            updateEditedImageList={this.updateEditedImageList}
            downloadImages = {this.downloadImages}
            downloadZipLink = {this.state.downloadZipLink}
            isZipReady = {this.state.isZipReady}
            bulkDownloadDone={this.state.bulkDownloadDone}
            getUserCredit={this.getUserCredit}
            auth_key={this.state.auth_key}
            bulkUploadAPI={this.bulkUploadAPI}
            emailId = {this.state.emailId}
            project_name={this.state.project_name}
            projectNameExists = {this.state.projectNameExists}
            handleRetouchImages={this.handleRetouchImages}
            requestRetouch={this.requestRetouch}
            handleUnchecked={this.handleUnchecked}
            imgId_for_retouch={this.state.imgId_for_retouch}
            />
            }
            
            
            
            </div>
            
            } 
            </div>
        </div>
        )
    }
}

export default Sentry.withProfiler(CarsLandingPage);
// export default CarsLandingPage