import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';

import styles from '../../../Css/Model/youtubeGif.module.css'

class YoutubeGif extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    handleCloseModal = () => {
        
        this.props.hideThisModal()
        
    }

    render() { 
        return ( 
            <>
                <Modal
                show={this.props.showYoutubeGifModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                >
                    <Modal.Header onClick={this.handleCloseModal} closeButton></Modal.Header>
                    <Modal.Body >
                        <section className={[styles['upload-image'],''].join(' ')}>
                        <div className={[styles[''],'container-fluid'].join(' ')}>
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles['upload-heading'],'col-md-8 offset-md-2'].join(' ')}>
                                    <h1>Here is how you n<span>eed to u</span>pload the images</h1>
                                    <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/uploadpopup.png"/>
                                    <button onClick={this.handleCloseModal} className={[styles['upload-go'],''].join(' ')}>Got it</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    </Modal.Body>
                   
                </Modal> 
            </>    
        );
    }
}
 
export default YoutubeGif;  