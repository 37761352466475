import React, { Component } from 'react'

export default class BackgroundRetouch extends Component {
    render() {
        return (
            <div>
                <h1>Background Retouch</h1>
            </div>
        )
    }
}
