import React, { Component } from 'react'

import styles from '../../Css/Model/BackgroundRemoval.module.css'
import marginStyles from '../../Css/Model/ScalingMargins.module.css'
import {backgroundRemoval,uploadImageTOS3,imageCropping} from '../Common/Utils'

import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone'
import {Spinner} from "react-bootstrap";
import Toggle from 'react-toggle'
import Axios from 'axios'

export default class ScalindMargins extends Component {
    constructor(props){
        super(props)
        this.state={
            url:'',
            loader:false,
            selectedFile:this.props.selectedFile,
            toggleStatus:false,
            toggleChange:false,
            orgUrl:'',
            marketplaceData:[],
            selectedCategory:'topwear',
            fetchingMarketplaces:true,
            processing:false
        }
    }
    componentDidMount = () => {
        console.log(process.env.BaseURL)
        Axios({
            'methods':"GET",
            'url':`${process.env.REACT_APP_BASEURL}/fetch-marketplace`,
            'headers':{
                'category':'scaling&margins'
            }
        })
        .then((res) => {
            this.setState({
                marketplaceData:res.data
            })
        })
    }
    handleCategory = (e) => {
        e.preventDefault()
        this.setState({
            selectedCategory:e.target.id
        })
    }
    handelProcess = () => {
        this.setState({
            loader:!this.state.loader
        })
        let file = this.state.selectedFile
        const fd = new FormData()
        fd.append("image",file);
        fd.append("optimization", false)
        uploadImageTOS3(fd)
        .then((uploadResponse) => {
            const bgfd = new FormData()
            bgfd.append("image_url", uploadResponse.data.image)
            bgfd.append("category",this.state.selectedCategory)
            bgfd.append("marketplace",this.state.selectedMarketPlaceId)
            imageCropping(bgfd)
            .then((res) => {
                this.setState({
                    url:res.data.url,
                    orgImgShow: true,
                    loader: false,
                    editedState: true,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    processing:true
                })
            })
            .catch((err) => {
                this.setState({
                    loader:false
                })
            })
        })
        .catch((err) => {
            this.setState({
                loader:false
            })
        })
    }
    download = (e) => {
        e.preventDefault()
        const link = this.state.url;
        window.open(link, "_blank");
    }
    render() {
        const {selectedCategory,marketplaceData,processing} = this.state
        return (
            <div>
                <div className={[styles['wrapper'],'row mt-5'].join(' ')}>
                    <div className={[styles['left-box'],'col-lg-7 col-md-7 col-sm-12 col-12'].join(' ')}>
                        {
                            this.state.loader ? 
                            <Spinner
                                className="loader-edited"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                                animation="border"
                                variant="primary"
                            />
                            :
                            <div className={this.state.toggleStatus ? styles['display-image-with-bg']: styles['display-image']}>
                                {
                                    this.state.toggleStatus ?
                                    <img src={this.state.url} className="img-fluid"></img>
                                    :
                                    <img src={this.state.orgUrl !== '' ? this.state.orgUrl : this.props.originalImage} className="img-fluid"></img>
                                }
                            </div>
                        }
                        <div className="sec-2-toggle">
                            <p className="mr-5 mt-2">Original</p>
                            <label className="mt-2">
                                <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                            </label>
                            <p className="ml-5 mt-2">Edited</p>
                        </div>
                    </div>
                    <div className={[styles['right-box'],'col-12 col-sm-12 offset-1 col-lg-4 col-md-4'].join(' ')} >
                        <p>Upload Image</p>
                        {/* <div className={[styles['tooltip-text'],''].join(' ')}>
                          <p data-tip data-for='happyFace' className={styles['info']}>i</p>
                          <ReactTooltip className={[styles['tooltip-show'],""].join(' ')} id='happyFace' type='dark'>
                            <span>Select Car angle according to your uploaded Image.</span>
                          </ReactTooltip>
                        </div> */}
                        <div>
                            <div className={[marginStyles['upload-section'],"d-flex justify-content-center"].join(' ')} style={{margin:"0px", maxWidth:"100%"}}>
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} className={[styles["screen-2-drop"],'container-fluid'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                            <p className={[marginStyles["para-1"],''].join(' ')}>Drag Image Here</p>
                                            <p className={[marginStyles["para-2"],''].join(' ')}>or browse for image</p>
                                            <input  onChange={this.props.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <hr></hr>
                            <div>
                                <p>Choose Category</p>
                                <div className={[marginStyles['category-buttons'],''].join(' ')}>
                                    <button id="topwear" onClick={this.handleCategory} className={selectedCategory == 'topwear' ? marginStyles['active-button']: marginStyles['inactive-button']}>Topwear</button>
                                    <button id="bottomwear" onClick={this.handleCategory} className={selectedCategory == 'bottomwear' ? marginStyles['active-button']: marginStyles['inactive-button']}>Bottomwear</button>
                                    <button id="other" onClick={this.handleCategory} className={selectedCategory == 'other' ? marginStyles['active-button']: marginStyles['inactive-button']}>Other</button>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <p>Marketplace</p>
                                <div className={[marginStyles['marketplaces'],''].join(' ')}>
                                    {
                                        marketplaceData.map((e,i) => {
                                            return(
                                                <div key={i} >
                                                    <img src={e.image_url}></img>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <p>Margins</p>
                                <div className="d-flex">
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Top</div>
                                            </div>
                                            <input type="text" className={[marginStyles['input-right'],"form-control"].join(' ')}  id="inlineFormInputGroup1"/>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Bottom</div>
                                            </div>
                                            <input type="text" className={[marginStyles['input-right'],"form-control"].join(' ')}  id="inlineFormInputGroup2"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Right</div>
                                            </div>
                                            <input type="text" className={[marginStyles['input-right'],"form-control"].join(' ')}  id="inlineFormInputGroup3"/>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <label className="sr-only" for="inlineFormInputGroup">Username</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className={[marginStyles['input-left'],"input-group-text bg-white"].join(' ')} >Left</div>
                                            </div>
                                            <input type="text" className={[marginStyles['input-right'],"form-control"].join(' ')}  id="inlineFormInputGroup4"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={[marginStyles['processing'],''].join(' ')}>
                                {
                                    processing ? 
                                    <button onClick={this.handelProcess}>Process</button>
                                    :
                                    <button onClick={this.download}>Download</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
