import React, { Component } from 'react'

import styles from '../../Css/Model/BackgroundRemoval.module.css'
import footwearOnlyStyles from '../../Css/FootwearScreenTwo.module.css'
import {backgroundRemoval,uploadImageTOS3,backgroundFootwearRemoval} from '../Common/Utils'

import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone'
import {Spinner} from "react-bootstrap";
import Toggle from 'react-toggle'

export default class BackgroundRemoval extends Component {
    constructor(props){
        super(props)
        this.state={
            url:'',
            loader:true,
            selectedFile:this.props.selectedFile,
            toggleStatus:true,
            toggleChange:true,
            orgUrl:'',
            progress:false
        }
    }
    download = (e) => {
        e.preventDefault()
        const link = this.state.url;
        window.open(link, "_blank");
    }
    hitapi = () => {
        let file = this.state.selectedFile
        const fd = new FormData()
        fd.append("image",file);
        fd.append("optimization", false)
        uploadImageTOS3(fd)
        .then((uploadResponse) => {
            const bgfd = new FormData()
            bgfd.append("image_url", uploadResponse.data.image)
            backgroundFootwearRemoval(bgfd)
            .then((res) => {
                this.setState({
                    url:res.data.url,
                    orgImgShow: true,
                    loader: false,
                    editedState: true,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    progress:true
                })
            })
            .catch((err) => {
                this.setState({
                    spinner:false,
                    progress:false
                })
            })
        })
    }
    fileSelectedHandler = (event) => {
        this.setState({
          loader:false,
          progress:false
        })
        if(event.target){
        var file = event.target.files[0];
        if (Math.round(file.size / 1024) > 30720) {
          return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState(
            {
              orgUrl: [reader.result],
            },
            () => {
              try {
                localStorage.setItem("orgUrlBg", this.state.orgUrl);
              } catch (e) {
                if (e == "QUOTA_EXCEEDED_ERR") {
                }
              }
            }
          );
        }.bind(this);
        let editedImages;
        editedImages = localStorage.getItem("editedImagesBgr");
        editedImages = editedImages ? editedImages.split(",") : [];
        this.setState(
          {
            selectedFile: event.target.files[0],
            editing: true,
            editedImagesArr: [...editedImages],
            uploading: true,
            loader: true,
            uploadBox: false,
          },
          () => {
            this.hitapi(1);
          }
        );
        }
        else{
          let file = event[0]
          if (Math.round(file.size / 1024) > 30720) {
            return;
          }
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            this.setState(
              {
                orgUrl: [reader.result],
              },
              () => {
                try {
                  localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                  if (e == "QUOTA_EXCEEDED_ERR") {
                  }
                }
              }
            );
          }.bind(this);
          let editedImages;
          editedImages = localStorage.getItem("editedImagesBgr");
          editedImages = editedImages ? editedImages.split(",") : [];
          this.setState(
            {
              selectedFile: event[0],
              editing: true,
              editedImagesArr: [...editedImages],
              uploading: true,
              loader: true,
              uploadBox: false,
            },
            () => {
              this.hitapi();
            }
          );
        }
      };
    componentDidMount = () => {
        this.hitapi()
    }
    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    // spinner:false
                });
            }
            .bind(this),
            500
        );
    }
    render() {
        return (
            <div>
                <div className={[styles['wrapper'],'row mt-5'].join(' ')}>
                    <div className={[styles['left-box'],'col-lg-7 col-md-7 col-sm-12 col-12'].join(' ')}>
                        {
                            this.state.loader ? 
                            <Spinner
                                className="loader-edited"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                                animation="border"
                                variant="primary"
                            />
                            :
                            <div className={this.state.toggleStatus ? styles['display-image-with-bg']: styles['display-image']}>
                                {
                                    this.state.toggleStatus ?
                                    <img src={this.state.url} className="img-fluid"></img>
                                    :
                                    <img src={this.state.orgUrl !== '' ? this.state.orgUrl : this.props.originalImage} className="img-fluid"></img>
                                }
                            </div>
                        }
                        <div className="sec-2-toggle">
                            <p className="mr-5 mt-2">Original</p>
                            <label className="mt-2">
                                <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                            </label>
                            <p className="ml-5 mt-2">Edited</p>
                        </div>
                    </div>
                    <div className={[styles['right-box'],'col-lg-4 col-md-4 col-sm-12 col-12 offset-1'].join(' ')}>
                        <p>Upload Image</p>
                        {/* <div className={[styles['tooltip-text'],''].join(' ')}>
                          <p data-tip data-for='happyFace' className={styles['info']}>i</p>
                          <ReactTooltip className={[styles['tooltip-show'],""].join(' ')} id='happyFace' type='dark'>
                            <span>Select Car angle according to your uploaded Image.</span>
                          </ReactTooltip>
                        </div> */}
                        <div>
                            <div className={[footwearOnlyStyles['upload-section'],"d-flex justify-content-center"].join(' ')} style={{margin:"0px", maxWidth:"100%"}}>
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} className={[styles["screen-2-drop"],'container-fluid'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                            <p className="para-1">Upload Files Here</p>
                                            <p class="para-2"><b>or</b> Browse For Image</p>
                                            <input  onChange={this.props.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <div className={[styles['download-section'],''].join(' ')}>
                                <button onClick={this.download} disabled={!this.state.progress ? true : false}>Download HD</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
