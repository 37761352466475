import React, { Component } from 'react' 
import Toggle from 'react-toggle'
import Dropzone from 'react-dropzone'
import Slider from "react-slick";
import '../../App.css'
import Style from '../../Css/CarsScreenTwo.module.css'
import styles from '../../Css/Footwear/FootwearBgV2.module.css'
// import Styles from '../../Css/Footwear/FootwearV2.module.css'
import DownloadclipprModal from '../Common/Modal/DownloadclipprModal';
import FootwearInsufficientCreditModal from '../Common/Modal/FootwearInsufficientCreditModal';
import FeedbackModal from '../Common/Modal/feedbackModal'
import {creditInfo, reduceCredit,cookie} from '../Common/Utils';
import marketPlace from '../FashionRemovalV2/marketPlace';
import {Spinner} from "react-bootstrap";
import {Helmet} from "react-helmet";


function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
 export default class FootwearBackgroundV2 extends Component {
    constructor(props){
        super(props)
        this.state={
            backgroundsData:[], 
            availableCredit:5,
            angle:this.props.selectedAngle,
            angleSelected:this.props.imageSelected,
            loginStatus:false,
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            toggleChange:true,
            otpVerification:true,
            userId:'',
            availableCredit:null,
            creditId:"",
            loader:false,
            creditAlloted:null,
            creditUsed:null,
            spinner:false,
            emailValidation:true,
            logoUrl:'',
            below_bg:false,
            logoPosition:null,
            askemail:'',
            isAskEmail:true,
            showInstruction:false,
            bg_color:'',
            selectedMarketPlaceId:'',
            // overlays_shown:false,
            // overlays_cat_clicked:"hb",
            // overlays_icon_click:true,
            // overlays_icon_click_src:"",
            // // overlayiconclicked:false,
            // overlays_toggle_show:false,
            // carsModal: true,
            // // overlays_toggle_show:true,
            // active_category:"hb",
            // active_overlay:"overlay_1",
            // floorSelected: false,
            // floor_img_url: '',
            // autoplay:false,
            activButton: "Exterior",
            actiButton:"Background1",
            isDownload: false,
            showBackgroundList: false,
            dropBackground: false,
            uploadWallUrl:"",
            visualSelection:"backgrounds",
            applyWay:"",
            tempCustomWall:"",
            tempPosition:0,
            showDelPopup:false,
            delWall:false,
            wallIndex:"",
            new_width:0,
            left_wall_padding:0,
            new_height:0,
            downloadType:"",
            FootwearInsuffCredModal:false,
            step1:true,
        //     rating:false
         }
        this.myDivToFocus = React.createRef()
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
   
    handleCustomBackgroundColor = (bg_url) => {
        this.props.handleBgColor(bg_url)
        this.setState({
            bg_color:bg_url,
        })
    }
    handleMarketPlaceId = (id) => {
        this.setState({
            selectedMarketPlaceId:id,
            step2:true,
        })
        this.props.handlemarket(id)
    }
    handleCloseModal = () => {
          this.setState({
              show:!this.state.show,
              showModal:false
          })
      }

    
    handleSliderTab = (name) => {
        this.setState({
            backgroundTab: name
        })
    }
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }

    handleToggleImage=()=>{
        this.props.handleToggle()
        if (this.props.toggleStatus==false && this.props.editedUrl !=""){
          this.setState({
            isDownload:true
          })
        }
        else if (this.props.toggleStatus==true){
          this.setState({
            isDownload:false
          })
        }
        
    }
    
    handleBrandTab = (name) => {
        this.setState({
            brandTab: name
        })
    }

    handleActivButton = (name) => {
        this.setState({
            activButton:name
        })
    }
    
    handleQualityTab = (name) => {
        this.setState({
            qualityTab: name
        })
    }

    hideRatingModal=()=>{
        this.setState({
            rating:!this.state.rating
        })
    }


    applyChange = (e) => {
        // window.scrollTo(this.myDivToFocus.current.offsetTop,0) 
        this.setState({
          isDownload: true,
          showBackgroundList: false
        })
        this.props.handleClick()
        
    }

    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus:true
        })
        this.props.fileSelectedHandler(acceptedFiles)
    } 
    
    downloadPopup = () => {
        this.setState({
          isDownload: false
        })
        this.props.handleToggle()
        
    }

    handleModal = () => {
        let userEmail = cookie("emailId")
        let userId = cookie("userId")
        if(userEmail && userId !== 'NA'){
            if(this.props.availableCredit <= 0 && userEmail && this.props.activateButton == "Hd"){
              this.setState({FootwearInsuffCredModal:true})
            }
            else{
              
              this.setState({clickModal: true})
              this.props.handleDownload()
            }
        }
          else{
            this.setState({
              show:false,
              showModal:false
          })
          }
      }
    

    hideDownloadModal=()=>{
            this.setState({
                clickModal:!this.state.clickModal
            })
    }

    hideInsufficientCreditModal=()=>{
        this.setState({
            FootwearInsuffCredModal:!this.state.FootwearInsuffCredModal
        })
    }

    fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
    }
      



    render() {
        const settings = { 
            infinite: false,
            slidesToShow:3,
            slidesToScroll: 1, 
            swipeToSlide: true,
            autoplay: false,
            speed: 500, 
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          };
          const settings_2 = { 
            infinite: false,
            slidesToShow:4,
            slidesToScroll: 1, 
            swipeToSlide: true,
            autoplay: false,
            speed: 500, 
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          };
         return (
            <><Helmet>
                <title>Replace Shoes Background Instantly | Shoe Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                <meta property="og:title" content="Replace Shoes Background Instantly | Shoe Photo Editor.Try Now" />
                <meta property="og:description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                 <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
             </Helmet>
             <div>
                     <section className={[styles['upload-footwear-image'], ''].join(' ')}>
                         <div className={[styles[''], "container"].join(" ")}>
                             <div className={[styles[''], "row"].join(" ")}>
                                 <div className={[styles['left-sku'], "col-md-4"].join(" ")}>
                                     {/* <h5>SKU0011223 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/sku.svg" /></h5> */}
                                 </div>
                                 <div className={[styles['upload-sec'], "col-md-4"].join(" ")}>
                                     {/* <div className={[styles['choose-button'],'text-center '].join(' ')}>
      <input
      style={{ display: "none" }}
      type="file"
      onChange={this.fileHandler}
      ref={(fileInput) => (this.fileInput = fileInput)}
      ></input>
      <button  onClick={() => this.fileInput.click()} className=""><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" /> Upload Image</button>
  </div> */}
                                     <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)}>
                                         {({ getRootProps, getInputProps }) => (
                                             <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                 <div {...getRootProps({})} className={styles['dropzone1']}>
                                                     <div className={[styles['choose-button'], 'text-center '].join(' ')}>

                                                         <button className="">
                                                             <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                             Upload Image</button>
                                                     </div>
                                                     <input onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                 </div>
                                             </section>
                                         )}
                                     </Dropzone>
                                 </div>

                             </div>
                         </div>
                     </section>
                     <div className={[styles['credit-sec']]}>
                         <div className={[styles['right-credits'], ""].join(" ")}>
                             <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/credit.png" />Available Credits: &nbsp;
                             <span> {this.props.availableCredit}</span>

                         </div>
                         <div className={[styles['right-top-credits'], ""].join(" ")}>
                             <a href="https://www.spyne.ai/tech/pricing">
                                 <button style={{ width: "max-content" }}>

                                     <span className={[styles['add-credit'], ""].join(" ")}> Add Credits</span> </button>
                             </a>
                         </div>
                     </div>


                     {/* Upload section End  */}

                     <section className={[styles['footwear-main'], ''].join(' ')}>
                         <div className={[styles[''], ""].join(" ")}>
                             <div className={[styles[''], "row"].join(" ")}>
                                 <div styles={{ Height: "600px" }} className={[styles[''], "col-md-7"].join(" ")}>
                                     <div className={[styles['background-tabs'], 'row'].join(' ')}>
                                         {/* <img className={styles['left-frame']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/left-frame.png"/> */}
                                         {/* <img className={styles['main-image']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/> */}
                                         {!this.props.spinner && this.props.editedUrl !== "" && this.props.orgUrl !== "" && this.props.toggleChange ? (
                                             <div className={styles['edited_image']}>
                                                 <img src={this.props.editedUrl} className={styles['main-image']}></img>
                                             </div>


                                         )
                                             :
                                             this.props.spinner && this.props.orgUrl !== "" ?
                                                 <div className={[Style['image-box-area'], 'd-flex'].join(' ')}>
                                                     <Spinner
                                                         className="loader-edited"
                                                         style={{
                                                             marginTop: "0px",
                                                             marginBottom: "0px",
                                                         }}
                                                         animation="border"
                                                         variant="primary" />

                                                 </div>
                                                 :
                                                 !this.props.orgUrl == "" && !this.props.toggleChange ? (
                                                     <div className={styles['edited_image']}>
                                                         <img alt="" className={[Style['original_image'], styles['main-image'], ''].join(' ')} src={this.props.orgUrl} />
                                                     </div>
                                                 )
                                                     :
                                                     null}

                                         {/* <img className={styles['frame']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/frame.png"/> */}
                                         {/* <img className={styles['bottom-frame']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/bottom-frame.png"/> */}


                                         {/* <Slider {...settings_2} className={[Styles['bulk-images-slider'],''].join(' ')}>
        <div className={[Styles['bulk-images-area'],''].join(' ')}>
            <div className={[Styles['bulk-image'],''].join(' ')}>
                <p>Kids.png</p>
                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                <img className={Styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
            </div>
        </div>
        <div className={[Styles['bulk-images-area'],''].join(' ')}>
            <div className={[Styles['bulk-image'],''].join(' ')}>
                <p>Kids.png</p>
                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                <img className={Styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
            </div>
        </div>
        <div className={[Styles['bulk-images-area'],''].join(' ')}>
            <div className={[Styles['bulk-image'],''].join(' ')}>
                <p>Kids.png</p>
                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                <img className={Styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
            </div>
        </div>
        <div className={[Styles['bulk-images-area'],''].join(' ')}>
            <div className={[Styles['bulk-image'],''].join(' ')}>
                <p>Kids.png</p>
                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                <img className={Styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
            </div>
        </div>
        <div className={[Styles['bulk-images-area'],''].join(' ')}>
            <div className={[Styles['bulk-image'],''].join(' ')}>
                <p>Kids.png</p>
                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/original-shoe.png"/>
                <img className={Styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
            </div>
        </div>
    </Slider> */}
                                         <div className={[styles['toggle-section'], "sec-2-toggle"].join(' ')}>
                                             <p className="mr-5 mt-2">Original</p>
                                             <label className="mt-2">
                                                 <Toggle onChange={this.handleToggleImage} icons={false} checked={this.props.toggleStatus} />
                                             </label>
                                             <p className="ml-5 mt-2">Edited</p>
                                         </div>




                                     </div>
                                 </div>
                                 {this.state.isDownload ?

                                     <>

                                         <div styles={{ Height: "90vh" }} className={[styles['background-content'], ""].join(" ")}>
                                             {/* <h4> Preview & Download </h4>
            <h5>Your Selected Marketplaces </h5>
            <Slider {...settings} className={[styles['brand-tabs'],styles['brand-slider'],''].join(' ')}>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand1" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand1")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand2" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand2")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/flipkart_logo.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand3" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand3")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/myntra_logo.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p className={[styles['colored'],''].join(' ')}></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand4" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand4")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/snapdeal.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateeButton == "Brand5" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand5")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/ajio.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand6" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand6")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/tata-cliq.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
             
            </Slider> */}

                                             <div className={[styles['download-popup'], 'row'].join(' ')}>
                                                 <div className={[styles['cross-btn'], ''].join(' ')}>
                                                     <img onClick={this.downloadPopup} className={styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png" />
                                                 </div>
                                                 <button className={this.props.activateButton == "Hd" ? [styles['download-hd'], styles['activate'], 'col-md-12 '].join(' ') : null} onClick={() => this.props.handleActivateButton("Hd")}>
                                                     <h6><input type="radio" checked={this.props.activateButton == "Hd"} />HD Download</h6>
                                                     <ul>
                                                         <li>Full Image 1920 × 1080 </li>
                                                         <li>Without Watermark</li>
                                                     </ul>
                                                 </button>
                                                 <div className={[styles['border-1'], ''].join(' ')}></div>
                                                 <button className={this.props.activateButton == "Basic" ? [styles['download-hd'], styles['activate'], 'col-md-12'].join(' ') : null} onClick={() => this.props.handleActivateButton("Basic")}>
                                                     <h6><input type="radio" checked={this.props.activateButton == "Basic"} />Basic Download</h6>
                                                     <ul>
                                                         <li>Preview Image 640 × 360 </li>
                                                         <li>With Watermark</li>
                                                     </ul>
                                                 </button>

                                                 <div className={[styles['apply-btn'], 'text-center'].join(' ')}>
                                                     <button onClick={this.handleModal} className={[styles['']]}>Download</button>
                                                 </div>
                                             </div>
                                         </div>

                                         <FootwearInsufficientCreditModal
                                             showInsufficientCreditModal={this.state.FootwearInsuffCredModal}
                                             hideThisModal={this.hideInsufficientCreditModal} />

                                         <DownloadclipprModal
                                             clickDownload={this.state.clickModal}
                                             hideModal={this.hideDownloadModal}
                                             downloadType={this.props.activateButton}
                                             availableCredit={this.props.availableCredit}
                                             handleModal={this.handleModal}
                                             handleActivateButton={this.props.handleActivateButton} />

                                         <FeedbackModal
                                             rating={this.state.rating}
                                             hideRatingModal={this.hideRatingModal} />
                                     </>


                                     :

                                     <div className={[styles['background-content'], ""].join(" ")}>
                                         <div className={[styles['right-top-selection']]}>
                                             <div className={this.state.visualSelection == "backgrounds" ? styles['active-visual-selection'] : styles['inactive-visual-selection']} style={{ cursor: "pointer" }}>Backgrounds </div>
                                             <div className={this.state.visualSelection == "custom_walls" ? styles['active-visual-selection'] : styles['inactive-visual-selection']} style={{ cursor: "pointer" }}></div>


                                         </div>
                                         <div>
                                             <div className={[styles['custom-walls-area'], ''].join(' ')}>
                                                 <div>
                                                     <div className={this.state.bg_color != '' ? [styles['custom-wall'], ''].join(' ') : [styles['adjust-height'], ''].join(' ')}>
                                                         <p><span>1</span>Select a Background</p>
                                                     </div>

                                                     <div className={[styles['side-margin']]}>
                                                         <div className={styles['solid-backgroung']}>
                                                             <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/white%20bg.png" height='142px' width='150px' onClick={() => this.handleCustomBackgroundColor("white")} style={this.state.bg_color == 'white' ? { border: "1px solid #FF7700", cursor: "pointer" } : { cursor: 'pointer' }}></img>
                                                             <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/grey%20bg.png" height='142px' width='150px' onClick={() => this.handleCustomBackgroundColor("grey")} style={this.state.bg_color == 'grey' ? { border: "1px solid #FF7700", cursor: 'pointer' } : { cursor: 'pointer' }}
                                                             ></img>
                                                         </div>
                                                     </div>

                                                 </div>
                                                 <div className={[styles['border-1'], ''].join(' ')}></div>

                                                 <div className={this.state.selectedMarketPlaceId != "" ? [styles['custom-wall'], ''].join(' ') : [styles['adjust-height'], ''].join(' ')}>
                                                     <p><span>2</span>Marketplace</p>
                                                 </div>
                                                 <div className={[styles['side-margin']]}>
                                                     <div className={[styles['marketplaces'], ''].join(' ')}>
                                                         {marketPlace.slice(0, 9).map((e, i) => {
                                                             return (
                                                                 <div key={i} style={this.state.selectedMarketPlaceId == e.name && this.state.step1 == true ? { border: "1px solid #FF7700", width: "53px" } : null}>
                                                                     <img src={e.img} onClick={this.state.step1 == true ? () => this.handleMarketPlaceId(e.name) : null} style={{ height: "50px", width: '50px' }}></img>
                                                                 </div>
                                                             );
                                                         })}
                                                         {marketPlace.length > 9 ? (
                                                             <div className={styles['inactive-background-sec']} onClick={this.handleMarketPlace}>
                                                                 <div className={styles['overlay-pos']}>
                                                                     <img alt="" src="https://storage.googleapis.com/spyne-website/try-categories/morre.png" style={{ position: "relative" }} />
                                                                     <div className={styles['img-overlay']}></div>
                                                                     <div className={styles['more-btn-overlay-2']}>+ {marketPlace.length - 9} More</div>
                                                                 </div>
                                                             </div>) : null}
                                                     </div>
                                                 </div>
                                                 <div className={[styles['border-1'], ''].join(' ')}></div>

                                                 <div>
                                            <label className={styles['toggle1']}>
                                                <div style={{ color: "black" , margin: "0 40PX" }}>
                                                    <snap style={{ position: "absolute" }}>Color Enhancement&ensp;<img className="" src="https://storage.googleapis.com/spyne-website/non-automobile-try/Group.png" title="Enhance color image" /></snap>
                                                    <input type="checkbox" onChange={this.props.handleColorEnhancement} checked={this.props.checkColorEnhancement} />
                                                    <span style={{ color: "white" }} className={styles['slider2']}><p></p>
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                                
                                                 <div className={[styles['adjust-height'], ''].join(' ')}>
                                                     <p><span>3</span> Apply and View Changes</p>

                                                 </div>

                                             </div>
                                         </div>

                                         <div className={[styles['apply-btn'], 'row text-center'].join(' ')}>
                                             <button onClick={this.applyChange}>Apply</button>
                                         </div>
                                         <div className={[styles['note'], 'row'].join(' ')}>
                                             {/* <p><strong>Note :</strong> Loremi Ipsum Loremi Ipsum Loremi  Ipsum Loremi Ipsum Loremi Ipsum.</p> */}
                                         </div>
                                     </div>}

                             </div>
                         </div>
                     </section>





                     {/* Footwear Selected popup Slider */}













                 </div></>
         )
     }
 }
 