import Axios from 'axios';
import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'
import {fetchCreditUserDetails, updateUserCredit,cookie,poll} from '../Utils'

class RefundModal extends Component {
    state = { 
        refundInitiated: false,
        refundReason:'',
        refundText:'',
        btnSelected:false
     }

     handleRefundText = (e) => {
         e.preventDefault();
         this.setState({
             refundText: e.target.value
         })
     }

     refundReason = (name) => {
        //  e.preventDefault();
         this.setState({
            refundReason: name
         })
         console.log(this.state.refundReason)
     }

     refundFunctionIntegration = () => {
        this.refundAPI()
        this.refundCredit()
        this.props.updateEditedImageList()
        this.updateStatusOfRefundedImg()
        // this.props.updateEditedImageList()
     }

     updateStatusOfRefundedImg = () => {
        // let auth_key = this.cookie("auth_token")
        const fd = new FormData()
        fd.append("image_id",this.props.refundImgId)
        fd.append("auth_key", this.props.auth_key)
        Axios.post(`https://www.clippr.ai/api/v2/image/status`,fd)
        .then(resp => {
            console.log(resp)
        })
     }
 
     refundAPI = () => {
         const fd = new FormData()
        //  let userId = this.cookie('userId')
        //  let auth_key = this.cookie("auth_token")
         fd.append("refund_reason",this.state.refundReason)
         fd.append("description",this.state.refundText)
         fd.append("edited_url",this.props.refundImgUrl)
         fd.append("org_url", this.props.refundOrgUrl)
         fd.append("image_id",this.props.refundImgId)
         fd.append("product_category","Automobile")
         fd.append("user_id",this.props.userId)
         fd.append("auth_key", this.props.auth_key)
         Axios.post(`https://www.clippr.ai/api/v2/image/refund`,fd)
         .then(resp => {
             if(resp.data.status == 200){
                 this.setState({
                     refundInitiated: true,
                 })
             }
         })
     }

     refundCredit = () => {
        
         const fd = new FormData()
         fd.append("emailId",this.props.emailId)
         fd.append("auth_key", this.props.auth_key)
         Axios.post(`https://www.clippr.ai/api/v2/user/update-credits`,fd)
         .then(resp => {
             console.log(resp)
         })
     }

     closeRefundModal = () => {
         this.setState({
            refundInitiated: false,
            refundReason:'',
            refundText:'',
            btnSelected:false
         })
         this.props.handleRefundModal()
     }
    
    
    render() { 
        return ( 
                <React.Fragment>
                    {this.state.refundInitiated ?
                        <Modal
                            show={this.props.showRefundModal}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            size='md'
                            centered
                            >
                                <Modal.Body className="text-right cursor-pointer">
                                    <img onClick={this.closeRefundModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                </Modal.Body>
                                    <Modal.Body className="">
                                        <section className={[styles['more-uploads'],''].join(' ')}>
                                            <div className={[styles[''],'container-fluid'].join(' ')}>
                                                <div className={[styles[''],'row'].join(' ')}>
                                                    <div className={[styles['popup-content'],' text-center'].join(' ')}>
                                                    
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/wallet-compress.gif" />
                                                        <p>Oops, We are sorry!</p>
                                                        <p style={{"color" : "#6d6d6d"}}>Our AI tech improves everyday, our team is working hard to not let this happen again.</p>
                                                        {/* <h3>Remaining Credits : <span>{this.props.availableCredit}</span></h3> */}
                                                        <h3 >We have added back <b>1 credit</b> to your wallet</h3>
                                                        <button onClick={this.closeRefundModal} className={styles['proceed-btn']}>Thank you</button>
                                                        
                                                    </div>
                                    
                                                </div>
                                            </div>
                                        </section> 
                                        
                                    </Modal.Body>
                                
                                
                            
                        </Modal> 
                        :
                        <Modal
                            show={this.props.showRefundModal}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            size='lg'
                            centered
                            >
                                <Modal.Body className="text-right cursor-pointer">
                                    <img onClick={this.props.handleRefundModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                </Modal.Body>
                                
                                    <Modal.Body className="">
                                        <section className={[styles['more-uploads'],''].join(' ')}>
                                            <div className={[styles[''],'container-fluid'].join(' ')}>
                                                <div className={[styles[''],'row'].join(' ')}>
                                                    <div className={[styles['popup-content'],'text-center pt-0'].join(' ')}> 
                                                        <h4>Please sel<span>ect a reason</span> for Refund</h4>
                                                        <img src={this.props.refundImgUrl} />
                                                        <ul>
                                                            <button onClick={() => this.refundReason('Car is not placed properly')} type="button" className={this.state.refundReason == 'Car is not placed properly' ? styles['btn-book-selected'] : styles['btn-book']} value="Car is not placed properly">
                                                                Car is not placed properly 
                                                            </button>
                                                            <button onClick={() => this.refundReason('Car Body and Window have reflections')} type="button" className={this.state.refundReason == 'Car Body and Window have reflections' ? styles['btn-book-selected'] : styles['btn-book']} value="Car Body and Window have reflections">
                                                                Car Body and Window have reflections
                                                            </button>
                                                            <button onClick={() => this.refundReason('Car looks unnatural')} type="button" className={this.state.refundReason == 'Car looks unnatural' ? styles['btn-book-selected'] : styles['btn-book']} value="Car looks unnatural">
                                                                Car looks un-natural 
                                                            </button>
                                                            <button onClick={() => this.refundReason('Background is not clear')} type="button" className={this.state.refundReason == 'Background is not clear' ? styles['btn-book-selected'] : styles['btn-book']} value="Background is not clear">
                                                                Background is not clear
                                                            </button>
                                                            <button onClick={() => this.refundReason('Other')} type="button" className={this.state.refundReason == 'Other' ? styles['btn-book-selected'] : styles['btn-book']} value="Other">
                                                                Other
                                                            </button>
                                                        </ul>
                                                        <textarea onChange={this.handleRefundText} cols="" rows="" placeholder="Tell us more....." className="form-control"></textarea> 
                                                        <button onClick={this.refundFunctionIntegration} className={styles['proceed-btn']}>Request Refund</button>
                                                    
                                                    </div>
                                    
                                                </div>
                                            </div>
                                        </section>
                                        
                                    </Modal.Body>
                            
                        </Modal> 
                    }
                
                </React.Fragment>
        );
    }
}
 
export default RefundModal;