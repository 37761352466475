import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import Resizer from 'react-image-file-resizer'
import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import BulkUploadEdited from './BulkUploadEdited';
import LandingModal from '../Common/Modal/LandingModal';
import { isMobile } from 'react-device-detect';
import { fetchCreditUserDetails, updateUserCredit, poll, chunkArray, getCookie } from '../Common/Utils'
import queryString from 'query-string'
import Spinner from '../Background/Spinner';
import Swal from "sweetalert2"; 



class MultiUploadEdited extends Component {
    state = {
        projectNameExists: false,
        filesList: [],
        showProjectNameModal: false,
        planData: [],
        project_id: '',
        sku_id: '',
        project_name: '',
        sku_name: '',
        auth_key: '',
        showInstruction: false,
        availableCredit: 0,
        alottedCredit: 0,
        creditUsed: 0,
        notEnoughCreditModal: false,
        enoughCreditModal: false,
        zeroCreditModal: false,
        enterpriseId: '',
        viewSample: true,
        viewSample2: true,
        uploadCounter: 0,
        uploadStarteds: false,
        isZipReady: false,
        productCategory: 'automobiles',
        prodCatId : 'cat_d8R14zUNE',
        background_id: 9999,
        originalImageList:[],
        isSpinner:false,
        projectUserId:'',
        imageCount:0,
        skuCount:0,
        projectName:'',
        showWarningModal:false,
        errorImageCounter:0,
        projectIdExists: false
    }
    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]
    componentDidMount = () => {
        sessionStorage.clear()
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck) {
            this.setState({
                showInstruction: false
            })
        }
        else {
            if (isMobile) {
                this.setState({
                    showInstruction: false
                })
            } else {
                this.setState({
                    showInstruction: true
                })
            }

        }
    
        
        this.setState({
            auth_key: this.cookie('auth_token')
        })
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        var enterpriseId = urlParams.get('enterpriseId')
        var projectId = urlParams.get('projectId')

        if(enterpriseId == null){
            this.setState({ enterpriseId: 'TaD1VC1Ko' })
            enterpriseId = 'TaD1VC1Ko'
        }else{
            this.setState({
                enterpriseId:enterpriseId
            })
        }


        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/enterprise/get",
            params: {
                enterprise_id: enterpriseId,
            }
        }).then(resp => {

            document.cookie = `enterpriseId=${resp.data?.data?.enterprise_id}; path=/`;
            document.cookie = `apiKey=${resp.data?.data?.api_key}; path=/`;
        })

        if(this.cookie('auth_token') != null){
            


            if(projectId == null){
                this.setState({
                    showProjectNameModal:true
                })
            }else{
                this.setState({
                    showProjectNameModal: false,
                    project_name:projectId,

                })
                this.getProjectImages(projectId)
                sessionStorage.setItem('project_id',projectId)
            }

            

            
            // var enterpriseId
            // if (queries.enterpriseId != undefined) {
            //     this.setState({ enterpriseId: queries.enterpriseId })
            //     enterpriseId = queries.enterpriseId
            // } else {
            //     this.setState({ enterpriseId: 'TaD1VC1Ko' })
            //     enterpriseId = 'TaD1VC1Ko'
            // }



            

            

            

            let auth_key = this.cookie("auth_token")
            axios({
                method: "GET",
                url: "https://www.clippr.ai/api/v2/credit/fetch",
                params: {
                    auth_key: auth_key
                }

            }).then(resp => {
                // console.log(resp)
                if (resp.data.status == 200) {
                    this.setState({
                        availableCredit: resp.data.data.credit_available,
                        creditAlloted: resp.data.data.credit_allotted,
                        creditUsed: resp.data.data.credit_used
                    })
                } else {
                    console.log(resp)
                }
            })

            

            var project_id = sessionStorage.getItem('project_id')
            if(project_id == null){
                this.setState({
                    showProjectNameModal: true
                })
            }else{
                this.setState({
                    showProjectNameModal: false
                })
                this.getProjectImages(project_id)
            }
        }


        

    }


    getProjectImages = (projectId) => {
        this.setState({
            isSpinner:true
        })
        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/multi/get-project-image",
            params: {
                enterprise_id : this.cookie('enterpriseId'),
                project_id:projectId
            }
        }).then((resp) => {
            if(resp?.data?.status == 200){
                this.setState({
                    originalImageList:[...resp?.data?.data],
                    isSpinner:false,
                    projectUserId:resp?.data?.user_id,
                    imageCount:resp?.data?.total_images,
                    skuCount:resp?.data?.total_sku,
                    projectName:resp?.data?.project_name,
                    projectIdExists: false
                })
            }else{
                this.setState({
                    isSpinner:false,
                    showProjectNameModal: true,
                    projectIdExists: true
                })
                
            }

            // console.log(resp)
        })
    }

    multipleFileUpload = (event) => {

        // if (this.state.project_name === '') {
        //     this.setState({
        //         showProjectNameModal: true
        //     })
        // }

        for (let i = 0; i < event.length; i++) {
            if (this.state.filesList.length < this.state.originalImageList.length) {
                if (event.target) {
                    let str = event.target.files[i].name
                    let imageCategory
                    let status
                    // if(str.includes("ext")){
                    //     imageCategory =  'Exterior'
                    // }else if(str.includes("int")){
                    //     imageCategory =  'Interior'
                    // }
                    let skuArr = str.split('_')
                    if(skuArr[2] === 'Ecom'){
                        imageCategory = 'Ecom'
                        status = 'Uploading'
                        
                    }else if(skuArr[2] === 'Food'){
                        imageCategory = 'Food'
                        status = 'Uploading'
                    }else if(skuArr.length > 2 ){
                        imageCategory = 'Automobile'
                        status = 'Uploading'
                    }else{
                        status = 'Uploading'
                        imageCategory = 'Automobile'
                    }
                    
                    let file = {
                        file: event.target.files[i],
                        name: event.target.files[i].name,
                        size: event.target.files[i].size,
                        type: event.target.files[i].type,
                        lastModified: event.target.files[i].lastModified,
                        lastModifiedDate: event.target.files[i].lastModifiedDate,
                        url: URL.createObjectURL(event.target.files[i]),
                        status: status,
                        imageId: null,
                        projectId: null,
                        skuId: null,
                        imageCategory: imageCategory
                    }
                    if (Math.round(file.size / 1024) > 30720) {
                        return;
                    }
                    if (status == 'Uploading') {
                        this.setState(
                            {
                                filesList: [...this.state.filesList, file],
                            },

                        );
                    }
                } else {
                    let str = event[i].name
                    let imageCategory
                    let status
                    
                    let skuArr = str.split('_')
                    if(skuArr[2] === 'Ecom'){
                        imageCategory = 'Ecom'
                        status = 'Uploading'
                        
                    }else if(skuArr[2] === 'Food'){
                        imageCategory = 'Food'
                        status = 'Uploading'
                    }else if(skuArr.length > 2){
                        imageCategory = 'Automobile'
                        status = 'Uploading'
                    }else{
                        status = 'Uploading'
                        imageCategory = 'Automobile'
                    }
                    
                    
                    let file = {
                        file: event[i],
                        name: event[i].name,
                        size: event[i].size,
                        type: event[i].type,
                        lastModified: event[i].lastModified,
                        lastModifiedDate: event[i].lastModifiedDate,
                        url: URL.createObjectURL(event[i]),
                        status: status,
                        imageId: null,
                        projectId: null,
                        skuId: null,
                        imageCategory: imageCategory
                    }
                    // let file = event[i]
                    if (Math.round(file.size / 1024) > 30720) {
                        return;
                    }
                    if (status == 'Uploading') {
                        this.setState(
                            {
                                filesList: [...this.state.filesList, file],
                            },

                        );
                    }

                }
                if (this.state.filesList.length === 1) {
                    this.setState({
                        isBulk: false
                    })
                }
                else {
                    this.setState({
                        isBulk: true
                    })
                }

            }
            else {
                this.setState({
                    maxUpload: true
                })
            }


        }

    }

    multipleFileUploadProject = (event) => {

        this.state.filesList.length = 0
        this.state.originalImageList = 0
        this.state.project_name = ''
        this.state.uploadStarteds = false
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.uploadCounter = 0
        sessionStorage.clear()
        window.location.reload()

        // for (let i = 0; i < event.length; i++) {
        //     if (this.state.filesList.length < 200) {
        //         if (event.target) {
        //             let str = event.target.files[i].name
        //             let imageCategory
        //             let status
        //             // if(str.includes("ext")){
        //             //     imageCategory =  'Exterior'
        //             // }else if(str.includes("int")){
        //             //     imageCategory =  'Interior'
        //             // }
        //             let skuArr = str.split('_')
        //             if (skuArr[1] == 'ext') {
        //                 imageCategory = 'Exterior'
        //                 status = 'Uploading'
        //             } else if (skuArr[1] == 'int') {
        //                 imageCategory = 'Interior'
        //                 status = 'Uploading'
        //             } else if (skuArr[1] == 'info') {
        //                 imageCategory = 'Info'
        //                 status = 'Uploading'
        //             } else {
        //                 status = 'Error'
        //                 imageCategory = ''
        //             }
        //             let file = {
        //                 file: event.target.files[i],
        //                 name: event.target.files[i].name,
        //                 size: event.target.files[i].size,
        //                 type: event.target.files[i].type,
        //                 lastModified: event.target.files[i].lastModified,
        //                 lastModifiedDate: event.target.files[i].lastModifiedDate,
        //                 url: URL.createObjectURL(event.target.files[i]),
        //                 status: status,
        //                 imageId: null,
        //                 projectId: null,
        //                 skuId: null,
        //                 imageCategory: imageCategory
        //             }
        //             if (Math.round(file.size / 1024) > 30720) {
        //                 return;
        //             }
        //             if (status == 'Uploading') {
        //                 this.setState(
        //                     {
        //                         filesList: [...this.state.filesList, file],
        //                     },

        //                 );
        //             }
        //         } else {
        //             let str = event[i].name
        //             let imageCategory
        //             let status
        //             // if(str.includes("_ext_")){
        //             //     imageCategory =  'Exterior'
        //             // }else if(str.includes("_int_")){
        //             //     imageCategory =  'Interior'
        //             // }
        //             let skuArr = str.split('_')
        //             if (skuArr[1] == 'ext') {
        //                 imageCategory = 'Exterior'
        //                 status = 'Uploading'
        //             } else if (skuArr[1] == 'int') {
        //                 imageCategory = 'Interior'
        //                 status = 'Uploading'
        //             } else if (skuArr[1] == 'info') {
        //                 imageCategory = 'Info'
        //                 status = 'Uploading'
        //             } else {
        //                 status = 'Error'
        //                 imageCategory = ''
        //             }
        //             let file = {
        //                 file: event[i],
        //                 name: event[i].name,
        //                 size: event[i].size,
        //                 type: event[i].type,
        //                 lastModified: event[i].lastModified,
        //                 lastModifiedDate: event[i].lastModifiedDate,
        //                 url: URL.createObjectURL(event[i]),
        //                 status: status,
        //                 imageId: null,
        //                 projectId: null,
        //                 skuId: null,
        //                 imageCategory: imageCategory
        //             }
        //             // let file = event[i]
        //             if (Math.round(file.size / 1024) > 30720) {
        //                 return;
        //             }
        //             if (status == 'Uploading') {
        //                 this.setState(
        //                     {
        //                         filesList: [...this.state.filesList, file],
        //                     },

        //                 );
        //             }
        //         }
                
        //     }
        //     else {
        //         this.setState({
        //             maxUpload: true
        //         })
        //     }


        // }
    }

    handleUploadLimitModal = () => {
        this.setState({
            maxUpload: false
        })
    }

    emptyMultipleFileArray = () => {
        this.state.filesList.length = 0
        this.setState({
            maxUpload: false
        })
    }

    hideLandingModal = () => {
        this.setState({ showInstruction: !this.state.showInstruction })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        this.setState({
            project_name: e.target.value,
            projectNameExists: false
        })
        sessionStorage.setItem("project_id", e.target.value);

    }

    handleProjectNameModal = () => {

        this.setState({
            isSpinner:true
        })
        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/multi/get-project-image",
            params: {
                enterprise_id : this.cookie('enterpriseId'),
                project_id:this.state.project_name
            }
        }).then((resp) => {
            if(resp?.data?.status == 200){
                this.setState({
                    originalImageList:[...resp?.data?.data],
                    isSpinner:false,
                    projectUserId:resp?.data?.user_id,
                    imageCount:resp?.data?.total_images,
                    skuCount:resp?.data?.total_sku,
                    projectName:resp?.data?.project_name,
                    showProjectNameModal: false,
                    projectNameExists: false,
                    projectIdExists: false
                })
            }else{
                this.setState({
                    isSpinner:false,
                    showProjectNameModal: true,
                    projectNameExists: true,
                    projectIdExists: false
                })
                
            }

            // console.log(resp)
        })
    }
    updateMultipleImageList = (i) => {
        this.state.filesList.splice(i, 1)
        this.forceUpdate()
    }

    hitUploadAPI = async () => {

        // let price = this.state.filesList.length

        this.setState({
            uploadStarteds: true,
            showBulkCreditModal: false,
            enoughCreditModal: false,
            showWarningModal:false

        })

        const batchFiles = chunkArray(this.state.filesList, 4)

        for (let batchIndex = 0; batchIndex < batchFiles.length; batchIndex++) {

            try {
                const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                    let i = 4 * batchIndex + currIndex
                    return this.bulkUploadAPI(i)
                }))
                console.log(resp)

            } catch (err) {
                console.error(err)

            }
        }
        this.setState({
            isZipReady: true
        })

        sessionStorage.setItem("isZipReady", true);


        sessionStorage.setItem("uploadStarteds", true);

    }

    bulkUploadAPI = async (i) => {
        
        let enterprise_id = this.state.enterpriseId
        
        const fd = new FormData();

        fd.append("image", this.state.filesList[i].file)
        fd.append("enterprise_id", enterprise_id)
        fd.append('project_id', this.state.project_name)

        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesList: [
                ...this.state.filesList.slice(0, i),
                Object.assign({}, this.state.filesList[i], { status: 'Uploading' }),
                ...this.state.filesList.slice(i + 1)
            ]
        })
        return axios.post(`https://www.clippr.ai/api/v2/multi/upload-output-image`, fd)
            .then((uploadResponse) => {
                if (uploadResponse.data.status == 200) {

                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], {
                                status: 'Done',
                                url: uploadResponse?.data?.output_image_hres_url,
                                imageId: uploadResponse?.data?.image_id,
                                projectId: uploadResponse?.data?.project_id,
                                // skuId: uploadResponse.data.data.sku_id,
                            }),
                            ...this.state.filesList.slice(i + 1)
                        ],
                        uploadCounter: this.state.uploadCounter + 1,
                    })

                } else if (uploadResponse.data.status == 400) {
                    document.cookie = 'emailId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'userId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'isValidated' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'auth_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.sessionStorage.clear()
                    window.location.reload()
                }else if(uploadResponse.data.status == 404){
                    this.setState({
                        errorImageCounter: this.state.errorImageCounter + 1
                    })
                }
                sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
                return Promise.resolve(uploadResponse)
            })
            .catch((err) => {
                this.setState({
                    errorBulkFiles: [...this.state.errorBulkFiles, Object.assign({}, this.state.filesList[i], {
                        status: 'Error',
                    })],
                    filesList: [
                        ...this.state.filesList.slice(0, i),
                        Object.assign({}, this.state.filesList[i], {
                            status: 'Error',
                        }),
                        ...this.state.filesList.slice(i + 1)
                    ]
                })
                return Promise.reject(err)

            })

    }

    handleId = (id, price) => {
        this.setState({
            background_id: id,
            bgCredit: price,
            // isEditingFinish:false,
            askEmail: false,
            toggleChange: false,
            toggleStatus: false,
        })
    }

    creditVerify = () => {

        // let userId = this.cookie('userId')
        let price = this.state.filesList.length
        if (this.state.availableCredit >= price) {


            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal: false,
                zeroCreditModal: false,
                enoughCreditModal: true,

                // uploadStarteds:true,
            })

        } else if (this.state.availableCredit == 0) {
            this.setState({
                showBulkCreditModal: true,
                zeroCreditModal: true,
                // uploadStarteds:true,
            })
        } else if (this.state.availableCredit < price) {
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal: true,
                filesList: this.state.filesList.slice(0, this.state.availableCredit)

            })
        }
        // console.log(this.state.filesList)
    }

    handleBulkCreditModal = () => {
        this.setState({
            showBulkCreditModal: false
        })
    }

    getUserCredit = async () => {
        // if(){
        let auth_key = this.cookie('auth_token')
        const resp = await poll({
            fn: () => {

                return axios({
                    method: "GET",
                    url: "https://www.clippr.ai/api/v2/credit/fetch",
                    // data : fd
                    params: {
                        // email_id : emailId,
                        auth_key: auth_key
                    }

                })
            },
            validate: (resp) => {
                if (resp.data.status == 200) {
                    if (this.state.bulkDownloadDone == true) {
                        return true
                    } else {
                        this.setState({
                            availableCredit: resp.data.data.credit_available,
                            allotedCredit: resp.data.data.credit_allotted,
                            creditUsed: resp.data.data.credit_used
                        })
                        return false
                    }
                    // this.setState({
                    //     availableCredit: resp.data.data.credit_available,
                    //     creditAlloted : resp.data.data.credit_allotted,
                    //     creditUsed: resp.data.data.credit_used
                    // })
                    // return false
                } else if (resp.data.status == 400) {
                    document.cookie = 'emailId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'userId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'isValidated' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'auth_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.sessionStorage.clear()
                    window.location.reload()
                }


            },
            interval: 5000,
        })
    }

    toggleSample = () => {
        this.setState({
            viewSample: !this.state.viewSample
        })
    }
    toggleSample2 = () => {
        this.setState({
            viewSample2: !this.state.viewSample2
        })
    }
    handleProductCategory = (name) => {
        let enterprise_id = this.cookie("enterpriseId")
        if(name == 'automobiles'){
            this.setState({
                prodCatId: 'cat_d8R14zUNE'
            })
        }else if(name == 'ecommerce'){
            this.setState({
                prodCatId: 'cat_Ujt0kuFxY'
            })
        }
        
        this.setState({
            productCategory: name
        })

        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/backgrounds/fetchEnterpriseBgs/v2",
            params: {
                category: name,
                enterprise_id: enterprise_id,
            }
        })
        .then((res) => {
            this.setState({
                planData: res.data?.data
            })
        })
    }

    handleWarningModal = () => {
        this.setState({
            showWarningModal:!this.state.showWarningModal
        })
    }

    render() {
        return (
            <div>
                
                   
                        <>
                            {this.state.auth_key == null ?

                                <LandingModal
                                showInstruction={this.state.showInstruction}
                                hideModal={this.hideLandingModal}
                                />
                                : null
                            }
                            

                            {this.state.isSpinner ? 
                                <Spinner />
                                :
                                null
                            }

                            <BulkUploadEdited
                            filesList={this.state.filesList}
                            multipleFileUpload={this.multipleFileUpload}
                            multipleFileUploadProject={this.multipleFileUploadProject}
                            handleId={this.handleId}
                            editedUrl={this.state.url}
                            handleToggle={this.handleToggle}
                            toggleStatus={this.state.toggleStatus}
                            handleAngle={this.handleAngle}
                            imageSelected={this.state.imageSelected}
                            handleClick={this.hitapi}
                            handleModalApi={this.hitapiModal}
                            isEditingFinish={this.state.isEditingFinish}
                            handleDownload={this.updateCredit}
                            creditUsed={this.state.creditUsed}
                            availableCredit={this.state.availableCredit}
                            allotedCredit={this.state.allotedCredit}
                            defaultChecked={this.state.carShadow}
                            planData={this.state.planData}
                            spinner={this.state.spinner}
                            toggleChange={this.state.toggleChange}
                            background_id={this.state.background_id}
                            dealerLogoFileHandler={this.dealerLogoFileHandler}
                            handleLogoPosition={this.handleLogoPosition}
                            logoUrl={this.state.logoUrl}
                            logoPosition={this.state.logoPosition}
                            hideLogo={this.state.hideLogo}
                            askEmail={this.state.askEmail}
                            backgroundUrl={this.state.backgroundEditedUrl}
                            imageEdited={this.state.imageEdited}
                            downloadPopup={this.downloadPopup}
                            handleActivateButton={this.handleActivButton}
                            activateButton={this.state.activateButton}
                            downloadComplete={this.state.downloadComplete}
                            handleDownloadComplete={this.handleDownloadComplete}
                            isDownload={this.state.isDownload}
                            handleWindowTransparency={this.handleWindowTransparency}
                            handelColorEnhancement={this.handelColorEnhancement}
                            handelBlurNumberPlate={this.handelBlurNumberPlate}
                            handleWindowCorrenction={this.handleWindowCorrenction}
                            checkWindowCorrenction={this.state.checkWindowCorrenction}
                            checkBlurNumberPlate={this.state.checkBlurNumberPlate}
                            checkColorEnhancement={this.state.checkColorEnhancement}
                            checkWindowTransparency={this.state.checkWindowTransparency}
                            bgCredit={this.state.bgCredit}
                            handleLogo={this.handleLogo}
                            maxUpload={this.state.maxUpload}
                            handleUploadLimitModal={this.handleUploadLimitModal}
                            emptyMultipleFileArray={this.emptyMultipleFileArray}
                            updateMultipleImageList={this.updateMultipleImageList}
                            creditVerify={this.creditVerify}
                            showBulkCreditModal={this.state.showBulkCreditModal}
                            handleBulkCreditModal={this.handleBulkCreditModal}
                            showProjectNameModal={this.state.showProjectNameModal}
                            handleProjectName={this.handleProjectName}
                            handleProjectNameModal={this.handleProjectNameModal}
                            notEnoughCreditModal={this.state.notEnoughCreditModal}
                            enoughCreditModal={this.state.enoughCreditModal}
                            zeroCreditModal={this.state.zeroCreditModal}
                            hitUploadAPI={this.hitUploadAPI}
                            uploadStarteds={this.state.uploadStarteds}
                            imgStatusDone={this.state.imgStatusDone}
                            imgStatusProcess={this.state.imgStatusProcess}
                            imgStatusUpload={this.state.imgStatusUpload}
                            showRefundModal={this.state.showRefundModal}
                            handleRefundModal={this.handleRefundModal}
                            userId={this.state.userId}
                            refundImgUrl={this.state.refundImgUrl}
                            getRefundModal={this.getRefundModal}
                            refundOrgUrl={this.state.refundOrgUrl}
                            refundImgId={this.state.refundImgId}
                            refundNum={this.state.refundNum}
                            updateEditedImageList={this.updateEditedImageList}
                            downloadImages={this.downloadImages}
                            downloadZipLink={this.state.downloadZipLink}
                            isZipReady={this.state.isZipReady}
                            bulkDownloadDone={this.state.bulkDownloadDone}
                            getUserCredit={this.getUserCredit}
                            auth_key={this.state.auth_key}
                            bulkUploadAPI={this.bulkUploadAPI}
                            emailId={this.state.emailId}
                            project_name={this.state.project_name}
                            projectNameExists={this.state.projectNameExists}
                            uploadCounter={this.state.uploadCounter}
                            productCategory={this.state.productCategory}
                            imageCount={this.state.imageCount}
                            skuCount={this.state.skuCount}
                            projectName={this.state.projectName}
                            projectUserId={this.state.projectUserId}
                            originalImageList={this.state.originalImageList}
                            showWarningModal={this.state.showWarningModal}
                            handleWarningModal={this.handleWarningModal}
                            errorImageCounter={this.state.errorImageCounter}
                            projectIdExists={this.state.projectIdExists}
                        />
                            
                        </>

                        
                
            </div>
        );
    }
}

export default MultiUploadEdited;