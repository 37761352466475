import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'

class ProjectNameModal extends Component {
    state = {  }


    handleChange = (e) => {
        this.props.handleProjectName(e)
    }
    
    render() { 
        return ( 
                <Modal
                    show={this.props.showProjectNameModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='md'
                    centered
                    >
                        <Modal.Header className={[styles['modal-header'],''].join(' ')}>
                            <div>
                                <h5>
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/project-icon.png"/></span>
                                    New Project</h5>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="">
                            <section className={[styles['more-uploads'],''].join(' ')}>
                                <div className={[styles[''],'container-fluid'].join(' ')}>
                                    <div className={[styles[''],'row'].join(' ')}>
                                        <div className={[styles['popup-content'],''].join(' ')}>
                                            <label>
                                                 
                                                 Project Name</label>
                                             <input className="form-control " type="text" required placeholder="Ex. Audi" onChange={this.handleChange} value={this.props.project_name}></input>
                                             {this.props.projectNameExists ? 
                                                <p style={{"color" : "#cc4d29","fontSize" : "11px", "textAlign" : "left"}}>Oops - This project name already exsists, Please try again with a new project name!</p>
                                                :
                                                null   
                                            }
                                             
                                            <div className={[styles['create-project'],''].join(' ')}>
                                                <button className={styles['proceed-btn']} onClick={this.props.handleProjectNameModal} disabled = {this.props.project_name == '' ? true : false}>Create</button>
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                        </section>
                            
                        </Modal.Body>
                    
                </Modal> 
        );
    }
}
 
export default ProjectNameModal;