import React, { Component } from 'react';
import  {Modal} from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'
import axios from 'axios'

  


class UploadLimitModal extends Component {
    constructor(props){
        super(props)
        this.state={
            
        }
    }

   

    handleCloseModal = () => {
       
            // this.props.hideModal()
    }

    

    render() {
        
        return ( 
                <Modal 
                    show={this.props.maxUpload}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='md'
                    centered>
                    {/* <Modal.Body className="text-right cursor-pointer">
                        <img onClick={this.props.handleUploadLimitModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                    </Modal.Body> */}
                    <Modal.Body className="text-center">
                        <section className={[styles['more-uploads'],''].join(' ')}>
                            <div className={[styles[''],'container-fluid'].join(' ')}>
                                <div className={[styles[''],'row'].join(' ')}>
                                    <div className={[styles['popup-content'],' text-center'].join(' ')}>
                                    
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/warning.gif" />
                                        <p>You cannot upload more than 36 images at a time</p>
                                        <button onClick={this.props.emptyMultipleFileArray} className={styles['proceed-btn2']}>Try Again</button>
                                    
                                    </div>
                    
                                </div>
                            </div>
                        </section> 
                        
                    </Modal.Body>

                </Modal>
        );
    }
}
 
export default UploadLimitModal;