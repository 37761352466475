import React, { Component } from 'react'
import styles from '../../../Css/CarsBackgroundReplacement.module.css'
export default class Cars extends Component {
    render() {
        return (
            <>
            <div className={styles['carsFront']}>
                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+section.png"></img>
              </div>
            </>
        )
    }
}
