import React, { Component } from "react";
import Slider from "react-slick";


import styles from '../../Css/ImageScoring/UploadScreen.module.css'
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

export default class UploadScreen extends Component {

  render() {
     
    const settings = {
      // className: "center",
      // dots: true,
      infinite: false,
      slidesToShow:4,
      slidesToScroll: 1, 
      swipeToSlide: true,
      autoplay: false,
      speed: 500,
      // autoplaySpeed: 7000
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }; 

    return (
            <div>
                <section className={[styles['image-scoring'],'i'].join(' ')}>
                  <div className={[styles['image-container'],''].join(' ')}>
                      <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['scoring-col'],'col-md-5'].join(' ')}>
                            <div className={[styles['scoring-upload'],'row'].join(' ')}>
                                <div className={[styles['scoring-upload-box'],'col-md-12'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/image-scoring/score-upload.gif"/>
                                    <h6>Drag & D<span>rop file here t</span>o upload</h6>
                                    <p>Browse files</p>
                                    <div className={[styles['slider-area'],'row m-0'].join(' ')}>
                                      <p>No image? Try one of these: <span> <i class="fa fa-refresh" aria-hidden="true"></i></span></p>
                                      <Slider {...settings} className= {[styles['image-slider'],'col-md-12 image-slider'].join(' ')} > 
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                      </Slider>
                                    </div>
                                </div>

                            </div>
                        </div>
                          <div className={[styles['scoring-title'],'col-md-7'].join(' ')}>
                               <h1>Try out the pre-trained <span>Everypixel models</span> in action!</h1>
                               <p>Try out the pre-trained <span>Everypixel models</span> in action!</p>
                               <img src="https://storage.googleapis.com/spyne-website/image-scoring/Rectangle.png" className={styles['rectangle']}/>
                          </div>
                      </div>
                  </div>
                </section>
                {/* Upload-Image Section End  */}
                <section className={[styles['image-scoring'],'i'].join(' ')}>
                  <div className={[styles['image-container'],''].join(' ')}>
                      <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['scoring-col'],'col-md-5'].join(' ')}>
                            <div className={[styles['scoring-upload2'],'row'].join(' ')}>
                                <div className={[styles['scoring-upload-box2'],'col-md-12'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/image-scoring/model.png"/>
                                     
                                </div>
                                <p>Drag & Drop file here to upload</p>
                            </div>
                            <div className={[styles['slider-area'],styles['slider2'],'row m-0'].join(' ')}>
                                      <p>No image? Try one of these: <span> <i class="fa fa-refresh" aria-hidden="true"></i></span></p>
                                      <Slider {...settings} className= {[styles['image-slider'],'col-md-12 image-slider'].join(' ')} > 
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                      </Slider>
                                    </div>
                        </div>
                          <div className={[styles['scoring-title'],'col-md-7'].join(' ')}>
                               <h1>1</h1>
                               <img src="https://storage.googleapis.com/spyne-website/image-scoring/Rectangle2.png" className={styles['rectangle']}/>
                          </div>
                      </div>
                  </div>
                </section>
                {/* Uploaded img Scanning End */}
            </div>
   

    )
  }
}
