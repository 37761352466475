import React, { Component } from 'react';
import styles from '../../Css/AppDashboard/TableSection.module.css'
import axios from 'axios'
import {fetchCreditUserDetails, updateUserCredit,cookie,getCookie,poll, chunkArray} from '../Common/Utils'
import ProjectTable from './ProjectTable'
import SkuTable from './SkuTable'
import ImageTable from './ImageTable'
import { Dropdown,DropdownButton } from 'react-bootstrap';
import Spinner from './Spinner'

class TableSection extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Projects",
            projectsList:[],
            auth_key:'',
            skuList:[],
            imageList:[],
            spinnerProject: false,
            spinnerSku: false,
            spinnerImage: false,
            month:''
        }
        
        this.fetchData = this.fetchData.bind(this);
        
    }

    componentDidMount = () => {
        this.setState({
            spinnerProject: true,
            spinnerSku: true,
            spinnerImage: true
        })
        let auth_key = cookie('ssoToken')
        
        this.setState({
            auth_key: auth_key
        })
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/projects",
            params : {
                auth_key: auth_key,
                month: "3"  ,
                enterprise_id:getCookie('enterpriseId')              
            }
            
        }).then(resp =>{
            this.setState({
                projectsList: resp.data.data,
                spinnerProject: false
            })
        })


        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/skus",
            params : {
                auth_key: auth_key,
                month: "3",
                enterprise_id:getCookie('enterpriseId')               
            }
            
        }).then(resp =>{
            this.setState({
                skuList: resp.data.data,
                spinnerSku: false,
            })
        })

        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/images",
            params : {
                auth_key: auth_key,
                month: "3",
                enterprise_id:getCookie('enterpriseId')                
            }
            
        }).then(resp =>{
            this.setState({
                imageList: resp.data.data,
                spinnerImage: false
            })
        })

    }

    fetchData = (event) => {
        this.state.skuList.length = 0
        this.setState({
            spinnerSku: true,
            spinnerImage: true,
            spinnerProject: true,
        })
        
        this.fetchSku(event)
        this.fetchProject(event)
        this.fetchImage(event)
        
    }

    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
        })
    }

    fetchSku = (event) => {
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/skus",
            params : {
                auth_key: this.state.auth_key,
                month: event.target.value,
                enterprise_id:getCookie('enterpriseId')          
            }
            
        }).then(resp =>{
            this.setState({
                skuList: resp.data.data,
                spinnerSku: false   
            })
        })
    }

    fetchProject = (event) => {
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/projects",
            params : {
                auth_key: this.state.auth_key,
                month: event.target.value,
                enterprise_id:getCookie('enterpriseId')          
            }
            
        }).then(resp =>{
            this.setState({
                projectList: resp.data.data,
                spinnerProject: false   
            })
        })
    }

    fetchImage = (event) => {
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/dashboard/images",
            params : {
                auth_key: this.state.auth_key,
                month: event.target.value,
                enterprise_id:getCookie('enterpriseId')          
            }
            
        }).then(resp =>{
            this.setState({
                imageList: resp.data.data,
                spinnerImage: false   
            })
        })
    }


    render() { 
        return ( 
            <div className="mb-5">
                <div className="row mt-4">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <a style={{textDecoration:"none", color:"#000"}} href="https://www.spyne.ai/tech/cars/try">
                            <img style={{width:"35px",cursor:"pointer"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/Button%20Icon%20Light.png"/>
                            <span className="ml-2">New Upload</span>
                        </a>
                    </div>

                </div>
                <ul className={[styles['change-tabs'],''].join(' ')}>
                    <li className={[styles[' '],''].join(' ')}>
                        <button className={this.state.activeButton == "Projects" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Projects")}> 
                            <span>Projects</span>
                        </button>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activeButton == "SKUs" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("SKUs")}> 
                            <span>SKUs</span>   
                        </button>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activeButton == "Images" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Images")}> 
                            <span>Images</span>   
                        </button>
                    </li>
                    <div>
                    {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </DropdownButton> */}
                    <form>
                        <select className={[styles['dropdown-btn'],''].join(' ')} onChange={this.fetchData}>
                            <option  value="3">Last 3 months</option>
                            <option value="6">Last 6 months</option>
                            <option  value="9"> Last 9 months</option>
                        </select>
                    </form>
                   
                    </div>
                </ul>
                <div className="ml-3">
                    {
                        this.state.activeButton == "Projects" 
                        ?
                        <React.Fragment>
                            {this.state.spinnerProject ? 
                                <Spinner />
                                :
                                <ProjectTable projectList={this.state.projectsList} />
                            }
                            
                        </React.Fragment>
                        
                        :
                        this.state.activeButton == "SKUs"
                        ?
                        <React.Fragment>
                            {this.state.spinnerSku ? 
                                <Spinner />
                                :
                                <SkuTable skuList={this.state.skuList}/>
                            }
                            
                        </React.Fragment>
                       
                        :
                        this.state.activeButton == "Images"
                        ?
                        <React.Fragment>
                            {this.state.spinnerImage ? 
                                <Spinner />
                                :
                                <ImageTable imageList={this.state.imageList}/>
                            }
                            
                        </React.Fragment>
                       
                        : null
                    }
                </div>
                
            </div>
         );
    }
}
 
export default TableSection;