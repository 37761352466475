import React, { Component } from 'react'
import styles from '../../Component/AddEnterprise/AddEnterprise.module.css' 
import axios from 'axios'
import queryString from 'query-string'
import swal from 'sweetalert';



export default class AddEnterprise extends Component {

    constructor(props){
        super(props)
        this.state = {
            name:'',
            logoUrl:'',
            color:'',
            category:'Cars'
        }
        // this.handleCategory = this.handleCategory.bind(this);
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handleLogoChange = (e) => {
        this.setState({
            logoUrl: e.target.value
        })
    }
    handleColorChange = (e) => {
        this.setState({
            color: e.target.value
        })
    }
    handleCategoryChange = (e) => {
        this.setState({
            category: e.target.value
        })
    }

    createEnterprise = (e) => {
        e.preventDefault()
        const fd = new FormData()
        fd.append("name", this.state.name);
        fd.append("color", this.state.color);
        fd.append("logo_url", this.state.logoUrl);
        fd.append("category",this.state.category)
        axios.post(`https://www.clippr.ai/api/v2/enterprise/create`,fd)
        .then(resp => {
            if(resp.data.status == 200){
                swal("Success", "New Enterprise Added", "success");
                this.setState({
                    name:'',
                    logoUrl:'',
                    color:'',
                    category:'Cars'
                })
                // this.forceUpdate()
                // setTimeout(() => {
                //     window.location.reload()
                // },2000)
            }
        })
        
    }

    render() { 
    return (
       
            <div>
                <section className={[styles['user-section']]}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row align-items-center justify-content-center'].join(' ')}>
                            <div className={[styles['user-heading'],'col-md-12'].join(' ')}>
                                <h6><img src="https://storage.googleapis.com/spyne-website/static/app-dashboard/AddUser.png"/>
                                <span>Add a new Enterprise</span>
                                    </h6>
                            </div>
                            <div className={[styles['user-form'],'col-md-12'].join(' ')}>        
                                <form onSubmit={this.createEnterprise} className={[styles[''],'row justify-content-center align-items-center'].join(' ')}>
                                    <div className="col-12 col-md-6">
                                        <label>Name*
                                            <input type="text" 
                                                placeholder="Business Name" value={this.state.name}
                                                required onChange={this.handleNameChange}
                                                 />
                                        </label>
                                        <label>Category
                                            <select defaultValue="Automobile" value={this.state.category} name="Category" onChange={this.handleCategoryChange}>
                                
                                                <option >Cars</option>
                                                <option >Footwear</option>
                                            </select>
                                        </label>
                                        <label>Logo Url
                                            <input type="text" value={this.state.logoUrl}
                                                placeholder="https://storage.googleapis.com/spyne-website/static/app-dashboard/AddUser.png" 
                                                onChange={this.handleLogoChange}
                                                />
                                        </label>
                                        <label className={[styles['color-picker']]}> Color
                                            <div><span>Select Color</span>
                                                <input type="color" value={this.state.color}
                                                    placeholder="" 
                                                    onChange={this.handleColorChange}
                                                    />
                                            </div>
                                        </label>
                                    
                                        <div className={[styles[''],'text-center mt-3 col-md-12'].join(' ')}>
                                            <button type="submit">Create Enterprise</button>
                                        </div>
                                    </div>
                                </form>  
                            </div>
                        </div>
                    </div>
                </section>
            </div>
             
    ) 

}
 }
