import React, { Component } from 'react'

import styles from '../../Css/RealEstate/SkyUseCases.module.css'

import AutoHDR from './AutoHDR'
import AutoExposure from './AutoExposure'
import UnwantedObject from './UnwantedObject'
import SkyReplacement from './SkyReplacement'
import PerspectiveCorrection from './PerspectiveCorrection'
import QualityScore from './QualityScore'
import DayNightConversion from './DayNightConversion' 
import BlurrImageCorrection from './BlurrImageCorrection'

export default class SkyUseCases extends Component {
    constructor(props){
        super(props)
        this.state={
            useCaseId:'2',
            originalImage:this.props.originalImage
        }
    }
    handleUseCaseId = (e) => {
        e.preventDefault()
        this.setState({
            useCaseId:e.target.id
        })
    }
    render() {
        return (
            <div>
                <div className={[styles['tab-section'],''].join(' ')}>
                    <button id="1" onClick={this.handleUseCaseId} className={this.state.useCaseId == "1" ? styles['active-usecase-button']: null}>Auto HDR</button>
                    <button id="2" onClick={this.handleUseCaseId} className={this.state.useCaseId == "2" ? styles['active-usecase-button']: null}>Auto Exposure</button>
                    <button id="3" onClick={this.handleUseCaseId} className={this.state.useCaseId == "3" ? styles['active-usecase-button']: null}>Removal of unwanted objects</button>
                    <button id="4" onClick={this.handleUseCaseId} className={this.state.useCaseId == "4" ? styles['active-usecase-button']: null}>Sky Replacement</button>
                    <button id="5" onClick={this.handleUseCaseId} className={this.state.useCaseId == "5" ? styles['active-usecase-button']: null}>Perspective Correction</button>
                    <button id="6" onClick={this.handleUseCaseId} className={this.state.useCaseId == "6" ? styles['active-usecase-button']: null}>Image quality assessment - quality score</button>
                    <button id="7" onClick={this.handleUseCaseId} className={this.state.useCaseId == "7" ? styles['active-usecase-button']: null}>Day to night conversion</button>
                    <button id="8" onClick={this.handleUseCaseId} className={this.state.useCaseId == "8" ? styles['active-usecase-button']: null}>Blurr image correction</button>
                </div>
            

                {
                    this.state.useCaseId == '1' ?
                    <AutoHDR
                        originalImage={this.props.originalImage}
                        selectedFile={this.props.selectedFile}
                    />
                    :
                    this.state.useCaseId == '2' ?
                    <AutoExposure
                        originalImage={this.props.originalImage}
                        selectedFile={this.props.selectedFile}
                    />
                    :
                    this.state.useCaseId == '3' ?
                    <UnwantedObject
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '4' ?
                    <SkyReplacement
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '5' ?
                    <PerspectiveCorrection
                        originalImage={this.state.originalImage}
                    />
                    :
                    this.state.useCaseId == '6' ?
                    <QualityScore
                        originalImage={this.props.originalImage} 
                    />
                    :
                    this.state.useCaseId == '7' ?
                    <DayNightConversion
                        originalImage={this.props.originalImage}
                        selectedFile={this.props.selectedFile}
                    />
                    :
                    this.state.useCaseId == '8' ?
                    <BlurrImageCorrection
                        originalImage={this.props.originalImage} 
                    />
                    :
                    null
                }
            </div>
        )
    }
}
