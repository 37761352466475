import React, { Component } from 'react';
import styles from '../../Css/AppDashboard/Spinner.module.css'

class Spinner extends Component {
    state = {  }
    render() { 
        return ( 
            <div className={styles["spinner-background"]}>
                <div className={styles["spinner-box"]}>
                    <div className={styles["loading-icon"]}>
                    </div>
                    {/* <span>Preparing Zip...</span> */}
                </div>
            </div>
            
         );
    }
}
 
export default Spinner;