import React, { Component } from 'react'
import styles from '../../../Css/CarsThreeSixtyOutput.module.css'
import cls from '../../../Css/CarsBackgroundReplacement.module.css'



export default class CarsThreeSixtyOutput extends Component {

    uploadNewProject = () => {
          sessionStorage.clear()
          window.location.reload()
      }

    render() {
        return (
            <div>
                

                {/* Video upload status */}
                {/* <section className={[styles['create-three-sixty'],''].join(' ')}>
                    <div className={[styles[''],'container'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles['video-title'],'col-md-12 text-center'].join(' ')}>
                                <h2>Video upload status </h2> 
                            </div> 
                            <div className={[styles['video-area-right'],'col-md-8 offset-md-2 text-center'].join(' ')}>
                                <div className={[styles['video-area-simple'],'row m-0'].join(' ')}> 
                                    <h4>Trim your video</h4>
                                    <img src="https://storage.googleapis.com/spyne-website/360-video/video.png"/>
                                    <p>Note:  Trim the video from start and end to get the desired frames</p>
 
                                    <p><button className={[styles['no-reupload']]}>No, Reupload</button> <button>Continue</button></p>
                                </div>

                            </div>
                            

                        </div>
                    </div>
                </section> */}

                {/* Video upload status */}
               
                <section className={[styles['create-three-sixty'],''].join(' ')}>
                <div className={[styles['video-title'],'col-md-12 text-center'].join(' ')}>
                                <button class="" onClick={this.props.uploadNewProject}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" /> Upload New Video</button>
                            </div>
                {!this.props.threeSixtyUrl?
                    <div className={[styles[''],'container'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                             
                           
                            <div className={[styles['video-area-right'],'col-md-8 offset-md-2 text-center'].join(' ')}>
                                {this.props.videoUploaded ? 
                                    <div className={[styles['video-area-simple'],styles['video-gif'],'row m-0'].join(' ')}> 
                                        <p><b>Frame {this.props.framesCompleted}/{this.props.frames} processed<br/> Estimated Time Taken 02 mintues</b></p>
                                        <img src="https://storage.googleapis.com/spyne-website/360-video/load.gif"/>
                                        <p className={[styles['notification']]}>Note: If you close your current tab, the ongoing 360 video can be found in the dashboard.</p>
                                    </div>
                                    :
                                    <div className={[styles['video-area-simple'],styles['video-gif'],'row m-0'].join(' ')}> 
                                        <p><b>Video Uploading</b></p>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif"/>
                                            <p className={[styles['notification']]}>Note: Do not close the tab until video gets uploaded.</p>
                                    </div>
                                }
                            </div>
                            
                        </div>
                    </div>
                     :
                     <div className={[styles['i-frame-360']]}>
                         <h3>360 Exterior Image</h3>
                         <iframe  src={this.props.threeSixtyUrl}/>
                    </div>
                            }
                </section>
               
            </div>
        )
    }
}