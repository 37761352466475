import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import "react-toggle/style.css";
import InsufficientCreditModal from './InsufficientCreditModal';
import styles from '../../../Css/Model/DownloadclipprModal.module.css'
import Dropzone from 'react-dropzone'
export default class DownloadclipprModal extends Component {
    
    constructor(props){
        super(props)
        this.state={
            showInsuffCredModal:false,
            switch:false,
            
        }
    }

    componentDidMount = () => {}



    handleCloseModal = () => {
        this.setState({
            switch:false
        })
        this.props.hideModal()
        
    } 

    handleSwitch = async ()=>{
       if(this.props.availableCredit===0)
       {
            this.setState({
                showInsuffCredModal:true
            })
       }
       else
       {
        this.setState({
            switch:true 
        })
        await this.props.handleActivateButton("Hd")
        await  this.props.handleModal()
       }

    }

    hideInsufficientCreditModal=()=>{
        this.setState({
            showInsuffCredModal:!this.state.showInsuffCredModal
        })
    }
    

    fileHandler = (acceptedFiles) => {
       
        this.props.fileSelectedHandler(acceptedFiles)
        

    } 


    render(){

        return(
            <>
            <Modal
                show={this.props.clickDownload }
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                >
                <Modal.Header />
                {
                    (this.props.downloadType=="HD" || this.props.downloadType=="Hd" || this.state.switch)?
                    //this is for HD download
                    <Modal.Body>

                        <div className={[styles[''],'container-fluid'].join(' ')}> 
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles['hd-download-popup'],'col-md-12 text-center'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/Successful.gif"/>
                                    <h4>Congratulations !! </h4>
                                    <p>Your HD images is successfully Downloaded</p>
                                    <button onClick={event =>  window.location.href='https://www.spyne.ai/tech/footwear/try'}>Add new image</button>
                                </div>
                            </div>
                            

                        </div>
                    </Modal.Body>
                    :
                    // this is for Basic download
                    <Modal.Body>
                        <div className={[styles[''],'container-fluid'].join(' ')}> 
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles['shoe-image'],'col-md-6'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/pinkshoe.png"/>
                                </div>
                                <div className={[styles['hd-download-popup'],'col-md-6 pt-3 text-center'].join(' ')}>
                                    <h4>Download Completed! </h4>
                                    <p>Remaining Credits: <span>{this.props.availableCredit}</span></p>
                                    {/* <button onClick={event =>  window.location.href='http://localhost:3000/tech/clippr'}className={styles['download-btn']}>Add new image</button> */}
                                    <div className={[styles['upload-top-btn'],'row'].join(' ')}>
                                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                            {({getRootProps, getInputProps}) => (
                                                <section className={[styles['upload-btn'],'col-md-12 text-center'].join(' ')}>
                                                <div {...getRootProps()}  className={[styles['screen-2-drop'],''].join(' ')}>
                                                    <span>Add new image</span>
                                                    
                                                    <input  onChange={acceptedFiles => this.fileHandler(acceptedFiles)} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>
                                
                            </div>
                            <div className={[styles['basic-download-popup'],'row'].join(' ')}>
                                <div className={[styles['basic-text'],'col-md-6'].join(' ')}>
                                    <h5>Download high quality images at just 1 Credits</h5>
                                    <button onClick={this.handleSwitch} className={styles['download-btn']}>Download HD</button>
                                </div>
                                <div className={[styles[''],'col-md-4 offset-md-2'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/whiteshoe.png"/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    
                }
            </Modal>
            <InsufficientCreditModal
                showInsufficientCreditModal={this.state.showInsuffCredModal}
                hideThisModal={this.hideInsufficientCreditModal}
            /> 
            </>
            
        )
       
    }
}