import React, { Component } from 'react'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'
import BackgroundListModalBulk from '../../Common/Modal/BackgroundListModalBulk'
import ProjectNameModal from '../../Common/Modal/ProjectNameModal';
import Dropzone from 'react-dropzone'
import CarsThreeSixtyOutput from './CarsThreeSixtyOutput';



// import Logo from './Logo';
export default class CarsThreeSixtyAfterUpload extends Component {
    constructor(props){
        super(props)
        this.state={
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
        }
    }
    

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }

      fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }

      creditVerify = () => {
    }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }

      playVideo = () => {
        var video = document.getElementsByClassName('video')[0]
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.props.start,
            end: this.props.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
              seekToStart: document.getElementsByClassName('seek-start')[0],
              seekToEnd: document.getElementsByClassName('seek-end')[0],
              reset: document.getElementsByClassName('reset-control')[0]
            }
          }
        if (player.video.paused) {
          player.video.play();
          player.controls.play.classList.remove('fa-play');
          player.controls.play.classList.add('fa-pause');
          console.log('play video');
        } else {
          this.pauseVideo();
        }
        if (player.video.currentTime >= player.end) {
          player.video.currentTime = player.start;
          player.controls.play.classList.remove('fa-play');
          player.controls.play.classList.add('fa-pause');
          console.log('start from beginning');
        }
      };

      pauseVideo =  () => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.props.start,
            end: this.props.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
            }
          }
        var video = document.getElementsByClassName('video')[0]
        player.video.pause();
        player.controls.play.classList.remove('fa-pause');
        player.controls.play.classList.add('fa-play');
        console.log('pause video');
        video.classList.remove('paused');
      };

      videoLoadData = () => {
        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.props.start,
            end: this.props.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
            }
        };
        player.end = player.video.duration;
        
      }

      videoTimeUpdate = () => {

        var player = {
            video: document.getElementsByClassName('video')[0],
            start: this.props.start,
            end: this.props.end,
            controls: {
              play: document.getElementsByClassName('play-control')[0],
            }
        };
        if (player.video.currentTime >= player.end) {
          this.pauseVideo();
        } 
      }

      uploadNewProject = () => {
          sessionStorage.clear()
          window.location.reload()
      }


    render() {
       
        const backLeft = this.props.planData.length - 8
        return (
            <div>
                <section className={[styles['perfect-output'],''].join(' ')}>
                 
                    <div className={[styles[''],''].join(' ')}>
                        {this.props.uploadVideoStarted ?
                            <CarsThreeSixtyOutput
                                videoUploaded={this.props.videoUploaded}
                                uploadNewProject={this.uploadNewProject}
                                threeSixtyUrl={this.props.threeSixtyUrl}
                                framesCompleted={this.props.framesCompleted}
                                frames={this.props.frames}

                            />
                        :
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                
                                <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                    
                                                    <button className="" onClick={this.uploadNewProject}>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                         Upload New Project</button>
                                                </div>
                                                <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                                <div className="container">
                                    <div className={[styles['video-sec']]}>
                                        <video className="video" width="auto" id="yoo" autoload="metadata" onClick={this.playVideo} onTimeUpdate={this.videoTimeUpdate} onLoadedData={this.videoLoadData}>
                                            <source src={this.props.videoUrl} type="video/mp4" />
                                        </video>
                                        <div className="controls" style={{display:"block"}}>
                                            <div className="player-controls" >
                                                <button onClick={() => this.playVideo()} className="play-control fa fa-play" title="Play/Pause" style={{marginRight:"0px",height:"35px"}}></button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles[''],'col-md-8'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        <div className={[styles['right-top-credits'],'col-md-4'].join(' ')} >
                                            <a href="https://www.spyne.ai/tech/pricing"  >
                                            <button >
                                            {/* <img style={{"marginRight" : "4px"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add%20credits%20gif.gif"/> */}
                                            <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                            </a>
                                            </div>
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}>
                                        <h6 className={[styles['bg-title'],''].join(' ')} >Backgrounds </h6>
                                                     
                                        <div className={styles['backgrounds-grid']}>
                                                    {
                                                      this.props.planData.slice(0,8).map((e,i) => {
                                                        return (
                                                          <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                            <img alt="" src={e.imageUrl}/>
                                                            <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                              <p className={[styles['back-name'],'col-12 col-md-12'].join(' ')}>{e.bgName} - {e.imageId}</p>
                                                              
                                                            </div>
                                                          </div>

                                                        )
                                                      })
                                                    }
                                                    <div className={styles['inactive-background-sec']} >
                                                      <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                        <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                        <div className={styles['img-overlay']}></div>
                                                        <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                      </div>
                                                      
                                                    </div>
                                                </div>
                                                 
                                    </div>
                                   
                                       
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button onClick={this.props.uploadVideoApi}>Apply SpyneAI   </button>
                                    </div>
                                    
                                            
                                    <BackgroundListModalBulk 
                                            handleBackground = {this.handleBackground}
                                            background_id={this.props.background_id} 
                                            planData={this.props.planData} hideList={this.hideList} 
                                            showBackgroundList={this.state.showBackgroundList} 
                                            orgUrl={this.props.orgUrl}
                                            creditVerify = {this.creditVerify}/>
                                    
                                    <ProjectNameModal 
                                          showProjectNameModal = {this.props.showProjectNameModal}  
                                          handleProjectNameModal = {this.props.handleProjectNameModal}
                                          handleProjectName={this.props.handleProjectName}
                                          project_name={this.props.project_name}
                                          projectNameExists = {this.props.projectNameExists}
                                          />
                                </div>
                            </div>
                        </div>
                            
                        }
                        
                    </div>
                </section>
            </div>
        )
    }
}
