import React, { Component, useRef, useState  } from 'react'

import Toggle from 'react-toggle'
import Dropzone from 'react-dropzone'
import "react-toggle/style.css"
import ReactTooltip from 'react-tooltip';
// import Overlay from 'react-image-overlay'
import Axios from 'axios';
import { ProgressBar, Spinner, Modal } from "react-bootstrap";
import Styles from '../../Css/FootwearV2.module.css'
// import '../../../Css/cars.css'
import styles from '../../Css/CarsScreenTwo.module.css'
import {fetchCreditUserDetails,cookie,dealerLogoFileHandler} from '../Common/Utils'
import LoginModal from '../BackgroundRemoval/LoginModal';
import footwearOnlyStyles from '../../Css/FootwearScreenTwo.module.css'
// import DealershipLogo from '../Common/DealerShipLogo'

import { InlineWidget } from "react-calendly";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import BackgroundListModal from '../Common/Modal/BackgroundListModal'
import CreditModal from '../Common/Modal/CreditModal'
import DownloadCompleteModal from '../Common/Modal/DownloadCompleteModal'

// import ReactCompareImage from 'react-compare-image';

import { ReactCompareSlider, ReactCompareSliderImage,ReactCompareSliderHandle } from 'react-compare-slider'
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'



function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" ,    left: "-15px"}}
      onClick={onClick}
    />
  );
}
function SampleNextArrow_1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" ,right : "-20px"}}
      onClick={onClick}
    />
  );
}



export default class CarsBackground extends Component {
    constructor(props){
        super(props)
        this.state={
          backgroundsData:[], 
          availableCredit:5,
          angle:this.props.selectedAngle,
          angleSelected:this.props.imageSelected,
          loginStatus:false,
          show:false,
          showModal:false,
          email:window.localStorage.getItem("clippr_user_email"),
          otpScreen:false,
          otp:'',
          verification:false,
          autoLogin:false,
          toggleStatus:true,
          toggleChange:true,
          loginStatus:false,
          otpVerification:true,
          userId:'',
          availableCredit:null,
          creditId:"",
          loader:false,
          creditAlloted:null,
          creditUsed:null,
          spinner:false,
          emailValidation:true,
          logoUrl:'',
          below_bg:false,
          logoPosition:null,
          askemail:'',
          isAskEmail:true,
          showInstruction:false,
          overlays_shown:false,
          overlays_cat_clicked:"hb",
          overlays_icon_click:true,
          overlays_icon_click_src:"",
          // overlayiconclicked:false,
          overlays_toggle_show:false,
          carsModal: true,
          // overlays_toggle_show:true,
          active_category:"hb",
          active_overlay:"overlay_1",
          floorSelected: false,
          floor_img_url: '',
          autoplay:false,
          activButton: "Exterior",
          actiButton:"Background1",
          isDownload: false,
          showBackgroundList: false,
          creditModal: false,
          dropBackground: false,
          uploadWallUrl:"",
          visualSelection:"virtual_studio",
          applyWay:"",
          tempCustomWall:"",
          tempPosition:0,
          showDelPopup:false,
          delWall:false,
          wallIndex:"",
          new_width:0,
          left_wall_padding:0,
          new_height:0,
          themeColor:''
        }
        this.myDivToFocus = React.createRef()
        this.wallFile=React.createRef()
        // this.props.handledefwalls()
        this.orgImg = React.createRef()
        this.wallImg = React.createRef()

    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    componentDidMount = () => {
      let domain = window.location.hostname
      Axios({
          method: "GET",
          url : "https://www.clippr.ai/api/v2/enterprise/getByCode",
          params : {
              enterprise_code : domain,
          }
      }).then(resp => {
      
          document.documentElement.style.setProperty("--primaryColor",resp.data?.data?.color)
          this.setState({
              themeColor: resp.data.data.color,
          })
          
      })
  }
    handleBackgroundList = () => {
      this.setState({showBackgroundList: true})
    }

    hideList = () => {
      this.setState({showBackgroundList: false})
    }

    handleClick = (selectedAngle) => {
      this.setState({
        angle:selectedAngle,
        angleSelected:true
      })
      var date = new Date();
      var days = 1;
      date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000
      window.document.cookie = "selectedAngle" + "=" + selectedAngle + "; expires=" + date.toGMTString() + "; path=/";
      this.props.handleAngle(selectedAngle)
    }

    handleUploadClick = (event)=>{
      event.preventDefault()
      this.wallFile.current.click()

    }

    handleUpload = () =>{
      // console.log(this.wallFile.current.files)
      this.wallfileHandler(this.wallFile.current.files)
    }


    handleModal = () => {
      let userEmail = cookie("emailId")
      let userId = cookie("userId")
      // Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedHighRes/')
      // .then((res) => {
      // })
      // .catch((err) => {
      // })

      // this.props.handleDownload()
        if(userEmail && userId !== 'NA'){
          if(this.props.availableCredit <= 0 && userEmail){
            this.setState({creditModal: true})
          }
          else{
            this.props.handleDownload()
          }
        }
        else{
          this.setState({
            show:false,
            showModal:false
        })
        }
    }
    handleCreditModal = () => {
      this.setState({creditModal:false})
   }
   redirectPricePage = () => {
    window.location.replace('https://www.spyne.ai/tech/pricing')
    this.setState({creditModal:false})
 }

    handleActivateButton = (name) => {
      this.setState({
          activateButton:name
      })
  }
    sendOTP = (e) => {
      e.preventDefault()
      let email = this.state.email
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(email.match(mailformat))
      {
          Axios({
              'method':'GET',
              "url":'https://www.spyne.ai/credit-user/register-vendor',
              "params":{
                  'emailId':this.state.email
              }
          })
          
          .then((res) => {
             this.setState({
                 otpScreen:true
             })
          })
          .catch((err) => {})
      }
      else
      {
          this.setState({
              emailValidation:false
          })
      }
     
  }
  verifyOTP = (e) => {
    e.preventDefault()
    Axios({
        'method':'GET',
        "url":'https://www.spyne.ai/credit-user/validate-otp',
        "params":{
            "emailId":this.state.email,
            'otp':this.state.otp
        }
    })
    .then((res) => {
        if(res.data.id == 200){
            this.setState({
                verification:res.data
            })
            document.cookie = `emailId=${this.state.email}; path=/`;
            window.location.reload(false)
        }
        else{
            this.setState({
                otpVerification:false
            })
        }
    })
    .catch((err) => {})
  }
  handleChange = (e) => {
    this.setState({
        [e.target.name]:e.target.value
    })
  }
  handleCloseModal = () => {
    this.setState({
        show:!this.state.show,
        showModal:false
    })
  }
  applyChange = (e) => {
    // window.scrollTo(this.myDivToFocus.current.offsetTop,0) 
    this.setState({
      isDownload: true,
      showBackgroundList: false
    })
    this.props.handleClick()
    // console.log(this.state.visualSelection)
    
  }
  handleAskEmail = (e) => {
    e.preventDefault()
    document.cookie = `emailId=${this.state.askemail}; path=/`;
    document.cookie = `userId=NA; path=/`;
    document.cookie = `isValidated=false; path=/`;
    this.setState({
      isAskEmail:false
    })
  }
  fileHandler = (acceptedFiles) => {
    this.setState({
      isAskEmail:true,
      dropBackground: false,
      isDownload: false
    })
    this.props.fileSelectedHandler(acceptedFiles)
  }
  // fileHandlerNew = (acceptedFiles) => {
  //   this.setState({
  //     isAskEmail:true
  //   })
  //   this.props.fileSelectedHandlerNew(acceptedFiles)
  // }
  

  handleUnshiftWalls = (data) =>{
    this.props.handleUnshiftWalls(data)
    if(this.state.visualSelection=="custom_walls"){
      this.props.customWallSet(data["wall_png_url"])
    }
  }

  wallfileHandler = (acceptedFiles) => {
      // console.log(acceptedFiles[0])
    
      //If user is uploading image with choose Image Button
      let enterprise_id = 'TaD1VC1Ko'
      const fd = new FormData();
      fd.append("image", acceptedFiles[0]);
      fd.append("optimization",false)
      let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
      Axios({
        'method':'POST',
        "url":'https://www.clippr.ai/api/upload',
        "params":{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"},
        "data":fd
      }).then((res)=>{
      
      const wallfd = new FormData();

      wallfd.append("wall_name",acceptedFiles[0]["name"].split('.')[0])
      wallfd.append("wall_png_url", res.data.image)
      wallfd.append("user_id",cookie("userId"))
      wallfd.append("enterprise_id",enterprise_id)
      Axios({
        'method':'POST',
        "url":'https://www.clippr.ai/api/wall-upload',
        "params":{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"},
        "data":wallfd
      }).then((wall_output)=>{
      this.handleUnshiftWalls(wall_output.data)
    })
    })
      
  }

  handleBackground = (imageId, imageCredit,below_bgs) => {
    this.setState({
      isAskEmail:true,
      below_bg:below_bgs
    })
    this.props.handleId(imageId,imageCredit,below_bgs)
  }






  overlayclicked = (data_overlay)  => {
    this.setState({
      active_category:data_overlay,
      overlays_shown:true,
      overlays_cat_clicked:data_overlay,
      overlays_icon_click:true,
      overlays_icon_click_src:(`https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/${data_overlay}_w/36_${data_overlay}_w.png`),
      active_overlay:"overlay_0",
      overlays_toggle_show:true,
    })
    // console.log(this.state.overlays_icon_click_src)
    // console.log(this.state.overlays_icon_click)


    // this.overlays_button()
    
  }
  overlayiconclicked = (overlay_src, overlay_index)  => {
    // alert(overlay_src)
    this.setState({
      overlays_icon_click:true,
      overlays_toggle_show:true,
      overlays_icon_click_src:overlay_src,
      active_overlay:overlay_index,

    })
    // alert(this.state.overlays_icon_click_src)

  }

  floorClicked = (floor_src) => {
    this.setState({
      floorSelected: true,
      floor_img_url: floor_src,
    })
    // console.log(this.state.floor_img_url)
  }

  componentDidMount = () => {
    let userEmail = cookie("emailId")
    let userId = cookie("userId")
    fetchCreditUserDetails(userId)
    .then((creditResponse) => {
      console.log(creditResponse.data)
    })
    this.overlayiconclicked(`https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/all_overlays_low_res/hb_w/36_hb_w.png`, "overlay_0")
    interact('.resizable')
      .resizable({
        edges: { top: false, left: false, bottom: true, right: false },
        listeners: {
          move :(event) =>{
            let { x, y } = event.target.dataset

            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top

            if (event.rect.height>this.state.container_height){
              // console.log(387)
              event.rect.height=this.state.container_height
            }
            if (event.rect.height<20){
              // console.log(387)
              event.rect.height=20
            }
            let pos= (event.rect.height/this.state.container_height)*100
            this.handleSetPosition(pos,event.rect.height)
            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${x}px, ${y}px)`
            })

            Object.assign(event.target.dataset, { x, y })
          }
        }
      })
  }

  handleOverlayToggle = () =>{
    this.setState({
      overlays_icon_click:!this.state.overlays_icon_click
    })
  }
  

  hideCarsModal = () => {
    this.setState({carsModal:!this.state.carsModal})
  }

  handleFloorClick = (e,newUrl) => {
    e.preventDefault()
    this.floorClicked(newUrl)
  }
  handlewalls = (wall_selected) => {
    // console.log(wall_selected)
    this.props.handlewalls(wall_selected)
  }
  customWallSets= (wall_selected) => {
    // console.log(wall_selected)
    this.setState({
      tempCustomWall:wall_selected,
      showBackgroundList: false

    })
  // console.log(this.props.wall)
  this.props.customWallSet(wall_selected)
  }


  downloadPopup = () => {
    this.setState({
      isDownload: false
    })
    this.props.handleToggle()
    
  }

  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }

  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }

  handleVisualSelection =(selection)=>{
    this.setState({
      visualSelection:selection,
      applyWay:""
    })
    this.props.handleTabSelection(selection)
    if(selection=="custom_walls"){
      this.handleSliderwidth()
    }
}
handleToggleImage=()=>{
  this.props.handleToggle()
  if (this.props.toggleStatus==false && this.props.editedUrl !=""){
    this.setState({
      isDownload:true
    })
  }
  else if (this.props.toggleStatus==true){
    this.setState({
      isDownload:false
    })
  }
  
}
handleSetPosition = (position, wall_height) => {
  this.props.handlePositionChange(position)
  
  this.setState({
    tempPosition:position,
    wallHeight:wall_height
  })
  // console.log("position"+ position)
}

handleSliderwidth = () => {
  let contW=document.getElementById("slider_div").clientWidth;
  let contH=document.getElementById("slider_div").clientHeight;
  // let cont_aspRat=contW/contH;
  // var img_aspRat=0;
  var myImg = document.querySelector("#orgImg_2");
  var imgW = myImg.naturalWidth;
  var imgH = myImg.naturalHeight;
  // var imgW=document.getElementById("orgImg").width
  // var imgH=document.getElementById("wallImg").height
  // console.log("new")
  // console.log(imgW)
  // console.log(imgH)

  // img_aspRat=imgW/imgH;
  var ratio_diff= contH/imgH;
  var new_imgW=imgW*ratio_diff;
  var new_imgH=imgH*ratio_diff;
  var new_wall_width=(new_imgW/contW)*100
  new_wall_width=String(new_wall_width)+"%"
  var left_padding=String((contW-new_imgW)/2)+"px"
  // console.log(left_padding)

  this.setState({
    new_width:new_wall_width,
    left_wall_padding:left_padding,
    container_height:contH,
    container_width:contW
  })

  // var crop_height= (contH*position)/100;
  // var height_diff=(new_imgH-contH)/2;

  // var new_crop_height=crop_height+height_diff;

  // var new_position=(new_crop_height/new_imgH)*100  + 0.15;

  // this.props.handlePositionChange(new_position)
  // this.setState({
  //   tempPosition:position
  // })
}

partialapply =(partialApplyWay)=>{
  this.setState({
    applyWay:partialApplyWay,
    // below_bg:false
  })
  if(partialApplyWay=="manually"){
    if (this.state.tempCustomWall.length>0){
      this.props.customWallSet(this.state.tempCustomWall)
    }
    else{
      this.props.customWallSet(this.props.wallData[0]["wall_png_url"])
    } 
  }
  if(partialApplyWay=="magically"){
    this.props.handlePositionChange(0)
  }
  // console.log(this.props.wallData[0]["wall_png_url"])
}
handleCloseModal = () => {
  if(this.state.flagOTP) {
      this.props.hideModal()
  }
}

showInstModal=()=>{
    this.setState({
      showInstruction:true
  })
  
}
handleCarsFavList =(i)=>{
  
  this.props.handlefavList(i)
}
handleCarsBgList =(i)=>{
  
  this.props.handleBgList(i)
}
delWallapi=()=>{
  // const fd = new FormData();
  let enterprise_id = 'TaD1VC1Ko'
  let wall=this.props.wallData[this.state.wallIndex]
  let wall_id=wall["wall_id"]
  const wallfd = new FormData();
  
  wallfd.append("wall_Id", wall_id);
  wallfd.append("user_id",cookie("userId"))
  wallfd.append("enterprise_id",enterprise_id)
  // wallfd.append("api_key","fde46c58-5735-4fcf-8b38-980c95001dc3")
  // let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
  Axios({
    'method':'POST',
    "url":'https://www.clippr.ai/api/wall-delete',
    "params":{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"},
    "data":wallfd
  }).then((res)=>{
    if (res.data.status_code==200)
    {
      this.props.wallData.splice(this.state.wallIndex,1)
    }
    this.setState({
      wallIndex:0
    })
  })
}
delWallShowModal=(i)=>{
  this.setState({
    delWall:true,
    wallIndex:i
  })
  // this.props.wallData.splice(i,1)
}
delWallModal=()=>{
  this.setState({
    delWall:false
  })
  this.delWallapi()
}
delWallModalHide=()=>{
  this.setState({
    delWall:false
  })
}
modalOnHide=()=>{
  this.setState({
    showInstruction:false
  })
}
fileHandlerNew = (acceptedFiles) => {
  // console.log(acceptedFiles.target)
  this.props.multipleFileUploadProject(acceptedFiles)
}



    render() {
      
        const editedImage = this.props.backgroundUrl.map((newUrl) => {
            return <div className={[styles['perfect-output-images'],'col-md-4'].join(' ')}>
                      <div onClick={()=> this.floorClicked(newUrl)}>
                        <img src={newUrl} alt=""/>
                      </div>
                      {/* <p>Slate</p> */}
                  </div>
          })
      const settings = {
        // className: "center",
        // dots: true,
        infinite: false,
        slidesToShow:4,
        slidesToScroll: 1,
        // centePadding:"1px",
        // vertical: true,
        // verticalSwiping: true,
        swipeToSlide: true,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 7000
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
      const settings_2 = {
        // className: "center",
        // dots: true,
        infinite: false,
        slidesToShow:5,
        slidesToScroll: 1,
        // centePadding:"1px",
        // vertical: true,
        // verticalSwiping: true,
        swipeToSlide: true,
        autoplay: false,
        speed: 500,
        // autoplaySpeed: 7000
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ],
        nextArrow: <SampleNextArrow_1 />,
        prevArrow: <SamplePrevArrow_1 />
      }; 
      const backLeft = this.props.planData.length - 5
      const spyne_backLeft = this.props.bg_list.length - 2
      const fav_backLeft = this.props.fav_list.length - 2
      const customWallLeft=this.props.wallData.length - 6

        return (
          <>
            <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
              {({getRootProps, getInputProps}) => (
                  <section>
                      <div {...getRootProps({
                        onClick: event => event.stopPropagation()
                      })} className={footwearOnlyStyles["screen-2-drop-car"]} >
                          {this.state.dropBackground ?
                            <section className={[styles['drop-box-text']]}>
                              <div>Drop Image Anywhere</div>  
                            
                            </section>
                            : null
                          }
                          
                          <section className={[styles['perfect-output'],''].join(' ')}>
                              <div className={[styles['upload-sec'],"container"].join(" ")}>
                                  
                                  <div className={[styles['dropzone-area'],'row'].join(' ')}>
                                        <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                              {({getRootProps, getInputProps}) => (
                                                  <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                      <div {...getRootProps({})} className={styles['dropzone1']}>
                                                          <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                              
                                                              <button className="">
                                                                  <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                                  Upload New Project</button>
                                                          </div>
                                                          <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                      
                                                      </div>
                                                  </section>
                                              )}
                                        </Dropzone>
                                       
                                    </div>
                                </div>
                              <div className={[styles['car-try-inner'],""].join(" ")}>
                                  <div className={[styles['angle-sec']]}>
                                    <div className="container-fluid">
                                      <div className={[styles['top-bar'],"row"].join(" ")}>
                                        <div className={[styles['car-mobile-angle'],"col-md-7 col-12"].join(" ")}>
                                            <div className="row">
                                                <div className="col-8 col-md-6" >
                                                How to Shoot your car?&nbsp;
                                                <span style={{color:"#214594", textDecoration :"underline", cursor:"pointer"}} onClick={()=>this.showInstModal()}>
                                                 Click Here</span> 
                                                </div>
                                            </div>
                                            
                                        
                                        </div>
                                      
                                      <div className={[styles['credit-text'],"col-md-5 text-right"].join(" ")}>
                                       <div className={[styles[''],"row"].join(" ")} >
                                        <div className={[styles['wallet-icon'],"col-md-6"].join(" ")} >
                                            <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M40.2146 9.79051C39.704 9.36483 39.0364 9.08105 38.2902 9.08105C38.3295 9.08105 38.3688 9.08105 38.408 9.08105V5.42735C38.408 3.93749 37.7404 2.58952 36.6408 1.59628C35.5412 0.603038 34.0489 0 32.3994 0H6.00862C4.3592 0 2.86686 0.603038 1.76724 1.59628C0.667625 2.58952 0 3.93749 0 5.42735V23.7668C0 25.2567 0.667625 26.6046 1.76724 27.5979C2.86686 28.5911 4.3592 29.1942 6.00862 29.1942H32.3994C34.0489 29.1942 35.5412 28.5911 36.6408 27.5979C37.7404 26.6046 38.408 25.2567 38.408 23.7668V20.0776C38.3688 20.0776 38.3295 20.0776 38.2902 20.0776C39.0364 20.0776 39.704 19.7938 40.2146 19.3682C40.6858 18.9425 41 18.304 41 17.63V11.5287C41 10.8547 40.6858 10.2517 40.2146 9.79051ZM36.2481 23.7313C36.2481 24.6891 35.8161 25.5404 35.1092 26.179C34.4023 26.8175 33.4598 27.2077 32.3994 27.2077H6.00862C4.94828 27.2077 4.00575 26.8175 3.29885 26.179C2.59195 25.5404 2.15996 24.6891 2.15996 23.7313V5.42735C2.15996 4.46958 2.59195 3.61823 3.29885 2.97972C4.00575 2.34121 4.94828 1.95101 6.00862 1.95101H32.3994C33.4598 1.95101 34.4023 2.34121 35.1092 2.97972C35.8161 3.61823 36.2481 4.46958 36.2481 5.42735V9.08105H24.0738C22.4243 9.08105 20.9713 9.68409 19.8716 10.6419C18.8113 11.5996 18.1437 12.9476 18.1437 14.4374V14.6858C18.1437 16.1756 18.8113 17.4881 19.8716 18.4814C20.932 19.4391 22.4243 20.0422 24.0738 20.0422H36.2481V23.7313ZM38.84 17.63C38.84 17.7719 38.7615 17.9138 38.6829 17.9847C38.5651 18.0912 38.4473 18.1266 38.2902 18.1266H24.0738C23.0527 18.1266 22.0709 17.7364 21.4033 17.1334C20.7356 16.5303 20.3036 15.6435 20.3036 14.7212V14.4729C20.3036 13.5152 20.7356 12.6638 21.4033 12.0608C22.1102 11.4223 23.0527 11.0675 24.0738 11.0675H38.2902C38.4473 11.0675 38.6044 11.1385 38.6829 11.2094C38.8008 11.3158 38.84 11.4223 38.84 11.5641V17.63Z" 
                                                fill={this.state.themeColor}/>
                                            </svg>

                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        <div className={[styles['right-top-credits'],"col-md-6"].join(" ")}>
                                          <a href="https://www.spyne.ai/tech/pricing"  >
                                          <button >
                                          
                                          <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={[styles['lower-section-with-overlay-2'],''].join(' ')} >
                                      
                                      <div className={[styles['left-section-with-image'],''].join(' ')} >
                                      
                                        <div className={[styles['with-image-inner'],''].join(' ')}>
                                        
                                          { 
                                            !this.props.spinner && this.props.editedUrl !== "" && this.props.orgUrl !== "" && this.props.toggleChange ?(
                                              <img src={this.props.editedUrl} ></img>                                              
                                            )
                                            :
                                            this.props.spinner && this.props.orgUrl !== "" ?
                                            <div  className={[styles['image-box-area'],'d-flex'].join(' ')}>
                                              <video autoPlay>
                                                <source src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/ezgif.com-gif-maker.mp4"></source>
                                              </video>
                                              
                                            </div>
                                            :
                                            !this.props.orgUrl == "" && !this.props.toggleChange ?(
                                              <div  id="slider_div" className={styles['original_image']} >
                                                {
                                                this.state.visualSelection=="custom_walls"?
                                                // object-fit: contain;
                                                // style={{"object-fit": "contain" }}
                                                (
                                                  this.state.applyWay=="manually" ?(
                                                //   <ReactCompareSlider id="sliderId"
                                                //   handle={<ReactCompareSliderHandle id="handler" buttonStyle={{backdropFilter: undefined, border: "2px solid white", boxShadow: undefined, color: 'white',transform: "rotateZ(90deg)",cursor: "n-resize"}} linesStyle={{opacity: 0.5}}/>}
                                                //   itemOne={<ReactCompareSliderImage id="wallImg" ref={this.wallImg} alt="one" src={this.props.customWall} style={{opacity: 0.6 ,"object-fit": "inherit"}}/>}
                                                //   itemTwo={<ReactCompareSliderImage id="orgImg_2" ref={this.orgImg}  alt="two" src={this.props.orgUrl}
                                                //   style={{"object-fit": "contain" }} />}
                                                //   onPositionChange={this.handleSetPosition}
                                                //   onlyHandleDraggable
                                                //   position={this.state.tempPosition==0?30:this.state.tempPosition}
                                                //   style={{
                                                //     // "object-position": "center center",
                                                //     height: '100%',
                                                //     width: this.state.new_width?this.state.new_width:"100%",
                                                //     left:this.state.left_wall_padding
                                                //   }}
                                                //   portrait={true}
                                                // /> 
                                                <div>
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}  />
                                                  <img alt="" className={[styles['resizable'],"resizable"].join(" ")} id="wallImg" src={this.props.customWall} 
                                                  style={{position:"relative", width:this.state.new_width, left:this.state.left_wall_padding,
                                                  height:this.state.new_height?this.state.new_height:80  }}
                                                  />
                                                  {/* <img alt="drag button" className={[styles['drag-button'],""].join(" ")} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/image%202.png"
                                                  style={{position:"relative", left:0 ,top:0}}/> */}


                                                </div>
                                               
                                                ):
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}  />
                                                  )
                                                :
                                                  <img alt="" id="orgImg_2" src={this.props.orgUrl}  />
                                                
                                              
                                              } 
                                              </div>
                                            )
                                            :
                                            null
                                          } 
                                          
                                        </div>
                                        <div className={[styles['toggle-section'], "sec-2-toggle"].join(' ')}>
                                            <p className="mr-5 mt-2">Original</p>
                                            <label className="mt-2">
                                                <Toggle onChange={this.handleToggleImage} icons={false} checked={this.props.toggleStatus} />
                                            </label>
                                            <p className="ml-5 mt-2">Edited</p>
                                        </div>
                                        {/* {
                                          this.props.editedUrl !== "" && this.state.visualSelection=="virtual_studio"?(
                                        <div className={[styles["bg-complete-sec"],''].join(' ')} >
                                          <div className={[styles['output-content'], "col-md-12"].join(' ')}>
                                            <h4>Hey! if output is not coming out well, <span style={{color:"#FF7700",   fontFamily: "Poppins"}} >Try other backgrounds</span></h4>
                                          </div>
                                          <div className={[styles['bg-below-sec'], "row"].join(' ')}>
                                            <div className={[styles['select-sec'],'col-md-4'].join(' ')}>
                                              <span>Select</span>
                                              <img className={styles["select-img"]} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/bg_under.png"/>
                                            </div>
                                              {
                                                this.props.planData.map((e,i) => {
                                                  // console.log(e.imageId)
                                                  {
                                                  if (e.imageId==501 || e.imageId==806) {
                                                  return (
                                                    <div className={[styles['active-background-sec-below'],'col-md-2 col-6'].join(' ')}  onClick={()=>this.handleBackground(e.imageId,e.imageCredit,true)}>
                                                      <img alt="" style={this.props.background_id == e.imageId && this.state.below_bg==true ?{border:"2px solid"}:null } src={e.imageUrl}/>
                                                    </div>
                                                  )}
                                                  }
                                                })
                                              }
                                              
                                           
                                            <div className={[styles['output-apply-btn'], "col-md-4 col-12"].join(' ')}>
                                              <button  onClick={this.applyChange} className={styles['below-bg-apply']}>Apply</button>
                                            </div>
                                            </div>
                                        </div>)
                                        :
                                        null
                                        } */}
                                        <div className={[styles['mobile-uploads'],'col-md-12 d-md-none d-block'].join(' ')}>
                                            <p><span>Car Image</span><button  onClick={() => this.fileInput.click()} className={[styles['upload-sm-img']]}> Upload Image</button></p>
                                        </div>


                                        <DownloadCompleteModal 
                                            showDownloadModal={this.props.downloadComplete}
                                            editedUrl={this.props.editedUrl}
                                            handleDownloadComplete={this.props.handleDownloadComplete}
                                            orgUrl={this.props.orgUrl}
                                          />
                                      </div>

                                      {/* MobileView (UploadLogo & UploadImage)  */}
                                          <div className={[styles['mobile-uploads'],'col-md-12 d-md-none d-block'].join(' ')}>
                                              <p><span>Logo</span> <button className={[styles['logo-upload'],styles['upload-sm-img'],''].join(' ')} disabled>Upload Logo</button>
                                              </p>
                                          </div>

                                      {
                                        this.state.isDownload ?
                                        <div className={[styles['download-popup-area'],'container-fluid'].join(' ')}>
                                            
                                            <div className={[styles['download-popup'],'row'].join(' ')}>
                                                <div className={[styles['cross-btn'],''].join(' ')}>
                                                  <img onClick={this.downloadPopup} className={styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                                </div>
                                                <button className={this.props.activateButton == "Hd" ? [styles['download-hd'],styles['activate'],'col-md-12 '].join(' ') : null} onClick={() => this.props.handleActivateButton("Hd")}>
                                                    <h6><input type="radio" checked={this.props.activateButton == "Hd"}/>HD Download</h6>
                                                    <ul>
                                                        <li>Full Image 1920 × 1080 </li>
                                                        <li>Without Watermark</li>
                                                    </ul>
                                                    <p>Price : <span>{this.props.bgCredit} Credit/Image</span></p>
                                                </button>
                                               <div className={[styles['border-1'],''].join(' ')}></div>
                                                <button className={this.props.activateButton == "Basic" ? [styles['download-hd'],styles['activate'],'col-md-12'].join(' ') : null} onClick={() => this.props.handleActivateButton("Basic")}>
                                                    <h6><input type="radio" checked={this.props.activateButton == "Basic"}/>Basic Download</h6>
                                                    <ul>
                                                        <li>Preview Image 640 × 360 </li>
                                                        <li>With Watermark</li>
                                                    </ul>
                                                    <p>Price : <span>Free</span></p>
                                                </button>
                                                
                                                <div className="text-center">
                                                  <button onClick={this.handleModal}  className={[styles['download-btn-hd']]}>Download</button>
                                                </div>
                                                {/* <div className={styles['background-list-2']}>
                                                  <h6>Backgrounds ({this.props.planData.length})</h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.planData.slice(0,2).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit)}>
                                                                <img alt="" src={e.imageUrl}/>
                                                                <div className="row pl-0 pr-0">
                                                                  <p className={[styles['back-name'],'col-md-8 col-12'].join(' ')}>{e.bgName}</p>
                                                                  <p className={[styles['back-credit'],'col-4'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        <div className={ styles['inactive-background-sec']}>
                                                          <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                          </div>
                                                          
                                                          
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className={[styles['btn-sec-2']]}>
                                                  <button onClick={this.applyChange}>Apply</button>
                                                </div> */}
                                                <BackgroundListModal 
                                                    delWallShowModal={this.delWallShowModal}
                                                    handleBackground = {this.handleBackground}
                                                    background_id={this.props.background_id} 
                                                    planData={this.props.planData} hideList={this.hideList} 
                                                    showBackgroundList={this.state.showBackgroundList} 
                                                    orgUrl={this.props.orgUrl}
                                                    visualSelection={this.state.visualSelection}
                                                    floor={this.props.floor}
                                                    customWallSets={this.customWallSets}
                                                    customWallSet={this.props.customWallSet}
                                                    wallFile={this.wallFile}
                                                    handleUploadClick={this.handleUploadClick}
                                                    handleUpload={this.handleUpload}
                                                    handleUnshiftWalls={this.props.handleUnshiftWalls}
                                                    wallData={this.props.wallData}
                                                    bg_list={this.props.bg_list}
                                                    fav_list={this.props.fav_list}
                                                    handleCarsFavList={this.handleCarsFavList}
                                                    handleCarsBgList={this.handleCarsBgList}
                                                    applyChange={this.applyChange}/>

                                                    <CreditModal 
                                                    showCreditModal={this.state.creditModal}
                                                    handleCreditModal={this.handleCreditModal}
                                                    redirectPricePage={this.redirectPricePage}
                                                    editedUrl={this.props.editedUrl}
                                                    bgCredit={this.props.bgCredit}
                                                    />
                                            </div>
                                            
                                              
                                          </div>
                                        :
                                        <div className={[styles['right-section-with-image'],''].join(' ')}>
                                          <div className={[styles['right-top-selection']]}>
                                            
                                            <div className={this.state.visualSelection=="virtual_studio"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}}  onClick={(e)=>this.handleVisualSelection('virtual_studio')} >Virtual Studio</div>
                                            <div className={this.state.visualSelection=="custom_walls"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}} onClick={(e)=>this.handleVisualSelection('custom_walls')} >Custom Walls <sup className={styles['superscript']}>Beta</sup></div>

                                            
                                          </div>
                                            {/* <div className={[styles['right-top-section'],''].join(' ')} >
                                                <div className={[footwearOnlyStyles['upload-section-car'],"d-flex justify-content-center"].join(' ')}>
                                                  <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                  {({getRootProps, getInputProps}) => (
                                                      <section>
                                                          <div {...getRootProps({
                                                            onClick: event => event.stopPropagation()
                                                          })} className={footwearOnlyStyles["screen-2-drop-car"]}>
                                                              <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                              <p className="para-1">DROP IMAGE HERE </p>
                                                              <p class="para-2"><b>or</b> Browse For Image</p>
                                                              <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                          </div>
                                                      </section>
                                                  )}
                                                  </Dropzone>
                                                </div>
                                                
                                            </div> */}
                                            {
                                              this.state.visualSelection=="virtual_studio"?(
                                              <div>
                                                <div className={styles['background-list']}>
                                                    <h6><span className={styles['colored']}>Your Favourite</span> <span>Backgrounds</span></h6> 
                                                    {
                                                        this.props.fav_list.length==0?
                                                          <p className={[styles['favroute-add'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/wishlist.gif" /> 
                                                            <span>Click on heart tab to add as your favourite</span>
                                                          </p>
                                                      :
                                                      <div></div>

                                                    }
                                                    {/* Favourite Backgrounds */}

                                                    <div className={[styles['backgrounds-grid'],''].join(' ')}>
                                                        {
                                                          this.props.fav_list.slice(0,2).map((e,i) => {
                                                            // console.log(e)
                                                            return (
                                                              <div className={this.props.background_id == e.image_id && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.image_id,e.image_credit,false)}>
                                                                <img alt="" src={e.image_url}/>
                                                                <p onClick={()=>this.handleCarsFavList(i)} className={[styles['fav-icon'],''].join(' ')}>
                                                                    <svg width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" fill="#FF0000" stroke="#FF0000"/>
                                                                    </svg>

                                                                </p>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.background_name}</p>
                                                                  <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        {
                                                          this.props.fav_list.length>2?
                                                            <div className={styles['inactive-background-sec']} >
                                                              <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                                <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay']}>+ {fav_backLeft} other <span>favourites</span></div>
                                                              </div>
                                                            </div>
                                                        :null}
                                                    </div>  
                                                </div>
                                                
                                                 {/* Spyne Backgrounds */}

                                                
                                                <div className={styles['background-list']}>
                                                    <h6><span className={styles['colored']}>Spyne’s</span> <span>Backgrounds</span> </h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.bg_list.slice(0,2).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.image_id && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.image_id,e.image_credit,false)}>
                                                                <img alt="" src={e.image_url}/>
                                                                <p  className={[styles['fav-icon'],''].join(' ')}>
                                                                    <svg className={styles['white-icon']} width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" fill="#ffffff" stroke="#ffffff"/>
                                                                    </svg> 
                                                                    <svg  className={styles['hoverable-icon']} width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.8218 1.1689C9.81275 0.295513 8.27816 0.428326 7.30583 1.44751L7.30561 1.44774L6.86165 1.91248L6.50011 2.29095L6.13856 1.91248L5.69461 1.44774L5.69404 1.44715C4.72427 0.42862 3.18766 0.295342 2.17843 1.1689C0.982641 2.20585 0.91689 4.07849 1.9898 5.20554L10.8218 1.1689ZM10.8218 1.1689C12.0176 2.20587 12.0833 4.07845 11.0083 5.20543L11.0082 5.20554L6.64761 9.77953C6.64758 9.77956 6.64755 9.7796 6.64752 9.77963C6.56285 9.8683 6.43512 9.8683 6.35045 9.77963C6.35042 9.7796 6.35039 9.77956 6.35035 9.77953L1.99001 5.20576L10.8218 1.1689Z" fill="white" stroke="#FF0000"/>
                                                                    </svg>
                                                                </p>
                                                                <p onClick={()=>this.handleCarsBgList(i)} className={[styles['fav-icon'],''].join(' ')}>
                                                                    <svg className={styles['white-icon']} width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" fill="#ffffff" stroke="#ffffff"/>
                                                                    </svg>
                                                                    
                                                                    <svg className={styles['hoverable-icon']} width="15" height="13" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M6.57489 1.35802L6.57512 1.35779C7.54745 0.338605 9.08204 0.205791 10.0911 1.07918C11.2869 2.11615 11.3526 3.98873 10.2775 5.11571L10.2774 5.11582L5.9169 9.68981C5.8322 9.77861 5.70434 9.77861 5.61964 9.68981L1.2593 5.11603C0.186169 3.98901 0.251851 2.11619 1.44771 1.07918C2.45695 0.205621 3.99356 0.338898 4.96333 1.35743L4.9639 1.35802L5.40785 1.82276L5.7694 2.20123L6.13094 1.82276L6.57489 1.35802Z" fill="white" stroke="#FF0000"/>
                                                                    </svg>
                                                                </p>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.background_name}</p>
                                                                  <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.image_credit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                        
                                                        {
                                                          this.props.bg_list.length>2?
                                                            <div className={styles['inactive-background-sec']} >
                                                              <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                                <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay']}>+ {spyne_backLeft} More</div>
                                                              </div>
                                                            </div>
                                                          :null}
                                                    </div>  
                                                </div>

                                                {/* <div className={styles['background-list']}>
                                                    <h6>Backgrounds </h6> 
                                                    <div className={styles['backgrounds-grid']}>
                                                        {
                                                          this.props.planData.slice(0,2).map((e,i) => {
                                                            return (
                                                              <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                                <img alt="" src={e.imageUrl}/>
                                                                <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                                  <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.bgName}</p>
                                                                  <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.imageCredit}
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                    <span></span></p>
                                                                </div>
                                                              </div>
                                                              

                                                            //   <div className={this.props.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit)}>
                                                            //   <img alt="" src={e.imageUrl}/>
                                                            //   <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                            //     <p className={[styles['back-name'],'col-8'].join(' ')}>{e.bgName}</p>
                                                            //     <p className={[styles['back-credit'],'col-4 d-md-block d-none'].join(' ')}>{e.imageCredit}
                                                            //       <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                            //       <span></span></p>
                                                            //   </div>
                                                            // </div>





                                                            )
                                                          })
                                                        }
                                                        <div className={styles['inactive-background-sec']} >
                                                          <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                          </div> */}
                                                          
                                                          {/* <div className="row pl-0 pr-0">
                                                            <p className={[styles['back-name'],'col-6'].join(' ')}>Radiant Slate</p>
                                                            <p className={[styles['back-credit'],'col-6'].join(' ')}>10
                                                              <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                              <span></span></p>
                                                          </div> */}
                                                        {/* </div>
                                                    </div>  
                                                </div> */}
                                             {/* //Backgrounds */}


                                                {/* <div className={styles['logo-sec']}>
                                                  <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                                      <DealershipLogo   
                                                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                                        handleLogoPosition = {this.props.handleLogoPosition}
                                                        logoUrl = {this.props.logoUrl}
                                                        logoPosition = {this.props.logoPosition}
                                                        hideLogo={this.props.hideLogo}
                                                        handleLogo={this.props.handleLogo}
                                                      />
                                                  </div>
                                                    
                                                </div> */}
                                                <div className={styles['checkbox-section']}>
                                                  <div className="container-fluid">
                                                  {/* this.state.isDownload  */}
                                                    <div className="row">
                                                        <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                          
                                                          <label className={styles['container-2']}>Color Enhancement
                                                            <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement}/>
                                                            <span className={styles['checkmark']}></span>
                                                          </label>
                                                        </div>
                                                        {/* <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                          
                                                          <label className={styles['container-2']}>Window Transparency
                                                            <input type="checkbox" onChange={this.props.handleWindowTransparency} checked={this.props.checkWindowTransparency}/>
                                                            <span className={styles['checkmark']} style={{left: "5%"}}></span>
                                                          </label>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                
                                                  <div className={[styles['btn-sec']]}>
                                                    <p className={[styles['rmaining'],'d-md-none d-block'].join(' ')}>Available Credits:&nbsp; <span>{this.props.availableCredit}</span>
                                                    </p>
                                                    
                                                    <button onClick={this.applyChange}>Apply</button>
                                                  </div>

                                                  
                                                
                                              </div>
                                              ):
                                              <div>
                                                  <div className={[styles['custom-walls-area'],''].join(' ')}>
                                                    <div className={this.props.customWall.length>0?[styles['custom-wall'],''].join(' '):[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>1</span> Choose a Wall </p>
                                                    </div>
                                                    <div className={[styles['background-list'],styles['custom-background-list'],''].join(' ')}>
                                                      <div className={styles['backgrounds-grid-custom']}>
                                                          <div className={styles['inactive-background-sec']}  >
                                                          <input type="file"  className="d-none" ref={this.wallFile} onChange={(e)=>this.handleUpload(e)}></input>

                                                            <img src="https://storage.googleapis.com/spyne-website/whitelabel/custom.png"  onClick={(e)=>this.handleUploadClick(e)}  ></img>
                                                            <span>Custom</span>
                                                            </div>
                                                          
                                                          {
                                                            
                                                            this.props.wallData.slice(0,6).map((e,i)=>{
                                                              
                                                              return(
                                                                // <span>test</span>
                                                                <div className={this.props.customWall==e["wall_png_url"]?styles['active-walls-sec']: styles['inactive-walls-sec']} onClick={()=>this.customWallSets(e["wall_png_url"])} >
                                                                  <img src={e["low_res_wall_url"]} ></img>
                                                                  {
                                                                    e["wall_id"].split('-')[0]=="WL"?

                                                                    <svg class={styles['wall-cross']} onClick={()=>this.delWallShowModal(i)}  width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                      <path d="M9.31524 1L1.11011 9.20513" stroke="#214594" stroke-linecap="round" stroke-linejoin="round"/>
                                                                      <path d="M1.79639 0.999023L10.0015 9.20415" stroke="#214594" stroke-linecap="round" stroke-linejoin="round"/>
                                                                  </svg>
                                                                    // <img class={styles['wall-cross']} onClick={()=>this.delWallShowModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                                                                    :
                                                                    null
                                                                  }
                                                                  
                                                                  <span>{e["wall_name"].substring(0, 10)}</span>
                                                                
                                                                </div>
                                                                
                                                                // onClick={()=>this.delCustomWall(i)}
                                                            )

                                                            })
                                                          }
                                                          {
                                                          customWallLeft>0?(
                                                            <div className={styles['inactive-background-sec']} onClick={this.handleBackgroundList}>
                                                              <div className={styles['overlay-pos']} >
                                                                <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                                <div className={styles['img-overlay']}></div>
                                                                <div className={styles['more-btn-overlay-2']}>+ {customWallLeft} More</div>
                                                              </div>
                                                            </div>):null
                                                          }
                                                          
                                                      </div>  
                                                  </div>
                                                    <div className={this.state.applyWay.length>0?[styles['custom-wall'],''].join(' '):[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>2</span> Adjust Height</p>
                                                      <div className={[styles['adjust-height-area'],''].join(' ')}>
                                                        <ul>
                                                          <li>
                                                            <button  className={this.state.applyWay=="manually"?[styles['active'],''].join(' '):[styles['inactive'],''].join(' ')} onClick={()=> this.partialapply("manually")} >
                                                              <h6>Manually</h6>
                                                              <img src={this.state.applyWay=="manually"?"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/ezgif.com-gif-maker%20(3).gif":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/auto-slidr.png"}/>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button className={this.state.applyWay=="magically"?[styles['active'],''].join(' '):[styles['inactive'],''].join(' ')} onClick={()=> this.partialapply("magically")} >
                                                              <h6>Magically</h6>
                                                              <img src={this.state.applyWay=="magically"?"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/auto-setting.gif":"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/animation_500_kov5rgmo%201.png"}/>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                    <div className={[styles['adjust-height'],''].join(' ')}>
                                                      <p><span>3</span> Apply and View Changes</p>
                                                      
                                                    </div>
                                                </div>

                                                <div className={[styles['btn-sec']]}>
                                                  <p className={[styles['rmaining'],'d-md-none d-block'].join(' ')}>Available Credits:&nbsp; <span>{this.props.availableCredit}</span></p>
                                                  <button onClick={this.applyChange}>Apply</button>
                                                </div>
                                              </div>
                                            }
                                            
                                            <BackgroundListModal 
                                              handleBackground = {this.handleBackground}
                                              delWallShowModal={this.delWallShowModal}
                                              background_id={this.props.background_id} 
                                              planData={this.props.planData} hideList={this.hideList} 
                                              showBackgroundList={this.state.showBackgroundList} 
                                              orgUrl={this.props.orgUrl}
                                              visualSelection={this.state.visualSelection}
                                              floor={this.props.floor}
                                              customWallSets={this.customWallSets}
                                              customWallSet={this.props.customWallSet}
                                              wallFile={this.wallFile}
                                              handleUploadClick={this.handleUploadClick}
                                              handleUpload={this.handleUpload}
                                              handleUnshiftWalls={this.props.handleUnshiftWalls}
                                              wallData={this.props.wallData}
                                              bg_list={this.props.bg_list}
                                              fav_list={this.props.fav_list}
                                              handleCarsFavList={this.handleCarsFavList}
                                              handleCarsBgList={this.handleCarsBgList}
                                              applyChange={this.applyChange}/>
                                        </div>
                                      }
                                      
                                </div>
                              </div>
                          </section>
                          <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                      </div>
                  </section>
              )}
              </Dropzone>
            
                   
            
            

                 
            <Modal
              show={this.state.showInstruction}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size='lg'
              centered
              onHide={() => this.setState({showInstruction:!this.state.showInstruction})}
           >
              <Modal.Header />
              <Modal.Body>
                <center className={[styles['car-text']]}>
                <h1>Output works best with these <span style={{color:"#FF7700"}}>Overlays</span></h1>
                </center>
                <div className={[styles[' '],'row'].join(' ')}>
                    <img className="mt-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/angles-gif.gif" />
                    
                </div>
                <center>
                    <button onClick={this.modalOnHide} className={styles['got-btn']}>Got It</button>
                </center>

              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.delWall}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size='lg'
              centered
              onHide={() => this.setState({delWall:!this.state.delWall})}
              >
              <Modal.Header />
              <Modal.Body>
                <center className={[styles['car-text']]}>
                <h1>Are you sure you want to Delete this <span style={{color:"#FF7700"}}>CustomWall</span></h1>
                </center>
                
                <center>
                    <button onClick={this.delWallModal} className={styles['got-btn']}>Yes</button>
                    <button onClick={this.delWallModalHide} className={styles['got-btn']}>No</button>
                </center>

              </Modal.Body>
            </Modal>
            {
              this.state.showModal ? 
              <LoginModal
                show={this.handleModal}  
                sendOTP={this.sendOTP}  
                verifyOTP={this.verifyOTP}
                otpScreen={this.state.otpScreen}
                verification={this.state.verification}
                handleChange={this.handleChange}
                otpVerification={this.state.otpVerification}
                emailValidation={this.state.emailValidation}
                onHide={this.handleCloseModal}
                defaultEmail = {this.state.email}
                cars={true}
              />
              :
              null
            }
          </>
        )
    }
}