import React, { Component } from 'react'
import "../../Css/ClipprNav.css";  
 
export default class TrustedHeader extends Component {
    
    render() {
        return (
            <div className="">
                <nav className="navbar fixed-top  navbar-expand-lg navbar-light small-nav-color header-design" style={{"background":"#fff"}}>
                    <div className="container-fluid navbarRemovebg-links d-flex justcontent-around">
                        <a className="navbar-brand" href="#">
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/trusted-car.png" width="120px" height="auto"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex float-center use-case"></ul> 
                            <div>
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex float-center">
                                    <li className="nav-item ">
                                        <a className="nav-link active trusted-active" aria-current="page" target="_blank" href="#">Contact Us</a>
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav> 
        </div>
        )
    }
}
