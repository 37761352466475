import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import Resizer from 'react-image-file-resizer'
import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import BulkUploadSku from './BulkUploadSku';
import LandingModal from '../Common/Modal/LandingModal';
import { isMobile } from 'react-device-detect';
import { fetchCreditUserDetails, updateUserCredit, poll, chunkArray, getCookie } from '../Common/Utils'
import queryString from 'query-string'




class MultiUploadSku extends Component {
    state = {
        projectNameExists: false,
        filesList: [],
        showProjectNameModal: false,
        planData: [],
        project_id: '',
        sku_id: '',
        project_name: '',
        sku_name: '',
        auth_key: '',
        showInstruction: false,
        availableCredit: 0,
        alottedCredit: 0,
        creditUsed: 0,
        notEnoughCreditModal: false,
        enoughCreditModal: false,
        zeroCreditModal: false,
        enterpriseId: '',
        viewSample: true,
        viewSample2: true,
        uploadCounter: 0,
        uploadStarteds: false,
        isZipReady: false,
        productCategory: 'automobiles',
        prodCatId : 'cat_d8R14zUNE',
        background_id: 9999,
        totalImageCost:0
    }
    cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]
    componentDidMount = () => {
        sessionStorage.clear()

        let queries = queryString.parse(this.props.location.search)
 
        var enterpriseId
        if (queries.enterpriseId != undefined) {
            this.setState({ enterpriseId: queries.enterpriseId })
            enterpriseId = queries.enterpriseId
        } else {
            this.setState({ enterpriseId: 'TaD1VC1Ko' })
            enterpriseId = 'TaD1VC1Ko'
        }



        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/enterprise/get",
            params: {
                enterprise_id: enterpriseId,
            }
        }).then(resp => {

            document.cookie = `enterpriseId=${resp.data?.data?.enterprise_id}; path=/`;
            document.cookie = `apiKey=${resp.data?.data?.api_key}; path=/`;
        })

        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck) {
            this.setState({
                showInstruction: false
            })
        }
        else {
            if (isMobile) {
                this.setState({
                    showInstruction: false
                })
            } else {
                this.setState({
                    showInstruction: true
                })
            }

        }

        

        let auth_key = this.cookie("auth_token")
        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/credit/fetch",
            params: {
                auth_key: auth_key
            }

        }).then(resp => {
            // console.log(resp)
            if (resp.data.status == 200) {
                this.setState({
                    availableCredit: resp.data.data.credit_available,
                    creditAlloted: resp.data.data.credit_allotted,
                    creditUsed: resp.data.data.credit_used
                })
            } else {
                console.log(resp)
            }
        })

        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/backgrounds/fetchEnterpriseBgs/v2",
            params: {
                category: this.state.productCategory,
                enterprise_id: enterpriseId,
            }
        })
        .then((res) => {
            this.setState({
                planData: res.data?.data
            })
        })
    }

    validateImageName = (name) => {
        // remove file entension
        name = name.replace(/\.[^/.]+$/, '');
        // name should only contain a-z, A-Z, 0-9, _ with no spaces
        const format =  /^[a-zA-Z0-9_]+$/;
        return format.test(name)
    }

    extractImageCategoryAndStatus = (imageName, category) => {
        const res = {status: "Uploading", imageCategory: ""}
        // validate image name
        if(!this.validateImageName(imageName)) {
            res.status = "Error"
            return res;
        }
        // extract image category
        const imageNameArr = imageName.split('_')
        if(category === 'automobiles') {
            if (imageNameArr[1].toLowerCase() === 'ext') {
                res.imageCategory = 'Exterior'
                return res
            } else if (imageNameArr[1].toLowerCase() === 'int') {
                res.imageCategory = 'Interior'
                return res
            } else if (imageNameArr[1].toLowerCase() === 'info') {
                res.imageCategory = 'Info'
                return res
            } 
            res.status = 'Error'
            return res
        } else {
            if(imageNameArr[1] === 'Ecom'){
                res.imageCategory = 'Ecom'
            } else if(imageNameArr[1] === 'Food'){
                res.imageCategory = 'Food'
            }else {
                res.status = 'Error'
            }
        }
        return res
    }

    multipleFileUpload = (event) => {

        if (this.state.project_name === '') {
            this.setState({
                showProjectNameModal: true
            })
        }

        for (let i = 0; i < event.length; i++) {
            if (this.state.filesList.length < 5000) {
                if (event.target) {
                    let str = event.target.files[i].name
                    const { imageCategory, status } = this.extractImageCategoryAndStatus(str, this.state.productCategory)
                    let file = {
                        file: event.target.files[i],
                        name: event.target.files[i].name,
                        size: event.target.files[i].size,
                        type: event.target.files[i].type,
                        lastModified: event.target.files[i].lastModified,
                        lastModifiedDate: event.target.files[i].lastModifiedDate,
                        url: URL.createObjectURL(event.target.files[i]),
                        status: status,
                        imageId: null,
                        projectId: null,
                        skuId: null,
                        imageCategory: imageCategory
                    }
                    if (Math.round(file.size / 1024) > 30720) {
                        return;
                    }
                    if (status == 'Uploading') {
                        this.setState(
                            {
                                filesList: [...this.state.filesList, file],
                            },

                        );
                    }

                    if(imageCategory == 'Exterior' || imageCategory == 'Ecom' || imageCategory == 'Food'){
                        this.setState({
                            totalImageCost: this.state.totalImageCost + 1
                        })
                    }

                } else {
                    let str = event[i].name
                    const { imageCategory, status } = this.extractImageCategoryAndStatus(str, this.state.productCategory)
                    
                    let file = {
                        file: event[i],
                        name: event[i].name,
                        size: event[i].size,
                        type: event[i].type,
                        lastModified: event[i].lastModified,
                        lastModifiedDate: event[i].lastModifiedDate,
                        url: URL.createObjectURL(event[i]),
                        status: status,
                        imageId: null,
                        projectId: null,
                        skuId: null,
                        imageCategory: imageCategory
                    }
                    // let file = event[i]
                    if (Math.round(file.size / 1024) > 30720) {
                        return;
                    }
                    if (status == 'Uploading') {
                        this.setState(
                            {
                                filesList: [...this.state.filesList, file],
                            },

                        );
                    }
                    if(imageCategory == 'Exterior' || imageCategory == 'Ecom' || imageCategory == 'Food'){
                        this.setState({
                            totalImageCost: this.state.totalImageCost + 1
                        })
                    }

                }
                if (this.state.filesList.length === 1) {
                    this.setState({
                        isBulk: false
                    })
                }
                else {
                    this.setState({
                        isBulk: true
                    })
                }

            }
            else {
                this.setState({
                    maxUpload: true
                })
            }


        }

    }

    multipleFileUploadProject = (event) => {

        this.state.filesList.length = 0
        this.state.project_name = ''
        this.state.uploadStarteds = false
        this.state.isZipReady = false
        this.state.bulkDownloadDone = false
        this.state.uploadCounter = 0
        sessionStorage.clear()
        window.location.reload()

       
    }

    handleUploadLimitModal = () => {
        this.setState({
            maxUpload: false
        })
    }

    emptyMultipleFileArray = () => {
        this.state.filesList.length = 0
        this.setState({
            maxUpload: false
        })
    }

    hideLandingModal = () => {
        this.setState({ showInstruction: !this.state.showInstruction })
    }

    handleProjectName = (e) => {
        e.preventDefault()
        this.setState({
            project_name: e.target.value,
            projectNameExists: false
        })
        sessionStorage.setItem("project_name", e.target.value);

    }

    handleProjectNameModal = () => {
        let auth_key = this.cookie("auth_token")
        let enterprise_id = this.cookie("enterpriseId")
        let user_id = this.cookie("userId")
        const fd = new FormData()
        fd.append("auth_key", auth_key);
        fd.append("user_id", user_id);
        fd.append("enterprise_id", enterprise_id);
        fd.append("project_name", this.state.project_name)
        axios.post(`https://www.clippr.ai/api/v2/project/verify-project`, fd)
            .then((resp) => {
                if (resp.data.status == 404) {
                    this.setState({
                        showProjectNameModal: false,
                        projectNameExists: false
                    })
                } else if (resp.data.status == 200) {
                    this.setState({
                        projectNameExists: true
                    })
                }
                // console.log(resp)
            })

    }
    updateMultipleImageList = (i) => {
        this.state.filesList.splice(i, 1)
        this.forceUpdate()
    }

    hitUploadAPI = async () => {

        let price = this.state.totalImageCost

        if (this.state.availableCredit >= price) {
            this.getUserCredit()
            this.setState({
                uploadStarteds: true,
                showBulkCreditModal: false,
                enoughCreditModal: false,

            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for (let batchIndex = 0; batchIndex < batchFiles.length; batchIndex++) {

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4 * batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)

                } catch (err) {
                    console.error(err)

                }
            }
            this.setState({
                isZipReady: true
            })

            sessionStorage.setItem("isZipReady", true);


            sessionStorage.setItem("uploadStarteds", true);

        } else if (this.state.availableCredit < price) {

            this.setState((prevState) => {
                return {
                    uploadStarteds: true,
                    showBulkCreditModal: false,
                    notEnoughCreditModal: false,
                    // filesList: prevState.filesList.slice(0,this.state.availableCredit)
                }
            }, () => {
                console.log(this.state.filesList.length)
                sessionStorage.setItem("uploadStarteds", true);
            })

            const batchFiles = chunkArray(this.state.filesList, 4)

            for (let batchIndex = 0; batchIndex < batchFiles.length; batchIndex++) {

                try {
                    const resp = await Promise.all(batchFiles[batchIndex].map((file, currIndex) => {
                        let i = 4 * batchIndex + currIndex
                        return this.bulkUploadAPI(i)
                    }))
                    console.log(resp)

                } catch (err) {
                    console.error(err)

                }
            }
            this.setState({
                isZipReady: true
            })

            sessionStorage.setItem("isZipReady", true);

            let arr = this.state.filesList.slice(0, this.state.availableCredit)
            this.setState({
                filesList: [...arr]
            }, () => {
                console.log(this.state.filesList.length)
            })

        }


    }

    bulkUploadAPI = async (i) => {
        let auth_key = this.cookie("auth_token")
        let enterprise_id = this.state.enterpriseId
        let user_id = this.cookie("userId")
        let sting = this.state.filesList[i].name
        let skuArr = sting.split('_')
        const frame_seq_no = skuArr[skuArr.length - 1].split('.')[0]
        const fd = new FormData();

        fd.append("image", this.state.filesList[i].file)
        fd.append("image_category", this.state.filesList[i].imageCategory)
        fd.append("prod_cat_id", this.state.prodCatId)
        fd.append("auth_key", auth_key)
        fd.append("prod_sub_cat_id", "")
        fd.append("sku_name", skuArr[0])
        fd.append("project_name", this.state.project_name)
        fd.append("background_id", this.state.background_id)
        fd.append("enterprise_id", enterprise_id)
        fd.append('source', 'MULTISKUBULK')
        fd.append('prod_cat_name', this.state.productCategory)
        fd.append('frame_seq_no', frame_seq_no)

        // this.state.filesList[i].status = 'Uploading'
        this.setState({
            filesList: [
                ...this.state.filesList.slice(0, i),
                Object.assign({}, this.state.filesList[i], { status: 'Uploading' }),
                ...this.state.filesList.slice(i + 1)
            ]
        })
        return axios.post(`${process.env.REACT_APP_BASEURL}/v2/multi/upload`, fd)
            .then((uploadResponse) => {
                if (uploadResponse.status == 200) {

                    this.setState({
                        filesList: [
                            ...this.state.filesList.slice(0, i),
                            Object.assign({}, this.state.filesList[i], {
                                status: 'Yet to Start',
                                url: uploadResponse.data.input_image_hres_url,
                                imageId: uploadResponse.data.image_id,
                                projectId: uploadResponse.data.project_id,
                                skuId: uploadResponse.data.sku_id,
                            }),
                            ...this.state.filesList.slice(i + 1)
                        ],
                        uploadCounter: this.state.uploadCounter + 1,
                    })

                } else if (uploadResponse.data.status == 400) {
                    document.cookie = 'emailId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'userId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'isValidated' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'auth_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.sessionStorage.clear()
                    window.location.reload()
                }
                sessionStorage.setItem("filesList", JSON.stringify(this.state.filesList));
                return Promise.resolve(uploadResponse)
            })
            .catch((err) => {
                this.setState({
                    errorBulkFiles: [...this.state.errorBulkFiles, Object.assign({}, this.state.filesList[i], {
                        status: 'Error',
                    })],
                    filesList: [
                        ...this.state.filesList.slice(0, i),
                        Object.assign({}, this.state.filesList[i], {
                            status: 'Error',
                        }),
                        ...this.state.filesList.slice(i + 1)
                    ]
                })
                return Promise.reject(err)

            })

    }

    handleId = (id, price) => {
        this.setState({
            background_id: id,
            bgCredit: price,
            // isEditingFinish:false,
            askEmail: false,
            toggleChange: false,
            toggleStatus: false,
        })
    }

    creditVerify = () => {

        // let userId = this.cookie('userId')
        let price = this.state.filesList.length
        if (this.state.availableCredit >= price) {


            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal: false,
                zeroCreditModal: false,
                enoughCreditModal: true,

                // uploadStarteds:true,
            })

        } else if (this.state.availableCredit == 0) {
            this.setState({
                showBulkCreditModal: true,
                zeroCreditModal: true,
                // uploadStarteds:true,
            })
        } else if (this.state.availableCredit < price) {
            this.setState({
                showBulkCreditModal: true,
                notEnoughCreditModal: true,
                filesList: this.state.filesList.slice(0, this.state.availableCredit)

            })
        }
        // console.log(this.state.filesList)
    }

    handleBulkCreditModal = () => {
        this.setState({
            showBulkCreditModal: false
        })
    }

    getUserCredit = async () => {
        // if(){
        let auth_key = this.cookie('auth_token')
        const resp = await poll({
            fn: () => {

                return axios({
                    method: "GET",
                    url: "https://www.clippr.ai/api/v2/credit/fetch",
                    // data : fd
                    params: {
                        // email_id : emailId,
                        auth_key: auth_key
                    }

                })
            },
            validate: (resp) => {
                if (resp.data.status == 200) {
                    if (this.state.bulkDownloadDone == true) {
                        return true
                    } else {
                        this.setState({
                            availableCredit: resp.data.data.credit_available,
                            allotedCredit: resp.data.data.credit_allotted,
                            creditUsed: resp.data.data.credit_used
                        })
                        return false
                    }
                   
                } else if (resp.data.status == 400) {
                    document.cookie = 'emailId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'userId' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'isValidated' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'auth_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.sessionStorage.clear()
                    window.location.reload()
                }


            },
            interval: 5000,
        })
    }

    toggleSample = () => {
        this.setState({
            viewSample: !this.state.viewSample
        })
    }
    toggleSample2 = () => {
        this.setState({
            viewSample2: !this.state.viewSample2
        })
    }
    handleProductCategory = (name) => {
        let enterprise_id = this.cookie("enterpriseId")
        if(name == 'automobiles'){
            this.setState({
                prodCatId: 'cat_d8R14zUNE'
            })
        }else if(name == 'ecommerce'){
            this.setState({
                prodCatId: 'cat_Ujt0kuFxY'
            })
        }
        
        this.setState({
            productCategory: name
        })

        axios({
            method: "GET",
            url: "https://www.clippr.ai/api/v2/backgrounds/fetchEnterpriseBgs/v2",
            params: {
                category: name,
                enterprise_id: enterprise_id,
            }
        })
        .then((res) => {
            this.setState({
                planData: res.data?.data
            })
        })
    }

    render() {
        return (
            <div className={[styles['multi-main-bg'],''].join(' ')}>
                {
                    this.state.filesList.length == 0 ?
                        <>
                            <LandingModal
                                showInstruction={this.state.showInstruction}
                                hideModal={this.hideLandingModal}
                            />
                            <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({
                                        onClick: event => event.stopPropagation()
                                    })} className={styles['dropzone1']}>
                                        {this.state.dropBackground ?
                                            <section className={[styles['drop-box-text']]}>
                                                <div>Drop Image Anywhere</div>

                                            </section>
                                            : null
                                        }
                                        <div className={styles['header-section']}>
                                            <p className={styles['heading-text']}>Spyne Multi SKU Bulk Transformer</p>
                                            <p className={styles['heading-para']}>Create Professional Images in a <span>Single Click</span> using AI</p>
                                        </div>
                                        <div className={[styles['car-main-wrapper'], ""].join(" ")} id="cars-try-modal">
                                            <div className={[styles[''], "container-fluid"].join(" ")}>

                                                <div className={[styles['multi-try'], 'row'].join(' ')}>
                                                    <div className={[styles['car-left-section'], 'col-md-12'].join(' ')}>
                                                        <div className={[styles['car-mid-section'], ''].join(' ')}>
                                                            <section className={[styles['try-drag-drop'],''].join(' ')}>
                                                                <div className={[styles['multi-tab-button'], 'align-items-center row'].join(' ')}>
                                                                    <div className={[styles['multi-button'], 'col'].join(' ')}>
                                                                        <button onClick={() => this.handleProductCategory('automobiles')} className={this.state.productCategory == 'automobiles' ?[styles['active'], 'col'].join(' ') :  null}>Automobile</button>
                                                                    </div>
                                                                    <div className={[styles['multi-button'], 'col'].join(' ')}>
                                                                        <button onClick={() => this.handleProductCategory('ecommerce')} className={this.state.productCategory == 'ecommerce' ?[styles['mactive'], 'col'].join(' ') :  null}>E-commerce</button>
                                                                    </div>
                                                                    
                                                                </div>
                                                                {this.state.productCategory=="automobiles"?
                                                                <div className={[styles['dropzone1'], styles['multi-dropzone'], ''].join(' ')}>
                                                                    <div className={[styles['multi-bulk'], 'row'].join(' ')}>
                                                                        <div className={[styles['multi-automobile'], 'col-md-7'].join(' ')}>
                                                                            <h5>Automobile Image Naming Convention </h5>

                                                                            <div className={[styles['sku-list'], styles['image-naming'], 'row'].join(' ')}>
                                                                                
                                                                                <h6> Image Naming Convention

                                                                                    {this.state.viewSample ?
                                                                                        <span onClick={this.toggleSample}>View Sample</span>
                                                                                        :
                                                                                        <span onClick={this.toggleSample}>View Convention</span>
                                                                                    }

                                                                                </h6>
                                                                                {this.state.viewSample ?
                                                                                    <div>
                                                                                        <h3><b className={[styles['sku-nm']]}>sku_name_</b><span className={[styles['red'],styles['ext-nm'],''].join(' ')}>ext</span>_<span className={[styles['purple'],styles['num-nm'],''].join(' ')}>1</span></h3>
                                                                                        <div className={[styles['image-naming'], 'col-md-12'].join(' ')}>
                                                                                            <ul className={[styles['sku-cards']]}>
                                                                                                <li>
                                                                                                    <div className={[styles['sku-name'], ''].join(' ')}>
                                                                                                        <h3>sku_name</h3><p><strong>Recommended</strong></p>
                                                                                                        <small>sku_name = VIN </small>
                                                                                                        <p>Each SKU Name should be <b>unique</b> If you dont have the VIN (Vehicle Identification Number) use License plate number.</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className={[styles['sku-name'], ''].join(' ')}>
                                                                                                        <h3><span className={styles['red']}>Image Type</span></h3><p><strong>Supported </strong></p>
                                                                                                        <small>sku_name = VIN </small>
                                                                                                        <p><b>Ext</b> = Exterior Shots </p>
                                                                                                        <p><b>Int</b> = Interior Shots</p>
                                                                                                        <p><b>Info</b> = Information Cards</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className={[styles['sku-name'], ''].join(' ')}>
                                                                                                        <h3><span className={styles['purple']}>Frame No.</span></h3><p><strong>Recommended</strong></p>
                                                                                                        <small>sku_name = VIN </small>
                                                                                                        <p>Use a simple number. <b>Each Image Type</b> should have their own list.</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className={[styles['image-naming'], 'col-md-12'].join(' ')}>
                                                                                        <div className={[styles[''], 'm-0 row'].join(' ')}>
                                                                                            <p className={styles['vin-no']}>Where Car VIN  =  “12345ABC”</p>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>2</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>int</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>int</span>_<span className={styles['purple']}>2</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>3</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>4</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>int</span>_<span className={styles['purple']}>3</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>int</span>_<span className={styles['purple']}>4</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>2</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>int</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>int</span>_<span className={styles['purple']}>2</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>3</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>ext</span>_<span className={styles['purple']}>4</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>int</span>_<span className={styles['purple']}>3</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>int</span>_<span className={styles['purple']}>4</span></p>
                                                                                            </div>

                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6 offset-md-3'].join(' ')}>
                                                                                                <p>12345ABC_<span className={styles['red']}>info</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                            <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                                <p>6789XYZ_<span className={styles['red']}>info</span>_<span className={styles['purple']}>1</span></p>
                                                                                            </div>
                                                                                             
                                                                                        </div>
                                                                                    </div>

                                                                                }




                                                                                {/* 2nd */}


                                                                            </div>
                                                                        </div>
                                                                        <div className={[styles['drag-file-area'], 'col-md-5  mt-5'].join(' ')}>

                                                                            <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} >

                                                                                {({ getRootProps, getInputProps }) => (

                                                                                    <div {...getRootProps({})}>
                                                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                                        <p className={styles["para-11"]}><h4>DROP IMAGE HERE</h4> </p>
                                                                                        <p class={styles["para-2"]}><span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                                                                        <p className={[styles['supports1'], styles['supports'], ''].join(' ')}>Supports jpeg, jpg, bmp & png (maximun 200 images)</p>
                                                                                        <input onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />


                                                                                    </div> 
                                                                                )}
                                                                            </Dropzone>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className={[styles['dropzone1'], styles['multi-dropzone'], ''].join(' ')}>
                                                                <div className={[styles['multi-bulk'], 'row'].join(' ')}>
                                                                    <div className={[styles['multi-automobile'], 'col-md-7'].join(' ')}>
                                                                        <h3><b>E-Commerce Upload Guidelines</b></h3>

                                                                        <div className={[styles['sku-list2'], styles['image-naming2'], 'row'].join(' ')}>
                                                                            <ul>
                                                                                <li>Each Product should be treated as one <span>SKU</span>.</li>
                                                                                <li>We recommend to use the  Product name as the <span>SKU name</span></li>
                                                                                <li>A single SKU (product) can have <span>multiple images</span></li>
                                                                                <li>Kindly update the images as per the below naming convention</li>
                                                                                <li>Image names not complying with convention will be skipped automatically</li>
                                                                            </ul> 
                                                                            <h2> Image Naming Convention
                                                                            </h2>
                                                                            
                                                                            <div className={styles["sku-buttons"]}>
                                                                            <button onClick={this.toggleSample2} className={this.state.viewSample2==true ?[styles['mactive1'], ''].join(' ') :  [styles['notactive'], ''].join(' ')}>View Sample</button>
                                                                            <button onClick={this.toggleSample2} className={this.state.viewSample2==false ?[styles['mactive1'], ''].join(' ') :  [styles['notactive'], ''].join(' ')}>View Convention</button>
                                                                            </div>                                                       
                                                                            {this.state.viewSample2 ?
                                                                                <div>
                                                                                    
                                                                                    <h3><span className={styles["sku-arrow1"]}><svg className={[styles["arrow"]]} width="37" height="64" viewBox="0 0 37 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.46967 63.5303C5.76257 63.8232 6.23744 63.8232 6.53033 63.5303L11.3033 58.7574C11.5962 58.4645 11.5962 57.9896 11.3033 57.6967C11.0104 57.4038 10.5355 57.4038 10.2426 57.6967L6 61.9393L1.75736 57.6967C1.46447 57.4038 0.989595 57.4038 0.696702 57.6967C0.403808 57.9896 0.403808 58.4645 0.696702 58.7574L5.46967 63.5303ZM5.25 1L5.25 63L6.75 63L6.75 1L5.25 1Z" fill="#6B9860"/>
                                                                                        <path d="M5.46967 63.5303C5.76257 63.8232 6.23744 63.8232 6.53033 63.5303L11.3033 58.7574C11.5962 58.4645 11.5962 57.9896 11.3033 57.6967C11.0104 57.4038 10.5355 57.4038 10.2426 57.6967L6 61.9393L1.75736 57.6967C1.46447 57.4038 0.989595 57.4038 0.696702 57.6967C0.403808 57.9896 0.403808 58.4645 0.696702 58.7574L5.46967 63.5303ZM5.25 1L5.25 63L6.75 63L6.75 1L5.25 1Z" fill="#6B9860"/>
                                                                                        <path d="M37 1L6 0.999997" stroke="#6B9860" stroke-width="1.5"/>
                                                                                        <path d="M37 1L6 0.999997" stroke="#6B9860" stroke-width="1.5"/>
                                                                                        </svg>
                                                                                        </span>sku_name_<span className={styles['purple']}>1</span><span className={styles["sku-arrow2"]}><svg className={[styles["arrow"]]} width="37" height="64" viewBox="0 0 37 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M31.5303 63.5303C31.2374 63.8232 30.7626 63.8232 30.4697 63.5303L25.6967 58.7574C25.4038 58.4645 25.4038 57.9896 25.6967 57.6967C25.9896 57.4038 26.4645 57.4038 26.7574 57.6967L31 61.9393L35.2426 57.6967C35.5355 57.4038 36.0104 57.4038 36.3033 57.6967C36.5962 57.9896 36.5962 58.4645 36.3033 58.7574L31.5303 63.5303ZM31.75 1L31.75 63L30.25 63L30.25 1L31.75 1Z" fill="#6B9860"/>
                                                                                        <path d="M31.5303 63.5303C31.2374 63.8232 30.7626 63.8232 30.4697 63.5303L25.6967 58.7574C25.4038 58.4645 25.4038 57.9896 25.6967 57.6967C25.9896 57.4038 26.4645 57.4038 26.7574 57.6967L31 61.9393L35.2426 57.6967C35.5355 57.4038 36.0104 57.4038 36.3033 57.6967C36.5962 57.9896 36.5962 58.4645 36.3033 58.7574L31.5303 63.5303ZM31.75 1L31.75 63L30.25 63L30.25 1L31.75 1Z" fill="#B465D0"/>
                                                                                        <path d="M0 1L31 0.999997" stroke="#6B9860" stroke-width="1.5"/>
                                                                                        <path d="M0 1L31 0.999997" stroke="#B465D0" stroke-width="1.5"/>
                                                                                        </svg>
                                                                                    </span></h3>
                                                                                    <div className={[styles["skuname-text"],'row'].join(' ')}>
                                                                                        <div className={[styles["skuname-details"],'col-md-4 offset-md-2 col-6'].join(' ')}>
                                                                                            <ul>
                                                                                                <li>sku-name = Product Name</li>
                                                                                                <li>Each sku name should be unique </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className={[styles["skuname-details"],'col-md-4 col-6'].join(' ')}>
                                                                                            <ul>
                                                                                                <li>Use a simple number</li>
                                                                                                <li>Each image should have its own number</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    
                                                                                    </div>

                                                                                </div>
                                                                                  :
                                                                                <div className={[styles['image-naming'], 'col-md-12'].join(' ')}>
                                                                                    <div className={[styles[''], 'm-0 row'].join(' ')}>
                                                                                         <p className={styles['vin-no-s2']}>Where Product 1 name (SKU name)  =  “12345ABC”</p>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>1</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>2</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>3</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>4</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>5</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>6</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>7</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>8</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>9</span></p>
                                                                                         </div>
                                                                                         <div className={[styles['sku-texts'], 'col-md-3 col-6'].join(' ')}>
                                                                                             <p>12345ABC_<span className={styles['purple2']}>10</span></p>
                                                                                         </div>
                                                                                         
                                                                                     </div>
                                                                                </div>

                                                                                }
                                                                        </div>
                                                                    </div>
                                                                    <div className={[styles['drag-file-area'],'col-md-5 pt-5 mt-5'].join(' ')}>
                                                                        <Dropzone onDrop={acceptedFiles => this.multipleFileUpload(acceptedFiles)} >

                                                                            {({ getRootProps, getInputProps }) => (

                                                                                <div {...getRootProps({})}>
                                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                                    <p className={styles["para-11"]}><h4>DROP IMAGE HERE</h4> </p>
                                                                                    <p class={styles["para-2"]}><span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                                                                    <p className={[styles['supports1'], styles['supports'], ''].join(' ')}>Supports jpeg, jpg, bmp & png (maximun 200 images)</p>
                                                                                    <input onChange={this.multipleFileUpload} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />


                                                                                </div>


                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                }
                                                            </section>
                                                             
                                                        </div>
                                                    </div>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                        <input onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </>

                        : <BulkUploadSku
                            filesList={this.state.filesList}
                            multipleFileUpload={this.multipleFileUpload}
                            multipleFileUploadProject={this.multipleFileUploadProject}
                            handleId={this.handleId}
                            editedUrl={this.state.url}
                            handleToggle={this.handleToggle}
                            toggleStatus={this.state.toggleStatus}
                            handleAngle={this.handleAngle}
                            imageSelected={this.state.imageSelected}
                            handleClick={this.hitapi}
                            handleModalApi={this.hitapiModal}
                            isEditingFinish={this.state.isEditingFinish}
                            handleDownload={this.updateCredit}
                            creditUsed={this.state.creditUsed}
                            availableCredit={this.state.availableCredit}
                            allotedCredit={this.state.allotedCredit}
                            defaultChecked={this.state.carShadow}
                            planData={this.state.planData}
                            spinner={this.state.spinner}
                            toggleChange={this.state.toggleChange}
                            background_id={this.state.background_id}
                            dealerLogoFileHandler={this.dealerLogoFileHandler}
                            handleLogoPosition={this.handleLogoPosition}
                            logoUrl={this.state.logoUrl}
                            logoPosition={this.state.logoPosition}
                            hideLogo={this.state.hideLogo}
                            askEmail={this.state.askEmail}
                            backgroundUrl={this.state.backgroundEditedUrl}
                            imageEdited={this.state.imageEdited}
                            downloadPopup={this.downloadPopup}
                            handleActivateButton={this.handleActivButton}
                            activateButton={this.state.activateButton}
                            downloadComplete={this.state.downloadComplete}
                            handleDownloadComplete={this.handleDownloadComplete}
                            isDownload={this.state.isDownload}
                            handleWindowTransparency={this.handleWindowTransparency}
                            handelColorEnhancement={this.handelColorEnhancement}
                            handelBlurNumberPlate={this.handelBlurNumberPlate}
                            handleWindowCorrenction={this.handleWindowCorrenction}
                            checkWindowCorrenction={this.state.checkWindowCorrenction}
                            checkBlurNumberPlate={this.state.checkBlurNumberPlate}
                            checkColorEnhancement={this.state.checkColorEnhancement}
                            checkWindowTransparency={this.state.checkWindowTransparency}
                            bgCredit={this.state.bgCredit}
                            handleLogo={this.handleLogo}
                            maxUpload={this.state.maxUpload}
                            handleUploadLimitModal={this.handleUploadLimitModal}
                            emptyMultipleFileArray={this.emptyMultipleFileArray}
                            updateMultipleImageList={this.updateMultipleImageList}
                            creditVerify={this.creditVerify}
                            showBulkCreditModal={this.state.showBulkCreditModal}
                            handleBulkCreditModal={this.handleBulkCreditModal}
                            showProjectNameModal={this.state.showProjectNameModal}
                            handleProjectName={this.handleProjectName}
                            handleProjectNameModal={this.handleProjectNameModal}
                            notEnoughCreditModal={this.state.notEnoughCreditModal}
                            enoughCreditModal={this.state.enoughCreditModal}
                            zeroCreditModal={this.state.zeroCreditModal}
                            hitUploadAPI={this.hitUploadAPI}
                            uploadStarteds={this.state.uploadStarteds}
                            imgStatusDone={this.state.imgStatusDone}
                            imgStatusProcess={this.state.imgStatusProcess}
                            imgStatusUpload={this.state.imgStatusUpload}
                            showRefundModal={this.state.showRefundModal}
                            handleRefundModal={this.handleRefundModal}
                            userId={this.state.userId}
                            refundImgUrl={this.state.refundImgUrl}
                            getRefundModal={this.getRefundModal}
                            refundOrgUrl={this.state.refundOrgUrl}
                            refundImgId={this.state.refundImgId}
                            refundNum={this.state.refundNum}
                            updateEditedImageList={this.updateEditedImageList}
                            downloadImages={this.downloadImages}
                            downloadZipLink={this.state.downloadZipLink}
                            isZipReady={this.state.isZipReady}
                            bulkDownloadDone={this.state.bulkDownloadDone}
                            getUserCredit={this.getUserCredit}
                            auth_key={this.state.auth_key}
                            bulkUploadAPI={this.bulkUploadAPI}
                            emailId={this.state.emailId}
                            project_name={this.state.project_name}
                            projectNameExists={this.state.projectNameExists}
                            uploadCounter={this.state.uploadCounter}

                            
                            productCategory={this.state.productCategory}
                            totalImageCost={this.state.totalImageCost}
                        />
                }
            </div>
        );
    }
}

export default MultiUploadSku;