import React, { useEffect } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'

const columns = [
  { id: 'name', label: 'Sku Name', minWidth: 170,align: 'left' },
  
  {
    id: 'population',
    label: 'Total Images Processed',
    minWidth: 150,
    align: 'left',
  },
  
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'credits',
    label: 'Cost in Credits',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 170,
    align: 'left'
  },
  { id: 'download', label: 'Download', minWidth: 100,align: 'center' },
];

function createData(name, population, email,credits,source,download) {
  
  return { name,population, email,credits,source,download:[...download]};
}



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F9FAFC',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: 'linear-gradient(180deg, #FF8314 0%, #CDA989 100%);',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows] = React.useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() =>{
    const newRows= props.skuList.map((sku) =>{
      return createData(sku.sku_name,sku.cost,sku.email_id,sku.cost,sku.source,[sku.sku_id,sku.project_id])
    })
    setRows(newRows)
  },[props.skuList])

  const downloadImages = (skuId,projectId) =>{

    axios({
        method: "GET",
        url : "https://www.spyne.ai/download/shoot/ai-images",
        params : {
            skuId : skuId,
            projectId : projectId,
        }
    })
    .then(resp => {
        
        const a = document.createElement("a")
        a.href = resp.data
        a.download = true
        a.click()
       
    })
  }

  

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <StyledTableRow  role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if(column.id == 'download'){
                      return(
                        <TableCell key={column.id} align={column.align}>
                          <img alt="" style={{cursor:"pointer"}} onClick={() => downloadImages(value[0],value[1])} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/arrow-down-circle.png"/>
                        </TableCell>
                      )
                    }else{
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                  })}
                </StyledTableRow>

              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
