import Axios from 'axios'
<script src="https://widget.cloudinary.com/v2.0/global/all.js" type="text/javascript"></script>  

const cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

const fetchCreditUserDetails = (userId) => {
    return Axios({
        'method':'GET',
        'url':"https://www.spyne.ai/credit/user-total-credit",
        "params":{
            'userId':userId
        }
    })
}

const updateUserCredit = (userId, creditReduce, availableCredit, creditAlloted, creditUsed) => {

    return Axios({
        "method":"GET",
        "url":"https://www.spyne.ai/credit/update-total-credit",
        'params' : {
            "userId":userId,
            "creditAlloted":creditAlloted,
            "creditUsed":creditUsed + creditReduce,
            "creditAvailable":Number(availableCredit) - creditReduce
        }
    })
}

const uploadImageTOS3 = (fd) =>{
    return Axios({
        "method":"POST",
        "url":"https://www.clippr.ai/api/upload?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3",
        "data":fd,
        "params":{
            'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"
        }
    })
}

const shadowOverFootwear = (fd) => {
    return Axios({
        "method":"POST",
        "url":"https://www.clippr.ai/api/footwear-background-replacement",
        "data":fd
    })
}

const backgroundRemoval = (fd) => {
    return Axios({
        "method":"POST",
        "url":"https://www.clippr.ai/api/remove-background",
        "data":fd
    })
}
const backgroundFootwearRemoval = (fd) => {
    return Axios({
        "method":"POST",
        "url":"https://www.clippr.ai/api/remove-footwear-background",
        "data":fd
    })
}

const skyReplacement = (fd) => {
    return Axios({
        "method":"POST",
        "url":"https://www.clippr.ai/api/sky-replacement",
        "data":fd
    })
}
const sendOTP = (email) => {
    return Axios({
        'method':'GET',
        "url":'https://www.spyne.ai/credit-user/register-vendor',
        "params":{
            'emailId':email
        }
    })
}

const verifyOTP = (email,otp) => {
    return Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":email,
                'otp':otp
            }
        })
}

const validateOTP = (fd) => {
    return Axios({
            'method':'POST',
            "url":'https://www.clippr.ai/api/v2/user/validate-otp',
            "data": fd
        })
}

const imageCropping = (fd) => {
    return Axios({
        'method':'POST',
        "url":"https://www.clippr.ai/models/image-cropping",
        "data":fd
    })
}

const downloadImg=(data)=>{
    return  fetch(data, {
        method: "GET",
        headers: {}
        })
        .then(response => {
            response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            let name = data
            var index = name.lastIndexOf("/") + 1;
            var filename = name.substr(index)
            link.setAttribute("download", filename); 
            document.body.appendChild(link);
            link.click();
            });
        })
        
}
    

// const myWidget = window.cloudinary.openUploadWidget({
//     cloudName: 'spyneai', 
//     styles:{
//         palette: {
//           window: "white", // This is the color of border
//           windowBorder: "#90A0B3", // This is the color of upload image logo
//           tabIcon: "#FF7700", // This is the color of active tab icon
//           menuIcons: "#515151",
//           textDark: "#515151",
//           textLight: "white",
//           link:  "#FF7700",
//           action:  "#FF620C",
//           inactiveTabIcon: "#515151",
//           error: "#F44235",
//           inProgress: "#0078FF",
//           complete: "#20B832",
//           sourceBg: "#E4EBF1"
//         }
//     },
//     uploadPreset: 'Test-tech-spyne',cropping:true}, (error, result) => { 
//         if (!error && result && result.event === "success") { 
            
//             console.log('Done! Here is the image info: ', result.info.url); 
//             return result.info
//         }
//         else{
//             console.log('Its an error',error)
//         }
//     }
// )
const newLogin = (fd) => {
    return Axios({
        'method':'POST',
        "url":`https://www.clippr.ai/api/v2/user/request-otp`,
        'data':fd
    })
}

const insertFreeCredit = (fd) => {
    return Axios({
        'method':'POST',
        'url':`${process.env.REACT_APP_BASEURL}/insert-user`,
        'data':fd
    })
}

const poll = ({ fn, validate, interval }) => {
    console.log('Start poll...');
  
    const executePoll = async (resolve, reject) => {
      console.log('- poll');
      const result = await fn();
  
      if (validate(result)) {
        return resolve(result);
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    };
  
    return new Promise(executePoll);
  };
// Get Cookie
function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
	    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	  ));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

// Set Cookie
function setCookie(name, value, options = {}) {
// let domain = location.hostname.split('www').join('')
	options = {
// 'domain': domain,
		'max-age': 36000,
		'path': '/'
	}
	
	let updatedCookie = name + '=' + value;
	
	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}
	
	document.cookie = updatedCookie;
}

function deleteCookie(name) {
	setCookie(name, '', {
		'max-age': 'Thu, 01 Jan 1970 00:00:01 GMT'
	});
}

const chunkArray = (arr, chunkSize) => {
    let result = []
    let length = arr.length

    for (let i = 0; i < length; i = i + chunkSize) {
        result.push(arr.slice(i, i + chunkSize))
    }

    return result
}

const chunkArrayWhiteoutCorrection = (arr, chunkSize) => {
    let result = []
    let length = arr.length

    for (let i = 0; i < length; i = i + chunkSize) {
        if(i==0){
            result.push(arr.slice(0,1))
            i=i-3
        }
        else{
        result.push(arr.slice(i, i + chunkSize))
        }
    }

    return result
}

const creditInfo = (auth_key) => {
    // let userId = this.cookie('userId')
    // let auth_key = this.cookie('auth_token')
    return Axios({
        'method':'GET',
        'url':`https://www.clippr.ai/api/v2/credit/fetch?auth_key=${auth_key}`
    })
}

const reduceCredit = (auth_key, no) => {
    console.log(auth_key)
    console.log(no);
    const formData=new FormData();
    formData.append ("auth_key",auth_key);
    formData.append ('credit_reduce',no);
    return Axios({
        'method':'PUT',
        'url':`https://www.clippr.ai/api/v2/credit/reduce-credit`,
        "data":formData
    })
}

export {
    cookie,
    fetchCreditUserDetails, 
    updateUserCredit,
    uploadImageTOS3,
    shadowOverFootwear,
    backgroundRemoval,
    backgroundFootwearRemoval,
    sendOTP,
    verifyOTP,
    skyReplacement,
    imageCropping,
    newLogin,
    insertFreeCredit,
    poll,
    validateOTP,
    setCookie,
    deleteCookie,
    getCookie,
    chunkArray,
    creditInfo,
    reduceCredit,
    downloadImg,
    chunkArrayWhiteoutCorrection
}