import React, { Component } from 'react';
import {Modal} from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'

class ProjectIdModal extends Component {
    state = {  }


    handleChange = (e) => {
        this.props.handleProjectName(e)
    }
    
    render() { 
        return ( 
                <Modal
                    show={this.props.showProjectNameModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    size='md'
                    centered
                    >
                        <Modal.Header className={[styles['modal-header'],''].join(' ')}>
                            <div>
                                <h5>
                                <span><img src="https://storage.googleapis.com/spyne-website/whitelabel/add.svg"/></span>
                                 Project ID</h5>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="">
                            <section className={[styles['more-uploads'],''].join(' ')}>
                                <div className={[styles[''],'container-fluid'].join(' ')}>
                                    <div className={[styles[''],'row'].join(' ')}>
                                        <div className={[styles['popup-content'],''].join(' ')}>
                                            <label>
                                                 
                                                 Project ID</label>
                                             <input className="form-control " type="text" required placeholder="Ex. c7d20410" onChange={this.handleChange}></input>
                                             {this.props.projectNameExists ? 
                                                <p style={{"color" : "#cc4d29","fontSize" : "11px", "textAlign" : "left"}}>Oops - This project Id doesn't exsists, Please try again with a new project Id!</p>
                                                :
                                                null   
                                            }
                                            {this.props.projectIdExists ? 
                                                <p style={{"color" : "#cc4d29","fontSize" : "11px", "textAlign" : "left"}}>All image for this project are already uploaded</p>
                                                :
                                                null   
                                            }
                                             
                                            <div className={[styles['create-project'],''].join(' ')}>
                                                <button className={styles['proceed-btn']} onClick={this.props.handleProjectNameModal} disabled = {this.props.project_name == '' ? true : false}>Submit</button>
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                        </section>
                            
                        </Modal.Body>
                    
                </Modal> 
        );
    }
}
 
export default ProjectIdModal;