import React, { Component } from 'react'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'
import BackgroundListModalBulk from '../Common/Modal/BackgroundListModalBulk'
import UploadLimitModal from '../Common/Modal/UploadLimitModal'
import DealershipLogo from '../Common/DealerShipLogo'
import BulkCreditModal from '../Common/Modal/BulkCreditModal';
import ProjectNameModal from '../Common/Modal/ProjectNameModal';
import RefundModal from '../Common/Modal/RefundModal';
import Dropzone from 'react-dropzone'
import axios from 'axios'




// import Logo from './Logo';
export default class BulkCarsBackground extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            orignalTab: "Original",
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
            activeButtonNew:"Edited",
            themeColor:'#ff7700'
        }
        
        
    }

    componentDidMount = () => {
        let domain = window.location.hostname
        axios({
            method: "GET",
            url : "https://www.clippr.ai/api/v2/enterprise/getByCode",
            params : {
                enterprise_code : domain,
            }
        }).then(resp => {
        
            document.documentElement.style.setProperty("--primaryColor",resp.data?.data?.color)
            this.setState({
                themeColor: resp.data.data.color,
            })
            
        })
    }

    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
            activeButtonNew:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }

      fileHandlerInterior = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadInterior(acceptedFiles)
      }

      fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }

      


    render() {
        const orgCars = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                        <svg onClick={() => this.props.updateMultipleImageList(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        {/* <img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /> */}
                                        </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>

        
            
        })


        const orgCarsInterior = this.props.filesListInterior.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                        <svg onClick={() => this.props.updateMultipleImageListInterior(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        {/* <img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /> */}
                                        </span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>

        
            
        })

        
        const orgCarsUrl = this.props.filesList.map((carFile,i) => {
            return <div key={"org_url_" + i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            
                            {/* <img src={carFile.url} /> */}
                            <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.url}>
                                     <img src={carFile.url} />
                            </LightgalleryItem>
                            {   carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : carFile.status == 'Uploading' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : null
                            }
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-6"><p>{carFile.name}</p></div>
                                { carFile.status == 'Done' || carFile.status == 'Error' ?
                                    <div className="col-6 text-right">
                                    
                                        {
                                            carFile.status == 'Done' ?
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                        : carFile.status == 'Error' ?
                                            <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                        : null
                                        }
                                        
                                        
                                    </div>
                                    : null
                                }
                                
                                
                            </div>
                            
                        </div>
                    </div>
        })


        const orgCarsUrlInterior = this.props.filesListInterior.map((carFile,i) => {
            return <div key={"org_url_" + i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            
                            {/* <img src={carFile.url} /> */}
                            <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.url}>
                                     <img src={carFile.url} />
                            </LightgalleryItem>
                            {/* {   carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : carFile.status == 'Uploading' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : null
                            } */}
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-6"><p>{carFile.name}</p></div>
                                { carFile.status == 'Done' || carFile.status == 'Error' ?
                                    <div className="col-6 text-right">
                                    
                                        {
                                            carFile.status == 'Done' ?
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                        : carFile.status == 'Error' ?
                                            <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                        : null
                                        }
                                        
                                        
                                    </div>
                                    : null
                                }
                                
                                
                            </div>
                            
                        </div>
                    </div>
        })

        const editedUrl = this.props.filesList.map((carFile,i) => {
            return <div key={"edited_url_" + i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            {/* {this.props.bulkDownloadDone || carFile.status == 'Uploading' ? null : 
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                        <svg onClick={() => this.props.getRefundModal(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <img onClick={() => this.props.getRefundModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" />
                                    </span>
                                </div>
                            } */}
                                
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Uploading' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Error' ?
                                <img onClick={this.props.bulkUploadAPI(i)} style={{maxHeight: "80px"},{objectFit: "contain"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                            : carFile.status == 'Done' ?
                               
                                <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.fullScreenOutput}>
                                     <img src={carFile.outputImgUrl} />
                                </LightgalleryItem>
                            : null
                            }
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : carFile.status == 'Uploading' ?
                            <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : null
                            }
                            
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                                {/* <div className="col-6 text-right">
                                    
                                    { carFile.status == 'Done' ?
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                    : carFile.status == 'Error' ?
                                        <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                    : null
                                    }
                                    
                                    
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
        })


        const editedUrlInterior = this.props.filesListInterior.map((carFile,i) => {
            return <div key={"edited_url_" + i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            {/* {this.props.bulkDownloadDone || carFile.status == 'Uploading' ? null : 
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span>
                                        <svg onClick={() => this.props.getRefundModal(i)} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31524 1L1.11011 9.20513" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke={this.state.themeColor} stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <img onClick={() => this.props.getRefundModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" />
                                    </span>
                                </div>
                            } */}
                                
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Uploading' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Error' ?
                                <img onClick={this.props.bulkUploadAPIInterior(i)} style={{maxHeight: "80px"},{objectFit: "contain"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                            : carFile.status == 'Done' ?
                               
                                <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.fullScreenOutput}>
                                     <img src={carFile.outputImgUrl} />
                                </LightgalleryItem>
                            : null
                            }
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : carFile.status == 'Uploading' ?
                            <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : null
                            }
                            
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                                {/* <div className="col-6 text-right">
                                    
                                    { carFile.status == 'Done' ?
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                    : carFile.status == 'Error' ?
                                        <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                    : null
                                    }
                                    
                                    
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
        })
        const backLeft = this.props.planData.length - 8
        return (
            <div>
                <section className={[styles['perfect-output'],''].join(' ')}>
                 
                    <div className={[styles[''],''].join(' ')}>
                        {this.props.uploadStarteds ?
                            <div className={[styles[''],'row'].join(' ')}>
                                <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                                    <div onClick={this.props.handleRedirect}className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload New Project</button>
                                                    </div>
                                    {/* <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                <div {...getRootProps({})} className={styles['dropzone1']}>
                                                    <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload New Project</button>
                                                    </div>
                                                    <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone> */}
                                    
                                    <ul className={[styles['change-tabs'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activeButtonNew == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                                <span>Original</span>
                                            </button>
                                        </li>
                                        <li className={[styles[''],''].join(' ')}>
                                            <button className={this.state.activeButtonNew == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                                <span>Edited</span>   
                                            </button>
                                        </li>
                                    </ul>
                                
                                    {

                                        this.state.activeButtonNew == "Original" ?
                                        
                                            <div className={[styles['tab-content'],''].join(' ')}>
                                               
                                                {this.props.filesList.length===0?
                                                    <div className={styles['container-fluid']}>
                                                    <div className={[styles['interior-null'],'mb-3 row'].join(' ')}>
                                                        <h1>Interior</h1>
                                                            <div className="row">
                                                                
                                                                <LightgalleryProvider
                                                                    lightgallerySettings={{
                                                                        "download": false,
                                                                        "thumbnail": false,
                                                                        "fullScreen": true,
                                                                        "zoom": true
                                                                    }}
                                                                >
                                                                    {orgCarsUrlInterior}
                                                                </LightgalleryProvider>
                                                            </div>
                                                    </div>
                                                    </div>
                                                :

                                                    
                                                        this.props.filesListInterior.length===0 ?
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                            <h1>Exterior</h1>
                                                                <div className="row">
                                                                    
                                                                    <LightgalleryProvider
                                                                        lightgallerySettings={{
                                                                            "download": false,
                                                                            "thumbnail": false,
                                                                            "fullScreen": true,
                                                                            "zoom": true
                                                                        }}
                                                                    >
                                                                        {orgCarsUrl}
                                                                    </LightgalleryProvider>
                                                                </div>
                                                        </div>
                                                        </div>

                                                    :
                                                        <>
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                            <h1>Exterior</h1>
                                                                    <div className="row">
                                                                        
                                                                        <LightgalleryProvider
                                                                            lightgallerySettings={{
                                                                                "download": false,
                                                                                "thumbnail": false,
                                                                                "fullScreen": true,
                                                                                "zoom": true
                                                                            }}
                                                                        >
                                                                            {orgCarsUrl}
                                                                        </LightgalleryProvider>
                                                                    </div>
                                                                    </div>            
                                                        </div>  
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['interior-null'],'mb-3 row'].join(' ')}>
                                                            <h1>Interior</h1>
                                                                    <div className="row">
                                                                        
                                                                        <LightgalleryProvider
                                                                            lightgallerySettings={{
                                                                                "download": false,
                                                                                "thumbnail": false,
                                                                                "fullScreen": true,
                                                                                "zoom": true
                                                                            }}
                                                                        >
                                                                            {orgCarsUrlInterior}
                                                                        </LightgalleryProvider>
                                                                    </div>
                                                        </div>        
                                                        </div>
                                                         </>
                                                    
                                                    
                                                    
                                                
                                                }
                                        
                                        
                                            </div>
                                    : 
                                    
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                              {this.props.filesList.length===0?
                                                    <div className={styles['container-fluid']}>
                                                    <div className={[styles['interior-null'],'mb-3 row'].join(' ')}>
                                                        <h1>Interior</h1>
                                                            <div className="row">
                                                                
                                                                <LightgalleryProvider
                                                                    lightgallerySettings={{
                                                                        "download": false,
                                                                        "thumbnail": false,
                                                                        "fullScreen": true,
                                                                        "zoom": true
                                                                    }}
                                                                >
                                                                    {editedUrlInterior}
                                                                </LightgalleryProvider>
                                                            </div>
                                                    </div>        
                                                    </div>
                                                :

                                                    
                                                        this.props.filesListInterior.length===0 ?
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                        <h1>Exterior</h1>
                                                            <div className="row">
                                                                
                                                                <LightgalleryProvider
                                                                    lightgallerySettings={{
                                                                        "download": false,
                                                                        "thumbnail": false,
                                                                        "fullScreen": true,
                                                                        "zoom": true
                                                                    }}
                                                                >
                                                                    {editedUrl}
                                                                </LightgalleryProvider>
                                                            </div>
                                                        </div>
                                                        </div>

                                                    :
                                                        <>
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                        <h1>Exterior</h1>
                                                                <div className="row">
                                                                    
                                                                    <LightgalleryProvider
                                                                        lightgallerySettings={{
                                                                            "download": false,
                                                                            "thumbnail": false,
                                                                            "fullScreen": true,
                                                                            "zoom": true
                                                                        }}
                                                                    >
                                                                        {editedUrl}
                                                                    </LightgalleryProvider>
                                                                </div>
                                                        </div>        
                                                        </div>  
                                                        <div className={styles['container-fluid']}>
                                                        <div className={[styles['interior-null'],'mb-3 row'].join(' ')}>
                                                        <h1>Interior</h1>
                                                                <div className="row">
                                                                    
                                                                    <LightgalleryProvider
                                                                        lightgallerySettings={{
                                                                            "download": false,
                                                                            "thumbnail": false,
                                                                            "fullScreen": true,
                                                                            "zoom": true
                                                                        }}
                                                                    >
                                                                        {editedUrlInterior}
                                                                    </LightgalleryProvider>
                                                                </div>
                                                                </div>
                                                        </div>
                                                         </>
                                                    
                                                    
                                                    
                                                
                                                }
                                    </div>
                                    
                                    }
                                
                                    
                                </div>
                                

                                <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                    <div className={styles['bulk-right-box']}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles['wallet-icon'],'col-md-8'].join(' ')}>
                                            <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M40.2146 9.79051C39.704 9.36483 39.0364 9.08105 38.2902 9.08105C38.3295 9.08105 38.3688 9.08105 38.408 9.08105V5.42735C38.408 3.93749 37.7404 2.58952 36.6408 1.59628C35.5412 0.603038 34.0489 0 32.3994 0H6.00862C4.3592 0 2.86686 0.603038 1.76724 1.59628C0.667625 2.58952 0 3.93749 0 5.42735V23.7668C0 25.2567 0.667625 26.6046 1.76724 27.5979C2.86686 28.5911 4.3592 29.1942 6.00862 29.1942H32.3994C34.0489 29.1942 35.5412 28.5911 36.6408 27.5979C37.7404 26.6046 38.408 25.2567 38.408 23.7668V20.0776C38.3688 20.0776 38.3295 20.0776 38.2902 20.0776C39.0364 20.0776 39.704 19.7938 40.2146 19.3682C40.6858 18.9425 41 18.304 41 17.63V11.5287C41 10.8547 40.6858 10.2517 40.2146 9.79051ZM36.2481 23.7313C36.2481 24.6891 35.8161 25.5404 35.1092 26.179C34.4023 26.8175 33.4598 27.2077 32.3994 27.2077H6.00862C4.94828 27.2077 4.00575 26.8175 3.29885 26.179C2.59195 25.5404 2.15996 24.6891 2.15996 23.7313V5.42735C2.15996 4.46958 2.59195 3.61823 3.29885 2.97972C4.00575 2.34121 4.94828 1.95101 6.00862 1.95101H32.3994C33.4598 1.95101 34.4023 2.34121 35.1092 2.97972C35.8161 3.61823 36.2481 4.46958 36.2481 5.42735V9.08105H24.0738C22.4243 9.08105 20.9713 9.68409 19.8716 10.6419C18.8113 11.5996 18.1437 12.9476 18.1437 14.4374V14.6858C18.1437 16.1756 18.8113 17.4881 19.8716 18.4814C20.932 19.4391 22.4243 20.0422 24.0738 20.0422H36.2481V23.7313ZM38.84 17.63C38.84 17.7719 38.7615 17.9138 38.6829 17.9847C38.5651 18.0912 38.4473 18.1266 38.2902 18.1266H24.0738C23.0527 18.1266 22.0709 17.7364 21.4033 17.1334C20.7356 16.5303 20.3036 15.6435 20.3036 14.7212V14.4729C20.3036 13.5152 20.7356 12.6638 21.4033 12.0608C22.1102 11.4223 23.0527 11.0675 24.0738 11.0675H38.2902C38.4473 11.0675 38.6044 11.1385 38.6829 11.2094C38.8008 11.3158 38.84 11.4223 38.84 11.5641V17.63Z" 
                                                fill={this.state.themeColor}/>
                                            </svg>
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        {/* <div className={[styles['right-top-credits'],'col-md-4'].join(' ')} >
                                            <a href="https://www.spyne.ai/tech/pricing"  >
                                            <button >
                                            
                                            <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                            </a>
                                            </div> */}
                                    </div>
                                    
                                        
                                        
                                        <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}> 
                                                        
                                            <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                                <div className={[styles['background-grid'],'col-md-12'].join(' ')}> 
                                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                                        
                                                        {/* {this.props.bulkDownloadDone ?
                                                            <div><a href={this.props.downloadZipLink} target="_blank"></a></div>
                                                            :
                                                            <div>
                                                                <h6>Unsatisfied with the Output ?</h6>
                                                                <p>Click
                                                                    <span>
                                                                        <svg  width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9.31524 1L1.11011 9.20513" stroke="#214594" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            <path d="M1.79639 0.999023L10.0015 9.20415" stroke="#214594" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    </span>
                                                                    on Images to  request refund  </p>
                                                            </div>
                                                            
                                                        } */}
                                                        
                                                    </div>
                                                    <div className={[styles['download-card-gif'],'row m-0'].join(' ')}>
                                                        {/* <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif"/>    */}
                                                        {this.props.isZipReady  ?
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif"/>
                                                            :
                                                            <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images-gif.gif"/>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                                    
                                        </div>
                                    
                                        
                                        <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                            <button onClick={this.props.downloadImages} disabled = {this.props.isZipReady ? false : true} >Download All</button>
                                            {/* <div>
                                                <h6>Unsatisfied with the Output ?</h6>
                                                <p>CLick
                                                    <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/></span>
                                                    on Images to  request refund  </p>
                                            </div> */}
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <RefundModal 
                                          handleRefundModal = {this.props.handleRefundModal}
                                          showRefundModal = {this.props.showRefundModal}
                                          userId = {this.props.userId}
                                          creditUsed={this.props.creditUsed}
                                          availableCredit={this.props.availableCredit}
                                          allotedCredit={this.props.allotedCredit}  
                                          refundImgUrl={this.props.refundImgUrl}   
                                          refundOrgUrl={this.props.refundOrgUrl} 
                                          refundImgId={this.props.refundImgId}    
                                          updateEditedImageList={this.props.updateEditedImageList} 
                                          auth_key={this.props.auth_key}     
                                          emailId={this.props.emailId}                                         
                                          />

                                
                                
                            </div>
                        :
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>

                            <div onClick={this.props.handleRedirect} className={[styles['choose-button'],'text-center '].join(' ')}>
                                                        
                                                        <button className="">
                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                            Upload New Project</button>
                            </div>
                                
                                {/* <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)} >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                <div className={[styles['choose-button'],'text-center '].join(' ')}>
                                                    
                                                    <button className="">
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                         Upload New Project</button>
                                                </div>
                                                <input  onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone> */}
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                    <li className={[styles[''],''].join(' ')}>
                                        <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")} disabled> 
                                            <span>Edited</span>   
                                        </button>
                                    </li>
                                </ul>
                                {/* {this.state.activeButton == "Original" ?
                                    <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                            {({getRootProps, getInputProps}) => (
                                                <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                    <div {...getRootProps({})} className={styles['dropzone1']}>
                                                        <div className={[styles['add-more'],''].join(' ')} >
                                                            
                                                            <button >+</button>
                                                            
                                                        </div>
                                                        <img className={[styles['more-text'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    
                                    : null
                                } */}
                               
                                {
                                    this.state.activeButton == "Original" ?
                                    <div className={[styles['tab-content'],''].join(' ')}>
                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['exterior-notnull'],'mb-3 row'].join(' ')}>
                                                <h1>Exterior</h1>
                                                <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className={[styles['add-section'],''].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['']}>
                                                                <h6><button >+</button> (Images will be processed using AI)</h6>
                                                                    
                                                                
                                                                <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            

                                                {this.props.filesList.length===0 ?
                                                    <div className="row">
                                                        <div className={[styles['null-text'],'col'].join(' ')}>
                                                        No Exterior Images Uploaded yet
                                                        </div>
                                                    </div>
                                                : 
                                                    <div className="row">
                                                            <LightgalleryProvider
                                                                lightgallerySettings={{
                                                                    "download": false,
                                                                    "thumbnail": false,
                                                                    "fullScreen": false,
                                                                    "zoom": false
                                                                }}
                                                            >
                                                            
                                                            {orgCars}
                                                            

                                                            </LightgalleryProvider>
                                                    </div> 
                                                }
                                            </div>
                                        </div>
                                        <div className={styles['container-fluid']}>
                                            <div className={[styles['interior-null'],'row'].join(' ')}>
                                                <h1>Interior</h1>
                                                <Dropzone onDrop={acceptedFiles => this.fileHandlerInterior(acceptedFiles)} >
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className={[styles['add-section'],''].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['']}>
                                                                <h6><button >+</button> (Images will be <span>not</span> processed using AI)</h6>
                                                                
                                                                <img className={[styles['add-more-hover-box'],''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png"/>
                                                                <input  onChange={this.fileHandlerInterior} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                {
                                                    this.props.filesListInterior.length===0?
                                                    <div className="row">
                                                        <div className={[styles['null-text'],'col'].join(' ')}>
                                                            No Interior Images Uploaded yet
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <LightgalleryProvider
                                                            lightgallerySettings={{
                                                                "download": false,
                                                                "thumbnail": false,
                                                                "fullScreen": false,
                                                                "zoom": false
                                                            }}
                                                        >
                                                        
                                                        {orgCarsInterior}
                                                        

                                                        </LightgalleryProvider>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                            
                                         
                                        
                                    
                                    </div>
                                :
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    
                                    
                                </div>
                                
                                
                                }
                               
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'row'].join(' ')}>
                                        <div style={{"marginTop" : "8px","fontSize" : "13px"}} className={[styles['wallet-icon'],'col-md-8'].join(' ')}>
                                            <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M40.2146 9.79051C39.704 9.36483 39.0364 9.08105 38.2902 9.08105C38.3295 9.08105 38.3688 9.08105 38.408 9.08105V5.42735C38.408 3.93749 37.7404 2.58952 36.6408 1.59628C35.5412 0.603038 34.0489 0 32.3994 0H6.00862C4.3592 0 2.86686 0.603038 1.76724 1.59628C0.667625 2.58952 0 3.93749 0 5.42735V23.7668C0 25.2567 0.667625 26.6046 1.76724 27.5979C2.86686 28.5911 4.3592 29.1942 6.00862 29.1942H32.3994C34.0489 29.1942 35.5412 28.5911 36.6408 27.5979C37.7404 26.6046 38.408 25.2567 38.408 23.7668V20.0776C38.3688 20.0776 38.3295 20.0776 38.2902 20.0776C39.0364 20.0776 39.704 19.7938 40.2146 19.3682C40.6858 18.9425 41 18.304 41 17.63V11.5287C41 10.8547 40.6858 10.2517 40.2146 9.79051ZM36.2481 23.7313C36.2481 24.6891 35.8161 25.5404 35.1092 26.179C34.4023 26.8175 33.4598 27.2077 32.3994 27.2077H6.00862C4.94828 27.2077 4.00575 26.8175 3.29885 26.179C2.59195 25.5404 2.15996 24.6891 2.15996 23.7313V5.42735C2.15996 4.46958 2.59195 3.61823 3.29885 2.97972C4.00575 2.34121 4.94828 1.95101 6.00862 1.95101H32.3994C33.4598 1.95101 34.4023 2.34121 35.1092 2.97972C35.8161 3.61823 36.2481 4.46958 36.2481 5.42735V9.08105H24.0738C22.4243 9.08105 20.9713 9.68409 19.8716 10.6419C18.8113 11.5996 18.1437 12.9476 18.1437 14.4374V14.6858C18.1437 16.1756 18.8113 17.4881 19.8716 18.4814C20.932 19.4391 22.4243 20.0422 24.0738 20.0422H36.2481V23.7313ZM38.84 17.63C38.84 17.7719 38.7615 17.9138 38.6829 17.9847C38.5651 18.0912 38.4473 18.1266 38.2902 18.1266H24.0738C23.0527 18.1266 22.0709 17.7364 21.4033 17.1334C20.7356 16.5303 20.3036 15.6435 20.3036 14.7212V14.4729C20.3036 13.5152 20.7356 12.6638 21.4033 12.0608C22.1102 11.4223 23.0527 11.0675 24.0738 11.0675H38.2902C38.4473 11.0675 38.6044 11.1385 38.6829 11.2094C38.8008 11.3158 38.84 11.4223 38.84 11.5641V17.63Z" 
                                                fill={this.state.themeColor}/>
                                            </svg>
                                            {/* <sub>{this.props.availableCredit}</sub> */}
                                            Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                        </div>
                                        {/* <div className={[styles['right-top-credits'],'col-md-4'].join(' ')} >
                                            <a href="https://www.spyne.ai/tech/pricing"  >
                                            <button >
                                            <img style={{"marginRight" : "4px"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add%20credits%20gif.gif"/>
                                            <span className={[styles['add-credit'],""].join(" ")} > Add Credits</span> </button>
                                            </a>
                                            </div> */}
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}>
                                        <h6 className={[styles['bg-title'],''].join(' ')} >Backgrounds </h6>
                                                     
                                        <div className={styles['backgrounds-grid']}>
                                                    {
                                                      this.props.planData.slice(0,8).map((e,i) => {
                                                        return (
                                                          <div className={this.props.background_id == e.imageId && this.state.below_bg==false ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackground(e.imageId,e.imageCredit,false)}>
                                                            <img alt="" src={e.imageUrl}/>
                                                            <p className={[styles['back-credit'],styles['bcredit'],'col-12'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                              <p className={[styles['back-name'],'col-12 col-md-8'].join(' ')}>{e.bgName}</p>
                                                              <p className={[styles['back-credit'],styles['backcredit'],'col-4 col-md-4'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                                <span></span></p>
                                                            </div>
                                                          </div>

                                                        )
                                                      })
                                                    }
                                                    {this.props.planData.length > 8 ?
                                                        <div className={styles['inactive-background-sec']} >
                                                            <div className={styles['overlay-pos']} onClick={this.handleBackgroundList}>
                                                            <img alt="" src="https://storage.googleapis.com/clippr-image-processing/backgrounds/radiant%20slate.jpg"/>
                                                            <div className={styles['img-overlay']}></div>
                                                            <div className={styles['more-btn-overlay']}>+ {backLeft} More</div>
                                                            </div>
                                                            
                                                        </div>
                                                        : null
                                                    }
                                                    
                                                </div>
                                                 
                                    </div>
                                    {/* <div className={[styles['logo-sec'],'col-md-12'].join(' ')}>
                                        <div  className={[styles['dealership-logo-section'],''].join(' ')}>
                                                    <DealershipLogo   
                                                        dealerLogoFileHandler = {this.props.dealerLogoFileHandler}
                                                        handleLogoPosition = {this.props.handleLogoPosition}
                                                        logoUrl = {this.props.logoUrl}
                                                        logoPosition = {this.props.logoPosition}
                                                        hideLogo={this.props.hideLogo}
                                                        handleLogo={this.props.handleLogo}
                                                    />
                                         </div>
                                        
                                    </div>  */}
                                    {/* <div className={[styles['checkbox-section'],'col-md-12'].join(' ')}>
                                        <div className="row">
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                 <label className={styles['container-2']}>Color Enhancement
                                                <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement}/>
                                                <span className={styles['checkmark']}></span>
                                                </label>
                                            </div>
                                            <div className={[styles['check-window'],'col-6'].join(' ')}>
                                                
                                                <label className={styles['container-2']}>Window Transparency
                                                <input type="checkbox" onChange={this.props.handleWindowTransparency} checked={this.props.checkWindowTransparency}/>
                                                <span className={styles['checkmark']} style={{left: "5%"}}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                    { this.props.filesList.length===0 ?
                                        <div className={[styles['apply-btn-section'],'col-md-12',].join(' ')} >
                                        <button style={{background:"red"}} disabled={this.props.filesList.length===0} onClick={this.props.creditVerify}>Add Exterior Images First  </button>
                                        </div>
                                      
                                    :
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button disabled={this.props.filesList.length===0} onClick={this.props.creditVerify}>Apply<span style={{"fontSize" : "14px"}}>({this.props.filesList.length} Credits)</span>  </button>
                                    </div>
                                    }
                                    <BackgroundListModalBulk 
                                            handleBackground = {this.handleBackground}
                                            background_id={this.props.background_id} 
                                            planData={this.props.planData} hideList={this.hideList} 
                                            showBackgroundList={this.state.showBackgroundList} 
                                            orgUrl={this.props.orgUrl}
                                            applyChange={this.applyChange}
                                            creditVerify = {this.props.creditVerify}/>
                                    <UploadLimitModal 
                                          maxUpload = {this.props.maxUpload}  
                                          handleUploadLimitModal = {this.props.handleUploadLimitModal}
                                          emptyMultipleFileArray = {this.props.emptyMultipleFileArray}
                                          />
                                    <BulkCreditModal 
                                          showBulkCreditModal = {this.props.showBulkCreditModal}  
                                          handleBulkCreditModal = {this.props.handleBulkCreditModal}
                                          notEnoughCreditModal={this.props.notEnoughCreditModal}
                                          enoughCreditModal={this.props.enoughCreditModal}
                                          zeroCreditModal={this.props.zeroCreditModal}
                                          hitUploadAPI={this.props.hitUploadAPI}
                                          filesList={this.props.filesList}
                                          availableCredit={this.props.availableCredit}
                                          getUserCredit={this.props.getUserCredit}
                                          />
                                    <ProjectNameModal 
                                          showProjectNameModal = {this.props.showProjectNameModal}  
                                          handleProjectNameModal = {this.props.handleProjectNameModal}
                                          handleProjectName={this.props.handleProjectName}
                                          project_name={this.props.project_name}
                                          projectNameExists = {this.props.projectNameExists}
                                          />
                                </div>
                            </div>
                        </div>
                            
                        }
                        
                    </div>
                </section>
            </div>
        )
    }
}
