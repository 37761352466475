import React, { Component } from "react";
import useState from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Form, ProgressBar, Spinner } from "react-bootstrap";
import queryString from 'query-string'
import loadImage from "blueimp-load-image";
import { fetchCreditUserDetails, updateUserCredit, cookie, poll, chunkArray } from '../../Common/Utils'
import LandingModal from "../../Common/Modal/LandingModalFashion";
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider'
import fs from 'fs'
import { copyFileSync } from "fs";
import styles from '../../../Css/3DBuild/CreateModel.module.css'
import YoutubeGif from "../../Common/Modal/youtubeGif";
import SareeModal from "../../Common/Modal/SareeModal"
import BlouseModal from "../../Common/Modal/BlouseModal"
import SelectModel from "./SelectModel";
import { textAlign } from "@material-ui/system";
import UploadSaree from "./UploadSaree";
import KurtiModal from "../Suit/UploadSuit"
import { delay } from "q";
import CurtainModel from "../Curtains/uploadCurtains"
import alertify from 'alertifyjs';
import Drawer from '../../Drawer/Drawer'
import * as Sentry from "@sentry/react"

class CreateModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      selectedFile: null,
      selectedFileBlouse: null,
      url: [],
      orgUrl: "",
      orgUrlBlouse: "",
      switchScreen: false,
      imageCategory: "",
      loader: false,
      imageWidth: "",
      imageHeight: "",
      productHunt: 'clippr',
      showLoginModal: true,
      auth_key: '',
      emailId: '',
      availableCredit: 0,
      dropBackground: false,
      creditAlloted: 0,
      creditUsed: 0,
      wm_image: [],
      hd_image: [],
      source: this.props.match.params,
      isUploadBlouse: false,
      showYoutubeGifModal: false,
      showSareeModal: false,
      isProjectReady: false,
      isSaree: false,
      selected: false,
      uploadScreen: false,
      sareeSpinner: false,
      posearr: [],
      skuId: '',
      projectId: '',
      downloadZipLink: '',
      forDublicateProjectId: 1,
      backButton:false,
      bgCredit:0,
      downloadType:'',
      poselength:0,
      checkColorEnhancement:false,


    };
  }

  cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]


  handelColorEnhancement = () => {
    this.setState({
      checkColorEnhancement:!this.state.checkColorEnhancement
    })
  }

  handleDownloadComplete = () => {
    this.setState({downloadComplete: false})
  }


  handleDownloadModal = async (DT) =>{

    let userId = this.cookie('userId')
    let price
    await this.setState({bgCredit:this.state.poselength,downloadType:DT});
    if(this.state.downloadType == 'HD' && this.state.availableCredit >= this.state.bgCredit){
    
    price = this.state.bgCredit;
    axios({
     method: "GET",
     url : "https://www.clippr.ai/api/v4/download-history",
     params : {
         user_id : userId,
         enterprise_id : 'TaD1VC1Ko',
         sku_id: this.state.skuId[0]
     }
     
    }).then( resp => {
     if(resp.data.status == 404){
         let auth_key = this.cookie('auth_token')
         const fd = new FormData()
         fd.append("auth_key", auth_key)
         fd.append("credit_reduce",price)
         axios.put(`https://www.clippr.ai/api/v2/credit/reduce-credit`,fd)
         .then( (res) => {
             this.setState({
                 availableCredit: this.state.availableCredit - price,
             })
            for(var i=0;i<this.state.hd_image.length;i++){
            var output_link = this.state.hd_image[i];
            fetch(output_link, {
                method: "GET",
                headers: {}
                })
                .then(response => {
                    response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    let name = output_link
                    var index = name.lastIndexOf("/") + 1;
                    var filename = name.substr(index)
                    link.setAttribute("download", filename); 
                    document.body.appendChild(link);
                    link.click();       
                });
                this.setState({
                  downloadComplete:true
                })
                }).catch((err) => alertify.error("Something went wrong! Please reload"))
                const fd = new FormData()
                 fd.append("user_id", userId)
                 fd.append("sku_id",this.state.skuId[0])
                 fd.append("enterprise_id" , 'TaD1VC1Ko')
                 fd.append("download_hd","true")
                 axios.post(`https://www.clippr.ai/api/v4/update-download-status`,fd)
            }

}).catch((err) => alertify.error("Something went wrong! Please reload"))
}else{
  for(var i=0;i<this.state.hd_image.length;i++){
    var output_link = this.state.hd_image[i];
    fetch(output_link, {
        method: "GET",
        headers: {}
        })
        .then(response => {
            response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            let name = output_link
            var index = name.lastIndexOf("/") + 1;
            var filename = name.substr(index)
            link.setAttribute("download", filename); 
            document.body.appendChild(link);
            link.click();       
        });
        this.setState({
          downloadComplete:true
        })
        }).catch((err) => alertify.error("Something went wrong! Please reload"))
    }

}
    }).catch((err) => alertify.error("Something went wrong! Please reload"))
}
else {
for(var i=0;i<this.state.wm_image.length;i++){
    var output_link = this.state.wm_image[i];
    fetch(output_link, {
        method: "GET",
        headers: {}
        })
        .then(response => {
            response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            let name = output_link
            var index = name.lastIndexOf("/") + 1;
            var filename = name.substr(index)
            link.setAttribute("download", filename); 
            document.body.appendChild(link);
            link.click();       
        });
        this.setState({
          downloadComplete:true
        })
        }).catch((err) => alertify.error("Something went wrong! Please reload"))
    }
}
}

  downloadImages = () => {

    axios({
      method: "GET",
      url: "https://www.spyne.ai/download/shoot/ai-images",
      params: {
        skuId: this.state.skuId[0],
        projectId: this.state.projectId[0],
      }
    })
      .then(resp => {
        this.setState({
          downloadZipLink: resp.data
        })
        const a = document.createElement("a")
        a.href = resp.data
        a.download = true
        a.click()
      })
  }

  
  handleUploadScreen = (pose, modelId) => {
    this.setState(
      {
        uploadScreen: true,
        poselength:pose.length,
      },
      () => {
        this.fileUploadHandler(pose, modelId);
      })
  }


  getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  fileUploadHandler = async (pose, modelId) => {
    this.setState({
      url: [],
      wm_image: [],
      hd_image: [],
      orgUrl: [],
      skuId: [],
      projectId: []

    })
  
    this.setState({ posearr: pose });
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          uploadPercentage: percentCompleted,
        });
        if (percentCompleted === 100) {
          this.setState({
            uploading: false,
            processing: true,
          });
        }
      }.bind(this),
    };


    try {
      let uuid = this.getRandomString(8);
      let auth_key = this.cookie('auth_token')
      let _userId_ = this.cookie("userId")
 
      this.setState({
        sareeSpinner: true
      })
      for (var i = 0; i < pose.length; i++) {
        var data = new FormData();
        data.append('auth_key', auth_key);
        data.append('pose_id', pose[i]);
        data.append('model_id', modelId);
        data.append('frame_seq_no', i + 1);
        data.append('total_project_count', pose.length);
        data.append('prod_cat_id', 'cat_skJ7HIvnc');
        data.append('prod_sub_cat_id', 'prod_WiE3dLKOs');
        data.append('image_file', this.state.selectedFile);
        data.append('category', "SareeBody");
        data.append('source', 'BULKPROCESS');
        data.append('is_color',this.state.checkColorEnhancement)
        var config_ph = {
          method: 'post',
          url:  `${process.env.REACT_APP_BASEURL}/v2/rendering/imageProcessSaree`,

          headers: {
          },
          data: data
        };
        axios(config_ph)
          .then((res) => {
            this.setState({
              url: [...this.state.url, res?.data?.data?.display_image],
              wm_image: [...this.state.wm_image, res?.data?.data?.watermark_image],
              hd_image: [...this.state.hd_image, res?.data?.data?.output_image],
              orgUrl: [res?.data?.data?.original_image],
              skuId: [res?.data?.data?.sku_id],
              projectId: [res?.data?.data?.project_id]


            });

            try {
              localStorage.setItem("urlbg", res.data.data.display_image);
              let editedImages;
              editedImages = localStorage.getItem("editedImagesBgr");
              editedImages = editedImages ? editedImages.split(",") : [];
              editedImages.push(res.data.display_image);
              localStorage.setItem(
                "editedImagesBgr",
                editedImages.toString()
              );
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
            this.setState({
              orgImgShow: true,
              processing: false,
              loader: false,
              editedState: true,
              uploadPercentage: 0,
              tabIndex: 1,

            });
          });
        await delay(7000)
      }
      this.setState({ sareeSpinner: false })
    } catch (e) {
      this.setState({
        isError: true,
        uploading: false,
        processing: false,
        loader: false,
        tabIndex: 0,
      });
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      loader: true,
      dropBackground: false,
      isProjectReady: false,
      showSareeModal: true,
    });
    if (event.target) {
      var file = event.target.files[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event.target.files[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,
        },
        () => {
          this.fileUploadHandler(1);
        }
      );
    } else {
      let file = event[0];
      if (Math.round(file.size / 1024) > 30720) {
        return;
      }
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState(
          {
            orgUrl: [reader.result],
          },
          () => {
            try {
              localStorage.setItem("orgUrlBg", this.state.orgUrl);
            } catch (e) {
              if (e == "QUOTA_EXCEEDED_ERR") {
              }
            }
          }
        );
      }.bind(this);
      let editedImages;
      editedImages = localStorage.getItem("editedImagesBgr");
      editedImages = editedImages ? editedImages.split(",") : [];
      this.setState(
        {
          selectedFile: event[0],
          editing: true,
          editedImagesArr: [...editedImages],
          uploading: true,
          loader: true,
          uploadBox: false,

        },
      );
    }
  };

  componentDidMount = async () => {
    let auth_key = this.cookie('auth_token')
    this.setState({
      auth_key: auth_key
    })
    var passing_location;


    passing_location = 'NULL'
    localStorage.setItem("passing_location", passing_location);


    if (window.localStorage.getItem("clippr_user_email") != null) {
      let dict2 = {};
      var userEmail = this.cookie("emailId")
      this.setState({
        emailId: userEmail
      })
      let _userId_ = this.cookie("userId")
      let isValidated = this.cookie("isValidated")

      dict2['authenticated_email_id'] = userEmail;
      dict2['user_id'] = _userId_;
      dict2['product_category'] = 'Cars Replacement';
      dict2['passing_location'] = window.localStorage.getItem("passing_location");
      dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");

      if (isValidated == 'true') {
        dict2['otp_verified'] = 'YES';
      }
      else {
        dict2['otp_verified'] = 'NO';
      }

      axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
        .then((res) => {
        })
        .catch((err) => {
        })
    }
    var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
    if (instructionModalCheck) {
      this.setState({
        showInstruction: false
      })
    }
    else {
      this.setState({
        showInstruction: true
      })
    }



    let userId = this.cookie('userId')
    if (userId !== "NA") {

      let emailId = this.cookie("emailId")
      let auth_key = this.cookie("auth_token")
      axios({
        method: "GET",
        url: "https://www.clippr.ai/api/v2/user/get",
        params: {
          email_id: emailId,
          auth_key: auth_key
        }

      }).then(resp => {
        if (resp.data.status == 200) {
          this.setState({
            availableCredit: resp.data.data.credit_available,
            creditAlloted: resp.data.data.credit_allotted,
            creditUsed: resp.data.data.credit_used
          })
        } else {
        }
      })
      this.setState({
        userId: userId
      })
    }
    if (this.state.uploadStarteds === "true") {
      this.getUserCredit()
    }

  }

  cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]

  hideLandingModal = () => {
    this.setState({ showLoginModal: !this.state.showLoginModal })
  }

  dragStart = (e) => {
    e.preventDefault()
    this.state.dropBackground = true
  }
  dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
  }
  backToUpload = () => {
    this.setState({isProjectReady:false})
  }



  handleSareeModal = () => {    // After Clicking cross Button on saree Modal
    this.setState(
      {
        showSareeModal: !this.state.showSareeModal,
      }
    )
  }


  handleSareeUpload = () => {   //For opening saree Modal
    this.setState(
      {
        showSareeModal: true,
      }
    )
  }

  handleFinishSareeUpload = () => {    // // After Clicking Upload Button on saree Modal
    this.setState(
      {
        isProjectReady: true,
        uploadScreen: false
      },
    )

  }







  render() {
    return (

      <>
      
        {
          this.state.showLoginModal ?
            <LandingModal
              showInstruction={this.state.showLoginModal}
              hideModal={this.hideLandingModal}
              productHunt={this.state.productHunt}

            />
            : null
        }
        {
          !this.state.isProjectReady ?
                  <>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.fileSelectedHandler(acceptedFiles)}
                      onDragEnter={this.dragStart}
                      onDragLeave={this.dragLeave}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            onClick: (event) => event.stopPropagation(),
                          })}
                          className={styles["dropzone1"]}
                        >
                          {this.state.dropBackground ? (
                            <section className={[styles["drop-box-text"]]}>
                              <div>Drop Image Anywhere</div>
                            </section>
                          ) : null}

                          {/* Drawer Section  */}
                           <Drawer/>
                          
                          <section className={[styles['upload-screen'], ''].join(' ')}>
                            <div className={[styles[''], 'container-fluid'].join(' ')}>
                              <div className={[styles[''], 'row'].join(' ')}>
                                <div className={[styles['upload'],'col-md-12'].join(' ')}>
                                  <h1>Upload Saree cloth</h1>
                                  <p>How to Shoot your saree? <span>View tutorial</span></p>
                                </div>
                              </div>
                              <>

                                <div className={[styles['crop-saree'], 'row'].join(' ')}>

                                  <div className={[styles[''], 'offset-md-3 col-md-6'].join(' ')}>
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        this.fileSelectedHandler(acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section className={[styles["try-drag-drop"],""].join(" ")} >
                                          <div {...getRootProps({})} className={[styles['upload-cols'], 'row'].join(' ')}>
                                            <div className={[styles['upload-single'], 'col-md-12'].join(' ')}>
                                              <p className={styles['active']}><span>1</span> Upload Saree body </p>
                                            </div>
                                            <div className={[styles['upload-text'], 'col-md-8 offset-md-2'].join(' ')}>
                                              <div className={[styles['upload-box'], ''].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/upld.png" />
                                                <h4>DROP IMAGE</h4>
                                                <p>Supports jpeg, jpg, bmp & png (maximun 36 images)</p>
                                              </div>
                                              <h3>Upload Saree</h3>
                                            </div>

                                            <input
                                              onChange={this.fileSelectedHandler}
                                              ref={(fileInput) =>
                                                (this.fileInput = fileInput)
                                              }
                                              type="file"
                                              {...getInputProps()}
                                            />
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>

                                <SareeModal
                                  hideThisModal={this.handleSareeModal}
                                  showSareeModal={this.state.showSareeModal}
                                  originalImageSaree={this.state.orgUrl}
                                  handleFinishSareeUpload={this.handleFinishSareeUpload}
                                />
                              </>
                            </div>
                          </section>
                          <input
                            onChange={this.fileSelectedHandler}
                            ref={(fileInput) => (this.fileInput = fileInput)}
                            type="file"
                            {...getInputProps()}
                          />

                        </div>
                      )}
                    </Dropzone>
                  </>
                  
              :
              !this.state.uploadScreen ?
                <SelectModel
                  originalImageSaree={this.state.orgUrl}
                  originalImageBlouse={this.state.orgUrlBlouse}
                  fileSelectedHandler={this.fileSelectedHandler}
                  loader={this.state.loader}
                  availableCredit={this.state.availableCredit}
                  handleUploadScreen={this.handleUploadScreen}
                  backToUpload={this.backToUpload}
                  checkColorEnhancement={this.state.checkColorEnhancement}
                  handelColorEnhancement={this.handelColorEnhancement}

                />
                :
                <UploadSaree
                  originalImage={this.state.orgUrl}
                  availableCredit={this.state.availableCredit}
                  editedImage={this.state.url}
                  hd_image={this.state.hd_image}
                  wm_image={this.state.wm_image}
                  fileSelectedHandler={this.fileSelectedHandler}
                  loader={this.state.loader}
                  posearr={this.state.posearr}
                  downloadImages={this.downloadImages}
                  handleDownloadModal={this.handleDownloadModal}
                  downloadComplete={this.state.downloadComplete}
                  handleDownloadComplete={this.handleDownloadComplete}


                />
              }
            </>

    );
  }
}

export default Sentry.withProfiler(CreateModel)
