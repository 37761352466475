import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Spinner } from "react-bootstrap";

import styles from '../../Css/Model/ModelLanding.module.css'
import FootwearUseCases from '../Footwears/FootwearUseCases'
import Footer from '../Common/Footer'
import {Helmet} from "react-helmet";
import LandingModal from '../Common/Modal/LandingModal'
import axios from "axios";

export default class FootwearLanding extends Component {
    constructor(props){
        super(props)
        this.state={
            show:true,
            selectedFile: null,
            url: "",
            orgUrl: "",
            switchScreen:false,
            imageCategory:"",
            loader:false,
            imageWidth:'',
            imageHeight:'',
            showLoginModal:true,
            productHunt: 'clippr',
            availableCredit:0,
            creditAlloted: 0,
            creditUsed: 0,
        }
    }

    componentDidMount = async() => {
      
      let userId = this.cookie('userId')
      if (userId !== "NA") {
        
        let emailId = this.cookie("emailId")
        let auth_key = this.cookie("auth_token")
        axios({
          method: "GET",
          url: process.env.REACT_APP_BASEURL + '/v2/user/get',
          // url: "https://www.clippr.ai/api/v2/user/get",
          params: {
            email_id: emailId,
            auth_key: auth_key
          }
        }).then(resp => {
          if (resp.data.status == 200) {
            this.setState({
              availableCredit: resp.data.data.credit_available,
              creditAlloted: resp.data.data.credit_allotted,
              creditUsed: resp.data.data.credit_used
            })
          } else {
            // console.log(resp)
          }
        })
        this.setState({
          userId: userId
        })
      }
  }

  cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1]



    hideLandingModal = () => {
      this.setState({showLoginModal:!this.state.showLoginModal})
    }

    fileSelectedHandler = (event) => {
        this.setState({
          loader:true
        })
        if(event.target){
        var file = event.target.files[0];
        if (Math.round(file.size / 1024) > 30720) {
          return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState(
            {
              orgUrl: [reader.result],
            },
            () => {
              try {
                localStorage.setItem("orgUrlBg", this.state.orgUrl);
              } catch (e) {
                if (e == "QUOTA_EXCEEDED_ERR") {
                }
              }
            }
          );
        }.bind(this);
        let editedImages;
        editedImages = localStorage.getItem("editedImagesBgr");
        editedImages = editedImages ? editedImages.split(",") : [];
        this.setState(
          {
            selectedFile: event.target.files[0],
            editing: true,
            editedImagesArr: [...editedImages],
            uploading: true,
            loader: true,
            uploadBox: false,
          },
        //   () => {
        //     this.fileUploadHandler(1);
        //   }
        );
        }
        else{
          let file = event[0]
          if (Math.round(file.size / 1024) > 30720) {
            return;
          }
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            this.setState(
              {
                orgUrl: [reader.result],
              },
              () => {
                try {
                  localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                  if (e == "QUOTA_EXCEEDED_ERR") {
                  }
                }
              }
            );
          }.bind(this);
          let editedImages;
          editedImages = localStorage.getItem("editedImagesBgr");
          editedImages = editedImages ? editedImages.split(",") : [];
          this.setState(
            {
              selectedFile: event[0],
              editing: true,
              editedImagesArr: [...editedImages],
              uploading: true,
              loader: true,
              uploadBox: false,
            },
            // () => {
            //   this.fileUploadHandler();
            // }
          );
        }
      };
    render() {
        return (
            <div>
              <Helmet>
                <title>Replace Shoes Background Instantly | Shoe Photo Editor.Try Now</title>
                <meta property="og:title" content="Replace Shoes Background Instantly | Shoe Photo Editor.Try Now"/>
                <meta property="og:description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech. Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click."/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
                
                
            </Helmet>
            {
        this.state.showLoginModal?
        <LandingModal
            showInstruction={this.state.showLoginModal}
            hideModal = {this.hideLandingModal}
            productHunt={this.state.productHunt}

        />
        :null
      }
                <div className={[styles['common-header'],''].join(' ')}>
                <div><a href = '/try-categories'>  
                <button className={[styles['back-button'], ''].join(' ')}>
                  <img src={"https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/backCategory.png"}/>
                </button>
                </a></div>
                    <center>
                        <h1 className={[styles['heading-text']]}>Get Marketplace Ready Images
                        <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png"/> 
                  Available Credits: <b> {this.state.availableCredit}</b></span>
                        </h1>
                        <p>in a <span>Single Click</span> using AI</p>
                    </center>
                </div>
                { this.state.selectedFile == null ?
                <div className={[styles['image-upload'],' container'].join(' ')}>
                    <div className={[styles['left-box'],'col-lg-6 col-md-6 col-sm-12 col-12'].join(' ')}>
                        <div className="outer-box d-dlex flex-row">
                        {/* {!this.state.loader ?
                        <> */}
                            <div className="d-flex outer-box-section-1">
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                        <p className={[styles['drag-text'],''].join(' ')}>Drag Image Here</p>
                                        <p className={[styles['browse-image-text'],' '].join(' ')}>or <span>browse</span> for Image</p>
                                        <p className={[styles['support-image-type'],''].join(' ')}>Support JPG/JPEG file</p>
                                        <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                    </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <input 
                                style={{ display: "none" }}
                                type="file"
                                onChange={this.fileSelectedHandler}
                                ref={(fileInput) => (this.fileInput = fileInput)}
                            ></input>
                            <button  onClick={() => this.fileInput.click()} className="btn btn-primary choose-image-button">Choose Image</button>
                        {/* </>
                        :
                            <Spinner
                            className="loader-edited"
                            style={{
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                            animation="border"
                            variant="primary"
                            />
                        } */}
                        </div>
                    </div>
                    <div className={[styles['right-box'],'col-lg-6 col-md-6 col-sm-12 col-12'].join(' ')}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/jootaa9.png" className={[styles[''],'img-fluid'].join
                    (' ')}></img>
                    </div>
                    {/* <Footer /> */}
                </div>
                :
                <FootwearUseCases
                    originalImage={this.state.orgUrl}
                    selectedFile={this.state.selectedFile}
                />
                }
            </div>
        )
    }
}
