import React, { Component } from "react";
import { Link, useHistory } from 'react-router-dom'

import styles from '../CaseStudies/CaseStudiesLanding.module.css'
import axios from 'axios'


export default class CaseStudiesLanding extends Component {

    constructor(props){
        super(props)
        this.state = {
            whitepaperList:[],
            emailId: ''
        }
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    componentDidMount = () => {

        var emailId = this.cookie('emailId')

        if(emailId){
            this.setState({
                emailId: emailId
            })
        }

        this.getWhitePaperList()
    }

    getWhitePaperList = () => {
        axios({
            'method':'GET',
            'url':"https://www.clippr.ai/api/v2/whitepaper/get",
            
        })
        .then((resp) => {
            this.setState({
                whitepaperList: resp?.data?.data
            })
        })
    }

    handleRedirection = (page_url) => {
        window.location.href = '/whitepapers/details?page_url=' + page_url
    }


    render() {
        return  (
            <div>
                <section className={[styles['case-studies'], ''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            
                            {this.state.whitepaperList.map((e,i) => {
                                return <div key={e.id} className={[styles[''],'col-md-12'].join(' ')}>
                                    <div className={[styles['case-card'],'row'].join(' ')} onClick={() => this.handleRedirection(e.page_url)}>
                                        <div className={[styles['case-studies-img'],'col-md-4'].join(' ')}>
                                            <img src={e.banner_url} className="img-fluid"/>
                                        </div>
                                        <div className={[styles['case-content'],'col-md-8'].join(' ')}>
                                            <h3>{e.title}</h3>
                                            <p>{e.short_description}</p>
                                        </div>
                                    </div>

                                    
                                
                                </div>
                            })}
                        </div>

                    </div>
                </section>

            </div>
        );

    }
}