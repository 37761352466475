import React, { Component } from 'react'
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'


export default class ThreeSixtyView extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Edited",
            orignalTab: "Edited",
            activateButton: "View",
            viewTab: "View"
        }
        
        
    }
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    
    handleViewTab = (name) => {
        this.setState({
            viewTab: name
        })
    }






    render() {
        return (
            <div>
                <section className={[styles['bulk-try'],''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                    <li className={[styles[''],''].join(' ')}>
                                        <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                            <span>Edited</span>   
                                        </button>
                                    </li>
                                </ul>
                               
                                {

                                    this.state.activeButton == "Original" ?
                                    
                                <div className={[styles['tab-content2'],'row'].join(' ')}>
                                     
                                    <div className={[styles['uploaded-cars'],'col-md-12'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                           <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                        </div>
                                    </div>
                                    
                                </div>
                                : this.state.activeButton == "Edited" ?
                                
                                <div className={[styles['tab-content2'],'row'].join(' ')}>
                                    <div className={[styles['uploaded-cars'],'col-md-12'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                           <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/Group%205892907.png" />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                                }
                               
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <ul className={[styles['change-tabs'],styles['change-tab2'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activateButton == "Bulk" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Bulk")}> 
                                                <span>Bulk Processing</span>
                                            </button>
                                        </li>
                                        <li className={[styles[''],''].join(' ')}>
                                            <button className={this.state.activateButton == "View" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("View")}> 
                                                <span>360 View</span>   
                                            </button>
                                        </li>
                                    </ul>
                                    <div className={[styles['credits-available'],'col-md-12 border-0'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                        <sub>15</sub>
                                        Available Credits: &nbsp; <span> 15</span>
                                    </div>
                                    <div className={[styles['sixty-link'],'col-md-12'].join(' ')}> 
                                                     
                                        <div className={[styles['sixty-content'],'row'].join(' ')}>
                                            <div className={[styles['sixty-link-copy'],'col-md-12'].join(' ')}> 
                                                <h4><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/bi_link.png" />Embedded Link</h4>
                                                <p><input type="text" value="https://docs.google.com/forms/d/e/1FAlpQlSCvdvsdfdbbb"/><button>Copy</button></p>
                                                
                                            </div>
                                            <div className={[styles['sixty-link-copy'],'col-md-12'].join(' ')}> 
                                                <h4>&#10094;/&#10097; Embed code</h4>
                                               <p><textarea type="text" value="&lt;iframe width=”560” height=”315 src=”https://www.youtube.com/embeded/In1zeSY5EiI” title=”Youtube video &gt;"/></p>
                                                
                                            </div>
                                            
                                            
                                        </div>
                                                 
                                    </div>
                                
                                    
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button> Download All Images</button>
                                    </div>
                                    <div className={[styles['sixty-satisfy'],'col-md-12'].join(' ')}> 
                                        <p>Are you satisfied? <img class="pl-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/up.png" />
                                        <img class="pl-3" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/down.png" />
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>

                                {/* Bulk Download complete  */}

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <ul className={[styles['change-tabs'],styles['change-tab2'],''].join(' ')}>
                                        <li className={[styles[' '],''].join(' ')}>
                                            <button className={this.state.activateButton == "Bulk" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("Bulk")}> 
                                                <span>Bulk Processing</span>
                                            </button>
                                        </li>
                                        <li className={[styles[''],''].join(' ')}>
                                            <button className={this.state.activateButton == "View" ? [styles['activate'],' '].join(' '): null} onClick={() => this.handleActivateButton("View")}> 
                                                <span>360 View</span>   
                                            </button>
                                        </li>
                                    </ul>
                                    <div className={[styles['credits-available'],'col-md-12 border-0'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                        <sub>15</sub>
                                        Available Credits: &nbsp; <span> 15</span>
                                    </div>
                                    <div className={[styles['sixty-link'],'col-md-12'].join(' ')}> 
                                                     
                                        <div className={[styles['sixty-content'],'row'].join(' ')}>
                                            <div className={[styles['download-complete'],'col-md-12 text-center'].join(' ')}> 
                                                 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/36591-beertech-check%20(6).gif" /> 
                                               
                                                <h4>Download Completed!</h4>
                                               
                                            </div>
                                            
                                            
                                        </div>
                                                 
                                    </div>
                                    <div className={[styles['sixty-satisfy'],'col-md-12'].join(' ')}> 
                                        <p>Rate the Outputs :  <img class="pl-2" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/up.png" />
                                        <img class="pl-3" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/down.png" />
                                        </p>
                                        
                                    </div>
                                    
                                    <div className={[styles['upload-image'],'col-md-12'].join(' ')}>
                                        <button><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/upload-image.png"/> Upload Image</button>
                                    </div>
                                    
                                </div>
                            </div>
                             
                            
                        </div>
                    </div>
                </section>
            
            
                
            </div>
        )
    }
}
