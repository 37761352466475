import React, { Component } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom'
import "../../Css/ClipprNav.css"; 
import Axios from 'axios'
import LoginModal from '../BackgroundRemoval/LoginModal'
import posthog from 'posthog-js';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import LandingModal from '../Common/Modal/LandingModal'
import { AuthContext } from '../../context/GlobalState'
import { deleteCookie, getCookie } from '../Common/Utils'
import queryString from 'query-string'

posthog.init('FoIzpWdbY_I9T_4jr5k4zzNuVJPcpzs_mIpO6y7581M',{api_host:'https://app.posthog.com'});
class ClipprNav extends Component {
    constructor(props){
        super(props)
        this.state={
            registeredEmail:"",
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            loginStatus:false,
            otpVerification:true,
            userId:'',
            emailValidation:true,
            domain:'',
            showInstruction: false,
            activeTab:''
        }
    }

    static contextType = AuthContext
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    componentDidMount = () => {
        let page = this.props.location.pathname

        if(page.includes('cars')){
            this.setState({
                activeTab: 'cars',
            })
        }else if(page.includes('bulk')){
            this.setState({
                activeTab: 'ecommerce',
            })
        }else if(page.includes('ecommerce')){
            this.setState({
                activeTab: 'ecommerce',
            })
        }else if(page.includes('food')){
            this.setState({
                activeTab: 'food',
            })
        }else if(page.includes('fabric')){
            this.setState({
                activeTab: 'fabric',
            })
        }else{
            this.setState({
                activeTab: 'cars',
            })
        }
        // let userEmail = this.cookie("emailId")
        // let userId = this.cookie("userId")
        // let validation = this.cookie("isValidated")
        let userEmail = this.context.auth.user.emailId
        let userId = this.context.auth.user.userId
        let validation = this.cookie("isValidated")
        // if(userEmail && userId && this.context.auth.loggedIn){
        //     this.setState({
        //         loginStatus:true,
        //         registeredEmail:userEmail
        //     })
        //     Axios({
        //         'method':'GET',
        //         'url':'https://www.spyne.ai/credit-user',
        //         'params':{
        //             'emailId':userEmail
        //         }
        //     })
        //     .then((res) => {
        //         if(res.data.id == 200){
        //             document.cookie = `userId=${res.data.data.userId}; path=/`;
        //             document.cookie = `isValidated=${res.data.data.isValidated}; path=/`;
        //             this.setState({
        //                 userId:res.data.data.userId
        //             })
        //         }
        //     })

        // }

        
        // else if(userEmail && userId !== "NA" && validation !== 'false'){
        //     this.setState({
        //         loginStatus:true,
        //         registeredEmail:userEmail
        //     })
        //     Axios({
        //         'method':'GET',
        //         'url':'https://www.spyne.ai/credit-user',
        //         'params':{
        //             'emailId':userEmail
        //         }
        //     })
        //     .then((res) => {
        //         if(res.data.id == 200){
        //             document.cookie = `userId=${res.data.data.userId}; path=/`;
        //             document.cookie = `isValidated=${res.data.data.isValidated}; path=/`;
        //             this.setState({
        //                 userId:res.data.data.userId
        //             })
        //         }
        //     })
        // }
        // else{
        //    this.setState({
        //        loginStatus:false
        //    })
        // }
        this.setState({
            domain:window.location.href
        })
    }
    

    logout = () =>{
        // document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        // document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        // document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        // window.sessionStorage.removeItem("lastEditedImage")
        // window.sessionStorage.removeItem("lastRawImage")
        // window.sessionStorage.clear()
        const fd = new FormData()
        fd.append('email_id', this.state.registeredEmail)
        fd.append('auth_token', getCookie('auth_token'))
        Axios({
            url: `${process.env.REACT_APP_BASEURL}/v2/user/logout`,
            method: 'POST',
            data: fd
        })
        .then((resp) => {
            if (resp.data?.status == 200) {
                this.context.loggedOut({
                    loggedIn: false,
                    user: {},
                    token: ''
                })
                document.cookie = 'emailId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'userId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'isValidated' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'auth_token' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'ssoToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'enterpriseId' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.sessionStorage.removeItem("lastEditedImage")
                window.sessionStorage.removeItem("lastRawImage")
                window.sessionStorage.clear()
                window.location.reload(false)
            }
        })
        
        // window.location.reload(false)
    }
    handleModal = () => {
        this.setState({
            show:true,
            showModal:true,
            showInstruction: true
        })
    }
    handleCloseModal = () => {
        this.setState({
            show:false,
            showModal:false
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat))
        {
            Axios({
                'method':'GET',
                "url":'https://www.spyne.ai/credit-user/register-vendor',
                "params":{
                    'emailId':this.state.email
                }
            })
            
            .then((res) => {
               this.setState({
                   otpScreen:true
               })
            })
            .catch((err) => {})
        }
        else
        {
            this.setState({
                emailValidation:false
            })
        }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":this.state.email,
                'otp':this.state.otp
            }
        })
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                    verification:res.data
                })

                if(this.state.verification){
                    posthog.people.set({email: this.state.email,website: "clippr.ai"});
                }

                document.cookie = `emailId=${this.state.email}; path=/`;
                // window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
    }
    render() {
        return (
            <div class="">
                <nav class="navbar fixed-top  navbar-expand-lg navbar-light small-nav-color header-design">
                    <div class="container-fluid navbarRemovebg-links d-flex justcontent-around">
                        <a className="navbar-brand" href="https://www.spyne.ai">
                            <img
                            src="https://storage.googleapis.com/spyne-website/static/website-themes/spyne/images/spyne-black-logo.png"
                            className={['logo-image']}
                            />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse col-md-10" id="navbarTogglerDemo02">
                            <div className="row nav-left">
                                <ul className="navbar-nav  mb-2 mb-lg-0 d-flex ">
                                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex float-center use-case"></ul> */}
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/tech/app/">App</a>
                                    </li>
                                    
                                    {/* <DropdownButton id="dropdown-basic-button" title="Technology" id="use-cases"> 
                                        <Dropdown.Item href="https://www.spyne.ai/tech/cars" target="_blank">Automobile</Dropdown.Item>
                                        <Dropdown.Item href="https://www.spyne.ai/tech/footwear" target="_blank">Footwear</Dropdown.Item>
                                        <Dropdown.Item href="https://www.spyne.ai/tech/model">Model</Dropdown.Item> 
                                        <Dropdown.Item href="https://www.spyne.ai/tech/real-estate">Sky Replacement</Dropdown.Item> 
                                    </DropdownButton> */}
 
                                    {/* <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/try-categories">Products</a>
                                    </li>
                                    */}
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/our-work">Portfolio</a>
                                    </li> 
                                    {/* <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/blogs">Blog</a>
                                    </li> */}

                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/about-us">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/blogs">Blog</a>
                                    </li>
                                    {this.state.activeTab == 'cars' ? 
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/tech/cars/pricing">Pricing</a>
                                        </li>
                                    : this.state.activeTab == 'ecommerce'?
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/tech/ecommerce/pricing">Pricing</a>
                                        </li>
                                    : this.state.activeTab == 'food' ?
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/tech/food/pricing">Pricing</a>
                                        </li>
                                    : this.state.activeTab == 'fabric' ? 
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" target="_blank" href="https://www.spyne.ai/tech/fabric/pricing">Pricing</a>
                                        </li>
                                        :
                                    null
                                    }
                                    
                                    {/* <DropdownButton id="dropdown-basic-button" title="Company" id="use-cases"> 
                                        <Dropdown.Item href="https://www.spyne.ai/about-us" target="_blank">About Us</Dropdown.Item>
                                        <Dropdown.Item href="https://www.spyne.ai/blogs" target="_blank">Blog</Dropdown.Item>
                                    </DropdownButton> */}
                                    {/* <li className="nav-item">
                                        <a className="nav-link" href="https://www.clippr.ai/contact-form/" tabindex="-1" target="_blank">Contact Us</a>
                                    </li> */}
                                   
                                </ul>
                            </div>
                            <div className=" nav-right d-flex pl-0 pr-0">
                                <ul>
                                    {/* <li>
                                        <a className="order-shoot" target="_blank" href="https://www.spyne.ai/butler/cliq-desktop/shoot/contact-detail"><span>Order Shoot</span></a>
                                    </li> */}
                                    <li>
                                        {/* <a className="sign-in" target="_blank" href="https://www.spyne.ai/butler/cliq-desktop">Sign In</a> */}
                                        {
                                        this.context.auth.loggedIn ? 
                                        <div className="nav-inner-list">
                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                               {/*  {this.state.registeredEmail} */}
                                               { this.context.auth?.user?.emailId }
                                            </button>
                                            {/* <a  style={{color:"#FF7700", cursor:"pointer"}}>
                                                {this.state.registeredEmail}
                                            </a> */}
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                {/* <li><a className="dropdown-item" href="https://www.spyne.ai/tech/dashboard">My Account</a></li> */}
                                                <li style={{"cursor" : "pointer"}}><a className="dropdown-item" onClick={this.logout}>Logout</a></li>
                                            </ul>
                                        </div>
                                        :
                                            <a  onClick={this.handleModal} className="sign-in">
                                                Sign In
                                            </a>
                                        }
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </nav>
            {
                    this.state.showModal ? 
                    // <LoginModal 
                    //     show={this.handleModal}  
                    //     sendOTP={this.sendOTP}  
                    //     verifyOTP={this.verifyOTP}
                    //     otpScreen={this.state.otpScreen}
                    //     verification={this.state.verification}
                    //     handleChange={this.handleChange}
                    //     otpVerification={this.state.otpVerification}
                    //     onHide={this.handleCloseModal}
                    //     emailValidation={this.state.emailValidation}
                    //     defaultEmail={this.state.email}
                    //     cars={this.state.domain=="http://localhost:3000/tech/cars" ? true : false}
                    // />
                    <LandingModal
                            showInstruction={this.state.showInstruction}
                            hideModal = {this.hideLandingModal}
                        />
                    :
                    null
            }
        </div>
        )
    }
}

export default withRouter(ClipprNav)