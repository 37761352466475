import React, { Component } from "react";
import Slider from "react-slick";


import styles from '../../Css/ImageScoring/UploadScreen.module.css'
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-block", background: "#fff" }}
      onClick={onClick}
    />
  );
}

export default class ScoreImage extends Component {

  render() {
     
    const settings = {
      // className: "center",
      // dots: true,
      infinite: false,
      slidesToShow:4,
      slidesToScroll: 1, 
      swipeToSlide: true,
      autoplay: false,
      speed: 500,
      // autoplaySpeed: 7000
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }; 

    return (
            <div>
                
                <section className={[styles['image-scoring'],'i'].join(' ')}>
                  <div className={[styles['image-container'],''].join(' ')}>
                      <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['scoring-col'],'col-md-5'].join(' ')}>
                            <div className={[styles['scoring-upload2'],'row'].join(' ')}>
                                <div className={[styles['scoring-upload-box2'],'col-md-12'].join(' ')}>
                                    <img src="https://storage.googleapis.com/spyne-website/image-scoring/model.png"/>
                                     
                                </div>
                                <p>Drag & Drop file here to upload</p>
                            </div>
                            <div className={[styles['slider-area'],styles['slider2'],'row m-0'].join(' ')}>
                                      <p>No image? Try one of these: <span> <i class="fa fa-refresh" aria-hidden="true"></i></span></p>
                                      <Slider {...settings} className= {[styles['image-slider'],'col-md-12 image-slider'].join(' ')} > 
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                        <div className={[styles['slide']]}>
                                          <img src="https://storage.googleapis.com/spyne/AI/raw/img-153521a5-9105-4d6e-9f99-0831c1777ad4.jpg" />
                                        </div>
                                      </Slider>
                                    </div>
                        </div>
                          <div className={[styles['scoring-title'],styles['scoring-title2'],'col-md-7'].join(' ')}>
                               <h1>87% <span>Great!</span></h1>
                               <img src="https://storage.googleapis.com/spyne-website/image-scoring/Rectangle2.png" className={styles['rectangle2']}/>
                                <ul>
                                    <li><p><span>0.5 / 10</span> Blur Score <a href="#" title="Blur Score" className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i></a></p></li>
                                    <li><p><span className={[styles['nine']]}>9.5 / 10</span> Resolution / Image Size <a href="#" title="Blur Score" className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i></a></p></li>
                                    <li><p><span className={[styles['five']]}>5 / 10</span> Subject Score <a href="#" title="Blur Score" className={[styles['tooltip'],''].join('')}><i className="fa fa-info" aria-hidden="true"></i></a></p></li>
                                    <li><p> <span className={[styles['comming']]}>Coming Soon</span> <b>Lighting Score</b> </p></li>
                                </ul>

                                <ul className={[styles['marketplace-dimension'],''].join(' ')}>
                                    <h5>Marketplace <span>Dimension</span> Verifier</h5>
                                    <li><button className={[styles['marketplace-img'],styles['active'],''].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/image-scoring/check.png" className={[styles['check']]}/>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li><button className={[styles['marketplace-img'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li><button className={[styles['marketplace-img'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/></button></li>
                                    <li>Your Image is almost matching <br/>to myntra guidelines: 1440/1080</li>
                                </ul>

                                <p>Power your marketplace imagery with our AI</p>
                                <h5><button className={[styles['try-btn']]}>Try Spyne AI</button> <button className={[styles['try-api']]}>Try API</button> <button className={[styles['get-app']]}>Get App <i class="fa fa-angle-double-right" aria-hidden="true"></i></button></h5>
                          </div>
                      </div>
                  </div>
                </section>
            </div>
   

    )
  }
}
