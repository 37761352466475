import React, { Component } from 'react'


import Styles from '../../Css/Landing/LandingScreenThree.module.css'

export default class LandingScreenThree extends Component {
    



    render() {
        return (
            <div>
                <section className={[Styles['landing-screen-three'],' '].join(' ')}>
                    <div className={[Styles[' '],'container'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles[' '],'col-md-12'].join(' ')}>
                                <div className={[Styles['screen-three-area'],'row'].join(' ')}>
                                    <div className={[Styles[' '],'col-md-8'].join(' ')}>
                                        <div className={[Styles['tab-contents'],'row'].join(' ')}>
                                        <p>1 credit = 1 HD Image</p>
                                            <div  className={[Styles['image-tab-content'],'col-md-12'].join(' ')}>
                                                <img class="img-fluid" src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/removeBgBefore.png" />
                                            </div>
                                            <div className={[Styles['image-editing'],'col-md-12'].join(' ')}>
                                            <p class="mr-5 mt-2">Original</p>
                                                <label className={[Styles['switch'],''].join(' ')}>
                                                    <input type="checkbox" />
                                                    <span className={[Styles['toggle-switch'],' '].join(' ')}></span>
                                                </label>
                                                <p class="ml-5 mt-2">Edited</p>
                                            </div>
                                            
                                        </div>

                                    </div>
                                    
                                    <div className={[Styles['right-drag-drop'],'col-md-4'].join(' ')}>
                                        <div  className={[Styles['screen-3-drop-model'],'row m-0'].join(' ')}>
                                            <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg" />
                                            <p className={[Styles['para-1'],''].join(' ')}>Drag Image Here</p>
                                            <p className={[Styles['para-2'],''].join(' ')}>or browse for image</p>
                                            <input type="file"  style={{display:"none"}} />
                                        </div>
                                        <button className={[Styles['mybtn'],'btn text-center'].join(' ')} >Download HD</button>
                                        <p>(Resolution 1920*1080)</p>
                                        <button className={[Styles['mybtn-border'],'btn text-center'].join(' ')} >Download </button>
                                        <p>(Free download low resolution 100*100)</p>
                                        <h6>Credits remaining 5</h6>
                                        <a href="#" >Buy credits</a>
                                    </div>
                                    
                                </div>
                                <div className={[Styles['bottom-contact-badge'],''].join(' ')}>
                                    <p>For bulk pricing <span> Contact Us</span></p>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}
