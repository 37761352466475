import React, { Component } from 'react';
import  {Modal} from 'react-bootstrap'
import styles from '../../../Css/Food/SelectFoodBgModal.module.css'



class SelectFoodBgModal extends Component {
    handleCloseModal = (e) => {
       
        this.props.handleBgModal(e)
    }

    render() {
      
        return ( 
            <>
                <Modal 
                show={this.props.show}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size='lg'
                centered
                onHide={this.handleCloseModal}
                    >
                     <Modal.Header />
                    <Modal.Body>
                       {/* <section className={[styles['food-angle'],''].join(' ')}>
                           <div className={[styles[' '],'container-fluid'].join(' ')}> */}
                                <div className={[styles[''],'row'].join(' ')}>
                                    <div className={[styles['food-image-area'],'col-md-10 offset-md-1'].join(' ')}>
                                        <h6>Uploaded IMAGE</h6>
                                        <img src="https://storage.googleapis.com/spyne-website/Food-Try/food.png"/>
                                        
                                    </div>
                                    
                                </div>
                                <div className={[styles['food-bottom'],'row'].join(' ')}>
                                    <h4> Choose IMAGE angle </h4>
                                    <div className={[styles[''],'col-md-4'].join(' ')}>
                                        <div className={[styles['food-angle-single'],'row m-0'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/Food-Try/90-degree.png"/>
                                            <button onClick={()=>this.handleCloseModal(0)}>0 Degree Angle</button>
                                        </div>
                                    </div>
                                    <div className={[styles[''],'col-md-4'].join(' ')}>
                                        <div className={[styles['food-angle-single'],'row m-0'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/Food-Try/45-degree.png"/>
                                            <button onClick={()=>this.handleCloseModal(45)}>45 Degree Angle</button>
                                        </div>
                                    </div>
                                    <div className={[styles[''],'col-md-4'].join(' ')}>
                                        <div className={[styles['food-angle-single'],'row m-0'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/Food-Try/0-degree.png"/>
                                            <button onClick={()=>this.handleCloseModal(90)}>90 Degree Angle</button>
                                        </div>
                                    </div>
                               </div>
                           {/* </div>
                       </section> */}
                        
                    </Modal.Body>

                </Modal>
                </>
        );
    }
}
 
export default SelectFoodBgModal;