import React, { Component } from 'react'
import "react-toggle/style.css";
import Toggle from 'react-toggle'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios';
import Jimp from 'jimp'
import { MagnifierContainer, MagnifierPreview, MagnifierZoom, MOUSE_ACTIVATION, TOUCH_ACTIVATION,} from "react-image-magnifiers";
import { ProgressBar, Spinner } from "react-bootstrap";
import LoginModal from './LoginModal'
import {Link} from 'react-router-dom'
import Dropzone from 'react-dropzone'
// import { ProgressBar, Spinner } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {creditInfo, reduceCredit,downloadImg} from '../Common/Utils';

import styles from '../../Css/BackgroundRemoval/ScreenTwo.module.css'
import DownloadclipprModal from '../Common/Modal/DownloadclipprModal';
import InsufficientCreditModal from '../Common/Modal/InsufficientCreditModal';

import FeedbackModal from '../Common/Modal/feedbackModal'
import loadImage from "blueimp-load-image";
import { saveAs } from 'file-saver';

import posthog from 'posthog-js';
import style from '../../Css/BackgroundRemoval/Screen2New.css'
posthog.init('FoIzpWdbY_I9T_4jr5k4zzNuVJPcpzs_mIpO6y7581M',{api_host:'https://app.posthog.com'});


export default class Clippr2 extends Component {
    
    constructor(props){
        super(props)
        this.state={
            show:false,
            showModal:false,
            email:window.localStorage.getItem("clippr_user_email"),
            otpScreen:false,
            otp:'',
            verification:false,
            autoLogin:false,
            toggleStatus:true,
            toggleChange:true,
            loginStatus:false,
            otpVerification:true,
            userId:'',
            availableCredit:0,
            creditId:"",
            loader:false,
            creditAlloted:null,
            creditUsed:null,
            spinner:false,
            emailValidation:true,
            downloadType:"",
            // rating:false
            showInsuffCredModal:false,
            downloadImage:"",
            dropBackground: false,
        }
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    componentDidMount = () => {

      let userEmail = this.cookie("emailId")
      let _userId_ = this.cookie("userId")
      let auth_key = this.cookie('auth_token')
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
      if(userEmail){
          this.setState({
              loginStatus:true,
              userId:_userId_
          })
            creditInfo(auth_key).then((res)=>{
                // console.log(res.data.data.credit_available)
                // console.log(this.availableCredit)
                this.setState({
                    availableCredit:res.data.data.credit_available,
                    creditAlloted:res.data.data.credit_alloted,
                    creditUsed:res.data.data.credit_used
                })
                // console.log(res.data.data.credit_available)
                
                
            })
              
        }
        else{
            this.setState({
                show:true,
                showModal:true,
                
            })
        }
    }
    handleCloseModal = () => {
        this.setState({
            show:!this.state.show,
            showModal:false
        })
    }
    // updateCredit = () => {
    //     // console.log(this.state.creditAlloted, this.state.creditUsed, this.state.availableCredit)
    //     Axios({
    //         "method":"GET",
    //         "url":"https://www.spyne.ai/credit/update-total-credit",
    //         'params' : {
    //             "userId":this.state.userId,
    //             "creditAlloted":this.state.creditAlloted,
    //             "creditUsed":this.state.creditUsed + 1,
    //             "creditAvailable":Number(this.state.availableCredit) - 1
    //         }
    //     })
    //     .then((updatedCredit) => {
    //         Axios({
    //             'method':'GET',
    //             'url':"https://www.spyne.ai/credit/user-total-credit",
    //             "params":{
    //                 'userId':this.state.userId
    //             }
    //         })
    //         .then((res) => {
    //             this.setState({
    //                 availableCredit:res.data.data.creditAvailable,
    //                 creditAlloted:res.data.data.creditAlloted,
    //                 creditUsed:res.data.data.creditUsed
    //             })
    //         })
    //     })
    // }
    handleChange = (e) => {
        // console.log(e.target.value)
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    sendOTP = (e) => {
        e.preventDefault()
        let email = this.state.email
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(mailformat))
        {
            Axios({
                'method':'GET',
                "url":'https://www.spyne.ai/credit-user/register-vendor',
                "params":{
                    'emailId':this.state.email
                }
            })
            
            .then((res) => {
               this.setState({
                   otpScreen:true
               })
            })
            .catch((err) => {})
        }
        else
        {
            this.setState({
                emailValidation:false
            })
        }
    }
    verifyOTP = (e) => {
        e.preventDefault()
        Axios({
            'method':'GET',
            "url":'https://www.spyne.ai/credit-user/validate-otp',
            "params":{
                "emailId":this.state.email,
                'otp':this.state.otp
            }
        })
        .then((res) => {
            if(res.data.id == 200){
                this.setState({
                verification:res.data
                })
                
            if(this.state.verification){
                posthog.people.set({email: this.state.email,website: "clippr.ai"});
            }
            document.cookie = `emailId=${this.state.email}`;
            window.location.reload(false)
            }
            else{
                this.setState({
                    otpVerification:false
                })
            }
        })
        .catch((err) => {})
    }
    handleToggle = (e) => {
        this.setState({
            spinner:true
        })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    spinner:false
                });
            }
            .bind(this),
            500
        );
    }
    // downloadImage = () => {


    //     // this.props.fileSelectedHandler(0)
    //     const link = this.props.editedImage;
    //     window.open(link, "_blank");

        
    //     Axios.get('https://www.clippr.ai/api/clipprReports/imgDownloadedLowRes/')
    //     .then((res) => {
    //     })
    //     .catch((err) => {
    //     })


    // }
    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus:true,
            dropBackground: false,
            downloadType:"",
        })
        this.props.fileSelectedHandler(acceptedFiles)
    } 
    handleDownloadType=(DT)=>{
        // console.log(DT)
        // console.log(this.props.wm_image)

        if(DT=="HD"){
            this.setState({
                downloadType:"HD",
                downloadImage:this.props.hd_image
            })

        }
        else if(DT=="Basic") {
            this.setState({
                downloadType:"Basic",
                downloadImage:this.props.wm_image
            })
        }
    }

    handleDownloadModal =() =>{
        // let auth_key = this.cookie('auth_token')
        // if(this.state.availableCredit == 0 && this.state.loginStatus && this.state.downloadType==="HD"){
        //     this.setState({
        //         showInsuffCredModal:true
        //     })
        // }
        // else if(this.state.availableCredit !== 0 && this.state.loginStatus && this.state.downloadType==="HD"){
        //     reduceCredit(auth_key,1).then((updatedCredit)=>{
        //         creditInfo(auth_key).then((res)=>{
        //         this.setState({
        //             availableCredit:res.data.data.credit_available,
        //             creditAlloted:res.data.data.credit_alloted,
        //             creditUsed:res.data.data.credit_used
        //         })
        //     })
        //     this.setState({
        //         clickDownload:true
        //     })
        //     // let imagePath = this.state.downloadImage;
        //     // downloadImg(imagePath)
        //     const output_link = this.state.downloadImage;
        //     fetch(output_link, {
        //     method: "GET",
        //     headers: {}
        //     })
        //     .then(response => {
        //         response.arrayBuffer().then(function(buffer) {
        //         const url = window.URL.createObjectURL(new Blob([buffer]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         let name = output_link
        //         var index = name.lastIndexOf("/") + 1;
        //         var filename = name.substr(index)
        //         link.setAttribute("download", filename); 
        //         document.body.appendChild(link);
        //         link.click();
        //         });
        //     })
        //     // let fileName = imagePath.substring(imagePath.lastIndexOf('/') + 1)
        //     // saveAs(imagePath, fileName);
        //     // console.log(updatedCredit)
        //  })
        // }
        // else
        // {
        //     this.setState({
        //         clickDownload:true
        //     })
        //     const output_link = this.state.downloadImage;
        //     fetch(output_link, {
        //     method: "GET",
        //     headers: {}
        //     })
        //     .then(response => {
        //         response.arrayBuffer().then(function(buffer) {
        //         const url = window.URL.createObjectURL(new Blob([buffer]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         let name = output_link
        //         var index = name.lastIndexOf("/") + 1;
        //         var filename = name.substr(index)
        //         link.setAttribute("download", filename); 
        //         document.body.appendChild(link);
        //         link.click();
        //         });
        //     })
        // }
        // const output_link = this.state.downloadImage;
        
        downloadImg(this.state.downloadImage)
    }


    // hideDownloadModal=()=>{
    //     this.setState({
    //         clickDownload:!this.state.clickDownload
    //     })
    // }

    handleRatingModal=()=>{
        // console.log("hdsfahslfbajfbl")
        this.setState({
            rating:true
        })
    }

    hideRatingModal=()=>{
        this.setState({
            rating:!this.state.rating
        })
    }
    hideInsufficientCreditModal=()=>{
        this.setState({
            showInsuffCredModal:!this.state.showInsuffCredModal
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
    }
    dragLeave = (e) => {
    e.preventDefault()
    this.state.dropBackground = false
    }
    updateScreen =(name) =>{
        this.props.resetUploadScreen()
        this.props.activeProductCategory(name)
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]
    render() {
        return (
            <Dropzone  onDrop={(acceptedFiles) => this.fileHandler(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
              {({getRootProps, getInputProps}) => (
                  <section>
                      <div {...getRootProps({
                        onClick: event => event.stopPropagation()
                      })} className={styles["dropzone1"]} >
                          {this.state.dropBackground ?
                            <section className={[styles['drop-box-text']]}>
                              <div>Drop Image Anywhere</div>  
                            
                            </section>
                            : null
                          }   
                    <div className={[styles['bg-main'],''].join(' ')}>
                        <div className="d-flex justify-content-center" style={{paddingTop:"100px"}}>
                            {/* <div className="text-center screen-2-top">
                                <>
                                    <div className="d-flex justify-content-center">
                                        {
                                            !this.props.loader ? 
                                        <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                            <div {...getRootProps()}>
                                                <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                <p className="para-1">Upload Files Here</p>
                                                <p class="para-2"><b>or</b> Browse For Image</p>
                                        editedImage        <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                            </section>
                                        )}
                                        </Dropzone>
                                        :
                                        <Spinner
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            width: '80px',
                                            height: '80px',
                                        }}
                                        animation="border"
                                        variant="primary"
                                    />
                                        }
                                    </div>
                                    <input 
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.fileSelectedHandler}
                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                    ></input>
                                </>
                            </div> */}
                        </div>
                        {/* <div className={[styles['upload-sec'],'row'].join(' ')}> */}
                        {/* <div className={[styles['upload-area'],'col-md-12 text-center'].join(' ')}>
                                <> */}
                                   
                                   <div class="myclass1 text-center">
                                 {this.props.categoryButton.map((name) => (
                                   <button class="btn2" onClick={() => this.updateScreen(name)} className={this.props.Prodcategory == name ?['mactive1']:  ['btn2']}>{name}</button>
                                    ))}
                                 </div> 
                                        {/* <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)} >
                                        {({getRootProps, getInputProps}) => (
                                            <section className={[styles[''],'col-md-12'].join(' ')}>
                                            <button className={[styles['upload-btn'],''].join(' ')}>
                                                <div {...getRootProps()}  className={[styles['screen-2-drop'],''].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png"/>
                                                    <span>Upload Image</span>
                                                    
                                                    <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                </div>
                                            </button>
                                            </section>
                                        )}
                                        </Dropzone> */}
                                        {/* <h6 className={[styles['product-hunt-tag'],''].join(' ')}>
                                            <a href="https://www.producthunt.com/posts/spyneai-s-car-background-tool?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spyneai-s-car-background-tool" target="_blank">
                                            <img  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299488&theme=light"
                                                alt="SpyneAI’s Car Background tool - Boost Online Car Sales with AI-Generated Backgrounds | Product Hunt"/>
                                        </a>
                                        </h6> */}
                                   
                                    {/* <input 
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.fileSelectedHandler}
                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                    ></input> */}
                                {/* </>
                            </div> */}
                        {/* </div> */}
                        <div className={[styles['main-box'],'row'].join(' ')}>
                            <div className={[styles['remove-bg-area'],'col-md-12'].join(' ')}>
                                <div className={[styles['remove-bg-box'],'row'].join(' ')}>
                                    <div className="col-lg-6 col-12 sec-2-left-card">
                                        <center>
                                            <div  className={[styles['containers'],''].join(' ')}>
                                                {
                                                    !this.state.toggleStatus && !this.state.toggleChange?
                                                    <div className="row">
                                                        <div className="col-12-12">
                                                            <div className="left-sec-upload">
                                                                <div className="left-sec-upload-image">
                                                                    {/* <img imageSrc={this.props.originalImage}/> */}
                                                                    {
                                                                        this.state.spinner ? 
                                                                        // <Spinner
                                                                        //     className="loader-edited-screen-2"
                                                                        //     animation="grow"
                                                                        //     variant="primary"
                                                                        // />
                                                                        <SkeletonTheme  color="#ffd5b0" >
                                                                            <div> 
                                                                                <Skeleton width={420} height={400} />
                                                                            </div>
                                                                        </SkeletonTheme>
                                                                        // <Skeleton width="100%" height="100%" style={{color:"#FF7700"}}/>
                                                                        :
                                                                        <img src={this.props.originalImage} className="magnifier_zoom"/>
                                                                    }
                                                                    {/* <img imageSrc={this.props.originalImage} className="magnifier_zoom"/> */}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                    <div className="col-12-12">
                                                            <div className="left-sec-upload">
                                                                <div class="d-none" id="result">
                                                                    </div>
                                                                <div className={[styles['left-sec-upload-image'],'left-sec-upload-image bg-removed'].join(' ')}>
                                                                    {/* <img imageSrc={this.props.editedImage}/> */}
                                                                    {
                                                                        !this.props.loader && !this.state.spinner ? 
                                                                        <img   src={this.props.editedImage} className="magnifier_zoom_bg"/>
                                                                        :
                                                                        this.state.spinner ?
                                                                        // <Spinner
                                                                        //     className="loader-edited-screen-2"
                                                                        //     animation="grow"
                                                                        //     variant="primary"
                                                                        // />
                                                                        <SkeletonTheme   color="#ffd5b0" >
                                                                            <div> 
                                                                                <Skeleton width={420} height={400} />
                                                                            </div>
                                                                        </SkeletonTheme>
                                                                        // <Skeleton width="100%" height="100%" style={{color:"#FF7700"}}/>
                                                                        :
                                                                        // <Spinner
                                                                        //     className="loader-edited-screen-2"
                                                                        //     animation="border"
                                                                        //     variant="primary"
                                                                        // />
                                                                        <SkeletonTheme   color="#ffd5b0" >
                                                                            <div> 
                                                                                <Skeleton width={420} height={400} />
                                                                            </div>
                                                                        </SkeletonTheme>
                                                                        // <Skeleton width="100%" height="100%" style={{color:"#FF7700"}}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                }
                                            </div>
                                            {
                                                !this.props.loader?
                                                    <div className="sec-2-toggle">
                                                        <p className="mr-5 mt-2">Original</p>
                                                        <label className="mt-2">
                                                            <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} />
                                                        </label>
                                                        <p className="ml-5 mt-2">Edited</p>
                                                    </div>
                                                :null
                                            }
                                        </center>
                                    </div>
                            <div  className={[styles['sec-2-right-card'],"col-lg-5 col-12 offset-lg-1 "].join(" ")}>
                            
                                {/* <div className="category-sec">
                                    <div className="category-container">
                                    <div className="category">{this.props.imageCategory}</div>
                                        <div className="category-header">Category Identified</div>
                                    </div>
                                </div> */}
                                
                                <div className={[styles['download-section'],""].join(" ")}>
                                    {/* <SkeletonTheme width="100%" height="100%" color="#FF7700" > */}
                                    
                                    <div  className={[styles[''],"download-section-inner2"].join(" ")}>
                                        {/* <p>Download HD</p> */}

                                        {/* <button className="btn btn-primary mb-3" onClick={this.handleModal}>Download HD</button>
                                        <p>(Resolution {this.props.imageWidth} * {this.props.imageHeight})</p>
                                        <p><a style={{fontSize:"15px"}} href="/tech/pricing">{this.state.availableCredit > 0 ? "See our pricing plans" : "Buy Credits"}</a></p>
                                        <p className="my-1">Or</p>
                                        <p><span onClick={this.downloadImage}>Download</span> low resolution for free</p>
                                        <p>(Resolution 100 x 100)</p>
                                        <p>Credits remaining {this.state.availableCredit !== null ? this.state.availableCredit: 5}</p> */}
                                    
                                    <div className={[styles['hd-download-area'],"row"].join(" ")}>
                                    
                                            <div className={[styles['download-popup'],'col-md-12'].join(' ')}>
                                                {
                                                    !this.props.loader?
                                                        <div className={[styles['credit-text'],""].join(" ")} >
                                                            {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png"/>
                                                            Available Credits: &nbsp; <span> {this.state.availableCredit} </span> */}
                                                        </div>
                                                    :
                                                    null
                                                }

                                                {/* <div className={[styles['cross-btn'],''].join(' ')}>
                                                    <img className={styles['cross-img']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                                </div> */}
                                                {
                                                    this.props.loader?
                                                        <SkeletonTheme  className={styles["download-section-inner"]} color="#ffd5b0" >
                                                            <div> 
                                                                <Skeleton width="85%" height={114} style={{marginBottom:"25px", marginTop:"50px", borderRadius:"10px"}}/>
                                                            </div>
                                                        </SkeletonTheme>
                                                    :
                                                
                                                    <button onClick={() => this.handleDownloadType("HD")} style={{border: "1px solid #0579df",boxSizing: "border-box",borderRadius: "10px"}}className={ this.state.downloadType=="HD"?[styles['download-hd'], styles['activate'],'col-md-12 '].join(' '):[styles['download-hd'],styles[''],'col-md-12 '].join(' ') }>
                                                        <h6><input type="radio" checked={this.state.downloadType=="HD"} />HD Download</h6>
                                                        <ul>
                                                            <li>Full Image 1920 × 1080 </li>
                                                            {/* <li>Without Watermark</li> */}
                                                        </ul>
                                                        
                                                    </button>
                                                }
                                                
                                                
                                                <div className={[styles['rating-area'],'col-md-10 text-center'].join(' ') }>
                                                {
                                                    this.props.loader?
                                                        <SkeletonTheme  className={styles["download-section-inner"]} color="#ffd5b0" >
                                                            <div> 
                                                                <Skeleton  height={50} style={{marginBottom:"25px", borderRadius:"10px"}}/>
                                                            </div>
                                                        </SkeletonTheme>
                                                    :
                                                    
                                                    <button onClick={this.handleDownloadModal} disabled={this.state.downloadType.length>0?false:true} className={[styles['download-btn-hd']]}>Download</button>
                                                    
                                                }
                                                
                                                <InsufficientCreditModal
                                                    showInsufficientCreditModal={this.state.showInsuffCredModal}
                                                    hideThisModal={this.hideInsufficientCreditModal}
                                                />    

                                                
                                                <DownloadclipprModal
                                                    clickDownload={this.state.clickDownload}
                                                    hideModal={this.hideDownloadModal}
                                                    downloadType={this.state.downloadType}
                                                    availableCredit={this.state.availableCredit}
                                                    fileSelectedHandler={this.fileHandler}
                                                />
                                                    
                                                {/* {
                                                    this.props.loader?null:(
                                                    <div>
                                                    <p>Rate this Output : <span onClick={this.handleRatingModal}>
                                                        <svg   width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.2053 21.9028C19.0281 21.9028 18.8481 21.8521 18.6887 21.7471C16.4687 20.279 13.5306 20.279 11.3096 21.7471C10.8765 22.034 10.2962 21.915 10.0103 21.4828C9.72432 21.0506 9.84338 20.4693 10.2746 20.1834C13.1115 18.3056 16.8878 18.3075 19.7228 20.1834C20.1549 20.4693 20.2731 21.0506 19.9871 21.4828C19.8081 21.7556 19.5099 21.9028 19.2053 21.9028Z" fill="#565656"/>
                                                        <path d="M15 30C6.72938 30 0 23.2706 0 15C0 6.72938 6.72938 0 15 0C23.2706 0 30 6.72938 30 15C30 23.2706 23.2706 30 15 30ZM15 1.875C7.7625 1.875 1.875 7.7625 1.875 15C1.875 22.2375 7.7625 28.125 15 28.125C22.2375 28.125 28.125 22.2375 28.125 15C28.125 7.7625 22.2375 1.875 15 1.875Z" fill="#565656"/>
                                                        <path d="M10.7894 12.1706C11.5614 12.1706 12.1872 11.5448 12.1872 10.7728C12.1872 10.0008 11.5614 9.375 10.7894 9.375C10.0174 9.375 9.3916 10.0008 9.3916 10.7728C9.3916 11.5448 10.0174 12.1706 10.7894 12.1706Z" fill="#565656"/>
                                                        <path d="M19.2191 12.1791C19.9911 12.1791 20.6169 11.5533 20.6169 10.7813C20.6169 10.0093 19.9911 9.38348 19.2191 9.38348C18.4471 9.38348 17.8213 10.0093 17.8213 10.7813C17.8213 11.5533 18.4471 12.1791 19.2191 12.1791Z" fill="#565656"/>
                                                        </svg>

                                                        </span>
                                                        <FeedbackModal
                                                            rating={this.state.rating}
                                                            hideRatingModal= {this.hideRatingModal}
                                                        /> 
                                                        <span>
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.2053 18.7765C19.0281 18.7765 18.8481 18.8271 18.6887 18.9321C16.4687 20.4002 13.5306 20.4002 11.3096 18.9321C10.8765 18.6452 10.2962 18.7643 10.0103 19.1965C9.72432 19.6287 9.84338 20.2099 10.2746 20.4958C13.1115 22.3737 16.8878 22.3718 19.7228 20.4958C20.1549 20.2099 20.2731 19.6287 19.9871 19.1965C19.8081 18.9237 19.5099 18.7765 19.2053 18.7765Z" fill="#565656"/>
                                                            <path d="M15 30C6.72938 30 0 23.2706 0 15C0 6.72938 6.72938 0 15 0C23.2706 0 30 6.72938 30 15C30 23.2706 23.2706 30 15 30ZM15 1.875C7.7625 1.875 1.875 7.7625 1.875 15C1.875 22.2375 7.7625 28.125 15 28.125C22.2375 28.125 28.125 22.2375 28.125 15C28.125 7.7625 22.2375 1.875 15 1.875Z" fill="#565656"/>
                                                            <path d="M10.7894 12.1706C11.5614 12.1706 12.1872 11.5448 12.1872 10.7728C12.1872 10.0008 11.5614 9.375 10.7894 9.375C10.0174 9.375 9.3916 10.0008 9.3916 10.7728C9.3916 11.5448 10.0174 12.1706 10.7894 12.1706Z" fill="#565656"/>
                                                            <path d="M19.2191 12.1791C19.9911 12.1791 20.6169 11.5533 20.6169 10.7813C20.6169 10.0093 19.9911 9.38348 19.2191 9.38348C18.4471 9.38348 17.8213 10.0093 17.8213 10.7813C17.8213 11.5533 18.4471 12.1791 19.2191 12.1791Z" fill="#565656"/>
                                                            </svg>

                                                        </span>
                                                        </p>
                                                        
                                                        <p className={[styles['get-mrktplace'],''].join(' ') }> Get Marketplace ready images 
                                                            <a style={{color:'#ff7700',textDecoration:'none'}} href="https://www.clippr.ai/contact-form/" target="_blank">
                                                            Click Here </a>
                                                        </p> 
                                                        </div>) 
                                                    } */}
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                        
                                    
                                    {/* </SkeletonTheme> */}
                                    
                                </div>
                                
                            </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.showModal ? 
                            <LoginModal 
                                show={this.handleModal}  
                                sendOTP={this.sendOTP}  
                                verifyOTP={this.verifyOTP}
                                otpScreen={this.state.otpScreen}
                                verification={this.state.verification}
                                handleChange={this.handleChange}
                                otpVerification={this.state.otpVerification}
                                emailValidation={this.state.emailValidation}
                                onHide={this.handleCloseModal}
                                defaultEmail = {this.state.email}
                            />
                            :
                            null
                        }
                    </div>
                    <input  onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                </div>
            </section>
        )}
        </Dropzone>    
        )
    }
}

