import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from 'react-bootstrap'
import styles from '../../../Css/BulkProcessing/BulkProcessingV2.module.css'


function dataURLtoFile(dataurl, filename = 'yoo.png') {
  console.log(dataurl)
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const Demo = (props) => {

  const dataurl = props.logoUrl


  const image = dataurl;
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const getCropData = async () => {
    if (typeof cropper !== "undefined") {

      await setCropData(cropper.getCroppedCanvas().toDataURL());
      var dataUrl = dataURLtoFile(cropper.getCroppedCanvas().toDataURL())
      props.handleCroppedLogoBlob(dataUrl, cropData)
      props.saveCroppedImage()


    }
  };

  const handlesave = async () => {

  }

  return (
    <React.Fragment>
      <Modal
        show={props.showlogoCropModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size='md'
        centered
      >
        <Modal.Body className={styles['crop-header']}>
          <div className="row">
            <div className="col-6">
              <h4>Crop the Logo</h4>
            </div>
            <div className="col-6 text-right">
              <img onClick={props.handleCropModal} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png" />
            </div>
          </div>


        </Modal.Body>
        <Modal.Body className="">

          <div>
            <div style={{ width: "100%" }}>


              <Cropper
                style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                aspectRatio={5 / 1}
                src={image}
                viewMode={0}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
                dragMode={'move'}
              />


            </div>
            <div>
              <div className="text-center">
                <p>Scroll on the image to zoom in or zoom out</p>
                <div className={styles['button-padding']}>
                <button className={styles['cancel-crop']} onClick={props.handleCropModal}>Cancel</button>
                
                <button className={styles['save-crop']} onClick={getCropData}>crop</button>
                </div>
              </div>
              <br style={{ clear: "both" }} />
            </div>
          </div>

        </Modal.Body>



      </Modal>

    </React.Fragment>

  );
};

export default Demo;
