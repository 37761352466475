import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Spinner, Modal } from "react-bootstrap";
import Toggle from 'react-toggle'
import axios from 'axios'
import TwentyTwenty from 'react-twentytwenty'

import styles from '../../Css/CarsBackgroundReplacement.module.css'
import '../../App.css'
import Footer from '../Common/Footer'
import {fetchCreditUserDetails, updateUserCredit,cookie} from '../Common/Utils'
import LandingModal from '../Common/Modal/LandingModal' 
import Styles from '../../Css/CarV2.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BulkProcessingV2 from './BulkProcessingV2';


export default class BulkLandingV2 extends Component {
    constructor(props){
        super(props)
        this.state={
          selectedFile: null,
          selectedLogoFile: null,
          url: window.sessionStorage.getItem("lastEditedImage") ? window.sessionStorage.getItem("lastEditedImage") : '' , // url of the image returned by the api 
          orgUrl:  window.sessionStorage.getItem("lastRawImage") ? window.sessionStorage.getItem("lastRawImage") :  '',
          object_id:0,
          background_id:0,
          uploadPercentage: 0,
          uploading: false,
          orgImgShow: true,
          loader: false,
          uploadBox: true,
          editedState: false,
          tabIndex: 0,
          isError: false,
          processing: false,
          editedImagesArr: [],
          editing: false,
          carShadow:false,
          buffer_array:[1],
          cnt:1,
          spinner:window.sessionStorage.getItem("lastEditedImage") ? false : true,
          car_bg_replacement_angle:'front',
          selectedAngle:'',
          imageSelected:false,
          firstUpload:true,
          planData:[],
          availableCredit:5,
          alottedCredit: 0,
          creditUsed:0,
          isEditingFinish:false,
          toggleChange:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          toggleStatus:window.sessionStorage.getItem("lastEditedImage") ? true : false,
          bgCredit:0,
          loginStatus:false,
          logoUrl:'',
          logoPosition:null,
          hideLogo:false,
          showInstruction:false,
          showInstructionModal:false,
          askEmail:false,
          activButton: "Exterior",
          backgroundId:["80","81","82"],
          backgroundEditedUrl:[],
          imageEdited : false,
          actiButton:"Background1",
          waterMarkImg:"",
          activateButton:"Hd",
          downloadComplete: false,
          isDownload: false,
          urlDownload: '',
          dropBackground: false,
          checkColorEnhancement:false,
          checkWindowTransparency:false
        }
    }
    
    componentDidMount = async () => {
        var passing_location;
        if ("geolocation" in navigator) {
          // check if geolocation is supported/enabled on current browser
          navigator.geolocation.getCurrentPosition(
           function success(position) {
    
            //  console.log('latitude', position.coords.latitude, 
            //              'longitude', position.coords.longitude);
            passing_location = [position.coords.latitude, position.coords.longitude];
            localStorage.setItem("passing_location", passing_location);
         },
          function error(error_message) {
            passing_location = 'NULL'
            localStorage.setItem("passing_location", passing_location);
          }  
      )}
         else {
          passing_location = 'NULL'
          localStorage.setItem("passing_location", passing_location);
        }
    
        
        if (window.localStorage.getItem("clippr_user_email")!= null){
        let dict2={};
        var userEmail = this.cookie("emailId")
        let _userId_ = this.cookie("userId")
        let isValidated = this.cookie("isValidated")
    
        dict2['authenticated_email_id'] = userEmail;
        dict2['user_id'] = _userId_;
        dict2['product_category'] = 'Cars Replacement';
        dict2['passing_location'] = window.localStorage.getItem("passing_location");
        dict2['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                
        if (isValidated=='true'){
          dict2['otp_verified'] = 'YES';
          }
        else {
          dict2['otp_verified'] = 'NO';
          }
    
          axios.post('https://www.clippr.ai/api/get_starting_info/', dict2)
          .then((res) => {
            })
            .catch((err) => {
                })
        }
        var instructionModalCheck = window.localStorage.getItem("showInstructionModal")
        if (instructionModalCheck){
            this.setState({
                showInstruction:false
            })
        }
        else{
            this.setState({
                showInstruction:true
            })
        }
        axios({
            'method':"GET",
            'url':`https://www.clippr.ai/api/car-replacement-plans?api_key=${process.env.REACT_APP_API_KEY}`
        })
        .then((res) => {
            this.setState({
                planData:res.data
            })
        })
        let userId = this.cookie('userId')
        if(userId !== "NA"){
            fetchCreditUserDetails(userId).then((resp) => {
                this.setState({
                    availableCredit:resp.data.data.creditAvailable,
                    allotedCredit:resp.data.data.creditAlloted,
                    creditUsed:resp.data.data.creditUsed
                })
            } )
        }
    }
    
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]


    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false,
            dropBackground: false,
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            
            );
        }
        // this.hitapiModal()
      };

      handelColorEnhancement = () =>{
        this.setState({
            checkColorEnhancement:!this.state.checkColorEnhancement,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    handleWindowTransparency = () =>{
        this.setState({
            checkWindowTransparency:!this.state.checkWindowTransparency,
            toggleChange:false,
            toggleStatus:false,
        })
    }

    fileSelectedHandlerNew = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            askEmail:false,
            spinner:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            //If user is uploading image with choose Image Button
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0]),
                backgroundEditedUrl:[],
            },
            );
        }
        else{
            //If User is uploading file with drag and drop 
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                backgroundEditedUrl:[],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file),
                backgroundEditedUrl:[],
            },
            
            );
        }
        this.hitapiModal()
      };

      handleDownloadComplete = () => {
          this.setState({downloadComplete: false})
      }

    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        if(this.state.isEditingFinish){
            setTimeout(
                function() {
                    this.setState({
                        toggleChange:!this.state.toggleChange ,
                        toggleStatus:!this.state.toggleStatus,
                        spinner:false
                    });
                }
                .bind(this),
                500
            );
        }
        else{
            this.setState({
                toggleChange:false,
                toggleStatus:false
            })
        }
    }

    handleAngle=(id)=>{
        let selectedAngle = this.cookie("selectedAngle")
        this.setState({
          car_bg_replacement_angle:id,
          selectedAngle:selectedAngle,
          imageSelected:true,
          isEditingFinish:false
        })
    }

    handleId=(id,price)=>{
        this.setState({
            background_id:id,
            bgCredit:price,
            // isEditingFinish:false,
            askEmail:false,
            toggleChange:false,
            toggleStatus:false,
        })
    }
    updateCredit = (e) => {
        // e.preventDefault()
        let userId = this.cookie('userId')
        let price 
        if(this.state.activateButton === 'Hd'){
            price = this.state.bgCredit
        }else{
            price = 0
        }

        // let price = this.state.bgCredit
        // if(price > this.state.availableCredit){
        //     window.location.replace("https://www.spyne.ai/tech/pricing")
        // }
        // else {
            updateUserCredit(userId,price, this.state.availableCredit, this.state.allotedCredit, this.state.creditUsed)
            .then((res) => {
                this.setState({
                    availableCredit: this.state.availableCredit - price,
                    // downloadComplete: true
                })
                setTimeout(() => {
                    this.setState({downloadComplete: true})
                },5000)
                const output_link = this.state.activateButton == 'Hd' ? this.state.urlDownload : this.state.waterMarkImg;
                fetch(output_link, {
                method: "GET",
                headers: {}
                })
                .then(response => {
                    response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    let name = output_link
                    var index = name.lastIndexOf("/") + 1;
                    var filename = name.substr(index)
                    link.setAttribute("download", filename); 
                    document.body.appendChild(link);
                    link.click();
                    
                    });
                    
                })
            })
        // }
    }
    askEmail =() => {
        this.setState({
            askEmail:true
        })
    }

    // downloadPopup = () => {
    //     this.setState({isEditingFinish : false})
    // }

    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }

    

    downloadPopup = () => {
        this.setState({isDownload: false})
    }

    hitapi = () => {
        this.setState({
          spinner: true,
          hideLogo:true,
        });
        if(this.state.selectedFile !== null){
            const fd = new FormData();
            fd.append("image", this.state.selectedFile);
            fd.append("optimization",false)
            let api_key = 'fde46c58-5735-4fcf-8b38-980c95001dc3'
            axios.post(`https://www.clippr.ai/api/upload?api_key=${api_key}`,fd,{params:{'api_key':"fde46c58-5735-4fcf-8b38-980c95001dc3"}})
            .then((uploadedResponse) => {
                const replacementFd = new FormData()
                let timestamp= new Date().getTime();
                timestamp.toString();
                let sku_id="sku"+timestamp
                replacementFd.append("user_id", this.cookie("userId"));
                replacementFd.append("sku_id", sku_id);
                replacementFd.append("background", this.state.background_id);
                replacementFd.append("source","Web");
                replacementFd.append("image_url",uploadedResponse.data.image);
                replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
                replacementFd.append("contrast",this.state.checkColorEnhancement);
                axios
                .post("https://www.clippr.ai/api/v2/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
                .then((res) => {
                    let userEmail = this.cookie("emailId")
                    let _userId_ = this.cookie("userId")
                    let isValidated = this.cookie("isValidated")
                    if(!userEmail){
                        clearInterval(x)
                        var x = setTimeout(this.askEmail, 3000);
                    }
                    let temp = res;
                    if (temp.data['url']) {

                        if (userEmail){       

                            temp.data['authenticated_email_id'] = userEmail;
                            temp.data['user_id'] = _userId_;
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            if (isValidated=='true'){
                                temp.data['otp_verified'] = 'YES';
                            }
                            else {
                                temp.data['otp_verified'] = 'NO';
                            }
                            if (window.localStorage.getItem("clippr_user_email") != null){
                                temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            }
                            else{
                                temp.data['free_trail_email'] = 'NULL';
                            }
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                        }
            
                        else if (window.localStorage.getItem("clippr_user_email") != null) {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['free_trail_email'] = window.localStorage.getItem("clippr_user_email");
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                            
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }
                        else {
                            temp.data['product_category'] = 'Cars Replacement';
                            temp.data['authenticated_email_id'] = 'NULL';
                            temp.data['free_trail_email'] = 'NULL';
                            temp.data['otp_verified'] = 'NO';
                            temp.data['passing_location'] = window.localStorage.getItem("passing_location");
                            temp.data['width'] = '1280';
                            temp.data['height'] = '720';
                
                            axios.post('https://www.clippr.ai/api/get_image_info/', temp.data)
                            .then((res) => {
                                })
                                .catch((err) => {
                                })
                        }

                    }
                    if(this.state.selectedLogoFile !== null){
                        const logoFormData = new FormData()
                        logoFormData.append("logo",this.state.selectedLogoFile);
                        logoFormData.append("logo-position",this.state.logoPosition)
                        logoFormData.append("image_url",res.data.display_image)
                        axios.post("https://www.clippr.ai/api/dealership-logo",logoFormData)
                        .then((logores) => {
                            // const logoFormData2 = new FormData()
                            // logoFormData2.append("image",this.state.selectedLogoFile);
                            // logoFormData2.append("logo-position",this.state.logoPosition)
                            // logoFormData2.append("image_url",logores.data.org_url)
                            // axios.post("http://www.clippr.ai/models/car-logo",logoFormData2)
                            // .then((carLogo) => {
                                this.setState({ 
                                        url:logores.data.org_url,
                                        orgImgShow: true,
                                        processing: false,
                                        loader: false,
                                        editedState: true,
                                        uploadPercentage: 0,
                                        tabIndex: 1,
                                        spinner:false,
                                        toggleStatus:true,
                                        toggleChange:true,
                                        isEditingFinish:true,
                                        isDownload: true,
                                        waterMarkImg: logores.data.watermark_image,
                                        // selectedLogoFile:null,
                                        // logoUrl:''
                                    });
                            // })
                            // .catch((err)=>{
                            //     this.setState({
                            //         spinner:false
                            //     })
                            // })
                        })
                        .catch((err)=>{
                            this.setState({
                                spinner:false
                            })
                        })
                    }
                    else{
                        this.setState({ 
                            url:res.data.display_image,
                            orgImgShow: true,
                            processing: false,
                            loader: false,
                            editedState: true,
                            uploadPercentage: 0,
                            tabIndex: 1,
                            spinner:false,
                            toggleStatus:true,
                            toggleChange:true,
                            isEditingFinish:true,
                            waterMarkImg:res.data.watermark_image,
                            isDownload: true,
                            urlDownload: res.data.output_image
                            });
                    }
                    window.localStorage.setItem("car-replacement-original-url", uploadedResponse.data.image)
                    window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                    window.sessionStorage.setItem("lastEditedImage", res.data.output_image)
                    window.sessionStorage.setItem("lastRawImage",uploadedResponse.data.image)
                })
                .catch((err) => {
                    this.setState({
                        spinner:false
                    })
                })
            })
            .catch((uploadFail) => {
                this.setState({
                    spinner:false
                })
            })
        }
        else{
            const replacementFd = new FormData()
            // const replacementFd = new FormData()
            let timestamp= new Date().getTime();
            timestamp.toString();
            let sku_id="sku"+timestamp
            replacementFd.append("user_id", this.cookie("userId"));
            replacementFd.append("sku_id", sku_id);
            replacementFd.append("background", this.state.background_id);
            replacementFd.append("source","Web");
            replacementFd.append("image_url",this.state.orgUrl);
            replacementFd.append("window_status",this.state.checkWindowTransparency ? 'inner' : 'outer');
            replacementFd.append("contrast",this.state.checkColorEnhancement);
            axios
            .post("https://www.clippr.ai/api/v2/bulk-car-reaplacement?api_key=fde46c58-5735-4fcf-8b38-980c95001dc3", replacementFd)
            .then((res) => {
                // console.log(res.data.output_image)
                if(this.state.selectedLogoFile !== null){
                    const logoFormData = new FormData()
                    logoFormData.append("logo",this.state.selectedLogoFile);
                    logoFormData.append("logo-position",this.state.logoPosition)
                    logoFormData.append("image_url",res.data.output_image)
                    axios.post("https://www.clippr.ai/api/dealership-logo",logoFormData)
                    .then((logores) => {
                        let userEmail = this.cookie("emailId")
                        if(!userEmail){
                            clearInterval(x)
                            var x = setTimeout(this.askEmail, 3000);
                        }
                        this.setState({ 
                            url:logores.data.org_url,
                            orgImgShow: true,
                            processing: false,
                            loader: false,
                            editedState: true,
                            uploadPercentage: 0,
                            tabIndex: 1,
                            spinner:false,
                            toggleStatus:true,
                            toggleChange:true,
                            isEditingFinish:true,
                            isDownload: true,
                            waterMarkImg: logores.data.watermark_image,
                            // selectedLogoFile:null,
                            // logoUrl:''
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            spinner:false
                        })
                    })
                }
                else{
                    this.setState({ 
                        url:res.data.display_image,
                        orgImgShow: true,
                        processing: false,
                        loader: false,
                        editedState: true,
                        uploadPercentage: 0,
                        tabIndex: 1,
                        spinner:false,
                        toggleStatus:true,
                        toggleChange:true,
                        isEditingFinish:true,
                        waterMarkImg:res.data.watermark_image,
                        isDownload: true,
                        urlDownload: res.data.output_image
                        });
                }
                // window.localStorage.setItem("car-replacement-original-url", this.state.orgUrl)
                // window.localStorage.setItem("car-replacement-edited-url",res.data.url)
                window.sessionStorage.setItem("lastEditedImage", res.data.display_image)
                window.sessionStorage.setItem("lastRawImagsuve", this.state.orgUrl)
            })
            .catch((err) => {
                this.setState({
                    spinner:false
                })
            })
        }
    };

    handelCarShadow = () => {
        this.setState({
          carShadow:!this.state.carShadow
        })
    }
    dealerLogoFileHandler = (event) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
        this.setState(
            {
                logoUrl: [reader.result],
                logoPosition:"leftTop",
                hideLogo:false
            },
        );
        }.bind(this);
        this.setState(
        { 
            selectedLogoFile:event.target.files[0],
            logoUrl:  URL.createObjectURL(event.target.files[0])
        },
        );
    };

    handleLogoPosition = (e) => {
        console.log(e.target.name)
        this.setState({
          logoPosition:e.target.name,
          hideLogo:false
        })
    }
    handleInstruction = (e) => {
        // e.preventDefault()
        this.setState({
            showInstructionModal:e.target.checked
        })
        window.localStorage.setItem("showInstructionModal", e.target.checked)
    }
    handleInstructionModal = () => {
        this.setState({
            showInstruction:true
        })
    }
    hideLandingModal = () => {
        this.setState({showInstruction:!this.state.showInstruction})
    }
    handleActivButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
    dragStart = (e) => {
        e.preventDefault()
        this.state.dropBackground = true
      }
      dragLeave = (e) => {
        e.preventDefault()
        this.state.dropBackground = false
      }
    render() {
        // console.log(this.state.backgroundEditedUrl)
        return (
            <div className={styles['bg-try']}>
            
            {
            this.state.orgUrl == '' ?
            <>  
                {/* <LandingModal
                            showInstruction={this.state.showInstruction}
                            hideModal = {this.hideLandingModal}
                        /> */}
                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} onDragEnter={this.dragStart} onDragLeave={this.dragLeave}>
                    {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({
                                onClick: event => event.stopPropagation()
                            })} className={styles['dropzone1']}>
                                {this.state.dropBackground ?
                                    <section className={[styles['drop-box-text']]}>
                                    <div>Drop Image Anywhere</div>  
                                    
                                    </section>
                                    : null
                                }
                                <div className={styles['header-section']}>
                                    <p className={styles['heading-text']}>Car Background Replacement</p>
                                    <p className={styles['heading-para']}>Create Professional Automobile Pictures in a <span>Single Click</span> using AI</p>
                                </div>
                                <div className={[styles['car-main-wrapper'],""].join(" ")} id="cars-try-modal">
                                    <div className={[styles[''],"container"].join(" ")}>
                                        
                                        <div className={[styles['car-try-wrapper'],'row'].join(' ')}> 
                                            <div className={[styles['car-left-section'],'col-md-10 offset-md-1'].join(' ')}>
                                                        
                                                        <div className={[styles['car-mid-section'],''].join(' ')}>
                                                            {/* <button id="upload_widget" className={[styles['dropzone'],"cloudinary-button"].join(' ')} onClick={() => myWidget.open()} >Upload files</button> */}
                                                            <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section className={[styles['try-drag-drop'],'d-md-block d-none'].join(' ')}>
                                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/37820-upload-animation.gif"></img>
                                                                            <p className={styles["para-11"]}>DROP IMAGE HERE </p>
                                                                            <p class={styles["para-2"]}>or <span>SELECT IMAGE ON YOUR COMPUTER</span> </p>
                                                                            <p className={styles['supports1']}>Supports jpeg, jpg, bmp & png</p>
                                                                            <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <section className={[styles['try-drag-drop'],'d-block d-md-none'].join(' ')}>
                                                                <div  className={styles['dropzone1']}>
                                                                        <div className={styles['choose-button']}>
                                                                        <input 
                                                                        style={{ display: "none" }}
                                                                        type="file"
                                                                        onChange={this.fileSelectedHandler}
                                                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                                                        ></input>
                                                                        <button  onClick={() => this.fileInput.click()} className="btn">Upload Image</button>
                                                                    </div>
                                                                </div>
                                                            </section>

                                                            {/* <div className={[styles['choose-button'],'text-center d-md-block d-none'].join(' ')}>
                                                                <input 
                                                                style={{ display: "none" }}
                                                                type="file"
                                                                onChange={this.fileSelectedHandler}
                                                                ref={(fileInput) => (this.fileInput = fileInput)}
                                                                ></input>
                                                                <button  onClick={() => this.fileInput.click()} className=" d-md-block d-none">Choose Image</button>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                            <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/upload-1.png"></img>
                                                    
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/upload-2.png"></img>
                                                    
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],'col-md-4 d-md-block d-none'].join(' ')}>
                                                <div className={[styles['try-images-area'],'row m-0'].join(' ')}>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/upload-3.png"></img>
                                                    
                                                </div>
                                            </div>
                                            <div className={[styles['try-images-area'],'col-md-12 col-12 d-block d-md-none'].join(' ')}> 
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/car-sm.png"/>
                                            </div>
                                            
                                                
                                            {/* <div className={[styles['right-section'],''].join(' ')}>
                                                <div>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right%20side.png"></img>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                            </div>
                    )}
                </Dropzone>
                 
            </>
            :
            <div>
            
            <BulkProcessingV2
                fileSelectedHandler={this.fileSelectedHandler}
                fileSelectedHandlerNew={this.fileSelectedHandlerNew}
                orgUrl={this.state.orgUrl}
                handleId={this.handleId}
                editedUrl={this.state.url}
                handleToggle={this.handleToggle}
                toggleStatus={this.state.toggleStatus}
                handleAngle={this.handleAngle}
                imageSelected={this.state.imageSelected}
                handleClick={this.hitapi}
                handleModalApi={this.hitapiModal}
                isEditingFinish={this.state.isEditingFinish}
                handleDownload={this.updateCredit}
                creditUsed={this.state.creditUsed}
                availableCredit={this.state.availableCredit}
                creditAlloted={this.state.creditAlloted}
                defaultChecked={this.state.carShadow}
                planData={this.state.planData}
                spinner={this.state.spinner}
                toggleChange={this.state.toggleChange}
                background_id={this.state.background_id}
                dealerLogoFileHandler = {this.dealerLogoFileHandler}
                handleLogoPosition = {this.handleLogoPosition}
                logoUrl = {this.state.logoUrl}
                logoPosition = {this.state.logoPosition}
                hideLogo={this.state.hideLogo}
                askEmail={this.state.askEmail}
                backgroundUrl={this.state.backgroundEditedUrl}
                imageEdited = {this.state.imageEdited}
                downloadPopup = {this.downloadPopup}
                handleActivateButton={this.handleActivButton}
                activateButton={this.state.activateButton}
                downloadComplete={this.state.downloadComplete}
                handleDownloadComplete={this.handleDownloadComplete}
                isDownload={this.state.isDownload}
                // downloadPopup={this.downloadPopup}
                handleWindowTransparency = {this.handleWindowTransparency}
                handelColorEnhancement = {this.handelColorEnhancement}
                checkColorEnhancement = {this.state.checkColorEnhancement}
                checkWindowTransparency = {this.state.checkWindowTransparency}
                bgCredit={this.state.bgCredit}
            />
            </div>
            
            } 
            </div>
        )
    }
}