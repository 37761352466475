import React, { useContext, useEffect } from 'react'
 
import { Link, useHistory } from 'react-router-dom' 
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
  } from 'react-accessible-accordion';
import cls from '../TryCategories/TryCategories.module.css' 

 

const Drawer = (props) => {
	
    return (
            <div>
				<div className={[cls['drawer-area'],''].join(' ')}>
					<div className={[cls['drawer-links'],'row m-0'].join(' ')}>
						<div className={[cls['area'],''].join(' ')}></div>
						<nav className={[cls['main-menu'],''].join(' ')}>
							<ul><li><a href="https://www.spyne.ai/dashboard/login" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/dash1.png"/>
										<span className={[cls['nav-text'],''].join(' ')}> Dashboard </span>
									</a></li>
								<li className={[cls['has-subnav'],''].join(' ')}><a href="https://www.spyne.ai/category/cars/try" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/car-2.png"/>
										<span className={[cls['nav-text'],''].join(' ')}>	Automobile </span>
									</a></li>
								<li className={[cls['has-subnav'],''].join(' ')}><a href="https://www.spyne.ai/category/fashion/try" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/dress.png"/>
									<span className={[cls['nav-text'],''].join(' ')}>Fashion </span>
								</a></li>
								<li className={[cls[''],''].join(' ')}><a href="https://www.spyne.ai/category/product/try" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/products11.png"/>
									<span className={[cls['nav-text'],''].join(' ')}> Product</span>
								</a></li>
								<li className={[cls[''],''].join(' ')}><a href="https://www.spyne.ai/category/footwear/try" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/shoes.png"/>
									<span className={[cls['nav-text'],''].join(' ')}> Footwear</span>
								</a></li>
								<li className={[cls[''],''].join(' ')}>
									<Accordion allowZeroExpanded >
                                            <AccordionItem >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton className={[cls['accor-btn']]}>
													<img src="https://storage.googleapis.com/spyne-website/try-categories/fabric.png"/> <span className={[cls['nav-text'],''].join(' ')}> Fabric <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
												<ul className={[cls['inner-links'],''].join(' ')}><li><a href="https://www.spyne.ai/category/fabric/saree/try" target="_blank"><span className={[cls['drop-text'],''].join(' ')}> Saree </span>
													</a></li>
													<li><a href="https://www.spyne.ai/category/fabric/suit/try" target="_blank"><span className={[cls['drop-text'],''].join(' ')}> Suit </span>
													</a></li>
												</ul>
                                                </AccordionItemPanel>
                                          </AccordionItem>
										</Accordion>
								</li>
								<li className={[cls[''],''].join(' ')}>									
									<Accordion allowZeroExpanded>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton className={[cls['accor-btn']]}>
													<img src="https://storage.googleapis.com/spyne-website/try-categories/double-bed.png"/> <span className={[cls['nav-text'],''].join(' ')}> Home Furnishing <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
												<ul className={[cls['inner-links'],''].join(' ')}><li><a href="https://www.spyne.ai/category/home-furnishing/bedsheet/try" target="_blank"><span className={[cls['drop-text'],''].join(' ')}> Bedsheet </span>
													</a></li>
													<li><a href="https://www.spyne.ai/category/home-furnishing/curtains/try" target="_blank"><span className={[cls['drop-text'],''].join(' ')}> Curtain </span>
													</a></li>
													<li><a href="https://www.spyne.ai/category/home-furnishing/cushions/try" target="_blank"><span className={[cls['drop-text'],''].join(' ')}> Cushion </span>
													</a></li>
													
												</ul>
                                                </AccordionItemPanel>
                                          </AccordionItem>
										</Accordion>
								</li>
								<li className={[cls[''],''].join(' ')}><a href="https://www.spyne.ai/category/food/try" target="_blank"><img src="https://storage.googleapis.com/spyne-website/try-categories/dish.png"/>
									<span className={[cls['nav-text'],''].join(' ')}> Food</span>
								</a></li>
								
							</ul>
						</nav>
					</div>
				</div>
				

			 
		</div>
    )
}

export default Drawer