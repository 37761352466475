import React, { Component } from 'react'
import Axios from 'axios'
import '../../Css/userDashboard.css'
export default class UserDashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            creditAlloted:'',
            creditAvailable:'',
            userEmail:''

        }
    }
    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    componentDidMount = () => {
        let _userId_ = this.cookie("userId")
        let _userEmail_ = this.cookie("emailId")
        Axios({
            'method':'GET',
            'url':"https://www.spyne.ai/credit/user-total-credit",
            "params":{
                'userId':_userId_
            }
        })
        .then((res) => {
            this.setState({
                creditAlloted:res.data.data.creditAlloted,
                creditAvailable:res.data.data.creditAvailable,
                userEmail:_userEmail_
            })
        })
    }
    render() {
        return (
            <div className="main-wrapper">
                <div className="main-inner-wrapper">
                    <h1>My Dashboard</h1>
                    <p className="user-email">{this.state.userEmail}</p>
                    <div className="boxes">
                        <div className="dashboard-small-box">
                            <p>{this.state.creditAvailable}</p>
                            <p>Remaining Credit</p>
                        </div>
                        <div className="dashboard-small-box">
                            <p>{this.state.creditAlloted - 5}</p>
                            <p>Subscription Plan</p>
                        </div>
                        <div className="dashboard-small-box">
                            <p>5</p>
                            <p>Free Credits</p>
                        </div>
                    </div>
                    <div className="upgrade-plan">
                        <p>Account Log</p>
                        <a href="https://www.spyne.ai/tech/dashboard"><p className="upgrade-plan-para">Upgrade Plan</p></a>
                    </div>
                </div>                
            </div>
        )
    }
}
