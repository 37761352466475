import React, { Component } from "react";
import styles from '../../../Css/3DBuild/Suit/SelectModelSuit.module.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dropzone from 'react-dropzone'
import ProjectNameModal3D from "../../Common/Modal/ProjectNameModal3D";
import axios from "axios";

export default class SelectModelSuit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleStatus: true,
            dropBackground: false,
            downloadType: "",
            modelData: [],
            fetchStatus: false,
            fetchStatus2: false,
            modelId: 0,
            modelPose: [],
            poseToDisplay: [],
            selectedIndex: 0,
            categoryScreen: false,
        }
    }
    componentDidMount() {
        const api = `https://www.clippr.ai/api/v2/image/get-models-poses?auth_key=7c16ee5b-15e4-4155-b799-7d4be8a20164&category=Suit`;
        fetch(api)
            .then(response => response.json())
            .then(data => this.setState({ modelData: data.data }));
    }
    fileHandler = (acceptedFiles) => {
        this.setState({
            toggleStatus: true,
            dropBackground: false,
            downloadType: "",
        })
        this.props.fileSelectedHandler(acceptedFiles)
    }


    set_modelId = async (model_id, pose) => {
        await this.setState({ modelId: model_id, fetchStatus: true, modelPose: pose, poseToDisplay: [] });
    }
    screen_handler = () => {
        this.setState({ selectedIndex: 1 });
    }


    add_poses = (input) => {
        if (this.state.poseToDisplay.indexOf(input) > -1) {
            // var index = this.state.poseToDisplay.indexOf(input);
            this.state.poseToDisplay.splice(this.state.poseToDisplay.indexOf(input), 1);
            this.setState({
                poseToDisplay: this.state.poseToDisplay
            })
        }
        else {
            this.setState({ poseToDisplay: [...this.state.poseToDisplay, input] });
        }


    }
    poseUpload = () => {
        this.props.handleModelProcess();
        this.props.handleUploadScreen(this.state.poseToDisplay, this.state.modelId);


    }
    handleSelect = index => {
        this.setState({ selectedIndex: index });
    };

    gotBackTab = () => {
        this.setState({ selectedIndex: 0 })
    }


    render() {
        return (

            <div className={[styles['main-box'],''].join(' ')}>
                <section className={[styles['select-model'], ''].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['upload'], 'col-md-12'].join(' ')}>


                                <div className={[styles['upload'], 'col-md-12'].join(' ')}>
                                    {this.state.selectedIndex == 0 ?
                                        <span className={[styles['previous']]}>
                                            <button onClick={this.props.handleUploadBtn} className={[styles['back-button'], ''].join(' ')}>
                                                <img src={"https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/backupload.png"} />

                                            </button></span>
                                        :
                                        <span className={[styles['previous']]}>
                                            <button onClick={this.gotBackTab} className={[styles['back-button'], ''].join(' ')}>
                                                <img src={"https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/back.png"} />

                                            </button>
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>

                        <Tabs className={[styles['model-area'], 'row'].join(' ')} onSelect={this.handleSelect} selectedIndex={this.state.selectedIndex}>
                            <TabList className={[styles['model-tabs'], 'col-md-6 model-tabs'].join(' ')}>

                                <Tab ><span>1</span> Select Model</Tab>
                                <Tab disabled={this.state.fetchStatus ? false : true}><span>2</span> Select Pose</Tab>
                            </TabList>
                            <div className={[styles[''], 'col-md-6'].join(' ')}>
                                <div className={[styles['credit-area'], 'row'].join(' ')}>
                                    <div className={[styles['available-credit'], 'col-md-6 col-8'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />Available Credits: &nbsp;
                                        <span>{this.props.availableCredit}</span>
                                    </div>
                                    <div className={[styles['right-top-credits'], 'col-md-6 col-4 col-sm-3'].join(' ')}>
                                        <a href="https://www.spyne.ai/tech/pricing"><button>
                                            <span className={[styles['add-credit'], ''].join(' ')}> Add Credits</span> </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* {Selection of model and respective poses} */}

                            <TabPanel tabId="0" className={[styles['model-tabdetails'], 'col-md-12 display-none'].join(' ')}>
                                <div className={[styles['model-saree'], 'row m-0'].join(' ')}>
                                    <h4>Select a Model which you want your Suit with</h4>
                                    <div className={[styles['selection-model'], 'row m-0'].join(' ')}>

                                        {this.state.modelData.map((model) => {
                                            return (
                                                <div className={[styles['single-model'], 'col-md-2 col-4 col-sm-3'].join(' ')}>
                                                    <button onClick={() => this.set_modelId(model.model_id, model.poses)} className={this.state.modelId == model.model_id ? styles['active'] : null}>
                                                        <img src={model.model_display} />
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <h6><button disabled={this.state.fetchStatus ? false : true} onClick={() => this.screen_handler()} className={[styles['upload-btn']]}>Next</button></h6>
                                </div>
                            </TabPanel>
                            {/* // { End Saree Selection } */}

                            <TabPanel className={[styles['model-tabdetails'], 'col-md-12 display-none'].join(' ')}>
                                <div className={[styles['model-saree'], styles['pose'], 'row m-0'].join(' ')}>
                                    <h4>Select a Pose by the model which you want. <span>Note: You can select multiple poses.</span></h4>
                                    <div className={[styles['selection-model'], 'row m-0'].join(' ')}>
                                        {this.state.modelPose.map((pose) => {
                                            return (
                                                <div className={[styles['single-model'], 'col-md-2 col-4 col-sm-3'].join(' ')}>
                                                    <button onClick={() => this.add_poses(pose.pose_id)} className={this.state.poseToDisplay.indexOf(pose.pose_id) > -1 ? styles['active'] : null}>
                                                        <img src={pose.display_image} />
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles['button-padding']}>
                                    <label className={styles['toggle1']}>Enhance the color of image!
                                            <div style={{color:"black"}}>
                                                <snap style={{position:"absolute"}}>Color Enhancement</snap>
                                                <input type="checkbox" onChange={this.props.handelColorEnhancement} checked={this.props.checkColorEnhancement} />
                                                <span style={{color:"white"}} className={styles['slider1']}>{this.props.checkColorEnhancement?<p></p>:<p></p>}</span>
                                                </div>
                                            </label>
                                            </div>
                                    <h6><button disabled={this.state.poseToDisplay.length > 0 ? false : true} onClick={() => this.poseUpload()} className={[styles['upload-btn']]}>Next</button></h6>
                                </div>
                            </TabPanel>
                        </Tabs>
                        <ProjectNameModal3D
                            showProjectNameModal={this.props.showProjectNameModal}
                            handleProjectNameModal={this.props.handleProjectNameModal}
                            handleProjectName={this.props.handleProjectName}
                            project_name={this.props.project_name}
                            projectNameExists={this.props.projectNameExists}
                        />


                    </div>
                </section>



            </div>

        );
    }
}
