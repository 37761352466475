import React, { Component } from 'react'

import styles from '../../Css/Model/BackgroundRemoval.module.css'
import marginStyles from '../../Css/Model/ScalingMargins.module.css'
import Styles from '../../Css/Model/Beautification.module.css'
import {backgroundRemoval,uploadImageTOS3, imageCropping} from '../Common/Utils'
import {topWear,bottomWear} from '../Common/ScalingMarginsData'

import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone'
import {Spinner} from "react-bootstrap";
import Toggle from 'react-toggle' 



export default class Beautification extends Component {
    constructor(props){
        super(props)
        this.state={
            url:'',
            loader:false,
            selectedFile:this.props.selectedFile,
            toggleStatus:false,
            toggleChange:false,
            orgUrl:'',
            marketplaceData:[],
            selectedCategory:'topwear',
            selectedMarketPlaceId:'71',
            top:'',
            bottom:'',
            right:'',
            left:'',
            fetchingMarketplaces:true,
            processing:false,
            activeButton: "",
        }
    }


    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    handleCategory = (e) => {
        e.preventDefault()
        this.setState({
            selectedCategory:e.target.id,
            processing:false
        })
    }
    fileSelectedHandler = (event) => {
        this.setState({
            firstUpload:false,
            isEditingFinish:false,
            processing:false
        })
        if(this.state.toggleChange){
            this.setState({
                toggleChange:false,
                toggleStatus:false,
            })
        }
        if(event.target){
            var file = event.target.files[0];
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
            );
            // console.log("this.state.orgUrl",this.state.orgUrl)
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: event.target.files[0],
                orgUrl: URL.createObjectURL(event.target.files[0])
            },
            );
        }
        else{
            let file = event[0]
            if (Math.round(file.size / 1024) > 30720) {
                return;
            }
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
            this.setState(
                {
                orgUrl: [reader.result],
                },
                
                () => {
                try {
                    localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                    if (e == "QUOTA_EXCEEDED_ERR") {
                    }
                }
                }
                
            );
            }.bind(this);
            let editedImages;
            editedImages = localStorage.getItem("editedImagesBgr");
            editedImages = editedImages ? editedImages.split(",") : [];
            this.setState(
            {
                selectedFile: file,
                orgUrl: URL.createObjectURL(file)
            },
            );
        }
    };
    handleMarketPlaceId = (id) => {
        this.setState({
            selectedMarketPlaceId:id,
            processing:false
        })
        
    }
    handelProcess = () => {
        this.setState({
            loader:!this.state.loader,
        })
        let file = this.state.selectedFile
        const fd = new FormData()
        fd.append("image",file);
        fd.append("optimization", false)
        uploadImageTOS3(fd)
        .then((uploadResponse) => {
            const bgfd = new FormData()
            bgfd.append("image_url", uploadResponse.data.image)
            bgfd.append("category",this.state.selectedCategory)
            bgfd.append("marketplace",this.state.selectedMarketPlaceId)
            imageCropping(bgfd)
            .then((res) => {
                this.setState({
                    url:res.data.url,
                    orgImgShow: true,
                    loader: false,
                    editedState: true,
                    spinner:false,
                    toggleStatus:true,
                    toggleChange:true,
                    processing:true
                })
            })
            .catch((err) => {
                this.setState({
                    loader:false,
                    processing:false
                })
            })
        })
        .catch((err) => {
            this.setState({
                loader:false,
                processing:false
            })
        })
    }
    download = (e) => {
        e.preventDefault()
        const link = this.state.url;
        window.open(link, "_blank");
    }
    handleToggle = (e) => {
        // this.setState({
        //     spinner:true
        // })
        setTimeout(
            function() {
                this.setState({
                    toggleChange:!this.state.toggleChange ,
                    toggleStatus:!this.state.toggleStatus,
                    // spinner:false
                });
            }
            .bind(this),
            500
        );
    }


    handleActiveButton = (tabName) => {
        if (tabName === "Brush") {
            // Specific URL for this brush
            
            if (document.querySelector("html").classList.contains(Styles['brush'])) {
                this.setState({
                    activeButton: ""
                })
            } else {
                this.setState({
                    activeButton:tabName
                })
            }
            document.querySelector("html").classList.toggle(Styles['brush'])
            document.querySelector("html").classList.remove(Styles['brush1'], Styles['brush2'])

        } else if (tabName === "Brush1") {
            if (document.querySelector("html").classList.contains(Styles['brush1'])) {
                this.setState({
                    activeButton: ""
                })
            } else {
                this.setState({
                    activeButton:tabName
                })
            }
            document.querySelector("html").classList.toggle(Styles['brush1'])
            document.querySelector("html").classList.remove(Styles['brush'], Styles['brush2'])

        } else if (tabName === "Brush2") {
            if (document.querySelector("html").classList.contains(Styles['brush2'])) { 
                this.setState({
                    activeButton: ""
                })
            } else {
                this.setState({
                    activeButton:tabName
                })
            }
            document.querySelector("html").classList.toggle(Styles['brush2'])
            document.querySelector("html").classList.remove(Styles['brush'], Styles['brush1'])
        }
        
    }



    render() {
        return (
            <div>
                <div className={[styles['wrapper'],'row mt-5'].join(' ')}>
                    <div className={[styles['left-box-scaling'],'col-lg-7 col-md-7 col-sm-12 col-12'].join(' ')}>
                        <div className={[styles['display-image'],'',Styles['beautification-image']].join(' ')}>
                            {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/photo-1523260349522-b9d156a677c3%201.png" className="img-fluid" /> */}
                            {
                            this.state.loader ? 
                            <Spinner
                                className="loader-edited"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                                animation="border"
                                variant="primary"
                            />
                            :
                            <div className={styles['display-image']}>
                                {
                                    this.state.toggleStatus ?
                                    <img src={this.state.url} className="img-fluid"></img>
                                    :
                                    <img src={this.state.orgUrl !== '' ? this.state.orgUrl : this.props.originalImage} className="img-fluid"></img>
                                }
                            </div>
                        }
                        </div>
                    
                    
                        <div className="sec-2-toggle">
                            <p className="mr-5 mt-2">Original</p>
                            <label className="mt-2">
                                <Toggle onChange={this.handleToggle} icons={false} checked={this.state.toggleStatus} /> 
                            </label>
                            <p className="ml-5 mt-2">Edited</p>
                        </div>
                    </div>
                    <div className={[styles['right-box-scaling'],'col-12 col-sm-12 offset-1 col-lg-4 col-md-4',Styles['upload-beauti']].join(' ')} >
                        <p>Upload Image</p>
                        <div>
                            <div className={[marginStyles['upload-section'],"d-flex justify-content-center"].join(' ')} style={{margin:"0px", maxWidth:"100%"}}>
                                <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)} >
                                {({getRootProps, getInputProps}) => (
                                    <section className={[Styles['dropzone-section'],' '].join(' ')}>
                                        <div {...getRootProps()} className={[styles["screen-2-drop"],'container-fluid'].join(' ')}>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg" />
                                            <p className={[marginStyles["para-1"],''].join(' ')}>Drag Image Here</p>
                                            <p className={[marginStyles["para-2"],''].join(' ')}>or browse for image</p>
                                            <input  onChange={this.props.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                            <hr></hr>
                            <div>
                                <p>Brushes <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Vector%20(1).png" /></p>
                                <div className={[marginStyles['category-buttons'],'',Styles['category-options']].join(' ')}>
                                    <ul>
                                        <li> <button className={this.state.activeButton == "Brush" ? [Styles['active'],' '].join(' ') : null} onClick={() => this.handleActiveButton("Brush")} ><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Group.png" /></button></li>
                                        <li> <button className={this.state.activeButton == "Brush1" ? [Styles['active'],' '].join(' ') : null} onClick={() => this.handleActiveButton("Brush1")} ><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Vector.png" /></button></li>
                                        <li> <button className={this.state.activeButton == "Brush2" ? [Styles['active'],' '].join(' ') : null} onClick={() => this.handleActiveButton("Brush2")} ><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Brush.png" /></button></li>
                                         
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <p>Auto Beautify <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Vector%20(1).png" /></p>
                                
                                <div className={[marginStyles['category-buttons'],'',Styles['category-options']].join(' ')}>
                                    <ul>
                                        {/* <li className={Styles['active']}><a href="#"><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Group.png" /></a></li>
                                        <li><a href="#"><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Vector.png" /></a></li> */}
                                        <li><a href="#"><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/Group%20(1).png" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className={[Styles[''],'text-center mt-3'].join(' ')}>
                                <button className={Styles['mybtn']}>Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
