import React, { Component } from 'react'

import styles from '../../../Css/CarsLanding.module.css'
import {cookie} from '../../Common/Utils'

import {Link, withRouter} from 'react-router-dom'
import { useHistory } from 'react-router-dom';
// import { withRouter } from "react-router-dom";

class LandingPage extends Component {
    constructor(props){
        super(props)
        this.state={
            loginCheck:false,
            userEmail:''
        }
    }

    componentDidMount = () => {
        if(cookie("emailId")){
            this.setState({
                loginCheck:true
            })
        }
        else{
            this.setState({
                loginCheck:false
            })
        }
    }
    handelChange = (e) => {
        e.preventDefault()
        this.setState({
            userEmail:e.target.value
        })
    }
    handleSubmit = () => {
        this.props.history.push("/cars/try");
    }
    render() {
        let{loginCheck,userEmail} = this.state
        return (
            <>
            <div className={styles["landing-main-wrapper"]}>

                {/* Web View  */}

                <div className={[styles['web-view'],"d-flex justify-content-between"].join(' ')}>
                     <div className="col-6">
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/car.gif" className="img-fluid"></img>
                     </div>
                     <div className="col-6">
                        <div className={styles['left-section']}>
                            <div>
                                <h1 className={styles["banner-head"]}>Sell your <span style={{color:'#F61067'}}>car</span> online!</h1>
                                {/* <p className={styles["banner-head"]}>in a <b style={{color:"red"}}>Single Click</b> using AI</p> */}
                                <p className={styles['paraaa']}>Use our automated AI tech to edit your car photos and stage them on premium backgrounds that convert.</p>
                                
                            </div>
                            {   
                                !loginCheck ?
                                <form className='d-flex' onSubmit={this.handleSubmit}>
                                    <input type="email" onChange={this.handelChange} placeholder="Enter your email here*"className={[styles['email-input'],"form-control"].join(' ')}></input>
                                    <button type="submit" disabled={userEmail.length > 0 ? false : true } className={[styles['free-trail-button'],"btn btn-primary"].join(' ')}>Start Free Trial</button>
                                </form>
                                :
                                <button className={[styles['try-now-button'],"btn btn-primary"].join(' ')} onClick={this.handleSubmit}>Try Now</button>
                            }
                            <p className={styles['automated']}>Fully Automated and Free</p>
                            <p className={[styles['image-cnt'],""].join(' ')}>2M+ Images Processed and counting</p>
                        </div>
                     </div>
                </div>

                {/* Mobile View */}

                <div className={[styles['mobile-view'],""].join(' ')}>
                    <div className={styles["mob-banner-section"]}>
                        <p className={styles["para-heading"]}>Instantly Replace Background in a <b style={{color:"red"}}>Single Click</b> using AI</p>
                        <p className={styles["automated"]}>Fully Automated and Free</p>
                        <img src="https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/car.gif" alt="remove background from image online free" className={["mob-gif-image", ["img-fluid"]].join(' ')}></img>
                        <div id="non-login">
                            <p className={styles["banner-section-2-bottom-para-1"]}>Get <b>5 Images</b> for Free</p>
                                {   !loginCheck ?
                                    <form onSubmit={this.handleSubmit} className={[styles["mob-banner-section-2-bottom"], "d-flex flex-column"].join(' ')}>
                                        <input  type="email" onChange={this.handelChange} placeholder="Enter your Email here*" className={["form-control", styles["mob-email-input"]].join(' ')} type="email" id="user_email_mobile" onchange="getEmailForMobile()"></input>
                                        <button type="submit" disabled={userEmail.length > 0 ? false : true }>Start Free Trial</button>
                                    </form>
                                    :
                                    <button onclick={this.handleSubmit} className={styles['try-now-mob']}>Try Now</button>
                                }
                            <p className={styles['image-processing']}>2M+ Images Processed and counting</p>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
export default withRouter(LandingPage)
