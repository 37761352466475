import React, { Component } from 'react'
import axios from 'axios'
import Styles from '../../../Css/CarV2.module.css'
import { InlineWidget } from "react-calendly";
import styles from '../../../Css/Whitepaper/WhitepaperBackgrounds.module.css'
export default class WhitepaperBackgrounds extends Component{
    
    constructor(props){
        super(props)
        this.state={
            gifList:[],
            gifUrl: 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/gifV2/82.gif', 
            planData:[],
            background_id:""
        }
        this.myDivToFocus = React.createRef()
    }
    componentDidMount = async () => {
        axios.get('https://www.clippr.ai/api/fetch-gif')
            .then(res => {
                this.setState({
                    gifList: res.data
                })
            })

            axios({
                'method':"GET",
                'url':`https://www.clippr.ai/api/car-replacement-plans-web?api_key=${process.env.REACT_APP_API_KEY}`
            })
            .then((res) => {
                this.setState({
                    planData:res.data
                })
            })    
    }
    handleBackgroundGif = (imageId,imageCredit, gifUrls) => {
 
                this.setState({
                    gifUrl: gifUrls
                }) 
    }

    applyChange = (e) => { 
         this.myDivToFocus.current.scrollIntoView()  
      }




    render(){
        return(
            <div>
                {/* Backgrounds Whitepaper start */}
                <section className={[styles['whitepaper-bg'],''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles['choose-output'],'row'].join(' ')}>
                            <div className={[styles['heading'],'col-md-12'].join(' ')}>
                                <h3>Our Amazi<span className={[styles['border-bt']]}>ng Car <span className={[styles['colored']]}>Backgrounds</span></span> </h3>
                            </div>
                            <div className={[styles['background-right'],'col-md-6 col-lg-7 col-12'].join(' ')}>
                                    <img className="" src={this.state.gifUrl} /> 
                                <div className={[styles['try-demo'],'row text-center'].join(' ')}>
                                    <p><a href="https://www.spyne.ai/tech/cars/try" target="_blank">Try Now</a>   <span>10 free credits on Sign Up</span></p>
                                        <p className={[styles['demo']]}><a onClick={this.applyChange}>Book Demo</a> <span>Connect with  our Enterpirse team</span>
                                    </p>
                                </div>
                            </div>
                            <div className={[styles['background-area'],'col-md-6 col-lg-5 col-12'].join(' ')}>
                                <div className={[styles['backgrounds'],'row m-0'].join(' ')}>
                                    <h6><span className={[styles['colored']]}>Spyne’s</span> <span>Backgrounds  </span></h6>
                                    <ul className={[styles['background-images'],''].join(' ')}>
                                        {
                                            this.state.planData.map((e,i) => {
                                                return(
                                                    <li className={this.state.background_id == e.imageId ? styles['active-background-sec']:styles['inactive-background-sec']} onClick={()=>this.handleBackgroundGif(e.imageId,e.imageCredit, e.gifUrl)}>
                                                        <img className="" src={e.imageUrl}/>
                                                        <div className={[styles['backgrounds-content'],'row pl-0 pr-0'].join(' ')}>
                                                            <p className={[styles['back-name'],'col-10 col-md-10'].join(' ')}>{e.bgName} - {e.imageId}</p>
                                                            <p className={[styles['back-credit'],styles['backcredit'],'col-2 col-md-2'].join(' ')}>{e.imageCredit}
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/>
                                                            </p>
                                                        </div>
                                                    </li>
                                                )
                                                
                                            })
                                        }
                                    
                                    </ul> 
                                </div> 
                            </div> 
                        </div> 
                    </div>
                </section>
            {/* Calendly Area Start */}
                <section className={[Styles['calendly-area'],' '].join(' ')} ref={this.myDivToFocus}>
                    <div className={[Styles[''],'container-fluid'].join(' ')}>
                        <div className={[Styles[' '],'row'].join(' ')}>
                            <div className={[Styles['title'],'col-md-12'].join(' ')}>
                            <h1> <span className={[Styles['no-border']]}>Earn more by </span> selling your car <span className={[Styles['no-border']]}> online using AI</span></h1>  
                                <h6><span>Book a Demo</span> today to learn more!</h6>
                            </div>

                            <div className={[Styles['cars-demo '],'col-lg-8 col-md-7 col-sm-6'].join(' ')}>  
                                <div className={[Styles['cars-video'],'row m-0'].join(' ')}>
                                    <iframe width="100%" height="auto" src="https://www.youtube.com/embed/LV9sgTxsIgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>

                            <div className={[Styles['cars-demo'],'col-lg-4 col-md-5 col-sm-6'].join(' ')}>  
                                <div className={[Styles['cars-calender'],'row m-0'].join(' ')}>
                                    <h4>Book a Demo </h4>
                                    <InlineWidget className={[Styles['calendly-inline-widget'],'p-0'].join(' ')} styles={{"height": "100%"}} url="https://calendly.com/spyneai/footweardemo?hide_event_type_details=1&hide_gdpr_banner=1"  style={{"minwidth": "320px"}} />
                                </div> 
                            </div> 
                        </div>
                    </div>
                </section>     
            
            {/* Calendly Section */}
            </div>
        )
    }
}
