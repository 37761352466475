 
import React, { Component } from 'react'
 
import Styles from '../../Css/BulkProcessing/BulkProcessing.module.css'
// import BulkProcessing from './BulkProcessing';

export default class BulkProcessing extends Component {
    constructor(props){
        super(props)
        this.state={ 
             showSpin:false,
             final: false,
        }
    }

   
    onClick= () => {
        this.setState({
            showSpin:true
        })
        setTimeout(() => {
            this.setState({
                showSpin:false,
                final:true
            })
          }, 2000);
      }
  
      
    onClick2= () => {
        this.setState({
            showSpin:false,
            final:false
        })
      }
  



    render() {
        

        return (
            <div>
                {
                    !this.state.showSpin ?
                <>
                {
                    !this.state.final ?
                    <>
                        <section className={[Styles['bulk-processing'],''].join(' ')}>
                            <div className={[Styles[''],'container'].join(' ')}>  
                              <div className={[Styles[''],'row'].join(' ')}>
                                <div className={[Styles['bulk-area'],'col-md-10 offset-md-1 text-center'].join(' ')}> 
                                    <h4>Select a folder to upload</h4>
                                    <p>Paste link in the input box</p>
                                    <div className={[Styles['outer-box'],'row m-0'].join(' ') }>
                                        <input type="text" placeholder="Paste link here" className={[Styles[''],'form-control'].join(' ')}  />
                                        <button  type="submit" className={[Styles['mybtn'],'btn'].join(' ')} onClick={() => this.onClick()} > Process</button>
                                    </div>
                                  </div>     
                                </div>
                            </div>
                        </section>
                    </>
                :
                   <section className={[Styles['bulk-processing'],'', Styles['complete-process']].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>  
                        <div className={[Styles[''],'row'].join(' ')}>
                            <div className={[Styles['bulk-area'],'col-md-10 offset-md-1 text-center'].join(' ')}> 
                                <h4>Your process is complete!</h4>
                                <p>Click the given link to access your files</p>
                                <div className={[Styles['outer-box'],'row m-0  '].join(' ') }>
                                    <a href="#" target="_blank">https://output.ai</a>
                                    <button   className={[Styles['mybtn'],'btn'].join(' ')} onClick={ this.onClick2}> New Process</button>  
                                </div> 
                            </div>
                            </div>
                        </div>
                    </section> 

                }
                </>
                :
                <>
                 <section className={[Styles['bulk-processing'],''].join(' ')}>
                    <div className={[Styles[''],'container'].join(' ')}>  
                        <div className={[Styles[''],'row'].join(' ')}>
                                <div className={[Styles['bulk-area'],'col-md-10 offset-md-1 text-center'].join(' ')}> 
                                   <div className={[Styles['outer-box'],'row m-0'].join(' ') }>
                                    <div className={  [Styles['spinner'],'spinner-border  text-info'].join(' ') }></div> 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 
                </>
                
                }
            </div>
        )
    }
}
