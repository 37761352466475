import React, { Component } from 'react'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import styles from '../../Css/Footwear/FootwearBulkBgV2.module.css'
import Slider from "react-slick";
import BackgroundListModalBulk from '../Common/Modal/BackgroundListModalBulk'
import UploadLimitModal from '../Common/Modal/UploadLimitModal'
import DealershipLogo from '../Common/DealerShipLogo'
import BulkCreditModal from '../Common/Modal/BulkCreditModal';
import ProjectNameModal from '../Common/Modal/ProjectNameModal';
import RefundModal from '../Common/Modal/RefundModal';
import Dropzone from 'react-dropzone'
import marketPlace from '../FashionRemovalV2/marketPlace'
import {Helmet} from "react-helmet";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-block", background: "#fff" }}
        onClick={onClick}
      />
    );
  }



// import Logo from './Logo';
export default class BulkCarsBackground extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Original",
            selectedMarketPlaceId:'',
            orignalTab: "Original",
            isAskEmail:true,
            below_bg:false,
            showBackgroundList: false,
            activeButtonNew:"Edited",
          
        }
        
        
    }
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name,
            activeButtonNew:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }

    fileHandler = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUpload(acceptedFiles)
      }
    handleActivateButton = (name) => {
        this.setState({
            activateButton:name
        })
    }
      fileHandlerNew = (acceptedFiles) => {
        // console.log(acceptedFiles.target)
        this.props.multipleFileUploadProject(acceptedFiles)
      }

      handleBackground = (imageId, imageCredit,below_bg) => {
        this.setState({
          isAskEmail:true,
          below_bg:below_bg
        })
        this.props.handleId(imageId,imageCredit)
      }

      handleBackgroundList = () => {
        this.setState({showBackgroundList: true})
      }
      hideList = () => {
        this.setState({showBackgroundList: false})
      }
      handleCustomBackgroundColor = (bg_url) => {
        this.props.handleBgColor(bg_url)
        this.setState({
            bg_color:bg_url,
        })
    }
    handleMarketPlaceId = (id) => {
        this.setState({
            selectedMarketPlaceId:id,
            step2:true,
        })
        this.props.handlemarket(id)
    }
    render() {
        const settings = { 
            infinite: false,
            slidesToShow:3,
            slidesToScroll: 1, 
            swipeToSlide: true,
            autoplay: false,
            speed: 500, 
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          };
          const settings_2 = { 
            infinite: false,
            slidesToShow:4,
            slidesToScroll: 1, 
            swipeToSlide: true,
            autoplay: false,
            speed: 500, 
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
          };

        const orgCars = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            <div className={[styles['reload-cancel'],''].join(' ')}>
                                {/* <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span> */}
                                {/* <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span> */}
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span><img onClick={() => this.props.updateMultipleImageList(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /></span>
                                </div>
                            </div>
                            <img src={carFile.url} />
                            {/* <p>{carFile.name}</p> */}

                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                            </div>
                            
                        </div>
                    </div>

        
            
        })
        const orgCarsUrl = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            
                            {/* <img src={carFile.url} /> */}
                            <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.url}>
                                     <img src={carFile.url} />
                            </LightgalleryItem>
                            {   carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : carFile.status == 'Uploading' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                                : null
                            }
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-6"><p>{carFile.name}</p></div>
                                { carFile.status == 'Done' || carFile.status == 'Error' ?
                                    <div className="col-6 text-right">
                                    
                                        {
                                            carFile.status == 'Done' ?
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                        : carFile.status == 'Error' ?
                                            <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                        : null
                                        }
                                        
                                        
                                    </div>
                                    : null
                                }
                                
                                
                            </div>
                            
                        </div>
                    </div>
        })
        const editedUrl = this.props.filesList.map((carFile,i) => {
            return <div key={i} className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                            {this.props.bulkDownloadDone || carFile.status == 'Uploading' ? null : 
                                <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                    <span><img onClick={() => this.props.getRefundModal(i)} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/cross-round.png" /></span>
                                </div>
                            }
                                
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Uploading' ?
                                <img style={{maxHeight: "80px"},{objectFit: "contain"}} src={carFile.url}/>
                            : carFile.status == 'Error' ?
                                <img onClick={this.props.bulkUploadAPI(i)} style={{maxHeight: "80px"},{objectFit: "contain"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                            : carFile.status == 'Done' ?
                               
                                <LightgalleryItem itemClassName='px-0' style={{padding: "0px"}} src={carFile.fullScreenOutput}>
                                     <img src={carFile.outputImgUrl} />
                                </LightgalleryItem>
                            : null
                            }
                            
                            {carFile.status == 'Uploaded' ?
                                <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : carFile.status == 'Uploading' ?
                            <img style={{height: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/load-screen.gif" />
                            : null
                            }
                            
                            <div className={[styles['upload-car-name-gif'],'row justify-content-center'].join(' ')}>
                                <div className="col-10 pl-4"><p>{carFile.name}</p></div>
                                {/* <div className="col-6 text-right">
                                    
                                    { carFile.status == 'Done' ?
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/done-gif.gif"/>
                                    : carFile.status == 'Error' ?
                                        <img style={{width: "auto"}} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/Error-512.png"/>
                                    : null
                                    }
                                    
                                    
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
        })
        const backLeft = this.props.planData.length - 8
        return (

            <><Helmet>
                <title>Replace Shoes Background Instantly | Shoe Photo Editor.Try Now</title>
                <meta id="meta-description" name="description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                <meta property="og:title" content="Replace Shoes Background Instantly | Shoe Photo Editor.Try Now" />
                <meta property="og:description" content="Convert Your Footwear Background Images to Market Places Ready Images in Just 5 Seconds with Spyne Shoes Photo Editing Tech.Instantly Remove Background for Shoes and give your png Images a new look.100 % Ai Shoe Editing Apps Remove your background with a Click." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
            </Helmet>
            <div>
                    {this.props.uploadStarteds ?
                        <section className={[styles['footwear-main'], ''].join(' ')}>
                            <div className={[styles[''], ""].join(" ")}>
                                <div className={[styles[''], "row"].join(" ")}>
                                    <div className={[styles[''], "col-md-7"].join(" ")}>

                                        <div className={[styles['background-tabs'], 'row'].join(' ')}>
                                            <ul className={[styles['change-tabs'], ''].join(' ')}>
                                                <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                <div className={[styles['choose-button'], 'text-center '].join(' ')}>

                                                                    <button className="">
                                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                                        Upload New Project</button>
                                                                </div>
                                                                <input onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <li className={[styles[' '], ''].join(' ')}>
                                                    <button className={this.state.activeButtonNew == "Original" ? [styles['active'], ' '].join(' ') : null} onClick={() => this.handleActiveButton("Original")}>
                                                        <span>Original</span>
                                                    </button>
                                                </li>
                                                <li className={[styles[''], ''].join(' ')}>
                                                    <button className={this.state.activeButtonNew == "Edited" ? [styles['active'], ' '].join(' ') : null} onClick={() => this.handleActiveButton("Edited")}>
                                                        <span>Edited</span>
                                                    </button>
                                                </li>
                                            </ul>
                                            {this.state.activeButtonNew == "Original" ?

                                                <div className={[styles['tab-content'], ''].join(' ')}>
                                                    <div className={styles['container-fluid']}>
                                                        <div className="row">

                                                            <LightgalleryProvider
                                                                lightgallerySettings={{
                                                                    "download": false,
                                                                    "thumbnail": false,
                                                                    "fullScreen": true,
                                                                    "zoom": true
                                                                }}
                                                            >
                                                                {orgCarsUrl}
                                                            </LightgalleryProvider>
                                                        </div>
                                                    </div>

                                                </div>
                                                : this.state.activeButtonNew == "Edited" ?

                                                    <div className={[styles['tab-content'], ''].join(' ')}>
                                                        <div className={styles['container-fluid']}>
                                                            <div className="row">
                                                                <LightgalleryProvider
                                                                    lightgallerySettings={{
                                                                        "download": false,
                                                                        "thumbnail": false,
                                                                        "fullScreen": true,
                                                                        "zoom": true
                                                                    }}
                                                                >
                                                                    {editedUrl}
                                                                </LightgalleryProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null}




                                        </div>
                                    </div>
                                    <div className={[styles['background-content'], "col-md-4 offset-md-1"].join(" ")}>
                                        <div className={[styles['credit-text'], ""].join(" ")}>
                                            <div className={[styles[''], "row"].join(" ")}>
                                                <div className={[styles[''], "col-md-6"].join(" ")}>
                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                                    Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                                </div>
                                                <div className={[styles['right-top-credits'], "col-md-6"].join(" ")}>
                                                    <a href="https://www.spyne.ai/tech/pricing">
                                                        <button>

                                                            <span className={[styles['add-credit'], ""].join(" ")}> Add Credits</span> </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <h4> Preview & Download </h4>
            <h5>Your Selected Marketplaces </h5>
            <Slider {...settings} className={[styles['brand-tabs'],styles['brand-slider'],''].join(' ')}>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand1" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand1")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand2" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand2")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/flipkart_logo.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand3" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand3")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/myntra_logo.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p className={[styles['colored'],''].join(' ')}></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand4" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand4")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/snapdeal.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateeButton == "Brand5" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand5")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/ajio.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
                
                <div className={[styles['slide-content'],''].join(' ')}>
                    <button className={this.state.activateButton == "Brand6" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand6")}>
                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/tata-cliq.png"/>
                        <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                    </button>
                    <p></p>
                </div>
            
            </Slider> */}

                                        <div className={[styles['bulk-bg'], 'row'].join(' ')}>
                                            <div className={[styles['background-grid'], 'col-md-12'].join(' ')}>
                                                <div className={[styles['apply-btn-section'], 'col-md-12'].join(' ')}>

                                                    {this.props.bulkDownloadDone ?
                                                        <div><a href={this.props.downloadZipLink} target="_blank"></a></div>
                                                        :
                                                        <div>
                                                            <h6>Unsatisfied with the Output ?</h6>
                                                            <p>Click
                                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                                                on Images to  request refund  </p>
                                                        </div>}

                                                </div>
                                                <div className={[styles['download-card-gif'], 'row m-0'].join(' ')}>
                                                    {/* <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif"/>    */}
                                                    {this.props.isZipReady ?
                                                        <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/download-gif.gif" />
                                                        :
                                                        <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images-gif.gif" />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={[styles['apply-btn-section'], 'col-md-12'].join(' ')}>
                                            <button onClick={this.props.downloadImages} disabled={this.props.isZipReady ? false : true}>Download All</button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :

                        <section className={[styles['footwear-main'], ''].join(' ')}>
                            <div className={[styles[''], ""].join(" ")}>
                                <div className={[styles[''], "row"].join(" ")}>
                                    <div className={[styles['left-sec'], ""].join(" ")}>
                                        <div className={[styles['background-tabs'], 'row'].join(' ')}>
                                            <Dropzone onDrop={acceptedFiles => this.fileHandlerNew(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                        <div {...getRootProps({})} className={styles['dropzone1']}>
                                                            <div className={[styles['choose-button'], 'text-center '].join(' ')}>

                                                                <button className="">
                                                                    <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/upload.png" />
                                                                    Upload New Project</button>
                                                            </div>
                                                            <input onChange={this.fileHandlerNew} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <ul className={[styles['change-tabs'], ''].join(' ')}>
                                                <li className={[styles[' '], ''].join(' ')}>
                                                    <button className={this.state.activeButton == "Original" ? [styles['active'], ' '].join(' ') : null} onClick={() => this.handleActiveButton("Original")}>
                                                        <span>Original</span>
                                                    </button>
                                                </li>
                                                <li className={[styles[''], ''].join(' ')}>
                                                    <button className={this.state.activeButton == "Edited" ? [styles['active'], ' '].join(' ') : null} onClick={() => this.handleActiveButton("Edited")} disabled>
                                                        <span>Edited</span>
                                                    </button>
                                                </li>
                                            </ul>
                                            {this.state.activeButton == "Original" ?
                                                <Dropzone onDrop={acceptedFiles => this.fileHandler(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className={[styles['try-drag-drop'], 'd-md-block d-none'].join(' ')}>
                                                            <div {...getRootProps({})} className={styles['dropzone1']}>
                                                                <div className={[styles['add-more'], ''].join(' ')}>

                                                                    <button>+</button>

                                                                </div>
                                                                <img className={[styles['more-text'], ''].join(' ')} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/add-images.png" />
                                                                <input onChange={this.fileHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>

                                                : null}

                                            {this.state.activeButton == "Original" ?
                                                <div className={[styles['tab-content'], ''].join(' ')}>
                                                    <div className={styles['container-fluid']}>
                                                        <div className="row">
                                                            <LightgalleryProvider
                                                                lightgallerySettings={{
                                                                    "download": false,
                                                                    "thumbnail": false,
                                                                    "fullScreen": false,
                                                                    "zoom": false
                                                                }}
                                                            >

                                                                {orgCars}


                                                            </LightgalleryProvider>
                                                        </div>
                                                    </div>

                                                </div>
                                                :
                                                <div className={[styles['tab-content'], 'row'].join(' ')}>


                                                </div>}




                                        </div>
                                    </div>





                                    <div className={[styles['background-content'], ""].join(" ")}>
                                        <div>
                                            {/* <ul className={[styles['brand-tabs'],''].join(' ')}>
                    <li className={[styles[' '],''].join(' ')}>
                        <button className={this.state.activateButton == "Brand1" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand1")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/amazon_logo_new.png"/>
                            <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/>
                        </button>
                        <div className={[styles['bg-box'],''].join(' ')}>
                            <h6>Backgrounds Color</h6>
                            <ul>
                                <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/white%20bg.png" />
                                    <p>white <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/></span></p>
                                </li>
                                <li><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/grey%20bg.png" />
                                    <p>Grey <span>10 <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/coin.png"/></span></p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activateButton == "Brand2" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand2")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/flipkart_logo.png"/>
                        </button>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activateButton == "Brand3" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand3")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/myntra_logo.png"/>
                        </button>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activateButton == "Brand4" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand4")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/snapdeal.png"/>
                        </button>
                    </li>
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activateeButton == "Brand5" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand5")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/ajio.png"/>
                        </button>
                    </li>
                    
                    <li className={[styles[''],''].join(' ')}>
                        <button className={this.state.activateButton == "Brand6" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActivateButton("Brand6")}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/FootwearV2/tata-cliq.png"/>
                            {/* <img className={styles['cross-icon']} src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg"/> */}
                                            {/* </button>
            </li>
            
        </ul>  */}
                                            <div className={[styles['credit-text'], ""].join(" ")}>
                                                <div className={[styles[''], "row"].join(" ")}>
                                                    <div className={[styles[''], "col-md-6"].join(" ")}>
                                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                                        Available Credits: &nbsp; <span> {this.props.availableCredit}</span>
                                                    </div>
                                                    <div className={[styles['right-top-credits'], "col-md-6"].join(" ")}>
                                                        <a href="https://www.spyne.ai/tech/pricing">
                                                            <button>

                                                                <span className={[styles['add-credit'], ""].join(" ")}> Add Credits</span> </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className={[styles['right-top-selection']]}>
                      <div className={this.state.visualSelection=="backgrounds"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}}  >Backgrounds </div>
                      <div className={this.state.visualSelection=="custom_walls"?styles['active-visual-selection']: styles['inactive-visual-selection']} style={{cursor:"pointer"}} ></div>


                  </div>  */}
                                            <div className={[styles['border-1']]}></div>
                                            <div>
                                                <div className={[styles['custom-walls-area'], ''].join(' ')}>
                                                    <div>
                                                        <div className={this.state.bg_color != '' ? [styles['custom-wall'], ''].join(' ') : [styles['adjust-height'], ''].join(' ')}>
                                                            <p><span></span><b>Backgrounds</b></p>
                                                        </div>

                                                        <div className={[styles['side-margin']]}>
                                                            <div className={styles['solid-backgroung']}>
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/white%20bg.png" height='142px' width='150px' onClick={() => this.handleCustomBackgroundColor("white")} style={this.state.bg_color == 'white' ? { border: "1px solid #FF7700", cursor: "pointer" } : { cursor: 'pointer' }}></img>
                                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/grey%20bg.png" height='142px' width='150px' onClick={() => this.handleCustomBackgroundColor("grey")} style={this.state.bg_color == 'grey' ? { border: "1px solid #FF7700", cursor: 'pointer' } : { cursor: 'pointer' }}
                                                                ></img>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className={[styles['border-1'], ''].join(' ')}></div>

                                                    <div className={this.state.selectedMarketPlaceId != "" ? [styles['custom-wall'], ''].join(' ') : [styles['adjust-height'], ''].join(' ')}>
                                                        <p><span></span><b>Marketplace</b></p>
                                                    </div>
                                                    <div className={[styles['side-margin']]}>
                                                        <div className={[styles['marketplaces'], ''].join(' ')}>
                                                            {marketPlace.slice(0, 9).map((e, i) => {
                                                                return (
                                                                    <div key={i} style={this.state.selectedMarketPlaceId == e.name ? { border: "1px solid #FF7700", width: "53px" } : null}>
                                                                        <img src={e.img} onClick={() => this.handleMarketPlaceId(e.name)} style={{ height: "50px", width: '50px' }}></img>
                                                                    </div>
                                                                );
                                                            })}
                                                            {marketPlace.length > 9 ? (
                                                                <div className={styles['inactive-background-sec']} onClick={this.handleMarketPlace}>
                                                                    <div className={styles['overlay-pos']}>
                                                                        <img alt="" src="https://storage.googleapis.com/spyne-website/try-categories/morre.png" style={{ position: "relative" }} />
                                                                        <div className={styles['img-overlay']}></div>
                                                                        <div className={styles['more-btn-overlay-2']}>+ {marketPlace.length - 9} More</div>
                                                                    </div>
                                                                </div>) : null}
                                                        </div>
                                                    </div>
                                                    <div className={[styles['border-1'], ''].join(' ')}></div>

                                                    <div className={[styles['adjust-height'], ''].join(' ')}>
                                                        <p><span></span></p>

                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                            <label className={styles['toggle1']}>
                                                <div style={{ color: "black" , margin: "0 40PX" }}>
                                                    <snap style={{ position: "absolute" }}>Color Enhancement&ensp;<img className="" src="https://storage.googleapis.com/spyne-website/non-automobile-try/Group.png" title="Enhance color image" /></snap>
                                                    <input type="checkbox" onChange={this.props.handleColorEnhancement} checked={this.props.checkColorEnhancement} />
                                                    <span style={{ color: "white" }} className={styles['slider2']}><p></p>
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                            <div className={[styles['apply-btn'], 'col-md-12'].join(' ')}>
                                                <button onClick={this.props.creditVerify}>Apply SpyneAI <span style={{ "fontSize": "14px" }}>({this.props.filesList.length} Credits)</span>  </button>
                                            </div>
                                            <BackgroundListModalBulk
                                                handleBackground={this.handleBackground}
                                                background_id={this.props.background_id}
                                                planData={this.props.planData} hideList={this.hideList}
                                                showBackgroundList={this.state.showBackgroundList}
                                                orgUrl={this.props.orgUrl}
                                                applyChange={this.applyChange}
                                                creditVerify={this.props.creditVerify} />
                                            <UploadLimitModal
                                                maxUpload={this.props.maxUpload}
                                                handleUploadLimitModal={this.props.handleUploadLimitModal}
                                                emptyMultipleFileArray={this.props.emptyMultipleFileArray} />
                                            <BulkCreditModal
                                                showBulkCreditModal={this.props.showBulkCreditModal}
                                                handleBulkCreditModal={this.props.handleBulkCreditModal}
                                                notEnoughCreditModal={this.props.notEnoughCreditModal}
                                                enoughCreditModal={this.props.enoughCreditModal}
                                                zeroCreditModal={this.props.zeroCreditModal}
                                                hitUploadAPI={this.props.hitUploadAPI}
                                                filesList={this.props.filesList}
                                                availableCredit={this.props.availableCredit}
                                                getUserCredit={this.props.getUserCredit} />
                                            <ProjectNameModal
                                                showProjectNameModal={this.props.showProjectNameModal}
                                                handleProjectNameModal={this.props.handleProjectNameModal}
                                                handleProjectName={this.props.handleProjectName}
                                                project_name={this.props.project_name}
                                                projectNameExists={this.props.projectNameExists} />







                                        </div>




                                    </div>
                                </div>
                            </div>
                        </section>}







                    {/* Footwear Selected popup Slider */}











                </div></>
        )
    }
}
