import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import { ProgressBar, Spinner } from "react-bootstrap";

import styles from '../../Css/Model/ModelLanding.module.css'
import ModelUseCases from './ModelUseCases'
import Footer from '../Common/Footer'

export default class modelLanding extends Component {
    constructor(props){
        super(props)
        this.state={
            show:true,
            selectedFile: null,
            url: "",
            orgUrl: "",
            switchScreen:false,
            imageCategory:"",
            loader:false,
            imageWidth:'',
            imageHeight:''
        }
    }
    fileSelectedHandler = (event) => {
        this.setState({
          loader:true
        })
        if(event.target){
        var file = event.target.files[0];
        if (Math.round(file.size / 1024) > 30720) {
          return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState(
            {
              orgUrl: [reader.result],
            },
            () => {
              try {
                localStorage.setItem("orgUrlBg", this.state.orgUrl);
              } catch (e) {
                if (e == "QUOTA_EXCEEDED_ERR") {
                }
              }
            }
          );
        }.bind(this);
        let editedImages;
        editedImages = localStorage.getItem("editedImagesBgr");
        editedImages = editedImages ? editedImages.split(",") : [];
        this.setState(
          {
            selectedFile: event.target.files[0],
            editing: true,
            editedImagesArr: [...editedImages],
            uploading: true,
            loader: true,
            uploadBox: false,
          },
        //   () => {
        //     this.fileUploadHandler(1);
        //   }
        );
        }
        else{
          let file = event[0]
          if (Math.round(file.size / 1024) > 30720) {
            return;
          }
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            this.setState(
              {
                orgUrl: [reader.result],
              },
              () => {
                try {
                  localStorage.setItem("orgUrlBg", this.state.orgUrl);
                } catch (e) {
                  if (e == "QUOTA_EXCEEDED_ERR") {
                  }
                }
              }
            );
          }.bind(this);
          let editedImages;
          editedImages = localStorage.getItem("editedImagesBgr");
          editedImages = editedImages ? editedImages.split(",") : [];
          this.setState(
            {
              selectedFile: event[0],
              editing: true,
              editedImagesArr: [...editedImages],
              uploading: true,
              loader: true,
              uploadBox: false,
            },
            // () => {
            //   this.fileUploadHandler();
            // }
          );
        }
      };
    render() {
        return (
            <div>
                <div className={[styles['common-header'],''].join(' ')}>
                    <center>
                        <h1>Get Marketplace Ready Images</h1>
                        <p>in a <span>Single Click</span> using AI</p>
                    </center>
                </div>
                { this.state.selectedFile == null ?
                <div className={[styles[''],' container'].join(' ')}>
                    <div className={[styles['image-upload'],' row'].join(' ')}>
                        <div className={[styles['left-box'],'col-lg-4 offset-lg-1 col-md-5 col-sm-6 col-12'].join(' ')}>
                            <div className={[styles['outer-box-section'],'row d-dlex flex-row'].join(' ')}>
                            {/* {!this.state.loader ?
                            <> */}
                                <div className={[styles['outer-box-section-1'],'col-md-12'].join(' ')}>
                                    <Dropzone onDrop={acceptedFiles => this.fileSelectedHandler(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section className={[styles['dropbox-box'],''].join(' ')}>
                                            <div {...getRootProps()} className={[styles['border-box'],''].join(' ')}>
                                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/svg/images/upload.svg"></img>
                                                <p className={[styles['drag-text'],''].join(' ')}>Drag Image Here</p>
                                                <p className={[styles['browse-image-text'],' '].join(' ')}>or <span>browse</span> for Image</p>
                                                <p className={[styles['support-image-type'],''].join(' ')}>Support JPG/JPEG file</p>
                                                <input  onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)} type="file" {...getInputProps()} />
                                            </div>
                                        </section>
                                    )}
                                    </Dropzone>
                                </div>
                                <input style={{ display: "none" }} type="file" onChange={this.fileSelectedHandler} ref={(fileInput) => (this.fileInput = fileInput)}/>
                                <button  onClick={() => this.fileInput.click()} className="btn btn-primary choose-image-button">Choose Image</button>
                            
                            </div>
                        </div>
                        <div className={[styles['right-box'],'col-lg-6 offset-md-1 offset-lg-1 col-md-6 col-sm-6 col-12'].join(' ')}>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/model.png" className={[styles[''],'img-fluid'].join(' ')}/>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
                :
                <ModelUseCases
                    originalImage={this.state.orgUrl}
                    selectedFile={this.state.selectedFile}
                />
                }
            </div>
        )
    }
}
