import React, { Component } from 'react';
import Slider from "react-slick";
import styles from '../../../Css/CarsBackgroundReplacement.module.css'
import axios from 'axios'

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { getCookie } from '../../Common/Utils';


function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "inline-block", background: "#fff" }}
			onClick={onClick}
		/>
	);
}

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "inline-block", background: "#fff" }}
			onClick={onClick}
		/>
	);
}



class BackgroundUser extends Component {

    constructor(props) {
		super(props)
		this.state = {
			gifList:[],
			gifUrl: 'https://storage.googleapis.com/spyne-website/static/website-themes/clippr/gifV2/82.gif',
			// file: null,
			listcustomWall:"",
			background_id:'',
            favourable:false
		}
	}

	componentDidMount = async () => {
		axios.get('https://www.clippr.ai/api/fetch-gif')
				.then(res => {
						this.setState({
								gifList: res.data,
                                gifUrl: this.props.otherBgList[0].gif_url,
                                background_id: this.props.otherBgList[0].image_id
						})
				})
	}

    handleBackgroundGif = (imageId,imageCredit, gifUrls, favorite) => {
        this.setState({
                gifUrl: gifUrls,
                background_id:imageId,
                favourable: favorite
        })
		// this.props.handleBackground(imageId,imageCredit)
    }

    handleFavBackround = (imageId, internal = false) => {
        const fd = new FormData()
        fd.append('auth_key', this.props.user.auth_token)
        fd.append('background_id', imageId)
        fd.append('favourable', 'true')
        axios.post(`https://www.clippr.ai/api/v2/backgrounds/updatefavbg`,fd)
        .then(resp => {
            console.log(resp)
            // Lift state up
            this.props.updateBackground(this.props.user.auth_token)
            // this.forceUpdate()
            if (internal) {
                this.setState({
                    favourable: false
                })
            }
        })
    }

    handleRemoveFavBackround = (imageId, internal=false) => {
        const fd = new FormData()
        fd.append('auth_key', this.props.user.auth_token)
        fd.append('background_id', imageId)
        // fd.append('favourable', 'true')
        axios.post(`https://www.clippr.ai/api/v2/backgrounds/updatefavbg`,fd)
        .then(resp => {
            console.log(resp)
            this.props.updateBackground(this.props.user.auth_token)
            if (internal) {
                this.setState({
                    favourable: false
                })
            }
            // this.forceUpdate()
        })
    }

    redirectToDashboard = (e) => {
        // e.preventDefault()
        // localStorage.removeItem('user')
        // window.location.href = `dashboard/enterprise-users?userId=${getCookie('userId')}&enterpriseId=${getCookie('enterpriseId')}`
        // this.props.nextForm(true)
        this.props.showLogoUpload()
    }

    handleBackground = (e) => {
        e.preventDefault()
        if (this.state.favourable) {
            this.handleRemoveFavBackround(this.state.background_id, true)
        } else  {
            this.handleFavBackround(this.state.background_id, true)
        }
    }

    render() { 
        const settings = {
			// className: "center",
			// dots: true,
			infinite: false,
			slidesToShow:3,
			slidesToScroll: 1,
			// centePadding:"1px",
			// vertical: true,
			// verticalSwiping: true,
			swipeToSlide: true,
			autoplay: false,
			speed: 500,
			// autoplaySpeed: 7000
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />
		}; 
        return ( 
            <div className="row justify-content-center align-items-center mt-4">
                <section className={[styles['perfect-output-1'], 'p-0'].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                                <div className={[styles['choose-output'], 'row'].join(' ')}>
                                    {/* <div className={[styles['heading'],'col-md-12'].join(' ')}>
                                    <h3>Choose  <span>your Back</span>ground </h3>
                                </div> */}
                                    <div className={[styles['bg-recomended-right'], 'col-md-7 col-12'].join(' ')}>
                                        {/* <h3>Choose  <span className={styles['border-bt']}>your <span className={styles['colored']}> Background</span></span></h3> */}
                                        {this.state.gifUrl != '' ?
                                            <img className="" src={this.state.gifUrl} />
                                            :
                                            <img className="" src={this.props.orgUrl} />
                                        }

                                        <div className={[styles['perfect-output-images'], 'row'].join(' ')}>
                                            <p className={[styles['fav-icon-2'], 'mb-4'].join(' ')}>
                                                <span onClick={this.handleBackground}><svg className={styles['']} width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" 
                                                    fill={!this.state.favourable?"#ffffff":"#FF0000"} stroke={!this.state.favourable?"#ffffff":"#FF0000"} />
                                                </svg></span> Click here to mark this background as favourite!
                                            </p>
                                            {/* <p><span><a onClick={this.props.hideList}>Cancel</a></span> <a onClick={this.props.applyChange}>Apply</a></p> */}
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-4">
                                                <button onClick={this.redirectToDashboard} disabled={this.props.favList.length === 0}>Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={[styles['bg-recomended-area'], styles['custom-big'], 'col-md-5'].join(' ')}>
                                        <Accordion allowMultipleExpanded preExpanded={['a', 'b']}>
                                            <AccordionItem uuid="a" className={[styles['bg-accordion-tab'], styles['max-accordion-height']].join(' ')}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h3><span className={styles['colored']}>Your Favourite</span><span> Backgrounds</span> <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/down-arrow.png" /></h3>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className={[styles['bg-recommended'], 'row m-0'].join(' ')}>
                                                        {/* <h6>Backgrounds <span>(Recommended)</span></h6> */}
                                                        <ul className={[styles['bg-recomended-images'], ''].join(' ')}>
                                                            {
                                                                this.props.favList.map((e, i) => {
                                                                    return (
                                                                        <li key={i} className={this.props.background_id == e.image_id ? styles['active-background-sec'] : styles['inactive-background-sec']} >
                                                                            <img className="" src={e.image_url} onClick={() => this.handleBackgroundGif(e.image_id, e.image_credit, e.gif_url, true)}/>
                                                                            <p className={[styles['fav-icon'], ''].join(' ')} onClick={() => this.handleRemoveFavBackround(e.image_id)}>
                                                                                <svg width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" 
                                                                                    fill="#FF0000" stroke="#FF0000" />
                                                                                </svg>
                                                                            </p>
                                                                            <span>{e.background_name}</span>
                                                                        </li>
                                                                    )

                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem uuid="b" className={[styles['bg-accordion-tab'], styles['max-accordion-height']].join(' ')}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <h3><span className={styles['colored']}>Spyne’s  </span><span> other Backgrounds</span> <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/down-arrow.png" /></h3>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className={[styles['bg-recommended'], 'row m-0'].join(' ')}>
                                                        <ul className={[styles['bg-recomended-images'], ''].join(' ')}>
                                                            {
                                                                this.props.otherBgList.map((e, i) => {
                                                                    return (
                                                                        <li key={`${e.image_id}_${i}`} className={this.state.background_id == e.image_id ? styles['active-background-sec'] : styles['inactive-background-sec']} >
                                                                            <img className="" src={e.image_url} onClick={() => this.handleBackgroundGif(e.image_id, e.image_credit, e.gif_url, false)}/>
                                                                            <p className={[styles['fav-icon'], ''].join(' ')} onClick={() => this.handleFavBackround(e.image_id)}>
                                                                                <svg className={styles['white-icon']} width="15" height="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M10.8218 1.16893C9.81275 0.295543 8.27816 0.428357 7.30583 1.44754L7.30561 1.44778L6.86165 1.91251L6.50011 2.29098L6.13856 1.91251L5.69461 1.44778L5.69404 1.44718C4.72427 0.42865 3.18766 0.295373 2.17843 1.16893C0.982641 2.20588 0.91689 4.07852 1.9898 5.20557L10.8218 1.16893ZM10.8218 1.16893C12.0176 2.2059 12.0833 4.07848 11.0083 5.20546L11.0082 5.20557L6.64761 9.77956C6.64758 9.77959 6.64755 9.77963 6.64752 9.77966C6.56285 9.86833 6.43512 9.86833 6.35045 9.77966C6.35042 9.77963 6.35039 9.77959 6.35035 9.77956L1.99001 5.20579L10.8218 1.16893Z" fill="#ffffff" stroke="#ffffff" />
                                                                                </svg>
                                                                                <svg className={styles['hoverable-icon']} width="15" height="13" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M6.57489 1.35802L6.57512 1.35779C7.54745 0.338605 9.08204 0.205791 10.0911 1.07918C11.2869 2.11615 11.3526 3.98873 10.2775 5.11571L10.2774 5.11582L5.9169 9.68981C5.8322 9.77861 5.70434 9.77861 5.61964 9.68981L1.2593 5.11603C0.186169 3.98901 0.251851 2.11619 1.44771 1.07918C2.45695 0.205621 3.99356 0.338898 4.96333 1.35743L4.9639 1.35802L5.40785 1.82276L5.7694 2.20123L6.13094 1.82276L6.57489 1.35802Z" fill="white" stroke="#FF0000" />
                                                                                </svg>
                                                                            </p>
                                                                            <span>{e.background_name}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>

                                        </Accordion>





                                    </div>
                                    <div className={[styles['bg-recomended-area'], 'col-12 d-md-none d-block'].join(' ')}>
                                        <div className={[styles['bg-recommended'], 'row m-0'].join(' ')} id="custom-bg-slider">
                                            <Slider {...settings} className={[styles['custom-slider'], 'col-md-12'].join(' ')} >
                                                {
                                                    this.props.planData.map((e, i) => {
                                                        return (
                                                            <div key={`${e.image_id}_${e.background_name}_${i}`} className={this.props.background_id == e.image_id ? styles['active-background-sec'] : styles['inactive-background-sec']} onClick={() => this.handleBackgroundGif(e.image_id, e.image_credit)}>
                                                                <img className="" src={e.image_url} />
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div>


                                </div>
                                
                    </div>
                </section>
            </div>
         );
    }
}
 
export default BackgroundUser;