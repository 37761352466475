import React, { Component } from "react";
import { Link, useHistory } from 'react-router-dom'

import styles from '../CaseStudies/CaseStudiesLanding.module.css'
import queryString from 'query-string'
import axios from 'axios'


export default class CaseStudiesDetails extends Component {

    constructor(props){
        super(props)
        this.state = {
            whitepaperData:null,
            emailId: '',
            isValidEmail: false,
            title:'',
            description:'',
            page_url:'',
            banner_img:'',
            pdf_url:''
        }
    }

    cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

    componentDidMount = () => {

        var emailId = this.cookie('emailId')
        let queries = queryString.parse(this.props.location.search)

        if(emailId){
            this.setState({
                emailId: emailId,
                isValidEmail: !this.state.isValidEmail
            })
        }

        this.getWhitePaper(queries.page_url)
    }

    getWhitePaper = (page_url) => {
        axios({
            'method':'GET',
            'url':"https://www.clippr.ai/api/v2/whitepaper/details",
            'params': {
                page_url: page_url,
            }
            
        })
        .then((resp) => {
            
            this.setState({
                
                title: resp?.data?.data?.title,
                description: resp?.data?.data?.long_description,
                page_url: resp?.data?.data?.page_url,
                banner_img: resp?.data?.data?.banner_url,
                pdf_url:resp?.data?.data?.pdf_url
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            emailId: e.target.value
        })
    }

    handleEmail = () => {
        const fd = new FormData()
        fd.append('email_id', this.state.emailId)
        fd.append('is_valid_email', this.state.isValidEmail)
        fd.append('page_url', this.state.page_url)
        axios.post(`https://www.clippr.ai/api/v2/whitepaper/insert`, fd)
        .then((resp) => {
            this.setState({
                emailId:''
            })
            // const a = document.createElement("a")
            // a.href = 'https://storage.googleapis.com/spyne-website/Whitepapers%20_%20Case%20Study/Final%20Food%20Case%20Study.pdf'
            // a.download = true
            // a.click()
            window.open(this.state.pdf_url);
        })
    }


    render() {
        return  (
            <div>
                <section className={[styles['case-studies'], ''].join(' ')}>
                    <div className={[styles['case-container'],''].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles['case-image'],'col-md-4'].join(' ')}>
                                <img src={this.state.banner_img}/>
                            </div>
                            <div className={[styles['case-content2'],'col-md-8'].join(' ')}>
                                <h3>{this.state.title}</h3>
                                <p>{this.state.description}</p>
                                <input value={this.state.emailId} type="email" placeholder="Email address *" onChange={this.handleChange}/>
                                <button disabled={this.state.emailId === ''} onClick={this.handleEmail}>Get whitepaper</button>
                                {/* <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
                                    dolore magna aliqua.Ut enim ad minim veniam, quis nostrud </small> */}
                            </div>
                               
                        </div>
                    </div>
                </section>

            </div>
        );

    }
}