import React, { Component } from 'react'
import styles from '../../Css/BulkProcessing/BulkProcessingV2.module.css'


import Logo from './Logo';

export default class OutputScreen1 extends Component {
    constructor(props){
        super(props)
        this.state={
            activeButton:"Edited",
            orignalTab: "Edited",
          
        }
        
        
    }
    handleActiveButton = (name) => {
        this.setState({
            activeButton:name
        })
    }
    
    handleOriginalTab = (name) => {
        this.setState({
            orignalTab: name
        })
    }







    render() {
        return (
            <div>
                <section className={[styles['bulk-try'],''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles[''],'col-md-7 col-lg-8'].join(' ')}>
                                <ul className={[styles['change-tabs'],''].join(' ')}>
                                    <li className={[styles[' '],''].join(' ')}>
                                        <button className={this.state.activeButton == "Original" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Original")}> 
                                            <span>Original</span>
                                        </button>
                                    </li>
                                    <li className={[styles[''],''].join(' ')}>
                                        <button className={this.state.activeButton == "Edited" ? [styles['active'],' '].join(' '): null} onClick={() => this.handleActiveButton("Edited")}> 
                                            <span>Edited</span>   
                                        </button>
                                    </li>
                                </ul>
                               
                                {

                                    this.state.activeButton == "Original" ?
                                    
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    <div className={[styles['add-more'],''].join(' ')}>
                                        <span>+</span>
                                    </div>
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                            <div className={[styles['reload-cancel'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span>
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}> 
                                            <div className={[styles['reload-cancel'],''].join(' ')}><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reload.svg" />
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/reorder.svg" /></span>
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>  
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car2.png" />
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                : this.state.activeButton == "Edited" ?
                                
                                <div className={[styles['tab-content'],'row'].join(' ')}>
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                            <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    <div className={[styles['uploaded-cars'],'col-md-3'].join(' ')}>
                                        <div className={[styles['uploaded-cars-single'],'row'].join(' ')}>
                                            <div className={[styles['reload-cancel2'],''].join(' ')}> 
                                                <span><img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/cross.svg" /></span>
                                            </div>
                                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/car1.png" />
                                            <p>car1.png</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                :
                                null
                                }
                               
                                
                            </div>

                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'col-md-12'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                        <sub>15</sub>
                                        Available Credits: &nbsp; <span> 15</span>
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}> 
                                                     
                                        <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                            <div className={[styles['background-grid'],'col-md-12'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/car-v2/virtual-showroom/1.png"/>
                                                    <p>Lorem ipsum lorem ipsum ipsum lorem ipsum lorem ipsum lorem ipsum</p>
                                                    <button className={styles['proceed-btn2']}>Generate 360</button>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                                 
                                    </div>
                                
                                    
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button>Download All</button>
                                    </div>
                                </div>
                            </div>


                            <div className={[styles['bulk-right'],'col-md-5 col-lg-4'].join(' ')}>
                                <div className={[styles['bulk-right-box'],'row m-0'].join(' ')}>
                                    <div className={[styles['credits-available'],'col-md-12'].join(' ')}>
                                        <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/credits.png" />
                                        <sub>15</sub>
                                        Available Credits: &nbsp; <span> 15</span>
                                    </div>
                                    <div className={[styles['bulk-accordion'],'col-md-12'].join(' ')}> 
                                                     
                                        <div className={[styles['bulk-bg'],'row'].join(' ')}>
                                            <div className="text-right">
                                            <img className="cross-img" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/close_big.png"/>
                                            </div>
                                            <div className={[styles['background-grid'],'col-md-12'].join(' ')}> 
                                                <div className={[styles['active-background-sec'],'row m-0'].join(' ')}>
                                                    <img alt="" src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/32925-sorting-pictures.gif"/>
                                                    <h5>Drag and Re-order orignal imagesGIF </h5>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                                 
                                    </div>
                                
                                    
                                    <div className={[styles['apply-btn-section'],'col-md-12'].join(' ')}>
                                        <button>Generate 360</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            
            
            
            {/* Payment Success  */}
            <section className={[styles['payment-success'],''].join(' ')}>
                    <div className={[styles[''],'container'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles['payment-success-content'],'col-lg-6 offset-lg-3 text-center'].join(' ')}>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/payment-added.gif" />
                                <h5>Payment Successful!</h5>
                                <p>Your payment was sucessful. You can now continue editing your Images</p>
                                <h6>Credits Added : <span>100</span></h6>
                                <button className={styles['proceed-btn']}>Proceed</button>
                            </div>
            
                        </div>
                    </div>
            </section>
            
           {/* Uh-Oh Popup More than 36 images  */}
           <section className={[styles['more-uploads'],''].join(' ')}>
                    <div className={[styles[''],'container-fluid'].join(' ')}>
                        <div className={[styles[''],'row'].join(' ')}>
                            <div className={[styles['popup-content'],'col-lg-4 text-center'].join(' ')}>
                                <h5>Uh-Oh !!</h5>
                                <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/oh-no.gif" />
                                <p>You don’t have enough  credits to process all the images.</p>
                                <button className={styles['proceed-btn']}>Buy Credits</button>
                                <p><span>or</span></p>
                                <h6>Upload a single image <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/arrow.png" /></h6>
                            </div>
            
                        </div>
                    </div>
            </section>

            {/* Warning popup U cant upload more than 36 */}
            <section className={[styles['more-uploads'],''].join(' ')}>
                <div className={[styles[''],'container-fluid'].join(' ')}>
                    <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['popup-content'],'col-lg-4 text-center'].join(' ')}>
                           
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/warning.gif" />
                            <p>You cannot upload more than 36 images at a time</p>
                            <button className={styles['proceed-btn2']}>Try Again</button>
                           
                        </div>
        
                    </div>
                </div>
            </section> 

            {/* Credit Deducted Wallet 2.0 */}

            <section className={[styles['more-uploads'],''].join(' ')}>
                <div className={[styles[''],'container-fluid'].join(' ')}>
                    <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['popup-content'],'col-lg-4 text-center'].join(' ')}> 
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/payment-success.gif" />
                            <p>36 Credits has been deducted from your wallet! </p>
                            <h3>Remaining Credits : <span>100</span></h3>
                            <button className={styles['proceed-btn']}>View Output</button>
                           
                        </div>
        
                    </div>
                </div>
            </section>    

            {/* Reason for refund Popup */}
            <section className={[styles['more-uploads'],''].join(' ')}>
                <div className={[styles[''],'container-fluid'].join(' ')}>
                    <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['popup-content'],'col-lg-4 text-center'].join(' ')}> 
                            <h4>Please sel<span>ect a reason</span> for Refund</h4>
                            <img src="https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/bulkprocessingv2/Group%205892907.png" />
                            <ul>
                                <li><span>Car is not placed properly </span></li>
                                <li><span>Car Body and Window have reflections </span></li>
                                <li><span>Car looks unnatural </span></li>
                                <li><span>Background is not clear </span></li>
                                <li><span>Other</span></li>
                            </ul>
                            <textarea cols="" rows="" placeholder="Tell us more....." className="form-control"></textarea> 
                            <button className={styles['proceed-btn']}>Claim Refund</button>
                           
                        </div>
        
                    </div>
                </div>
            </section>   
            
            {/*How we can improve */}
            <section className={[styles['more-uploads'],''].join(' ')}>
                <div className={[styles[''],'container-fluid'].join(' ')}>
                    <div className={[styles[''],'row'].join(' ')}>
                        <div className={[styles['popup-content'],styles['popup-text'],'col-lg-4 text-center'].join(' ')}> 
                            <h4>How c<span>an we imp</span>rove?</h4>
                            <p>Do you have any suggestion or found some bug? please let us know in the field below.</p>
                            <ul>
                                <li><span>Car is not placed properly </span></li>
                                <li><span>Car Body and Window have reflections </span></li>
                                <li><span>Car looks unnatural </span></li>
                                <li><span>Background is not clear </span></li>
                                <li><span>Other</span></li>
                            </ul>
                            <textarea cols="" rows="" placeholder="Tell us more....." className="form-control"></textarea> 
                            <button className={styles['proceed-btn']}>Submit</button>
                           
                        </div>
        
                    </div>
                </div>
            </section>  



            </div>
        )
    }
}
