import React, { Component } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom'
import ScreenOne from '../Component/BackgroundRemovalV2/Screen1'
import ScreenOneFashion from '../Component/FashionRemovalV2/Screen1'
import ScreenTwo from '../Component/BackgroundRemoval/Screen2'
import Pricing from '../Component/BackgroundRemoval/pricing'
import ClipprNav from '../Component/Common/ClipprNav'
import UserDashboard from '../Component/BackgroundRemoval/UserDashboard'
import Cars from '../Component/BackgroundReplacement/Cars/CarsBackgroundReplacement'
import ProCars from '../Component/BackgroundReplacement/Cars/ProCarsBackgroundReplacement'
import CarsLanding from '../Component/BackgroundReplacement/Cars/LandingPage'
import FootWearLanding from '../Component/Foootwear/Landingpage'
// import Footwear from '../Component/Foootwear/Footwear'
import SkyLanding from '../Component/SkyReplacement/SkyLanding'
import Model from '../Component/Model/modelLanding'
import Footwear from '../Component/Footwears/FootwearLanding'
import Upsacling from '../Component/Upscaling/Upsacling'
import LandingPage from '../Component/LandingPage/home'
import LandingScreenTwo from '../Component/BackgroundRemoval/LandingScreenTwo'
import LandingScreenThree from '../Component/BackgroundRemoval/LandingScreenThree'
import RemoveForeground from '../Component/RemoveForeground/RemoveForeground'
import BulkProcessing from '../Component/BulkProcessing/BulkProcessing'
import Car from '../Component/BackgroundReplacement/Cars/Car'
import Bikes from "../Component/BackgroundReplacement/Bikes/BikesBackgroundReplacement"
import Footwears from '../Component/Footwears/Footwears'
import ScreenOneProduct from '../Component/Product/screen1'
import Footer from '../Component/Common/Footer'
import CarsTest from '../Component/BackgroundReplacementV2/Cars/CarsBackgroundReplacement'
import TrustedCars from '../Component/BackgroundReplacement/Cars/TrustedCars'
import TrustedHeader from '../Component/Common/TrustedHeader'
import GroceryLanding from '../Component/Grocery/GroceryLanding'

import FootwearV2 from '../Component/Foootwear/FootwearV2' 
import FootwearBackgroundV2 from '../Component/Foootwear/FootwearBackgroundV2'
import BulkUploadV2 from '../Css/Footwear/BulkUploadV2'

import AppLanding from '../Component/AppLanding/AppLanding'  
import BulkLandingV2 from '../Component/BulkProcessing/BulkLandingV2'
import OutputScreen1 from '../Component/BulkProcessing/OutputScreen1'
import ThreeSixtyView from '../Component/BulkProcessing/ThreeSixtyView'
import LoginPage from '../Component/Login/LoginPage'
import SignUp from '../Component/SignUp/SignUp'
import TryCategories from '../Component/TryCategories/TryCategories'
import ForgetPassword from '../Component/Login/ForgetPassword'
import SuccessfullyChanged from '../Component/Login/SuccessfullyChanged'
import AppDashboard from '../Component/AppDashboard/appDashboard'
import TravoClippr from '../Component/TravoBackgroundRemoval/Screen1'
import TravoFootwear from '../Component/TravoFootwear/FootwearLanding'
import WhitepaperBackgrounds from '../Component/BackgroundReplacement/Background/WhitepaperBackgrounds'
import User from '../Component/AppDashboard/User/User'
import EditUser from '../Component/AppDashboard/User/EditUser'
import AddEnterprise from '../Component/AddEnterprise/AddEnterprise'
import WhitelabelCars from '../WhitelabelClassifier/Component/CarsBackgroundReplacement'
import SelectModel from '../Component/3DBuild/Saree/SelectModel'
import UploadSaree from '../Component/3DBuild/Saree/UploadSaree'
import Upload1 from '../Component/3DBuild/Saree/Upload1'
import CreateModel from '../Component/3DBuild/Saree/CreateModel'
import MultiUploadEdited from '../WhitelabelClassifier/Component/MultiUploadSku/MultiUploadEdited'
import Fabric from '../Component/3DBuild/Fabric/Fabric'



import ClipprTry from '../Component/BackgroundRemovalV2/ClipprTry'
import MultiUploadSku from '../WhitelabelClassifier/Component/MultiUploadSku/MultiUploadSku'
import UploadSuit from '../Component/3DBuild/Suit/UploadSuit'
import UploadCurtains from '../Component/3DBuild/Curtains/uploadCurtains'
import UploadCushion from '../Component/3DBuild/Cushion/uploadCushion'

import HomeFurnishing from '../Component/3DBuild/HomeFurnishing/HomeFurnishing'
import CarsThreeSixty from '../Component/BackgroundReplacement/Cars/CarsThreeSixty'
import CarsThreeSixtyOutput from '../Component/BackgroundReplacement/Cars/CarsThreeSixtyOutput'
import ScreenFirst from '../Component/Food/ScreenFirst'

import EcomPricing from '../Component/BackgroundRemoval/EcomPricing'
import FoodPricing from '../Component/BackgroundRemoval/FoodPricing'
import FabricPricing from '../Component/BackgroundRemoval/FabricPricing'

import Affiliate from '../Component/Affiliate/Affiliate'
import AffiliateForm from '../Component/Affiliate/AffiliateForm'
import CaseStudiesLanding from '../Component/CaseStudies/CaseStudiesLanding'
import CaseStudiesDetails from '../Component/CaseStudies/CaseStudiesDetails'
import UploadScreen from '../Component/ImageScoring/UploadScreen'
import Score from '../Component/ImageScoring/ScoreImage'
import ScoreImage from '../Component/ImageScoring/ScoreImage'
import ImageScoringUpload from '../Component/ImageScoring/ImageScoringUpload'
import Admin from '../Component/SuperAdmin/Admin'
import Template from '../Component/FacebookTemplate/Template'

class Routes extends Component {
    constructor(props){
        super(props)
        this.state={
            domain:'',
        }
    }
    componentDidMount = () => {
        this.setPathAndLoad();
        this.setState({
            domain:window.location.href,
        })
    }
    
    componentDidUpdate = ()=>{
        this.setPathAndLoad();  
    }

    setPathAndLoad = () => {
        try {
            let url = window.location.pathname
            if (url.includes('//')) {
                url = url.replaceAll('//', '/');
                const { protocol, host, search } = window.location;
                const newURL = `${protocol}//${host}${url}${search}`
                window.location.replace(newURL)
                return;
            }
        }
        catch (error) {
            //console.log(error)
        }
    }

    render() {
        const { location } = this.props
        const Header = () => {
            if (location.pathname == "/tech/trusted-cars") {
                return <TrustedHeader/>
            } else if (location.pathname == "/login") {
                return null
            } else if (location.pathname == "/signup") {
                return null
            } else if (location.pathname == "/forgot-password") {
                return null
            } else if (location.pathname == "/success") {
                return null
            } else if (location.pathname == "/butler/api-home") {
                return null
            } else if (location.pathname == "/tech/travo-clippr/try") {
                return null
            } else if (location.pathname == "/tech/travo-footwear/try") {
                return null
            } else if (location.pathname == "/butler/add-user") {
                return null
            } else if (location.pathname == "/butler/edit-user") {
                return null
            } else if (location.pathname == "/whitelabel/add-enterprise") {
                return null
            } else {
                return <ClipprNav/>
            }
        }

        const FooterComp = () => {
            if (location.pathname == "/tech/trusted-cars") {
                return null
            } else if (location.pathname == "/login") {
                return null
            } else if (location.pathname == "/signup") {
                return null
            } else if (location.pathname == "/try-categories") {
                return null
            } else if (location.pathname == "/forgot-password") {
                return null
            }  else if (location.pathname == "/success") {
                return null
            } else if (location.pathname == "/butler/api-home") {
                return null
            } else if (location.pathname == "/tech/travo-clippr/try") {
                return null
            } else if (location.pathname == "/tech/travo-footwear/try") {
                return null
            }else if (location.pathname == "/butler/add-user") {
                return null
            } else if (location.pathname == "/butler/edit-user") {
                return null
            } else if (location.pathname == "/whitelabel/add-enterprise") {
                return null
            } else if (location.pathname == "/image-scoring") {
                 return null
            }  else {
                return <Footer />
            }
        }
        return (
            <div>

                
                {<Header/>}
                <Route path="/" exact component = {LandingPage}/>
                <Route path="/login" exact component = {LoginPage}/>
                <Route path="/signup" exact component = {SignUp}/>
                <Route path="/forgot-password" exact component = {ForgetPassword}/>
                <Route path="/success" exact component = {SuccessfullyChanged}/>
                <Route path="/try-categories" exact component = {TryCategories}/>
                <Route path="/tech/clippr/try" exact component={ScreenOne}/> 
                <Route path="/tech/travo-clippr/try" exact component={TravoClippr}/> 
                <Route path="/tech/fashion/try" exact component={ScreenOneFashion}/>
                <Route path="/tech/product/try" exact component={ScreenOneProduct}/>
                <Route path="/try/screentwo" component={ScreenTwo}/>
                <Route path="/tech/pricing" component={Pricing}/>
                <Route path="/tech/cars/pricing" component={Pricing}/>
                <Route path="/tech/ecommerce/pricing" component={EcomPricing}/>
                <Route path="/tech/food/pricing" component={FoodPricing}/>
                <Route path="/tech/fabric/pricing" component={FabricPricing}/>
                <Route path="/tech/dashboard" component={UserDashboard}/>
                <Route path="/tech/cars/try" exact component={Cars}/>
                <Route path="/tech/bikes/try" exact component={Bikes}/>
                <Route path="/tech/cars/pro" exact component={ProCars}/>
                <Route path="/tech/cars/test" exact component={CarsTest}/>
                <Route path="/tech/cars" exact component={Car}/>
                <Route path="/tech/footwear/try" exact component={FootwearV2}/>
                {/* <Route path="/tech/footwear/try" component={Footwear}/> */}
                <Route path="/tech/travo-footwear/try" component={TravoFootwear}/>
                <Route path="/tech/food/try" component={ScreenFirst}/>
                
                <Route path="/tech/real-estate" exact component={SkyLanding}/>
                <Route path="/tech/model" exact component={Model}/>
                <Route path="/tech/upscaling" exact component={Upsacling}/>
                <Route path="/tech/landingscreentwo" exact component={LandingScreenTwo}/>
                <Route path="/tech/landingscreenthree" exact component={LandingScreenThree}/>
                <Route path="/tech/removeforeground" exact component={RemoveForeground} />
                <Route path="/tech/bulkprocessing" exact component={BulkProcessing} />
                <Route path="/tech/footwear" exact component={Footwears} />
                <Route path="/tech/trusted-cars" exact component={TrustedCars} />
                <Route path="/tech/grocery" exact component={GroceryLanding} />
                <Route path="/tech/app" exact component={AppLanding} />
                <Route path="/tech/footwear/tryv2" exact component={FootwearV2}/>
                <Route path="/tech/footwear/trybulk" exact component={BulkUploadV2} />
                <Route path="/tech/footwear/footwearbackgroundv2" exact component={FootwearBackgroundV2}/>
                <Route path="/tech/carssixty/try" exact component={CarsThreeSixty}/>
                <Route path="/tech/carssixtyoutput/try" exact component={CarsThreeSixtyOutput}/>

                <Route path="/tech/bulkprocessing/try" exact component={BulkLandingV2} />
                <Route path="/tech/bulkprocessing/output" exact component={OutputScreen1} />
                <Route path="/tech/bulkprocessing/360view" exact component={ThreeSixtyView} />
                <Route path="/butler/api-home" exact component={AppDashboard} />
                <Route path="/tech/cars/backgrounds" exact component={WhitepaperBackgrounds}/>
                <Route path="/butler/add-user" exact component={User} />
                <Route path="/butler/edit-user" exact component={EditUser} />
                <Route path="/whitelabel/add-enterprise" exact component={AddEnterprise} />
                <Route path="/tech/cars/darcars" exact component={WhitelabelCars} />
                <Route path="/tech/build/wrap" exact component={SelectModel}/>
                <Route path="/tech/build/wrap2" exact component={UploadSaree}/>
                <Route path="/tech/build/wrap3" exact component={Upload1}/>
                <Route path="/tech/build" exact component={CreateModel}/>
                <Route path="/tech/fabric/kurti" exact component={UploadSuit}/>
                <Route path="/tech/home-furnishing/curtain" exact component={UploadCurtains}/>
                <Route path="/tech/home-furnishing/cushion" exact component={UploadCushion}/>
                
                <Route path="/tech/fabric/saree" exact component={CreateModel}/>
                <Route path="/tech/fabric" exact component={Fabric}/>
                <Route path="/tech/home-furnishing" exact component={HomeFurnishing}/>
                <Route path="/affiliate" exact component={Affiliate}/>
                <Route path="/affiliate/form" exact component={AffiliateForm}/>
                <Route path="/whitepapers" exact component={CaseStudiesLanding}/>
                <Route path="/whitepapers/details" exact component={CaseStudiesDetails}/>
                <Route path="/tech/clippr/try-new" exact component={ClipprTry}/> 
                <Route path="/tech/bulk/multisku" exact component={MultiUploadSku} />
                <Route path="/tech/bulk/edited/multisku" exact component={MultiUploadEdited} />
                <Route path="/image-scoring" exact component={ImageScoringUpload}/>
                {/* <Route path="/tech/add-modify/user-role" exact component={Admin} /> */}
                <Route path="/fb" exact component={Template} />
                { <FooterComp/> }
            </div>
        )
    }
}

export default withRouter(Routes)
