import React, { useContext, useEffect } from 'react'
import styles from '../../../Css/3DBuild/HomeFurnishing/HomeFurnishing.module.css'
import { Link, useHistory } from 'react-router-dom'
import {Helmet} from "react-helmet";

const HomeFurnishing = (props) => {
     
	 
    return (
        <><Helmet>
            <title>Try AI Home Furnishing Photo Editor Now</title>
            <meta id="meta-description" name="description" content="Change Your Home Furnishing Products Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Home Furnishing Photo Editing Tech. Instantly Change Image Background for Home Furnishing Products and give your Home Furnishing Photography a new look." />
            <meta property="og:title" content="Try AI Home Furnishing Photo Editor Now" />
            <meta property="og:description" content="Change Your Home Furnishing Products Background to Marketplaces Ready Images in Just 5 Seconds with Spyne Home Furnishing Photo Editing Tech. Instantly Change Image Background for Home Furnishing Products and give your Home Furnishing Photography a new look." />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135505701-1"></script>
        </Helmet><div>
                <section className={[styles['fabric-section'], ''].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                        <div className={[styles[''], 'row'].join(' ')}>
                            <div className={[styles['fabric'], 'col-md-12'].join(' ')}>
                                <div className={[styles[''], 'row'].join(' ')}>
                                    <div className={[styles['fabric-back'], 'col-md-3'].join(' ')}>
                                        <a href="/try-categories"><span>&#8249;</span></a>
                                        <img src="https://storage.googleapis.com/spyne-website/try-categories/home-breadcrumb.png" />
                                    </div>
                                    <div className={[styles['fabric-content'], 'col-md-9'].join(' ')}>
                                        <h4>Edit<span> Home Furnishing </span>Images</h4>
                                        <p>Convert Fabric images to 3D rendered Marketplace ready images instantly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* //Fabric Breadcrumb  */}

                {/* Fabric main  */}
                <section className={[styles['fabric-main'], ''].join(' ')}>
                    <div className={[styles[''], 'container-fluid'].join(' ')}>
                        <div className={[styles[''], 'row align-item-center justify-content-center'].join(' ')}>

                            <div className={[styles['cars-try'], 'col-md-4'].join(' ')}>
                                <Link to="/tech/home-furnishing/bedsheet" className={[styles["text-black"], styles['link']].join(' ')}>
                                    <div className={[styles['card'], styles['fabric11']].join(' ')}>
                                        <div className={styles['img-container']}>
                                            <img src="https://storage.googleapis.com/spyne-website/try-categories/bedsheet.png" />
                                        </div>
                                        <div className="row">
                                            <h4 className="text-decoration-none">Bedsheet</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className={[styles['cars-try'], 'col-md-4'].join(' ')}>
                                <Link to="/tech/home-furnishing/curtain" className={[styles["text-black"], styles['link']].join(' ')}>
                                    <div className={[styles['card'], styles['fabric12']].join(' ')}>
                                        <div className={styles['img-container']}>
                                            <img src="https://storage.googleapis.com/spyne-website/try-categories/curtain.png" />
                                        </div>
                                        <div className="row">
                                            <h4 className="text-decoration-none">Curtain</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className={[styles['cars-try'], 'col-md-4'].join(' ')}>
                                <Link to="/tech/home-furnishing/cushion" className={[styles["text-black"], styles['link']].join(' ')}>
                                    <div className={[styles['card'], styles['fabric13']].join(' ')}>
                                        <div className={styles['img-container']}>
                                            <img src="https://storage.googleapis.com/spyne-website/try-categories/Cushion.png" />
                                        </div>
                                        <div className="row">
                                            <h4 className="text-decoration-none">Cushion</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </section>

            </div></>
    )
}

export default HomeFurnishing