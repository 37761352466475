const imgData = [
        {
                img1:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category1.a.png",
                img2:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category1.b.png"
        },
        {
                img1:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category2.a.png",
                img2:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category2.b.png"
        },
        {
                img1:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category3.a.png",
                img2:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category3.b.png"
        },
        {
                img1:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category4.a.png",
                img2:"https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/category4.b.png"
        }
    ]

export default imgData
